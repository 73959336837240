import React, { useEffect, useState } from "react"
import { Link, useHistory, useLocation } from "react-router-dom"
import styled from "styled-components"
import { message, Input, Button } from "antd"
import { useStoreActions } from "easy-peasy"
import {
  CheckCircleOutlined,
  LockOutlined,
  PhoneOutlined,
  UserOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons"
import { AppDataStore } from "../appData/types"
import swimHeader from "../images/swimmingly_final_header.png"
import { gen, getLandingPageRoute, swimminglyApi, validateEmail } from "./utils"

const RegisterBackground = styled.div`
  height: 100%;
  background-color: var(--lightgrey);
`

const RegisterContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;

  section {
    width: 95%;
    max-width: 470px;
  }

  .swimmingly-logo-container {
    min-width: 250px;
    padding-bottom: 10px;
    padding-left: 5px;
  }

  .top-row {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-content: center;

    .user-icon {
      width: 84px;
      height: 46px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--deepdarkblue);
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      color: var(--white);
    }
  }
`

const RegisterForm = styled.div`
  background-color: var(--snow);
  border-radius: 5px;
  border-top-right-radius: 0;
  border: 1px solid var(--mediumgrey);
  border-top: 5px solid var(--deepdarkblue);

  form {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    padding-top: 25px;
    padding-bottom: 25px;
    gap: 5px;

    .label-row {
      padding-top: 10px;
      color: var(--mediumgrey);
    }

    .two-items {
      display: flex;
      justify-content: space-between;
    }

    .form-button {
      border: none;
      background-color: var(--deepdarkblue);
      color: var(--white);
      height: 40px;
      margin-top: 10px;
      border-radius: 5px;
    }
  }
`

type Errors = {
  firstName?: {
    message: string
  }
  lastName?: {
    message: string
  }
  email?: {
    message: string
  }
  password?: {
    message: string
  }
  passwordConfirm?: {
    message: string
  }
  [key: string]: any
}

interface ILocation {
  state?: {
    email?: string | null
    clubToken?: string | undefined
  }
}

export default function RegisterParentForm() {
  const setUser = useStoreActions((actions: AppDataStore) => actions.setUser)
  const [loading, setLoading] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [clubToken, setClubToken] = useState<string|undefined>(undefined)
  const [phone, setPhone] = useState("")
  const [password, setPassword] = useState("")
  const [passwordConfirm, setPasswordConfirm] = useState("")
  const [invalidPhone, setInvalidPhone] = useState(false)
  // const [errors, setErrors] = useState<Errors>({})

  const history = useHistory()

  const location: ILocation = useLocation()

  useEffect(() => {
    if (location && location.state && location.state.email) {
      setEmail(location.state.email.toLowerCase().trim())
    }
    setClubToken(location.state?.clubToken)
  }, [location])

  const validate = () => {
    // setErrors({})
    let newErrors: Errors = {}
    let errorCount = 0

    if (firstName.trim() === "") {
      errorCount++
      newErrors = {
        ...newErrors,
        firstName: { message: "Must provide first name" },
      }
    }
    if (lastName.trim() === "") {
      errorCount++
      newErrors = {
        ...newErrors,
        lastName: { message: "Must provide last name" },
      }
    }
    if (email.trim() === "") {
      errorCount++
      newErrors = {
        ...newErrors,
        email: { message: "Must provide email" },
      }
    }
    if (!validateEmail(email)) {
      errorCount++
      newErrors = {
        ...newErrors,
        email: { message: "Invalid email" },
      }
    }
    if (phone && phone.trim() !== "") {
      let numericPhoneNumber = phone.replace(/[^0-9]/g, "")
      if (
        /a-zA-Z/.test(phone) ||
        !(numericPhoneNumber.length >= 10 && numericPhoneNumber.length <= 14)
      ) {
        errorCount++
        newErrors = {
          ...newErrors,
          phone: { message: "invalid phone number" },
        }
        setInvalidPhone(true)
      } else {
        setPhone(numericPhoneNumber)
      }
    }
    if (password.trim() === "") {
      errorCount++
      newErrors = {
        ...newErrors,
        password: { message: "Must provide password" },
      }
    }
    if (passwordConfirm.trim() === "" || passwordConfirm !== password) {
      errorCount++
      newErrors = {
        ...newErrors,
        passwordConfirm: { message: "Passwords do not match" },
      }
    }

    // setErrors(newErrors)

    for (let theKey of Object.keys(newErrors)) {
      message.error(newErrors[theKey].message)
    }

    if (errorCount > 0) {
      return false
    } else {
      return true
    }
  }

  const submit = async () => {
    setLoading(true)
    const isValid = validate()
    if (isValid) {
      let newUserData = await swimminglyApi
        .post(gen("/api/createNewParent"))
        .body({
          firstName,
          lastName,
          email,
          phone,
          password,
          passwordConfirm,
          clubToken
        })

      if (newUserData.status === "success" && newUserData.user) {
        message.success(
          "Success! Check your email for an activation email from us.",
          7,
        )
        setUser(newUserData.user)
        let landingPage = getLandingPageRoute(newUserData.user)
        setLoading(false)
        history.push(landingPage)
        return
      } else {
        message.error("Uh oh, something went wrong...")
      }
    }
    setLoading(false)

    return
  }

  const passwordConfirmKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === "Enter") {
      submit()
    }
  }

  return (
    <RegisterBackground>
      <RegisterContainer>
        <section>
          <div className="top-row">
            <div className="swimmingly-logo-container">
              <Link to="/">
                <img src={swimHeader} width="250px" alt="Swimmingly Header" />
              </Link>
            </div>
            <div />
            <div style={{ alignSelf: "end" }}>
              <div className="user-icon">
                <UserOutlined /> Register
              </div>
            </div>
          </div>
          <RegisterForm>
            <form onSubmit={(e) => e.preventDefault()}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  columnGap: "10px",
                }}
              >
                <Input
                  type="text"
                  style={{ borderRadius: "5px" }}
                  value={firstName}
                  placeholder="First"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    setFirstName(e.target.value)
                  }
                  size="large"
                />
                <Input
                  type="text"
                  style={{ borderRadius: "5px" }}
                  value={lastName}
                  placeholder="Last"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    setLastName(e.target.value)
                  }
                  size="large"
                />
              </div>
              <Input
                type="email"
                name="email"
                id="email"
                style={{ borderRadius: "5px" }}
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  setEmail(e.target.value.toLowerCase().trim())
                }
                placeholder="Email"
                suffix={<UserOutlined />}
                size="large"
              />
              <Input
                type="tel"
                name="phone"
                id="phone"
                style={{ borderRadius: "5px" }}
                value={phone}
                autoComplete="off"
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  setPhone(e.target.value)
                }}
                placeholder="Phone"
                suffix={<PhoneOutlined />}
                size="large"
              />
              {invalidPhone && (
                <div
                  style={{
                    marginTop: "0px",
                    color: "var(--rejectionred)",
                    backgroundColor: "var(--rejectionbackground)",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "1px 10px 1px 10px",
                    border: "1px solid var(--rejectionred)",
                    borderRadius: "4px",
                  }}
                >
                  <span>This is not a valid phone number</span>
                  <CloseCircleOutlined onClick={() => setInvalidPhone(false)} />
                </div>
              )}
              <Input
                type="password"
                name="password"
                id="password"
                style={{ borderRadius: "5px" }}
                placeholder="Password"
                autoComplete="off"
                value={password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  setPassword(e.target.value)
                }
                suffix={<LockOutlined />}
                size="large"
              />
              <Input
                type="password"
                name="password-confirm"
                id="password-confirm"
                style={{ borderRadius: "5px" }}
                placeholder="Confirm Password"
                autoComplete="off"
                value={passwordConfirm}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  setPasswordConfirm(e.target.value)
                }
                onKeyDown={passwordConfirmKeyPress}
                suffix={<CheckCircleOutlined />}
                size="large"
              />
              <Button
                className="form-button"
                loading={loading}
                onClick={submit}
              >
                Create Account
              </Button>
            </form>
          </RegisterForm>
        </section>
      </RegisterContainer>
    </RegisterBackground>
  )
}
