import React, { useCallback, useEffect } from "react"
import { useHistory } from "react-router"
import { useStoreState, useStoreActions, StateMapper } from "easy-peasy"
import { AppDataStore } from "../appData/types"
import { gen, IUser, swimminglyApi } from "./utils"
import _ from "lodash"

export default function ActivateAndLogin({
  activationToken,
  clubToken
}: {
  activationToken: string,
  clubToken?: string|null
}) {
  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)
  const setUser = useStoreActions((actions: AppDataStore) => actions.setUser)

  const history = useHistory()

  const activateUser = useCallback(
    ({
      activationToken,
      user,
      setUser,
    }: {
      activationToken: string
      user: IUser | null
      setUser: (value: IUser | null) => void
    }) => {
      swimminglyApi
        .get(gen(`/api/activateUser/${activationToken}`))
        .then(async (data) => {
          if (
            data.status === "success" &&
            user?.userId &&
            user.userId === data.user?.userId &&
            data.user?.isActive
          ) {
            setUser({ ...user, isActive: true })
            await new Promise((resolve, reject) => setTimeout(resolve, 100))
          }
        })
        .finally(() => {
          if (_.isNil(clubToken)) {
            history.push("/")

          } else {
            history.push(`/clubs/register/${clubToken}`)
          }
        })
    },
    [history],
  )

  useEffect(() => {
    let activateTimer: NodeJS.Timeout | undefined
    if (user) {
      activateUser({ activationToken, user, setUser })
    } else {
      // still try the activation process, but wait first - which gives
      // the app half a second to try to figure out if there is a user
      // from the session cookies
      activateTimer = setTimeout(() => {
        activateUser({ activationToken, user, setUser })
      }, 500)
    }
    return () => {
      if (activateTimer !== undefined) {
        clearTimeout(activateTimer)
      }
    }
  }, [activationToken, user, setUser, history, activateUser])

  return <p>loading...</p>
}
