import React from "react"
import { Modal, Button } from "antd"
import NewWindow from "react-new-window"
import PrintPdf from "./PrintPdf"
import { actionItemStyle } from "./utils"
export default class PrintLineUp extends React.Component {
  constructor(props) {
    super(props)
    this.newWindowRef = React.createRef()
  }
  state = {
    age_start: "",
    age_end: "",
    gender: "all",
    visible: false,
    loading: false,
    showWindow: false,
  }

  handleOk = () => {
    this.setState({ showWindow: true, visible: false })
    this.newWindowRef = React.createRef()
  }

  onClick = () => {
    this.props.closeMenu()
    this.setState({ visible: true })
  }

  render() {
    return (
      <div>
        <span onClick={this.onClick} style={actionItemStyle}>
          Print Line Up By Age Group
        </span>

        <Modal
          visible={this.state.visible}
          title="Print Line Up By Age"
          onCancel={() => this.setState({ visible: false })}
          footer={[
            <Button
              type="default"
              key="Cancel"
              onClick={() => this.setState({ visible: false })}
            >
              Cancel
            </Button>,
            <Button
              type="primary"
              loading={this.state.loading}
              key="Ok"
              onClick={this.handleOk}
            >
              Print Line Up
            </Button>,
          ]}
        >
          <div>
            Set the age group range of the swimmers you would like to see.
            <div>
              <b> Please note: </b>
              If you don't select an age group range it will display all
              swimmers{" "}
            </div>
            <div
              id="ageGroup"
              className="row"
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <div
                className="input-group col-sm-6"
                style={{ marginLeft: "20px" }}
              >
                <span className="input-group-addon">Ages</span>
                <input
                  type="number"
                  id="age_start"
                  name="startAge"
                  className="form-control"
                  value={this.state.age_start}
                  onChange={(e) => this.setState({ age_start: e.target.value })}
                />
                <span className="input-group-addon">to</span>
                <input
                  type="number"
                  id="age_end"
                  name="endAge"
                  className="form-control"
                  value={this.state.age_end}
                  onChange={(e) => this.setState({ age_end: e.target.value })}
                />
              </div>
              <div id="gender" className="col-sm-6">
                <input
                  type="radio"
                  name="gender"
                  value="male"
                  checked={this.state.gender === "male"}
                  onChange={(e) => this.setState({ gender: e.target.value })}
                />{" "}
                Male
                <br />
                <input
                  type="radio"
                  name="gender"
                  value="female"
                  checked={this.state.gender === "female"}
                  onChange={(e) => this.setState({ gender: e.target.value })}
                />{" "}
                Female
                <br />
                <input
                  type="radio"
                  name="gender"
                  value="all"
                  checked={this.state.gender === "all"}
                  onChange={(e) => this.setState({ gender: e.target.value })}
                />{" "}
                All
                <br />
              </div>
            </div>
            <div>
              <b>
                {" "}
                Please note the pdf will be opened in a new browser window.{" "}
                <br />
                You <i>will not</i> be able to continue using the app until you
                have either "Saved" the file or clicked "Cancel"{" "}
              </b>
            </div>
          </div>
        </Modal>
        {this.state.showWindow && (
          /*Say swimmingly in the url*/
          <NewWindow
            title="Lineup"
            ref={this.newWindowRef}
            features={{ width: "40px" }}
          >
            <PrintPdf
              newWindowRef={this.newWindowRef}
              meetId={this.props.meetId}
              clubId={this.props.clubId}
              ageStart={Number(this.state.age_start) || "null"}
              ageEnd={Number(this.state.age_end) || "null"}
              gender={this.state.gender}
              isLineUp={this.props.isLineUp}
            />
          </NewWindow>
        )}
      </div>
    )
  }
}
