import React, { useCallback, useState } from "react"
import { groupBy } from "lodash"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../../appData/types"
import {
  gen,
  formatNumberAsTime,
  convertGender,
  swimminglyApi,
  convertAgeGroupToNumbers,
  formatTimeWithoutLeadingZeros,
  IMeet,
} from "../utils"
import { Select, Table, Checkbox, Modal, Tooltip, message, Radio } from "antd"
import EditMeetEntryTime from "../EditMeetEntryTime"
import EntryHeader from "../EntryHeader"
import IndividualEntryLimitModal from "../IndividualEntryLimitModal"
import RelayEntryLimitModal from "../RelayEntryLimitModal"
import { useEffect } from "react"
import { TMeetEvent } from "."
const { Option } = Select
const { confirm } = Modal

interface IMeetEntryInfo {
  best_time_at_merge: number | null
  club_id: number
  createdBy: number | null
  created_at: string
  date_of_birth: string
  deleted_at: string | null
  event_sort_order: number
  first_name: string
  gender: 1 | 2
  heat_number: number | null
  id: number
  is_exhibition: number | null
  lane_assignement: number | null
  last_name: string
  meet_id: number
  race_type: number
  distance: string
  age_group: string
  event_gender: "Boys" | "Girls" | "Mixed"
  swim_event_id: number
  swimmer_id: number
  team_number: number
  team_position: number
  updated_at: string
  is_relay: 0 | 1
  previousBest: number | null
  converted: boolean
  stroke: string
}

interface ISwimmerInformation {
  dateOfBirth: string
  firstName: string
  gender: 1 | 2
  isActive: boolean
  lastName: string
  middleInitials: string | number
  swimmerId: number
  swimmerShoulderNumber: number | null
  usaSwimmingId: string | null
  leagueAge: number
  guardians: {
    userId: number
    name: string | null
    email: string
    role: number
    isActive: boolean
    phoneNumber: string | null
    leagueId: number | null | undefined
    clubId: number | null | undefined
  }[]
}

export default function SwimmerEntriesTable({
  meetId,
  setViewTab,
  setCurrentEventId,
  refreshSwimmers,
}: {
  meetId: number
  setViewTab: (newTab: "by event" | "by swimmer") => void
  setCurrentEventId: (newId: number) => void
  refreshSwimmers: () => void
}): JSX.Element {
  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)
  const impersonateClub = useStoreState(
    (state: StateMapper<AppDataStore>) => state.aliasedClub,
  )
  const [swimmerArr, setSwimmerArr] = useState<ISwimmerInformation[]>([])
  const [meetEntries, setMeetEntries] = useState<IMeetEntryInfo[]>([])
  const [bestTimes, setBestTimes] = useState<Record<string, Record<string,number>>>()
  const [isEdit, setIsEdit] = useState<number | null>(null)
  const [deletedSwimmers, setDeletedSwimmers] = useState<
    {
      first_name: string
      last_name: string
      middle_initials: string | null
      swimmerId: number
    }[]
  >([])
  const [addedSwimmers, setAddedSwimmers] = useState<
    {
      first_name: string
      last_name: string
      middle_initials: string | null
      swimmerId: number
    }[]
  >([])
  const [individualVisible, setIndividualVisible] = useState(false)
  const [relayVisible, setRelayVisible] = useState(false)
  const [swimmerId, setSwimmerId] = useState<number | null>(null)
  const [eventId, setEventId] = useState<number | null>(null)
  const [meetDeclarationsCloseDate, setMeetDeclarationsCloseDate] =
    useState(null)
  const [individualEntryLimit, setIndividualEntryLimit] = useState(100)
  const [relayEntryLimit, setRelayEntryLimit] = useState(100)
  const [swimEvents, setSwimEvents] = useState<TMeetEvent[]>([])
  const [groupedEventKey, setGroupedEventKey] = useState("")
  const [meet, setMeet] = useState<IMeet | null>(null)
  const [triggerRefreshMeetEntries, setTriggerRefreshMeetEntries] = useState(0)

  useEffect(() => {
    if (meetId) {
      swimminglyApi
        .get(gen(`/api/getMeetInformation/${meetId}`))
        .then((data) => {
          setMeet(data.meet)
          setIndividualEntryLimit(data.meet?.individualLimit)
          setRelayEntryLimit(data.meet?.relayLimit)
        })
    }
  }, [meetId, setMeet])

  useEffect(() => {
    let mounted = true
    if (meetId) {
      swimminglyApi
        .get(gen(`/api/getMeetEventOrder/${meetId}`))
        .then((data: { meetEvents: TMeetEvent[] }) => {
          if (data.meetEvents && data.meetEvents.length > 0) {
            if (mounted) {
              setSwimEvents(data.meetEvents)
              const firstEvent = data.meetEvents[0]
              setGroupedEventKey(`${firstEvent.gender}|${firstEvent.ageGroup}`)
            }
            // setCurrentEventId(data.meetEvents[0].swimEventId)
          }
        })
    }
    return () => {
      mounted = false
    }
  }, [meetId, setSwimEvents])

  const toggleSwimmer = (
    newSwimmerId: number,
    newEventId: number,
    toOn: boolean,
  ) => {
    if (toOn) {
      addSwimmersToMeetWithCheck(newSwimmerId, newEventId)
    } else {
      deleteSwimmerEvent(newSwimmerId, newEventId)
    }
    setTriggerRefreshMeetEntries(triggerRefreshMeetEntries + 1)
  }

  const addSwimmersToMeetWithCheck = (
    newSwimmerId: number,
    newEventId: number,
  ) => {
    let numberOfEntriesRelay = meetEntries
      .filter((el) => !el.is_exhibition)
      .filter((el) => el.swimmer_id === newSwimmerId)
      .filter((el) => el.is_relay).length

    let numberOfEntriesIndividual = meetEntries
      .filter((el) => el.swimmer_id === newSwimmerId)
      .filter((el) => !el.is_exhibition)
      .filter((el) => !el.is_relay).length
    const isRelay =
      swimEvents.find((sE) => sE.swimEventId === newEventId)?.isRelay === 1
    if (
      individualEntryLimit !== null &&
      numberOfEntriesIndividual >= individualEntryLimit &&
      !isRelay
    ) {
      setIndividualVisible(true)
      setRelayVisible(false)
      setSwimmerId(newSwimmerId)
      setEventId(newEventId)
    } else if (
      relayEntryLimit !== null &&
      numberOfEntriesRelay >= relayEntryLimit &&
      isRelay
    ) {
      setRelayVisible(true)
      setIndividualVisible(false)
      setSwimmerId(newSwimmerId)
      setEventId(newEventId)
    } else {
      addSwimmersToMeet(newSwimmerId, newEventId, false)
    }
  }

  const getMeetEntries = useCallback(
    (mountStatus: { mounted: boolean }) => {
      impersonateClub?.clubId &&
        swimminglyApi
          .post(gen("/api/getMeetEntriesBySwimmerForClub"))
          .body({
            meetId: meetId,
            clubId: impersonateClub.clubId,
          })
          .then((data) => {
            if (mountStatus.mounted) setMeetEntries(data.meetEntries)
          })
    },
    [impersonateClub?.clubId, meetId, setMeetEntries],
  )

  const addSwimmersToMeet = useCallback(
    (newSwimmerId: number, newEventId: number, isExhibition: boolean) => {
      impersonateClub?.clubId &&
        swimminglyApi
          .post(gen("/api/addMeetSwimmers2Post"))
          .body({
            clubId: impersonateClub.clubId,
            swimmer_id: newSwimmerId,
            event_id: newEventId,
            isrelay: false,
            meet_id: meetId,
            is_exhibition: isExhibition,
          })
          .then(() => getMeetEntries({ mounted: true }))
    },
    [impersonateClub?.clubId, meetId, getMeetEntries],
  )

  const deleteSwimmerEvent = useCallback(
    (oldSwimmerId: number, oldSwimEventId: number) => {
      impersonateClub?.clubId &&
        swimminglyApi
          .post(gen("/api/removeSwimmerEvent2Post"))
          .body({
            meet_id: meetId,
            swimmer_id: oldSwimmerId,
            swim_event_id: oldSwimEventId,
            isrelay: false,
            clubId: impersonateClub.clubId,
          })
          .then(() => getMeetEntries({ mounted: true }))
    },
    [impersonateClub?.clubId, meetId, getMeetEntries],
  )

  const getClubSwimmers = useCallback(
    (mountStatus: { mounted: boolean }) => {
      impersonateClub?.clubId &&
        swimminglyApi
          .get(
            gen(
              `/api/getClubSeasonSwimmers/${impersonateClub.clubId}?meetId=${meetId}`,
            ),
          )
          .then(({ status, swimmers }) => {
            if (status === "success") {
              const newSwimmerArr = []
              for (let i = 0; i < swimmers.length; i++) {
                const newSwimmer = {
                  ...swimmers[i].swimmer,
                  leagueAge: swimmers[i].swimmerLeagueAge,
                  swimmerShoulderNumber: swimmers[i].swimmerShoulderNumber,
                  guardians: swimmers[i].guardians,
                }
                newSwimmerArr.push(newSwimmer)
              }
              if (mountStatus.mounted) {
                setSwimmerArr(newSwimmerArr)
              }
            } else {
              message.error("Problem finding swimmers")
            }
          })
    },
    [impersonateClub?.clubId, meetId, setSwimmerArr],
  )

  const getAddedClubSwimmers = useCallback(
    (mountStatus: { mounted: boolean }) => {
      impersonateClub?.clubId &&
        swimminglyApi
          .post(gen("/api/getAddedSwimmers2Post"))
          .body({
            meetId: meetId,
            user,
            clubId: impersonateClub.clubId,
          })
          .then((data) => {
            if (mountStatus.mounted) setAddedSwimmers(data.addedSwimmers)
          })
    },
    [impersonateClub?.clubId, meetId, setAddedSwimmers, user],
  )

  const getDeletedClubSwimmers = useCallback(
    (mountStatus: { mounted: boolean }) => {
      impersonateClub?.clubId &&
        swimminglyApi
          .post(gen("/api/getDeletedSwimmers2Post"))
          .body({
            meetId: meetId,
            user,
            clubId: impersonateClub.clubId,
          })
          .then((data) => {
            if (mountStatus.mounted) setDeletedSwimmers(data.deletedSwimmers)
          })
    },
    [impersonateClub?.clubId, meetId, user, setDeletedSwimmers],
  )

  useEffect(() => {
    swimminglyApi
      .get(gen(`/api/getMeetDeclarationsCloseDate/meetId=${meetId}/clubId=${impersonateClub?.clubId}`))
      .then((data) => {
        setMeetDeclarationsCloseDate(data.meetDeclarationsCloseDate)
      })
  }, [meetId])

  useEffect(() => {
    if (impersonateClub) {
      swimminglyApi
      .post(gen("/api/getAllMeetEntries"))
      .body({
        meetId: meetId,
        clubId: impersonateClub.clubId,
      }).then((data) => {
        const meetEntries = data.meetEntries as IMeetEntryInfo[]
        const localBestTimes: Record<string, Record<string,number>> = meetEntries.reduce((accumulatedBestTimes, meetEntry) => {
          const swimmerId = `${meetEntry.swimmer_id}`
          const swimEventId = `${meetEntry.swim_event_id}`
          const bestTime = meetEntry.previousBest
          if (!accumulatedBestTimes[swimmerId]) {
            accumulatedBestTimes[swimmerId] = {} as Record<string,number>
          }
          
          if (bestTime) {
            accumulatedBestTimes[swimmerId][swimEventId] = bestTime
          }
          return accumulatedBestTimes

        }, {} as Record<string,Record<string,number>>)
        
        setBestTimes(localBestTimes)
      })

    }

  }, [meetId, impersonateClub?.clubId])


  useEffect(() => {
    const mountStatus = { mounted: true }
    getClubSwimmers(mountStatus)
    getMeetEntries(mountStatus)
    getDeletedClubSwimmers(mountStatus)
    getAddedClubSwimmers(mountStatus)
    return () => {
      mountStatus.mounted = false
    }
  }, [
    getClubSwimmers,
    getMeetEntries,
    getDeletedClubSwimmers,
    getAddedClubSwimmers,
    triggerRefreshMeetEntries,
  ])

  const renderBestTime2 = (entry: IMeetEntryInfo, alternativeBestTime: number|undefined) => {
    let entryText = entry?.is_exhibition ? "x" : ""
    return (
      <span onClick={() => setIsEdit(entry?.swim_event_id)}>
        {entry?.best_time_at_merge
          ? entryText + formatNumberAsTime(entry.best_time_at_merge)
          : formatNumberAsTime(alternativeBestTime ?? null)}
      </span>
    )
  }

  const addRemoveSwimmerToMeet = useCallback(
    (swimmerId: number) => {
      if (!swimmerId || !meetId) {
        alert("Processing error")
        return
      }

      swimminglyApi
        .post(
          gen(
            `/api/declareSwimmerForMeet?swimmerId=${swimmerId}&meetId=${meetId}`,
          ),
        )
        .body({ isAttending: true })
        .then((data) => {
          if (data.status === "success") {
            message.success("Success!")
            getDeletedClubSwimmers({ mounted: true })
            getAddedClubSwimmers({ mounted: true })
          } else {
            message.error("Uh oh... something went wrong")
          }
        })
        .catch(() => message.error("Uh oh... something went wrong"))
    },
    [meetId, getDeletedClubSwimmers, getAddedClubSwimmers],
  )

  const removeSwimmerFromAllEvents = useCallback(
    (swimmerId: number) => {
      if (!meetId) return

      swimminglyApi
        .post(
          gen(
            `/api/declareSwimmerForMeet?swimmerId=${swimmerId}&meetId=${meetId}`,
          ),
        )
        .body({ isAttending: false })
        .then((data) => {
          if (data.status === "success") {
            message.success("Success!")
            // this.getDeletedClubSwimmers()
            // this.getAddedClubSwimmers()
          } else {
            message.error("Uh oh... something went wrong")
          }
        })
        .catch(() => message.error("Uh oh... something went wrong"))
        .finally(() => {
          getMeetEntries({ mounted: true })
          getDeletedClubSwimmers({ mounted: true })
          getAddedClubSwimmers({ mounted: true })
        })
    },
    [meetId, getDeletedClubSwimmers, getAddedClubSwimmers, getMeetEntries],
  )

  const removeSwimmerFromMeet = useCallback(
    (swimmerId: number) => {
      confirm({
        title: "Remove this swimmer?",
        content:
          "This will remove the swimmer from this meet and all events in this meets lineup.",
        onOk() {
          removeSwimmerFromAllEvents(swimmerId)
        },
      })
    },
    [removeSwimmerFromAllEvents],
  )

  const onChangeValue = useCallback(
    (value: boolean, swimmerId: number) => {
      if (!value) {
        removeSwimmerFromMeet(swimmerId)
      } else {
        addRemoveSwimmerToMeet(swimmerId)
      }
    },
    [removeSwimmerFromMeet, addRemoveSwimmerToMeet],
  )

  const mySort = (
    a: { swimmerId: number; lastName: string },
    b: { swimmerId: number; lastName: string },
  ) => {
    let addedSwimmerIds = addedSwimmers.map((el) => el.swimmerId)
    if (
      addedSwimmerIds.includes(a.swimmerId) &&
      !addedSwimmerIds.includes(b.swimmerId)
    ) {
      return -1
    }
    if (
      !addedSwimmerIds.includes(a.swimmerId) &&
      addedSwimmerIds.includes(b.swimmerId)
    ) {
      return 1
    }
    let deletedSwimmerIds = deletedSwimmers.map((el) => el.swimmerId)
    if (
      deletedSwimmerIds.includes(a.swimmerId) &&
      !deletedSwimmerIds.includes(b.swimmerId)
    ) {
      return 1
    }
    if (
      !deletedSwimmerIds.includes(a.swimmerId) &&
      deletedSwimmerIds.includes(b.swimmerId)
    ) {
      return -1
    }
    if (a.lastName > b.lastName) {
      return 1
    }
    if (a.lastName < b.lastName) {
      return -1
    }
    return 0
  }

  const mySort2 = (a: string, b: string) => {
    let [gender1, activeAgeRange1] = a.split("|")
    let { lower: bottom1, upper: top1 } =
      convertAgeGroupToNumbers(activeAgeRange1)
    let [gender2, activeAgeRange2] = b.split("|")
    let { lower: bottom2, upper: top2 } =
      convertAgeGroupToNumbers(activeAgeRange2)

    if (gender1 < gender2) {
      return -1
    }
    if (gender1 > gender2) {
      return 1
    }
    if (bottom1 < bottom2) {
      return -1
    }
    if (bottom2 < bottom1) {
      return 1
    }
    if (top1 < top2) {
      return -1
    }
    if (top2 > top1) {
      return 1
    }
    return 0
  }

  const handleMixed = (
    groupedEvents: { [key: string]: any },
    mixedEvent: string,
  ) => {
    let mixedEventsList = groupedEvents[mixedEvent]
    let boysEvent = mixedEvent.replace("Mixed|", "Boys|")
    let girlsEvent = mixedEvent.replace("Mixed|", "Girls|")
    if (groupedEvents[boysEvent]) {
      groupedEvents[boysEvent] =
        groupedEvents[boysEvent].concat(mixedEventsList)
    } else {
      groupedEvents[boysEvent] = mixedEventsList
    }

    if (groupedEvents[girlsEvent]) {
      groupedEvents[girlsEvent] =
        groupedEvents[girlsEvent].concat(mixedEventsList)
    } else {
      groupedEvents[girlsEvent] = mixedEventsList
    }
    delete groupedEvents[mixedEvent]
    return groupedEvents
  }

  let groupedEventsHere = groupBy(
    swimEvents,
    (el) => `${el.gender}|${el.ageGroup}`,
  )
  let groupedEvents: { [key: string]: TMeetEvent[] } = JSON.parse(
    JSON.stringify(groupedEventsHere),
  )
  let groupedEventsKeys = Object.keys(groupedEvents)
  for (let i = 0; i < groupedEventsKeys.length; i++) {
    let thisEvent = groupedEventsKeys[i]
    if (thisEvent.includes("Mixed")) {
      groupedEvents = handleMixed(groupedEvents, thisEvent)
    }
  }
  let sortedEvents = Object.keys(groupedEvents).sort(mySort2)
  // groupedEvents = this.handleMixed(groupedEvents, "Mixed|7-8")

  let deletedSwimmerIds = deletedSwimmers.map((el) => el.swimmerId)
  let addedSwimmerIds = addedSwimmers.map((el) => el.swimmerId)

  let finalColumns: {
    title: string
    dataIndex: string
    key: string
    fixed?: string
    width?: number
    render: (some: any, el: any) => any
  }[] = []
  const smallestCommonWidth = 90;
  const smallerColumnWidth = 140;
  const columnWidth = 180;
  let columns = [
    {
      title: "Attending",
      dataIndex: "attending",
      key: "attending",
      fixed: "left",
      width: smallerColumnWidth,
      render: (text: string, swimmer: ISwimmerInformation) => {
        let attendanceValue: boolean | null = null
        if (deletedSwimmerIds.includes(swimmer?.swimmerId)) {
          attendanceValue = false
        }
        if (addedSwimmerIds.includes(swimmer?.swimmerId)) {
          attendanceValue = true
        }
        return (
          <div
            key={`radio_group_${swimmer?.swimmerId}`}
            style={{ display: "grid", gridTemplateColumns: "20px 1fr" }}
          >
            <Radio
              type="radio"
              id={`attending-swimmer-${swimmer?.swimmerId}`}
              checked={attendanceValue === true}
              onChange={(e) => {
                const newVal = e.target.checked
                if (attendanceValue === true && newVal) {
                  e.preventDefault()
                  return
                }
                onChangeValue(newVal, swimmer?.swimmerId)
              }}
            />
            <label htmlFor={`attending-swimmer-${swimmer?.swimmerId}`}>
              Attending
            </label>
            <Radio
              type="radio"
              id={`not-attending-swimmer-${swimmer?.swimmerId}`}
              checked={attendanceValue === false}
              onChange={(e) => {
                const newVal = e.target.checked
                if (attendanceValue === false && !newVal) {
                  e.preventDefault()
                  return
                }
                onChangeValue(!newVal, swimmer?.swimmerId)
              }}
            />
            <label htmlFor={`not-attending-swimmer-${swimmer?.swimmerId}`}>
              Not Attending
            </label>
          </div>
        )
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: smallerColumnWidth,
      render: (text: string, swimmer: ISwimmerInformation) => {
        return (<>{swimmer?.firstName} <br /> {swimmer?.lastName}</>)
      },
    },
    {
      title: "League Age",
      dataIndex: "age",
      key: "age",
      fixed: "left",
      width: smallestCommonWidth,
      render: (some: string, el: ISwimmerInformation) => el.leagueAge,
    },
  ]
  if (groupedEvents[groupedEventKey]) {
    let events = groupedEvents[groupedEventKey]
    let myEvents = events.map((event, evtIdx) => {
      let entries = meetEntries.filter(
        (me) => me.swim_event_id === event.swimEventId,
      )
      return {
        title: (
          <div>
            <div >
              <Tooltip placement="top" title={"Go to event"}>
                <div
                  onClick={() => {
                    setCurrentEventId(event.swimEventId)
                    setViewTab("by event")
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Event #{event.eventNumber}
                </div>
              </Tooltip>
            </div>
            <div>
            {event.gender} {event.ageGroup}
            </div>
            <div>
            {event.distance} {event.stroke} 
            </div>
          </div>
        ),
        dataIndex: "distance|stroke",
        key: `${event.distance} ${event.stroke} ${evtIdx}`,
        width: columnWidth,
        render: (text: string, record: ISwimmerInformation) => {
          let here = entries.filter((me) => me.swimmer_id === record.swimmerId)
          let firstEntry = here[0]
          if (event.isRelay) {
            if (firstEntry && firstEntry.team_number) {
              return (
                <div>
                  Team&nbsp;
                  {String.fromCharCode(64 + Number(firstEntry.team_number))}
                </div>
              )
            } else {
              return ""
            }
          }

          let textVal =
            Boolean(firstEntry) && isEdit === firstEntry.id ? (
              <EditMeetEntryTime
                timeString={
                  firstEntry.best_time_at_merge
                    ? formatTimeWithoutLeadingZeros(
                        firstEntry.best_time_at_merge,
                      )
                    : "NT"
                }
                entryId={firstEntry.id}
                changeEditEntryId={setIsEdit}
                refreshSwimmers={() => getMeetEntries({ mounted: true })}
              />
            ) : (<>
                {renderBestTime2(firstEntry, bestTimes?.[`${record.swimmerId}`]?.[`${event.swimEventId}`] ?? undefined)}
   
              </>)


          return (
            <React.Fragment>
              <Checkbox
                checked={Boolean(firstEntry)}
                onChange={(tester: any) => {
                  toggleSwimmer(
                    record.swimmerId,
                    event.swimEventId,
                    !Boolean(firstEntry),
                  )
                  refreshSwimmers()
                }}
                disabled={deletedSwimmerIds.includes(record?.swimmerId)}
              />{" "}
              {textVal}
              {/* {renderBestTime2(firstEntry)} */}
            </React.Fragment>
          )
        },
      }
    })
    finalColumns = columns.concat(myEvents as any)
  }
  let actualSwimmers = swimmerArr
  if (groupedEventKey) {
    let [gender, activeAgeRange] = groupedEventKey.split("|")
    let convertedGenderVal = convertGender[gender]
    let { lower: bottom, upper: top } = convertAgeGroupToNumbers(activeAgeRange)
    if (bottom !== null) {
      actualSwimmers = actualSwimmers.filter((el) => el.leagueAge >= bottom)
    }

    if (top !== null) {
      actualSwimmers = actualSwimmers.filter((el) => el.leagueAge <= top)
    }

    if (convertedGenderVal === "1" || convertedGenderVal === "2") {
      actualSwimmers = actualSwimmers.filter(
        (el) => el.gender === Number(convertedGenderVal),
      )
    }

    actualSwimmers.sort(mySort)
  }

  return (
    <div>
      <div style={{ marginBottom: "10px" }}>
        <EntryHeader
          date={meetDeclarationsCloseDate}
          isLocked={null}
          meet={
            meet === null
              ? {
                  individualLimit: individualEntryLimit,
                  relayLimit: relayEntryLimit,
                }
              : {
                  ...meet,
                  individualLimit: individualEntryLimit,
                  relayLimit: relayEntryLimit,
                }
          }
        />
      </div>
      <Select
        style={{ width: "300px", marginBottom: "20px" }}
        value={groupedEventKey}
        onChange={setGroupedEventKey}
      >
        {sortedEvents.map((key) => {
          return (
            <Option value={key} key={key}>
              {key.replace("|", " ")}
            </Option>
          )
        })}
      </Select>
      <Table
        rowKey={(record) => record.swimmerId}
        scroll={{ x: `${finalColumns.reduce((totalWidth, currentColumn) => {return totalWidth + (currentColumn.width ?? 0)}, 0)}px`, y: "640px" }}
        rowClassName={(swimmer) => {
          if (deletedSwimmerIds.includes(swimmer.swimmerId)) {
            return "grey"
          }
          if (addedSwimmerIds.includes(swimmer.swimmerId)) {
            return "green"
          }
          return ""
        }}
        dataSource={actualSwimmers}
        columns={finalColumns as any}
        bordered
        pagination={false}
      />
      <IndividualEntryLimitModal
        visible={individualVisible}
        swimmerId={swimmerId || -1}
        eventId={eventId || -1}
        addSwimmersToMeet={addSwimmersToMeet}
        setVisible={setIndividualVisible}
      />
      <RelayEntryLimitModal
        visible={relayVisible}
        swimmerId={swimmerId || -1}
        eventId={eventId || -1}
        addSwimmersToMeet={addSwimmersToMeet}
        setVisible={setRelayVisible}
      />
    </div>
  )
}
