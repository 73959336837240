import React from "react";
import EditUserLeagueModal from "./EditUserLeague";
import { roleMapping, gen, RoleHelper } from "./utils";
import { Modal, Table, Button, message, Tag } from "antd";
import { EditOutlined } from "@ant-design/icons";

const { Column } = Table;

export default class AdminUsersAdmin extends React.Component {
  state = {
    approveUserLoading: false,
    userIdEdit: null,
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    role: 3,
    title: "",
    visible: false,
    loading: false,
  };

  editUser = (user) => {
    let nameSplit = user.name.split(" ");
    this.setState({
      userIdEdit: user.id,
      email: user.email,
      firstName: nameSplit[0],
      lastName: nameSplit.slice(1).join(" "),
      phoneNumber: user.phoneNumber,
      role: String(user.role),
      title: user.title,
      visible: true,
    });
  };

  approveUser = async (userId) => {
    this.setState({ approveUserLoading: true });
    try {
      const response = await fetch(gen("/api/activateThisUserPost"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId: userId }),
      });

      const data = await response.json();
      if (data.status === "error" && /did not get email/.test(data.error)) {
        message.error(data.error);
      } else {
        message.success("Activated user!");
      }
    } catch (error) {
      console.error("Error activating user:", error);
      message.error("Failed to activate the user. Please try again.");
    } finally {
      this.setState({ approveUserLoading: false });
      this.props.getAdminUsers();
    }
  };

  render() {
    return (
      <div>
        <Table
          dataSource={this.props.adminUsers}
          bordered
          loading={this.state.loading}
          rowKey="id"
          size="middle"
        >
          <Column title="Name" dataIndex="name" key="name" />
          <Column title="Email" dataIndex="email" key="email" />
          <Column
            title="Phone Number"
            dataIndex="phoneNumber"
            key="phone_number"
          />
          <Column
            title="Role"
            dataIndex="role"
            key="role"
            render={(role, user) => {
              const roleHelper = new RoleHelper(user.role);
              const roleColor = roleHelper.isClubAdmin()
                ? "purple"
                : roleHelper.isLeagueAdmin()
                ? "cyan"
                : "default";

              return (
                <Tag color={roleColor}>
                  {roleMapping[user.role]}{" "}
                  {user.role === 3 && user.leagueName && `(${user.leagueName})`}
                </Tag>
              );
            }}
          />
          <Column
            title="Status"
            dataIndex="status"
            key="status"
            render={(status, user) => {
              const lastLogin = user.lastLogin || user.updatedAt;
              const lastLoginFormatted = lastLogin
                ? `Last login ${new Date(lastLogin).toLocaleDateString("en-US")}`
                : "Last login unknown";

              const lastLoginStyle = {
                fontSize: "smaller",
                fontStyle: "italic",
                color: "#8898aa",
              };

              let activated = Number(user.isActive); 
              return (
                <span>
                  {activated === 1 ? "Active" : "Inactive"}{" "}
                  <div style={lastLoginStyle}>{lastLoginFormatted}</div>
                  {activated === 0 && (
                    <div>
                      <Button
                        type="default"
                        size="small"
                        onClick={() => this.approveUser(user.id)}
                        loading={this.state.approveUserLoading}
                        style={{
                          backgroundColor: "#369423",
                          color: "white",
                        }}
                      >
                        Approve
                      </Button>
                    </div>
                  )}
                </span>
              );
            }}
          />
          <Column
            title="Actions"
            key="actions"
            render={(user) => {
              return (
                <span>
                  <Button
                    icon={<EditOutlined />}
                    onClick={() => this.editUser(user)}
                  />
                </span>
              );
            }}
          />
        </Table>

        <EditUserLeagueModal
          userIdEdit={this.state.userIdEdit}
          email={this.state.email}
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          phoneNumber={this.state.phoneNumber}
          title={this.state.title}
          user={this.props.user}
          visible={this.state.visible}
          setVisible={(val) => this.setState({ visible: val })}
          setLoading={(val) => this.setState({ loading: val })}
          getAdminUsers={this.props.getAdminUsers}
        />
      </div>
    );
  }
}