import React from "react"
import { Modal, Button, message, Select } from "antd"
import { gen } from "./utils"

const { Option } = Select

const courseMapping = { SCY: 0, SCM: 1, LCM: 2 }
const reverseCourseMapping = { 0: "SCY", 1: "SCM", 2: "LCM" }

const valueNotGiven = (quantity) => {
  if (quantity === null || quantity === undefined) return true
  return false
}

const anyValueNotGiven = (...quantities) => {
  let out = false
  for (let i = 0; i < quantities.length; i++) {
    let quantity = quantities[i]
    let quantityValueNotGiven = valueNotGiven(quantity)
    out = out || quantityValueNotGiven
  }
  return out
}

export default class ChangeMeetCourseModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      newCourse: null,
    }
  }

  componentDidUpdate(prevProps) {
    if (anyValueNotGiven(this.props.currentCourse, this.props.visible)) {
      return
    }
    if (
      prevProps.currentCourse !== this.props.currentCourse ||
      prevProps.visible !== this.props.visible
    ) {
      this.setState({ newCourse: null })
    }
  }

  submitResults = async () => {
    if (
      anyValueNotGiven(this.state.newCourse, this.props.meetId) ||
      this.state.newCourse === ""
    ) {
      message.error(
        "You haven't given us enough information to update the course of the meet!.",
      )
      return
    }
    await this.setState({ loading: true })
    const submit = await fetch(gen("/api/updateMeetCourse"), {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify({
        meetId: this.props.meetId,
        newCourse: courseMapping[this.state.newCourse],
      }),
    })

    const submitOutcome = await submit.json()
    if (submitOutcome.status === "success") {
      message.success("Success!")
    } else {
      message.error("Uh oh, something went wrong!")
    }
    await this.setState({ loading: false })
    this.props.setVisible(false)
    this.props.refreshPage()
  }

  render() {
    return (
      <Modal
        title="Update Meet Course"
        loading={this.state.loading}
        visible={this.props.visible}
        footer={[
          <Button
            key="back"
            onClick={() => {
              this.setState({
                loading: false,
                newCourse: null,
              })
              this.props.setVisible(false)
            }}
          >
            Back
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={this.state.loading}
            onClick={this.submitResults}
          >
            Ok
          </Button>,
        ]}
      >
        You are about to update this meet from{" "}
        {(this.props.currentCourse !== null) &
        (this.props.currentCourse !== undefined)
          ? reverseCourseMapping[this.props.currentCourse]
          : "no course"}{" "}
        to another course. This will NOT update or convert the times in any way,
        it will just correct the course of the meet.
        <br />
        <br />
        Only use this feature if you know that the meet was swum in a different
        course than the one listed here.
        <br />
        <br />
        <div>
          Correct Meet Course:
          <Select
            value={this.state.newCourse || ""}
            onChange={(value) => {
              this.setState({ newCourse: value })
            }}
          >
            <Option value={""}>Select Meet Course</Option>
            <Option value={"SCY"}>SCY</Option>
            <Option value={"SCM"}>SCM</Option>
            <Option value={"LCM"}>LCM</Option>
          </Select>
        </div>
      </Modal>
    )
  }
}
