import React from "react"
// import $ from "jquery"
import CenterSpin from "./CenterSpin"
import { DatePicker, Button, Empty } from "antd"
import { gen, parseTime, convertToOther } from "./utils"
import { groupBy } from "lodash"
import moment from "moment-timezone"
const { RangePicker } = DatePicker

export default class BestTimesCard extends React.Component {
  state = {
    done: false,
    doneSCY: false,
    doneSCM: false,
    bestSwims: [],
    bestSwimsSCY: [],
    bestSwimsSCM: [],

    startDate: null,
    endDate: null,
  }

  componentDidMount() {
    this.searchTimesNew()
  }

  searchTimesNew = async () => {
    let self = this
    await this.setState({
      done: false,
      doneSCY: false,
      doneSCM: false,
    })
    let swimmer_id = this.props.swimmer_id || "null"
    let start = this.state.startDate || "null"
    if (start !== "null") {
      start = start.format("YYYY-MM-DD")
    }
    let end = this.state.endDate || "null"
    if (end !== "null") {
      end = end.format("YYYY-MM-DD")
    }

    let courseEnds = [
      { name: "bestSwims", doneName: "done", course: "null" },
      { name: "bestSwimsSCY", doneName: "doneSCY", course: "0" },
      { name: "bestSwimsSCM", doneName: "doneSCM", course: "1" },
    ]
    console.log("HEY MAN")
    for (let i = 0; i < courseEnds.length; i++) {
      let el = courseEnds[i]

      let thePostData = {
        age_start: "null",
        age_end: "null",
        start: start,
        end: end,
        swimmer_id: swimmer_id,
        course: el.course,
      }

      let response = await fetch(gen("/api/getBestTimes"), {
        method: "post",
        headers: { "Content-Type": "application/json" },
        credentials: "same-origin",
        body: JSON.stringify(thePostData),
      })
      let result = await response.json()
      let bestSwims = result.best_swims

      bestSwims.forEach((x) => {
        x.date = moment.utc(x.date).format("YYYY-MM-DD")
      })
      await self.setState({
        [el.name]: bestSwims,
        [el.doneName]: true,
      })
    }
  }

  getFastest = (arr) => {
    arr.forEach((el) => {
      let actualTime = parseTime(el.better_time)
      el.timeInSCY = convertToOther(actualTime, el.unit_of_measure, 0)
    })
    if (arr.length === 0) {
      return null
    }
    let smallest = arr[0].timeInSCY
    let smallestVal = arr[0]
    for (let i = 0; i < smallest.length; i++) {
      let cur = smallest[i]
      if (cur.timeInSCY < smallest) {
        smallest = cur.timeInSCY
        smallestVal = cur
      }
    }
    return smallestVal
  }

  renderBigTable(bestSwims, isDone) {
    let groupedSwims = groupBy(bestSwims, (el) => el.stroke + el.distance)
    let realBestSwims = Object.values(groupedSwims).map(
      (smallArr) =>
        // this.getFastest(smallArr)
        smallArr.sort((a, b) =>
          a.yardsEquivTime > b.yardsEquivTime ? 1 : -1,
        )[0],
    )

    if (!isDone) {
      return <CenterSpin />
    }

    if (!bestSwims) {
      return null
    }
    if (bestSwims.length === 0) {
      return <Empty />
    }

    realBestSwims = realBestSwims.sort((a, b) => {
      let strokes = [
        "Butterfly",
        "Backstroke",
        "Breaststroke",
        "Freestyle",
        "Individual Medley",
        "Medley Relay",
        "Freestyle Relay",
      ]
      if (parseInt(a.distance) > parseInt(b.distance)) return 1
      else if (strokes.indexOf(a.stroke) > strokes.indexOf(b.stroke)) return 1
      else return -1
    })

    return (
      <table
        className="table table-bordered table-striped mb-none"
        id="datatable-default"
      >
        <thead>
          <tr style={{ backgroundColor: "#25bfea" }}>
            <th>Event</th>
            <th>Time</th>
            <th>Course</th>
            <th>Date</th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {realBestSwims.map((el, inx) => {
            return (
              <tr key={inx}>
                <td> {el.event_name}</td>
                <td> {el.better_time}</td>
                <td> {el.course} </td>
                <td> {el.date}</td>
                <td> {el.meet_name} </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  renderTable = (bestSwims, isDone) => {
    if (!isDone) {
      return <CenterSpin />
    }

    if (!bestSwims) {
      return null
    }
    if (bestSwims.length === 0) {
      return <Empty />
    }

    bestSwims = bestSwims.sort((a, b) => {
      let strokes = [
        "Butterfly",
        "Backstroke",
        "Breaststroke",
        "Freestyle",
        "Individual Medley",
        "Medley Relay",
        "Freestyle Relay",
      ]
      if (parseInt(a.distance) > parseInt(b.distance)) return 1
      else if (strokes.indexOf(a.stroke) > strokes.indexOf(b.stroke)) return 1
      else return -1
    })

    return (
      <table
        className="table table-bordered table-striped mb-none"
        id="datatable-default"
      >
        <thead>
          <tr style={{ backgroundColor: "#25bfea" }}>
            <th>Event</th>
            <th>Time</th>
            <th>Date</th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {bestSwims.map((el, inx) => {
            return (
              <tr key={inx}>
                <td> {el.event_name}</td>
                <td> {el.better_time}</td>
                <td> {el.date}</td>
                <td> {el.meet_name} </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  render() {
    // console.log("Hey")
    let { done, doneSCY, doneSCM, bestSwims, bestSwimsSCY, bestSwimsSCM } =
      this.state

    return (
      <div>
        <h4>Best Times</h4>
        <div className="row">
          <div className="col-sm-12">
            <div className="input-group">
              <div className="col-sm-3 ">Date Range</div>
              <RangePicker
                value={[this.state.startDate, this.state.endDate]}
                onChange={(dates) =>
                  this.setState({ startDate: dates[0], endDate: dates[1] })
                }
              />
            </div>
            <Button
              type="primary"
              onClick={this.searchTimesNew}
              style={{ margin: "10px 10px 10px 0" }}
            >
              Search Times
            </Button>
            <i>*If date range blank, we search all times</i>
          </div>
        </div>

        <div className="form-group ">
          <div style={{ display: "flex" }}>
            <h4>Overall Best Times</h4>
            <div style={{ marginTop: "10px", marginLeft: "20px" }}>
              <i> Includes conversion factor between 25 Yards and 25 Meters </i>
            </div>
          </div>
          {this.renderBigTable(bestSwims, done)}
        </div>
        <div className="form-group ">
          <h4>SCY Best Times</h4>
          {this.renderTable(bestSwimsSCY, doneSCY)}
        </div>
        <div className="form-group ">
          <h4>SCM Best Times</h4>
          {this.renderTable(bestSwimsSCM, doneSCM)}
        </div>
      </div>
    )
  }
}
