import React from "react"
import $ from "jquery"
import moment from "moment-timezone"
import {
  raceTypeArr,
  roleMapping,
  gen,
  convertCourse,
  formatNumberAsTime,
  download,
  actionItemStyle,
  swimminglyApi,
} from "./utils"
import CircleSeedingButton from "./CircleSeedingButton"
import CenterSpin from "./CenterSpin"
import PrintLineUp from "./PrintLineUp"
import UnMergeButton from "./UnMergeButton"
// import OneSidedHeatSheet from "./OneSidedHeatSheet"
import ActionsMenu from "./ActionsMenu"
import HoverListItem from "./HoverListItem"
import { Select, message, Spin, Tag, Divider } from "antd"
import groupBy from "lodash/groupBy"
import generateHeatSheetPDF from "./GenerateHeatSheetPdf"
import { size } from "lodash"
const { Option } = Select

class EventHeat extends React.Component {
  renderHidden = () => {
    return <i>Hidden</i>
  }

  getAge = (dob, meet, single) => {
    if (!dob) {
      return null
    }

    if (this.props.leagueCutOffMonth) {
      let leagueCutOffDate = moment(
        `${moment(this.props.meetInfoArr.meet_date).year()}-${String(
          this.props.leagueCutOffMonth,
        ).padStart(2, "0")}-${String(this.props.leagueCutOffDay).padStart(
          2,
          "0",
        )}`,
      )

      // console.log(swimmerName)
      // console.log("League CutOff Date", leagueCutOffDate.format("YYYY-MM-DD"))
      // console.log("Dob", moment(dob).format("YYYY-MM-DD"))

      return leagueCutOffDate.diff(moment(dob), "years")
    }
    return moment().diff(moment(dob), "years")
  }

  renderSwimmerName = (single) => {
    if (single.is_exhibition === null) {
      return single.swimmer_name
    }
    if (single.is_exhibition !== 0) {
      return "x " + single.swimmer_name
    }
    return single.swimmer_name
  }
  renderSingleHeat = (key, heat, activeClubId, meetLanes) => {
    let actualLanes = Array.from(new Set(meetLanes.map((el) => el.lane_number)))
    // console.log("actualLanes", actualLanes)
    return (
      <React.Fragment key={key}>
        <tr className="nodrop">
          <td>Heat : {key}</td>
        </tr>
        <tr id="horizontalHeader">
          <th style={{ width: "10%" }}>Lane</th>
          <th style={{ width: "24%" }}>Swimmer</th>
          <th style={{ width: "13%" }}>Swimmer Id</th>
          <th style={{ width: "10%" }}>Age</th>
          <th style={{ width: "15" }}>Best Time</th>
          <th style={{ width: "25%" }}>Club</th>
        </tr>
        {actualLanes.map((lane) => {
          let entries = heat[lane]
          if (!entries) {
            return (
              <tr
                id="horizontal"
                data-entry-id={null}
                data-lane={lane}
                data-heat={key}
                data-club-id={null}
                key={`${lane}|${key}`}
              >
                <td>{lane}</td>
                <td>{""}</td>
                <td>{""}</td>
                <td>{""}</td>
                <td>{""}</td>
                <td>{""}</td>
              </tr>
            )
          }
          let singles = Object.values(entries)
          return singles.map((single, indexSingle) => {
            let dob = single.date_of_birth
            let age = this.getAge(dob, single)
            let isVisible = true
            if (!single.club_id) {
              isVisible = true
            } else {
              isVisible = activeClubId ? activeClubId === single.club_id : true
            }
            let swimmerId = single.swimmer_number
            // Deal with the null case
            if (swimmerId) {
              swimmerId = String(swimmerId).padStart(3, "0")
            }
            return (
              <tr
                id="horizontal"
                data-entry-id={single.id}
                data-lane={lane}
                data-heat={key}
                data-club-id={single.club_id}
                key={`${lane}|${single.id}`}
              >
                <td>{indexSingle === 0 ? lane : ""}</td>
                <td>
                  {isVisible
                    ? this.renderSwimmerName(single)
                    : this.renderHidden()}
                </td>
                <td>{isVisible && swimmerId}</td>
                <td>{isVisible && age}</td>
                <td>
                  {isVisible && formatNumberAsTime(single.best_time_at_merge)}
                </td>
                <td>{indexSingle === 0 ? single.clubName : ""}</td>
              </tr>
            )
          })
        })}
      </React.Fragment>
    )
  }

  componentDidUpdate() {
    // console.log("hey man")
    let self = this
    $(".draggableTable tr").draggable({
      start: function (event, ui) {
        $(this).addClass("my_dragged_element")
      },
      stop: function (event, ui) {
        $(this).removeClass("my_dragged_element")
      },
      appendTo: "body",
      helper: "clone",
      cursor: "pointer",
      revert: "invalid",
      distance: 0,
    })
    $(".draggableTable tr").droppable({
      activeClass: "ui-state-default picked_element",
      hoverClass: "bg-change",
      tolerance: "intersect",
      accept: ":not(.ui-sortable-helper)",
      over: function (event, ui) {
        $(".nodrop").droppable("disable")
      },
      drop: function (event, ui) {
        let frmEvent = $(ui.draggable)
        // console.log(frmEvent)
        let frmEntryId = frmEvent.attr("data-entry-id")
        // console.log(frmEntryId)
        let frmHeat = frmEvent.attr("data-heat")
        // console.log(frmHeat)
        let frmLane = frmEvent.attr("data-lane")
        let frmClubId = frmEvent.attr("data-club-id")
        // console.log(frmLane)
        let toEvent = $(this)
        // console.log(toEvent)
        let toEntryId = toEvent.attr("data-entry-id")
        // console.log(toEntryId)
        let toHeat = toEvent.attr("data-heat")
        let toLane = toEvent.attr("data-lane")
        let toClubId = toEvent.attr("data-club-id")
        if (
          !self.props.isAdmin &&
          frmClubId &&
          toClubId &&
          frmClubId !== toClubId
        ) {
          return
        }
        let curEventId = self.props.curEventId
        let returnEntriesStringArr = []
        // console.log(`${frmEntryId},${curEventId},${toLane},${toHeat}`)
        // console.log(`${toEntryId},${curEventId},${frmLane},${frmHeat}`)

        if (frmEntryId && curEventId) {
          returnEntriesStringArr.push(
            `${frmEntryId},${curEventId},${toLane},${toHeat}`,
          )
        }
        if (toEntryId && curEventId) {
          returnEntriesStringArr.push(
            `${toEntryId},${curEventId},${frmLane},${frmHeat}`,
          )
        }
        let returnEntriesString = returnEntriesStringArr.join("|")
        // console.log(returnEntriesString)

        $.ajax({
          url: gen("/api/setMeetEntriesHeatSheetPost"),
          method: "POST",
          dataType: "json",
          data: {
            entriesString: returnEntriesString,
          },
        }).done((data) => {
          self.props.refreshHeat()
          self.props.invalidateFunc()
        })
        $.ajax({
          url: gen(`/api/moveEntriesPost`),
          method: "POST",
          dataType: "json",
          data: { meetId: self.props.meetId },
        }).done(() => {})
      },
    })
  }

  render() {
    let heatSheetView = this.props.heatSheetView
    // console.log("heatSheetView", heatSheetView)
    if (heatSheetView.length === 0) {
      return null
    }

    let keys = Object.keys(heatSheetView)
    // console.log(keys)
    let activeClubId = this.props.oneSided && this.props.clubId
    return (
      <table className="table table-striped draggableTable">
        <thead>
          {keys.map((key) => {
            let heat = heatSheetView[key]
            return this.renderSingleHeat(
              key,
              heat,
              activeClubId,
              this.props.meetLanes,
            )
          })}
        </thead>
      </table>
    )
  }
}

class HeatSheetPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      curEventId: "",
      heatSheetView: [],
      leagueCutOffDay: null,
      leagueCutOffMonth: null,
      loading: false,
      heatsheetLoading: false,
      isSyncing: false,
      /* Actions Menu visible */
      actionsVisible: false,
    }

    this.invalidate = false
  }

  refreshHeat = () => {
    let eventId = this.state.curEventId
    let meet_id = this.props.meetId
    this.setState({ loading: true })

    $.ajax({
      url: gen("/api/getMeetEventsWithHeat4Post"),
      method: "POST",
      dataType: "json",
      data: {
        clubId: this.props.club.clubId,
        event_id: eventId,
        meet_id: meet_id,
      },
    }).done((data) => {
      // console.log(data)
      let entries = data.heatSheetView
      let entriesByHeat = groupBy(entries, "heat_number")

      for (let [key, value] of Object.entries(entriesByHeat)) {
        entriesByHeat[key] = groupBy(value, "lane_assignement")
      }
      console.log()
      this.setState({
        heatSheetView: entriesByHeat,
        leagueCutOffDay: data.leagueCutOffDay,
        leagueCutOffMonth: data.leagueCutOffMonth,
        meetLanes: data.meetLanes,
        loading: false,
      })
    })
  }

  doSomethingBeforeUnload = () => {
    let meet_id = this.props.meetId
    // console.log(this.invalidate, meet_id)
    if (this.invalidate) {
      // console.log("in here")
      $.ajax({
        url: gen("/api/refreshEntriesPost"),
        method: "POST",
        dataType: "json",
        data: {
          meetId: meet_id,
        },
      })
    }
  }

  refreshEntriesNow = () => {
    let meet_id = this.props.meetId
    this.setState({ isSyncing: true })
    // console.log(this.invalidate, meet_id)

    // console.log("in here")
    $.ajax({
      url: gen("/api/refreshEntriesPost"),
      method: "POST",
      dataType: "json",
      data: {
        meetId: meet_id,
      },
    }).done(() => {
      message.success("synced")
      this.setState({ isSyncing: false })
    })
  }

  setupBeforeUnloadListener = () => {
    window.addEventListener("beforeunload", (ev) => {
      ev.preventDefault()
      return this.doSomethingBeforeUnload()
    })
  }

  componentDidMount() {
    let first = this.props.meetEventsArr[0]
    if (first) {
      // this.setGenderAgeAndRelay(first)
      this.setState({ curEventId: first.id }, this.refreshHeat)
    }

    this.setupBeforeUnloadListener()
  }

  onClickNext = () => {
    let found = this.props.meetEventsArr.findIndex(
      (el) => String(el.id) === String(this.state.curEventId),
    )

    if (found !== -1) {
      if (found === this.props.meetEventsArr.length - 1) {
        let event = this.props.meetEventsArr[0]
        // this.setGenderAgeAndRelay(event)
        this.setState({ curEventId: event.id }, this.refreshHeat)
      } else {
        let event = this.props.meetEventsArr[found + 1]
        // this.setGenderAgeAndRelay(event)
        this.setState({ curEventId: event.id }, this.refreshHeat)
      }
    }
  }

  onClickPrev = () => {
    let found = this.props.meetEventsArr.findIndex(
      (el) => String(el.id) === String(this.state.curEventId),
    )

    if (found !== -1) {
      if (found === 0) {
        let event =
          this.props.meetEventsArr[this.props.meetEventsArr.length - 1]
        // this.setGenderAgeAndRelay(event)
        this.setState({ curEventId: event.id }, this.refreshHeat)
      } else {
        let event = this.props.meetEventsArr[found - 1]
        // this.setGenderAgeAndRelay(event)
        this.setState({ curEventId: event.id }, this.refreshHeat)
      }
    }
  }

  onChange = (val) => {
    // let found = this.props.meetEventsArr.find((el) => String(el.id) === e.target.value)

    this.setState({ curEventId: val }, this.refreshHeat)
  }

  downloadHeatSheet = async () => {
    await this.setState({ heatsheetLoading: true })
    let heatSheetResponse
    try {
      heatSheetResponse = await fetch(
        gen(
          `/api/getHeatSheet/meet=${this.props.meetId}/club=${this.props.club.clubId}`,
        ),
        {
          method: "get",
          headers: { "Content-Type": "application/json" },
          credentials: "same-origin",
        },
      )
    } catch (error) {
      console.log(error)
    }
    if (heatSheetResponse) {
      if (
        heatSheetResponse.headers.get("Content-Type") ===
        "application/octet-stream"
      ) {
        let file = await heatSheetResponse.blob()
        await download(file, `HeatSheet.pdf`)
      } else {
        console.log("heatsheet result:")
        console.log(await heatSheetResponse.json())
        message.error("problem downloading heatsheet")
      }
    }
    await this.setState({ heatsheetLoading: false })
  }

  downloadHeatSheetPdf = async (numColumns) => {
    this.setState({ heatsheetLoading: true });
    
    try {
        const response = await fetch(`/api/getHeatSheetJson/meet=${this.props.meetId}/club=${this.props.club.clubId}`, {
            method: "get",
            headers: { "Content-Type": "application/json" },
            credentials: "same-origin",
        });

        if (!response.ok) {
            throw new Error(`Failed to fetch: ${response.status}`);
        }

        const data = await response.json();
        
        //console.log("Fetched data:", data);

        if (!data.heatSheetData || !Array.isArray(data.heatSheetData.events)) {
            throw new Error("Invalid data format: Missing or invalid 'heatSheetData' or 'events'");
        }

        generateHeatSheetPDF(data.heatSheetData, this.props, numColumns);

    } catch (error) {
        console.error('Error fetching heat sheet PDF:', error);
    } finally {
        this.setState({ heatsheetLoading: false });
    }
}


  render() {
    let canChange =
      roleMapping[this.props.user.role] === "Hydro Admin" ||
      roleMapping[this.props.user.role] === "League Admin"

    let leagueAdminOrHydroAdminOrHomeClub =
      roleMapping[this.props.user.role] === "Hydro Admin" ||
      roleMapping[this.props.user.role] === "League Admin" ||
      this.props.meetInfoArr.is_home === this.props.club.clubId

    // console.log(this.props.user.role, roleMapping)
    let keys = Object.keys(this.props.meetEventsArr)
    let oneSided = this.props.meetInfoArr.one_sided_heat_sheet
    // console.log(this.props)
    return (
      <div>
        <header className="page-header">
          <h2>
            {`Heat Sheet ${this.props.meetName} ${
              convertCourse[this.props.meetInfoArr.unit_of_measure]
            } ${moment
              .utc(this.props.meetInfoArr.meet_date)
              .format("MM/DD/YYYY")}`}
          </h2>
        </header>

        <section className="panel">
          <header
            className="panel-heading"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2 className="panel-title">Heat Sheet</h2>
            {this.props.user?.role === 2 && (
              <button
                style={{
                  backgroundColor: "var(--buttonblue)",
                  border: "none",
                  borderRadius: "5px",
                  color: "var(--snow)",
                }}
                onClick={() => {
                  if (this.props.meetInfoArr?.meetId) {
                    swimminglyApi
                      .get(
                        gen(
                          `/api/updateMeetSeedTimes/${this.props.meetInfoArr.meetId}`,
                        ),
                      )
                      .then((data) => {
                        if (data.status === "success") {
                          message.success("updated seed times!")
                          this.refreshHeat()
                        }
                      })
                  } else {
                    message.warning("No meet id", 4)
                  }
                }}
              >
                fix seed times
              </button>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ActionsMenu
                visible={this.state.actionsVisible}
                setVisible={(val) => this.setState({ actionsVisible: val })}
              >
                <React.Fragment>
                  <HoverListItem>
                    {/* This is to test the data call for now. Got rid of the old download heat sheet button that was here*/}
                    <div style={{ display: "column" }}>
                      <Tag style={{ marginBottom: 0, marginTop: 5, marginLeft: 10 }} color="cyan">NEW</Tag >  
                      <p
                        style={{ ...actionItemStyle, marginBottom: 0 }}
                        onClick={() => this.downloadHeatSheetPdf(3)} // 3-column option
                      >
                        Download Heat Sheet (3 Column) 
                      </p>
                      <Spin
                        style={{ marginTop: "7px" }}
                        spinning={this.state.heatsheetLoading}
                      />
                    </div>
                  </HoverListItem>
                  <HoverListItem>
                    {/* This is to test the data call for now. Got rid of the old download heat sheet button that was here*/}
                    <div style={{ display: "column" }}>
                      <Tag style={{ marginBottom: 0, marginTop: 5, marginLeft: 10 }} color="cyan">NEW</Tag >  
                      <p
                        style={{ ...actionItemStyle, marginBottom: 0 }}
                        onClick={() => this.downloadHeatSheetPdf(2)} // 2-column option
                      >
                        Download Heat Sheet (2 Column) 
                      </p>
                      <Spin
                        style={{ marginTop: "7px" }}
                        spinning={this.state.heatsheetLoading}
                      />
                    </div>
                  </HoverListItem>
                  <HoverListItem>
                    <div style={{ display: "flex" }}>
                      <a
                        style={{
                          ...actionItemStyle,
                          marginBottom: 0,
                          color: "var(--black)",
                        }}
                        href={`/api/getHeatSheetHtml/meet=${this.props.meetId}/club=${this.props.club.clubId}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        View Heat sheet (Legacy HTML)
                      </a>
                    </div>
                  </HoverListItem>

                  <HoverListItem>
                    <div
                      onClick={this.refreshEntriesNow}
                      style={{ display: "flex" }}
                    >
                      <p style={{ ...actionItemStyle, marginBottom: 0 }}>
                        Sync heat sheet with SwimminglyFan
                      </p>
                      <Spin
                        style={{ marginTop: "7px" }}
                        spinning={this.state.isSyncing}
                      />
                    </div>
                  </HoverListItem>

                  <HoverListItem>
                    <PrintLineUp
                      meetId={this.props.meetInfoArr.meetId}
                      clubId={this.props.club && this.props.club.clubId}
                      isLineUp={false}
                      closeMenu={() => this.setState({ actionsVisible: false })}
                    />
                  </HoverListItem>

                  {this.props.meetInfoArr.lanesassignemnt === 2 &&
                    this.props.meetInfoArr.laneassigmenttype === 2 && (
                      <HoverListItem>
                        <CircleSeedingButton
                          meet={this.props.meetInfoArr}
                          closeMenu={() =>
                            this.setState({ actionsVisible: false })
                          }
                        />
                      </HoverListItem>
                    )}

                    {leagueAdminOrHydroAdminOrHomeClub && (
                      <HoverListItem>
                        <UnMergeButton
                          meetId={this.props.meetId} 
                          leagueAdminOrHydroAdminOrHomeClub={leagueAdminOrHydroAdminOrHomeClub} />
                      </HoverListItem>
                    )}
                    {!leagueAdminOrHydroAdminOrHomeClub && (
                      <UnMergeButton 
                        meetId={this.props.meetId} 
                        leagueAdminOrHydroAdminOrHomeClub={leagueAdminOrHydroAdminOrHomeClub} />
                    )}


                </React.Fragment>
              </ActionsMenu>
            </div>
          </header>
          <div className="ourpanel-body">
            <div className="row">
              <div className="col-sm-6 col-md-6 col-sm-offset-3">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="col1b prev-event">
                    <div className="event-pager event-pager-prev">
                      <span
                        id="prev"
                        disabled="disabled"
                        onClick={this.onClickPrev}
                      >
                        <span>&lt;</span>
                      </span>
                    </div>
                  </div>
                  <div className="colm event-title">
                    <Select
                      value={this.state.curEventId}
                      onChange={this.onChange}
                      style={{ width: "300px" }}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {keys.map((key) => {
                        let event = this.props.meetEventsArr[key]
                        return (
                          <Option value={event.id} key={key}>
                            {`${Number(key) + 1}: ${event.gender} ${
                              event.age_group
                            } ${event.distance} ${
                              raceTypeArr[event.race_type]
                            }`}
                          </Option>
                        )
                      })}
                    </Select>
                  </div>
                  <div className="col1b next-event">
                    <div className="event-pager event-pager-next">
                      <span id="next" onClick={this.onClickNext}>
                        <span className="next-indicator">&gt;</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ padding: "0px 20px" }}>
              <EventHeat
                heatSheetView={this.state.heatSheetView}
                meetLanes={this.state.meetLanes}
                leagueCutOffMonth={this.state.leagueCutOffMonth}
                leagueCutOffDay={this.state.leagueCutOffDay}
                meetInfoArr={this.props.meetInfoArr}
                clubId={this.props.club && this.props.club.clubId}
                oneSided={oneSided}
                meetId={this.props.meetId}
                curEventId={this.state.curEventId}
                isRelay={false /* Todo */}
                refreshHeat={this.refreshHeat}
                isAdmin={canChange}
                invalidateFunc={() => {
                  this.invalidate = true
                }}
              />
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default class RealHeatSheet extends React.Component {
  state = {
    loaded: false,
  }

  componentDidMount() {
    $.ajax({
      url: gen(`/api/heatSheet2DataPost`),
      method: "POST",
      dataType: "json",
      data: {
        meetId: this.props.meetId,
        clubId: this.props.club.clubId,
        userId: this.props.user.userId,
      },
    }).done((data) => {
      if (data.error) {
        message.error("Not authorized")
      } else {
        this.setState({
          meetEvents: data.meetEvents,
          meet: data.meet,
          meetName: data.meetName,
          loaded: true,
        })
      }
    })
  }

  render() {
    if (!this.state.loaded) {
      return (
        <div>
          <header className="page-header">
            <h2>Heat Sheet</h2>
          </header>

          <section className="panel">
            <header className="panel-heading">
              <h2 className="panel-title">Heat Sheet</h2>
            </header>
            <div className="ourpanel-body">
              <CenterSpin />
            </div>
          </section>
        </div>
      )
    }
    // console.log(this.state, this.props)

    return (
      <HeatSheetPage
        meetInfoArr={this.state.meet}
        meetEventsArr={this.state.meetEvents}
        meetName={this.state.meetName}
        club={this.props.club}
        meetId={this.props.meetId}
        user={this.props.user}
      />
    )
  }
}
