import React, { useState, useEffect, useCallback } from "react"
import { Table, Empty, InputNumber, DatePicker, Radio, Tooltip } from "antd"
import styled from "styled-components"
import moment from "moment-timezone"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../../appData/types"
import {
  formatTimeWithoutLeadingZeros,
  gen,
  ILeague,
  IUser,
  swimminglyApi,
} from "../utils"
import { EventFormStyle } from "./styles"
import { validEvents } from "./hardCodes"

const { RangePicker } = DatePicker

interface IResultRow {
  id: number
  name: string
  league_age: number
  club: string
  meet_date: string
  official_time: number
}

const NumericInput = styled(InputNumber)`
  border-radius: 5px;
  width: 100%;
`

interface IEventFormProps {
  user: IUser | null
  stroke: string
  setStroke: (val: string) => void
  distance: number
  setDistance: (val: number) => void
  course: number
  setCourse: (val: number) => void
  gender: number
  setGender: (val: number) => void
  minAge: number
  setMinAge: (val: number) => void
  maxAge: number
  setMaxAge: (val: number) => void
  startDate: any
  setStartDate: (val: any) => void
  endDate: any
  setEndDate: (val: any) => void
  club: number | undefined
  setClub: (val: number | undefined) => void
  stateId: string | undefined
  setStateId: (val: string | undefined) => void
  useConvertedTimes: boolean
  setUseConvertedTimes: (val: boolean) => void
  bestTimesOnly: boolean
  setBestTimesOnly: (val: boolean) => void
  lifetime: boolean
  setLifetime: (val: boolean) => void
  resultsPerPage: number
  setResultsPerPage: (val: number) => void
  selectedLeague: number
  setSelectedLeague: (value: number) => void
  setLeagueName: (value: string) => void
  meetOrLeagueAge: "meet" | "league"
  setMeetOrLeagueAge: (value: "meet" | "league") => void
  isPatKerrigan: boolean
}

function EventForm({
  user,
  stroke,
  setStroke,
  distance,
  setDistance,
  course,
  setCourse,
  gender,
  setGender,
  minAge,
  setMinAge,
  maxAge,
  setMaxAge,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  club,
  setClub,
  stateId,
  setStateId,
  useConvertedTimes,
  setUseConvertedTimes,
  bestTimesOnly,
  setBestTimesOnly,
  lifetime,
  setLifetime,
  resultsPerPage,
  setResultsPerPage,
  selectedLeague,
  setSelectedLeague,
  setLeagueName,
  meetOrLeagueAge,
  setMeetOrLeagueAge,
  isPatKerrigan,
}: IEventFormProps) {
  const [leaguesList, setLeaguesList] = useState<Array<ILeague>>([])
  const [eventOptions, setEventOptions] =
    useState<
      {
        distance: number
        stroke: string
        course: string
        courseValue: number
      }[]
    >(validEvents)

  useEffect(() => {
    let newEventOptions = validEvents

    if (stroke !== "none")
      newEventOptions = newEventOptions.filter((e) => e.stroke === stroke)
    if (distance > 0)
      newEventOptions = newEventOptions.filter((e) => e.distance === distance)
    if (course !== -1)
      newEventOptions = newEventOptions.filter((e) => e.courseValue === course)
    setEventOptions(newEventOptions)
  }, [stroke, distance, course])

  useEffect(() => {
    swimminglyApi
      .get(gen(`/api/getUsersLeagues/${user?.userId || 0}`))
      .then((data) => {
        let leaguesArr: Array<ILeague> = data.leaguesArr
        leaguesArr.sort((league1, league2) =>
          league1.name > league2.name ? 1 : -1,
        )
        setLeaguesList(leaguesArr)
        if (user?.role === 2) setSelectedLeague(0)
        else if (leaguesArr.length > 0) setSelectedLeague(leaguesArr[0].id)
      })
  }, [user?.userId, user?.role, setSelectedLeague])

  const resetFilters = useCallback(() => {
    setStroke("none")
    setDistance(0)
    setCourse(-1)
  }, [setCourse, setDistance, setStroke])

  return (
    <EventFormStyle>
      <div className="event-form-container">
        <h2>
          <Tooltip title="As a league admin, you have early access to the Swimmingly® Leaderboard for your league. Utilize this feature anytime for leaderboards of any event across your entire league!">
            <span>Swimmingly Leaderboard (Beta)</span>
          </Tooltip>
        </h2>
        <hr />
        {(isPatKerrigan || user?.role === 2) && (
          <button
            style={{ width: "100%", marginBottom: "10px" }}
            onClick={() => {
              setLifetime(!!!lifetime)
              setSelectedLeague(-1)
            }}
          >
            {lifetime ? "View by League" : "View All Life Time"}
          </button>
        )}
        {!lifetime && (
          <select
            className="leaderboard-mode-selector"
            value={selectedLeague}
            onChange={(e) => {
              const leagueId = parseInt(e.target.value)
              const theLeague = leaguesList.find(
                (league) => league.id === leagueId,
              )
              setSelectedLeague(leagueId)
              setLeagueName(theLeague?.name || "")
            }}
          >
            <option
              className="leaderboard-mode-option"
              value={-1}
              disabled
              hidden
            >
              Choose Leaderboard Mode
            </option>
            <option
              className="leaderboard-mode-option"
              value={0}
              disabled={user?.role !== 2}
            >
              National Leaderboard (not yet available)
            </option>
            {leaguesList.map((league) => {
              return (
                <option
                  className="leaderboard-mode-option"
                  value={league.id}
                  key={`league_${league.id}`}
                >
                  {league.name} Leaderboard
                </option>
              )
            })}
          </select>
        )}
        <div className="event-form">
          <div>
            <h4>Event Info</h4>
            <p>what event are you looking for?</p>
            <button
              className="reset-button large-screen"
              onClick={resetFilters}
            >
              Reset Filters
            </button>
          </div>
          <form>
            <label htmlFor="course">Course</label>
            <select
              id="course"
              value={course}
              onChange={(e) => setCourse(parseInt(e.target.value))}
            >
              <option value={-1}>Course...</option>
              {eventOptions
                .map((eO) => `${eO.course}|${eO.courseValue}`)
                .filter((v, i, a) => a.indexOf(v) === i)
                .map((courseOption) => {
                  const course = courseOption.split("|")[0]
                  const courseValue = parseInt(courseOption.split("|")[1])
                  return (
                    <option value={courseValue} key={course}>
                      {course}
                    </option>
                  )
                })}
            </select>
            <label htmlFor="distance">Distance</label>
            <select
              id="distance"
              value={distance}
              onChange={(e) => setDistance(parseInt(e.target.value))}
            >
              <option value={-1}>Distance...</option>
              {eventOptions
                .map((eO) => eO.distance)
                .filter((v, i, a) => a.indexOf(v) === i)
                .map((dist) => (
                  <option value={dist} key={dist}>
                    {dist}
                  </option>
                ))}
            </select>
            <label htmlFor="stroke">Stroke</label>
            <select
              id="stroke"
              value={stroke}
              onChange={(e) => setStroke(e.target.value)}
            >
              <option value="none">Stroke...</option>
              {eventOptions
                .map((eO) => eO.stroke)
                .filter((v, i, a) => a.indexOf(v) === i)
                .map((str) => (
                  <option value={str} key={str}>
                    {str}
                  </option>
                ))}
            </select>
            <button
              className="reset-button small-screen"
              onClick={(e) => {
                e.preventDefault()
                resetFilters()
              }}
            >
              Reset Filters
            </button>
          </form>
        </div>
        <hr />
        <div className="event-form">
          <div>
            <h4>Swimmer Info</h4>
            <p>who do you want to compare?</p>
          </div>
          <form>
            <label htmlFor="gender">Gender</label>
            <select
              id="gender"
              value={gender}
              onChange={(e) => setGender(parseInt(e.target.value))}
            >
              <option value={-1}>Gender...</option>
              <option value={1}>Girls</option>
              <option value={2}>Boys</option>
              <option value={3}>All</option>
            </select>
            <div className="age-range">
              <div style={{ marginRight: "4px" }}>
                <label htmlFor="min-age">Min Age:</label>
                <NumericInput
                  id="minAge"
                  min={0}
                  max={Math.min(maxAge, 100)}
                  // defaultValue={8}
                  value={minAge}
                  onChange={(val) => setMinAge(val as number)}
                />
              </div>
              <div style={{ marginLeft: "4px" }}>
                <label htmlFor="max-age">Max Age:</label>
                <NumericInput
                  id="maxAge"
                  min={Math.max(1, minAge)}
                  max={200}
                  // defaultValue={18}
                  value={maxAge}
                  onChange={(val) => setMaxAge(val as number)}
                />
              </div>
            </div>
            {selectedLeague > 0 ? (
              <p style={{ color: "grey", textAlign: "center" }}>
                Your leaderboard ages are based upon your league's age cutoff
                date
              </p>
            ) : (
              <p style={{ color: "grey", textAlign: "center" }}>
                The national leaderboard ages are based upon swimmer's age on
                the day of the meet.
              </p>
            )}
          </form>
        </div>
        <hr />
        <div className="event-form">
          <div>
            <h4>Other Info</h4>
            <p>choose the date range and other customized options</p>
          </div>
          <form>
            <label htmlFor="date-range">Start and End Date:</label>
            <RangePicker
              style={{ borderRadius: "5px" }}
              value={[startDate, endDate]}
              ranges={{
                "This Week": [moment().startOf("week"), moment()],
                "This Month": [moment().startOf("month"), moment()],
                "This Year": [moment().startOf("year"), moment()],
              }}
              onChange={(value) => {
                if (value && value.length > 1 && value[0] && value[1]) {
                  setStartDate(moment.min([value[0], moment()]))
                  setEndDate(moment.min([value[1], moment()]))
                } else if (value && value.length > 0 && value[0]) {
                  setStartDate(moment.min([value[0], moment()]))
                  setEndDate(moment())
                } else {
                  return
                }
              }}
            />
            <label>
              results per page
              <Radio.Group
                onChange={(e) => setResultsPerPage(e.target.value)}
                value={resultsPerPage}
              >
                <Radio value={10}>10</Radio>
                <Radio value={25}>25</Radio>
                <Radio value={50}>50</Radio>
                <Radio value={5000}>All</Radio>
              </Radio.Group>
            </label>
          </form>
        </div>
      </div>
    </EventFormStyle>
  )
}

export default function Leaderboard() {
  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)
  const [stroke, setStroke] = useState("none")
  const [distance, setDistance] = useState(0)
  const [minAge, setMinAge] = useState(0)
  const [maxAge, setMaxAge] = useState(100)
  const [gender, setGender] = useState(0)
  const [course, setCourse] = useState(-1)
  const [club, setClub] = useState<number | undefined>(undefined)
  const [stateId, setStateId] = useState<string | undefined>(undefined)
  const [useConvertedTimes, setUseConvertedTimes] = useState(false)
  const [startDate, setStartDate] = useState(moment().subtract(10, "week"))
  const [endDate, setEndDate] = useState(moment())
  const [bestTimesOnly, setBestTimesOnly] = useState(true)
  const [topTimes, setTopTimes] = useState<Array<IResultRow>>([])
  const [lifetime, setLifetime] = useState<boolean>(false)
  const [resultsPerPage, setResultsPerPage] = useState<number>(10)
  const [selectedLeague, setSelectedLeague] = useState(-1)
  const [leagueName, setLeagueName] = useState("")
  const [meetOrLeagueAge, setMeetOrLeagueAge] =
    useState<"meet" | "league">("league")
  const [isPatKerrigan, setIsPatKerrigan] = useState(false)

  useEffect(() => {
    if (user?.email === "pkerrigan@lt.life" || user?.email === "jrezac@lt.life" ) setIsPatKerrigan(true)
  }, [user?.email])

  // refresh the data when they search for something new
  useEffect(() => {
    fetch(gen("/api/getLeaderboardTimes"), {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify({
        stroke,
        distance,
        minAge,
        maxAge,
        gender,
        course,
        league: selectedLeague,
        club,
        stateId,
        useConvertedTimes,
        startDate,
        endDate,
        lifetime,
      }),
    })
      .then((response) => response.json())
      .then(({ results }: { results: Array<IResultRow> }) => {
        setTopTimes(results)
      })
  }, [
    stroke,
    distance,
    minAge,
    maxAge,
    gender,
    course,
    club,
    stateId,
    useConvertedTimes,
    startDate,
    endDate,
    lifetime,
    selectedLeague,
  ])

  return (
    <div>
      <header className="page-header">
        <h2>Leaderboard</h2>
      </header>
      <EventForm
        user={user}
        stroke={stroke}
        setStroke={setStroke}
        distance={distance}
        setDistance={setDistance}
        course={course}
        setCourse={setCourse}
        gender={gender}
        setGender={setGender}
        minAge={minAge}
        setMinAge={setMinAge}
        maxAge={maxAge}
        setMaxAge={setMaxAge}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        club={club}
        setClub={setClub}
        stateId={stateId}
        setStateId={setStateId}
        useConvertedTimes={useConvertedTimes}
        setUseConvertedTimes={setUseConvertedTimes}
        bestTimesOnly={bestTimesOnly}
        setBestTimesOnly={setBestTimesOnly}
        lifetime={lifetime}
        setLifetime={setLifetime}
        resultsPerPage={resultsPerPage}
        setResultsPerPage={setResultsPerPage}
        selectedLeague={selectedLeague}
        setSelectedLeague={setSelectedLeague}
        setLeagueName={setLeagueName}
        meetOrLeagueAge={meetOrLeagueAge}
        setMeetOrLeagueAge={setMeetOrLeagueAge}
        isPatKerrigan={isPatKerrigan}
      />
      {course !== -1 && stroke !== "none" && distance > 0 && gender !== 0 && (
        <div>
          <div>
            {selectedLeague > 0 && (
              <h3 style={{ textAlign: "center", marginBottom: "10px" }}>
                {leagueName}
              </h3>
            )}
            <h3 style={{ textAlign: "center" }}>
              {gender === 1 ? "Girls " : gender === 2 ? "Boys " : ""}
              {minAge}-{maxAge}{" "}
              {course === 0 ? "SCY" : course === 1 ? "SCM" : "LCM"} {distance}{" "}
              {stroke}
            </h3>
          </div>
          <div
            style={{
              float: "left",
              padding: "5px 8px 2px 8px",
              borderTop: "1px solid lightgrey",
              borderLeft: "1px solid lightgrey",
              borderRight: "1px solid lightgrey",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
            }}
          >
            <input type="checkbox" disabled style={{ marginRight: "5px" }} />
            <label style={{ color: "grey" }}>
              verified times only (not yet available)
            </label>
          </div>
        </div>
      )}
      <Table
        rowKey={(record) => record.id}
        locale={{
          emptyText: <Empty description={<span>no swims...</span>} />,
        }}
        dataSource={topTimes}
        pagination={{ pageSize: resultsPerPage }}
        columns={[
          { title: "Rank", dataIndex: "swimmerRank", key: "swimmerRank" },
          { title: "Swimmer", dataIndex: "name", key: "name" },
          {
            title: "Age",
            dataIndex: selectedLeague > 0 ? "league_age" : "meet_age",
            key: "age",
          },
          { title: "Club", dataIndex: "club", key: "club" },
          { title: "Meet", dataIndex: "meetName", key: "meetName" },
          { title: "Date", dataIndex: "meet_date", key: "date" },
          {
            title: "Time",
            dataIndex: "official_time",
            key: "time",
            render: (text, record) =>
              formatTimeWithoutLeadingZeros(record.official_time),
          },
        ]}
      />
    </div>
  )
}
