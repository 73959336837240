import { useState, useEffect } from "react"
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom"
import { createBrowserHistory } from "history"
import Analytics from "react-router-ga"
import { message } from "antd"
import {
  useStoreState,
  StoreProvider,
  useStoreActions,
  StateMapper,
} from "easy-peasy"
import { AppDataStore } from "./appData/types"
import store from "./appData/store"
import LoginWithRouter from "./components/LoginPage"
import ActivateAndLoginWithRouter from "./components/ActivateAndLoginPage"
import ClubLeagueLogin from "./components/ClubLeagueLogin"
import PasswordResetEmail from "./components/PasswordResetEmail"
import PasswordReset from "./components/PasswordReset"
import NotFound from "./components/NotFound"
import ActivateThisUser from "./components/ActivateThisUser"
import InAppRoutes from "./components/InAppRoutes"
import GlobalStyles from "./styles/GlobalStyles"
import { CustomEventEmitter, gen, swimminglyApi } from "./components/utils"
import CenterSpin from "./components/CenterSpin"
import RegisterParentForm from "./components/RegisterParentForm"
import ClubSwimmerRegistration from "./components/ClubSwimmerRegistration"
import { Wrapper } from "@googlemaps/react-wrapper";
import { googleMapsServerApiKey } from "./components/utils"
// Possibly only conditionally import TestRoute
// above when we are in development

import "./App.css"
import "./styles/nprogress.css"
import NationalChampsUserRegistration from "./components/NationalChampsUserRegistration"
export const seasonPrepModalListener = new CustomEventEmitter()

declare global {
  interface Window {
    Appcues: any
    AppcuesWidget: (input: any) => any
    google: any
    _wq: any
  }
}

export interface ISeasonDetails {
  clubId: number
  seasonId: number
  clubName: string
  seasonSetupConfirmedBy: number | null
  seasonSetupConfirmedOn: string | null
  isSwimminglyCustomer: 0 | 1 | null
  corporatePaymentId: number | null
  clubPays: number | null
  parentPays: number | null
  seasonName: string
  startDate: string
  endDate: string
}

function App() {
  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)
  const setUser = useStoreActions((actions: AppDataStore) => actions.setUser)
  const setImpersonateClub = useStoreActions(
    (actions: AppDataStore) => actions.setAliasedClub,
  )
  const acceptedCookies = useStoreState(
    (state: StateMapper<AppDataStore>) => state.acceptedCookies,
  )
  const setAcceptedCookies = useStoreActions(
    (actions: AppDataStore) => actions.setAcceptedCookies,
  )
  const windowHistory = createBrowserHistory()
  const history = useHistory()
  const [forceLogout, setForceLogout] = useState(false)

  windowHistory.listen((location, action) => {
    window.Appcues.page()
  })

  useEffect(() => {
    if (user?.role === 2) message.success(`Welcome back ${user?.email}`)
  }, [user?.role, user?.email])

  useEffect(() => {
    if (!acceptedCookies) {
      swimminglyApi.get(gen("/api/whoami")).then((data) => {
        if (data && data.acceptedCookies === true) {
          setAcceptedCookies(true)
        }
        if (!data || !data.user || !data.acceptedCookies) {
          const shouldShowLogin = (
            window.location.pathname !== "/registerUser" &&
            window.location.pathname !== "/registerUser/2021NationalChamps" &&
            window.location.pathname !== "/registerParent" &&
            window.location.pathname !== "/passwordReset" &&
            !/^\/outside\//.test(window.location.pathname) &&
            !/^\/password\/reset\//.test(window.location.pathname) &&
            !/^\/activate\//.test(window.location.pathname) &&
            !/^\/activateThisUser\//.test(window.location.pathname) &&
            !/^\/clubs\/register\//.test(window.location.pathname)
          )

          if (shouldShowLogin) {
            history.push("/login")
          }
          return
        } else {
          const pathName = window.location.pathname
          setUser(data.user)
          if (/^\/outside\//.test(`${pathName}`)) {
            //establishes the convention that any front end route that begins with /outside will authenticate and then push the route onto the history stack
            if (data.club) {
              setImpersonateClub(data.club)
            }
            history.push(pathName)
          } else if (data.club) {
            setImpersonateClub(data.club)
            return
          } else {
            history.push("/")
            return
          }
        }
      })
    }
  }, [
    acceptedCookies,
    history,
    setAcceptedCookies,
    setImpersonateClub,
    setUser,
  ])
  
  if (
    !user &&
    window.location.pathname !== "/" &&
    window.location.pathname !== "/login" &&
    window.location.pathname !== "/registerUser/2021NationalChamps" &&
    window.location.pathname !== "/registerUser" &&
    window.location.pathname !== "/registerParent" &&
    window.location.pathname !== "/passwordReset" &&
    !/^\/outside\//.test(window.location.pathname) &&
    !/^\/password\/reset\//.test(window.location.pathname) &&
    !/^\/activate\//.test(window.location.pathname) &&
    !/^\/activateThisUser\//.test(window.location.pathname) &&
    !/^\/clubs\/register\//.test(window.location.pathname)
  ) {
    if (forceLogout) {
      return <Redirect to="/login" />
    }
    setTimeout(() => setForceLogout(true), 200)
    return (
      <div style={{ paddingTop: "200px", margin: "auto" }}>
        <CenterSpin />
      </div>
    )
  }

  return (
    <div>
      <Switch>
        {/* Public Routes */}
        <Route exact path="/" key="home" render={() => <LoginWithRouter /> } />
        <Route
          exact
          path="/login"
          key="login"
          render={() => <LoginWithRouter />}
        />
        <Route
          path="/activate/:activationToken"
          key="home"
          render={(props) => {
            const clubToken = new URLSearchParams(props.location.search).get("clubToken")
            return (
              <ActivateAndLoginWithRouter
                {...props}
                activationToken={props.match.params.activationToken}
                clubToken={clubToken}
              />
            )
          }}
        />
        <Route
          exact
          path="/registerUser/2021NationalChamps"
          key="registerUser"
          render={() => {
            return <NationalChampsUserRegistration />
          }}
        />
        <Route
          exact
          path="/registerUser"
          key="registerUser"
          render={(props) => {
            return (
              <ClubLeagueLogin
                {...props}
                initialLeagueId={null}
                initialClubId={null}
                initialEmail={null}
                currentStep={null}
                active={false}
              />
            )
          }}
        />
        <Route
          exact
          path="/registerParent"
          key="registerParent"
          render={() => {
            return <RegisterParentForm />
          }}
        />
        <Route
          exact
          path="/registerUser/:leagueId/:clubId/:email"
          key="registerUser2"
          render={(props) => {
            let curStep: string = "3a"
            let leagueId: string = props.match.params.leagueId
            let clubId: string = props.match.params.clubId
            let email: string = props.match.params.email
            let active: boolean = true
            if (leagueId.toLowerCase() === "null") {
              curStep = "3b"
            }
            if (clubId.toLowerCase() === "null") {
              curStep = "3a"
            }
            return (
              <ClubLeagueLogin
                {...props}
                initialLeagueId={parseInt(leagueId || "0")}
                initialClubId={parseInt(clubId || "0")}
                initialEmail={email}
                currentStep={curStep}
                active={active}
              />
            )
          }}
        />
  
        <Route
          exact
          path="/activateThisUser/:userId"
          key="activateThisUser"
          render={(props) => {
            let userIdString: string = props.match.params.userId
            let userId: number | null
            if (!userIdString || userIdString) {
              userId = null
            } else if (!/^[0-9]+$/.test(userIdString)) {
              userId = null
            } else {
              userId = parseInt(userIdString)
            }
            return <ActivateThisUser userId={userId} />
          }}
        />
        <Route
          exact
          path="/passwordReset"
          key="passwordReset"
          render={() => {
            return <PasswordResetEmail />
          }}
        />
        <Route
          exact
          path="/password/reset/:token"
          key="passwordReset2"
          render={(props) => {
            let token: string = props.match.params.token
            return <PasswordReset token={token} />
          }}
        />
        <Route
          exact
          path="/clubs/register/:superSecretToken"
          key="superSecretLink"
          render={(props) => {
            const token: string = props.match.params.superSecretToken
            return <ClubSwimmerRegistration token={token} />
          }}
        />

        <Route 
          path="/outside/declarations/club/:clubId/meetLineUp/:meetId"
          render={(props: any) => {
            const redirectURL = `/app/parent/viewMeetLineUp/${props.match.params.meetId}/${props.match.params.clubId}`
            return  user ? <Redirect to={redirectURL} /> : <LoginWithRouter targetURL={redirectURL} />
          }}
          />

        <Route path="/app" component={InAppRoutes} />

        <Route component={NotFound} />
      </Switch>
      
    </div>
  )
}

export default function AppWithData() {
  return ( 
    <StoreProvider store={store}>
      <BrowserRouter>
        <GlobalStyles />
        <Analytics id="UA-157604144-1">
          <Wrapper apiKey={googleMapsServerApiKey} libraries={["places"]}> 
          <App />
        </Wrapper>
        </Analytics>
      </BrowserRouter>
    </StoreProvider>
  )
}
