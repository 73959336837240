import React from "react"
import { Spin, Tag } from "antd"
import { doubleMetaphone } from "double-metaphone"
import { stemmer } from "stemmer"
import { IUser } from "./sharedDataTypes"
import moment from "moment"
import _ from "lodash"

const darragh = {
  firstName: "Darragh",
  lastName: "Mahns",
  title: "Customer Success Associate",
  favoriteEvent: "100 Back",
}

const hayes = {
  firstName: "Hayes",
  lastName: "Johnson",
  title: "Swimmingly President",
  favoriteEvent: "200 Back",
}

const jason = {
  firstName: "Jason",
  lastName: "Deana",
  title: "Swimmingly Sales Manager",
  favoriteEvent: "50 Free",
}

const aysia = {
  firstName: "Aysia",
  lastName: "Leckie",
  title: "Customer Success Manager",
  favoriteEvent: "100 Fly",
}

export const swimminglyAssociates = [aysia, darragh, hayes, jason]

export const roleMapping: { [key: number]: string } = {
  2: "Hydro Admin",
  3: "League Admin",
  4: "Club Admin",
  5: "Parent",
}

export class RoleHelper {

  private role?: number
  private adminRoles = new Set([2,3,4])

  public constructor(role?: number) {
    this.role = role
  }

  isAnyAdmin = (): boolean => {
    return this.role ? this.adminRoles.has(this.role) : false
  }
  isSwimminglyAdmin = (): boolean => {
    return this.role === 2
  }
  isLeagueAdmin = (): boolean => {
    return this.role === 3
  }
  isClubAdmin = (): boolean => {
    return this.role === 4
  }
  isGuardian = (): boolean => {
    return this.role === 5
  }
  roleName = (): string => {
    return this.role ? roleMapping[this.role] : ""
  }  

}


export const titleMapping = {
  HeadCoach: "Head Coach",
  MeetCentralRep: "Meet Central Rep",
  SwimminglyRep: "SwimminglyRep",
  "League Board": "League Board",
  ClubTreasure: "Club Treasurer",
  "Assistant Coach": "Assistant Coach",
  LeaguePresident: "League President",
  LeagueTreasurer: "League Treasurer",
}

export const titleMap = new Map<string, string>()
titleMap.set("HeadCoach", "Head Coach")
titleMap.set("MeetCentralRep", "Meet Central Rep")
titleMap.set("SwimminglyRep", "SwimminglyRep")
titleMap.set("League Board", "League Board")
titleMap.set("ClubTreasure", "Club Treasurer")
titleMap.set("Assistant Coach", "Assistant Coach")
titleMap.set("LeaguePresident", "League President")
titleMap.set("LeagueTreasurer", "League Treasurer")

export const raceTypeArr: { [key: number]: string } = {
  0: "Medley Relay",
  1: "Freestyle",
  2: "Backstroke",
  3: "Breaststroke",
  4: "Butterfly",
  5: "Freestyle Relay",
  6: "Individual Medley",
  7: "Butterfly Relay",
  8: "Backstroke Relay",
  9: "Breaststroke Relay",
  10: "Fun",
  11: "Diving",
}

export const convertCourse: { [key: number]: string } = {
  0: "SCY",
  1: "SCM",
  2: "LCM",
}

export const ageGroupArr: { [key: number]: string } = {
  0: "Select",
  1: "Open",
  2: "3",
  3: "4",
  4: "5",
  5: "5-18",
  6: "5-6",
  7: "6",
  8: "6 & Under",
  9: "6-7",
  10: "6-8",
  11: "7",
  12: "7 & Over",
  13: "7 & Under",
  14: "7-12",
  15: "7-8",
  16: "7-9",
  17: "8",
  18: "8 & Under",
  19: "8-11",
  20: "8-9",
  21: "9",
  22: "9-10",
  23: "9-18",
  24: "10",
  25: "10 & Over",
  26: "10 & Under",
  27: "10-11",
  28: "10-12",
  29: "11",
  30: "11 & Over",
  31: "11 & Under",
  32: "11-12",
  33: "11-13",
  34: "11-14",
  35: "12",
  36: "12 & Over",
  37: "12 & Under",
  38: "12 & Under",
  39: "12-13",
  40: "12-15",
  41: "13",
  42: "13 & Over",
  43: "13 & Under",
  44: "13-14",
  45: "13-15",
  46: "13-16",
  47: "13-18",
  48: "14",
  49: "14 & Over",
  50: "14 & Under",
  51: "14-15",
  52: "14-18",
  53: "15",
  54: "15 & Over",
  55: "15-16",
  56: "15-17",
  57: "15-18",
  58: "16",
  59: "16 & Over",
  60: "16-17",
  61: "16-20",
  62: "17",
  63: "17 & Under",
  64: "17-18",
  65: "17-19",
  66: "17 & Over",
  67: "18",
  68: "18 & Over",
  69: "18 & Under",
  70: "18-19",
  71: "19-25",
  72: "19-29",
  73: "20 & Over",
  74: "21 & Over",
  75: "26-35",
  76: "30-39",
  77: "36-50",
  78: "40-49",
  79: "50-59",
  80: "51-64",
  81: "60-69",
  82: "65 & Over",
  83: "70 & Over",
  84: "70-79",
  85: "80 & Over",
}

// Possibly delete this one because it is a copy, but has different
// empty string case
export const meetAgeGroupArr = {
  "": "Select",
  1: "Open",
  2: "3",
  3: "4",
  4: "5",
  5: "5-18",
  6: "5-6",
  7: "6",
  8: "6 & Under",
  9: "6-7",
  10: "6-8",
  11: "7",
  12: "7 & Over",
  13: "7 & Under",
  14: "7-12",
  15: "7-8",
  16: "7-9",
  17: "8",
  18: "8 & Under",
  19: "8-11",
  20: "8-9",
  21: "9",
  22: "9-10",
  23: "9-18",
  24: "10",
  25: "10 & Over",
  26: "10 & Under",
  27: "10-11",
  28: "10-12",
  29: "11",
  30: "11 & Over",
  31: "11 & Under",
  32: "11-12",
  33: "11-13",
  34: "11-14",
  35: "12",
  36: "12 & Over",
  37: "12 & Under",
  38: "12-13",
  39: "12-15",
  40: "13",
  41: "13 & Over",
  42: "13 & Under",
  43: "13-14",
  44: "13-15",
  45: "13-16",
  46: "13-18",
  47: "14",
  48: "14 & Over",
  49: "14 & Under",
  50: "14-15",
  51: "14-18",
  52: "15",
  53: "15 & Over",
  54: "15-16",
  55: "15-17",
  56: "15-18",
  57: "16",
  58: "16 & Over",
  59: "16-17",
  60: "16-20",
  61: "17",
  62: "17 & Under",
  63: "17-18",
  64: "17-19",
  65: "17 & Over",
  66: "18",
  67: "18 & Over",
  68: "18 & Under",
  69: "18-19",
  70: "19-25",
  71: "19-29",
  72: "20 & Over",
  73: "21 & Over",
  74: "26-35",
  75: "30-39",
  76: "36-50",
  77: "40-49",
  78: "50-59",
  79: "51-64",
  80: "60-69",
  81: "65 & Over",
  82: "70 & Over",
  83: "70-79",
  84: "80 & Over",
}

export const convertAgeGroupToAgeRange = (ageGroup: string): string => {
  let AG: string = ageGroup.trim()
  if (AG.toLowerCase() === "all ages" || AG.toLowerCase() === "open") {
    return "UNOV"
  } else if (AG.toLowerCase() === "qqq" || AG.toLowerCase() === "qqqq") {
    return AG
  }

  interface IOutAgeGroup {
    minAge?: string
    maxAge?: string
  }

  let out: IOutAgeGroup = {}
  if (/^\d*$/.test(AG)) {
    out.minAge = AG.padStart(2, "0")
    out.maxAge = AG.padStart(2, "0")
  } else if (/^\d*\s*-\s*\d*$/.test(AG)) {
    let ages = AG.split(/\s*-\s*/)
    out.minAge = ages[0].padStart(2, "0")
    out.maxAge = ages[1].padStart(2, "0")
  } else if (/^\d*\s*&\s*(u|U)nder$/.test(AG)) {
    let ages = AG.split(/\s*&\s*/)
    out.minAge = "UN"
    out.maxAge = ages[0].padStart(2, "0")
  } else if (/^\d*\s*&\s*(o|O)ver$/.test(AG)) {
    let ages = AG.split(/\s*&\s*/)
    out.minAge = ages[0].padStart(2, "0")
    out.maxAge = "OV"
  }
  return `${out.minAge}${out.maxAge}`
}

export const convertAgeGroupToNumbers = (
  ageGroup: string,
): { lower: number; upper: number } => {
  const formattedAgeGroup = convertAgeGroupToAgeRange(ageGroup)
  const lowerString = formattedAgeGroup.slice(0, 2)
  const upperString = formattedAgeGroup.slice(2)

  let lower = 0
  let upper = 200

  if (/^[0-9]+$/.test(lowerString)) lower = parseInt(lowerString)
  if (/^[0-9]+$/.test(upperString)) upper = parseInt(upperString)

  return { lower, upper }
}

export const genderTypeArr: { [key: string]: string } = {
  "": "Select",
  Boys: "Boys",
  Girls: "Girls",
  Mixed: "Mixed",
}

export const distanceArr: { [key: number]: string } = {
  0: "Select",
  1: "1",
  3: "3",
  12: "12",
  15: "15",
  25: "25",
  50: "50",
  75: "75",
  100: "100",
  125: "125",
  150: "150",
  175: "175",
  200: "200",
  300: "300",
  400: "400",
  500: "500",
  800: "800",
  1000: "1000",
  1500: "1500",
  1650: "1650",
}

export const momentTimezones = [
  "Africa/Abidjan",
  "Africa/Accra",
  "Africa/Addis_Ababa",
  "Africa/Algiers",
  "Africa/Asmara",
  "Africa/Asmera",
  "Africa/Bamako",
  "Africa/Bangui",
  "Africa/Banjul",
  "Africa/Bissau",
  "Africa/Blantyre",
  "Africa/Brazzaville",
  "Africa/Bujumbura",
  "Africa/Cairo",
  "Africa/Casablanca",
  "Africa/Ceuta",
  "Africa/Conakry",
  "Africa/Dakar",
  "Africa/Dar_es_Salaam",
  "Africa/Djibouti",
  "Africa/Douala",
  "Africa/El_Aaiun",
  "Africa/Freetown",
  "Africa/Gaborone",
  "Africa/Harare",
  "Africa/Johannesburg",
  "Africa/Juba",
  "Africa/Kampala",
  "Africa/Khartoum",
  "Africa/Kigali",
  "Africa/Kinshasa",
  "Africa/Lagos",
  "Africa/Libreville",
  "Africa/Lome",
  "Africa/Luanda",
  "Africa/Lubumbashi",
  "Africa/Lusaka",
  "Africa/Malabo",
  "Africa/Maputo",
  "Africa/Maseru",
  "Africa/Mbabane",
  "Africa/Mogadishu",
  "Africa/Monrovia",
  "Africa/Nairobi",
  "Africa/Ndjamena",
  "Africa/Niamey",
  "Africa/Nouakchott",
  "Africa/Ouagadougou",
  "Africa/Porto-Novo",
  "Africa/Sao_Tome",
  "Africa/Timbuktu",
  "Africa/Tripoli",
  "Africa/Tunis",
  "Africa/Windhoek",
  "America/Adak",
  "America/Anchorage",
  "America/Anguilla",
  "America/Antigua",
  "America/Araguaina",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Catamarca",
  "America/Argentina/ComodRivadavia",
  "America/Argentina/Cordoba",
  "America/Argentina/Jujuy",
  "America/Argentina/La_Rioja",
  "America/Argentina/Mendoza",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Salta",
  "America/Argentina/San_Juan",
  "America/Argentina/San_Luis",
  "America/Argentina/Tucuman",
  "America/Argentina/Ushuaia",
  "America/Aruba",
  "America/Asuncion",
  "America/Atikokan",
  "America/Atka",
  "America/Bahia",
  "America/Bahia_Banderas",
  "America/Barbados",
  "America/Belem",
  "America/Belize",
  "America/Blanc-Sablon",
  "America/Boa_Vista",
  "America/Bogota",
  "America/Boise",
  "America/Buenos_Aires",
  "America/Cambridge_Bay",
  "America/Campo_Grande",
  "America/Cancun",
  "America/Caracas",
  "America/Catamarca",
  "America/Cayenne",
  "America/Cayman",
  "America/Chicago",
  "America/Chihuahua",
  "America/Coral_Harbour",
  "America/Cordoba",
  "America/Costa_Rica",
  "America/Creston",
  "America/Cuiaba",
  "America/Curacao",
  "America/Danmarkshavn",
  "America/Dawson",
  "America/Dawson_Creek",
  "America/Denver",
  "America/Detroit",
  "America/Dominica",
  "America/Edmonton",
  "America/Eirunepe",
  "America/El_Salvador",
  "America/Ensenada",
  "America/Fort_Nelson",
  "America/Fort_Wayne",
  "America/Fortaleza",
  "America/Glace_Bay",
  "America/Godthab",
  "America/Goose_Bay",
  "America/Grand_Turk",
  "America/Grenada",
  "America/Guadeloupe",
  "America/Guatemala",
  "America/Guayaquil",
  "America/Guyana",
  "America/Halifax",
  "America/Havana",
  "America/Hermosillo",
  "America/Indiana/Indianapolis",
  "America/Indiana/Knox",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Tell_City",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indianapolis",
  "America/Inuvik",
  "America/Iqaluit",
  "America/Jamaica",
  "America/Jujuy",
  "America/Juneau",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Knox_IN",
  "America/Kralendijk",
  "America/La_Paz",
  "America/Lima",
  "America/Los_Angeles",
  "America/Louisville",
  "America/Lower_Princes",
  "America/Maceio",
  "America/Managua",
  "America/Manaus",
  "America/Marigot",
  "America/Martinique",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Mendoza",
  "America/Menominee",
  "America/Merida",
  "America/Metlakatla",
  "America/Mexico_City",
  "America/Miquelon",
  "America/Moncton",
  "America/Monterrey",
  "America/Montevideo",
  "America/Montreal",
  "America/Montserrat",
  "America/Nassau",
  "America/New_York",
  "America/Nipigon",
  "America/Nome",
  "America/Noronha",
  "America/North_Dakota/Beulah",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/Ojinaga",
  "America/Panama",
  "America/Pangnirtung",
  "America/Paramaribo",
  "America/Phoenix",
  "America/Port-au-Prince",
  "America/Port_of_Spain",
  "America/Porto_Acre",
  "America/Porto_Velho",
  "America/Puerto_Rico",
  "America/Punta_Arenas",
  "America/Rainy_River",
  "America/Rankin_Inlet",
  "America/Recife",
  "America/Regina",
  "America/Resolute",
  "America/Rio_Branco",
  "America/Rosario",
  "America/Santa_Isabel",
  "America/Santarem",
  "America/Santiago",
  "America/Santo_Domingo",
  "America/Sao_Paulo",
  "America/Scoresbysund",
  "America/Shiprock",
  "America/Sitka",
  "America/St_Barthelemy",
  "America/St_Johns",
  "America/St_Kitts",
  "America/St_Lucia",
  "America/St_Thomas",
  "America/St_Vincent",
  "America/Swift_Current",
  "America/Tegucigalpa",
  "America/Thule",
  "America/Thunder_Bay",
  "America/Tijuana",
  "America/Toronto",
  "America/Tortola",
  "America/Vancouver",
  "America/Virgin",
  "America/Whitehorse",
  "America/Winnipeg",
  "America/Yakutat",
  "America/Yellowknife",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville",
  "Antarctica/Macquarie",
  "Antarctica/Mawson",
  "Antarctica/McMurdo",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/South_Pole",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "Arctic/Longyearbyen",
  "Asia/Aden",
  "Asia/Almaty",
  "Asia/Amman",
  "Asia/Anadyr",
  "Asia/Aqtau",
  "Asia/Aqtobe",
  "Asia/Ashgabat",
  "Asia/Ashkhabad",
  "Asia/Atyrau",
  "Asia/Baghdad",
  "Asia/Bahrain",
  "Asia/Baku",
  "Asia/Bangkok",
  "Asia/Barnaul",
  "Asia/Beirut",
  "Asia/Bishkek",
  "Asia/Brunei",
  "Asia/Calcutta",
  "Asia/Chita",
  "Asia/Choibalsan",
  "Asia/Chongqing",
  "Asia/Chungking",
  "Asia/Colombo",
  "Asia/Dacca",
  "Asia/Damascus",
  "Asia/Dhaka",
  "Asia/Dili",
  "Asia/Dubai",
  "Asia/Dushanbe",
  "Asia/Famagusta",
  "Asia/Gaza",
  "Asia/Harbin",
  "Asia/Hebron",
  "Asia/Ho_Chi_Minh",
  "Asia/Hong_Kong",
  "Asia/Hovd",
  "Asia/Irkutsk",
  "Asia/Istanbul",
  "Asia/Jakarta",
  "Asia/Jayapura",
  "Asia/Jerusalem",
  "Asia/Kabul",
  "Asia/Kamchatka",
  "Asia/Karachi",
  "Asia/Kashgar",
  "Asia/Kathmandu",
  "Asia/Katmandu",
  "Asia/Khandyga",
  "Asia/Kolkata",
  "Asia/Krasnoyarsk",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Asia/Kuwait",
  "Asia/Macao",
  "Asia/Macau",
  "Asia/Magadan",
  "Asia/Makassar",
  "Asia/Manila",
  "Asia/Muscat",
  "Asia/Nicosia",
  "Asia/Novokuznetsk",
  "Asia/Novosibirsk",
  "Asia/Omsk",
  "Asia/Oral",
  "Asia/Phnom_Penh",
  "Asia/Pontianak",
  "Asia/Pyongyang",
  "Asia/Qatar",
  "Asia/Qyzylorda",
  "Asia/Rangoon",
  "Asia/Riyadh",
  "Asia/Saigon",
  "Asia/Sakhalin",
  "Asia/Samarkand",
  "Asia/Seoul",
  "Asia/Shanghai",
  "Asia/Singapore",
  "Asia/Srednekolymsk",
  "Asia/Taipei",
  "Asia/Tashkent",
  "Asia/Tbilisi",
  "Asia/Tehran",
  "Asia/Tel_Aviv",
  "Asia/Thimbu",
  "Asia/Thimphu",
  "Asia/Tokyo",
  "Asia/Tomsk",
  "Asia/Ujung_Pandang",
  "Asia/Ulaanbaatar",
  "Asia/Ulan_Bator",
  "Asia/Urumqi",
  "Asia/Ust-Nera",
  "Asia/Vientiane",
  "Asia/Vladivostok",
  "Asia/Yakutsk",
  "Asia/Yangon",
  "Asia/Yekaterinburg",
  "Asia/Yerevan",
  "Atlantic/Azores",
  "Atlantic/Bermuda",
  "Atlantic/Canary",
  "Atlantic/Cape_Verde",
  "Atlantic/Faeroe",
  "Atlantic/Faroe",
  "Atlantic/Jan_Mayen",
  "Atlantic/Madeira",
  "Atlantic/Reykjavik",
  "Atlantic/South_Georgia",
  "Atlantic/St_Helena",
  "Atlantic/Stanley",
  "Australia/ACT",
  "Australia/Adelaide",
  "Australia/Brisbane",
  "Australia/Broken_Hill",
  "Australia/Canberra",
  "Australia/Currie",
  "Australia/Darwin",
  "Australia/Eucla",
  "Australia/Hobart",
  "Australia/LHI",
  "Australia/Lindeman",
  "Australia/Lord_Howe",
  "Australia/Melbourne",
  "Australia/NSW",
  "Australia/North",
  "Australia/Perth",
  "Australia/Queensland",
  "Australia/South",
  "Australia/Sydney",
  "Australia/Tasmania",
  "Australia/Victoria",
  "Australia/West",
  "Australia/Yancowinna",
  "Brazil/Acre",
  "Brazil/DeNoronha",
  "Brazil/East",
  "Brazil/West",
  "CET",
  "CST6CDT",
  "Canada/Atlantic",
  "Canada/Central",
  "Canada/Eastern",
  "Canada/Mountain",
  "Canada/Newfoundland",
  "Canada/Pacific",
  "Canada/Saskatchewan",
  "Canada/Yukon",
  "Chile/Continental",
  "Chile/EasterIsland",
  "Cuba",
  "EET",
  "EST",
  "EST5EDT",
  "Egypt",
  "Eire",
  "Etc/GMT",
  "Etc/GMT+0",
  "Etc/GMT+1",
  "Etc/GMT+10",
  "Etc/GMT+11",
  "Etc/GMT+12",
  "Etc/GMT+2",
  "Etc/GMT+3",
  "Etc/GMT+4",
  "Etc/GMT+5",
  "Etc/GMT+6",
  "Etc/GMT+7",
  "Etc/GMT+8",
  "Etc/GMT+9",
  "Etc/GMT-0",
  "Etc/GMT-1",
  "Etc/GMT-10",
  "Etc/GMT-11",
  "Etc/GMT-12",
  "Etc/GMT-13",
  "Etc/GMT-14",
  "Etc/GMT-2",
  "Etc/GMT-3",
  "Etc/GMT-4",
  "Etc/GMT-5",
  "Etc/GMT-6",
  "Etc/GMT-7",
  "Etc/GMT-8",
  "Etc/GMT-9",
  "Etc/GMT0",
  "Etc/Greenwich",
  "Etc/UCT",
  "Etc/UTC",
  "Etc/Universal",
  "Etc/Zulu",
  "Europe/Amsterdam",
  "Europe/Andorra",
  "Europe/Astrakhan",
  "Europe/Athens",
  "Europe/Belfast",
  "Europe/Belgrade",
  "Europe/Berlin",
  "Europe/Bratislava",
  "Europe/Brussels",
  "Europe/Bucharest",
  "Europe/Budapest",
  "Europe/Busingen",
  "Europe/Chisinau",
  "Europe/Copenhagen",
  "Europe/Dublin",
  "Europe/Gibraltar",
  "Europe/Guernsey",
  "Europe/Helsinki",
  "Europe/Isle_of_Man",
  "Europe/Istanbul",
  "Europe/Jersey",
  "Europe/Kaliningrad",
  "Europe/Kiev",
  "Europe/Kirov",
  "Europe/Lisbon",
  "Europe/Ljubljana",
  "Europe/London",
  "Europe/Luxembourg",
  "Europe/Madrid",
  "Europe/Malta",
  "Europe/Mariehamn",
  "Europe/Minsk",
  "Europe/Monaco",
  "Europe/Moscow",
  "Europe/Nicosia",
  "Europe/Oslo",
  "Europe/Paris",
  "Europe/Podgorica",
  "Europe/Prague",
  "Europe/Riga",
  "Europe/Rome",
  "Europe/Samara",
  "Europe/San_Marino",
  "Europe/Sarajevo",
  "Europe/Saratov",
  "Europe/Simferopol",
  "Europe/Skopje",
  "Europe/Sofia",
  "Europe/Stockholm",
  "Europe/Tallinn",
  "Europe/Tirane",
  "Europe/Tiraspol",
  "Europe/Ulyanovsk",
  "Europe/Uzhgorod",
  "Europe/Vaduz",
  "Europe/Vatican",
  "Europe/Vienna",
  "Europe/Vilnius",
  "Europe/Volgograd",
  "Europe/Warsaw",
  "Europe/Zagreb",
  "Europe/Zaporozhye",
  "Europe/Zurich",
  "GB",
  "GB-Eire",
  "GMT",
  "GMT+0",
  "GMT-0",
  "GMT0",
  "Greenwich",
  "HST",
  "Hongkong",
  "Iceland",
  "Indian/Antananarivo",
  "Indian/Chagos",
  "Indian/Christmas",
  "Indian/Cocos",
  "Indian/Comoro",
  "Indian/Kerguelen",
  "Indian/Mahe",
  "Indian/Maldives",
  "Indian/Mauritius",
  "Indian/Mayotte",
  "Indian/Reunion",
  "Iran",
  "Israel",
  "Jamaica",
  "Japan",
  "Kwajalein",
  "Libya",
  "MET",
  "MST",
  "MST7MDT",
  "Mexico/BajaNorte",
  "Mexico/BajaSur",
  "Mexico/General",
  "NZ",
  "NZ-CHAT",
  "Navajo",
  "PRC",
  "PST8PDT",
  "Pacific/Apia",
  "Pacific/Auckland",
  "Pacific/Bougainville",
  "Pacific/Chatham",
  "Pacific/Chuuk",
  "Pacific/Easter",
  "Pacific/Efate",
  "Pacific/Enderbury",
  "Pacific/Fakaofo",
  "Pacific/Fiji",
  "Pacific/Funafuti",
  "Pacific/Galapagos",
  "Pacific/Gambier",
  "Pacific/Guadalcanal",
  "Pacific/Guam",
  "Pacific/Honolulu",
  "Pacific/Johnston",
  "Pacific/Kiritimati",
  "Pacific/Kosrae",
  "Pacific/Kwajalein",
  "Pacific/Majuro",
  "Pacific/Marquesas",
  "Pacific/Midway",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Norfolk",
  "Pacific/Noumea",
  "Pacific/Pago_Pago",
  "Pacific/Palau",
  "Pacific/Pitcairn",
  "Pacific/Pohnpei",
  "Pacific/Ponape",
  "Pacific/Port_Moresby",
  "Pacific/Rarotonga",
  "Pacific/Saipan",
  "Pacific/Samoa",
  "Pacific/Tahiti",
  "Pacific/Tarawa",
  "Pacific/Tongatapu",
  "Pacific/Truk",
  "Pacific/Wake",
  "Pacific/Wallis",
  "Pacific/Yap",
  "Poland",
  "Portugal",
  "ROC",
  "ROK",
  "Singapore",
  "Turkey",
  "UCT",
  "US/Alaska",
  "US/Aleutian",
  "US/Arizona",
  "US/Central",
  "US/East-Indiana",
  "US/Eastern",
  "US/Hawaii",
  "US/Indiana-Starke",
  "US/Michigan",
  "US/Mountain",
  "US/Pacific",
  "US/Pacific-New",
  "US/Samoa",
  "UTC",
  "Universal",
  "W-SU",
  "WET",
  "Zulu",
]

export const utcArr = {
  "": "Please choose",
  "Dateline Standard Time": "(UTC-12:00) International Date Line West",
  "UTC-11": "(UTC-11:00) Coordinated Universal Time-11",
  "Aleutian Standard Time": "(UTC-10:00) Aleutian Islands",
  "Hawaiian Standard Time": "(UTC-10:00) Hawaii",
  "Marquesas Standard Time": "(UTC-09:30) Marquesas Islands",
  "Alaskan Standard Time": "(UTC-09:00) Alaska",
  "UTC-09": "(UTC-09:00) Coordinated Universal Time-09",
  "Pacific Standard Time (Mexico)": "(UTC-08:00) Baja California",
  "UTC-08": "(UTC-08:00) Coordinated Universal Time-08",
  "Pacific Standard Time": "(UTC-08:00) Pacific Time (US & Canada)",
  "US Mountain Standard Time": "(UTC-07:00) Arizona",
  "Mountain Standard Time (Mexico)": "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
  "Mountain Standard Time": "(UTC-07:00) Mountain Time (US & Canada)",
  "Central America Standard Time": "(UTC-06:00) Central America",
  "Central Standard Time": "(UTC-06:00) Central Time (US & Canada)",
  "Easter Island Standard Time": "(UTC-06:00) Easter Island",
  "Central Standard Time (Mexico)":
    "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
  "Canada Central Standard Time": "(UTC-06:00) Saskatchewan",
  "SA Pacific Standard Time": "(UTC-05:00) Bogota, Lima, Quito, Rio Branco",
  "Eastern Standard Time (Mexico)": "(UTC-05:00) Chetumal",
  "Eastern Standard Time": "(UTC-05:00) Eastern Time (US & Canada)",
  "Haiti Standard Time": "(UTC-05:00) Haiti",
  "Cuba Standard Time": "(UTC-05:00) Havana",
  "US Eastern Standard Time": "(UTC-05:00) Indiana (East)",
  "Paraguay Standard Time": "(UTC-04:00) Asuncion",
  "Atlantic Standard Time": "(UTC-04:00) Atlantic Time (Canada)",
  "Venezuela Standard Time": "(UTC-04:00) Caracas",
  "Central Brazilian Standard Time": "(UTC-04:00) Cuiaba",
  "SA Western Standard Time":
    "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
  "Pacific SA Standard Time": "(UTC-04:00) Santiago",
  "Turks And Caicos Standard Time": "(UTC-04:00) Turks and Caicos",
  "Newfoundland Standard Time": "(UTC-03:30) Newfoundland",
  "Tocantins Standard Time": "(UTC-03:00) Araguaina",
  "E. South America Standard Time": "(UTC-03:00) Brasilia",
  "SA Eastern Standard Time": "(UTC-03:00) Cayenne, Fortaleza",
  "Argentina Standard Time": "(UTC-03:00) City of Buenos Aires",
  "Greenland Standard Time": "(UTC-03:00) Greenland",
  "Montevideo Standard Time": "(UTC-03:00) Montevideo",
  "Saint Pierre Standard Time": "(UTC-03:00) Saint Pierre and Miquelon",
  "Bahia Standard Time": "(UTC-03:00) Salvador",
  "UTC-02": "(UTC-02:00) Coordinated Universal Time-02",
  "Mid-Atlantic Standard Time": "(UTC-02:00) Mid-Atlantic - Old",
  "Azores Standard Time": "(UTC-01:00) Azores",
  "Cape Verde Standard Time": "(UTC-01:00) Cabo Verde Is.",
  UTC: "(UTC) Coordinated Universal Time",
  "Morocco Standard Time": "(UTC+00:00) Casablanca",
  "GMT Standard Time": "(UTC+00:00) Dublin, Edinburgh, Lisbon, London",
  "Greenwich Standard Time": "(UTC+00:00) Monrovia, Reykjavik",
  "W. Europe Standard Time":
    "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
  "Central Europe Standard Time":
    "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
  "Romance Standard Time": "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
  "Central European Standard Time":
    "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
  "W. Central Africa Standard Time": "(UTC+01:00) West Central Africa",
  "Namibia Standard Time": "(UTC+01:00) Windhoek",
  "Jordan Standard Time": "(UTC+02:00) Amman",
  "GTB Standard Time": "(UTC+02:00) Athens, Bucharest",
  "Middle East Standard Time": "(UTC+02:00) Beirut",
  "Egypt Standard Time": "(UTC+02:00) Cairo",
  "E. Europe Standard Time": "(UTC+02:00) Chisinau",
  "Syria Standard Time": "(UTC+02:00) Damascus",
  "West Bank Standard Time": "(UTC+02:00) Gaza, Hebron",
  "South Africa Standard Time": "(UTC+02:00) Harare, Pretoria",
  "FLE Standard Time":
    "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
  "Turkey Standard Time": "(UTC+02:00) Istanbul",
  "Israel Standard Time": "(UTC+02:00) Jerusalem",
  "Kaliningrad Standard Time": "(UTC+02:00) Kaliningrad",
  "Libya Standard Time": "(UTC+02:00) Tripoli",
  "Arabic Standard Time": "(UTC+03:00) Baghdad",
  "Arab Standard Time": "(UTC+03:00) Kuwait, Riyadh",
  "Belarus Standard Time": "(UTC+03:00) Minsk",
  "Russian Standard Time": "(UTC+03:00) Moscow, St. Petersburg, Volgograd",
  "E. Africa Standard Time": "(UTC+03:00) Nairobi",
  "Iran Standard Time": "(UTC+03:30) Tehran",
  "Arabian Standard Time": "(UTC+04:00) Abu Dhabi, Muscat",
  "Astrakhan Standard Time": "(UTC+04:00) Astrakhan, Ulyanovsk",
  "Azerbaijan Standard Time": "(UTC+04:00) Baku",
  "Russia Time Zone 3": "(UTC+04:00) Izhevsk, Samara",
  "Mauritius Standard Time": "(UTC+04:00) Port Louis",
  "Georgian Standard Time": "(UTC+04:00) Tbilisi",
  "Caucasus Standard Time": "(UTC+04:00) Yerevan",
  "Afghanistan Standard Time": "(UTC+04:30) Kabul",
  "West Asia Standard Time": "(UTC+05:00) Ashgabat, Tashkent",
  "Ekaterinburg Standard Time": "(UTC+05:00) Ekaterinburg",
  "Pakistan Standard Time": "(UTC+05:00) Islamabad, Karachi",
  "India Standard Time": "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
  "Sri Lanka Standard Time": "(UTC+05:30) Sri Jayawardenepura",
  "Nepal Standard Time": "(UTC+05:45) Kathmandu",
  "Central Asia Standard Time": "(UTC+06:00) Astana",
  "Bangladesh Standard Time": "(UTC+06:00) Dhaka",
  "N. Central Asia Standard Time": "(UTC+06:00) Novosibirsk",
  "Myanmar Standard Time": "(UTC+06:30) Yangon (Rangoon)",
  "SE Asia Standard Time": "(UTC+07:00) Bangkok, Hanoi, Jakarta",
  "Altai Standard Time": "(UTC+07:00) Barnaul, Gorno-Altaysk",
  "W. Mongolia Standard Time": "(UTC+07:00) Hovd",
  "North Asia Standard Time": "(UTC+07:00) Krasnoyarsk",
  "Tomsk Standard Time": "(UTC+07:00) Tomsk",
  "China Standard Time": "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
  "North Asia East Standard Time": "(UTC+08:00) Irkutsk",
  "Singapore Standard Time": "(UTC+08:00) Kuala Lumpur, Singapore",
  "W. Australia Standard Time": "(UTC+08:00) Perth",
  "Taipei Standard Time": "(UTC+08:00) Taipei",
  "Ulaanbaatar Standard Time": "(UTC+08:00) Ulaanbaatar",
  "North Korea Standard Time": "(UTC+08:30) Pyongyang",
  "Aus Central W. Standard Time": "(UTC+08:45) Eucla",
  "Transbaikal Standard Time": "(UTC+09:00) Chita",
  "Tokyo Standard Time": "(UTC+09:00) Osaka, Sapporo, Tokyo",
  "Korea Standard Time": "(UTC+09:00) Seoul",
  "Yakutsk Standard Time": "(UTC+09:00) Yakutsk",
  "Cen. Australia Standard Time": "(UTC+09:30) Adelaide",
  "AUS Central Standard Time": "(UTC+09:30) Darwin",
  "E. Australia Standard Time": "(UTC+10:00) Brisbane",
  "AUS Eastern Standard Time": "(UTC+10:00) Canberra, Melbourne, Sydney",
  "West Pacific Standard Time": "(UTC+10:00) Guam, Port Moresby",
  "Tasmania Standard Time": "(UTC+10:00) Hobart",
  "Vladivostok Standard Time": "(UTC+10:00) Vladivostok",
  "Lord Howe Standard Time": "(UTC+10:30) Lord Howe Island",
  "Bougainville Standard Time": "(UTC+11:00) Bougainville Island",
  "Russia Time Zone 10": "(UTC+11:00) Chokurdakh",
  "Magadan Standard Time": "(UTC+11:00) Magadan",
  "Norfolk Standard Time": "(UTC+11:00) Norfolk Island",
  "Sakhalin Standard Time": "(UTC+11:00) Sakhalin",
  "Central Pacific Standard Time": "(UTC+11:00) Solomon Is., New Caledonia",
  "Russia Time Zone 11": "(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky",
  "New Zealand Standard Time": "(UTC+12:00) Auckland, Wellington",
  "UTC+12": "(UTC+12:00) Coordinated Universal Time+12",
  "Fiji Standard Time": "(UTC+12:00) Fiji",
  "Kamchatka Standard Time": "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
  "Chatham Islands Standard Time": "(UTC+12:45) Chatham Islands",
  "Tonga Standard Time": "(UTC+13:00) Nuku alofa",
  "Samoa Standard Time": "(UTC+13:00) Samoa",
  "Line Islands Standard Time": "(UTC+14:00) Kiritimati Island",
}

export function ageString(ageStart: number, ageEnd: number): string {
  if (!ageStart && !ageEnd) {
    return "All Ages"
  }
  if (!ageStart && ageEnd) {
    return `${ageEnd} and Under`
  }
  if (ageStart && !ageEnd) {
    return `${ageStart} and Over`
  }
  return `${ageStart} to ${ageEnd}`
}

export function dateString(startDate: string, endDate: string): string {
  if (startDate && endDate) {
    return `${startDate} to ${endDate}`
  }
  return "All Dates"
}

export const divLikeLink = {
  cursor: "pointer",
  color: "#1890ff",
}

export function validateEmail(email: string): boolean {
  return /^[^\s@.]+[^\s@]*@[^\s@.]+[^\s@]*\.[^\s@]{2,}$/.test(email)
  // let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  // return re.test(String(email).toLowerCase())
}

export function saveFile(CSV: string, fileName: string): void {
  let csvData = new Blob([CSV], { type: "text/csv;charset=utf-8;" })
  //IE11 & Edge
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(csvData, fileName)
  } else {
    //In FF link must be added to DOM to be clicked
    let link = document.createElement("a")
    link.href = window.URL.createObjectURL(csvData)
    link.setAttribute("download", fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}

export function download(
  data: string,
  filename: string,
  type: string | null = null,
) {
  let file = new Blob([data], { type: type !== null ? type : undefined })
  if (window.navigator.msSaveOrOpenBlob)
    // IE10+
    window.navigator.msSaveOrOpenBlob(file, filename)
  else {
    // Others
    let a = document.createElement("a"),
      url = URL.createObjectURL(file)
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    setTimeout(function () {
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)
    }, 0)
  }
}

export const isRaceRelay: { [key: number]: boolean } = {
  0: true,
  5: true,
  7: true,
  8: true,
  9: true,
}

export function exists(value: any): boolean {
  if (value === null || value === undefined) return false
  return true
}

export const isInThePast = (dateString: string): boolean | undefined => {
  const today = moment();
  const date = moment(dateString, "YYYY-MM-DD")
  return date.isValid() ? date.isBefore(today) : undefined
}

export function parseTime(stringNumb: string): number | null {
  if (!exists(stringNumb)) return null
  let noColons: string = stringNumb.replace(/:/g, "")
  if (/^[0-9]*\.{0,1}[0-9]*$/.test(noColons)) {
    if (noColons === ".") return 0
    let timeComponents: Array<string> = stringNumb.split(":")
    if (timeComponents.length > 3) {
      console.error(`Invalid time ${stringNumb}.`)
      return null
    } else if (timeComponents.length === 3) {
      return (
        100 *
        (3600 * parseInt(timeComponents[0]) +
          60 * parseInt(timeComponents[1]) +
          parseFloat(timeComponents[2]))
      )
    } else if (timeComponents.length === 2) {
      return (
        100 * (60 * parseInt(timeComponents[0]) + parseFloat(timeComponents[1]))
      )
    } else if (timeComponents.length === 1) {
      return 100 * parseFloat(timeComponents[0])
    } else {
      console.error(
        `Should not have reached this case. Invalid time ${stringNumb}.`,
      )
      return null
    }
  } else {
    console.error(`Invalid time ${stringNumb}.`)
    return null
  }
}

export function convertToOther(
  prevBest: number,
  prevBestCourse: number,
  curSwimCourse: number,
): number {
  // trace todo - remove from this the conversion to number
  // because the input should be a number
  if (Number(prevBestCourse) === Number(curSwimCourse)) {
    return prevBest
  }
  if (Number(prevBestCourse) === 0 && Number(curSwimCourse) !== 0) {
    return prevBest * 1.11
  } else {
    return prevBest / 1.11
  }
}

export function formatNumberAsTime(inputSeconds: number | null): string {
  if (!exists(inputSeconds)) {
    return "NT"
  }
  let seconds: number = inputSeconds || 0
  let formattedTime: string = new Date(seconds * 1000)
    .toISOString()
    .substring(11, 22)
  if (formattedTime.length === 11 && formattedTime.slice(0, 3) === "00:") {
    return formattedTime.slice(3)
  } else {
    return formattedTime
  }
}

export function formatTimeWithoutLeadingZeros(seconds: number): string {
  let initialTime: string = formatNumberAsTime(seconds)
  let finished = false
  while (!finished) {
    initialTime = initialTime.replace(/^0+/, "").replace(/^:/, "")
    if (/^0/.test(initialTime) || /^:/.test(initialTime)) {
      finished = false
    } else {
      finished = true
    }
  }
  initialTime = initialTime.replace(/^0+/, "").replace(/^\./, "0.")
  if (initialTime === "") {
    initialTime = "0.00"
  }
  return initialTime
}

export const unitOfMeasureToCourse = (unitOfMeasure: number) => {

  switch(Number(unitOfMeasure)){
    case 0 : 
      return "SCY"

    case 1 :
      return "SCM"

    case 2 : 
      return "SCM"
  }
}

export function formatConvertedTime(
  time: number,
  oldCourse: number,
  newCourse: number,
): string {
  if (!time) {
    return ""
  }
  if (typeof time !== "number") {
    console.error(`${time} is not a numeric input to formatConvertedTime.`)
  }
  let seconds: number = time
  let convertedTime: number = convertToOther(seconds, oldCourse, newCourse)
  return formatNumberAsTime(convertedTime)
}

export function shouldConvert(meetCourse: number, timeCourse: number): boolean {
  if (!meetCourse) {
    return false
  }
  if (timeCourse === null || timeCourse === undefined) {
    return false
  }
  if (String(meetCourse) === String(timeCourse)) {
    return false
  }
  return true
}

export const convertGender: { [key: string]: string } = {
  Open: "0",
  Mixed: "0",
  Girls: "1",
  Boys: "2",
}

export function toLowerN(theString: string): string {
  if (theString) {
    return theString.toLowerCase()
  }
  return ""
}

export function isSpinning(
  ReactNode: React.ReactNode,
  isSpinning: boolean,
): React.ReactNode {
  if (!isSpinning) {
    return ReactNode
  }
  return <Spin size="large">{ReactNode}</Spin>
}

export const actionItemStyle: { [key: string]: string } = {
  lineHeight: "22px",
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  padding: "8px 10px",
}

export function convertAgeRange(ageRangeString: string): {
  bottom: number | null
  top: number | null
} {
  let ageRange: string = ageRangeString.trim()
  if (ageRange.includes("Open") || ageRange.includes("All")) {
    return { bottom: null, top: null }
  }
  if (ageRange.includes("Under")) {
    let top = Number(ageRange.split(" ")[0])
    let bottom = null
    return { bottom, top }
  }
  if (ageRange.includes("Over")) {
    let top = null
    let bottom = Number(ageRange.split(" ")[0])
    return { bottom, top }
  }
  if (ageRange.includes("-")) {
    let split = ageRange.split("-")
    let bottom = Number(split[0])
    let top = Number(split[1])
    return { bottom, top }
  } else {
    let value = Number(ageRange)
    return { bottom: value, top: value }
  }
}

// Eventually do more shit here
export function gen(url: string): string {
  return url
  // if (process.env.NODE_ENV === "development") {
  //   return url
  // }
  // let val: string = url.replace("api/", "")
  // return val
  // return url.replace("api/", "")
}

export const gmapsApiKey: string = "AIzaSyCzkOR0_8CSzdIKc8rBopNLmhIZHvcrVO0"
export const googleMapsServerApiKey: string = "AIzaSyBh44s1k5FQjtmWOfk43qu5jcUmER3vDJk"

export async function wait(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

export const metersPerYard: number = 0.9144
export const yardsPerMeter: number = 1.09361329833770776

export const DistanceFormatter = {
  metersFromYards: (yards: number, unit: string = "m"): string => `${Math.round((yards * 100) / yardsPerMeter) / 100}${unit}`,
  yardsFromMeters: (meters: number, unit: string = "y"): string => `${Math.round((meters * 100) / metersPerYard) / 100}${unit}`
}

export const validateUsaSwimmingId = (id: string): boolean => {
  const isValidUsaSwimmingId = /^[0-9a-zA-Z*]{14}$/.test(id)
  const isValidCanadianSwimmingId = /^[0-9a-zA-Z*]{9}$/.test(id)
  return !_.isNil(id) && (isValidUsaSwimmingId || isValidCanadianSwimmingId)
}

export function longestCommonSubsequence(
  str1: string,
  str2: string,
  useLowerCase = true,
): number {
  let string1 = str1
  let string2 = str2

  if (useLowerCase === true) {
    string1 = str1.toLowerCase()
    string2 = str2.toLowerCase()
  }

  // find the length of the strings
  let m = string1.length
  let n = string2.length

  // declaring the array for storing the dp values
  let arr = []
  for (let i = 0; i < m + 1; i++) {
    let subArr = []
    for (let j = 0; j < n + 1; j++) {
      subArr.push(0)
    }
    arr.push(subArr)
  }

  // Following steps build arr[m+1][n+1] in bottom up fashion
  // Note: arr[i][j] contains length of LCS of string1[0..i-1]
  // and string2[0..j-1]
  for (let i = 0; i < m + 1; i++) {
    for (let j = 0; j < n + 1; j++) {
      if (i === 0 || j === 0) {
        arr[i][j] = 0
      } else if (string1[i - 1] === string2[j - 1]) {
        arr[i][j] = arr[i - 1][j - 1] + 1
      } else {
        arr[i][j] = Math.max(arr[i - 1][j], arr[i][j - 1])
      }
    }
  }

  // arr[m][n] contains the length of LCS of string1[0..n-1] & string2[0..m-1]
  return arr[m][n]
}

export function stringPercentSimilarity(
  string1: string,
  string2: string,
  useLowerCase: boolean = true,
): number {
  let minLength = Math.min(string1.length, string2.length)
  return longestCommonSubsequence(string1, string2, useLowerCase) / minLength
}

export function longestCommonSubstring(
  string1: string,
  string2: string,
  useLowerCase: boolean = true,
): number {
  let shorterWord
  let longerWord
  if (string1.length <= string2.length) {
    shorterWord = useLowerCase ? string1.toLowerCase() : string1
    longerWord = useLowerCase ? string2.toLowerCase() : string2
  } else {
    shorterWord = useLowerCase ? string2.toLowerCase() : string2
    longerWord = useLowerCase ? string1.toLowerCase() : string1
  }

  let substringLength = 0
  for (let testLength = shorterWord.length; testLength > 0; testLength--) {
    for (
      let startChar = 0;
      startChar <= shorterWord.length - testLength;
      startChar++
    ) {
      let substr = shorterWord.slice(startChar, startChar + testLength)
      if (longerWord.includes(substr)) {
        substringLength = testLength
        break
      }
    }
    if (substringLength > 0) {
      break
    }
  }

  return substringLength
}

interface ISubstringOrSequenceLengths {
  val: any
  lengthOfSubstring?: number
  lengthOfSubsequence?: number
}

export function fuzzyRank<T = any>(
  list: Array<any>,
  strFunc: (val: any) => string | Array<string>,
  searchString: string,
): Array<T> {
  let subsequenceLengths: Array<ISubstringOrSequenceLengths> = []
  for (let i = 0; i < list.length; i++) {
    let theValue = list[i]
    let lengthOfSubsequence
    let lengthOfSubstring
    let strings = strFunc(theValue)
    if (Array.isArray(strings)) {
      let theStrings: Array<string> = strings as Array<string>
      let lengthsOfSubstrings = theStrings.map((str) =>
        longestCommonSubsequence(str, searchString),
      )
      let continuousSubstrings = theStrings.map((str) =>
        longestCommonSubstring(str, searchString),
      )
      lengthOfSubsequence = Math.max(...lengthsOfSubstrings)
      lengthOfSubstring = Math.max(...continuousSubstrings)
    } else {
      let theString: string = strings as string
      lengthOfSubsequence = longestCommonSubsequence(theString, searchString)
      lengthOfSubstring = longestCommonSubstring(theString, searchString)
    }
    subsequenceLengths.push({
      val: theValue,
      lengthOfSubsequence,
      lengthOfSubstring,
    })
  }

  return subsequenceLengths
    .sort((obj1, obj2) => {
      if ((obj1.lengthOfSubsequence || 0) > (obj2.lengthOfSubsequence || 0))
        return -1
      else if (
        (obj1.lengthOfSubsequence || 0) < (obj2.lengthOfSubsequence || 0)
      )
        return 1
      else {
        // compare substring lengths now
        return (obj1.lengthOfSubstring || 0) > (obj2.lengthOfSubstring || 0)
          ? -1
          : 1
      }
    })
    .map((obj) => obj.val)
}


export function getLandingPageRoute(user: IUser, clubToken: string|undefined = undefined): string {
  if (user.role === 2) {
    return "/app/admin/club"
  } else if (user.role === 3) {
    return `/app/admin/edit_league/${user.leagueId}`
  } else if (user.role === 4) {
    return "/app/club/admin"
  } else if (user.role === 5) {
    
    if (clubToken) {
      return `/clubs/register/${clubToken}`
    } else {
      return "/app/landingPage"
    }
  } else {
    return "/"
  }
}

interface IFetchParams extends RequestInit {
  headers: {
    Accept: string
    "Content-Type": string
  }
  method: string
  body?: string
}

const makeApiCall = ({
  url,
  method = "post",
  data = {},
}: {
  url: string
  method?: string
  data?: any
}) => {
  const fetchParams: IFetchParams = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "same-origin",
    method,
  }
  if (method !== "get") {
    fetchParams.body = JSON.stringify(data)
  }

  return fetch(url, fetchParams)
    .then(async (response) => {
      if (!response.status || response.status < 200 || response.status >= 300) {
        let data: { [key: string]: any } = await response.json()
        if (data && data.message) {
          return {
            status: "error",
            message: data.message,
            statusCode: response.status,
          }
        } else {
          return {
            status: "error",
            message: "something went wrong",
            statusCode: response.status,
          }
        }
      }
      if ([
        // added mime type for spreadsheet
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        // mime type for pdf
        "application/octet-stream"
      ].includes(response?.headers?.get("Content-Type") ?? '') ) {
        
        return await response.blob()
      } else {
        return await response.json()
      }
    })
    .catch((err) => {
      return {
        status: "error",
        message: err?.message || "Uh oh, something went wrong...",
      }
    })
}

export const swimminglyApi = {
  post: (url: string) => ({
    body: (data: { [key: string]: any }) =>
      makeApiCall({ url, method: "post", data }),
  }),
  get: (url: string) => makeApiCall({ url, method: "get" }),
  put: (url: string) => ({
    body: (data: { [key: string]: any }) =>
      makeApiCall({ url, method: "put", data }),
  }),
  patch: (url: string) => ({
    body: (data: { [key: string]: any }) =>
      makeApiCall({ url, method: "patch", data }),
  }),
  delete: (url: string) => ({
    body: (data?: { [key: string]: any }) =>
      makeApiCall({ url, method: "delete", data }),
  }),
  download: (url: string, fileName: string, document: Document) => makeApiCall({ url, method: "get" })
    .then((response) => {
      const a = document.createElement("a");
      const url = URL.createObjectURL(response);
      a.href = url;
      a.download = fileName;
      a.click();
    }),

}

export const getRole = (user: IUser): string => {
  if (!user) {
    return "user of this app"
  }
  if (user.role === 2) {
    return "Hydro admin"
  }
  if (user.role === 3) {
    return "League admin"
  }
  if (user.role === 4) {
    return "Club admin"
  }
  if (user.role === 5) {
    return "Parent"
  }
  return "user of this app"
}

export const getPermissionBadge = (user: IUser): JSX.Element => {
  if (!user) {
    return <Tag color="default">user of this app</Tag>;
  }
  
  switch (user.role) {
    case 2:
      return <Tag color="gold">Swimmingly Super User</Tag>;
          
    case 3:
      return <Tag color="cyan">League Admin</Tag>;
    case 4:
      return <Tag color="purple">Club Admin</Tag>;
    case 5:
      return <Tag color="blue">Parent / Guardian</Tag>;
    default:
      return <Tag color="default">user of this app</Tag>;
  }
};


// this is taken from Wes Bos' advanced react course
export function formatMoney(cents: number = 0, alwaysDisplayCents: boolean = false): string {
  const options = {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  }

  // check if its a clean dollar amount
  if (cents % 100 === 0 && !alwaysDisplayCents) {
    options.minimumFractionDigits = 0
  }

  const formatter = Intl.NumberFormat("en-US", options)

  return formatter.format(cents / 100)
}

export function formatArrayAsText(arr: any[]): string {
  if (!Array.isArray(arr)) {
    throw new Error("Input is not an array")
  }
  if (arr.length === 0) return ""
  if (arr.length === 1) return `${arr[0]}`
  if (arr.length === 2) {
    return `${arr[0]} and ${arr[1]}`
  }
  if (arr.length >= 3) {
    return arr
      .slice(0, arr.length - 1)
      .map((v) => `${v}`)
      .join(", ")
      .concat(`, and ${arr[arr.length - 1]}`)
  }
  throw new Error("Something went wrong formatting the input array as text")
}

export function fuzzyMatchString<T = any>(
  arr: T[],
  fn: (value: T) => string,
  target: string,
): T[] {
  const output: T[] = []
  for (let i = 0; i < arr.length; i++) {
    const el = arr[i]
    const elString = fn(el)
    const pctSimilarity = stringPercentSimilarity(elString, target, true)
    let phoneticSounds1 = doubleMetaphone(elString)
    let phoneticSounds2 = doubleMetaphone(stemmer(elString))
    const phoneticSoundsEl = [...phoneticSounds1, ...phoneticSounds2].filter(
      (v, i, a) => a.indexOf(v) === i,
    )
    phoneticSounds1 = doubleMetaphone(target)
    phoneticSounds2 = doubleMetaphone(stemmer(target))
    const phoneticSoundsTarget = [
      ...phoneticSounds1,
      ...phoneticSounds2,
    ].filter((v, i, a) => a.indexOf(v) === i)
    const matchingSoundProfile = phoneticSoundsTarget.find((sound) =>
      phoneticSoundsEl.includes(sound),
    )
    if (!matchingSoundProfile && pctSimilarity < 0.9) continue
    else output.push(el)
  }
  return output
}

export function parsedInt(value: string): number | undefined {
  const parsedInt = parseInt(value)
  return isNaN(parsedInt) ? undefined : parsedInt
}
