import React, { useEffect, useState } from "react"
import { Link, Redirect, useHistory } from "react-router-dom"
import { Table, Alert, Input, Button, Switch, InputNumber } from "antd"
import { SwitchChangeEventHandler } from "antd/lib/switch"
import moment from "moment-timezone"
import { useStoreState, useStoreActions, StateMapper } from "easy-peasy"
import { AppDataStore } from "../appData/types"
import CenterSpin from "./CenterSpin"
import RecentUsers from "./RecentUsers"
import { divLikeLink, toLowerN, gen, swimminglyApi, IClub } from "./utils"
import ActionAlerts from "./ActionAlerts"
const { Column } = Table

const CenteredSwitch = ({
  checked,
  onChange,
}: {
  checked: boolean
  onChange: SwitchChangeEventHandler
}) => (
  <div
    style={{
      display: "flex",
      justifyContent: "space-between",
    }}
  >
    <div />
    <Switch checked={checked} onChange={onChange} />
    <div />
  </div>
)

const ClickEditText = ({
  text,
  editAction,
  metric,
}: {
  text: string
  editAction: (val: string) => void
  metric: any
}) => {
  const [editing, setEditing] = useState(false)

  const handleEdit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault()
    const [key, value]: [string, string] = [
      e.key,
      (e.target as HTMLInputElement).value,
    ]
    if (key === "Enter") {
      editAction(value)
      setEditing(false)
    }
  }

  if (!editing) {
    if (!text || text.trim() === "")
      return (
        <button onClick={() => setEditing(true)}>
          Add {metric || "value"}
        </button>
      )
    else
      return (
        <p
          style={{ color: "dodgerblue", textAlign: "center" }}
          onClick={() => setEditing(true)}
        >
          {text}
        </p>
      )
  } else {
    return <input type="text" onKeyUp={handleEdit} />
  }
}

const ClickEditNumber = ({
  num,
  min,
  max,
  submitAction,
  metric,
}: {
  num: number | null
  min: number
  max: number
  submitAction: (param: any) => void
  metric: any
}) => {
  const [editing, setEditing] = useState(false)

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault()
      handleSubmit((e.target as HTMLInputElement).value)
    }
  }

  const handleSubmit = (val: string) => {
    if (!val || val === "" || parseFloat(val) === 0) submitAction(null)
    else if (parseFloat(val) >= min && parseFloat(val) <= max) {
      submitAction(val)
    }

    setEditing(false)
  }

  if (!editing) {
    if (!num)
      return (
        <button onClick={() => setEditing(true)}>
          Add {metric || "value"}
        </button>
      )
    else
      return (
        <p
          style={{ color: "dodgerblue", textAlign: "center" }}
          onClick={() => setEditing(true)}
        >
          {num}
        </p>
      )
  } else {
    return (
      <InputNumber
        min={min}
        max={max}
        onKeyDown={handleKeyDown}
        onBlur={(e) => handleSubmit(e.target.value)}
      />
    )
  }
}

interface IClubWithName extends IClub {
  leagueName: string
  canImportEntries?: boolean | 0 | 1 | null
  canExportEntries?: boolean | 0 | 1 | null
  canImportResults?: boolean | 0 | 1 | null
  paymentOrganization: string | null
  startYear: number | null
  startMonth: number | null
  lastPayingYear: number | null
  lastPayingMonth: number | null
  organizationType: string | null
  is_meet_central_club: boolean | 0 | 1 | null
}

export default function AllClubs(): JSX.Element {
  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)
  const setImpersonateClub = useStoreActions(
    (actions: AppDataStore) => actions.setAliasedClub,
  )

  const [clubs, setClubs] = useState<IClubWithName[]>([])
  const [shouldDisplayWarning, setShouldDisplayWarning] = useState(false)

  const [value, setValue] = useState("")
  const [loaded, setLoaded] = useState(false)
  const [showAdvancedClubColumns, setShowAdvancedClubColumns] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const [triggerRefresh, setTriggerRefresh] = useState(0)

  const history = useHistory()

  const sortClubs = (a: IClubWithName, b: IClubWithName): number => {
    if (a.leagueName > b.leagueName) {
      return 1
    }
    if (a.leagueName < b.leagueName) {
      return -1
    }
    if (a.name_long > b.name_long) {
      return 1
    }
    if (a.name_long < b.name_long) {
      return -1
    }
    return 0
  }

  useEffect(() => {
    let mounted = true
    swimminglyApi
      .post(gen(`/api/getUsersClubsPost`))
      .body({
        userId: user?.userId,
      })
      .then(
        (data: {
          status: string
          clubs: Array<{
            clubId: number
            name_long: string
            unique_identifier?: string | null
            is_meet_central_club: boolean | 0 | 1 | null
            leagueName: string
            canImportEntries?: boolean | 0 | 1 | null
            canExportEntries?: boolean | 0 | 1 | null
            canImportResults?: boolean | 0 | 1 | null
            paymentOrganization: string | null
            startYear: number | null
            startMonth: number | null
            lastPayingYear: number | null
            lastPayingMonth: number | null
            organizationType: string | null
          }>
        }) => {
          const usersClubs = data.clubs
          usersClubs.sort(sortClubs)
          if (mounted) {
            setClubs(usersClubs)
            setLoaded(true)
          }

          if (user?.role === 2) {
            swimminglyApi
              .post(gen(`/api/getRostersHydroAdminPost`))
              .body({
                userId: user.userId,
              })
              .then(
                (data: {
                  status: string
                  results: { clubId: number; count: number }[]
                }) => {
                  let rosterMapping: Array<number | undefined> = []
                  data.results.forEach(
                    (el) => (rosterMapping[el.clubId] = el.count),
                  )
                },
              )
          } else {
            swimminglyApi
              .post(gen(`/api/getRostersPost`))
              .body({
                leagueId: user?.leagueId,
              })
              .then(
                (data: {
                  status: string
                  results: { clubId: number; count: number }[]
                }) => {
                  let rosterMapping: Array<number | undefined> = []
                  data.results.forEach(
                    (el) => (rosterMapping[el.clubId] = el.count),
                  )
                },
              )
          }
        },
      )
      .catch(console.log)

    swimminglyApi
      .post(gen(`/api/shouldDisplayWarningPost`))
      .body({
        userId: user?.userId,
      })
      .then((data) => {
        if (mounted) {
          setShouldDisplayWarning(Boolean(data.shouldDisplay))
        }
      })
    return () => {
      mounted = false
    }
  }, [user?.leagueId, user?.role, user?.userId, triggerRefresh])

  const aliasToOtherClub = (otherClub: IClubWithName) => {
    swimminglyApi
      .post("/api/impersonateClub2")
      .body({ user: user, impersonateClubId: otherClub.clubId })
      .then((data: { status: string; club?: IClubWithName }) => {
        if (data && data.club) {
          setImpersonateClub(data.club)
          return data.club.clubId as number
        } else {
          return null
        }
      })
      .then((theClubId: number | null) => {
        if (!theClubId) {
          return
        }
        swimminglyApi
          .post(gen(`/api/updateAliasedClub/${user?.userId}`))
          .body({ clubId: theClubId })
      })
      .finally(() => {
        history.push("/app/club/admin")
      })
  }

  if (!user) return <Redirect to="/login" />

  let hydroAdmin = user.role === 2
  let leagueAdmin = user.role === 2 || user.role === 3

  let allClubs = null

  if (!loaded) {
    allClubs = (
      <section className="panel">
        <header className="panel-heading">
          <h2 className="panel-title">Manage Clubs</h2>
        </header>
        <div className="ourpanel-body">
          <CenterSpin />
        </div>
      </section>
    )
  } else {
    let curClubs = clubs

    if (value) {
      curClubs = clubs.filter((el) => {
        return (
          toLowerN(el.name_long).includes(toLowerN(value)) ||
          toLowerN(el.leagueName).includes(toLowerN(value))
        )
      })
    }

    allClubs = (
      <section className="panel">
        <header className="panel-heading">
          <h2 className="panel-title">
            Manage Clubs{" "}
            <span style={{ fontSize: "16px" }}>({clubs.length} clubs)</span>
          </h2>
        </header>
        <div className="ourpanel-body">
          <div style={{ display: "flex", marginBottom: 0, paddingBottom: 0 }}>
            <Button
              type="primary"
              style={{
                marginBottom: 0,
                paddingBottom: 0,
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              <Link to="/app/admin/club/add">Add new Club</Link>
            </Button>
            {hydroAdmin && (
              <Button
                type="primary"
                style={{
                  marginBottom: 0,
                  paddingBottom: 0,
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
                onClick={() => {
                  setShowAdvancedClubColumns(!showAdvancedClubColumns)
                }}
              >
                Advanced Options
              </Button>
            )}
          </div>
          <br />
          <Input
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            style={{ marginBottom: "10px", width: "200px" }}
            placeholder="Search"
          />
          <Table
            pagination={{
              current: currentPage,
              pageSize,
            }}
            onChange={(pagination, filters) => {
              setCurrentPage(pagination.current || 1)
              setPageSize(pagination.pageSize || 10)
            }}
            dataSource={curClubs}
            bordered
            size="middle"
            rowKey="clubId"
          >
            <Column
              title="Club Name"
              dataIndex="name_long"
              key="name_long"
              render={(_text: string, el: IClubWithName) => {
                return (
                  <span>
                    {leagueAdmin ? (
                      <div
                        style={divLikeLink}
                        onClick={() => aliasToOtherClub(el)}
                      >
                        {el.name_long}
                      </div>
                    ) : (
                      el.name_long
                    )}
                  </span>
                )
              }}
            />
            <Column
              title="League Name"
              dataIndex="leagueName"
              key="leagueName"
            />
            {hydroAdmin && (
              <React.Fragment>
                <Column
                  title="Paid"
                  dataIndex="unique_identifier"
                  key="unique_identifier"
                  render={(unique_identifier) =>
                    unique_identifier === "PAID" ? "Yes" : "No"
                  }
                />
                <Column
                  title="Can Import Entries"
                  dataIndex="canImportEntries"
                  key="canImportEntries"
                  render={(_text, record: IClubWithName) => (
                    <CenteredSwitch
                      checked={record.canImportEntries === 1 ? true : false}
                      onChange={(val) => {
                        swimminglyApi
                          .post(gen("/api/updateCanImportEntries"))
                          .body({
                            clubId: record.clubId,
                            newValue: val ? 1 : 0,
                          })
                          .then(() => setTriggerRefresh(triggerRefresh + 1))
                      }}
                    />
                  )}
                />
                <Column
                  title="Can Export Entries"
                  dataIndex="unique_identifier"
                  key="unique_identifier"
                  render={(_text, record: IClubWithName) => (
                    <CenteredSwitch
                      checked={record.canExportEntries === 1 ? true : false}
                      onChange={(val) => {
                        swimminglyApi
                          .post(gen("/api/updateCanExportEntries"))
                          .body({
                            clubId: record.clubId,
                            newValue: val ? 1 : 0,
                          })
                          .then(() => setTriggerRefresh(triggerRefresh + 1))
                      }}
                    />
                  )}
                />
                <Column
                  title="Can Import Results"
                  dataIndex="unique_identifier"
                  key="unique_identifier"
                  render={(_text, record: IClubWithName) => (
                    <CenteredSwitch
                      checked={record.canImportResults === 1 ? true : false}
                      onChange={(val) => {
                        swimminglyApi
                          .post(gen("/api/updateCanImportResults"))
                          .body({
                            clubId: record.clubId,
                            newValue: val ? 1 : 0,
                          })
                          .then(() => setTriggerRefresh(triggerRefresh + 1))
                      }}
                    />
                  )}
                />
                {showAdvancedClubColumns && (
                  <Column
                    title="Payment Organization"
                    dataIndex="paymentOrganization"
                    key="paymentOrganization"
                    render={(text: string, record: IClubWithName) => (
                      <select
                        value={text && text.trim() !== "" ? text : ""}
                        onChange={(e) => {
                          let val = e.target.value
                          swimminglyApi
                            .post(gen("/api/updatePaymentOrganization"))
                            .body({
                              clubId: record.clubId,
                              paymentOrganization: val,
                            })
                            .then(() => setTriggerRefresh(triggerRefresh + 1))
                        }}
                      >
                        <option value={""}>Choose payment organization</option>
                        <option value="yearRound">year round</option>
                        <option value="summer">summer</option>
                        <option value="corporate">corporate</option>
                        <option value="masters">masters</option>
                        <option value="highschool">highschool</option>
                        <option value="college">college</option>
                        <option value="collegeClub">collegeClub</option>
                      </select>
                    )}
                  />
                )}
                {showAdvancedClubColumns && (
                  <Column
                    title="Organization Type"
                    dataIndex="organizationType"
                    key="organizationType"
                    render={(text: string, record: IClubWithName) => (
                      <ClickEditText
                        text={text}
                        editAction={(val) => {
                          swimminglyApi
                            .post(gen("/api/updateOrganizationType"))
                            .body({
                              clubId: record.clubId,
                              organizationType: val,
                            })
                            .then(() => setTriggerRefresh(triggerRefresh + 1))
                        }}
                        metric="organization type"
                      />
                    )}
                  />
                )}
                {showAdvancedClubColumns && (
                  <Column
                    title="Start Paying Year"
                    dataIndex="startYear"
                    key="startYear"
                    render={(text: string, record: IClubWithName) => (
                      <ClickEditNumber
                        num={(text && parseInt(text)) || null}
                        min={2013}
                        max={moment().year() + 2}
                        submitAction={(value) => {
                          swimminglyApi
                            .post(gen("/api/updateSubscriptionStart"))
                            .body({ clubId: record.clubId, year: value })
                            .then(() => setTriggerRefresh(triggerRefresh + 1))
                        }}
                        metric="first year"
                      />
                    )}
                  />
                )}
                {showAdvancedClubColumns && (
                  <Column
                    title="Start Paying Month"
                    dataIndex="startMonth"
                    key="startMonth"
                    render={(text: string, record: IClubWithName) => (
                      <ClickEditNumber
                        num={(text && parseInt(text)) || null}
                        min={1}
                        max={12}
                        submitAction={(value) => {
                          swimminglyApi
                            .post(gen("/api/updateSubscriptionStart"))
                            .body({ clubId: record.clubId, month: value })
                            .then(() => setTriggerRefresh(triggerRefresh + 1))
                        }}
                        metric="first month"
                      />
                    )}
                  />
                )}
                {showAdvancedClubColumns && (
                  <Column
                    title="Last Paying Year"
                    dataIndex="lastPayingYear"
                    key="lastPayingYear"
                    render={(text: string, record: IClubWithName) => (
                      <ClickEditNumber
                        num={(text && parseInt(text)) || null}
                        min={2013}
                        max={moment().year() + 2}
                        submitAction={(value) => {
                          swimminglyApi
                            .post(gen("/api/updateSubscriptionEnd"))
                            .body({ clubId: record.clubId, year: value })
                            .then(() => setTriggerRefresh(triggerRefresh + 1))
                        }}
                        metric="last paying year"
                      />
                    )}
                  />
                )}
                {showAdvancedClubColumns && (
                  <Column
                    title="Last Paying Month"
                    dataIndex="lastPayingMonth"
                    key="lastPayingMonth"
                    render={(text: string, record: IClubWithName) => (
                      <ClickEditNumber
                        num={(text && parseInt(text)) || null}
                        min={1}
                        max={12}
                        submitAction={(value) => {
                          swimminglyApi
                            .post(gen("/api/updateSubscriptionEnd"))
                            .body({ clubId: record.clubId, month: value })
                            .then(() => setTriggerRefresh(triggerRefresh + 1))
                        }}
                        metric="last paying month"
                      />
                    )}
                  />
                )}
              </React.Fragment>
            )}
          </Table>
        </div>
      </section>
    )
  }

  return (
    <div>
      <header className="page-header">
        <h2>Manage Clubs</h2>
      </header>
      <ActionAlerts />
      {shouldDisplayWarning && (
        <React.Fragment>
          <Alert
            message={
              <span>
                A new admin is requesting access. Please click on League or Club
                Admins to approve or reject.
              </span>
            }
            type="warning"
            closable
          />
          <br />
        </React.Fragment>
      )}
      {user.role === 2 && <RecentUsers />}
      <div style={{ height: "15px" }}></div>
      {allClubs}
    </div>
  )
}
