import React from "react"
import { Spin } from "antd"
import { actionItemStyle } from "./utils"

export default class ExportMeetEntries extends React.Component {
  state = { loading: false }

  showTheModal = () => {
    this.props.showModal(true)
    this.props.closeMenu()
  }

  render() {
    return (
      <span onClick={this.showTheModal} style={actionItemStyle}>
        {this.state.loading && (
          <Spin size="small" style={{ marginRight: "5px" }} />
        )}
        Export meet entries
      </span>
    )
  }

}
