import { gen,  swimminglyApi } from "../../utils"
import { IWaiver } from "../sharedState"

// models the API to retreive Registration waiver configuration
export class RegistrationWaiverHelper {
    api = swimminglyApi;
    
    public constructor(api = swimminglyApi) {
        this.api = api
    }

    public async getWaiversWithSections(clubId: number, seasonId: number): Promise<IWaiver[]>  {
        return await this.api
            .get(gen(`/api/registrationWaivers/clubId=${clubId}/seasonId=${seasonId}`)) 
            .then((data) => {
                return Promise.resolve(data["waiversConfiguration"])
            })
            .catch ((err) => {
                console.log("Error connecting to the server.")
                return Promise.resolve([])
            })  
     }
}