import React, { ReactNode } from "react"
import styled from "styled-components"
import { CloseOutlined } from "@ant-design/icons"
import { screenSizes } from "../styles/GlobalStyles"

export interface IGenericModalProps {
  visible: boolean
  setVisible: (val: boolean) => void
  children: ReactNode
  width?: string
  maxWidth?: string
  maxHeight?: string
  title: string
  footer: () => ReactNode
  zIndex?: number
}

interface IModalProps {
  width?: string
  maxWidth?: string
  maxHeight?: string
  zIndex?: number
}

const ModalContainer = styled.div`
  border-bottom: 1px solid #eee;
  height: 50px;
`

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: ${({ zIndex = 0 }: { zIndex: number }) => 10000000 + zIndex};
`

const Modal = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  width: ${(props: IModalProps) =>
    props.width ? props.width : "calc(100vw-4em)"};
  max-width: ${(props: IModalProps) =>
    props.maxWidth ? props.maxWidth : "32em"};
  height: auto;
  max-height: ${(props: IModalProps) =>
    props.maxHeight ? props.maxHeight : "700px"};
  overflow: auto;
  overflow-y: auto;
  transform: translate(-50%, -50%);
  padding: 1em;
  border-radius: 0.2em;
  background: white;
  z-index: ${({ zIndex = 0 }: IModalProps) => 10000000 + zIndex + 1};

  @media (max-height: 700px) {
    max-height: 550px;
  }

  @media (max-height: 600px) {
    max-height: 400px;
  }

  @media (max-height: 400px) {
    max-height: 300px;
  }

  @media (max-height: 300px) {
    max-height: 200px;
  }

  @media (max-width: ${screenSizes.medium}px) {
    width: ${(props: IModalProps) => (props.width ? props.width : "450px")};
    max-width: ${(props: IModalProps) =>
      props.maxWidth ? props.maxWidth : "85%"};
  }

  @media (max-width: ${screenSizes.small}px) {
    width: ${(props: IModalProps) => (props.width ? props.width : "350px")};
    max-width: ${(props: IModalProps) =>
      props.maxWidth ? props.maxWidth : "98%"};
    overflow: scroll;
  }
`

const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px;
`

function Title({
  text,
  setVisible,
}: {
  text: string
  setVisible: (val: boolean) => void
}) {
  return (
    <ModalTitle>
      <h4>{text}</h4>
      <CloseOutlined onClick={() => setVisible(false)} />
    </ModalTitle>
  )
}

const ModalFooterRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const ModalFooterButtons = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 70%;

  button {
    margin-left: 2px;
    margin-right: 2px;
  }
`

interface IModalFooterProps {
  children: ReactNode
}

const ModalFooter = ({ children }: IModalFooterProps) => {
  return (
    <ModalFooterRow>
      <div />
      <ModalFooterButtons>{children}</ModalFooterButtons>
    </ModalFooterRow>
  )
}

export default function GenericModal({
  visible,
  setVisible,
  children,
  width,
  maxWidth,
  maxHeight,
  title,
  footer,
  zIndex = 0,
}: IGenericModalProps) {
  if (visible) {
    return (
      <ModalContainer>
        <ModalBackground onClick={() => setVisible(false)} zIndex={zIndex} />
        <Modal
          width={width}
          maxWidth={maxWidth}
          maxHeight={maxHeight}
          zIndex={zIndex}
        >
          <Title text={title} setVisible={setVisible} />
          <hr style={{ marginTop: "0px" }} />
          {children}
          <hr style={{ marginTop: "5px", marginBottom: "5px" }} />
          <ModalFooter>{footer()}</ModalFooter>
        </Modal>
      </ModalContainer>
    )
  } else {
    return null
  }
}
