import moment from "moment-timezone"
import ContentPanel from "../ContentPanel"
import { StyledBillingRow } from "./BillingHistoryStyles"
import { DateFormats, formatMoney } from "../utils"
import { IInvoice } from "../ClubSeasonManagement/RegistrationConfiguration/RegistrationHelper"
import { FunctionComponent, useState } from "react"
import { MoneyCell } from "../ui/MoneyCell"
import Modal from "antd/lib/modal/Modal"
import { Button, Col, Divider, Popconfirm, Row, Table, Tag } from 'antd';
import 'antd/dist/antd.css';
import { EyeOutlined } from "@ant-design/icons"
import swimHeader from "./swimmingly_final_header.png"

type CellProps  = {
  bold?: boolean
  children?: React.ReactNode
}
const TitleCell: FunctionComponent<CellProps>  = ({children, bold = false}: CellProps) => {
  return (<th style={{textAlign:"right", paddingRight:"8px", fontWeight: bold ? "bold" : "normal"}}>{children}</th>)
}


export default function BillingHistory({
  invoices
}: {
  invoices: IInvoice[]
}): JSX.Element | null {

  const [invoiceVisible, setInvoiceVisible] = useState(false)
  const [selectedInvoice, setSelectedInvoice] = useState<IInvoice | undefined>(undefined)
  const invoicesArray = invoices?.map((invoice, invoiceIndex) => {
    return (
      <>
      <Divider />
        <StyledBillingRow key={`billing_row_${invoiceIndex}`}>
          <span>
            <Tag
              color={"geekblue"}
            >
              {moment(invoice.invoiceDate).format(DateFormats.default)}
            </Tag>
            <Tag
              color={"blue"}
            >
              {invoice.clubName}
            </Tag>
            <br />
            {invoice.seasonName}            
          </span>
          <span>&nbsp;</span>
          <span>{formatMoney(invoice.invoiceTotal)}</span>
          <Button
            type="default"
            size="small"
            
            icon={<EyeOutlined />}
            onClick={() => {
              setInvoiceVisible(true)
              setSelectedInvoice(invoice)
            }}
          >
            View Receipt
          </Button>
             
        </StyledBillingRow>
        <Divider 
          dashed={true}
        /> 
        {invoice.swimmers.map((swimmer, index) => {
          const refundsAreDisabled = true
          return (
            <StyledBillingRow key={`swimmer-${index}`}>
              <span>&nbsp;</span>
              <span>{`${swimmer.firstName} ${swimmer.lastName}`}</span>
              <span>{formatMoney(swimmer.swimminglyMembershipFee)}</span>
              <Popconfirm
                title="This action will refund & revoke this swimmer's Swimmingly membership.
                      This swimmer will no longer be active/eligible. This action cannot be undone."
                okText="Refund & Revoke Membership"
              >
                <Button
                  type="link"
                  size="small"
                  block={true}
                  hidden={refundsAreDisabled}
                >
                  Refund/Revoke Membership
                </Button>
              </Popconfirm>
            </StyledBillingRow>

          ) 
        })}
      </>

    )
  })

  const headers = ["Season Info", "Swimmer", "Amount"].map((header) => (<span className="header">{header}</span>))
  return (
    <>
      <ContentPanel title="Payment History">
        <StyledBillingRow>
          {headers}
        </StyledBillingRow>
        {invoicesArray}
      </ContentPanel>

      <Modal
        visible={invoiceVisible}
        okText={"Close"}
        onOk={() => setInvoiceVisible(false)}
        onCancel={() => setInvoiceVisible(false)}
        okType="link"
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <div style={{ padding: 0 }}> 
          <Divider plain>
          <img 
              src={swimHeader} 
              alt="Receipt"
              height={25}
          /> 
            <br />
            <h4>
              Payment Receipt #{selectedInvoice?.invoiceId}
            </h4>
            
            <h5><a href="https://splash.swimmingly.app/benefits" target="_blank">Click for Membership Benefits</a></h5>
          </Divider>
          <Row style={{ marginTop: 10 }}>
            <Col span={24}>
              <table width={"100%"}>
                <tr>
                  <TitleCell>Date:</TitleCell>
                  <td>{moment(selectedInvoice?.invoiceDate).format(DateFormats.default)}</td>
                </tr>

                <tr>
                <TitleCell>Club Name:</TitleCell>
                  <td>{selectedInvoice?.clubName}</td>
                </tr>

                <tr>
                <TitleCell>Season Name: </TitleCell>
                  <td> {selectedInvoice?.seasonName}</td>
                </tr>

                {
                 selectedInvoice?.payingGuardianEmail && 
                  <tr>
                  <TitleCell>Guardian Email: </TitleCell>
                  <td> {selectedInvoice?.payingGuardianEmail}</td>
                  </tr>
                }
              </table>
            </Col>
          </Row>

          {
            selectedInvoice?.swimmers && selectedInvoice.swimmers.length > 0 &&
            <>
              <Divider plain><h5 style={{ marginTop: 15 }}><strong>Swimmers</strong></h5></Divider>
              <Row style={{ marginTop: 15 }}>
                <Table
                  dataSource={selectedInvoice?.swimmers.map((swimmer) => (
                    {
                      firstName: swimmer.firstName,
                      lastName: swimmer.lastName
                    }
                  )

                  )}
                  pagination={false}
                >
                  <Table.Column title="First Name" dataIndex='firstName' />
                  <Table.Column title="Last Name" dataIndex='lastName' />
                </Table>
              </Row>
            </>
          }

          {
            selectedInvoice?.checkoutItems && selectedInvoice.checkoutItems.length > 0 &&
            <>
              <Divider plain><h5 style={{ marginTop: 15 }}><strong>Additional Checkout Items</strong></h5></Divider>
              <Row style={{ marginTop: 15 }}>
                <Table
                  dataSource={selectedInvoice?.checkoutItems.map((checkoutItem) => (
                    {
                      code: checkoutItem.itemDescription,
                      amount: formatMoney(checkoutItem.amount, true)
                    }
                  )

                  )}
                  pagination={false}
                >
                  <Table.Column title="Item" dataIndex='code' />
                  <Table.Column title="Unit Cost" dataIndex='amount' />
                </Table>
              </Row>
            </>
          }

          <Divider plain><h5><strong>Totals</strong></h5></Divider>
          <Row style={{ marginTop: 15 }}>
            <Col span={16}>
              <table>
                <tr>
                  <TitleCell>Dues:</TitleCell>
                  <MoneyCell amount={selectedInvoice?.invoiceDues} />
                </tr>

                <tr>
                  <TitleCell>Additional Checkout Items:</TitleCell>
                  <MoneyCell amount={selectedInvoice?.checkoutItemsAmount} />
                </tr>

                <tr>
                  <TitleCell>Discount:</TitleCell>
                  <MoneyCell color="red" amount={selectedInvoice?.discountAmount} />
                </tr>


                <tr>
                  <TitleCell>Subtotal:</TitleCell>
                  <MoneyCell amount={selectedInvoice?.invoiceSubtotal} />
                </tr>

                <tr>
                  <TitleCell>Service Fee:</TitleCell>
                  <MoneyCell amount={selectedInvoice?.processingFee} />
                </tr>

                <tr>
                  <TitleCell bold={true}>Total Registration Cost</TitleCell>
                  <MoneyCell amount={selectedInvoice?.invoiceTotal} bold={true} />
                </tr>
              </table>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  )
}
