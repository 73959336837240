import React from "react";
import { validateEmail, gen } from "./utils";
import PromoteDemoteUser from "./PromoteDemoteUser";
import { Modal, message, Row, Col, Input, Select, Button, Spin } from "antd";

const { Option } = Select;

class EditUserClubModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: true,
      userIdEdit: props.userIdEdit,
      email: props.email || "",
      firstName: props.firstName || "",
      lastName: props.lastName || "",
      phoneNumber: props.phoneNumber || "",
      title: props.title || "SwimminglyRep",
      role: props.role || "3",  // Ensure the role is initialized from props
      emailMessage: "",
      firstNameMessage: "",
      lastNameMessage: "",
      phoneNumberMessage: "",
      loading: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      userIdEdit: nextProps.userIdEdit,
      email: nextProps.email || "",
      firstName: nextProps.firstName || "",
      lastName: nextProps.lastName || "",
      phoneNumber: nextProps.phoneNumber || "",
      title: nextProps.title || "MeetCentralRep",
      role: nextProps.role || "3",  // Ensure the role is updated from nextProps
    });
  }

  onSubmitFunc = async () => {
    let isGood = this.onCheckValues();
    if (!isGood) {
      return;
    }

    this.setState({ loading: true });

    const data = {
      page: "club",
      email: this.state.email,
      FirstName: this.state.firstName,
      LastName: this.state.lastName,
      PhoneNumber: this.state.phoneNumber,
      role: parseInt(this.state.role, 10),  // Ensure the role is passed as a number
      club_id: this.props.clubId,
      is_edit: true,
      id: this.state.userIdEdit,
      send_validation_now: false,
      password: null,
      userId: this.props.user.userId,
    };

    // Only include the title if the role is not 5 (Parent/Guardian)
    if (parseInt(this.state.role, 10) !== 5) {
      data.title = this.state.title;
    }

    try {
      const response = await fetch(gen("/api/registerPost"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();

      this.setState({ loading: false });
      this.props.setVisible(false);

      if (result.error) {
        message.error(result.error);
      } else {
        message.success("User settings have been updated");

        // Use the onUserUpdated callback if provided
        if (this.props.onUserUpdated) {
          this.props.onUserUpdated({
            userIdEdit: this.state.userIdEdit,
            email: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            phoneNumber: this.state.phoneNumber,
            role: parseInt(this.state.role, 10),  // Ensure the role is passed as a number
            title: parseInt(this.state.role, 10) !== 5 ? this.state.title : null,  // Only include title if the role is not 5
          });
        }
      }
    } catch (error) {
      this.setState({ loading: false });
      message.error("An error occurred. Please try again.");
    }
  };

  onCheckValues = () => {
    let isGood = true;
    if (!validateEmail(this.state.email)) {
      this.setState({ emailMessage: "Not a valid email" });
      isGood = false;
    }

    if (!this.state.firstName) {
      this.setState({ firstNameMessage: "Not a valid first name" });
      isGood = false;
    }

    if (this.state.firstName.length < 3) {
      this.setState({
        firstNameMessage: "First name must be at least 3 characters",
      });
      isGood = false;
    }

    if (this.state.firstName.length > 255) {
      this.setState({
        firstNameMessage: "First name must be less than 255 characters",
      });
      isGood = false;
    }

    if (!this.state.lastName) {
      this.setState({ lastNameMessage: "Not a valid last name" });
      isGood = false;
    }

    if (this.state.lastName.length < 3) {
      this.setState({
        lastNameMessage: "Last name must be at least 3 characters",
      });
      isGood = false;
    }

    if (this.state.lastName.length > 255) {
      this.setState({
        lastNameMessage: "Last name must be less than 255 characters",
      });
      isGood = false;
    }

    if (!this.state.phoneNumber) {
      this.setState({ phoneNumberMessage: "Not a valid phone number" });
      isGood = false;
    }
    let onlyDigits = this.state.phoneNumber.replace(/[^0-9]/g, "");
    if (onlyDigits.length < 10) {
      this.setState({ phoneNumberMessage: "Needs at least 10 digits" });
      isGood = false;
    }
    return isGood;
  };

  render() {
    return (
      <Modal
        visible={this.props.visible}
        title="Edit User"
        onCancel={() => {
          this.setState({ loading: false });
          this.props.setVisible(false);
        }}
        footer={[
          <Button
            key="Cancel"
            onClick={() => {
              this.setState({ loading: false });
              this.props.setVisible(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={this.state.loading}
            onClick={this.onSubmitFunc}
          >
            {this.state.loading ? <Spin /> : "Update User's Settings"}
          </Button>,
        ]}
      >
        <div>
          <Row style={{ paddingBottom: "15px" }}>
            <Col span={8}>Email</Col>
            <Col span={16}>
              <Input
                id="email"
                name="email"
                value={this.state.email}
                onChange={(e) =>
                  this.setState({ email: e.target.value, emailMessage: "" })
                }
                placeholder="Email Address"
                type="email"
                disabled
              />
              {this.state.emailMessage && (
                <span id="spanUserName" className="field-validation-error">
                  {this.state.emailMessage}
                </span>
              )}
            </Col>
          </Row>

          <Row style={{ paddingBottom: "15px" }}>
            <Col span={8}>First Name</Col>
            <Col span={16}>
              <Input
                id="FirstName"
                name="FirstName"
                placeholder="First Name"
                type="text"
                value={this.state.firstName}
                onChange={(e) =>
                  this.setState({
                    firstName: e.target.value,
                    firstNameMessage: "",
                  })
                }
              />
              {this.state.firstNameMessage && (
                <span id="spanUserName" className="field-validation-error">
                  {this.state.firstNameMessage}
                </span>
              )}
            </Col>
          </Row>

          <Row style={{ paddingBottom: "15px" }}>
            <Col span={8}>Last Name</Col>
            <Col span={16}>
              <Input
                name="LastName"
                id="LastName"
                placeholder="Last Name"
                type="text"
                value={this.state.lastName}
                onChange={(e) =>
                  this.setState({
                    lastName: e.target.value,
                    lastNameMessage: "",
                  })
                }
              />
              {this.state.lastNameMessage && (
                <span id="spanUserName" className="field-validation-error">
                  {this.state.lastNameMessage}
                </span>
              )}
            </Col>
          </Row>

          <Row style={{ paddingBottom: "15px" }}>
            <Col span={8}>Phone Number</Col>
            <Col span={16}>
              <Input
                name="PhoneNumber"
                id="PhoneNumber"
                placeholder="Phone Number"
                type="text"
                value={this.state.phoneNumber}
                onChange={(e) =>
                  this.setState({
                    phoneNumber: e.target.value,
                    phoneNumberMessage: "",
                  })
                }
              />
              {this.state.phoneNumberMessage && (
                <span id="spanUserName" className="field-validation-error">
                  {this.state.phoneNumberMessage}
                </span>
              )}
            </Col>
          </Row>

          {parseInt(this.state.role, 10) !== 5 && (
            <Row style={{ paddingBottom: "15px" }}>
              <Col span={8}>Title</Col>
              <Col span={16}>
                <Select
                  span={100}
                  style={{ width: "100%" }}
                  id="person_title"
                  name="title"
                  value={this.state.title}
                  onChange={(val) => this.setState({ title: val })}
                >
                  <Option value="SwimminglyRep">Swimmingly Rep</Option>
                  <Option value="HeadCoach">Head Coach</Option>
                  <Option value="Assistant Coach">Assistant Coach</Option>
                  <Option value="ClubTreasure">Club Treasurer</Option>
                  <Option value="LeaguePresident">League President</Option>
                  <Option value="LeagueTreasurer">League Treasurer</Option>
                  <Option value="League Board">League Board</Option>
                </Select>
              </Col>
            </Row>
          )}

          <Row>
            <Col span={8}>Permission Level</Col>
            <Col span={16}>
              <PromoteDemoteUser
                userId={this.props.user.userId}
                targetUserId={this.props.userIdEdit}
              />
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
}

export default EditUserClubModal;
