import React from "react"
import { Input, Button, message } from "antd"
import { gen } from "./utils"

export default class PasswordResetEmail extends React.Component {
  state = {
    email: "",
    loading: false,
  }

  onSubmit = async () => {
    await this.setState({ loading: true })
    let rawResults
    try {
      rawResults = await fetch(gen(`/api/passwordResetEmail`), {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        //credentials: "same-origin",
        body: JSON.stringify({ email: this.state.email }),
      })
    } catch (error) {
      console.log("error")
      console.log(error)
    }
    let results = await rawResults.json()
    console.log(results)
    this.setState({ loading: false })
    message.success("Email sent")
    // $.ajax({
    //   url: gen("/api/password/email"),
    //   method: "POST",
    //   data: {
    //     email: this.state.email,
    //   },
    // }).done((err) => {
    //   this.setState({ loading: false })
    //   message.success("Email sent")
    // })
  }

  render() {
    return (
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "30px" }}
      >
        <section className="panel" style={{ width: "350px" }}>
          <div className="panel-heading" style={{ fontSize: "20px" }}>
            Reset Password
          </div>
          <div className="ourpanel-body">
            <Input
              value={this.state.email}
              onChange={(e) => this.setState({ email: e.target.value })}
              onPressEnter={this.onSubmit}
              placeholder="Email Address"
            />
            <Button
              style={{ width: "100%", marginTop: "10px" }}
              type="primary"
              onClick={this.onSubmit}
              loading={this.state.loading}
            >
              Send Password Reset Link
            </Button>
          </div>
        </section>
      </div>
    )
  }
}
