import moment from "moment-timezone"
import { convertAgeGroupToAgeRange } from "./utils"

function formatSpaces(
  inputString: string | number,
  theLength: number,
  justify: string = "left",
): string {
  let out: string = ""
  let theString: string = ""
  // if it's missing, just make a bunch of blank spaces
  if (inputString === null || inputString === undefined) {
    out = "".padStart(theLength)
  } else {
    // if it's not missing, first make sure you're working with a string
    theString = inputString.toString()
    // then format the string based on the specified justification
    if (justify === "right") {
      out = theString.slice(0, theLength).padStart(theLength)
    } else if (justify === "left") {
      out = theString.slice(0, theLength).padEnd(theLength)
    } else if (justify === "center") {
      let curLength: number = theString.length
      if (curLength >= theLength) {
        out = theString.slice(0, theLength)
      } else {
        out = theString
          .padStart(Math.floor((theLength - curLength) / 2))
          .padEnd(Math.ceil((theLength - curLength) / 2))
      }
    }
  }
  return out
}

let numberMapping: { [key: number]: string } = {
  1: "A",
  2: "B",
  3: "C",
  4: "D",
  5: "E",
  6: "F",
  7: "G",
  8: "H",
  9: "I",
  10: "J",
  11: "K",
  12: "L",
  13: "M",
  14: "N",
  15: "O",
  16: "P",
  17: "Q",
  18: "R",
  19: "S",
  20: "T",
  21: "U",
  22: "V",
  23: "W",
  24: "X",
  25: "Y",
  26: "Z",
}

function makeA0(meetDate: string): string {
  let A0 = "A0" // start with record type
  A0 += "1" // add org code of 1, which represents USA meet. there is no summer league code.
  A0 += "Ver. 3.0" // add version number
  A0 += "02" // add file code of 02 for meet results
  A0 = A0.padEnd(43) // add blank spaces
  A0 += formatSpaces("Swimmingly", 20) // add software name
  A0 += formatSpaces("Ver 4.0", 10) // add software version
  A0 += formatSpaces("Swimmingly", 20) // add contact name
  A0 += "866-377-7946" // add contact phone
  A0 += meetDate // add meet date
  return A0.padEnd(160)
}

const fixMeetName = (meetName: string): string => {
  if (!meetName || meetName.trim() === "") return "Swimmingly Meet"
  if (meetName.length > 29) return meetName.slice(0, 26) + "..."
  return meetName
}

function makeB1(
  meetName: string,
  meetLocation: string,
  leagueCity: string,
  leagueState: string,
  meetDate: string,
  meetCourse: string,
): string {
  let B1 = "B1"
  B1 += "1" // add org code of 1, which represents USA meet. there is no summer league code.
  B1 = B1.padEnd(11) // add blank space
  B1 += formatSpaces(fixMeetName(meetName), 30) // add the meet name
  B1 += formatSpaces(meetLocation, 22) // add the meet location
  B1 = B1.padEnd(85) // add meet location line 2
  B1 += formatSpaces(leagueCity, 20) // add meet city
  B1 += formatSpaces(leagueState, 2) // add meet state
  B1 = B1.padEnd(117) // add postal code
  B1 += "USA" // add country code
  B1 += "1" // add Meet Code of 1 for invitational
  B1 += meetDate // add meet start date
  B1 += meetDate // add meet end date
  B1 = B1.padEnd(149) // add blank space
  B1 += meetCourse // add meet course
  return B1.padEnd(160)
}

function makeC1(
  theLSC: string,
  clubCode: string,
  shortClubName: string,
  clubName: string,
): string {
  let C1 = "C1"
  C1 += "1" // Add Org Code 1 for USA meet (note there is no summer league code)
  C1 = C1.padEnd(11) // Add blank space
  C1 += formatSpaces(theLSC, 2) // Add lsc
  C1 += formatSpaces(clubCode, 4) // Add club code
  C1 += formatSpaces(clubName, 30) // Add club name
  C1 += formatSpaces(shortClubName, 16) // Add short club name
  C1 = C1.padEnd(139) // Add blank space
  C1 += "USA" // Add country code
  C1 = C1.padEnd(149) // Add blank space
  // add team code 5th character if it exists
  if (clubCode.length === 5) {
    C1 += clubCode.slice(4, 5)
  }
  return C1.padEnd(160)
}

function makeD0(
  swimmerName: string,
  swimmerDOB: string,
  swimmerAge: string,
  swimmerGender: string,
  eventGender: string,
  eventDistance: string,
  eventStroke: string,
  eventNumber: string,
  eventAge: string,
  seedTime: number | undefined,
  resultTime: number,
  disqualified: string,
  resultHeat: string,
  resultLane: string,
  resultPlace: string,
  resultPoints: number,
  meetCourse: string,
  meetDate: string,
  relayOnly: boolean,
  usaSwimmingId: string | null = null,
) {
  let D0 = "D0"
  D0 += "1" // Add Org Code 1 for USA meet (note there is no summer league code)
  D0 = D0.padEnd(11) // Add blank space
  D0 += formatSpaces(swimmerName.replace(/'/g, ""), 28) // Add swimmer name
  D0 = D0.padEnd(39) // blank space up until USA Swimming Id
  if ((usaSwimmingId || "").trim() !== "") {
    let idToAdd: string = (usaSwimmingId || "").trim()
    D0 += idToAdd.padEnd(14).slice(0, 12) // Add USA Swimming ID
  }
  D0 = D0.padEnd(51) // blank space to the end of the USA Swimming Id section
  D0 = D0.padEnd(55) // Add blank space
  D0 += swimmerDOB.toString().padStart(8, "0") // Add swimmer birthday
  D0 += formatSpaces(swimmerAge, 2) // Add swimmer age
  D0 += swimmerGender // Add swimmer gender
  if (relayOnly === true) {
    D0 = D0.padEnd(72)
  } else {
    D0 += eventGender // Add event gender
    D0 += formatSpaces(eventDistance, 4, "left") // Add distance
    D0 += eventStroke // Add stroke
  }
  D0 += formatSpaces(eventNumber, 4) // Add event number
  if (relayOnly === true) {
    D0 = D0.padEnd(80)
  } else {
    D0 += eventAge // Add event age group
  }
  D0 += meetDate // Add event date
  if (relayOnly === true) {
    D0 = D0.padEnd(96)
  } else if (seedTime === 0.0 || seedTime === null) {
    D0 += "     NT " // Add seed time
    D0 += " " // Add meet course
  } else if (seedTime === undefined) {
    D0 += "         " // no seed time because this is a results sd3
  } else {
    D0 += formatSpaces(seedTime, 8, "right") // Add seed time
    D0 += meetCourse // Add meet course
  }
  D0 = D0.padEnd(115)
  if (disqualified) {
    D0 += "      DQX"
  } else {
    if (resultTime === 0 || resultTime === null) {
      D0 += "     NT " // Add time
      D0 += " " // Add meet course
    } else if (resultTime === undefined) {
      D0 += "         " // no time because this is a results sd3
    } else {
      D0 += formatSpaces(resultTime, 8, "right") // add time
      D0 += meetCourse
    }
  }
  D0 = D0.padEnd(128)
  D0 += resultHeat ? `${resultHeat}`.padEnd(2) : "  "
  D0 += resultLane ? `${resultLane}`.padEnd(2) : "  "
  D0 = D0.padEnd(135) + (resultPlace ? `${resultPlace}`.padEnd(3) : "   ")
  D0 +=
    resultPoints === null || resultPoints === undefined
      ? "    "
      : `${Math.round(resultPoints * 100) / 100}`.slice(0, 4).padEnd(4)
  return D0.padEnd(160)
}

function makeD3(usaSwimmingId: string | null = null) {
  let idForAdd: string = (usaSwimmingId || "").trim()
  if (idForAdd === "" || idForAdd.length > 14) {
    return "D3".padEnd(160)
  } else {
    let D3 = "D3"
    D3 += idForAdd
    return D3.padEnd(160)
  }
}

function makeE0(
  relayNumber: number,
  theLSC: string,
  clubCode: string,
  numSwimmers: string,
  eventGender: string,
  eventDistance: string,
  eventStroke: string,
  eventNumber: string,
  resultHeat: string,
  resultLane: string,
  seedTime: number | undefined,
  resultTime: number,
  disqualified: boolean,
  eventAge: string,
  resultPlace: string,
  resultPoints: string,
  meetCourse: string,
  theMeetDate: string,
) {
  let E0 = "E0"
  E0 += "1" // Add Org Code 1 for USA meet (note there is no summer league code)
  E0 = E0.padEnd(11) // Add blank space
  E0 += numberMapping[relayNumber] // Add relay team ('A' relay, 'B' relay, etc.)
  E0 += formatSpaces(theLSC, 2) // Add the lsc
  E0 += formatSpaces(clubCode, 4) // Add the club code
  E0 += formatSpaces(numSwimmers, 2, "right") // Add the number of swimmers in the relay
  E0 += eventGender // Add the event gender
  E0 += formatSpaces(eventDistance, 4, "right") // Add the event distance
  E0 += eventStroke // Add the event stroke
  E0 += formatSpaces(eventNumber, 4) // Add the event number
  E0 += eventAge // Add the event age
  E0 = E0.padEnd(37) // Add blank space
  E0 += theMeetDate.padStart(8, "0") // Add meet date
  if (seedTime === 0 || seedTime === null) {
    E0 += "     NT " // Add seed time
    E0 += " " // Add meet course
  } else if (seedTime === undefined) {
    E0 += "         "
  } else {
    E0 += formatSpaces(seedTime, 8, "right") // Add seed time
    E0 += meetCourse // Add meet course
  }
  E0 = E0.padEnd(72)
  if (disqualified) {
    E0 += "      DQX"
  } else {
    if (resultTime === 0 || resultTime === null) {
      E0 += "     NT " // Add seed time
      E0 += " " // Add meet course
    } else if (resultTime === null) {
      E0 += "         "
    } else {
      E0 += formatSpaces(resultTime, 8, "right") // Add seed time
      E0 += meetCourse // Add meet course
    }
  }
  E0 = E0.padEnd(85) + (resultHeat ? `${resultHeat}`.padEnd(2) : "  ")
  E0 = E0.padEnd(87) + (resultLane ? `${resultLane}`.padEnd(2) : "  ")
  E0 = E0.padEnd(92) + (resultPlace ? `${resultPlace}`.padEnd(3) : "   ")
  E0 = E0.padEnd(95) + (resultPoints ? `${resultPoints}`.padEnd(4) : "    ")
  return E0.padEnd(160)
}

function makeF0(
  theLSC: string,
  clubCode: string,
  relayNumber: number,
  swimmerName: string,
  swimmerDOB: string,
  swimmerAge: string,
  swimmerGender: string,
  swimmerPosition: number,
  usaSwimmingId: string | null = null,
) {
  let F0 = "F0"
  F0 += "1" // Add Org Code 1 for USA meet (note there is no summer league code)
  F0 = F0.padEnd(15) // Add blank space
  F0 += formatSpaces(theLSC, 2) // Add lsc
  F0 += formatSpaces(clubCode, 4) // Add club code
  F0 += numberMapping[relayNumber] // Add relay team
  F0 += formatSpaces(swimmerName.replace(/'/g, ""), 28) // Add the swimmer's name
  F0 = F0.padEnd(50) // blank space up until usa swimming id
  if (usaSwimmingId && usaSwimmingId.trim() !== "") {
    F0 += usaSwimmingId.padEnd(14).slice(0, 12)
  }
  F0 = F0.padEnd(62) // blank space until the end of the usa swimming id
  F0 = F0.padEnd(65) // Add blank space
  F0 += swimmerDOB.toString().padStart(8, "0") // Add birthday
  F0 += formatSpaces(swimmerAge, 2) // Add swimmer age
  F0 += swimmerGender // Add swimmer gender
  F0 += `${swimmerPosition}${swimmerPosition}${swimmerPosition}` // Add order codes
  F0 = F0.padEnd(92)
  if (usaSwimmingId && usaSwimmingId.trim() !== "") {
    F0 += usaSwimmingId.padEnd(14).slice(0, 14)
  }
  return F0.padEnd(160)
}

function makeZ0(
  numB: number,
  numC: number,
  numD: number,
  numSwimmers: number,
  numE: number,
  numF: number,
  numG: number,
): string {
  let Z0 = "Z0"
  Z0 += "1" // Add Org Code 1 for USA meet (note there is no summer league code)
  Z0 = Z0.padEnd(11) // Add blank space
  Z0 += "02" // Add file code 01 for Meet Results
  Z0 += formatSpaces("Created with Swimmingly", 30) // Add notes
  Z0 += formatSpaces(numB, 3, "right") // Add number of B records
  Z0 += formatSpaces(numB, 3, "right") // Add number of meets
  Z0 += formatSpaces(numC, 4, "right") // Add number of C records
  Z0 += formatSpaces(numC, 4, "right") // Add number of teams
  Z0 += formatSpaces(numD, 6, "right") // Add number of D records
  Z0 += formatSpaces(numSwimmers, 6, "right") // Add number of swimmers
  Z0 += formatSpaces(numE, 5, "right") // Add number of E records
  Z0 += formatSpaces(numF, 5, "right") // Add number of F records
  Z0 += formatSpaces(numG, 5, "right") // Add number of G records
  return Z0.padEnd(160)
}

export function createResultsSD3(meetResults: Array<any>) {
  console.log(meetResults)
  let [numB, numC, numD, numE, numF, numG] = [0, 0, 0, 0, 0, 0]
  if (!meetResults || meetResults.length === 0) {
    console.log("no results!")
    return
  }

  let meetDate = moment.utc(meetResults[0].meetDate)
  let {
    meetName,
    meetLocation,
    city,
    state,
    course,
    lsc,
    teamCode,
    club,
    clubLongName,
  } = meetResults[0]

  let outputData: {
    individualResults: Array<any>
    relayResults: Array<any>
  } = { individualResults: [], relayResults: [] }

  let individualResults = meetResults.filter(
    (x) =>
      x.is_relay === 0 ||
      x.is_relay.toString().toLowerCase() === "false" ||
      x.is_realy === false,
  )
  let individualSwimmers = individualResults
    .map((x) => x.swimmer_id)
    .filter((v, i, a) => a.indexOf(v) === i)

  for (let i = 0; i < individualSwimmers.length; i++) {
    let theResults: Array<any> = individualResults.filter(
      (x) => x.swimmer_id === individualSwimmers[i],
    )
    outputData.individualResults.push(theResults)
  }

  // sort alphabetically
  outputData.individualResults = outputData.individualResults.sort((a, b) =>
    b[0].swimmer_name > a[0].swimmer_name ? -1 : 1,
  )

  let relayResults = meetResults.filter(
    (x) =>
      x.is_relay === 1 ||
      x.is_relay.toString().toLowerCase() === "true" ||
      x.is_realy === true,
  )
  let theRelays = relayResults
    .map(
      (x) =>
        `${x.event_number}|${x.heat_identifier || "no_identifier"}|${
          x.heat_number
        }|${x.lane_number}`,
    )
    .filter((v, i, a) => a.indexOf(v) === i)

  for (let i = 0; i < theRelays.length; i++) {
    let theResults = relayResults.filter(
      (x) =>
        `${x.event_number}|${x.heat_identifier || "no_identifier"}|${
          x.heat_number
        }|${x.lane_number}` === theRelays[i],
    )
    outputData.relayResults.push(theResults)
  }

  let sd3Text =
    makeA0(meetDate.format("MMDDYYYY")) +
    "\r\n" +
    makeB1(
      meetName,
      meetLocation,
      city,
      state,
      meetDate.format("MMDDYYYY"),
      course,
    ) +
    "\r\n"
  numB += 1

  let resultsClubs = meetResults
    .map((x) => x.clubLongName)
    .filter((v, i, a) => a.indexOf(v) === i)

  for (let theClub of resultsClubs) {
    let clubIndividualResults = outputData.individualResults.filter(
      (x) => x[0].clubLongName === theClub,
    )
    let clubRelayResults = outputData.relayResults.filter(
      (x) => x[0].clubLongName === theClub,
    )

    if (clubIndividualResults.length > 0) {
      lsc = clubIndividualResults[0][0].lsc
      teamCode = clubIndividualResults[0][0].teamCode
      club = clubIndividualResults[0][0].club
      clubLongName = clubIndividualResults[0][0].clubLongName
    } else if (clubRelayResults.length > 0) {
      lsc = clubRelayResults[0][0].lsc
      teamCode = clubRelayResults[0][0].teamCode
      club = clubRelayResults[0][0].club
      clubLongName = clubRelayResults[0][0].clubLongName
    }

    sd3Text += makeC1(lsc, teamCode, club, clubLongName) + "\r\n"
    numC += 1

    for (let i = 0; i < clubIndividualResults.length; i++) {
      let theSwimmer = clubIndividualResults[i]
      for (let j = 0; j < theSwimmer.length; j++) {
        let theSwim = theSwimmer[j]
        sd3Text +=
          makeD0(
            theSwim.swimmer_name,
            moment.utc(theSwim.date_of_birth).format("MMDDYYYY"),
            theSwim.swimmer_league_age,
            theSwim.swimmer_gender,
            theSwim.event_gender,
            theSwim.distance,
            theSwim.sd3_stroke,
            theSwim.event_number,
            convertAgeGroupToAgeRange(theSwim.age_group),
            undefined,
            theSwim.time,
            theSwim.disqualified,
            theSwim.heat_number,
            theSwim.lane_number,
            theSwim.resultPlace,
            theSwim.points,
            theSwim.course,
            meetDate.format("MMDDYYYY"),
            false,
            theSwim.usaSwimmingId,
          ) + "\r\n"
        numD += 1
        if (j === 0) {
          sd3Text += makeD3(theSwim.usaSwimmingId) + "\r\n"
        }
      }
    }
    clubRelayResults.sort((a, b) => {
      if (b[0].event_number < a[0].event_number) {
        return 1
      } else if (b[0].event_number > a[0].event_number) {
        return -1
      } else if (b[0].official_time < a[0].official_time) {
        return 1
      }
      return -1
    })
    let theTeam = 0
    let curRelayNumber = 0
    if (clubRelayResults.length > 0) {
      curRelayNumber = clubRelayResults[0].event_number
    }
    for (let i = 0; i < clubRelayResults.length; i++) {
      let theRelay = clubRelayResults[i]
      if (curRelayNumber === theRelay[0].event_number) {
        theTeam += 1
      } else {
        curRelayNumber = theRelay[0].event_number
        theTeam = 1
      }

      sd3Text +=
        makeE0(
          theTeam,
          lsc,
          teamCode,
          theRelay.length,
          theRelay[0].event_gender,
          theRelay[0].distance,
          theRelay[0].sd3_stroke,
          theRelay[0].event_number,
          theRelay[0].heat_number,
          theRelay[0].lane_number,
          undefined,
          theRelay[0].time,
          theRelay[0].disqualified,
          convertAgeGroupToAgeRange(theRelay[0].age_group),
          theRelay[0].resultPlace,
          theRelay[0].points,
          theRelay[0].course,
          meetDate.format("MMDDYYYY"),
        ) + "\r\n"
      numE += 1
      for (let j = 0; j < theRelay.length; j++) {
        let theSwimmer = theRelay[j]
        sd3Text +=
          makeF0(
            lsc,
            teamCode,
            theTeam,
            theSwimmer.swimmer_name,
            moment.utc(theSwimmer.date_of_birth).format("MMDDYYYY"),
            theSwimmer.swimmer_league_age,
            theSwimmer.swimmer_gender,
            j + 1,
            theSwimmer.usaSwimmingId,
          ) + "\r\n"
        numF += 1
      }
    }
  }
  sd3Text += makeZ0(
    numB,
    numC,
    numD,
    individualSwimmers.length,
    numE,
    numF,
    numG,
  )
  return sd3Text
}
