import { Amounts, formatMoney, IClubSeasonInfo } from "../../utils"

export class FeeHelper {
    swimminglyMembershipFees = (clubSeason: IClubSeasonInfo | null, frontendUsingRegistration: boolean): string => {
        const amount = (() => {
          if (clubSeason === null) {
            return 0
          } else if (frontendUsingRegistration && clubSeason.corporatePaymentId) {
            return Amounts.membershipFeeForFormerLumpSumClubs
          } else {
            return (clubSeason.clubPays ?? 0) + (clubSeason.leaguePays ?? 0) + (clubSeason.parentPays ?? 0)
          }
        })()
      
        return formatMoney(amount)
    }
}