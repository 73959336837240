import React from "react"
import { actionItemStyle, download, gen, swimminglyApi } from "./utils"
import { Button, message } from "antd"
export default class ParentHeatSheet extends React.Component {
  downloadHeatSheet = () => {
    swimminglyApi
      .get(
        gen(
          `/api/getHeatSheet/meet=${this.props.meetId}/club=${this.props.clubId}`,
        ),
      )
      .then((file) => {
        if (file) {
          download(file, `HeatSheet.pdf`)
        } else {
          message.error("Uh oh - we had a problem finding the heatsheet")
        }
      })
  }

  render() {
    return (
      <Button
        type="link"
        onClick={() => this.downloadHeatSheet()}
        style={actionItemStyle}
      >
        {this.props.title}
      </Button>
    )
  }
}
