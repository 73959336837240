import { gen, IClubSeasonInfo, swimminglyApi } from "../../utils"
import { ISeasonDetails } from "../../../App"

// models the API to retreive season information for a club
export class SeasonHelper {
    api = swimminglyApi;
    
    public constructor(api = swimminglyApi) {
        this.api = api
    }

    /**
    * Returns an array of `ISeasonDetails` for each upcoming season for the given `clubId`
    */
    public async getUpcomingSeasonDetails(clubId: number, onlyConfirmed: boolean = false): Promise<ISeasonDetails[]>  {

        const {seasons}: {seasonInfoStatus: string ,seasons: ISeasonDetails[]|undefined} 
        = await this.api.get(gen(`/api/checkClubsSeasonStatus/${clubId}`))

        return seasons?.filter( (season) => {
            return (onlyConfirmed ? Boolean(season.seasonSetupConfirmedBy) : true)                
        }) ?? []
    }

    /**
    * Returns a single `ISeasonDetails` for the given `clubId` and `seasonId`
    */
    public async getSeasonDetails(clubId: number, seasonId: number): Promise<ISeasonDetails>  {

        const {seasonInfo}: {seasonInfoStatus: string,seasonInfo: ISeasonDetails|undefined} 
            = await this.api.get(gen(`/api/getPaymentInfoForSeason?clubId=${clubId}&seasonId=${seasonId}`))

        if (seasonInfo !== undefined) {
            return Promise.resolve(seasonInfo)
        } else {
            return Promise.reject("Unable to retreive season information")
        }
        
    }

    public async getClubSeason(clubId: number, seasonId: number): Promise<IClubSeasonInfo>  {

        try {
            const {seasons}: {status: string,seasons: IClubSeasonInfo[]|undefined} 
            = await this.api.get(gen(`/api/getSwimSeasonsForClub/${clubId}/${seasonId}`))

        if (seasons !== undefined && seasons.length > 0) {
            return Promise.resolve(seasons[0])
        } else {
            return Promise.reject("Unable to retreive club season information")
        }
            
        } catch (error) {
            console.log(error)
            return Promise.reject("Error retrieving club season information")

        }
        
    }
    public async getClubSeasonsForGuardian(guardianId: number): Promise<IClubSeasonInfo[]>  {

        try {
            const {seasons}: {status: string,seasons: IClubSeasonInfo[]|undefined} 
            = await this.api.get(gen(`/api/getSwimSeasonsForGuardian/${guardianId}`))

        if (seasons !== undefined && seasons.length > 0) {
            return Promise.resolve(seasons)
        } else {
            return Promise.reject("Unable to retreive club season information")
        }
            
        } catch (error) {
            console.log(error)
            return Promise.reject("Error retrieving club season information")

        }
        
    }



}
  
