import React from "react"
import $ from "jquery"
import { message, Spin } from "antd"
import { actionItemStyle, gen } from "./utils"
export default class CircleSeedingButton extends React.Component {
  state = {
    loading: false,
  }
  onClickCircle = () => {
    this.setState({ loading: true })
    let meet = this.props.meet
    $.ajax({
      url: gen("/api/circleSeedingPost"),
      method: "POST",
      dataType: "json",
      data: {
        meetId: meet.meetId,
      },
    }).done((data) => {
      message.success("You're meet has been circle seated.")
      this.setState({ loading: false })
      window.location.reload()
    })
  }
  render() {
    // console.log(meet)
    // Slowest to Fastest            // By Time
    return (
      <span onClick={this.onClickCircle} style={actionItemStyle}>
        {this.state.loading && (
          <Spin size="small" style={{ marginRight: "5px" }} />
        )}
        Circle Seeding
      </span>
    )
  }
}
