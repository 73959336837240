import React, { useEffect, useState } from "react"
import moment from "moment-timezone"
import CenterSpin from "./CenterSpin"
import {
  roleMapping,
  titleMapping,
  toLowerN,
  gen,
  swimminglyApi,
} from "./utils"
import { Table, message, Input, Tag, Tooltip } from "antd"
import { QuestionCircleOutlined } from "@ant-design/icons"

interface ILatestUsers {
  name: string
  email: string
  phoneNumber: string
  role: number
  title: string
  created_at: string
  clubNameLong: string
  leagueName: string
  activated: 0 | 1
}

export default function RecentUsers(): JSX.Element | null {
  const [users, setUsers] = useState<ILatestUsers[]>([])
  const [loaded, setLoaded] = useState(true)
  const [value, setValue] = useState<string | null>(null)
  const [curUsers, setCurUsers] = useState<ILatestUsers[]>([])

  useEffect(() => {
    let mounted = true
    setLoaded(false)
    swimminglyApi
      .post(gen("/api/latestUsersPost"))
      .body({})
      .then((data) => {
        if (mounted) {
          setUsers(data.users)
        }
      })
      .catch((err) => {
        message.error("Error loading latest users")
      })
      .finally(() => mounted && setLoaded(true))
    return () => {
      mounted = false
    }
  }, [])

  useEffect(() => {
    let mounted = true
    if (value) {
      const newCurUsers = users.filter((el) => {
        let lowerState = toLowerN(value)
        return (
          toLowerN(el.name).includes(lowerState) ||
          toLowerN(el.email).includes(lowerState) ||
          toLowerN(el.phoneNumber).includes(lowerState) ||
          toLowerN(el.clubNameLong).includes(lowerState) ||
          toLowerN(el.leagueName).includes(lowerState) ||
          toLowerN(roleMapping[el.role]).includes(lowerState) ||
          toLowerN((titleMapping as any)[el.title]).includes(lowerState) ||
          toLowerN(moment(el.created_at).format("YYYY MM/DD h:mm a")).includes(
            lowerState,
          )
        )
      })
      if (mounted) {
        setCurUsers(newCurUsers)
      }
    } else {
      if (mounted) {
        setCurUsers(users)
      }
    }
  }, [users, value])

  if (!loaded) {
    return (
      <section className="panel">
        <header className="panel-heading">
          <h2 className="panel-title">New Admin Activity</h2>
        </header>
        <div className="ourpanel-body">
          <CenterSpin />
        </div>
      </section>
    )
  }

  return (
    <section className="panel">
      <header className="panel-heading">
        <h2 className="panel-title">
            New Admin Activity
            <Tooltip title="This table shows League/Club Admin activity. For Parent / Guardians, refer to the user management table">
              <QuestionCircleOutlined style={{ marginLeft: 8, cursor: "pointer", color: "gray" }} />
            </Tooltip>
        </h2>
      </header>
      <div className="ourpanel-body">
        <Input
          type="text"
          value={value || ""}
          onChange={(e) => setValue(e.target.value)}
          style={{ marginBottom: "10px", width: "200px" }}
          placeholder="Search by any keyword"
        />
        <Table
          bordered
          size="middle"
          dataSource={curUsers}
          rowKey={(user) => `${user.name}${user.phoneNumber}${user.email}`}
          rowClassName={(user) => (!user.activated ? "absolutelyOrange" : "")}
          columns={[
            {
              title: "Name",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "Email",
              dataIndex: "email",
              key: "email",
              render: (email) => <a href={`mailto:${email}`}>{email}</a>,
            },
            {
              title: "Phone",
              dataIndex: "phoneNumber",
              key: "phoneNumber",
            },
            {
              title: "Club",
              dataIndex: "clubNameLong",
              key: "clubNameLong",
              render: (clubNameLong) =>
                clubNameLong ? (
                  clubNameLong
                ) : (
                  <Tooltip title="See user profile for club">
                    <span style={{ cursor: "pointer" }}>
                      <QuestionCircleOutlined style={{ color: "#ad8b00" }} />
                    </span>
                  </Tooltip>
                ),
            },
            {
              title: "League",
              dataIndex: "leagueName",
              key: "leagueName",
            },
            {
              title: "Admin",
              dataIndex: "role",
              key: "role",
              render: (role) => {
                if (roleMapping[role] === "Club Admin") {
                  return <Tag color="purple">{roleMapping[role]}</Tag>;
                } else if (roleMapping[role] === "League Admin") {
                  return <Tag color="cyan">{roleMapping[role]}</Tag>;
                } else {
                  return roleMapping[role];
                }
              },
            },
            {
              title: "Title",
              dataIndex: "title",
              key: "title",
              render: (title) => (titleMapping as any)[title],
            },
            {
              title: "Date Created",
              dataIndex: "created_at",
              key: "created_at",
              render: (created_at) =>
                moment(created_at).format("YYYY MM/DD h:mm a"),
            },
          ]}
        />
      </div>
    </section>
  )
}
