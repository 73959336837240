import React from "react"
import $ from "jquery"
import { saveFile, gen, actionItemStyle } from "./utils"
import { message } from "antd"

function createBestTimesCSV(meetData) {
  /**********************************************
   * this chunk of code figures out the maximum *
   * number of events in the meet for an age    *
   * group so we can get the number of commas   *
   * right when we write the csv                *
   **********************************************/
  // find the age groups that have events in the meet
  let theAgeGroups = meetData.map((x) => x.eventGender + "|" + x.eventAgeGroup)
  // filter to unique age groups
  let distinctAgeGroups = theAgeGroups.filter((v, i, a) => a.indexOf(v) === i)
  // figure out how many events each age group has in the meet
  distinctAgeGroups = distinctAgeGroups.map((x) => {
    // set up an object with info on the age group
    let ageGroupInfo = {
      eventGender: x.split("|")[0],
      eventAgeGroup: x.split("|")[1],
    }
    // now filter meet data to rows in the age group
    let allRecords = meetData.filter(
      (y) =>
        y.eventGender === ageGroupInfo.eventGender &&
        y.eventAgeGroup === ageGroupInfo.eventAgeGroup,
    )
    // now take those rows and pull out the event info
    let events = allRecords.map(
      (y) => y.eventDistance + "|" + y.eventDescription,
    )
    // now filter to unique events
    let distinctEvents = events.filter((v, i, a) => a.indexOf(v) === i)
    // now count them up
    ageGroupInfo.eventCount = distinctEvents.length
    return ageGroupInfo
  })

  // now find the greatest number of events in an age group
  const maxEvents = Math.max(...distinctAgeGroups.map((x) => x.eventCount))

  /****************************************************
   * This chunk of code sets up the header of the csv *
   ****************************************************/
  const numColumns = 3 + maxEvents
  const poolCourse = meetData[0]["poolLength"]
  const meetName = meetData[0]["meetName"]
  const meetDate = meetData[0]["meet_date"]

  let out =
    "Best Times by event (NOTE - only individual events shown below)" +
    ",".repeat(numColumns - 1) +
    "\r\n"
  out +=
    "This meet is set up in a " +
    poolCourse +
    " pool. All times below are standardized to that length."
  out +=
    " Converted times are denoted with a *." +
    ",".repeat(numColumns - 1) +
    "\r\n"
  out += meetName + ",".repeat(numColumns - 1) + "\r\n"
  out += meetDate + ",".repeat(numColumns - 1) + "\r\n\r\n"

  // loop through each age group
  for (let i = 0; i < distinctAgeGroups.length; i++) {
    const theAgeGroup = distinctAgeGroups[i]
    // keep track of how many commas we need for a row of the csv
    let remainingCommas = numColumns - 1
    out +=
      theAgeGroup.eventAgeGroup +
      " " +
      theAgeGroup.eventGender +
      ", League Age, Swimmer Number"
    remainingCommas -= 2
    // find the names of all events this age group swims in the meet
    const ageGroupEventNames = meetData
      .filter(
        (x) =>
          x.eventAgeGroup === theAgeGroup.eventAgeGroup &&
          x.eventGender === theAgeGroup.eventGender,
      )
      .map((x) => x.eventDistance + " " + x.eventDescription)
      .filter((v, i, a) => a.indexOf(v) === i)
    // now find the unique set of events by age group
    const ageGroupEvents = ageGroupEventNames.map((x) => {
      // find a row in meetData that corresponds to this event
      let forOutput = meetData.find(
        (y) => y.eventDistance + " " + y.eventDescription === x,
      )
      // now get the name, distance and stroke from the row and return that info
      return {
        name: forOutput.eventDistance + " " + forOutput.eventDescription,
        distance: forOutput.eventDistance,
        stroke: forOutput.eventDescription,
      }
    })
    // loop through the events in the meet for the given age group
    // add in the event names to the age group header
    for (let j = 0; j < ageGroupEvents.length; j++) {
      out += "," + ageGroupEvents[j]["name"]
      remainingCommas -= 1
    }
    out += ",".repeat(remainingCommas) + "\r\n"

    const results = meetData.filter(
      (x) =>
        x.eventAgeGroup === theAgeGroup.eventAgeGroup &&
        x.eventGender === theAgeGroup.eventGender,
    )
    const swimmerNumbers = results
      .map((x) => x.swimmer_number)
      .filter((v, i, a) => a.indexOf(v) === i)
    // loop through each swimmer
    for (let j = 0; j < swimmerNumbers.length; j++) {
      const swimmerResults = results.filter(
        (x) => x.swimmer_number === swimmerNumbers[j],
      )
      const swimmerName =
        swimmerResults[0]["firstName"] + " " + swimmerResults[0]["lastName"]
      out +=
        swimmerName +
        "," +
        swimmerResults[0]["leagueAge"] +
        "," +
        swimmerNumbers[j].toString()
      // loop through each event
      for (let k = 0; k < ageGroupEvents.length; k++) {
        const thisEvent = ageGroupEvents[k]
        const swimmerBestTime = swimmerResults
          .filter(
            (x) =>
              x.eventDistance === thisEvent["distance"] &&
              x.eventDescription === thisEvent["stroke"],
          )
          .map((x) => x.bestTime)[0]
        out += ',="' + swimmerBestTime + '"'
      }
      // now add a new line
      out += "\r\n"
    }
    // add a blank row
    out += ",".repeat(numColumns - 1) + "\r\n"
  }
  return out
}

export default class BestTimesMatrixButton extends React.Component {
  clickBestTimesMatrix = () => {
    let { meetId, clubId } = this.props
    if (!meetId || !clubId) {
      message.error("No meet or club id")
      return
    }
    $.ajax({
      url: gen(`/api/bestTimesMatrixPost`),
      method: "POST",
      dataType: "json",
      data: {
        meetId: meetId,
        clubId: clubId,
      },
    }).done((data) => {
      let results = data.results
      let theCSV = createBestTimesCSV(results)
      saveFile(theCSV, "BestTimes.csv")
    })
  }

  render() {
    return (
      <span onClick={this.clickBestTimesMatrix} style={actionItemStyle}>
        Best Time Matrix
      </span>
    )
  }
}
