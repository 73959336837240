import React, { useState, useEffect, useRef, useCallback } from "react";
import moment, { Moment } from "moment-timezone";
import styled from "styled-components";
import { message, Badge, DatePicker, Modal, Button, Select } from "antd";
import { Link } from "react-router-dom";
import {
  HourglassTwoTone,
  MinusSquareTwoTone,
  PlusSquareTwoTone,
} from "@ant-design/icons";
import { useStoreState, useStoreActions, StateMapper } from "easy-peasy";
import { AppDataStore } from "../appData/types";
import {
  gen,
  ISwimmerWithNumericGender,
  IUser,
  swimminglyApi,
  ISwimmerWithGuardian,
  validateEmail,
} from "./utils";
import { ApproveButton, RejectButton } from "./ApprovRejectButtons";
import { RegistrationHelper } from "./ClubSeasonManagement/RegistrationConfiguration/RegistrationHelper";

const ParentTable = styled.table`
  width: 100%;

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: var(--tableblue);
  }
  tr:nth-child(odd) {
    background-color: var(--white);
  }
`;

export const SwimmerBirthdayPicker = ({
  value,
  onChange,
}: {
  value: Moment | null;
  onChange: (newDate: Moment | null) => void;
}): JSX.Element => {
  const datePickerRef = useRef() as React.MutableRefObject<any>;
  return (
    <DatePicker
      ref={datePickerRef}
      showToday={false}
      value={value}
      onChange={(newDate) => {
        onChange(newDate);
        datePickerRef.current.blur();
      }}
    />
  );
};

const AddGuardianButton = styled.button`
  border-radius: 3px;
  background-color: var(--primaryblue);
  color: white;
  margin-left: 10px;
  justify-self: center;
  align-self: center;
  padding: 5px;
  border: none;
  cursor: pointer;
`;

interface IParentSwimmersProps {
  user: IUser;
}

export default function ParentSwimmers({
  user,
}: IParentSwimmersProps): JSX.Element {
  const [searchText, setSearchText] = useState("");
  const [allSwimmers, setAllSwimmers] = useState<ISwimmerWithGuardian[]>([]);
  const [filteredSwimmers, setFilteredSwimmers] = useState<
    ISwimmerWithGuardian[]
  >([]);
  const [expandedSwimmers, setExpandedSwimmers] = useState<number[]>([]);
  const [triggerRefreshData, setTriggerRefreshData] = useState(0);
  const setTriggerUsersSwimmersRefresh = useStoreActions(
    (actions: AppDataStore) => actions.setTriggerUsersSwimmersRefresh
  );
  const triggerUsersSwimmersRefresh = useStoreState(
    (state: StateMapper<AppDataStore>) => state.triggerUsersSwimmersRefresh
  );
  const [componentIsMounted, setComponentIsMounted] = useState(false);
  const [selectedSwimmer, setSelectedSwimmer] =
    useState<ISwimmerWithGuardian | null>(null);
  const [addGuardianModalVisible, setAddGuardianModalVisible] = useState(false);
  const [newGuardianEmail, setNewGuardianEmail] = useState("");

  useEffect(() => {
    let canSetState = true;
    if (!componentIsMounted) {
      if (canSetState) setComponentIsMounted(true);
      setTriggerUsersSwimmersRefresh(triggerUsersSwimmersRefresh);
    }
    return () => {
      canSetState = false;
    };
  }, [
    componentIsMounted,
    setTriggerUsersSwimmersRefresh,
    triggerUsersSwimmersRefresh,
  ]);

  useEffect(() => {
    let mounted = true;
    if (user?.userId) {
      swimminglyApi
        .get(gen(`/api/getAllSwimmersOfGuardian/${user.userId}`))
        .then(
          ({
            status,
            swimmers,
          }: {
            status: string;
            swimmers: {
              swimmer: ISwimmerWithNumericGender;
              swimmerShoulderNumber: number;
              thisGuardianStatus: "confirmed" | "requested";
              guardians: {
                user: IUser;
                guardianStatus: "confirmed" | "requested";
              }[];
              invitedGuardians: {
                requestedGuardianId: number;
                email: string;
                swimmerId: number;
                requestedBy: number;
                requestedOn: string;
                requestExpires: string;
                requesterEmail: string;
              }[];
            }[];
          }) => {
            if (status === "success") {
              const newAllSwimmers: ISwimmerWithGuardian[] = [];
              for (let i = 0; i < swimmers.length; i++) {
                newAllSwimmers.push({
                  swimmer: swimmers[i].swimmer,
                  guardians: swimmers[i].guardians,
                  invitedGuardians: swimmers[i].invitedGuardians,
                  thisGuardianStatus: swimmers[i].thisGuardianStatus,
                  swimmerShoulderNumber: swimmers[i].swimmerShoulderNumber,
                });
              }
              if (mounted) {
                setAllSwimmers(newAllSwimmers);
                setFilteredSwimmers(newAllSwimmers);
              }
            } else {
              message.error("Something went wrong retrieving swimmers");
            }
          }
        )
        .catch(() => {
          message.error("Something went wrong retrieving swimmers.");
        });
    } else {
      setAllSwimmers([]);
      setFilteredSwimmers([]);
    }
    return () => {
      mounted = false;
    };
  }, [user?.userId, triggerRefreshData]);

  useEffect(() => {
    if (!searchText || searchText.trim() === "")
      setFilteredSwimmers(allSwimmers);
    else {
      const theFilteredSwimmers = allSwimmers.filter(
        (el) =>
          (el.swimmer.firstName &&
            el.swimmer.firstName
              .toLowerCase()
              .includes(searchText.toLowerCase())) ||
          (el.swimmer.lastName &&
            el.swimmer.lastName
              .toLowerCase()
              .includes(searchText.toLowerCase()))
      );
      setFilteredSwimmers(theFilteredSwimmers);
    }
  }, [searchText, allSwimmers]);

  useEffect(() => {
    const newExpandedSwimmers = expandedSwimmers.filter((swimmerId) =>
      filteredSwimmers.map((sWG) => sWG.swimmer.swimmerId).includes(swimmerId)
    );
    let removedSwimmers = expandedSwimmers.filter(
      (swimmerId) => !newExpandedSwimmers.includes(swimmerId)
    );
    if (removedSwimmers.length > 0) {
      setExpandedSwimmers(newExpandedSwimmers);
    }
  }, [filteredSwimmers, expandedSwimmers]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const updateExpandedSwimmers = useCallback(
    (swimmer: ISwimmerWithGuardian) => {
      if (expandedSwimmers.includes(swimmer.swimmer.swimmerId)) {
        setExpandedSwimmers(
          expandedSwimmers.filter(
            (swimmerId) => swimmerId !== swimmer.swimmer.swimmerId
          )
        );
      } else {
        setExpandedSwimmers([...expandedSwimmers, swimmer.swimmer.swimmerId]);
      }
    },
    [expandedSwimmers]
  );

  const registrationHelper = new RegistrationHelper();

  const handleGenderChange = async (swimmerId: number, newGender: number) => {
    try {
      const response = await swimminglyApi
        .post(gen(`/api/updateSwimmer/${swimmerId}`))
        .body({
          gender: newGender,
        });

      if (response.status === "success") {
        message.success("Gender updated successfully");
        setTriggerRefreshData(triggerRefreshData + 1);
      } else {
        message.error("Failed to update gender");
      }
    } catch (error) {
      console.error("Error updating gender:", error);
      message.error("Error updating gender");
    }
  };

  let headerHtml = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
        marginTop: "10px",
      }}
    >
      <div>
        <input
          value={searchText}
          className="form-control"
          placeholder={"Search Swimmers"}
          onChange={onChange}
        />
      </div>
    </div>
  );

  if (allSwimmers.length === 0) {
    return (
      <div className="row">
        <div className="col-md-12 center">
          {headerHtml}
          <h3>No Swimmers Found</h3>
        </div>
      </div>
    );
  }
  return (
    <div>
      {headerHtml}
      <div>
        <ParentTable>
          <thead>
            <tr>
              <th>Name</th>
              <th>Date of Birth</th>
              <th>Gender</th>
              <th>Your Guardian Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredSwimmers.map((swimmer) => (
              <React.Fragment
                key={`swimmer_row_group_${swimmer.swimmer.swimmerId}`}
              >
                <tr key={`${swimmer.swimmer.swimmerId}`}>
                  <td>
                    {swimmer.thisGuardianStatus === "confirmed" ? (
                      <Link
                        to={`/app/athleteProfile/${swimmer.swimmer.swimmerId}`}
                      >
                        {swimmer.swimmer.firstName} {swimmer.swimmer.lastName}
                      </Link>
                    ) : (
                      <span style={{ color: "lightgray" }}>
                        {swimmer.swimmer.firstName} {swimmer.swimmer.lastName}
                      </span>
                    )}
                  </td>
                  <td>
                    {swimmer.thisGuardianStatus === "confirmed" ? (
                      <SwimmerBirthdayPicker
                        value={moment(swimmer.swimmer.dateOfBirth)}
                        onChange={(newDate) => {
                          if (swimmer.swimmer.swimmerId && newDate !== null) {
                            swimminglyApi
                              .post(
                                gen(
                                  `/api/updateSwimmer/${swimmer.swimmer.swimmerId}`
                                )
                              )
                              .body({
                                dateOfBirth: newDate.format("YYYY-MM-DD"),
                              })
                              .then((data) => {
                                if (data.status === "success") {
                                  message.success(
                                    `Updated ${swimmer.swimmer.firstName} ${swimmer.swimmer.lastName}'s date of birth!`
                                  );
                                  setTriggerRefreshData(
                                    triggerRefreshData + 1
                                  );
                                } else {
                                  message.error(
                                    "Problem updating date of birth"
                                  );
                                }
                              });
                          }
                        }}
                      />
                    ) : null}
                  </td>
                  <td>
                    {swimmer.thisGuardianStatus === "confirmed" ? (
                      <Select
                        value={swimmer.swimmer.gender}
                        onChange={(value) =>
                          handleGenderChange(swimmer.swimmer.swimmerId, value)
                        }
                        style={{ width: 100 }}
                      >
                        <Select.Option value={2}>Boy</Select.Option>
                        <Select.Option value={1}>Girl</Select.Option>
                      </Select>
                    ) : null}
                  </td>
                  <td>
                    {swimmer.thisGuardianStatus}{" "}
                    {swimmer.thisGuardianStatus === "requested" ? (
                      <HourglassTwoTone />
                    ) : null}
                  </td>
                  <td>
                    {swimmer.thisGuardianStatus === "confirmed" ? (
                      <span
                        style={{ color: "var(--linkblue)", cursor: "pointer" }}
                      >
                        {expandedSwimmers.includes(
                          swimmer.swimmer.swimmerId
                        ) ? (
                          <MinusSquareTwoTone
                            onClick={() => updateExpandedSwimmers(swimmer)}
                            style={{ fontSize: "1.7rem" }}
                          />
                        ) : (
                          <PlusSquareTwoTone
                            onClick={() => updateExpandedSwimmers(swimmer)}
                            style={{ fontSize: "1.7rem" }}
                          />
                        )}
                        <AddGuardianButton
                          onClick={() => {
                            setSelectedSwimmer(swimmer);
                            setAddGuardianModalVisible(true);
                            setNewGuardianEmail("");
                          }}
                        >
                          add guardian
                        </AddGuardianButton>
                      </span>
                    ) : null}{" "}
                    {swimmer.guardians?.find(
                      (guardian) => guardian.guardianStatus !== "confirmed"
                    ) ? (
                      <Badge
                        count={
                          swimmer.guardians?.filter(
                            (guardian) =>
                              guardian.guardianStatus !== "confirmed"
                          ).length
                        }
                      />
                    ) : null}
                  </td>
                </tr>
                {expandedSwimmers.includes(swimmer.swimmer.swimmerId) ? (
                  <tr key={`${swimmer.swimmer.swimmerId}_guardians`}>
                    <td
                      colSpan={5}
                      style={{ padding: "30px", paddingTop: "15px" }}
                    >
                      <table
                        style={{ width: "100%" }}
                        className="background-red"
                      >
                        <thead>
                          <tr>
                            <th>guardian name</th>
                            <th>guardian email</th>
                            <th>guardian phone</th>
                            <th>guardian status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {swimmer.guardians?.map((guardian) => (
                            <tr
                              key={`${swimmer.swimmer.swimmerId}_${guardian.user.userId}`}
                            >
                              <td>{guardian.user.name}</td>
                              <td>{guardian.user.email}</td>
                              <td>{guardian.user.phoneNumber}</td>
                              <td>
                                {guardian.guardianStatus}{" "}
                                {guardian.guardianStatus !== "confirmed" && (
                                  <div style={{ display: "inline" }}>
                                    <ApproveButton
                                      action={() => {
                                        swimminglyApi
                                          .post(
                                            gen(
                                              "/api/createParentGuardianRelationship"
                                            )
                                          )
                                          .body({
                                            userId: guardian.user.userId,
                                            swimmerId:
                                              swimmer.swimmer.swimmerId,
                                            approverId: user?.userId,
                                          })
                                          .then((data) => {
                                            if (data.status === "success")
                                              message.success("Success!");
                                            else
                                              message.error(
                                                "Uh oh, something went wrong..."
                                              );
                                          })
                                          .catch(() =>
                                            message.error(
                                              "Uh oh, something went wrong..."
                                            )
                                          )
                                          .finally(() =>
                                            setTriggerRefreshData(
                                              triggerRefreshData + 1
                                            )
                                          );
                                      }}
                                    />
                                    <RejectButton
                                      action={() => {
                                        swimminglyApi
                                          .post(
                                            gen(
                                              "/api/rejectParentGuardianRequest"
                                            )
                                          )
                                          .body({
                                            userId: guardian.user.userId,
                                            swimmerId:
                                              swimmer.swimmer.swimmerId,
                                          })
                                          .catch(() => {
                                            message.error(
                                              "Uh oh, something went wrong..."
                                            );
                                          })
                                          .finally(() =>
                                            setTriggerRefreshData(
                                              triggerRefreshData + 1
                                            )
                                          );
                                      }}
                                    />
                                  </div>
                                )}
                              </td>
                            </tr>
                          ))}
                          {swimmer.invitedGuardians?.map(
                            (invitedGuardian, iGidx) => (
                              <tr key={`ig_${invitedGuardian.email}_${iGidx}`}>
                                <td />
                                <td>{invitedGuardian.email}</td>
                                <td />
                                <td>
                                  invited by {invitedGuardian.requesterEmail}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            ))}
          </tbody>
        </ParentTable>
      </div>
      <Modal
        title={`Invite User as a Guardian for ${selectedSwimmer?.swimmer.firstName} ${selectedSwimmer?.swimmer.lastName}`}
        visible={addGuardianModalVisible}
        okText="Add Guardian"
        cancelText="No More Guardians"
        onOk={() => {
          if (
            selectedSwimmer?.swimmer.swimmerId &&
            validateEmail(newGuardianEmail.trim().toLowerCase())
          ) {
            const guardianEmail = newGuardianEmail.trim().toLowerCase();
            registrationHelper.addGuardianToSwimmer(
              selectedSwimmer?.swimmer.swimmerId,
              user?.userId,
              guardianEmail
            );
            setAddGuardianModalVisible(false);
            setSelectedSwimmer(null);
            setNewGuardianEmail("");
          } else {
            message.warn("Please check the email you are trying to add...", 4);
          }
        }}
        onCancel={() => {
          setAddGuardianModalVisible(false);
          setSelectedSwimmer(null);
          setNewGuardianEmail("");
        }}
      >
        <p>
          Please enter the email of the guardian you would like to add for your
          swimmer:
        </p>
        <input
          value={newGuardianEmail}
          onChange={(e) =>
            setNewGuardianEmail(e.target.value.trim().toLowerCase())
          }
          placeholder="email..."
        />
      </Modal>
    </div>
  );
}
