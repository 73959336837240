import { useState, useEffect, useCallback } from "react"
import moment, { Moment } from "moment-timezone"
import { HomeTwoTone, DownOutlined, RightOutlined, FilePdfOutlined } from "@ant-design/icons"
import { cloneDeep } from "lodash"
import { Button, Empty, message, Progress, Skeleton, Tooltip, Menu, Dropdown } from "antd"
import styled from "styled-components"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../appData/types"
import {
  IClubSeason,
  IMeet,
  swimminglyApi,
  gen,
  IClub,
  CustomEventEmitter,
} from "./utils"
import { screenSizes } from "../styles/GlobalStyles"
import ClubBlock from "./ClubBlock"
import ContentPanel from "./ContentPanel"
import swimminglyImage from "../images/Swimmingly_Illustrations_03.png"
import CreateMeetModal from "./CreateMeetModal"
import MeetActionsModal from "./MeetActionsModal"

export const updateSeasonMeetArrayListener = new CustomEventEmitter()

export const StyledSeasonDisplay = styled.div`
  margin-top: 75px;

  &:first-child {
    margin-top: 25px;
  }

  .swim-meets {
    margin-left: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    column-gap: 20px;
    row-gap: 25px;
  }
  .empty-image {
    height: 130px;
    width: 162px;
    margin: auto;
  }

  .swim-meet {
    box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
  }

  .national-champs {
    box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    background-color: var(--primaryblue);
    color: white;
  }

  .national-champs h3 {
    text-align: center;
    color: white;
  }

  h2.season-title {
    color: var(--darkgrey);
    display: flex;
  }

  h2.season-title button {
    align-self: center;
  }

  @media (max-width: ${screenSizes.medium}px) {
    .swim-meets {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  @media (max-width: ${screenSizes.small}px) {
    .swim-meets {
      grid-template-columns: 1fr 1fr;
    }
    .empty-image {
      margin-left: 20px;
    }
  }

  @media (max-width: ${screenSizes.extraSmall}px) {
    .swim-meets {
      display: flex;
      flex-direction: column-reverse;
    }
    .empty-image {
      margin-left: 20px;
    }
  }
`

export function MeetClubStatus({
  club,
  meetstatus: meetStatus,
  isHome,
}: {
  club: IClub
  meetstatus: 0 | 2 | 1 | null | undefined
  isHome: boolean
}): JSX.Element {
  
  return (
    <div style={{ display: "grid", gridTemplateColumns: "20px 95px auto" }}>
      <span>{isHome ? <HomeTwoTone /> : ""}</span>
      <Tooltip title={club.nameLong} placement="topLeft">
        <span>{club.name}</span>
      </Tooltip>
      <div style={{ width: "100px" }}>
        <Tooltip title="Entries started">
          <Progress
            style={{ width: "33%" }}
            showInfo={false}
            percent={meetStatus !== null ? 100 : 0}
            strokeColor="#1890ff"
            trailColor="#bdbdbd"
          />
        </Tooltip>
        <Tooltip title="Heat Sheet Merged">
          <Progress
            style={{ width: "33%" }}
            showInfo={false}
            percent={meetStatus === 1 || meetStatus === 2 ? 100 : 0}
            strokeColor="#1890ff"
            trailColor="#bdbdbd"
          />
        </Tooltip>
        <Tooltip title="Results Published">
          <Progress
            style={{ width: "33%" }}
            showInfo={false}
            percent={meetStatus === 2 ? 100 : 0}
            strokeColor="#1890ff"
            trailColor="#bdbdbd"
          />
        </Tooltip>
      </div>
    </div>
  )
}

const menu = (
  <Menu>
    <Menu.Item>
    <Tooltip
        title="Your authoritative Help Guide - download & print as your reference on the pool deck during the meet!"
        placement="left"
        >
    <div>
      <a
        href="/Swimmingly%C2%AE%20App%20Help%20Guide%20V11.pdf"
        target="_blank"
        rel="noopener noreferrer"
        
      >
        <FilePdfOutlined /> Swimmingly® Help Guide v11
      </a>
    </div>
      
      </Tooltip>
    </Menu.Item>
    <Menu.Item>
    <Tooltip
        title="Download & print QR Code PDFs so volunteers can scan & download the Swimmingly app, and parents/spectators can scan & download the SwimminglyFan app as they arrive at the pool!"
        placement="left"
        >
    <div>
      <a
        href="/Swimmingly%20SwimminglyFan%20QR%20Code.pdf"
        target="_blank"
        rel="noopener noreferrer"
      
      >
        <FilePdfOutlined /> Swimmingly® & SwimminglyFan™ - QR Code PDF
      </a>
    </div>
      
      </Tooltip>
    </Menu.Item>
    <Menu.Item>
    <Tooltip
        title="Download & print the swim meet cheat sheet - includes quick tips for the huddle & each role in the Swimmingly® app."
        placement="left"
        >
    <div>
      <a
        href="/Swimmingly%20Swim%20Meet%20Cheat%20Sheet.pdf"
        target="_blank"
        rel="noopener noreferrer"
      
      >
        <FilePdfOutlined /> Swim Meet Cheat Sheet for Volunteers
      </a>
    </div>
      
      </Tooltip>
    </Menu.Item>
  </Menu>
)

const PlaceholderSeasonDisplay = (): JSX.Element => {
  return (
    <StyledSeasonDisplay>
      <Skeleton paragraph={{ rows: 0 }} active />
      <div className="swim-meets">
        <div className="swim-meet">
          <Skeleton.Button active />
          <Skeleton paragraph={{ rows: 4 }} active />
        </div>
        <div className="swim-meet">
          <Skeleton.Button active />
          <Skeleton paragraph={{ rows: 4 }} active />
        </div>
      </div>
    </StyledSeasonDisplay>
  )
}

interface IMeetWithClubs {
  meet: IMeet
  meetCourse: "SCY" | "SCM" | "LCM"
  virtualMeetCourse: null | "SCY" | "SCM" | "LCM"
  isVirtualMeet: boolean
  clubs: {
    club: IClub
    meetstatus: null | 0 | 1 | 2
  }[]
}

function ClubSeasonMeets({
  clubSeasonId,
  seasonEnded,
  openCreateMeetModal,
  setMeetIdToEdit,
  setEditMeetModalVisible,
  triggerRefresh,
}: {
  clubSeasonId: number
  seasonEnded: boolean
  openCreateMeetModal: () => void
  setMeetIdToEdit: (meetIdToEdit: number) => void
  setEditMeetModalVisible: (newVisible: boolean) => void
  triggerRefresh: number
}): JSX.Element {
  const [meets, setMeets] = useState<IMeetWithClubs[]>([])
  const [loading, setLoading] = useState(false)

  const getMeets = useCallback(() => {
    let mounted = true
    if (!clubSeasonId) {
      if (mounted) {
        setMeets([])
      }
      return
    }
    if (mounted) {
      setLoading(true)
    }
    swimminglyApi
      .get(gen(`/api/getClubSeasonMeets/${clubSeasonId}`))
      .then(
        ({ status, meets }: { status: string; meets: IMeetWithClubs[] }) => {
          if (status === "success") {
            if (mounted) {
              setMeets(meets)
            }
          } else {
            message.error("Problem finding meets for season")
          }
        },
      )
      .finally(() => {
        if (mounted) {
          setLoading(false)
        }
      })
    return () => {
      mounted = false
    }
  }, [clubSeasonId, setMeets, setLoading])

  // now we can trigger this component to update its list of meets from any other component
  // using our event emitter
  useEffect(() => {
    updateSeasonMeetArrayListener.on(
      `updateSeasonMeets${clubSeasonId}`,
      getMeets,
    )
    return () =>
      updateSeasonMeetArrayListener.removeAllListeners(
        `updateSeasonMeets${clubSeasonId}`,
      )
  }, [clubSeasonId, getMeets])

  useEffect(getMeets, [getMeets, triggerRefresh])

  if (loading) {
    return (
      <div className="swim-meets">
        <div className="swim-meet">
          <Skeleton.Button active />
          <Skeleton paragraph={{ rows: 4 }} active />
        </div>
        <div className="swim-meet">
          <Skeleton.Button active />
          <Skeleton paragraph={{ rows: 4 }} active />
        </div>
      </div>
    )
  }

  if (meets.length === 0) {
    return (
      <div className="swim-meets">
        <Empty
          style={!seasonEnded ? { cursor: "pointer" } : {}}
          image={swimminglyImage}
          className="empty-image"
          description={
            seasonEnded ? (
              <span>There were no meets this season</span>
            ) : (
              <span>
                There aren't any meets for this season yet!{" "}
                <Button
                  onClick={openCreateMeetModal}
                  type="link"
                  style={{ padding: 0 }}
                >
                  Do want to add one?
                </Button>
              </span>
            )
          }
        />
      </div>
    )
  }

  return (
    <div className="swim-meets">
      {meets.map((meet, index) => (
        <div
          key={`meet_card_${meet.meet.meetId}_${index}`}
          className="swim-meet"
          onClick={() => {
            setMeetIdToEdit(meet.meet.meetId)
            setEditMeetModalVisible(true)
          }}
        >
          <h3>
            {meet.meet.meetName.length > 57
              ? meet.meet.meetName.slice(0, 54) + "..."
              : meet.meet.meetName}
          </h3>
          <h4>
            <span>{moment(meet.meet.meet_date).format("MM-DD-YYYY")}</span>
            <br/>
            <span>Course: {meet.meetCourse}</span>
            {meet.virtualMeetCourse !== null ? (
              <span><br />Virtual Course: {meet.virtualMeetCourse}</span>
            ) : null}
          </h4>
          {meet.clubs.length > 4 ? (
            <Button type="link">View Clubs</Button>
          ) : (
            meet.clubs.map((club, index) => (
              <MeetClubStatus
                key={`meet_status_${meet.meet.meetId}_${club.club.clubId}_${index}`}
                club={club.club}
                isHome={meet.meet.is_home === club.club.clubId}
                meetstatus={club.meetstatus}
              />
            ))
          )}
        </div>
      ))}
    </div>
  )
}

interface IClubSeasonDisplay {
  visible: boolean
  clubSeason: IClubSeason
}

function MeetSchedulePageInner(): JSX.Element {
  const impersonateClub = useStoreState(
    (state: StateMapper<AppDataStore>) => state.aliasedClub,
  )

  const [clubSeasons, setClubSeasons] = useState<IClubSeasonDisplay[]>([])
  const [loadingClubSeasons, setLoadingClubSeasons] = useState(false)
  const [selectedSeason, setSelectedSeason] =
    useState<{
      clubSeasonId: number
      seasonId: number
      seasonName: string
      startDate: Moment
      endDate: Moment
    } | null>(null)
  const [createMeetModalVisible, setCreateMeetModalVisible] = useState(false)
  const [editMeetModalVisible, setEditMeetModalVisible] = useState(false)
  const [meetIdToEdit, setMeetIdToEdit] = useState(-1)
  const [triggerRefresh, setTriggerRefresh] = useState(0)

  useEffect(() => {
    if (createMeetModalVisible === false) {
      setSelectedSeason(null)
    }
  }, [createMeetModalVisible])

  const getClubSeasons = useCallback(() => {
    if (!impersonateClub?.clubId) {
      setClubSeasons([])
      return
    }
    setLoadingClubSeasons(true)
    swimminglyApi
      .get(gen(`/api/getSeasonsForClub/${impersonateClub.clubId}`))
      .then(
        ({
          status,
          clubSeasons,
        }: {
          status: string
          clubSeasons: IClubSeason[]
        }) => {
          if (status === "success") {
            setClubSeasons(
              clubSeasons?.map((clubSeason, idx) => ({
                visible: moment(clubSeason.endDate).diff(moment(), "days") > -5,
                clubSeason,
              })) || [],
            )
          } else {
            message.error("Problem finding seasons for your club")
          }
        },
      )
      .finally(() => setLoadingClubSeasons(false))
  }, [impersonateClub?.clubId, setClubSeasons, setLoadingClubSeasons])

  useEffect(getClubSeasons, [getClubSeasons])

  const updateClubSeasonVisible = (clubSeasonId: number) => {
    const newClubSeasons = cloneDeep(clubSeasons)
    const clubSeasonToUpdate = newClubSeasons.find(
      (clubSeasonDisplay) =>
        clubSeasonDisplay.clubSeason.clubSeasonId === clubSeasonId,
    )
    if (clubSeasonToUpdate) {
      clubSeasonToUpdate.visible = !clubSeasonToUpdate.visible
    }
    setClubSeasons(newClubSeasons)
  }

  const openCreateMeetModal = useCallback(
    (
      clubSeasonId: number,
      seasonId: number,
      seasonName: string,
      startDate: Moment,
      endDate: Moment,
    ): void => {
      setSelectedSeason({
        clubSeasonId,
        seasonId,
        seasonName,
        startDate,
        endDate,
      })
      setCreateMeetModalVisible(true)
    },
    [],
  )


  if (loadingClubSeasons) return <PlaceholderSeasonDisplay />

  return (
    <div className="all-club-seasons">
      {clubSeasons.map((clubSeasonDisplay, index) => (
        <StyledSeasonDisplay
          key={`club_season_block_${clubSeasonDisplay.clubSeason.clubSeasonId}_${index}`}
        >
          <h2 className="season-title">
            {clubSeasonDisplay.visible ? (
              <DownOutlined
                style={{ cursor: "pointer" }}
                onClick={() =>
                  updateClubSeasonVisible(
                    clubSeasonDisplay.clubSeason.clubSeasonId,
                  )
                }
              />
            ) : (
              <RightOutlined
                style={{ cursor: "pointer" }}
                onClick={() =>
                  updateClubSeasonVisible(
                    clubSeasonDisplay.clubSeason.clubSeasonId,
                  )
                }
              />
            )}{" "}
            <span>{clubSeasonDisplay.clubSeason.seasonName}</span>
          </h2>
          {moment(clubSeasonDisplay.clubSeason.endDate).diff(moment(), "days") >
          0 ? (
            <Button
              style={{ marginBottom: "30px", marginLeft: "40px" }}
              type="primary"
              onClick={() =>
                openCreateMeetModal(
                  clubSeasonDisplay.clubSeason.clubSeasonId,
                  clubSeasonDisplay.clubSeason.seasonId,
                  clubSeasonDisplay.clubSeason.seasonName,
                  moment(clubSeasonDisplay.clubSeason.startDate),
                  moment(clubSeasonDisplay.clubSeason.endDate),
                )
              }
            >
              Add Swim Meet to This Season
            </Button>
          ) : null}
          {clubSeasonDisplay.visible ? (
            <ClubSeasonMeets
              triggerRefresh={triggerRefresh}
              seasonEnded={
                moment(clubSeasonDisplay.clubSeason.endDate).diff(
                  moment(),
                  "days",
                ) < 0
              }
              setMeetIdToEdit={setMeetIdToEdit}
              setEditMeetModalVisible={setEditMeetModalVisible}
              openCreateMeetModal={() =>
                openCreateMeetModal(
                  clubSeasonDisplay.clubSeason.clubSeasonId,
                  clubSeasonDisplay.clubSeason.seasonId,
                  clubSeasonDisplay.clubSeason.seasonName,
                  moment(clubSeasonDisplay.clubSeason.startDate),
                  moment(clubSeasonDisplay.clubSeason.endDate),
                )
              }
              clubSeasonId={clubSeasonDisplay.clubSeason.clubSeasonId}
            />
          ) : null}
        </StyledSeasonDisplay>
      ))}
      <CreateMeetModal
        visible={createMeetModalVisible}
        setVisible={setCreateMeetModalVisible}
        season={selectedSeason}
      />
      <MeetActionsModal
        meetId={meetIdToEdit}
        visible={editMeetModalVisible}
        setVisible={setEditMeetModalVisible}
        refreshData={() => {
          setTriggerRefresh(triggerRefresh + 1)
        }}
      />
    </div>
  )
}

export default function MeetSchedulePage(): JSX.Element {
  return (
    <div>
      <header className="page-header">
        <h2>Meet Schedule</h2>
      </header>
      
      <ClubBlock />
      
      <ContentPanel
        title="Meet Schedule"
      >
        <div 
          style={{ 
          textAlign: "right" 
          }}
        >
          <Dropdown 
            overlay={menu}
          >
          <a 
            onClick={e => e.preventDefault()}
          >    
            Swim Meet Resources <DownOutlined />
          </a>
          </Dropdown>
        </div>
        <MeetSchedulePageInner />
      </ContentPanel>
    </div>
  )
}
