import React from "react"
import { Modal } from "antd"

function SwimminglyFanModal() {
  Modal.info({
    title: "Download SwimminglyFan",
    content: (
      <p style={{ margin: 0 }}>
        Heat sheets and results also available on <b>SwimminglyFan</b>! (iOS and
        Android).
      </p>
    ),
    onOk() {},
  })
}

export default SwimminglyFanModal
