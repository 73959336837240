import { gen, IClub, swimminglyApi } from "../utils"


export class MeetHelper {
    api = swimminglyApi

    public constructor(api = swimminglyApi) {
        this.api = api
    }

    public async getClubsForMeet(meetId: number): Promise<IClub> {
        return this.api.get(gen(`/api/getMeetClubs/${meetId}`))
    }

}  

