import { ArrowsAltOutlined, CheckCircleOutlined, DashOutlined, DownloadOutlined, MessageOutlined, MoreOutlined } from "@ant-design/icons";
import { Button, Card, Tag, Tooltip } from "antd";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import { Colors } from "../ClubSeasonManagement/styles";
import { MeetClubStatus } from "../MeetSchedulePageNew";
import ParentHeatSheet from "../ParentHeatSheet";
import SwimminglyFanModal from "../SwimminglyFanModal";
import { gen, IClub, } from "../utils";
import { unitOfMeasureToCourse } from "../utils/helperFunctions";
import { IParentsMeet, StyledParentMeetDisplay } from "./ParentMeetScheduleInner";


export default function ParentMeetCardDisplay({
    meet,
    clubs,
    guardianClubs,
    setParentMeetDetailsModalVisible,
    setMeetToView,
    setClubsToView
}: {
    meet: IParentsMeet,
    clubs: IClub[],
    guardianClubs: IClub[]
    setParentMeetDetailsModalVisible: (newVisible: boolean) => void,
    setMeetToView: (meetToView: IParentsMeet) => void,
    setClubsToView: (clubsToView: IClub[]) => void
})
    : JSX.Element {

    const maximumMeetNameCharacterLength = 57;

    const MeetDeclarationButton = ({
        meet,
    }: {
        meet: IParentsMeet,
    }): JSX.Element => {
        if (!meet.meetstatus && !meet.meetDeclarationsCloseDate) {
            return (
                <div className="text-left" key={meet.meetId}>
                    <br />
                    <Tooltip
                        title="Meet Declarations have not been opened for this swim meet by your club leadership"
                        placement="right"
                    >
                        <Button
                            type="primary"
                            shape="round"
                            disabled={true}
                            color="#25bfea"
                            icon={<MessageOutlined />}
                        >
                            Meet Declarations (pending)
                        </Button>
                    </Tooltip>
                </div>

            )
        }

        if (!meet.meetstatus) {
            return (
                <div className="text-left" key={meet.meetId}>
                    <br />
                    <Link
                        to={`/app/parent/viewMeetLineUp/${meet.meetId}/${meet.club_id}`}
                    >
                        <Button
                            type="primary"
                            shape="round"
                            icon={<MessageOutlined />}
                        >
                            Meet Declarations {`${moment(meet.meetDeclarationsCloseDate) < moment() ? " (closed)" : "(open)"}`}
                        </Button>
                    </Link>
                </div>
            )
        }

        if (meet.meetstatus === 1) {
            return (
                <div className="text-left" key={meet.meetId}>
                    <div onClick={SwimminglyFanModal}>
                        <br />
                        <ParentHeatSheet
                            meetId={meet.meetId}
                            clubId={meet.club_id}
                            title="Downlod Heat Sheet PDF"
                        />
                    </div>
                </div>
            )
        }

        if (meet.meetstatus === 2) {
            return (
                <div className="text-left" key={meet.meetId}>
                    <br />
                    {meet.isVirtualMeet && (
                        <Link
                            to={`/app/virtualMeet/results/${meet.virtualMeetId}`}>
                            <Button
                                type="link"
                                shape="round"
                                icon={<CheckCircleOutlined />}
                            >Virtual Swim Meet Results
                            </Button>
                        </Link>
                    )}
                    <div onClick={SwimminglyFanModal}>
                        <Link
                            to={`/app/meet/results/${meet.meetId}`}>
                            <Button
                                type="primary"
                                shape="round"
                                icon={<CheckCircleOutlined />}
                            >
                                {meet.isVirtualMeet ? "My Club's Results" : "Swim Meet Results"}
                            </Button>
                        </Link>
                    </div>



                    {meet.hasResultsFile && (
                        <div onClick={SwimminglyFanModal}>
                            <Link
                                to={`/app/meet/results/${meet.meetId}`}>
                                Results

                                <Button
                                    type="link"
                                    href={gen(`/api/downloadPdf/${meet.meetId}/false/${meet.club_id}`)}
                                >
                                    Download Results
                                </Button>
                            </Link>
                        </div>
                    )}
                </div>
            )
        }
        return <div className="text-left" />
    }

    const guardianClubIDs = new Set(guardianClubs.map(club => club.clubId ?? 0))
    const clubsInOrder = [...clubs]
    const collapsedClubsToDisplay = 12;

    clubsInOrder.sort((club0, club1) => {
        if (club0.clubId === meet.is_home) {
            return -1
        } else if (club1.clubId === meet.is_home) {
            return 1
        } else if (club0.clubId && guardianClubIDs.has(club0.clubId)) {
            return -1
        } else if (club1.clubId && guardianClubIDs.has(club1.clubId)) {
            return 1
        } else {
            const nameCompared = (club0.name ?? "").localeCompare(club1.name ?? "")
            const idCompared = (club0.clubId ?? 0) - (club1.clubId ?? 0)
            return nameCompared !== 0 ? nameCompared : idCompared
        }
    })


    return (
        <StyledParentMeetDisplay>
            <Card
                hoverable={true}
                type="inner"
                title={`${_.truncate(meet.meetName, { length: maximumMeetNameCharacterLength })}`}
                onClick={() => {
                    setMeetToView(meet)
                    setClubsToView(clubsInOrder)
                    setParentMeetDetailsModalVisible(true)
                }}
            >
                <h5>
                    {meet.location && <h5>{meet.location}</h5>}
                    <h5>

                        <Tag
                            color="blue"
                        >
                            Date:{" "}{moment(meet.meet_date).format("MM-DD-YYYY")}
                        </Tag>
                        <Tooltip
                            title="Swim meets can be standardized as SCY (25 yard pool), SCM (25 meter pool), or LCM (50 meter pool)"
                            placement="right"
                        >
                            <Tag
                                color="cyan"
                            >
                                Course:{" "}
                                {unitOfMeasureToCourse(meet.unit_of_measure)}
                            </Tag>
                            {Boolean(meet.isVirtualMeet) && !_.isNil(meet.virtualCourse) ? (
                                <Tag color={Colors.primaryBlue} style={{ marginTop: "4px" }}>Virtual Course: {` ${unitOfMeasureToCourse(meet.virtualCourse)} `}</Tag>
                            ) : null}
                        </Tooltip>
                    </h5>
                </h5>
                <br />

                {clubsInOrder.slice(0, collapsedClubsToDisplay).map((club, index) => (
                    <MeetClubStatus
                        key={`meet_status_${meet.meetId}_${club.clubId}_${index}`}
                        club={club}
                        isHome={meet.is_home === club.clubId}
                        meetstatus={meet?.meetstatus}
                    />
                ))}
                {
                    clubs.length > collapsedClubsToDisplay &&
                    (
                        <Button
                            type="link"
                            icon={<ArrowsAltOutlined />}
                        >
                            View All Clubs
                        </Button>
                    )
                }
                <MeetDeclarationButton meet={meet} />
            </Card>
        </StyledParentMeetDisplay>
    )
}