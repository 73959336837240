import React from "react"
import CenterSpin from "./CenterSpin"
import { gen, swimminglyApi } from "./utils"
import LineUpPdf from "./LineUpPdf"

export default class PrintPdf extends React.Component {
  state = {
    loaded: false,
  }
  componentDidMount() {
    swimminglyApi
      .post(gen(`/api/heatsheetPost`))
      .body({
        clubId: parseInt(String(this.props.clubId), 10),
        meetId: this.props.meetId,
        ageStart: this.props.ageStart,
        ageEnd: this.props.ageEnd,
        gender: this.props.gender,
      })
      .then((data) => {
        this.setState(
          {
            recordsAggregated: data.recordsAggregated,
            meetName: data.meetName,
            meetDate: data.meetDate,
            loaded: true,
          },
          () => {
            this.props.newWindowRef.current.window.title = "Lineup"
            // self.props.newWindowRef.current.window.print()
            // setTimeout(() => {
            //   self.props.newWindowRef.current.window.close()
            // }, 100)

            // window.print()
            // setTimeout(function() {
            //   window.close()
            // }, 100)
          },
        )
      })
  }

  render() {
    if (!this.state.loaded) {
      return (
        <div style={{ marginTop: "20px" }}>
          <CenterSpin />
        </div>
      )
    }
    return (
      <LineUpPdf
        recordsAggregated={this.state.recordsAggregated}
        meetName={this.state.meetName}
        meetDate={this.state.meetDate}
        isLineUp={this.props.isLineUp}
      />
    )
  }
}
