import React from "react";
import { Table, Button, Tooltip, Row, Col, message, Tag } from "antd";
import { EditOutlined } from "@ant-design/icons";
import EditUserClubModal from "./EditUserClub";
import { roleMapping, titleMapping, gen } from "./utils";

export default class AdminUsersClub extends React.Component {
  state = {
    approveUserLoading: false,
    userIdEdit: null,
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    role: 3,
    title: "",
    visible: false,
  };

  editUser = (user) => {
    let nameSplit = user.name.split(" ");
    this.setState({
      userIdEdit: user.id,
      email: user.email,
      firstName: nameSplit[0],
      lastName: nameSplit.slice(1).join(" "),
      phoneNumber: user.phone_number,
      role: String(user.role),
      title: user.title,
      visible: true,
    });
  };

  approveUser = async (userId) => {
    this.setState({ approveUserLoading: true });
    try {
      const response = await fetch(gen("/api/activateThisUserPost"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId: userId }),
      });

      const data = await response.json();

      if (data.status === "error" && /did not get email/.test(data.error)) {
        message.error(data.error);
      } else {
        message.success("Activated user!");
      }
      this.setState({ approveUserLoading: false });
      this.props.reloadUserData();  // Reload the user data
    } catch (error) {
      message.error("Huh, something went wrong... try again!");
      this.setState({ approveUserLoading: false });
    }
  };

  deleteUser = async (userId) => {
    if (!userId) return;

    try {
      const response = await fetch(gen("/api/deleteUserPost"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: userId }),
      });

      if (response.ok) {
        message.success("User deleted successfully!");
        this.props.reloadUserData();  // Reload the user data
      } else {
        const errorData = await response.json();
        message.error(
          errorData.error || "Failed to delete the user. Please try again."
        );
      }
    } catch (error) {
      message.error("An error occurred while deleting the user.");
    }
  };

  render() {
    const { adminUsers } = this.props;

    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Email Address",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Phone Number",
        dataIndex: "phone_number",
        key: "phone_number",
      },
      {
        title: "Role",
        dataIndex: "role",
        key: "role",
        render: (role, user) => {
          // Check if the role is 4, which corresponds to "Club admin"
          const roleColor = role === 4 ? "purple" : "default";
      
          return (
            <Tag color={roleColor}>
              {roleMapping[role]} 
              {role === 3 && user.hasleague && `(${user.hasleague.name})`}
            </Tag>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "activated",
        key: "status",
        render: (activated, user) => {
          const lastLogin = user.lastLogin || user.updated_at;
          const lastLoginFormatted = lastLogin
            ? `last login ${new Date(lastLogin).toLocaleDateString('en-US')}`
            : "last login unknown";
      
          const lastLoginStyle = {
            color: "#8898aa",
            fontSize: "smaller",  // 1 point smaller
            fontStyle: "italic",  // Italics
            marginLeft: "8px"
          };
      
          return (
            <>
              {activated === 1 ? (
                <div>
                  Active
                  <span style={lastLoginStyle}>{lastLoginFormatted}</span>
                </div>
              ) : (
                <div>
                  Inactive
                  <span style={lastLoginStyle}>{lastLoginFormatted}</span>
                  <Button
                    type="default"
                    size="small"
                    danger
                    onClick={() => this.deleteUser(user.id)}
                  >
                    Reject
                  </Button>
                  <Button
                    type="default"
                    size="small"
                    loading={this.state.approveUserLoading}
                    onClick={() => this.approveUser(user.id)}
                    style={{ marginLeft: 8 }}
                  >
                    Approve
                  </Button>
                </div>
              )}
            </>
          );
        },
      },            
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
        render: (title) => titleMapping[title],
      },
      {
        title: "Edit User",
        key: "edit",
        render: (text, user) => (
          <Tooltip title="Edit this user's details">
            <Button
              icon={<EditOutlined />}
              onClick={() => this.editUser(user)}
            />
          </Tooltip>
        ),
      },
    ];

    if (adminUsers.length === 0) {
      return (
        <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
          <Col>
            <h3>No Club User Found</h3>
          </Col>
        </Row>
      );
    }

    return (
      <div>
        <Table
          dataSource={adminUsers}
          columns={columns}
          rowKey="id"
          pagination={false}
          scroll={{ x: 800 }}
        />
        <EditUserClubModal
          reloadUserData={this.props.reloadUserData}  // Pass the reload function to the modal
          userIdEdit={this.state.userIdEdit}
          email={this.state.email}
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          phoneNumber={this.state.phoneNumber}
          title={this.state.title}
          visible={this.state.visible}
          setVisible={(val) => this.setState({ visible: val })}
          clubId={this.props.club && this.props.club.clubId}
          user={this.props.user}
        />
      </div>
    );
  }
}
