import React from "react"
import $ from "jquery"
import AgeCliffs from "./AgeCliffs"
import CenterSpin from "./CenterSpin"
import { momentTimezones, gen } from "./utils"
import AddEventOrderModal from "./AddEventOrderModal"
import AddEventModal from "./AddEventModal"
import {
  Button,
  message,
  Divider,
  Row,
  Col,
  Input,
  Select,
  Popconfirm,
  Modal,
} from "antd"
import DragSortingTable from "./ReactDragAndDropTable"
import ActionAlerts from "./ActionAlerts"
import { EditOutlined, DeleteOutlined, } from "@ant-design/icons"
import { Colors } from "./ClubSeasonManagement/styles"

const { Option } = Select
// Dont reload page
// Don't create Primary Event Order
// Have user make first event order
class LeagueInfo extends React.Component {
  validate() {
    let isGood = true
    if (!this.state.name) {
      message.error("No name specified")
      isGood = false
    }
    if (!this.state.timezone) {
      message.error("No timezone specified")
      isGood = false
    }
    if (!this.state.city) {
      message.error("No city specified")
      isGood = false
    }
    return isGood
  }

  onChange = (leagueField, newVal) => {
    const newLeague = this.props.league
    newLeague[leagueField] = newVal
    this.props.setState({ league: newLeague })
  }

  render() {
    let { momentTimezones, league } = this.props
    let momentKeys = Object.keys(momentTimezones)
    return (
      <div>
        <Row style={{ paddingBottom: "15px" }}>
          <Col span={6}>League Name:</Col>
          <Col span={12}>
            <Input
              value={league.name}
              onChange={(e) => this.onChange("name", e.target.value)}
            />
          </Col>
          
        </Row>

        
        <Row style={{ paddingBottom: "15px" }}>
          <Col span={6}>City:</Col>
          <Col span={12}>
            <Input
              value={league.City}
              onChange={(e) => this.onChange("City", e.target.value)}
            />
          </Col>
        </Row>

        <Row style={{ paddingBottom: "15px" }}>
          <Col span={6}>State:</Col>
          <Col span={12}>
            <Input
              value={league.StateID}
              onChange={(e) => this.onChange("StateID", e.target.value)}
            />
          </Col>
        </Row>
        <Row style={{ paddingBottom: "15px" }}>
          <Col span={6}>TimeZone:</Col>
          <Col span={12}>
            <select
              style={{
                height: "35px",
                padding: 0,
                borderRadius: 0,
                width: "465px",
              }}
              value={league.TimeZoneCode}
              onChange={(e) => this.onChange("TimeZoneCode", e.target.value)}
            >
              {momentKeys.map((el) => (
                <option
                  style={{ height: "35px", padding: 0 }}
                  value={momentTimezones[el]}
                  key={el}
                >
                  {momentTimezones[el]}
                </option>
              ))}
            </select>
          </Col>
        </Row>
      </div>
    )
  }
}

class EventOrders extends React.Component {
  state = {
    events: [],
    eventOrdersArr: [],
    eventOrderId: -1,
    acrhiveInProcess: false,

    editEventModal: false,
    editEventOrderName: "",
  }

  getEventOrders = () => {
    // console.log("in here")
    let league_id = this.props.league.id

    $.ajax({
      url: gen("/api/getUsersEventOrders2Post"),
      method: "POST",
      dataType: "json",
      data: { league_id: league_id },
    }).done((data) => {
      let eventOrderArr = data.eventOrderArray
      let lastOne =
        eventOrderArr.length > 0
          ? eventOrderArr[eventOrderArr.length - 1].id
          : null
      let name = this.getName(lastOne, eventOrderArr)
      this.setState({
        eventOrdersArr: eventOrderArr,
        eventOrderId: lastOne,
        editEventOrderName: name,
      })
      this.getEvents()
    })
  }

  getEvents = (theEventOrderId = undefined, cb) => {
    let league_id = this.props.league.id
    let eventOrderId = this.state.eventOrderId
    if (eventOrderId === -1) eventOrderId = null
    $.ajax({
      url: gen("/api/getUsersEvents2Post"),
      method: "POST",
      dataType: "json",
      data: { eventOrderId: String(eventOrderId), league_id: league_id },
    }).done((data) => {
      if (cb) {
        this.setState({ events: data.eventArray }, cb)
      } else {
        this.setState({ events: data.eventArray })
      }
    })
  }

  deleteEvent = (id) => {
    $.ajax({
      url: gen("/api/deleteUserEventPost"),
      method: "POST",
      dataType: "json",
      data: { id: id },
    }).done((data) => {
      this.getEvents(null, () =>
        this.resortEvents(this.state.events, this.getEvents),
      )
    })
  }

  resortEvents = (events, cb) => {
    if (events.length > 0) {
      $.ajax({
        url: gen("/api/sortUserEventsPost"),
        method: "POST",
        dataType: "json",
        data: { order: events },
      }).done((data) => {
        if (cb) {
          cb()
        }
      })
    }
  }

  componentDidMount() {
    this.getEventOrders()
  }

  changeIt = (val) => {
    let name = this.getName(val, this.state.eventOrdersArr)
    this.setState(
      { eventOrderId: val, editEventOrderName: name },
      this.getEvents,
    )
  }

  archive = () => {
    this.setState({ acrhiveInProcess: true })
    let eventOrderId = this.state.eventOrderId
    if (eventOrderId === -1) eventOrderId = null
    $.ajax({
      url: gen("/api/archiveEventOrderPost"),
      method: "POST",
      dataType: "json",
      data: { eventOrderId },
    }).done((data) => {
      this.getEventOrders()
      this.setState({ acrhiveInProcess: false })
    })
  }

  getName = (eventOrderId, eventOrdersArr) => {
    const theEventOrder = eventOrdersArr.find((eO) => eO.id === eventOrderId)
    if (theEventOrder) return theEventOrder.name
    else return "Select Event Order..."
  }

  saveName = (eventOrderId, newName) => {
    if (eventOrderId === -1 || eventOrderId === null)
      message.error("Please select an event order")
    else {
      $.ajax({
        url: gen("/api/editEventOrderName"),
        method: "POST",
        dataType: "json",
        data: {
          eventOrderId,
          newName,
        },
      }).done((data) => {
        this.getEventOrders()
        this.setState({ eventOrderId })
      })
    }
  }

  render() {
    return (
      <div>
        <Row type="flex" align="top">
          <Col span={4}><h4>Select Existing Event Order:</h4></Col>
          <Col span={10}>
            <Select
              value={this.state.eventOrderId}
              onChange={this.changeIt}
              style={{ width: "95%" }}
            >
              <Option value={-1} disabled>
                Please Select an Event Order...
              </Option>
              {this.state.eventOrdersArr.map((el) => (
                <Option value={el.id} key={el.id}>
                  {el.name}
                </Option>
              ))}
            </Select>
            <div
              style={{
                display: "flex",
                alignItems: "left",
                cursor: "pointer",
                marginTop: "10px",
              }}
            >
              <EditOutlined
                onClick={() => this.setState({ editEventModal: true })}
                style={{ color: Colors.primaryBlue, fontSize: "20px" }}
              />
              <Button type="link" onClick={() => this.setState({ editEventModal: true })}>
              Edit Name of Selected Event Order
            </Button>
            </div>
            <div>
            <DeleteOutlined
              style={{ color: Colors.primaryBlue, fontSize: "20px" }}
            />
            <Popconfirm
              id="archiveEvent"
              title={
                <div>
                  Archiving will make this event order inaccessible when creating future meets. <br />
                  Meets previously created using this event order will not be affected.
                </div>
              }
              okText="Archive Now"
              cancelText="Cancel"
              onConfirm={this.archive}
            >
              <Button type="link" loading={this.state.acrhiveInProcess}>
              Archive Selected Event Order
              </Button>
            </Popconfirm>
            </div>
          </Col>
        
          <Col span={1} />
        <Col span={1}>
          &nbsp;<b>Or</b>
        </Col>
        <Col span={1} />
          <Col span={6}>
            <AddEventOrderModal
              league={this.props.league}
              getEventOrders={this.getEventOrders}
            />
          </Col>
        </Row>

        <Divider />

        <AddEventModal
          getEvents={this.getEvents}
          league={this.props.league}
          eventOrderId={this.state.eventOrderId}
        />

        <DragSortingTable
          data={this.state.events}
          deleteEvent={this.deleteEvent}
          resortEvents={this.resortEvents}
          getEvents={this.getEvents}
        />

        <Modal
          title="Edit Name of Event Order"
          visible={this.state.editEventModal}
          onOk={() => {
            this.saveName(
              this.state.eventOrderId,
              this.state.editEventOrderName,
            )
            this.setState({ editEventModal: false })
          }}
          onCancel={() => this.setState({ editEventModal: false })}
          okText="Update Now"
        >
          <Input
            type="text"
            value={this.state.editEventOrderName}
            onChange={(e) =>
              this.setState({ editEventOrderName: e.target.value })
            }
          />
        </Modal>
      </div>
    )
  }
}

export default class EditLeague extends React.Component {
  state = {
    league: null,
    loaded: false,
    cutOffs: [],
  }

  componentDidMount() {
    this.refreshData()
  }

  refreshData() {
    let leagueId = this.props.match.params.leagueId
    $.ajax({
      url: gen("/api/editLeagueDataPost"),
      method: "POST",
      dataType: "json",
      data: {
        leagueId: Number(leagueId),
        userId: this.props.user.userId,
      },
    }).done((data) => {
      this.setState({
        league: data.league,
        loaded: true,
      })
    })
  }

  onClickSubmit = () => {
    this.setState({ loading: true })

    const obj = {
      name: this.state.league.name,
      TimeZoneCode: this.state.league.TimeZoneCode,
      City: this.state.league.City,
      StateID: this.state.league.StateID,
      cut_off_year: this.state.league.cut_off_year,
      cut_off_day: this.state.league.cut_off_day,
      cut_off_month: this.state.league.cut_off_month,
      is_public: 1,
      league_id: this.state.league.id,
      userId: this.props.user.userId,
      typePay: this.state.league.typePay,
    }

    if (this.props.user.role === 2 || this.props.user.role === 3) {
      this.saveCutoffs()
    }

    $.ajax({
      url: gen("/api/postEditLeaguePost"),
      method: "POST",
      dataType: "json",
      data: obj,
    }).done((data) => {
      console.log(data)
      this.setState({ loading: false })
      this.refreshData()
    })
  }

  saveCutoffs = () => {
    let cutOffs = this.state.cutOffs.map((el) => Number(el)).slice()
    for (let i = 0; i < cutOffs.length; i++) {
      let val = cutOffs[i]
      if (isNaN(val)) {
        message.error("There are some values which are not filled in")
        return
      }
      if (val <= 0) {
        message.error("Can't have any empty values, or values below 0")
        return
      }
      if (val >= 100) {
        message.error("Can't have any values above 100")
      }
    }

    for (let i = 0; i < cutOffs.length - 1; i++) {
      let first = cutOffs[i]
      let second = cutOffs[i + 1]
      if (first >= second) {
        message.error("The ages have to be increasing")
        return
      }
    }
    let stringCutoffs = cutOffs.join(",")

    $.ajax({
      url: gen("/api/changeAgeCliffsPost"),
      method: "POST",
      dataType: "json",
      data: {
        leagueId: this.state.league.id,
        ageCliffs: stringCutoffs,
      },
    }).done((data) => {
      console.log("Done Setting Age Groups", data)
      this.setState({ loading: false })
      this.refreshData()
    })
  }

  render() {
    let { league } = this.state

    if (!this.state.loaded) {
      return (
        <div>
          <header className="page-header">
            <h2>Edit League</h2>
          </header>
          <section className="panel">
            <header className="panel-heading">
              <h2 className="panel-title">Edit League</h2>
            </header>
            <div className="ourpanel-body">
              <CenterSpin />
            </div>
          </section>
        </div>
      )
    }

    return (
      <div>
        <header className="page-header">
          <h2>Edit League</h2>
        </header>

        <ActionAlerts />

        <section className="panel">
          <header className="panel-heading">
            <h2 className="panel-title">Edit League</h2>
          </header>
          <div className="ourpanel-body">
            <LeagueInfo
              league={league}
              momentTimezones={momentTimezones}
              setState={(val) => this.setState(val)}
            />
            <Divider />
            {league && <EventOrders league={league} />}
            <Divider />
            {league && (
              <AgeCliffs
                cutOffs={this.state.cutOffs}
                setCutOffs={(val) => this.setState({ cutOffs: val })}
                league={league}
              />
            )}
            <Button
              loading={this.state.loading}
              type="primary"
              onClick={this.onClickSubmit}
              style={{ marginLeft: "20px" }}
            >
              {" "}
              Save{" "}
            </Button>
          </div>
        </section>
      </div>
    )
  }
}
