import { gen, IClub, swimminglyApi } from "../../utils"
import {PaymentsConfiguration} from "../sharedState"

// models the API to retreive Registration payment configuration
export class PaymentsHelper {
    api = swimminglyApi;
    
    public constructor(api = swimminglyApi) {
        this.api = api
    }

    public async getPaymentsConfiguration(clubId: number, seasonId: number): Promise<PaymentsConfiguration>  {

        return await this.api
            .get(gen(`/api/paymentsConfiguration/clubId=${clubId}/seasonId=${seasonId}`)) 
            .then((data) => {
                return Promise.resolve(data["paymentsConfiguration"])
            })
            .catch ((err) => {
                return Promise.resolve({canReceivePayments: false, errorMessage: "Unable to connect to payment processor."})
            })
  
        
    }

  
    
  
}
  