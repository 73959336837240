import { gen, IAnnouncement, swimminglyApi } from "../utils"


export class AnnouncementHelper {
    api = swimminglyApi

    public constructor(api = swimminglyApi) {
        this.api = api
    }

    public async getAnnouncements(userId: number): Promise<IAnnouncement[]> {
        return this.api.get(gen(`/api/messaging/getAnnouncements/${userId}`))
    }

}