import React from "react"
import $ from "jquery"
import { message, Tabs, Button, Divider, Tooltip } from "antd"
import moment from "moment-timezone"
import {
  raceTypeArr,
  convertCourse,
  divLikeLink,
  gen,
  swimminglyApi,
} from "./utils"
import EditEventTimeModal from "./EditEventTimeModal"
import EditEventSwimmerModal from "./EditEventSwimmerModal"
import CenterSpin from "./CenterSpin"
import ChangeMeetCourseModal from "./ChangeMeetCourseModal"

const { TabPane } = Tabs

function prep10(numb) {
  if (numb < 10) return `0${Math.round(numb)}`
  return String(Math.round(numb))
}

function formatTime(secondsString) {
  let hundrethSeconds = Number(secondsString) * 100
  const seconds = Math.floor(hundrethSeconds / 100)
  const hundreth = hundrethSeconds % 100

  if (seconds < 60) return `${seconds}.${prep10(hundreth)}`

  const minutes = Math.floor(seconds / 60)
  const actualSeconds = seconds % 60
  return `${minutes}:${prep10(actualSeconds)}.${prep10(hundreth)}`
}

function breakTime(secondsString) {
  let hundrethSeconds = Number(secondsString) * 100
  const seconds = Math.floor(hundrethSeconds / 100)
  const hundreth = hundrethSeconds % 100

  if (seconds < 60)
    return { min: "00", sec: prep10(seconds), hundreth: prep10(hundreth) }

  const minutes = Math.floor(seconds / 60)
  const actualSeconds = seconds % 60
  return {
    min: prep10(minutes),
    sec: prep10(actualSeconds),
    hundreth: prep10(hundreth),
  }
}

function getName(result) {
  if (!result) {
    return "Unknown Swimmer"
  }
  if (result.swimmer_id === 0) {
    return `(${result.swimmer_number_sent}) Unknown Swimmer`
  }

  return `${result.first_name} ${
    result.middle_initials ? result.middle_initials : ""
  } ${result.last_name}`
}

function splitOut(arr, fieldSplit) {
  if (arr.length === 0) {
    return []
  }
  let currentName = arr[0][fieldSplit]
  let bigList = []
  let curList = []
  for (let i = 0; i < arr.length; i++) {
    let curRecord = arr[i]
    if (curRecord[fieldSplit] !== currentName) {
      if (curList.length > 0) {
        bigList.push(curList)
      }

      curList = [curRecord]
      currentName = curRecord[fieldSplit]
    } else {
      curList.push(curRecord)
    }
  }

  if (curList.length > 0) {
    bigList.push(curList)
  }
  return bigList
}

function breakApartBucket(resultsArr) {
  if (resultsArr.length === 1) {
    return [resultsArr]
  }

  let seenThusFar = new Set()
  let finalList = []
  let curBlast = []
  for (let i = 0; i < resultsArr.length; i++) {
    let curResult = resultsArr[i]
    if (seenThusFar.has(curResult.swimmer_order_in_lane)) {
      if (curBlast.length > 0) {
        finalList.push(curBlast)
      }
      curBlast = [curResult]
      seenThusFar = new Set()
      seenThusFar.add(curResult.swimmer_order_in_lane)
    } else {
      curBlast.push(curResult)
      seenThusFar.add(curResult.swimmer_order_in_lane)
    }
  }
  if (curBlast.length > 0) {
    finalList.push(curBlast)
  }
  return finalList
}

class SingleEventResults extends React.Component {
  getEventName = (result) => {
    let title = `Event ${result.event_sort_order}  / ${result.gender} - ${
      result.age_group
    } - ${result.distance} - ${raceTypeArr[result.race_type]}`

    return title
  }

  editEventSwimmer = (resultId) => {
    this.props.setEventSwimmerModalState(resultId, true)
  }

  editEventTime = (result) => {
    let time = result.official_time
    let { min, sec, hundreth } = breakTime(time)
    // console.log(min, sec, hundreth)
    this.props.setEventTimeModalState({
      min: min,
      sec: sec,
      milli: hundreth,
      result_id: result.id,
      editTimeVisible: true,
    })
  }

  createDQRow = (result, names, resultId) => {
    let fontColor = { color: "#ff3e3e" }
    return (
      <tr
        key={`this_dq_row_${resultId}`}
        style={{ backgroundColor: "#ffd7d7" }}
      >
        <td>
          <p style={fontColor}>
            DQ({result && result.disqualification_reason})
          </p>
        </td>
        <td>
          <p style={fontColor}>{names}</p>
        </td>
        <td>
          <p style={fontColor}>DQ {result && result.clubName}</p>
        </td>

        <td>
          <p style={fontColor}>{result && result.lane_number}</p>
        </td>
        <td>
          <p style={fontColor}>DQ</p>
        </td>
        <td>
          <p style={fontColor}>DQ</p>
        </td>
      </tr>
    )
  }
  createSingleRow(resultsArr) {
    let result = resultsArr[resultsArr.length - 1]
    let resultId = `sResultId_${result.id}`
    let names = resultsArr.map((el) => getName(el)).join(", ")

    if (result.disqualification_reason) {
      return this.createDQRow(result, names, resultId)
    }
    return (
      <tr key={resultId}>
        <td>
          {this.props.user &&
          this.props.user.role &&
          this.props.user.role < 5 &&
          this.props.isVirtualMeet ? (
            <div style={{ display: "grid", gridTemplateColumns: "30px 1fr" }}>
              <input
                type="checkbox"
                checked={result.isExhibition}
                onChange={(e) => {
                  e.preventDefault()
                  Promise.all(
                    resultsArr.map((r) =>
                      swimminglyApi
                        .post(gen(`/api/updateIsExhibition/${r.id}`))
                        .body({
                          isExhibition: e.target.checked,
                        }),
                    ),
                  ).then(this.props.refreshPage)
                }}
              />
              <span>{result.isExhibition ? "EX" : result.place_in_event}</span>
            </div>
          ) : result.isExhibition ? (
            "EX"
          ) : (
            result.place_in_event
          )}
        </td>
        <td>
          {this.props.isAuth ? (
            <span
              style={divLikeLink}
              onClick={() => {
                this.editEventSwimmer(resultId, true)
              }}
            >
              {names}
            </span>
          ) : (
            <span>{names}</span>
          )}
        </td>
        <td>{result && result.clubName}</td>
        <td>{result.lane_number}</td>
        <td>{result.isExhibition ? 0 : result.points}</td>
        <td>
          {this.props.isAuth ? (
            <span
              className="swimmertime"
              id={resultId}
              onClick={() => this.editEventTime(result)}
              style={divLikeLink}
            >
              {formatTime(result.official_time)}
            </span>
          ) : (
            <span className="swimmertime" id={resultId}>
              {formatTime(result.official_time)}
            </span>
          )}
        </td>
      </tr>
    )
  }

  // Two events are in the same bucket if they have the
  // same place_in_event but they have a different swimmer_order_in_lane
  // lane_number
  bucketResults = (results) => {
    let places = splitOut(results, "place_in_event")
    // console.log("places", places)
    let allBuckets = []
    for (let i = 0; i < places.length; i++) {
      let curPlaceArr = places[i]
      let arr = breakApartBucket(curPlaceArr)
      // console.log("arr", arr)
      for (let j = 0; j < arr.length; j++) {
        allBuckets.push(arr[j])
      }
    }
    return allBuckets
  }

  renderTable = (results) => {
    let buckets = this.bucketResults(results)

    return (
      <div className="table-responsive" style={{ marginBottom: "20px" }}>
        <table id="checkerTable" className="background-red">
          <thead>
            <tr>
              <th className="headEventPlace">
                {this.props.user &&
                this.props.user.role &&
                this.props.user.role < 5 &&
                this.props.isVirtualMeet ? (
                  <div
                    style={{ display: "grid", gridTemplateColumns: "30px 1fr" }}
                  >
                    <span>(Ex)</span>
                    <span>Place</span>
                  </div>
                ) : (
                  "Place"
                )}
              </th>
              <th className="headName">Name</th>
              <th className="headClub">Club</th>
              <th className="headLane">Lane</th>
              <th className="headPoints">Points</th>
              <th className="headTime">Time</th>
            </tr>
          </thead>
          <tbody>{buckets.map((el) => this.createSingleRow(el))}</tbody>
        </table>
      </div>
    )
  }
  render() {
    let results = this.props.results

    return (
      <div>
        <h3
          style={{
            color: "#25bfea",
            textAlign: "center",
          }}
        >
          {this.getEventName(results[0])}
        </h3>
        {this.renderTable(results)}
      </div>
    )
  }
}

class SingleHeatResults extends React.Component {
  getEventName = (result) => {
    let title = `Event ${result.event_sort_order}  / ${result.gender} - ${
      result.age_group
    } - ${result.distance} - ${raceTypeArr[result.race_type]} Heat ${
      result.heat_number
    }`

    return title
  }
  createDQRow = (result, names, resultId) => {
    let fontColor = { color: "#ff3e3e" }
    return (
      <tr key={`dq_row_${resultId}`} style={{ backgroundColor: "#ffd7d7" }}>
        <td>
          <p style={fontColor}>
            DQ({result && result.disqualification_reason})
          </p>
        </td>
        <td>
          <p style={fontColor}>{names}</p>
        </td>
        <td>
          <p style={fontColor}>DQ {result && result.clubName}</p>
        </td>
        <td>
          <p style={fontColor}>DQ</p>
        </td>
        <td>
          <p style={fontColor}>{result && result.lane_number}</p>
        </td>
        <td>
          <p style={fontColor}>DQ</p>
        </td>
        <td>
          <p style={fontColor}>DQ</p>
        </td>
      </tr>
    )
  }

  editEventTime = (result) => {
    let time = result.official_time
    let { min, sec, hundreth } = breakTime(time)
    // console.log(min, sec, hundreth)
    this.props.setEventTimeModalState({
      min: min,
      sec: sec,
      milli: hundreth,
      result_id: result.id,
      editTimeVisible: true,
    })
  }
  createSingleRow(resultsArr) {
    let result = resultsArr[resultsArr.length - 1]
    let resultId = `sResultId_${result.id}`
    let names = resultsArr.map((el) => getName(el)).join(", ")

    if (result.disqualification_reason) {
      return this.createDQRow(result, names, resultId)
    }
    return (
      <tr key={resultId}>
        <td>{result.place_in_heat}</td>
        <td>{names}</td>
        <td>{result.clubName}</td>
        <td>{result.place_in_event}</td>
        <td>{result.lane_number}</td>
        <td>{result.points}</td>
        <td>
          {this.props.isAuth ? (
            <span
              className="swimmertime"
              id={resultId}
              onClick={() => this.editEventTime(result)}
              style={divLikeLink}
            >
              {formatTime(result.official_time)}
            </span>
          ) : (
            <span className="swimmertime" id={resultId}>
              {formatTime(result.official_time)}
            </span>
          )}
        </td>
      </tr>
    )
  }

  // Two events are in the same bucket if they have the
  // same place_in_event but they have a different swimmer_order_in_lane
  // lane_number
  bucketResults = (results) => {
    let places = splitOut(results, "place_in_event")
    // console.log("places", places)
    let allBuckets = []
    for (let i = 0; i < places.length; i++) {
      let curPlaceArr = places[i]
      let arr = breakApartBucket(curPlaceArr)
      // console.log("arr", arr)
      for (let j = 0; j < arr.length; j++) {
        allBuckets.push(arr[j])
      }
    }
    return allBuckets
    // console.log("allBuckets", allBuckets)
  }

  renderTable = (results) => {
    let buckets = this.bucketResults(results)

    return (
      <div className="table-responsive" style={{ marginBottom: "20px" }}>
        <table id="checkerTable" className="background-red">
          <thead>
            <tr>
              <th className="headEventPlace">Place</th>
              <th className="headName">Name</th>
              <th className="headClub">Club</th>
              <th className="headClub">Place In Event</th>
              <th className="headLane">Lane</th>
              <th className="headPoints">Points</th>
              <th className="headTime">Time</th>
            </tr>
          </thead>
          <tbody>{buckets.map((el) => this.createSingleRow(el))}</tbody>
        </table>
      </div>
    )
  }
  render() {
    let results = this.props.results

    return (
      <div>
        <h3
          style={{
            color: "#25bfea",
            textAlign: "center",
          }}
        >
          {this.getEventName(results[0])}
        </h3>
        {this.renderTable(results)}
      </div>
    )
  }
}

function sortForEvents(a, b) {
  if (a.event_sort_order < b.event_sort_order) {
    return -1
  }
  if (a.event_sort_order > b.event_sort_order) {
    return 1
  }
  if (a.swim_event_id < b.swim_event_id) {
    return -1
  }
  if (a.swim_event_id > b.swim_event_id) {
    return 1
  }
  if (a.place_in_event && !b.place_in_event) {
    return -1
  }
  if (!a.place_in_event && b.place_in_event) {
    return 1
  }
  if (
    (!a.place_in_event && !b.place_in_event) ||
    parseInt(a.place_in_event) === parseInt(b.place_in_event)
  ) {
    // check if one was disqualified
    if (
      a.disqualification_reason !== null &&
      b.disqualification_reason !== null
    ) {
      // if not, then check their times
      let aTime = parseFloat(a.official_time)
      let bTime = parseFloat(b.official_time)
      if (aTime < bTime) {
        return -1
      }
      if (bTime < aTime) {
        return 1
      }
    } else if (a.disqualification_reason === null) {
      return 1
    } else if (b.disqualification_reason === null) {
      return -1
    }
    if (a.isExhibition && !b.isExhibition) return 1
    if (!a.isExhibition && b.isExhibition) return -1
    // if none were disqualified AND they have the same time, check if clubs are the same
    if (a.clubName < b.clubName) {
      return -1
    } else if (b.clubName < a.clubName) {
      return 1
    }
    // if all of that is inconclusive, check heat and lane
    if (
      `${a.heat_number}|${a.lane_number}` < `${b.heat_number}|${b.lane_number}`
    ) {
      return -1
    } else if (
      `${b.heat_number}|${b.lane_number}` < `${a.heat_number}|${a.lane_number}`
    ) {
      return 1
    }
    if (a.swimmer_order_in_lane < b.swimmer_order_in_lane) {
      return -1
    } else if (a.swimmer_order_in_lane > b.swimmer_order_in_lane) {
      return 1
    }
    if (a.last_name + " " + a.first_name > b.last_name + " " + b.first_name) {
      return 1
    } else {
      return -1
    }
  }
  if (a.isExhibition && !b.isExhibition) return 1
  if (!a.isExhibition && b.isExhibition) return -1
  if (a.place_in_event < b.place_in_event) {
    return -1
  }
  if (a.place_in_event > b.place_in_event) {
    return 1
  }
  if (a.heat_number < b.heat_number) {
    return -1
  }
  if (a.heat_number > b.heat_number) {
    return 1
  }
  if (a.lane_number < b.lane_number) {
    return -1
  }
  if (a.lane_number > b.lane_number) {
    return 1
  }
  if (a.swimmer_order_in_lane < b.swimmer_order_in_lane) {
    return -1
  }
  if (a.swimmer_order_in_lane > b.swimmer_order_in_lane) {
    return 1
  }
  return 0
}

function sortForHeats(a, b) {
  if (a.event_sort_order < b.event_sort_order) {
    return -1
  }
  if (a.event_sort_order > b.event_sort_order) {
    return 1
  }
  if (a.swim_event_id < b.swim_event_id) {
    return -1
  }
  if (a.swim_event_id > b.swim_event_id) {
    return 1
  }
  if (a.heat_number < b.heat_number) {
    return -1
  }
  if (a.heat_number > b.heat_number) {
    return 1
  }
  if (a.place_in_heat < b.place_in_heat) {
    return -1
  }
  if (a.place_in_heat > b.place_in_heat) {
    return 1
  }
  if (a.lane_number < b.lane_number) {
    return -1
  }
  if (a.lane_number > b.lane_number) {
    return 1
  }
  if (a.swimmer_order_in_lane < b.swimmer_order_in_lane) {
    return -1
  }
  if (a.swimmer_order_in_lane > b.swimmer_order_in_lane) {
    return 1
  }
  return 0
}

export default class MeetResults extends React.Component {
  state = {
    type: "event",
    teams: [],
    allEvents: [],
    selectedEvent: null,

    min: "00",
    sec: "00",
    milli: "00",
    result_id: null,
    editSwimmerVisible: false,
    editTimeVisible: false,
    changeMeetCourseVisible: false,
    isVirtualMeet: false,

    done: false,
  }

  getResultWithEvents = (meet_id, swim_event_id) => {
    $.ajax({
      url: gen("/api/getMeetClubsAndScore2Post"),
      method: "POST",
      dataType: "json",
      data: {
        meetId: meet_id,
        user: this.props.user,
      },
    }).done((data) => {
      let newData = data
      let teams = newData.allClubs
      for (let i = 0; i < teams.length; i++) {
        let curTeam = teams[i]
        for (let j = 0; j < newData.allScores.length; j++) {
          let curScore = newData.allScores[j]
          if (curTeam.club_id === curScore.club_id) {
            curTeam.points = curScore.points
          }
        }
        if (!curTeam.points) {
          curTeam.points = 0
        }
      }

      this.setState({
        teams,
      })
    })

    $.ajax({
      url: gen("/api/getMeetResults2Post"),
      method: "POST",
      dataType: "json",
      data: {
        user: this.props.user,
        meet_id: meet_id,
      },
    }).done((data) => {
      let newData = data
      // console.log(newData)

      // let teams = this.generateTeamScores(newData.resultsArr)
      let firstResult = newData.resultsArr[0]

      this.setState({
        resultsArr: newData.resultsArr,
        meet: newData.meet,
        // teams,
        firstResult,
        done: true,
        allEvents: newData.allEvents || [],
      })
    })
  }

  renderHeader = (teams, firstResult) => {
    let sortedTeams = teams.sort((a, b) => (a.club_id < b.club_id ? -1 : 1))
    let name = sortedTeams.map((el) => el.name).join(" vs ")
    let course = convertCourse[firstResult.unit_measure]
    let newDateString = moment
      .utc(this.state.meet.meet_date)
      .format("MM-DD-YYYY")

    return (
      <React.Fragment>
        <h2
          className="swimmeet-title"
          style={{
            paddingTop: 0,
            borderTop: "0px",
            marginTop: "5px",
          }}
        >
          {name} : {course} {newDateString}
        </h2>
        {this.props.user && this.props.user.role && this.props.user.role < 5 ? (
          <Tooltip
            title={`Accidentally published the meet in the wrong course? If the times for this meet weren't swum in a ${course} pool click here to fix it.`}
          >
            <Button
              type="link"
              style={{
                marginTop: 0,
                paddingTop: 0,
                marginBottom: "10px",
                paddingBottom: 0,
              }}
              onClick={() => this.setState({ changeMeetCourseVisible: true })}
            >
              This meet was published in {course}
            </Button>
          </Tooltip>
        ) : null}
        <Divider style={{ marginTop: 0 }} />
      </React.Fragment>
    )
  }

  renderTeams = (teams) => {
    return teams
      .sort((a, b) => (a.points < b.points ? 1 : -1))
      .map((el) => {
        return (
          <h4 key={el.club_id}>
            {el.name} : {el.points}
          </h4>
        )
      })
  }

  renderHeaderAndTeamScores = (teams, firstResult) => {
    return (
      <div>
        {this.renderHeader(teams, firstResult)}
        {this.renderTeams(teams)}
      </div>
    )
  }
  breakIntoEvents(resultsArr) {
    if (resultsArr.length === 0) {
      return []
    }

    let cutOffs = []
    for (let i = 0; i < resultsArr.length - 1; i++) {
      if (resultsArr[i].swim_event_id !== resultsArr[i + 1].swim_event_id) {
        cutOffs.push(i + 1)
      }
    }
    let events = []
    cutOffs.unshift(0)
    for (let i = 0; i < cutOffs.length - 1; i++) {
      let allEvents = resultsArr.slice(cutOffs[i], cutOffs[i + 1])
      events.push(allEvents)
    }
    events.push(resultsArr.slice(cutOffs[cutOffs.length - 1]))
    return events
  }
  makeHeatKey = (result) => {
    return `${result.swim_event_id}|${result.heat_number}`
  }
  breakIntoHeats(resultsArr) {
    if (resultsArr.length === 0) {
      return []
    }

    let cutOffs = []
    for (let i = 0; i < resultsArr.length - 1; i++) {
      if (
        this.makeHeatKey(resultsArr[i]) !== this.makeHeatKey(resultsArr[i + 1])
      ) {
        cutOffs.push(i + 1)
      }
    }
    let events = []
    cutOffs.unshift(0)
    for (let i = 0; i < cutOffs.length - 1; i++) {
      let allEvents = resultsArr.slice(cutOffs[i], cutOffs[i + 1])
      events.push(allEvents)
    }
    events.push(resultsArr.slice(cutOffs[cutOffs.length - 1]))
    return events
  }
  renderInternalTable = () => {
    if (!this.state.firstResult) {
      return <h1>No results for this meet </h1>
    }
    let header = this.renderHeaderAndTeamScores(
      this.state.teams,
      this.state.firstResult,
    )
    let isAuthCheck = this.props.user.role !== 5

    if (this.state.type === "event") {
      let sortedResults = this.state.resultsArr.sort(sortForEvents)
      let events = this.breakIntoEvents(sortedResults)
      return (
        <div>
          {header}
          {events.map((el, idx) => {
            return (
              <SingleEventResults
                key={String(el && el[0] && el[0].id) + `_${idx}`}
                user={this.props.user}
                results={el}
                isAuth={isAuthCheck}
                setEventTimeModalState={this.setEventTimeModalState}
                setEventSwimmerModalState={this.setEventSwimmerModalState}
                refreshPage={() => {
                  let meetId = this.props.meetId
                  this.getResultWithEvents(meetId)
                }}
                isVirtualMeet={this.state.isVirtualMeet}
              />
            )
          })}
        </div>
      )
    }
    if (this.state.type === "heat") {
      let sortedResults = this.state.resultsArr.sort(sortForHeats)
      let heats = this.breakIntoHeats(sortedResults)
      return (
        <div>
          {header}
          {heats.map((el, idx) => (
            <SingleHeatResults
              key={`single_heat_results_${idx}`}
              results={el}
              isAuth={isAuthCheck}
              setEventTimeModalState={this.setEventTimeModalState}
            />
          ))}
        </div>
      )
    }
  }

  setEventSwimmerModalState = (resultId, editSwimmerVisible) => {
    let editTimeVisible = this.state.editTimeVisible
    if (editSwimmerVisible === true) {
      editTimeVisible = false
    }
    this.setState({ result_id: resultId, editSwimmerVisible, editTimeVisible })
  }

  setEventTimeModalState = ({
    min,
    sec,
    milli,
    result_id,
    editTimeVisible,
  }) => {
    // console.log("her", { min, sec, milli, ids })
    let editSwimmerVisible = this.state.editSwimmerVisible
    if (editTimeVisible === true) {
      editSwimmerVisible = false
    }
    this.setState({
      min,
      sec,
      milli,
      result_id,
      editTimeVisible,
      editSwimmerVisible,
    })
  }

  renderSelectOption = () => {
    let isMobile = $(window).width() < 768
    if (!isMobile || this.state.allEvents.length === 0) {
      return null
    }

    return (
      <div>
        <select
          id="events"
          value={this.state.selectedEvent}
          onChange={(e) => this.setState({ selectedEvent: e.target.value })}
        >
          {this.state.allEvents.map((el) => (
            <option value={el.id}>{el.eventName}</option>
          ))}
        </select>
        <Button onClick={this.onClickResubmit}>Submit</Button>
      </div>
    )
  }

  onClickResubmit = () => {
    let meetId = this.props.meetId
    let swim_event_id = this.state.selectedEvent
    this.getResultWithEvents(meetId, swim_event_id)
  }

  componentDidMount() {
    let meetId = this.props.meetId
    swimminglyApi.get(gen(`/api/getMeetInformation/${meetId}`)).then((data) => {
      this.setState({ isVirtualMeet: data.meet.isVirtualMeet === 1 })
    })
    this.getResultWithEvents(meetId)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.editSwimmerVisible === this.state.editSwimmerVisible) {
      return
    }
    let meetId = this.props.meetId
    this.getResultWithEvents(meetId)
  }

  putTimeHere = (result_id, seconds) => {
    if (!this.state.resultsArr) {
      return
    }
    let resultsArr = this.state.resultsArr
    let curVal = resultsArr.find((el) => el.id === result_id)
    if (!curVal) {
      message.error("Can't update")
    }
    curVal.official_time = seconds
    this.setState({ resultsArr })
  }

  render() {
    if (!this.state.done) {
      return (
        <section className="panel">
          <header className="panel-heading">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: 1 }}>
                <h2 className="panel-title heading-div">Results</h2>
              </div>
            </div>
          </header>
          <div className="ourpanel-body">
            <CenterSpin />
          </div>
        </section>
      )
    }

    return (
      <section className="panel">
        <header className="panel-heading">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <h2 className="panel-title heading-div">Results</h2>
            </div>
            <div>
              <Tabs
                value={this.state.type}
                onChange={(val) => this.setState({ type: val })}
                size={"small"}
                tabBarStyle={{ margin: 0, padding: 0 }}
              >
                <TabPane tab="By Event" key="event" />
                <TabPane tab="By Heat" key="heat" />
              </Tabs>
            </div>
            <div />
          </div>
        </header>
        <div className="ourpanel-body">
          <div className="form-group ">
            <div className="row-fluid">{this.renderSelectOption()}</div>
            <div className="row-fluid">{this.renderInternalTable()}</div>
          </div>
        </div>
        {this.props.user && this.props.user.role && this.props.user.role < 5 ? (
          <ChangeMeetCourseModal
            currentCourse={this.state.firstResult.unit_measure}
            visible={this.state.changeMeetCourseVisible}
            meetId={this.props.meetId}
            setVisible={(val) =>
              this.setState({ changeMeetCourseVisible: val })
            }
            refreshPage={() => {
              let meetId = this.props.meetId
              this.getResultWithEvents(meetId)
            }}
          />
        ) : null}
        <EditEventTimeModal
          min={this.state.min}
          sec={this.state.sec}
          milli={this.state.milli}
          result_id={this.state.result_id}
          putTimeHere={this.putTimeHere}
          visible={this.state.editTimeVisible}
          setVisible={(val) => {
            let editSwimmerVisible = this.state.editSwimmerVisible
            if (val === true) {
              editSwimmerVisible = false
            }
            this.setState({ editTimeVisible: val, editSwimmerVisible })
          }}
        />
        <EditEventSwimmerModal
          visible={this.state.editSwimmerVisible}
          resultId={this.state.result_id}
          setVisible={(val) => {
            let editTimeVisible = this.state.editTimeVisible
            if (val === true) {
              editTimeVisible = false
            }
            this.setState({ editSwimmerVisible: val, editTimeVisible })
          }}
        />
      </section>
    )
  }
}
