import { useState, useEffect } from "react"
import { Alert } from "antd"
import { Link } from "react-router-dom"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../appData/types"
import { gen, swimminglyApi } from "./utils"
import ClubSignUpAlert from "./ClubSignUpAlert"

export default function ActionAlerts(): JSX.Element {
  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)
  const impersonateClub = useStoreState(
    (state: StateMapper<AppDataStore>) => state.aliasedClub,
  )

  const [shouldAddParents, setShouldAddParents] = useState(false)
  const [shouldAddClubAdmins, setShouldAddClubAdmins] = useState(false)
  const [shouldAddLeagueAdmins, setShouldAddLeagueAdmins] = useState(false)
  const [shouldAddClubId, setShouldAddClubId] = useState(false)

  useEffect(() => {
    swimminglyApi
      .post(gen("/api/getUserActions"))
      .body({ userId: user?.userId })
      .then((data) => {
        setShouldAddParents(data.shouldAddParents)
        setShouldAddClubAdmins(data.shouldAddClubAdmins)
        setShouldAddLeagueAdmins(data.shouldAddLeagueAdmins)
        setShouldAddClubId(data.shouldAddClubId || false)
      })
  }, [user?.userId])

  let alertList = []
  if (
    user?.clubId === impersonateClub?.club_id ||
    user?.clubId === impersonateClub?.clubId
  ) {
    if (
      (user?.role === 4 || user?.role === 3) &&
      impersonateClub?.secretToken
    ) {
      alertList.push(<ClubSignUpAlert key={5} />)
    }
  }

  if (shouldAddParents) {
    alertList.push(
      <Alert
        key={1}
        message={
          <span>
            New parents are requesting access. Click{" "}
            <Link to="/app/club/manageroster">here</Link>, and navigate to the
            Parents tab to approve or reject.
          </span>
        }
        type="warning"
        closable
      />,
    )
  }
  if (shouldAddClubId) {
    alertList.push(
      <Alert
        key={2}
        message={
          <span>
            Click on your avatar in the header to tag yourself to your club.
          </span>
        }
        type="warning"
        closable
      />,
    )
  }
  if (shouldAddClubAdmins) {
    alertList.push(
      <Alert
        key={3}
        message={
          <span>
            New club admins are requesting access. Please click{" "}
            <Link to="/app/club/manageclubusers">here</Link> to approve or
            reject.
          </span>
        }
        type="warning"
        closable
      />,
    )
  }
  if (shouldAddLeagueAdmins) {
    alertList.push(
      <Alert
        key={4}
        message={
          <span>
            New league admins are requesting access. Please click{" "}
            <Link to="/app/admin/manage_users/">here</Link> to approve or
            reject.
          </span>
        }
        type="warning"
        closable
      />,
    )
  }

  if (alertList.length === 0) {
    return <div />
  }
  return <div style={{ marginBottom: "10px" }}>{alertList}</div>
}
