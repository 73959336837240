import { Modal } from "antd"
import { FunctionComponent } from "react"
import { useHistory } from "react-router-dom"

interface IConfirmClubModalProps {
  clubName: string
  visible: boolean
  setVisible: (choice: boolean) => void
  onOk: () => void
}

export const ConfirmClubModal: FunctionComponent<IConfirmClubModalProps> = ({
  clubName,
  visible,
  setVisible,
  onOk,
}) => {
  const history = useHistory()

  return (
    <Modal
      visible={visible}
      title="Confirm Your Club"
      onCancel={() => {
        setVisible(false)
        history.push("/")
      }}
      onOk={() => {
        setVisible(false)
        onOk()
      }}
    >
      <p>You are about to proceed to registration for {clubName}.</p>
      <p>
        If this is the wrong club please click cancel and go back to enter the
        correct Club Registration Code.
      </p>
      .
    </Modal>
  )
}
