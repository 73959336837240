import React, { useState, useEffect } from "react"
import { Link, useHistory, useLocation } from "react-router-dom"
import { LockOutlined, UserOutlined } from "@ant-design/icons"
import { message, Button, Input } from "antd"
import LogRocket from "logrocket"
import moment from "moment-timezone"
import { useStoreActions, useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../appData/types"
import CookiePermissionModal from "./CookiePermissionModal"
import swimHeader from "../images/swimmingly_final_header.png"
import { gen, IUser, getLandingPageRoute, swimminglyApi } from "./utils"
import { seasonPrepModalListener } from "../App"

function saveToLogRocketIfProd(user: IUser): void {
  if (process.env.NODE_ENV !== "development") {
    LogRocket.identify(String(user.userId), {
      name: user.name,
      email: user.email,
    })
  }
}

interface LocationState {
 token?: string | null
 targetUrl?: string | null
}

export default function Login(props: {targetURL?: string|undefined, clubToken?: string|undefined}): JSX.Element {
  const location = useLocation<LocationState>();
  const token = props.clubToken ?? location?.state?.token
  const targetUrl = props.targetURL ?? location?.state?.targetUrl
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [cookieModalVisible, setCookieModalVisible] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)

  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)
  const acceptedCookies = useStoreState(
    (state: StateMapper<AppDataStore>) => state.acceptedCookies,
  )
  const setAcceptedCookies = useStoreActions(
    (actions: AppDataStore) => actions.setAcceptedCookies,
  )
  const setUser = useStoreActions((actions: AppDataStore) => actions.setUser)
  const setImpersonateClub = useStoreActions(
    (actions: AppDataStore) => actions.setAliasedClub,
  )

  const history = useHistory()

  useEffect(() => {
    let mounted = true
    if (user && acceptedCookies) {
      let landingPageRoute = getLandingPageRoute(user)
      if (mounted) {
        history.push(landingPageRoute)
      }
    } else if (!acceptedCookies) {
      if (mounted) {
        setCookieModalVisible(true)
      }
    } else {
      if (mounted) {
        setCookieModalVisible(false)
      }
    }
    return () => {
      mounted = false
    }
  }, [user, acceptedCookies, history])

  // const logInAsClub = (thisUser: IUser, clubId: number, cb: () => any) => {
  //   swimminglyApi
  //     .post("/api/impersonateClub2")
  //     .body({ user: thisUser, impersonateClubId: clubId })
  //     .then((data: { [key: string]: any }): number | null => {
  //       if (data.club && data.club.clubId) {
  //         setImpersonateClub(data.club)
  //         return data.club.clubId
  //       } else {
  //         return null
  //       }
  //     })
  //     .then((theClubId) => {
  //       if (!theClubId) {
  //         return
  //       }
  //       swimminglyApi
  //         .post(gen(`/api/updateAliasedClub/${thisUser.userId}`))
  //         .body({ clubId })
  //     })
  //     .finally(() => {
  //       if (cb) {
  //         cb()
  //       }
  //     })
  // }

  const acceptCookies = () => {
    fetch(gen("/api/makeCookieChoice"), {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
      method: "post",
      body: JSON.stringify({
        cookieChoice: true,
      }),
    })
      .then((response: Response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setAcceptedCookies(true)
          setCookieModalVisible(false)
        }
      })
  }

  const onClick = async () => {
    if (!acceptedCookies) {
      setCookieModalVisible(true)
      return
    }
    setLoading(true)
    const responseData = await swimminglyApi
      .post(gen("/api/login"))
      .body({ email, password })

    if (responseData && responseData.club) {
      setImpersonateClub(responseData.club)
      if (responseData.user) {
        swimminglyApi
          .post(
            gen(
              `/api/updateAliasedClub/${
                responseData.user.id || responseData.user.userId
              }`,
            ),
          )
          .body({ clubId: responseData.club.clubId })
      }
    }

    if (
      responseData &&
      (responseData.error || responseData.status === "error")
    ) {
      message.error("Failed credentials")
      setLoading(false)
      return
    }
    if (responseData && responseData.exception) {
      message.error("Server error")
      setLoading(false)
      return
    }

    if (responseData && responseData.user) {
      await new Promise((resolve, reject) => {
        const loginUser: IUser = responseData.user
        saveToLogRocketIfProd(loginUser)
        setUser(loginUser)
        setLoading(false)
        // setSeasonPrepModal = useStoreActions((actions: AppDataStore) => actions.setSeasonPrepModal)
        resolve(true)
      })
        .then(() => {
          setTimeout(() => {
            seasonPrepModalListener.emit("updateSeasonPrepModal")
          }, 50)
          return 1
        })
        .then(() => {
          setTimeout(() => {
            if (token){
              history.push({
                pathname: `/clubs/register/${token}`,
                state: {
                  justLoggedIn: true,
                },
              })
            } else if (targetUrl){
              history.push({
                pathname: targetUrl,
                state: {
                  justLoggedIn: true,
                },
              })
            } else {
              history.push({
                pathname: "/app/landingPage",
                state: {
                  justLoggedIn: true,
                },
              })
            }
          }, 50)
        })
      return true
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onClick()
    }
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "50px",
        }}
      >
        <section style={{ maxWidth: "470px", width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",

              height: "74px",
            }}
          >
            <div style={{ alignSelf: "flex-start" }}>
              <Link to="/">
                <img src={swimHeader} width="250px" alt="Swimmingly Header" />
              </Link>
            </div>
            <div style={{ alignSelf: "flex-end", margin: 0, padding: 0 }}>
              <div
                style={{
                  width: "84px",
                  height: "46px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgb(16,18,66)",
                  borderTopLeftRadius: "5px",
                  borderTopRightRadius: "5px",
                  color: "white",
                }}
              >
                <UserOutlined />
                Log In
              </div>
            </div>
          </div>
          <div
            className="panel panel-sign"
            style={{
              borderTop: "5px solid rgb(16,18,66)",
              borderTopRightRadius: 0,
            }}
          >
            <div
              className="ourpanel-body"
              style={{ borderTopColor: "#101242", padding: "20px" }}
            >
              <div>
                <label>Email</label>
                <Input
                  type="email"
                  value={email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    setEmail(e.target.value)
                  }
                  suffix={<UserOutlined />}
                  size="large"
                />
              </div>

              <div style={{ paddingBottom: "20px", marginTop: "20px" }}>
                <div className="clearfix">
                  <label className="pull-left">Password</label>
                  <Link
                    to="/passwordReset"
                    tabIndex={-1}
                    style={{ float: "right" }}
                  >
                    Reset Password?
                  </Link>
                </div>
                <Input
                  type="password"
                  value={password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    setPassword(e.target.value)
                  }
                  suffix={<LockOutlined />}
                  size="large"
                  onKeyPress={handleKeyPress}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  type="link"
                  onClick={() => history.push("/registerUser")}
                >
                  Sign Up
                </Button>
                <Button
                  type="primary"
                  loading={loading}
                  style={{ background: "#101242" }}
                  onClick={onClick}
                >
                  Sign In
                </Button>
              </div>
            </div>
          </div>
          <p className="text-center text-muted mt-md mb-md">
            &copy; Copyright {moment().format("YYYY")}. All Rights Reserved.
          </p>
        </section>
      </div>
      <CookiePermissionModal
        visible={cookieModalVisible}
        setVisible={setCookieModalVisible}
        acceptCookies={acceptCookies}
      />
    </>
  )
}
