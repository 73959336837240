import { FunctionComponent, useEffect, useState } from "react"
import { PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import { Button, message, Tooltip } from "antd"
import { FaTrash } from "react-icons/fa"
import { gen, swimminglyApi } from "../../utils"
import { useClubSeasonManagementContext } from "../sharedState"

export const ClubDuesSetup: FunctionComponent = () => {
  const [explanationExpanded, setExplanationExpanded] = useState(false)

  const {
    registrationForm,
    pricePoints,
    updatePricePoint,
    loadPricePoints,
    dbUsingRegistration,
    setRegistrationModalVisible,
  } = useClubSeasonManagementContext()

  useEffect(() => {
    loadPricePoints()
  }, [])

  
  return (
    <div 
        style={{
          fontSize: "16px",
          color: "#101242",
          paddingBottom: "25px",
          paddingRight: "15px",
          paddingTop: "15px", 
        }}
      >    
      <div
        style={{
          padding: "30px",
          borderRadius: "5px",
          maxWidth: "800px",
          backgroundColor: "whitesmoke",
          fontSize: "18px",
        }}
      >
        <p style={{ marginBottom: "5px" }}>
        Please set your desired club dues for each swimmer below. Do not include Swimmingly membership fees into this figure as they will be added at checkout. The parent will see the total registration dues at checkout (Swimmingly membership fee will be included as a part of the registration due) and you can preview what they will see before confirming your setup.
        </p>
     
        {pricePoints.map((pricePoint) => {

          return (
            <div key={`price_point_${pricePoint.clubDuesId}`}>
              $
              <input
                style={{
                  borderRadius: "3px",
                  border: "1px solid lightgrey",
                  padding: "3px",
                  marginRight: "5px",
                }}
                type="number"
                min="0"
                step="any"
                value={ pricePoint.price ? pricePoint.price/ 100 : 0}
                onChange={(e) => {
                  if (!dbUsingRegistration) {
                    setRegistrationModalVisible(true)
                  } else {
                    updatePricePoint(
                      pricePoint.clubDuesId,
                      pricePoint.numSwimmer,
                      parseFloat(e.target.value) * 100,
                      )
                  }
                }}
              />
              <span>
                Swimmer # {pricePoint.numSwimmer}
                {pricePoint.maxNumSwimmer === 1 &&
                  " and all subsequent swimmers"}
                {pricePoints.length > 1 && (
                  <Tooltip title="Remove this swimmer">
                    <FaTrash
                      onClick={() => {
                        if (!dbUsingRegistration) {
                          setRegistrationModalVisible(true)
                        } else {
                          swimminglyApi
                            .post(
                              gen(
                                `/api/removePricePoint/${registrationForm?.registrationFormId}`,
                              ),
                            )
                            .body({
                              clubDuesId: pricePoint.clubDuesId,
                            })
                            .then((data) => {
                              if (data.status === "success") {
                                loadPricePoints()
                              } else {
                                message.error("Problem removing price point!")
                              }
                            })
                            .catch((err) => {
                              console.error(err)
                              message.error("Problem removing price point!")
                            })
                        }
                      }}
                      style={{
                        color: "var(--linkblue)",
                        marginLeft: "5px",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                )}
              </span>
            </div>
          )
        })}
        <br />
        <span style={{ display: "flex", alignItems: "center" }}>
          <Button
            type="primary"
            style={{
              borderRadius: "50px",
              padding: "10px",
              marginRight: "5px",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              if (!dbUsingRegistration) {
                setRegistrationModalVisible(true)
              } else {
                swimminglyApi
                  .post(
                    gen(
                      `/api/addPricePoint/${registrationForm?.registrationFormId}`,
                    ),
                  )
                  .body({})
                  .then((data) => {
                    if (data.status === "success") {
                      loadPricePoints()
                    } else {
                      message.error("Problem adding a new price point!")
                    }
                  })
                  .catch((err) => {
                    console.error(err)
                    message.error("Problem adding price point!")
                  })
              }
            }}
          >
            <PlusOutlined />
          </Button>
          &nbsp;Add Subsequent Swimmer Dues
        </span>
        <span
          style={{ color: "var(--linkblue)", cursor: "pointer" }}
          onClick={() => setExplanationExpanded(!explanationExpanded)}
        >
          <br />
          <QuestionCircleOutlined />
          &nbsp;Maximum transaction amount per family?
        </span>
        {explanationExpanded && (
          <div>
            <p style={{ marginBottom: "2px" }}>
              Let's say your club has dues of $150 for the first swimmer, $140
              for the second swimmer, and $130 for each subsequent swimmer. If
              you want to set a maximum dues amount of $500 per transaction,
              then you would want to set your pricing tiers to:
            </p>
            <ul>
              <li>swimmer 1: $150</li>
              <li>swimmer 2: $140</li>
              <li>swimmer 3: $130</li>
              <li>swimmer 4: $80</li>
              <li>swimmer 5: $0 - (applies to all subsequent swimmers)</li>
            </ul>
          </div>
        )}
      </div>

      <div 
        style={{
          fontSize: "18px",
          color: "#101242",
          paddingBottom: "25px",
          paddingRight: "15px",
          paddingTop: "15px", 
        }}
      >
        Setup your club's schedule of club registration dues. You can update your
        registration dues here, and if your club uses a tiered pricing structure
        you can add subsequent swimmer dues.<br /> 
        <b>Registration Transactions:</b> Tiered registration dues are applied per transaction so encourage your families to register all of their swimmers in a single transaction. A standard fee of 5% is applied at checkout for credit card & Swimmingly processing fees.
      </div>
      <div 
        style={{
            fontSize: "18px",
            color: "#101242",
          paddingBottom: "25px",
          paddingRight: "15px",
          paddingTop: "15px", 
        }}
      >
      
      </div>
    </div>
  )
}
