import { Fragment, useEffect, useState } from "react"
import { Redirect } from "react-router"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../../appData/types"
import BillingHistory from "./BillingHistory"
import { IInvoice, RegistrationHelper } from "../ClubSeasonManagement/RegistrationConfiguration/RegistrationHelper"
import moment from "moment-timezone"

export interface IPaymentMethod {
  paymentMethodId: string
  address: {
    city: string | null
    country: string | null
    line1: string | null
    line2: string | null
    postal_code: string | number | null
    state: string | null
  }
  email: string | null
  name: string | null
  phone: string | null
  cardBrand: string
  country: string | null
  expMonth: number
  expYear: number
  last4: number
  threeDSecureUsage?: boolean | null
}

export interface IRegistration {
  swimmerId: number
  clubId: number
  seasonId: number
  parentPays: number
  payingForMonth: number | null
}

export default function Billing(): JSX.Element {
  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)
  const [shouldRedirect, setShouldRedirect] = useState(false)
  const [invoices, setInvoices] = useState<IInvoice[]>([])

  useEffect(() => {
    const registrationHelper = new RegistrationHelper()
    if (user) {
      registrationHelper.invoicesForGuardian(user.userId).then((invoiceResult) => {
        const invoices = invoiceResult.invoices.sort(function (a, b) {
          return moment(b.invoiceDate).isAfter(moment(a.invoiceDate)) ? 1 : -1
        }
        )
        return setInvoices(invoices)
      })
    }
  }, [user])

  if (shouldRedirect) {
    return <Redirect to="/app/landingPage" />
  }




  return (
    <Fragment>
      <header className="page-header">
        <h2>Payment History</h2>
      </header>
      <br />
      <BillingHistory
        invoices={invoices}
      />
      <br />
    </Fragment>
  )
}