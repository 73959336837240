import React from "react"
import { Route, Link } from "react-router-dom"
import { useStoreState } from "easy-peasy"
import NotFound from "./NotFound"
import CenterSpin from "./CenterSpin"

function checkIfActivationIsAllowed(user, allowedActivations) {
  if (!user) return false
  let activations = allowedActivations.map((activation) => {
    if (activation === 0) return false
    if (activation === false) return false
    return true
  })
  let userActivation = false
  if (user.activated === 1 || user.activated === true) userActivation = true
  if (user.isActive === 1 || user.isActive === true) userActivation = true
  if (activations.includes(userActivation)) return true
  return false
}

export function PrivateRoute({
  component: Component = null,
  render: Render = null,
  allowedRoles = [],
  allowedActivations = [1],
  ...rest
}) {
  const user = useStoreState((state) => state.user)

  return (
    <Route
      {...rest}
      render={(props) => {
        /* Not logged in */
        if (!user) {
          return (
            // <Redirect to={{ pathname: "/", state: { from: props.location } }} />
            <>
              <CenterSpin />
              <p>
                Loading... If this page has problems loading you can click{" "}
                <Link to="/">here</Link> to redirect to safety...
              </p>
            </>
          )
        }
        /* Logged in but can't see route */
        if (user && !allowedRoles.includes(user.role)) {
          return <NotFound />
        }

        if (!checkIfActivationIsAllowed(user, allowedActivations)) {
          return <NotFound />
        }

        /* You have a render function */
        if (Render) {
          return Render(props)
        }
        /* You have a component */
        if (Component) {
          return <Component {...props} />
        }
        return null
      }}
    />
  )
}
export default PrivateRoute
