import { useState } from "react"
import styled from "styled-components"
import { Link, useHistory } from "react-router-dom"
import { message, Input, Button } from "antd"
import { useStoreActions } from "easy-peasy"
import {
  CheckCircleOutlined,
  LockOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons"
import { AppDataStore } from "../appData/types"
import swimHeader from "../images/swimmingly_final_header.png"
import { gen, swimminglyApi, validateEmail } from "./utils"

const RegisterBackground = styled.div`
  height: 100%;
  background-color: var(--lightgrey);
`

const RegisterContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;

  section {
    width: 95%;
    max-width: 470px;
  }

  .swimmingly-logo-container {
    min-width: 250px;
    padding-bottom: 10px;
    padding-left: 5px;
  }

  .top-row {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-content: center;

    .user-icon {
      width: 84px;
      height: 46px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--deepdarkblue);
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      color: var(--white);
    }
  }
`

const RegisterForm = styled.div`
  background-color: var(--snow);
  border-radius: 5px;
  border-top-right-radius: 0;
  border: 1px solid var(--mediumgrey);
  border-top: 5px solid var(--deepdarkblue);

  form {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    padding-top: 25px;
    padding-bottom: 25px;
    gap: 5px;

    .label-row {
      padding-top: 10px;
      color: var(--mediumgrey);
    }

    .two-items {
      display: flex;
      justify-content: space-between;
    }

    .form-button {
      border: none;
      background-color: var(--deepdarkblue);
      color: var(--white);
      height: 40px;
      margin-top: 10px;
      border-radius: 5px;
    }
  }
`

export default function NationalChampsUserRegistration(): JSX.Element {
  const setUser = useStoreActions((actions: AppDataStore) => actions.setUser)
  const setImpersonateClub = useStoreActions(
    (actions: AppDataStore) => actions.setAliasedClub,
  )
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [password, setPassword] = useState("")
  const [passwordConfirm, setPasswordConfirm] = useState("")
  const [clubName, setClubName] = useState("")
  const [clubCode, setClubCode] = useState("")
  const [poolCourse, setPoolCourse] = useState<-1 | 0 | 1 | 2>(-1)

  const history = useHistory()

  const submit = () => {
    if (firstName.trim() === "") {
      message.error("You must put in a first name", 4)
      return
    }
    if (lastName.trim() === "") {
      message.error("You must put in a last name", 4)
      return
    }
    if (!validateEmail(email.trim())) {
      message.error("You must put in a valid email", 4)
      return
    }
    if (password.trim() === "") {
      message.error("You must put in a valid password", 4)
      return
    }
    if (password !== passwordConfirm) {
      message.error("Your passwords do not match", 4)
      return
    }
    if (poolCourse === -1) {
      message.warning(
        "Please select a pool course that your club will swim in for the national championships!",
        5,
      )
      return
    }
    swimminglyApi
      .post(gen("/api/createNationalChampsAccount"))
      .body({
        firstName,
        lastName,
        email,
        phone,
        password,
        passwordConfirm,
        clubName,
        clubCode,
        poolCourse,
      })
      .then((data) => {
        if (data.status === "success") {
          const newUser = data.user
          const newClub = data.club
          setUser(newUser)
          setImpersonateClub(newClub)
          history.push("/app/landingPage")
        } else if (
          data.status === "error" &&
          data.message === "cannot re-register user."
        ) {
          message.warning(
            "Cannot re-register this user for the Swimmingly Clubhouse",
            4,
          )
        }
      })
  }

  const passwordConfirmKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === "Enter") {
      submit()
    }
  }

  return (
    <RegisterBackground>
      <RegisterContainer>
        <section>
          <div className="top-row">
            <div className="swimmingly-logo-container">
              <Link to="/">
                <img src={swimHeader} width="250px" alt="Swimmingly Header" />
              </Link>
            </div>
            <div />
            <div style={{ alignSelf: "end" }}>
              <div className="user-icon">
                <UserOutlined /> Register
              </div>
            </div>
          </div>
          <RegisterForm>
            <form onSubmit={(e) => e.preventDefault()}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  columnGap: "10px",
                }}
              >
                <Input
                  type="text"
                  style={{ borderRadius: "5px" }}
                  value={firstName}
                  placeholder="First"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    setFirstName(e.target.value)
                  }
                  size="large"
                />
                <Input
                  type="text"
                  style={{ borderRadius: "5px" }}
                  value={lastName}
                  placeholder="Last"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    setLastName(e.target.value)
                  }
                  size="large"
                />
              </div>
              <Input
                type="text"
                style={{ borderRadius: "5px" }}
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  setEmail(e.target.value.toLowerCase().trim())
                }
                placeholder="Email"
                suffix={<UserOutlined />}
                size="large"
              />
              <Input
                type="text"
                style={{ borderRadius: "5px" }}
                value={phone}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  setPhone(e.target.value)
                }}
                placeholder="Phone"
                suffix={<PhoneOutlined />}
                size="large"
              />
              <Input
                type="password"
                style={{ borderRadius: "5px" }}
                placeholder="Password"
                value={password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  setPassword(e.target.value)
                }
                suffix={<LockOutlined />}
                size="large"
              />
              <Input
                type="password"
                style={{ borderRadius: "5px" }}
                placeholder="Confirm Password"
                value={passwordConfirm}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  setPasswordConfirm(e.target.value)
                }
                onKeyDown={passwordConfirmKeyPress}
                suffix={<CheckCircleOutlined />}
                size="large"
              />
              <br />
              <span>Tell us About Your Club</span>
              <Input
                style={{ borderRadius: "5px" }}
                placeholder="Club Full Name"
                value={clubName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  setClubName(e.target.value)
                }
                size="large"
              />
              <Input
                style={{ borderRadius: "5px" }}
                placeholder="Club Code (3 to 5 digit abbreviation)"
                value={clubCode}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  setClubCode(e.target.value)
                }
                size="large"
              />
              <select
                value={poolCourse}
                onChange={(e) =>
                  setPoolCourse(parseInt(e.target.value, 10) as -1 | 0 | 1 | 2)
                }
              >
                <option value={-1}>
                  Pool Course (what course is your home pool?)
                </option>
                <option value={0}>Short Course Yards (25 yard pool)</option>
                <option value={1}>Short Course Meters (25 meter pool)</option>
                <option value={2}>Long Course Meters (50 meter pool)</option>
              </select>
              <Button className="form-button" onClick={submit}>
                Create Swimmingly Clubhouse Account
              </Button>
            </form>
          </RegisterForm>
        </section>
      </RegisterContainer>
    </RegisterBackground>
  )
}
