import { FunctionComponent } from "react"
import { Tabs } from "antd"
import { ClubDuesSetup } from "./ClubDuesSetup"
import { DiscountCodes, FeeCodes } from "./DiscountCodes"
import { Colors } from "../styles"

const { TabPane } = Tabs

export const PricingConfiguration: FunctionComponent = () => {
  return (
    <div
      style={{
        backgroundColor: "lightgrey",
        borderRadius: "25px",
      }}
    >
      <Tabs tabPosition="left" defaultActiveKey="pricingOverview">
        <TabPane tab="Pricing Overview" key="pricingOverview">
          <div
            style={{
              textAlign: "left",
              width: "90%",
              marginTop: "15px",
              marginBottom: "50px",
              color: Colors.veryDarkBlue,
              fontSize: "16px",
            }}
          >
            <b>Step 3:</b> Welcome to Pricing
            <br />
            <br />
            <b>Setup Registration Dues</b>
            <br />
            Customize registration dues amount for each swimmer in a family's
            checkout.
            <br />
            <br />
            <b>Setup Additional Checkout Items</b>
            <br />
            Add additional checkout items for your families to purchase. Include
            a description in the title field (shirt sizes, additional info,
            etc). At checkout, a quantity will be listed next to each item for
            guardians to make their appropriate selection. Common checkout items
            include:
            <ul>
              <li>Team Shirts</li>
              <li>Team Swimsuits</li>
              <li>Trophies/Towels</li>
              <li>Champs/City Meet Entries</li>
              <li>Volunteer Buyout</li>
            </ul>
            <br />
            <b>Setup Discount Codes</b>
            <br />
            Create Discount Codes to share with your families. These Discount
            Codes are applied per swimmer during checkout. These Codes can be
            deactivated/reactivated at anytime during your Club Registration.
            Suggested Discount Codes could include:
            <ul>
              <li>Swimmer of board member Discount</li>
              <li>
                <b>"BOARD2022</b>
              </li>
              <br />
              <li>Jr Coach Discount</li>
              <li>
                <b>"COACH2022"</b>
              </li>
              <br />
              <li>Early-Bird Discount</li>
              <li>
                <b>"EARLYBIRD2022"</b>
              </li>
            </ul>
          </div>
        </TabPane>
        <TabPane tab="- Setup Registration Dues" key="clubDues">
          <ClubDuesSetup />
        </TabPane>
        <TabPane tab="- Setup Additional Checkout Items" key="additionalFees">
          <FeeCodes />
          <div
            style={{
              textAlign: "left",
              width: "90%",
              marginTop: "15px",
              marginBottom: "50px",
              color: Colors.veryDarkBlue,
              fontSize: "16px",
            }}
          >
            <b>Setup Additional Checkout Items</b>
            <br />
            Add additional checkout items for your families to purchase. Include
            a description in the title field (shirt sizes, additional info,
            etc). At checkout, a quantity will be listed next to each item for
            guardians to make their appropriate selection. Common checkout items
            include:
            <ul>
              <li>Team Shirts</li>
              <li>Team Swimsuits</li>
              <li>Trophies/Towels</li>
              <li>Champs/City Meet Entries</li>
              <li>Volunteer Buyout</li>
            </ul>
          </div>
        </TabPane>
        <TabPane tab="- Set Up Discount Codes" key="discountCodes">
          <DiscountCodes />
          <div
            style={{
              textAlign: "left",
              width: "90%",
              marginTop: "15px",
              marginBottom: "50px",
              color: Colors.veryDarkBlue,
              fontSize: "16px",
            }}
          >
            <b>Setup Discount Codes</b>
            <br />
            Create Discount Codes to share with your families. These Discount
            Codes are applied per swimmer during checkout. These Codes can be
            deactivated/reactivated at anytime during your Club Registration.
            Suggested Discount Codes could include:
            <ul>
              <li>"Swimmer of board member Discount"</li>
              <li>"Jr Coach Discount"</li>
              <li>"Early-Bird Discount"</li>
            </ul>
          </div>
        </TabPane>
      </Tabs>
    </div>
  )
}
