import React, { useState, useRef, useEffect } from "react"

const InlineTextEdit = (props) => {
  // these couple lines simulate having state in the functional component
  const [text, setText] = useState(props.children || "")
  const [editing, setEditing] = useState(false)

  // this next chunk of code simulates getting prevProps (or in this case prevChildren)
  // when the props/children passed to the functional component change
  const prevTextRef = useRef()
  useEffect(() => {
    prevTextRef.current =
      props.children && !["", "not set"].includes(props.children.trim())
        ? props.children
        : null
  })
  const prevText = prevTextRef.current

  useEffect(() => {
    setText(props.children || "")
  }, [props?.updateText, props?.children])

  // this next little chunk of code is like componentDidUpdate
  // it compares the new children to the prevChildren and if they've change,
  // it updates state to trigger a re-render
  useEffect(() => {
    if (
      props.children &&
      !["", "not set"].includes(props.children.trim()) &&
      (!prevText || props.children !== prevText)
    ) {
      setText(props.children)
    }
  }, [props.children, prevText])

  // this chunk of code updates the state of the functional component when
  // the user is typing in a new value in the input box
  const addInput = async (e) => {
    let [theText, theKey] = [e.target.value, e.key]
    await setText(theText)
    if (theKey === "Enter") {
      setEditing(false)
      await props.updateTheValue(theText)
    }
  }

  // this guy makes sure that when you exit the input box it updates state of the parent component
  // so that the text value has truly been updated and is ready to write to the database
  const makeUpdate = async (value) => {
    setEditing(false)
    await props.updateTheValue(value)
  }

  // and this is like our render function
  if (editing === true) {
    return (
      <input autoFocus onKeyUp={addInput} onBlur={() => makeUpdate(text)} />
    )
  } else {
    return (
      <span
        style={{ color: "blue", cursor: "pointer" }}
        onClick={() => setEditing(true)}
      >
        <u>{text}</u>
      </span>
    )
  }
}

export default InlineTextEdit
