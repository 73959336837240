export const validEvents = [
  { distance: 25, stroke: "Freestyle", course: "SCY", courseValue: 0 },
  { distance: 50, stroke: "Freestyle", course: "SCY", courseValue: 0 },
  { distance: 100, stroke: "Freestyle", course: "SCY", courseValue: 0 },
  { distance: 200, stroke: "Freestyle", course: "SCY", courseValue: 0 },
  { distance: 500, stroke: "Freestyle", course: "SCY", courseValue: 0 },
  { distance: 1000, stroke: "Freestyle", course: "SCY", courseValue: 0 },
  { distance: 1650, stroke: "Freestyle", course: "SCY", courseValue: 0 },
  { distance: 25, stroke: "Butterfly", course: "SCY", courseValue: 0 },
  { distance: 50, stroke: "Butterfly", course: "SCY", courseValue: 0 },
  { distance: 100, stroke: "Butterfly", course: "SCY", courseValue: 0 },
  { distance: 200, stroke: "Butterfly", course: "SCY", courseValue: 0 },
  { distance: 25, stroke: "Backstroke", course: "SCY", courseValue: 0 },
  { distance: 50, stroke: "Backstroke", course: "SCY", courseValue: 0 },
  { distance: 100, stroke: "Backstroke", course: "SCY", courseValue: 0 },
  { distance: 200, stroke: "Backstroke", course: "SCY", courseValue: 0 },
  { distance: 25, stroke: "Breaststroke", course: "SCY", courseValue: 0 },
  { distance: 50, stroke: "Breaststroke", course: "SCY", courseValue: 0 },
  { distance: 100, stroke: "Breaststroke", course: "SCY", courseValue: 0 },
  { distance: 200, stroke: "Breaststroke", course: "SCY", courseValue: 0 },
  { distance: 100, stroke: "Individual Medley", course: "SCY", courseValue: 0 },
  { distance: 200, stroke: "Individual Medley", course: "SCY", courseValue: 0 },
  { distance: 400, stroke: "Individual Medley", course: "SCY", courseValue: 0 },
  { distance: 25, stroke: "Freestyle", course: "SCM", courseValue: 1 },
  { distance: 50, stroke: "Freestyle", course: "SCM", courseValue: 1 },
  { distance: 100, stroke: "Freestyle", course: "SCM", courseValue: 1 },
  { distance: 200, stroke: "Freestyle", course: "SCM", courseValue: 1 },
  { distance: 400, stroke: "Freestyle", course: "SCM", courseValue: 1 },
  { distance: 800, stroke: "Freestyle", course: "SCM", courseValue: 1 },
  { distance: 1500, stroke: "Freestyle", course: "SCM", courseValue: 1 },
  { distance: 25, stroke: "Butterfly", course: "SCM", courseValue: 1 },
  { distance: 50, stroke: "Butterfly", course: "SCM", courseValue: 1 },
  { distance: 100, stroke: "Butterfly", course: "SCM", courseValue: 1 },
  { distance: 200, stroke: "Butterfly", course: "SCM", courseValue: 1 },
  { distance: 25, stroke: "Backstroke", course: "SCM", courseValue: 1 },
  { distance: 50, stroke: "Backstroke", course: "SCM", courseValue: 1 },
  { distance: 100, stroke: "Backstroke", course: "SCM", courseValue: 1 },
  { distance: 200, stroke: "Backstroke", course: "SCM", courseValue: 1 },
  { distance: 25, stroke: "Breaststroke", course: "SCM", courseValue: 1 },
  { distance: 50, stroke: "Breaststroke", course: "SCM", courseValue: 1 },
  { distance: 100, stroke: "Breaststroke", course: "SCM", courseValue: 1 },
  { distance: 200, stroke: "Breaststroke", course: "SCM", courseValue: 1 },
  { distance: 100, stroke: "Individual Medley", course: "SCM", courseValue: 1 },
  { distance: 200, stroke: "Individual Medley", course: "SCM", courseValue: 1 },
  { distance: 400, stroke: "Individual Medley", course: "SCM", courseValue: 1 },
  { distance: 50, stroke: "Freestyle", course: "LCM", courseValue: 2 },
  { distance: 100, stroke: "Freestyle", course: "LCM", courseValue: 2 },
  { distance: 200, stroke: "Freestyle", course: "LCM", courseValue: 2 },
  { distance: 400, stroke: "Freestyle", course: "LCM", courseValue: 2 },
  { distance: 800, stroke: "Freestyle", course: "LCM", courseValue: 2 },
  { distance: 1500, stroke: "Freestyle", course: "LCM", courseValue: 2 },
  { distance: 50, stroke: "Butterfly", course: "LCM", courseValue: 2 },
  { distance: 100, stroke: "Butterfly", course: "LCM", courseValue: 2 },
  { distance: 200, stroke: "Butterfly", course: "LCM", courseValue: 2 },
  { distance: 50, stroke: "Backstroke", course: "LCM", courseValue: 2 },
  { distance: 100, stroke: "Backstroke", course: "LCM", courseValue: 2 },
  { distance: 200, stroke: "Backstroke", course: "LCM", courseValue: 2 },
  { distance: 50, stroke: "Breaststroke", course: "LCM", courseValue: 2 },
  { distance: 100, stroke: "Breaststroke", course: "LCM", courseValue: 2 },
  { distance: 200, stroke: "Breaststroke", course: "LCM", courseValue: 2 },
  { distance: 200, stroke: "Individual Medley", course: "LCM", courseValue: 2 },
  { distance: 400, stroke: "Individual Medley", course: "LCM", courseValue: 2 },
]
