import { createStore, action } from "easy-peasy"

export default createStore({
  user: null,
  setUser: action((state, user) => {
    if (user === null || user === undefined) {
      state.user = null
    } else {
      state.user = user
    }
  }),
  triggerUsersSwimmersRefresh: 0,
  setTriggerUsersSwimmersRefresh: action(
    (state, triggerUsersSwimmersRefresh) => {
      state.triggerUsersSwimmersRefresh = triggerUsersSwimmersRefresh
    },
  ),
  aliasedClub: null,
  setAliasedClub: action((state, aliasedClub) => {
    if (aliasedClub === null || aliasedClub === undefined) {
      state.aliasedClub = null
    } else {
      state.aliasedClub = aliasedClub
    }
  }),
  acceptedCookies: false,
  setAcceptedCookies: action((state, acceptedCookies) => {
    state.acceptedCookies = !!acceptedCookies
  }),
  seasonPrepModal: null,
  setSeasonPrepModal: action((state, whichModal) => {
    state.seasonPrepModal = whichModal
  }),
})
