import React from "react"
import { Button } from "antd"
import GenericModal from "./GenericModal"
import styled from "styled-components"

const Content = styled.div`
  p {
    font-size: 1.3rem;
  }
`

interface ICookiePermissionModalProps {
  visible: boolean
  setVisible: (value: boolean) => void
  acceptCookies: () => void
}

export default function CookiePermissionModal({
  visible,
  setVisible,
  acceptCookies,
}: ICookiePermissionModalProps) {
  return (
    <GenericModal
      visible={visible}
      setVisible={setVisible}
      width="95%"
      maxWidth="400px"
      title="Cookies/Permission 🍪"
      footer={() => (
        <>
          <Button
            key="cancel"
            onClick={() => {
              setVisible(false)
            }}
          >
            Cancel
          </Button>
          <Button
            key="ok"
            onClick={() => {
              acceptCookies()
              setVisible(false)
            }}
          >
            Ok
          </Button>
        </>
      )}
    >
      <Content>
        <p>Hi there 👋,</p>
        <p>
          Like many sites on the internet we use cookies to help provide a
          better experience on our site.
        </p>
        <p>
          This means We store a tiny amount of information in your browser to
          help us identify you when you come back to our site on the same
          computer. This makes our site and your data more secure.
        </p>
        <p>
          FYI if you're on a public computer you should be sure to sign out of
          the clubhouse by clicking the log out button when you're done!
        </p>
      </Content>
    </GenericModal>
  )
}
