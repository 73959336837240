import React, { useEffect, useState } from "react"
import AllMeetsCard from "./SwimmerResults/AllMeetsCard"
import BestTimesCard from "./BestTimesCard"
import SwimmerTimeCard from "./SwimmerTimeCard"
import CenterSpin from "./CenterSpin"
import { gen, swimminglyApi } from "./utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faClock,
  faTrophy,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons"
import _ from "lodash"


function AthleteView({ swimmer, user, clubId }) {
  const [active, setActive] = useState("lastMeet")

  const renderInner = (active) => {
    if (active === "lastMeet") {
      return (
        <AllMeetsCard
          swimmer_id={swimmer.id}
          club={clubId}
          user={user}
        />
      )
    }
    if (active === "bestTimes") {
      return (
        <BestTimesCard
          swimmer_id={swimmer.id}
          user={user}
        />
      )
    }
    if (active === "swimmerTime") {
      return (
        <SwimmerTimeCard
          swimmer={swimmer}
          clubId={clubId}
          user={user}
        />
      )
    }
  }

  const getStyles = (isActive) => {
    if (!isActive) {
      return {
        borderRadius: "50%",
        border: "4px solid #22C8FA",
        cursor: "pointer",
      }
    }
    return {
      borderRadius: "50%",
      border: "4px solid #22C8FA",
      cursor: "pointer",
      backgroundColor: "#ddd",
    }
  }

  return (
    <div>
      <header className="page-header">
        <h2>
          {swimmer.first_name} {swimmer.last_name}
        </h2>
      </header>
      <div
        id="hereWeGo"
        style={{ marginTop: "-20px", marginBottom: "10px" }}
      />
      <div className="row">
        <div>
          <div
            style={{ backgroundColor: "white", color: "black" }}
            className="ourpanel-body"
          >
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    paddingTop: "5px",
                  }}
                >
                  <div>
                    <div
                      style={getStyles(active === "lastMeet")}
                      onClick={() => setActive("lastMeet")}
                    >
                      <div style={{ fontSize: "15.5px" }}>
                        <FontAwesomeIcon
                          icon={faClock}
                          size="6x"
                          style={{ padding: "20px" }}
                        />
                      </div>
                    </div>
                    <div style={{ textAlign: "center" }}>Meet Results</div>
                  </div>
                  <div>
                    <div
                      style={getStyles(
                        active === "bestTimes",
                      )}
                      onClick={() => setActive("bestTimes")}
                    >
                      <div style={{ fontSize: "15.5px" }}>
                        <FontAwesomeIcon
                          icon={faTrophy}
                          size="6x"
                          style={{ padding: "20px" }}
                        />
                      </div>
                    </div>
                    <div style={{ textAlign: "center" }}>Best Times</div>
                  </div>
                  <div>
                    <div
                      style={getStyles(
                        active === "swimmerTime",
                      )}
                      onClick={() => setActive("swimmerTime")}
                    >
                      <div style={{ fontSize: "17px" }}>
                        <FontAwesomeIcon
                          icon={faChartLine}
                          size="6x"
                          style={{ padding: "20px" }}
                        />
                      </div>
                    </div>
                    <div style={{ textAlign: "center" }}>Progression</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div
            style={{ backgroundColor: "white", color: "black" }}
            className="ourpanel-body"
          >
            {renderInner(active)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default function AthleteViewReal({swimmerId, user}) {
  const [swimmer, setSwimmer] = useState()
  const [clubId, setClubId] = useState(0)

  useEffect(() => {
    swimminglyApi
      .post(gen("/api/athleteProfile2Post"))
      .body({
        swimmerId: swimmerId,
        user: user,
      })
      .then((data) => {
        setSwimmer(data.swimmer)
        setClubId(_.last(data.clubIds) ?? 0)
      })
  }, [swimmerId, user, clubId]);


  return _.isNil(swimmer) ? ( <CenterSpin /> ) : (<AthleteView swimmer={swimmer} clubId={clubId} user={user} key={clubId} />)

}
