import React, { useState, useEffect } from "react";
import { Table, DatePicker, Select, Tag, Form, Modal, Button, Input, Tooltip, Row, Col, message, Divider } from "antd";
import { PlusOutlined, EditOutlined, AppleOutlined, AndroidOutlined, LaptopOutlined, DeleteFilled } from "@ant-design/icons";
import moment, { Moment } from "moment";
import { swimminglyApi } from "./utils"; // Ensure correct path to utils

const { Option } = Select;

interface EditingNote {
    releaseId: number;
    releaseDescription: string;
    releaseDate: string;
    appUpdated: string;
    releaseVersion: string;
    sendReleaseEmail: boolean;
    releaseEmailSent: boolean;
    postedByUser: string;
}

const AppUpdateReleaseNotes: React.FC = () => {
    const [releaseNotes, setReleaseNotes] = useState<EditingNote[]>([]);
    const [filteredReleaseNotes, setFilteredReleaseNotes] = useState<EditingNote[]>([]);
    const [editingNote, setEditingNote] = useState<EditingNote | null>(null);
    const [deletingNote, setDeletingNote] = useState<EditingNote | null>(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [descriptionModalVisible, setDescriptionModalVisible] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [deleteInput, setDeleteInput] = useState("");
    const [newReleaseDate, setNewReleaseDate] = useState<Moment | null>(null);
    const [newAppUpdated, setNewAppUpdated] = useState<string | undefined>(undefined);
    const [newReleaseVersion, setNewReleaseVersion] = useState<string>("");
    const [releaseDescription, setReleaseDescription] = useState<string>("");
    const [charactersRemaining, setCharactersRemaining] = useState<number>(1000);
    const [loading, setLoading] = useState<boolean>(false); // Loading state for spinner
    const [tempReleaseVersion, setTempReleaseVersion] = useState<string>("");
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [filterValues, setFilterValues] = useState<{ appUpdated?: string; releaseVersion?: string }>({});
    const [releaseVersions, setReleaseVersions] = useState<string[]>([]);
    const [tagColor, setTagColor] = useState<string>("default");
    const [isDeleteValid, setIsDeleteValid] = useState<boolean>(false);

    // Function to fetch release notes and release versions
    const fetchReleaseNotes = async () => {
        setLoading(true); // Show loading spinner
        try {
            const response = await swimminglyApi.get("/api/releaseNotes");
            if (response && response.status === "success") {
                const { releaseNotes } = response;
                if (Array.isArray(releaseNotes)) {
                    setReleaseNotes(releaseNotes); // Update state with the fetched release notes
                    setFilteredReleaseNotes(releaseNotes);
                    const versions = Array.from(new Set(releaseNotes.map(note => note.releaseVersion).filter(Boolean)))
                    .sort(compareVersions); // Sort using the custom function
                    setReleaseVersions(versions);
                } else {
                    console.error("Unexpected structure of releaseNotes:", releaseNotes);
                    message.error('Failed to fetch release notes. Unexpected data structure.');
                }
            } else {
                console.error("Unexpected response structure:", response);
                message.error('Failed to fetch release notes. Please try again.');
            }
        } catch (error: any) {
            console.error("Error fetching release notes:", error.message || error);
            message.error('An error occurred while fetching release notes.');
        } finally {
            setLoading(false); // Hide loading spinner
        }
    };

    // Fetch release notes when the component mounts
    useEffect(() => {
        fetchReleaseNotes();
    }, []); // Empty dependency array means this useEffect runs once on mount

    useEffect(() => {
        const { appUpdated, releaseVersion } = filterValues;
        let filtered = releaseNotes;

        if (appUpdated) {
            filtered = filtered.filter(note => note.appUpdated === appUpdated);
        }
        if (releaseVersion) {
            filtered = filtered.filter(note => note.releaseVersion === releaseVersion);
        }
        setFilteredReleaseNotes(filtered);

        // Update release versions based on filtered notes
        const versions = Array.from(new Set(filtered.map(note => note.releaseVersion).filter(Boolean)))
        .sort(compareVersions); 
        setReleaseVersions(versions);

    }, [filterValues, releaseNotes]);

    // Handle filter changes
    const handleFilterChange = (value: string, filterType: 'appUpdated' | 'releaseVersion') => {
        setFilterValues(prev => ({ ...prev, [filterType]: value }));
    };

    // Reset filters
    const handleResetFilters = () => {
        setFilterValues({});
        setFilteredReleaseNotes(releaseNotes);
        setReleaseVersions(Array.from(new Set(releaseNotes.map(note => note.releaseVersion).filter(Boolean))));
    };

    // Custom function to compare version strings
    const compareVersions = (a: string, b: string) => {
        const versionA = a.split('.').map(Number);
        const versionB = b.split('.').map(Number);
    
        const maxLength = Math.max(versionA.length, versionB.length);
    
        // Compare each part of the version
        for (let i = 0; i < maxLength; i++) {
        const numA = versionA[i] || 0; // Pad with 0 if undefined
        const numB = versionB[i] || 0; // Pad with 0 if undefined
        if (numA > numB) return 1;
        if (numA < numB) return -1;
        }
    
        return 0; // Versions are equal
    };
  

    const getAppUpdatedColor = (appUpdated: string) => {
        switch (appUpdated) {
            case "clubhouse":
                return "magenta";
            case "swimminglyIos":
                return "blue";
            case "swimminglyfanIos":
                return "blue";
            case "swimminglyAndroid":
                return "green";
            case "swimminglyfanAndroid":
                return "green";
            default:
                return "default"; // Default color if appUpdated doesn't match any case
        }
    };

    useEffect(() => {
        if (newAppUpdated) {
            setTagColor(getAppUpdatedColor(newAppUpdated));
        } else {
            setTagColor("default"); // Reset color if appUpdated is not set
        }
    }, [newAppUpdated]);

    // useEffect to validate the form whenever any of the relevant fields change
        useEffect(() => {
            validateForm(); // Validate form when any of the relevant fields change
        }, [newReleaseDate, newAppUpdated, newReleaseVersion, releaseDescription]);

    // Function to validate the form
    const validateForm = () => {
        const isValid = newReleaseDate !== null &&
                        newAppUpdated !== undefined &&
                        newReleaseVersion.trim() !== "" &&
                        releaseDescription.trim() !== "";
        setIsFormValid(isValid);
    };

    // Function to handle description updates
    const handleDescriptionUpdate = async () => {
        if (!editingNote) return;

        try {
            setLoading(true);
            const { releaseId, releaseDescription } = editingNote;
            const response = await swimminglyApi.put(`/api/releaseNotes/${releaseId}`).body({
                releaseDescription
            });
            if (response.status === "success") {
                fetchReleaseNotes(); // Refresh the list
                setDescriptionModalVisible(false); // Close modal after successful update
            } else {
                console.error("Error updating release description: ", response.status);
            }
        } catch (error) {
            console.error("Error updating release description:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.toUpperCase(); // Convert input to uppercase
        setDeleteInput(value);
        setIsDeleteValid(value === "DELETE"); // Validate if input matches "DELETE"
    };

    const handleDelete = async () => {
        if (!deletingNote || !isDeleteValid) return;

        try {
            setLoading(true);
            const response = await swimminglyApi.put(`/api/releaseNotes/${deletingNote.releaseId}/delete`).body({
                deletedAt: moment().format("YYYY-MM-DD HH:mm:ss")
            });
            if (response.status === "success") {
                fetchReleaseNotes(); // Refresh the list
                setDeleteModalVisible(false); // Close modal after successful deletion
                message.success('Release note successfully removed.');
            } else {
                console.error("Error deleting release note: ", response.status);
                message.error('Failed to delete the release note. Please try again.');
            }
        } catch (error) {
            console.error("Error deleting release note:", error);
            message.error('An unexpected error occurred. Please try again.');
        } finally {
            setLoading(false);
            setDeleteInput(""); // Reset the input field after deletion
            setIsDeleteValid(false); // Reset the validation state
        }
    };

    const handleCreateReleaseNote = async () => {
        setLoading(true); // Show loading spinner
        try {
            const response = await swimminglyApi.post('/api/releaseNotes').body({
                releaseDate: newReleaseDate?.format("YYYY-MM-DD"),
                appUpdated: newAppUpdated,
                releaseVersion: newReleaseVersion,
                releaseDescription: releaseDescription,
                sendReleaseEmail: false,
                postedByUserId: 8926 // Ensure this is included
            });
    
            // Check if the response indicates success
            if (response && response.status === "success") {
                await fetchReleaseNotes(); // Fetch updated records after creation
                setModalVisible(false); // Close modal
                message.success('Release Update Created & Posted In-App for Customer!'); // Show success message
            } else {
                console.error("Unexpected response format:", response);
                message.error('Failed to create release note. Please try again.'); // Show error message
            }
        } catch (error: any) {
            console.error("Error occurred:", error);
            message.error('An unexpected error occurred. Please try again.'); // Show error message
        } finally {
            setLoading(false); // Hide loading spinner
        }
    };    

    const handleEdit = (record: EditingNote) => {
        setNewReleaseDate(moment(record.releaseDate, "YYYY-MM-DD HH:mm:ss"));
        setNewAppUpdated(record.appUpdated);
        setNewReleaseVersion(record.releaseVersion);
        setReleaseDescription(record.releaseDescription);
        setEditingNote(record);  // Set the record being edited
        setModalVisible(true); // Open the modal
    };

    const handleUpdateReleaseNote = async () => {
        if (!editingNote) return;

        setLoading(true); // Show loading spinner
        try {
            const response = await swimminglyApi.put(`/api/releaseNotes/${editingNote.releaseId}`).body({
                releaseDate: newReleaseDate?.format("YYYY-MM-DD"),
                appUpdated: newAppUpdated,
                releaseVersion: newReleaseVersion,
                releaseDescription: releaseDescription
            });

            if (response && response.status === "success") {
                await fetchReleaseNotes(); // Refresh the list after update
                setModalVisible(false); // Close the modal
                message.success('Release Update Successfully Modified'); // Show success message
            } else {
                console.error("Unexpected response format:", response);
                message.error('Failed to modify release note. Please try again.'); // Show error message
            }
        } catch (error: any) {
            console.error("Error occurred while modifying release note:", error);
            message.error('An unexpected error occurred. Please try again.'); // Show error message
        } finally {
            setLoading(false); // Hide loading spinner
        }
    };

    const resetModalFields = () => {
        setNewReleaseDate(null);
        setNewAppUpdated(undefined);
        setNewReleaseVersion("");
        setReleaseDescription("");
        setEditingNote(null); // Reset editing note to ensure a new record is created
        setCharactersRemaining(1000);
        setTempReleaseVersion("");
    };

    const handleAddReleaseVersion = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && tempReleaseVersion.trim()) {
            setNewReleaseVersion(tempReleaseVersion.trim());
            setTempReleaseVersion(""); // Clear the temporary input
            validateForm(); // Validate form after adding release version
            e.preventDefault();
        }
    };

    const handleReleaseDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setReleaseDescription(e.target.value);
        setCharactersRemaining(1000 - e.target.value.length);
        validateForm(); // Validate form after description change
    };

    const handleBlurReleaseVersion = () => {
        if (tempReleaseVersion.trim()) {
            setNewReleaseVersion(tempReleaseVersion.trim());
            setTempReleaseVersion(""); // Clear the temporary input
            validateForm(); // Validate form after adding release version
        }
    };

    const columns = [
        {
            title: "Release Date",
            dataIndex: "releaseDate",
            render: (text: string) => {
              // Parse the incoming date string and format it for display
              const formattedDate = moment(text, "YYYY-MM-DD HH:mm:ss").format("MM-DD-YYYY");
              return (
                <span>{formattedDate}</span>
              );
            },
          },
          {
            title: "App Updated",
            dataIndex: "appUpdated",
            render: (text: string) => {
              let icon;
              let label;
              let color = getAppUpdatedColor(text);
          
              switch (text) {
                case "clubhouse":
                  icon = <LaptopOutlined />;
                  label = "Clubhouse";
                  break;
                case "swimminglyIos":
                  icon = <AppleOutlined />;
                  label = "Swimmingly";
                  break;
                case "swimminglyfanIos":
                  icon = <AppleOutlined />;
                  label = "SwimminglyFan";
                  break;
                case "swimminglyAndroid":
                  icon = <AndroidOutlined />;
                  label = "Swimmingly Timer";
                  break;
                case "swimminglyfanAndroid":
                  icon = <AndroidOutlined />;
                  label = "SwimminglyFan";
                  break;
                default:
                  icon = null;
                  label = "Not Set";
              }
          
              return (
                <Tag
                  color={color}
                  style={{ fontSize: '1.5em', alignItems: 'center' }}
                >
                  {icon} {label}
                </Tag>
              );
            },
          },
          
          {
            title: "Release Version",
            dataIndex: "releaseVersion",
            render: (text: string, record: EditingNote) => (
                text && text.trim() !== "" ? (
                    <Tag
                        color={getAppUpdatedColor(record.appUpdated)} // Match color with appUpdated
                        style={{ fontSize: '1.5em' }} // Make the tag 1.5x bigger
                    >
                        {text}
                    </Tag>
                ) : (
                    <Tag color="volcano">Not Set</Tag>
                )
            ),
        },        
        {
            title: "Release Description",
            dataIndex: "releaseDescription",
            render: (text: string) => (
                text && text.trim() !== "" ? (
                        <Input.TextArea
                            value={text}
                            autoSize={true}
                            readOnly 
                            disabled
                        />
                ) : (
                    <Tag color="volcano">Not Set</Tag>
                )
            ),
        },     
        {
            title: "Send Release Email",
            dataIndex: "sendReleaseEmail",
            render: (text: boolean) => (text ? "Yes" : "No"),
        },
        {
            title: "Release Email Sent",
            dataIndex: "releaseEmailSent",
            render: (text: boolean) => (text ? "Yes" : "No"),
        },
        {
            title: "Posted By",
            dataIndex: "postedByUser",
        },
        {
            title: "Actions",
            render: (text: string, record: EditingNote) => (
                <div>
                    <Button
                        icon={<EditOutlined />}
                        onClick={() => handleEdit(record)}
                        style={{ marginRight: 8 }} // Add spacing between buttons
                    />
                    <Button
                        type="text"
                        icon={<DeleteFilled />}
                        onClick={() => {
                            setDeletingNote(record);
                            setDeleteModalVisible(true);
                        }}
                    />
                </div>
            ),
        },
    ];

    return (
        <div>
            <div style={{ paddingTop: '20px', paddingLeft: '20px', paddingBottom: "40px" }}>
                <Tooltip title="This release update will be added to the Swimmingly update & release notes that our users can read!">
                    <Button
                        type="link"
                        icon={<PlusOutlined style={{ fontSize: '1.25em' }} />} // Make the icon 2x bigger
                        style={{ fontSize: '2em' }} // Make the button text 2x bigger
                        onClick={() => {
                            resetModalFields(); // Reset fields before opening the modal
                            setModalVisible(true);
                        }}
                    >
                        Create Release Update
                    </Button>
                </Tooltip>
            </div>
            <Form layout="inline" style={{ marginBottom: 16, paddingLeft: '40px' }}>
                <Form.Item label="App Updated">
                    <Select
                        style={{ width: 200 }}
                        placeholder="Select App Type"
                        onChange={(value: string | undefined) => handleFilterChange(value ?? '', 'appUpdated')}
                        allowClear
                    >
                        <Option value="clubhouse">
                            <LaptopOutlined /> Clubhouse
                        </Option>
                        <Option value="swimminglyIos">
                            <AppleOutlined /> Swimmingly
                        </Option>
                        <Option value="swimminglyfanIos">
                            <AppleOutlined /> SwimminglyFan
                        </Option>
                        <Option value="swimminglyAndroid">
                            <AndroidOutlined /> Swimmingly Timer
                        </Option>
                        <Option value="swimminglyfanAndroid">
                            <AndroidOutlined /> SwimminglyFan
                        </Option>
                    </Select>
                </Form.Item>
                <Form.Item label="Release Version">
                <Select
                    style={{ width: 200 }}
                    placeholder="Select Release Version"
                    onChange={(value: string | undefined) => handleFilterChange(value ?? '', 'releaseVersion')}
                    allowClear
                >
                    {releaseVersions.map(version => (
                        <Option key={version} value={version}>
                            {version}
                        </Option>
                    ))}
                </Select>
                </Form.Item>
                <Form.Item>
                    <Button onClick={handleResetFilters}>Reset Filters</Button>
                </Form.Item>
            </Form>
            <Divider />
            <Table 
                pagination={false}
                columns={columns} 
                dataSource={filteredReleaseNotes} 
                rowKey="releaseId" 
                loading={loading} 
                scroll={{ x: 800 }}
            />

            {/* Create Release Note Modal */}
            <Modal
                title={editingNote ? "Modify Release Update" : "Create Release Update"}
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                onOk={editingNote ? handleUpdateReleaseNote : handleCreateReleaseNote}
                okText={editingNote ? "Modify Release Update" : "Add Release Update"}
                okButtonProps={{ disabled: !isFormValid }} // Disable button if form is not valid
            >
                <Form layout="vertical">
                    <Form.Item label="Release Date">
                        <DatePicker
                            value={newReleaseDate}
                            format="MM-DD-YYYY"
                            onChange={(date) => {
                                setNewReleaseDate(date);
                                validateForm(); // Validate form after date change
                            }}
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="App Updated">
                                <Select
                                    value={newAppUpdated}
                                    style={{ width: "100%" }}
                                    onChange={(value) => {
                                        setNewAppUpdated(value);
                                        validateForm(); // Validate form after app updated change
                                    }}
                                >
                                    <Select.Option value="clubhouse">
                                        <LaptopOutlined /> Clubhouse
                                    </Select.Option>
                                    <Select.Option value="swimminglyIos">
                                        <AppleOutlined /> Swimmingly
                                    </Select.Option>
                                    <Select.Option value="swimminglyfanIos">
                                        <AppleOutlined /> SwimminglyFan
                                    </Select.Option>
                                    <Select.Option value="swimminglyAndroid">
                                        <AndroidOutlined /> Swimmingly Timer
                                    </Select.Option>
                                    <Select.Option value="swimminglyfanAndroid">
                                        <AndroidOutlined /> SwimminglyFan
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Release Version">
                                {newReleaseVersion ? (
                                    <Tag closable onClose={() => setNewReleaseVersion("")} color={tagColor}>
                                        {newReleaseVersion}
                                    </Tag>
                                ) : (
                                    <Input
                                        value={tempReleaseVersion}
                                        onChange={(e) => setTempReleaseVersion(e.target.value)}
                                        onPressEnter={handleAddReleaseVersion}
                                        onBlur={handleBlurReleaseVersion} // Trigger when input loses focus
                                        placeholder="Enter version (e.g., 4.6.24)"
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item label="Release Description">
                        <Input.TextArea
                            rows={4}
                            maxLength={1000}
                            value={releaseDescription}
                            onChange={handleReleaseDescriptionChange}
                        />
                        <div style={{ textAlign: "right" }}>{charactersRemaining} characters remaining</div>
                    </Form.Item>
                    <Form.Item>
                        <div>
                            <li style={{ color: "#f50" }}>
                                Note: Upon creating this release update, app version and description will be immediately visible to customers.
                                Double check your work before proceeding.
                            </li>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>

            {/* Edit Release Description Modal */}
            <Modal
                title={`Edit Release Notes Description for ${editingNote?.appUpdated} ${editingNote?.releaseVersion}`}
                visible={descriptionModalVisible}
                onCancel={() => setDescriptionModalVisible(false)}
                onOk={handleDescriptionUpdate}
            >
                <Input.TextArea
                    rows={4}
                    maxLength={1000}
                    value={editingNote?.releaseDescription}
                    onChange={(e) => setEditingNote({ ...editingNote!, releaseDescription: e.target.value })}
                />
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal
                title="Confirm Deletion"
                visible={deleteModalVisible}
                onCancel={() => setDeleteModalVisible(false)}
                onOk={handleDelete}
                okText="Confirm and Delete"
                okButtonProps={{
                    disabled: !isDeleteValid, // Disable button when isDeleteValid is false
                    style: isDeleteValid 
                        ? { backgroundColor: '#F50', borderColor: '#F50', color: '#fff' } 
                        : {}, // No custom styles when button is disabled
                }}
            >

                <p>To delete this record, type DELETE.</p>
                <Input
                    value={deleteInput}
                    onChange={handleDeleteInputChange}
                    style={{ textTransform: "uppercase" }} // Display input in uppercase
                />
            </Modal>
        </div>
    );
};

export default AppUpdateReleaseNotes;
