import { WarningOutlined } from "@ant-design/icons"
import { FunctionComponent } from "react"
import styled from "styled-components"
import { Colors } from "../styles"

export const StyledRegistrationWaiversTable = styled.table`
  border: 2px solid ${Colors.veryDarkBlue};

  thead,
  tbody,
  tr,
  th {
    border: 1px solid ${Colors.veryDarkBlue};
    width: 70%;
    text-align: center;
    padding: 15px;
  }
  td {
    border: 1px solid ${Colors.veryDarkBlue};
    text-align: center;
    padding: 5px;
  }
`

export const StyledAlertSpan = styled.span`
  background-color: rgb(254, 251, 230);
  border: 1px solid rgb(255, 229, 142);
  padding: 4px;
  border-radius: 3px;
  color: ${Colors.veryDarkBlue};
  cursor: pointer;
  p.inset {outline-style: inset;}
`

export const StyledPreformattedText = styled.pre`
  width: 95%;
  min-height: 200px;
  margin-bottom: 16px;
  white-space: normal;
  word-break: normal;
  font-family: "Open Sans";
  font-size: 18px;
  color: ${Colors.veryDarkBlue};
  
`

export const StyledWarningOutlined: FunctionComponent = () => (
  <WarningOutlined
    style={{
      color: "red",
      cursor: "pointer",
      fontSize: "1.5rem",
    }}
  />
)
