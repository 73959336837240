import React from "react"
import { DatePicker, Button, Checkbox, message } from "antd"
import moment from "moment-timezone"
import MeetSummaryTable from "./MeetSummaryTable"
import { gen, download } from "./utils"
const { RangePicker } = DatePicker

export default class ServiceReport extends React.Component {
  state = {
    sendServiceEmails: false,
    preMeetMarketingEmail: false,
    includeTestLeagues: false,
    emailSwimminglyTeam: false,
    downloadReport: false,
    meetReportStart: moment().subtract(3, "days"),
    meetReportEnd: moment().add(7, "days"),
    tableData: [],
    renderTable: false,
    loading: false,
  }

  reportDateChange = ([first, second]) => {
    this.setState({
      meetReportStart: first,
      meetReportEnd: second,
    })
  }

  serviceEmailChange = (e) => {
    //this.setState({ sendServiceEmails: e.target.checked })
    console.log("we've turned off sending service emails")
  }

  emailTeamChange = (e) => {
    this.setState({ emailSwimminglyTeam: e.target.checked })
  }

  includeTestLeaguesChange = (e) => {
    this.setState({ includeTestLeagues: e.target.checked })
  }

  downloadReportChange = (e) => {
    this.setState({ downloadReport: e.target.checked })
  }

  preMeetMarketingEmailChange = (e) => {
    this.setState({ preMeetMarketingEmail: e.target.checked })
  }

  onClick = async () => {
    this.setState({ loading: true })
    let response
    try {
      response = await fetch(gen(`/api/runMeetReport`), {
        method: "post",
        headers: { "Content-Type": "application/json" },
        credentials: "same-origin",
        body: JSON.stringify({
          "meet-report-start": this.state.meetReportStart.format("YYYY-MM-DD"),
          "meet-report-end": this.state.meetReportEnd.format("YYYY-MM-DD"),
          "email-xlsx-swimmingly": this.state.emailSwimminglyTeam,
          "include-test-leagues": this.state.includeTestLeagues,
          preMeetMarketingEmail: this.state.preMeetMarketingEmail,
        }),
      })
    } catch (error) {
      console.log(error)
    }
    if (response) {
      let meetReportData = await response.json()
      this.setState({
        renderTable: true,
        tableData: meetReportData,
        loading: false,
      })
      message.success("Meet Report run successfully!")
    }

    // now download the file
    if (this.state.downloadReport === true || this.state.emailSwimminglyTeam) {
      let fileResponse
      try {
        fileResponse = await fetch(gen(`/api/getMeetReportExcelFile`), {
          method: "post",
          headers: { "Content-Type": "application/json" },
          credentials: "same-origin",
          body: JSON.stringify({
            "meet-report-start":
              this.state.meetReportStart.format("YYYY-MM-DD"),
            "meet-report-end": this.state.meetReportEnd.format("YYYY-MM-DD"),
            "email-xlsx-swimmingly": this.state.emailSwimminglyTeam,
            "include-test-leagues": this.state.includeTestLeagues,
          }),
        })
      } catch (error) {
        console.log(error)
      }
      if (fileResponse && this.state.downloadReport) {
        if (
          fileResponse.headers.get("Content-Type") ===
          "application/octet-stream"
        ) {
          let file = await fileResponse.blob()
          await download(file, `MeetReport.xlsx`)
        } else {
          console.log("meet report result:")
          console.log(await fileResponse.json())
          message.error("problem downloading meet report")
        }
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <div>
            <RangePicker
              defaultValue={[
                this.state.meetReportStart,
                this.state.meetReportEnd,
              ]}
              onChange={this.reportDateChange}
            />
          </div>
          {/*<div>
            <Checkbox
              checked={this.state.sendServiceEmails}
              onChange={this.serviceEmailChange}
            >
              Wanna send service emails?
            </Checkbox>
          </div>*/}
          <div>
            <Checkbox
              checked={this.state.emailSwimminglyTeam}
              onChange={this.emailTeamChange}
            >
              Email a copy of the meet report to the team?
            </Checkbox>
          </div>
          <div>
            <Checkbox
              checked={this.state.includeTestLeagues}
              onChange={this.includeTestLeaguesChange}
            >
              Do you want to include test leagues
            </Checkbox>
          </div>
          <div>
            <Checkbox
              checked={this.state.downloadReport}
              onChange={this.downloadReportChange}
            >
              Do you want to download a copy of the meet report?
            </Checkbox>
          </div>
          <div>
            <Checkbox
              checked={this.state.preMeetMarketingEmail}
              onChange={this.preMeetMarketingEmailChange}
            >
              Do you want to send pre-meet marketing emails to parents
            </Checkbox>
          </div>
          <div>
            <Button
              type="primary"
              onClick={this.onClick}
              loading={this.state.loading}
            >
              Submit
            </Button>
          </div>
        </div>
        <MeetSummaryTable
          data={this.state.tableData}
          shouldRender={this.state.renderTable}
        />
      </React.Fragment>
    )
  }
}
