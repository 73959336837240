import styled from "styled-components"

export const Colors = {
    seasonSetupBlue: "#448DF7",
    primaryBlue: "#25bfea",
    veryDarkBlue: "#101242",
    announcementBlue: "#0047AB",
    hasCardOnFileText: "#4FAE32",
    noCardOnFileText: "#AB7D29",
    linkButtonBackground: "#110F43",
    bankAccountNotVerifiedBackground: "#EEF6FC",
    bankAccountVerifiedBackground: "#f6ffed"

}

export const Sizes = {
    iconSize: "96px",
    smallIconSize: "72px",
    statusIconSize: "48px"
}
