import React from "react"
import $ from "jquery"
import { Modal, message, Button } from "antd"
import { gen } from "./utils"
export default class EditEventTimeModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      min: props.min,
      sec: props.sec,
      milli: props.milli,
      result_id: null,
      loading: false,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // console.log(nextProps)
    if (
      nextProps.min !== this.state.min ||
      nextProps.sec !== this.state.sec ||
      nextProps.milli !== this.state.milli ||
      nextProps.result_id !== this.state.result_id
    ) {
      this.setState({
        min: nextProps.min,
        sec: nextProps.sec,
        milli: nextProps.milli,
        result_id: nextProps.result_id,
      })
    }
  }

  saveEvent = () => {
    let swimEventResultId = this.state.result_id
    let min = this.state.min
    let secs = this.state.sec
    let milliSecs = this.state.milli
    //console.log({ swimEventResultId, min, secs, milliSecs })
    if (!swimEventResultId) {
      message.error("Update didn't work")
      return
    }

    let seconds = min * 60 + +secs
    seconds = seconds + "." + milliSecs
    this.setState({ loading: true })
    $.ajax({
      url: gen("/api/updateMeetTimePost"),
      method: "POST",
      dataType: "json",
      data: {
        official_time: seconds,
        result_id: swimEventResultId,
      },
    }).done((data) => {
      this.setState({ loading: false })
      this.props.putTimeHere(swimEventResultId, seconds)
      this.props.setVisible(false)
      message.success("Successfully changed time")
    })
  }
  renderMinSelect = () => {
    let options = []
    for (let i = 0; i < 60; i++) {
      let opt = ("0" + i).slice(-2)
      options.push(
        <option value={opt} key={opt}>
          {opt}
        </option>,
      )
    }
    return (
      <select
        id="Mins"
        value={this.state.min}
        onChange={(e) => this.setState({ min: e.target.value })}
      >
        {options}
      </select>
    )
  }
  renderSecSelect = () => {
    let options = []
    for (let i = 0; i < 60; i++) {
      let opt = ("0" + i).slice(-2)
      options.push(
        <option value={opt} key={opt}>
          {opt}
        </option>,
      )
    }
    return (
      <select
        id="Secs"
        value={this.state.sec}
        onChange={(e) => this.setState({ sec: e.target.value })}
      >
        {options}
      </select>
    )
  }

  renderMilli = () => {
    let options = []
    for (let i = 0; i < 100; i++) {
      let opt = ("0" + i).slice(-2)
      options.push(
        <option value={opt} key={opt}>
          {opt}
        </option>,
      )
    }
    return (
      <select
        id="MilliSecs"
        value={this.state.milli}
        onChange={(e) => this.setState({ milli: e.target.value })}
      >
        {options}
      </select>
    )
  }

  render() {
    return (
      <Modal
        visible={this.props.visible}
        title="Edit Event Time"
        footer={[
          <Button key="back" onClick={() => this.props.setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={this.state.loading}
            onClick={this.saveEvent}
          >
            Submit
          </Button>,
        ]}
        onCancel={() => this.props.setVisible(false)}
      >
        <div className="form-group mt-lg">
          <label className="col-sm-3 ">Time:</label>
          <div className="col-sm-9">
            {this.renderMinSelect()}&nbsp; {this.renderSecSelect()} &nbsp;{" "}
            {this.renderMilli()}
          </div>
        </div>
      </Modal>
    )
  }
}
