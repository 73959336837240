import React from "react";
import {
  Button,
  message,
  Table,
  Row,
  Col,
  Select,
  Input,
  Radio,
  Checkbox,
  Divider,
  Tooltip,
  Tag,
  Modal,
} from "antd";
import { jsPDF } from "jspdf"; // Import jsPDF
import './MuseoSansBinary.js'; // import the custom font binary
import { gen, parseTime, swimminglyApi } from "./utils";
import { DownloadOutlined, InfoCircleOutlined } from "@ant-design/icons";
const { Option } = Select;

const secTommss = (seconds) => {
  const min = Math.floor(seconds / 60);
  const sec = seconds % 60;
  return `${min}:${sec < 10 ? "0" : ""}${sec.toFixed(2)}`;
};

const getPlaceSuffix = (place) => {
  if (place % 100 >= 11 && place % 100 <= 13) {
    return `${place}th`;
  }
  switch (place % 10) {
    case 1:
      return `${place}st`;
    case 2:
      return `${place}nd`;
    case 3:
      return `${place}rd`;
    default:
      return `${place}th`;
  }
};

export default class MeetsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      meetId:
        props.newMeetsArr &&
        props.newMeetsArr[0] &&
        props.newMeetsArr[0].meetId,
      ageStart: 1,
      ageEnd: 18,
      gender: "all",
      onlyBest: false,
      placeStart: 1,
      placeEnd: 10,

      swimmerArr: [],

      loadedMeets: false,
      loadedDQ: false,

      loadingSearch: false,
      loadingDQ: false,

      bestTimeDefinition: null,
      selectWidth: 400, // Default width
    };
  }

  componentDidMount() {
    this.adjustSelectWidth();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.newMeetsArr !== this.props.newMeetsArr) {
      this.adjustSelectWidth();
    }
  }

  adjustSelectWidth = () => {
    if (this.props.newMeetsArr) {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      context.font = "14px Arial"; // Should match the font style of the Select component
      const widths = this.props.newMeetsArr.map((el) =>
        context.measureText(el.name).width
      );
      const maxWidth = Math.max(...widths);
      const adjustedWidth = Math.min(maxWidth + 50, 500); // Adding some padding and setting a max width of 200px
      this.setState({ selectWidth: adjustedWidth });
    }
  };  

  handleOptionChange = (changeEvent) => {
    this.setState({
      gender: changeEvent.target.value,
    });
  };

  onClickSearchTimes = async () => {
    if (!this.state.meetId) {
      message.error("Need to select a meet");
      return;
    }
    await this.setState({ loadingSearch: true });
  
    const data = {
      meetId: this.state.meetId,
      ageStart: this.state.ageStart,
      ageEnd: this.state.ageEnd,
      gender: this.state.gender,
      clubId: this.props.club.clubId,
      placeStart: this.state.placeStart, 
      placeEnd: this.state.placeEnd, 
    };
    if (!data.ageStart) {
      data.ageStart = "null";
    }
    if (!data.ageEnd) {
      data.ageEnd = "null";
    }
  
    let response;
    let returnData;
    try {
      response = await fetch(gen("/api/meetCardPost"), {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
        method: "post",
        body: JSON.stringify(data),
      });
      returnData = await response.json();
    } catch (error) {
      await this.setState({ loadingSearch: false });
      message.error("Failed to fetch meet data");
      return;
    }
  
    returnData.loadedMeets = true;
    returnData.loadedDQ = false;
    returnData.loadingSearch = false;
  
    if (!returnData.swimmerArr) {
      returnData.swimmerArr = [];
    }
    let swimmerArr = returnData.swimmerArr;
    swimmerArr.sort((a, b) => {
      let nameA = a.last_name.toUpperCase(); // ignore upper and lowercase
      let nameB = b.last_name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
  
      // names must be equal
      return 0;
    });
  
    await this.setState({
      ...returnData,
      bestTimeDefinition: returnData.best_time_definition, // Set the bestTimeDefinition state
    });
  };
  
  onClickDQReport = async () => {
    if (!this.state.meetId) {
      message.error("Need to select a meet");
      return;
    }

    await this.setState({ loadingDQ: true });

    const data = {
      meetId: this.state.meetId,
      clubId: this.props.club.clubId,
    };

    let response;
    let returnData;

    try {
      response = await fetch(gen("/api/DQReportPost"), {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
        method: "post",
        body: JSON.stringify(data),
      });
      returnData = await response.json();
    } catch (error) {
      await this.setState({ loadingDQ: false });
      message.error("Failed to fetch DQ report");
      return;
    }

    returnData.loadedMeets = false;
    returnData.loadedDQ = true;
    returnData.loadingDQ = false;

    let swimmerArray = returnData.swimmerArr;
    if (swimmerArray) {
      swimmerArray.sort((a, b) => {
        let nameA = a.last_name.toUpperCase(); // ignore upper and lowercase
        let nameB = b.last_name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
    }
    this.setState(returnData);
  };

  showDownloadModal = (labelType) => {
    Modal.success({
      title: `Downloading ${labelType}`,
      okText: `I will, Thanks!`,
      content: (
        <div>
          <p>
            Every printer's setting are different. These labels are optimized
            to be printed at 100% scale. Please ensure your settings print this
            PDF at 100% scale.
          </p>
        </div>
      ),
      onOk() {},
    });
  };

  generateTimeImprovementLabels = () => {
    const { swimmerArr, meetDate, meetName } = this.state;
    const doc = new jsPDF();

    // Add the custom font
    doc.setFont("MuseoSansRounded-500", "normal");

    const rowsPerPage = 10;
    const colsPerPage = 3;
    const rowHeight = 25.4; // 1 inch
    const colWidth = 66.675; // 2 5/8 inch
    const colSpacing = 3.175; // 1/8 inch

    const totalColWidth = colsPerPage * colWidth + (colsPerPage - 1) * colSpacing;
    const totalRowHeight = rowsPerPage * rowHeight;
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    const leftMargin = (pageWidth - totalColWidth) / 2;
    const topMargin = (pageHeight - totalRowHeight) / 2;

    let row = 0;
    let col = 0;

    doc.setFontSize(7);

    swimmerArr.forEach((swimmer) => {
      swimmer.records.forEach((record) => {
        const isImprovement =
          record.prev_best && parseTime(record.prev_best) > parseTime(record.time);

        if (!isImprovement) {
          return; // Skip non-improvement records
        }

        if (col === colsPerPage) {
          col = 0;
          row++;
          if (row === rowsPerPage) {
            row = 0;
            doc.addPage();
          }
        }

        const x = leftMargin + col * (colWidth + colSpacing);
        const y = topMargin + row * rowHeight;

        const improvementTime =
          parseTime(record.prev_best) - parseTime(record.time);

        doc.roundedRect(x, y, colWidth, rowHeight, 2, 2); // Draw the border with rounded corners

        doc.text(`${swimmer.name} - ${getPlaceSuffix(record.place)} (Event Place)`, x + 3, y + 5);
        
        let meetText = `${meetDate} - ${meetName}`;
        // Limit meetText to a maximum of 90 characters, including ellipsis
        if (meetText.length > 90) {
          meetText = meetText.substring(0, 87) + '...';
}
        let meetLines = [];
        while (doc.getTextWidth(meetText) > colWidth - 12) {
          let splitIndex = meetText.lastIndexOf(' ', colWidth - 12);
          if (splitIndex === -1) {
            splitIndex = colWidth - 12;
          }
          meetLines.push(meetText.substring(0, splitIndex));
          meetText = meetText.substring(splitIndex).trim();
        }
        meetLines.push(meetText);
        
        meetLines.forEach((line, index) => {
          doc.text(line, x + 3, y + 9 + (index * 4));
        });

        const eventText = `${record.age_group} ${record.event}`;
        const prevBestText = `Prev Best: ${record.prev_best}`;
        const prevBestTextWidth = doc.getTextWidth(prevBestText);
        
        doc.text(eventText, x + 3, y + 16 + (meetLines.length - 1) * 4);
        doc.text(prevBestText, x + colWidth - prevBestTextWidth - 2, y + 16 + (meetLines.length - 1) * 4);

        const timeText = `Time: ${record.time}`;
        const improvementText = `Improvement: ${isImprovement ? secTommss(improvementTime / 100) : "-"} (${record.improvement})`;
  
        const timeTextWidth = doc.getTextWidth(timeText);
        const improvementTextWidth = doc.getTextWidth(improvementText);
        const availableWidth = colWidth - 4; // 2px padding on each side
  
        doc.text(timeText, x + 3, y + 20 + (meetLines.length - 1) * 4);
        doc.text(improvementText, x + 2 + availableWidth - improvementTextWidth, y + 20 + (meetLines.length - 1) * 4);
  
        col++;
      });
    });
  
    doc.save("time_improvement_labels.pdf");
    this.showDownloadModal("Time Improvements Ribbon Labels");
  };

  generateDqLabels = () => {
    const { swimmerArr, meetDate, meetName, meetCourse } = this.state;
    const doc = new jsPDF();
  
    // Add the custom font
    doc.setFont("MuseoSansRounded-500", "normal");
  
    const rowsPerPage = 10;
    const colsPerPage = 3;
    const rowHeight = 25.4; // 1 inch
    const colWidth = 66.675; // 2 5/8 inch
    const colSpacing = 3.175; // 1/8 inch
  
    const totalColWidth = colsPerPage * colWidth + (colsPerPage - 1) * colSpacing;
    const totalRowHeight = rowsPerPage * rowHeight;
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
  
    const leftMargin = (pageWidth - totalColWidth) / 2;
    const topMargin = (pageHeight - totalRowHeight) / 2;
  
    let row = 0;
    let col = 0;
  
    doc.setFontSize(6);
  
    swimmerArr.forEach((swimmer) => {
      if (col === colsPerPage) {
        col = 0;
        row++;
        if (row === rowsPerPage) {
          row = 0;
          doc.addPage();
        }
      }
  
      const x = leftMargin + col * (colWidth + colSpacing);
      const y = topMargin + row * rowHeight;
  
      doc.roundedRect(x, y, colWidth, rowHeight, 2, 2); // Draw the border with rounded corners
  
      doc.text(swimmer.name, x + 3, y + 5);
  
      let meetText = `${meetDate} | ${meetCourse} | ${meetName}`;
      if (meetText.length > 60) {
        meetText = `${meetText.substring(0, 57)}...`;
      }
      doc.text(meetText, x + 3, y + 9);
  
      doc.text(`${swimmer.event}`, x + 3, y + 13);
  
      const dqReasonLines = doc.splitTextToSize(`DQ Reason: ${swimmer.dq_reason}`, 60);
      let lineY = y + 17;
  
      dqReasonLines.forEach((line) => {
        doc.text(line, x + 3, lineY);
        lineY += 4; // Move to next line with some spacing
      });
  
      col++;
    });
  
    doc.save("dq_labels.pdf");
    this.showDownloadModal("DQ Ribbon Labels");
  };  

  render() {
    const radioStyle = {
      display: "block",
      height: "30px",
      lineHeight: "30px",
    };

    return (
      <div>
        <Row>
          <Col xs={24} sm={6} md={4} lg={3} style={{ marginTop: "10px" }}>
            Meets
          </Col>
          <Col xs={24} sm={18} md={20}>
            <Select
              value={this.state.meetId}
              onChange={(val) => this.setState({ meetId: val })}
              style={{ width: '100%' }}
            >
              {this.props.newMeetsArr.map((el) => (
                <Option value={el.meetId} key={el.meetId}>
                  {el.name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={6} md={4} lg={3} style={{ marginTop: "10px" }}>
            Ages
          </Col>
          <Col xs={8} sm={5} md={4} lg={3}>
            <Input
              type="number"
              name="startAge"
              min="1"
              max="100"
              value={this.state.ageStart}
              onChange={(e) => this.setState({ ageStart: e.target.value })}
            />
          </Col>
          <Col xs={8} sm={2} md={1} lg={1} style={{ textAlign: "center", paddingTop: "5px" }}>
            -
          </Col>
          <Col xs={8} sm={5} md={4} lg={3}>
            <Input
              type="number"
              name="endAge"
              min="1"
              max="100"
              value={this.state.ageEnd}
              onChange={(e) => this.setState({ ageEnd: e.target.value })}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={6} md={4} lg={3} style={{ marginTop: "10px" }}>
            Places
          </Col>
          <Col xs={8} sm={5} md={4} lg={3}>
            <Input
              type="number"
              name="placeStart"
              min="1"
              value={this.state.placeStart}
              onChange={(e) => this.setState({ placeStart: e.target.value })}
            />
          </Col>
          <Col xs={8} sm={2} md={1} lg={1} style={{ textAlign: "center", paddingTop: "5px" }}>
            -
          </Col>
          <Col xs={8} sm={5} md={4} lg={3}>
            <Input
              type="number"
              name="placeEnd"
              min="1"
              value={this.state.placeEnd}
              onChange={(e) => this.setState({ placeEnd: e.target.value })}
            />
          </Col>
          <Col xs={24} sm={6} md={4} lg={3} style={{ textAlign: "center", paddingTop: "5px" }}>
            <Tag color="cyan">NEW</Tag>
          </Col>
        </Row>
        
        <Row>
        <Col xs={24} sm={12} md={16} lg={6} style={{ marginTop: "10px" }}>
          <Radio.Group
            onChange={(changeEvent) =>
              this.setState({
                gender: changeEvent.target.value,
              })
            }
            value={this.state.gender}
            buttonStyle="outlined"
            size="small"
          >
            <Radio.Button value={"all"}>All</Radio.Button>
            <Radio.Button value={"male"}>Boys</Radio.Button>
            <Radio.Button value={"female"}>Girls</Radio.Button>
          </Radio.Group>
        </Col>
        </Row>
        <Row>
          <Col xs={24} sm={12} md={16} lg={6} style={{ marginTop: "10px" }}>
            <Radio.Group
              onChange={(e) => this.setState({ onlyBest: e.target.value })}
              value={this.state.onlyBest}
              buttonStyle="outlined"
              size="small"
            >
              <Radio.Button value={false}>All Times</Radio.Button>
              <Radio.Button value={true}>Time Improvement Only</Radio.Button>
            </Radio.Group>
          </Col>
        </Row>

        <Divider />

        <div>
          <div style={{ display: "flex", marginBottom: "20px" }}>
            <div
              className="form-group"
              style={{ marginTop: "5px" }}
            >
              <Button
                type="primary"
                onClick={this.onClickSearchTimes}
                loading={this.state.loadingSearch}
              >
                Run Meet Report
              </Button>
              {this.state.loadedMeets && (
                <Tooltip
                  title="Download time improvement ribbon labels PDF"
                >
                  {this.state.loadedMeets && (
                    <Button
                      type="link"
                      icon={<DownloadOutlined />}
                      style={{ marginTop: "20px" }}
                      onClick={this.generateTimeImprovementLabels}
                    >
                      Download Time Improvement Ribbon Labels &nbsp;<Tag color="cyan">New</Tag>
                    </Button>
                  )}
                </Tooltip>
              )}
              <Divider />
              <div
                style={{ marginTop: "5px" }}
              >
                <Button
                  onClick={this.onClickDQReport}
                  loading={this.state.loadingDQ}
                >
                  Run DQ Report
                </Button>
                {this.state.loadedDQ && (
                  <Tooltip
                    title="Download DQ ribbon labels PDF"
                  >
                    {this.state.loadedDQ && (
                      <Button
                        type="link"
                        icon={<DownloadOutlined />}
                        style={{ marginTop: "20px" }}
                        onClick={this.generateDqLabels}
                      >
                        Download DQ Ribbon Labels &nbsp;<Tag color="cyan">New</Tag>
                      </Button>
                    )}
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
          {this.state.loadedMeets && (
            <MeetTable
              {...this.state}
              bestTimeDefinition={this.state.bestTimeDefinition}
            />
          )}
          {this.state.loadedDQ && <DQTable {...this.state} />}
        </div>
      </div>
    );
  }
}

class MeetTable extends React.Component {
  render() {

    return (
      <div style={{ backgroundColor: "#F6F6F6" }} className="ourpanel-body">
        <div style={{ fontSize: "16px", lineHeight: "40px", fontWeight: "bold" }}>
          {`${this.props.meetName} (${this.props.meetCourse})`} <Divider type="vertical" />
          {`${this.props.meetDate}`} <Divider type="vertical" />
          {`${this.props.gender_text} ages ${this.props.age_group_text}`}
          <Divider />
        </div>

        <div className="form-group ">
          {this.props.swimmerArr.map((swimmer) => {
            const recordsToShow = this.props.onlyBest
              ? swimmer.records.filter((record) => {
                const isImprovement =
                  record.prev_best &&
                  parseTime(record.prev_best) > parseTime(record.time);
                return isImprovement;
              })
              : swimmer.records;

            if (this.props.onlyBest && recordsToShow.length === 0) {
              return null;
            }

            return (
              <div key={`${swimmer.swimmerId}`}>
                <div style={{ fontSize: "20px", marginTop: "13px" }}>
                  {swimmer.name}
                </div>
                <table className="table table-bordered table-striped mb-none rosterListing">
                  <thead>
                    <tr>
                      <th>Event</th>
                      <th>Age Group</th>
                      <th>Place</th>
                      <th>Time</th>
                      <Tooltip
                        title="Time Improvements based upon 'All Time Best' or 'Season Best' under your Manage Club settings"
                      >
                        <th>Previous Best <InfoCircleOutlined /></th>
                      </Tooltip>
                      <th>Time Improvement</th>
                      <th>% Improvement</th>
                      <th>Points</th>
                    </tr>
                  </thead>
                  <tbody>
                    {recordsToShow.map((record) => {
                      const isImprovement =
                        record.prev_best &&
                        parseTime(record.prev_best) > parseTime(record.time);
                      const improvementTime =
                        parseTime(record.prev_best) - parseTime(record.time);

                      return (
                        <tr
                          key={`${record.swimmerId}-${record.event}`}
                          style={{
                            backgroundColor: isImprovement ? "#C7FFC1" : "white",
                          }}
                        >
                          <td>{record.event}</td>
                          <td>{record.age_group}</td>
                          <td>{record.place}</td>
                          <td>{record.time}</td>
                          <td>{record.prev_best}</td>
                          <td>
                            {isImprovement
                              ? secTommss(improvementTime / 100)
                              : "-"}
                          </td>
                          <td>{record.improvement}</td>
                          <td>{record.points}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: "20px",
                    fontSize: "14px",
                  }}
                >
                  Total Points: {swimmer.points}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

class DQTable extends React.Component {
  render() {
    return (
      <div style={{ backgroundColor: "#F6F6F6" }} className="ourpanel-body">
        <div style={{ fontSize: "16px", lineHeight: "40px", fontWeight: "bold" }}>
          {`${this.props.meetName} | ${this.props.meetDate}`}
        </div>
        <Table
          rowKey={"id"}
          dataSource={this.props.swimmerArr}
          columns={[
            { title: "Name", dataIndex: "name", key: "name" },
            { title: "Event", dataIndex: "event", key: "event" },
            { title: "Age Group", dataIndex: "age_group", key: "age_group" },
            { title: "DQ Reason", dataIndex: "dq_reason", key: "dq_reason" },
          ]}
        />
      </div>
    );
  }
}
