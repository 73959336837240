import { useState, useEffect } from "react";
import { Switch, Table, Select, Modal } from "antd";
import styled from "styled-components";
import { debounce } from "lodash";
import { useHistory } from "react-router-dom";
import { useStoreActions } from "easy-peasy";
import { AppDataStore } from "../appData/types";
import { gen, useScreenSize, getLandingPageRoute, IUser, swimminglyApi } from "./utils";
import SearchInput from "./SearchInput";
import { screenSizes } from "../styles/GlobalStyles";

const CenteredSwitch = styled(Switch)`
  display: grid;
  justify-content: center;
  align-items: center;
`;

const RoleSelector = styled.div`
  
  width: 500px;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 0px;
  background: var(--snow);
  padding-bottom: 0px;
  @media (max-width: ${screenSizes.small}px) {
    width: 200px;
    .checkbox-and-label {
      margin-left: 20px;
    }
  }
  .role-group {
    display: grid;
    @media (max-width: ${screenSizes.small}px) {
      grid-template-columns: auto;
      gap: 0px;
    }
    padding-bottom: 15px;
  }
`;

interface IUserWithRoleDescription extends IUser {
  roleDescription: string;
  club: string | null;
  league: string | null;
}

const delayBeforeSubmittingSearchInMilliseconds = 100;

export default function UserManagement() {
  const [users, setUsers] = useState<IUserWithRoleDescription[]>([]);
  const [searchText, setSearchText] = useState("");
  const [paginationCurrent, setPaginationCurrent] = useState<number>(1);
  const [searchInformation, setSearchInformation] = useState("");
  const screenSize = useScreenSize();
  const setUser = useStoreActions((actions: AppDataStore) => actions.setUser);
  const setImpersonateClub = useStoreActions((actions: AppDataStore) => actions.setAliasedClub);

  const history = useHistory();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);

  const showModal = (user: IUser) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    if (selectedUser) {
      let userClubData;
      try {
        let response = await fetch(gen(`/api/getUserPrimaryClub/${selectedUser.userId}`), {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          credentials: "same-origin",
        });
        userClubData = await response.json();
      } catch (err) {
        console.error(err);
        return;
      }

      if (userClubData && userClubData.club) {
        setImpersonateClub(userClubData.club);
      } else {
        setImpersonateClub(null);
      }
      setUser(selectedUser);
      let landingPageRoute = getLandingPageRoute(selectedUser);
      history.push(landingPageRoute);
    }
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getUserData = () => {
    if (searchText === "" || searchInformation === "") {
      return;
    }
    swimminglyApi
      .post(gen("/api/getBasicInfoForAllUsers"))
      .body({
        searchText,
        searchInformation,
      })
      .then((data: { status: string; usersInfo: IUserWithRoleDescription[] }) => {
        setUsers([]);
        setUsers(data.usersInfo);
        setPaginationCurrent(1);
      });
  };

  useEffect(getUserData, [searchText, searchInformation]);

  const renderUserActive = (text: string, record: IUser) => {
    const checked = record.isActive;
    const onChange = (val: boolean) => {
      swimminglyApi
        .post(gen("/api/updateUserIsActive"))
        .body({ userId: record.userId, isActive: val ? 1 : 0 })
        .then((data) => {
          if (data.status === "success") {
            let theUsers = [...users].map((oneUser) => {
              if (oneUser.userId === record.userId) {
                return { ...oneUser, isActive: val };
              } else return oneUser;
            });
            setUsers(theUsers);
          }
        });
    };
    return (
      <CenteredSwitch
        key={`isActive_${record.userId}`}
        checked={checked}
        onChange={onChange}
        checkedChildren={"Active"}
        unCheckedChildren={"Inactive"}
      />
    );
  };

  const renderUserName = (text: string, record: IUser) => {
    return (
      <span
        onClick={() => showModal(record)}
        style={{ cursor: "pointer", color: "var(--linkblue)" }}
      >
        {record.name && record.name.trim() !== "" ? record.name : "no name"}
      </span>
    );
  };

  let columns;
  if (screenSize === "small") {
    columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: renderUserName,
      },
      {
        title: "Role",
        dataIndex: "role",
        key: "role",
      },
      {
        title: "Active",
        dataIndex: "isActive",
        key: "isActive",
        render: renderUserActive,
      },
    ];
  } else if (screenSize === "medium") {
    columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: renderUserName,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Phone #",
        dataIndex: "phone_number",
        key: "phoneNumber",
      },
      {
        title: "Role",
        dataIndex: "roleDescription",
        key: "role",
      },
      {
        title: "Active",
        dataIndex: "isActive",
        key: "isActive",
        render: renderUserActive,
      },
    ];
  } else {
    columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: renderUserName,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Phone",
        dataIndex: "phoneNumber",
        key: "phoneNumber",
      },
      {
        title: "Role",
        dataIndex: "roleDescription",
        key: "role",
      },
      {
        title: "Club",
        dataIndex: "club",
        key: "club",
      },
      {
        title: "League",
        dataIndex: "league",
        key: "league",
      },
      {
        title: "Active",
        dataIndex: "isActive",
        key: "isActive",
        render: renderUserActive,
      },
    ];
  }

  const handleChange = (selectedSearchInformation: string) => {
    setSearchInformation(selectedSearchInformation);
  };

  return (
    <>
      <RoleSelector>
        <div className="role-group">
        <h5 style={{ color: 'gray' }}>Choose a category to search users...</h5>
          <Select
            onSelect={handleChange}
            placeholder="Search by following category"
            options={[
              {
                value: "guardiansByClubName",
                label: "Club Name (Parent / Guardians will return)",
              },
              {
                value: "clubAdminsByClubName",
                label: "Club Name (Admins will return)",
              },
              {
                value: "leagueAdminsByLeagueName",
                label: "League Name (League Admins will return)",
              },
              {
                value: "anyoneByEmail",
                label: "Any Email (Any User will return)",
              },
            ]}
          />
        </div>
        <SearchInput
          placeholder="Enter your search here..."
          onChange={debounce((e) => {
            setSearchText(e.target.value);
            setPaginationCurrent(1);
          }, delayBeforeSubmittingSearchInMilliseconds)}
        />
      </RoleSelector>
      <Table
        pagination={{
          pageSizeOptions: ["10", "20", "50", "100", "10000"],
          current: paginationCurrent,
          onChange: (page: number) => setPaginationCurrent(page),
        }}
        rowKey="userId"
        dataSource={users}
        columns={columns}
      />

      <Modal
        title={`Alias Into User: ${selectedUser?.email}?`}
        visible={isModalOpen}
        onOk={handleOk}
        okButtonProps={{ autoFocus: true }}
        onCancel={handleCancel}
        okText="Yes, Alias Into User's Account"
        cancelText="Cancel"
      >
        <p>
          You will be aliased to {selectedUser?.email}'s account now. 
          <ul>
            <li>To escape out of this user's account, refresh your screen at anytime.</li>
            <li>You cannot register swimmers on behalf of this user. The user must be directly logged in to complete this action.</li>
            <li>If a user is an admin part of a 'By Club' payment method team, you must alias into their account to see the 'Club Billing' tab.</li>
          </ul>
        </p>
      </Modal>
    </>
  );
}
