import { DatePicker } from "antd"
import styled from "styled-components"

const StyledDatePicker = styled(DatePicker)`
  box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 5px;
  background-color: rgba(202, 244, 201, 0.2);
  font-weight: 700;
  cursor: pointer;

  input {
    cursor: pointer;
    padding: 5px;
    color: green;
    font-size: 1.5rem;
    background-color: rgba(202, 244, 201, 0.4);
  }

  &:hover {
    border: 2px solid rgba(0, 0, 0, 0.06);
  }
`

export default StyledDatePicker
