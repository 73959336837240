import { useState, useEffect } from "react"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../../appData/types"
import { gen, IClubSeasonInfo, swimminglyApi } from "../utils"
import ClubSeasonSwimmerInvoices from "./ClubSeasonSwimmerInvoices"

export interface ISwimmerInvoiceInfo {
  swimmerId: number
  clubId: number
  clubName: string
  leagueName: string
  seasonName: string
  clubSeasonId: number
  endDate: string
  seasonId: number
  lastName: string
  firstName: string
  dateOfBirth: string
  seasonAge: number | null
  gender: "Boy" | "Girl"
  clubPays: number | null
  parentPays: number | null
  numSwims: number
  payingUserId: number | null
  payingClubId: number | null
  paymentAmount: number
  paymentStatus: string
  paymentMethod: string
  createdAt: string
}


export default function ClubInvoices(): JSX.Element {
  const impersonateClub = useStoreState(
    (state: StateMapper<AppDataStore>) => state.aliasedClub,
  )
  const [seasons, setSeasons] = useState<IClubSeasonInfo[]>([])
  const [selectedSeason, setSelectedSeason] = useState(-1)

  useEffect(() => {
    if (impersonateClub?.clubId) {
      swimminglyApi
        .get(gen(`/api/getSwimSeasonsForClub/${impersonateClub.clubId}`))
        .then((data: { status: String; seasons: IClubSeasonInfo[] }) => {
          if (data.status === "success") {
            setSeasons(data.seasons)
          } else setSeasons([])
        })
    } else setSeasons([])
  }, [impersonateClub?.clubId])

  return (
    <div>
      <select
        style={{ marginLeft: "25px" }}
        value={selectedSeason}
        onChange={(e) => setSelectedSeason(parseInt(e.target.value, 10))}
      >
        <option value={-1}>Choose season...</option>
        {seasons.map((clubSeason) => (
          <option key={clubSeason.clubSeasonId} value={clubSeason.clubSeasonId}>
            {clubSeason.seasonName}
          </option>
        ))}
      </select>
      {selectedSeason !== -1 &&
        seasons.find(
          (clubSeason) => clubSeason.clubSeasonId === selectedSeason,
        ) && (
          <ClubSeasonSwimmerInvoices
            clubSeason={
              seasons.find(
                (clubSeason) => clubSeason.clubSeasonId === selectedSeason,
              ) as IClubSeasonInfo
            }
          />
        )}
    </div>
  )
}
