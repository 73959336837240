import React from "react"
import { withRouter } from "react-router-dom"
import { Button, DatePicker, message, Input, Row, Col } from "antd"
import moment from "moment-timezone"
import { momentTimezones, gen, swimminglyApi } from "./utils"

class LeagueInfoInner extends React.Component {
  state = {
    name: "",
    timezone: "",
    ageCutOff: moment(`${moment().year()}-06-01`),
    city: "",
    state: "",
    loading: false,
  }

  validate() {
    let isGood = true
    if (!this.state.name) {
      message.error("No name specified")
      isGood = false
    }
    if (!this.state.timezone) {
      message.error("No timezone specified")
      isGood = false
    }
    if (!this.state.city) {
      message.error("No city specified")
      isGood = false
    }
    return isGood
  }
  onClickSubmit = () => {
    this.setState({ loading: true })

    if (!this.validate()) {
      return
    }

    const obj = {
      name: this.state.name,
      TimeZoneCode: this.state.timezone,
      City: this.state.city,
      StateID: this.state.state,
      cut_off_year: this.state.ageCutOff.year(),
      cut_off_day: this.state.ageCutOff.date(),
      cut_off_month: this.state.ageCutOff.month() + 1,
      is_public: 0,
      userId: this.props.user.userId,
    }

    swimminglyApi
      .post(gen(`/api/post_add_leaguePost`))
      .body(obj)
      .then((data) => {
        this.setState({ loading: false })
        message.success("League added")
        this.props.history.push(`/app/admin/edit_league/${data.leagueId}`)
      })
  }

  disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment(`${moment().year()}-01-01`)
  }

  render() {
    let { momentTimezones } = this.props
    let momentKeys = Object.keys(momentTimezones)
    return (
      <div>
        <Row style={{ paddingBottom: "15px" }}>
          <Col span={6}>Name:</Col>
          <Col span={12}>
            <Input
              value={this.state.name}
              onChange={(e) => this.setState({ name: e.target.value })}
            />
          </Col>
        </Row>

        <Row style={{ paddingBottom: "15px" }}>
          <Col span={6}>TimeZone:</Col>
          <Col span={12}>
            <select
              style={{
                height: "35px",
                padding: 0,
                borderRadius: 0,
                width: "465px",
              }}
              value={this.state.timezone}
              onChange={(e) => this.setState({ timezone: e.target.value })}
            >
              {momentKeys.map((el) => (
                <option
                  style={{ height: "35px", padding: 0 }}
                  value={momentTimezones[el]}
                  key={el}
                >
                  {momentTimezones[el]}
                </option>
              ))}
            </select>
          </Col>
        </Row>

        <Row style={{ paddingBottom: "15px" }}>
          <Col span={6}>Age Cut Off Date:</Col>
          <Col span={12}>
            <DatePicker
              disabledDate={this.disabledDate}
              value={this.state.ageCutOff}
              onChange={(date) => this.setState({ ageCutOff: date })}
            />
          </Col>
        </Row>

        <Row style={{ paddingBottom: "15px" }}>
          <Col span={6}>City:</Col>
          <Col span={12}>
            <Input
              type="text"
              id="City"
              value={this.state.city}
              onChange={(e) => this.setState({ city: e.target.value })}
            />
          </Col>
        </Row>

        <Row style={{ paddingBottom: "15px" }}>
          <Col span={6}>State:</Col>
          <Col span={12}>
            <Input
              type="text"
              id="StateID"
              value={this.state.state}
              onChange={(e) => this.setState({ state: e.target.value })}
            />
          </Col>
        </Row>

        <Row>
          <Col span={6} />
          <Col span={12}>
            <Button
              onClick={this.onClickSubmit}
              type="primary"
              loading={this.state.loading}
            >
              Save{" "}
            </Button>
          </Col>
        </Row>
      </div>
    )
  }
}

const LeagueInfo = withRouter(LeagueInfoInner)

export default class AddLeague extends React.Component {
  render() {
    return (
      <div>
        <header className="page-header">
          <h2>Add League</h2>
        </header>

        <section className="panel">
          <header className="panel-heading">
            <h2 className="panel-title">Add League</h2>
          </header>
          <div className="ourpanel-body">
            <LeagueInfo
              momentTimezones={momentTimezones}
              user={this.props.user}
            />
          </div>
        </section>
      </div>
    )
  }
}
