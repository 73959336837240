import React, { useState, useEffect, useMemo } from "react";
import { Badge, Empty, Tooltip, message, Spin } from "antd";
import styled from "styled-components";
import moment from "moment-timezone";
import { useStoreState, StateMapper } from "easy-peasy";
import { AppDataStore } from "../appData/types";
import {
  gen,
  GenderFormatter,
  getPermissionBadge,
  IGuardian,
  ISwimmer,
  IUser,
  swimminglyApi,
  DateFormats, // Assuming DateFormats is imported from utils
} from "./utils";
import {
  ApproveButton,
  RejectButton,
} from "./ApprovRejectButtons";
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  EditOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";
import EditUserClubModal from "./EditUserClub";

interface EditingUser {
  userIdEdit: number | null;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  role: number;
  title: string | null;
}

const ParentTable = styled.table`
  width: 100%;
  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  th:hover {
    background-color: #f5f5f5;
  }
  tr:nth-child(even) {
    background-color: #f4f4f4;
  }
  tr:nth-child(odd) {
    background-color: var(--white);
  }
`;

export default function ClubParentsTable({
  seasonId,
}: {
  seasonId?: number | null;
}) {
  const user = useStoreState(
    (state: StateMapper<AppDataStore>) => state.user
  );
  const impersonateClub = useStoreState(
    (state: StateMapper<AppDataStore>) => state.aliasedClub
  );

  const [allParents, setAllParents] = useState<Array<IGuardian>>([]);
  const [expanded, setExpanded] = useState<number | null>(null);
  const [triggerRefresh, setTriggerRefresh] = useState(0);
  const [sortedAscending, setSortedAscending] = useState<Boolean>(true); // Changed to boolean
  const [loading, setLoading] = useState(true); // Loading state

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingUser, setEditingUser] = useState<EditingUser>({
    userIdEdit: null,
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    role: 3,
    title: null,
  });

  const editUser = (guardian: IGuardian) => {
    const nameSplit = guardian.name.split(" ");
    setEditingUser({
      userIdEdit: guardian.userId as number,
      email: guardian.email,
      firstName: nameSplit[0],
      lastName: nameSplit.slice(1).join(" "),
      phoneNumber: guardian.phoneNumber,
      role: guardian.role,
      title: guardian.role !== 5 ? guardian.title : null,
    });
    setIsEditModalVisible(true);
  };

  const fetchParentsData = () => {
    let mounted = true;
    setLoading(true); // Start loading
    if ((seasonId === null || seasonId === 0) && mounted) {
      setAllParents([]);
      setLoading(false); // End loading
    } else {
      let route = `/api/getAllGuardiansFromClub/${
        impersonateClub?.clubId || ""
      }`;
      if (seasonId !== null && seasonId !== undefined)
        route += `?seasonId=${seasonId}`;
      if (impersonateClub?.clubId) {
        swimminglyApi.get(gen(route)).then(({ status, clubGuardians }) => {
          if (status === "success") {
            const newAllParents: IGuardian[] = [];
            for (let i = 0; i < clubGuardians.length; i++) {
              const clubGuardian = clubGuardians[i];
              const formattedGuardian: IGuardian = {
                ...clubGuardian.guardian,
                swimmers: clubGuardian.guardiansSwimmers,
              };
              newAllParents.push(formattedGuardian);
            }
            if (mounted) {
              setAllParents(newAllParents);
            }
          }
          setLoading(false); // End loading
        });
      }
    }
    return () => {
      mounted = false;
    };
  };

  useEffect(() => {
    fetchParentsData();
  }, [impersonateClub?.clubId, triggerRefresh, seasonId]);

  const sortedParents = useMemo(() => {
    return [...allParents].sort((a, b) => {
      if (a.role !== b.role) {
        return a.role - b.role;
      }

      const lastNameA = a.name.split(" ").pop() || "zzz";
      const lastNameB = b.name.split(" ").pop() || "zzz";

      if (sortedAscending) {
        return lastNameA > lastNameB ? 1 : lastNameA < lastNameB ? -1 : 0;
      } else {
        return lastNameA < lastNameB ? 1 : lastNameA > lastNameB ? -1 : 0;
      }
    });
  }, [allParents, sortedAscending]);

  const handleSortToggle = () => {
    setSortedAscending((wasAscending) => !wasAscending);
  };

  const approveParent = (swimmer: ISwimmer, guardian: IGuardian) => {
    if (!user) {
      return message.error("You must be signed in to approve a request.");
    }
    swimminglyApi
      .post(gen("/api/createParentGuardianRelationship"))
      .body({
        swimmerId: swimmer.swimmerId,
        userId: guardian.userId,
        approverId: user.userId,
      })
      .then((data) => {
        if (data.status === "success") {
          message.success("success!");
        } else if (data.status === "error") {
          message.error("uh oh, something went wrong...");
        }
        return;
      })
      .catch(() => {
        message.error("uh oh, something went wrong...");
        return;
      })
      .finally(() => {
        setTriggerRefresh(triggerRefresh + 1);
      });
  };

  const rejectParent = (swimmer: ISwimmer, guardian: IGuardian) => {
    swimminglyApi
      .post(gen("/api/rejectParentGuardianRequest"))
      .body({ swimmerId: swimmer.swimmerId, userId: guardian.userId })
      .then((data) => {
        if (data.status === "success") {
          message.success("success!");
        } else if (data.status === "error") {
          message.error("uh oh, something went wrong...");
        }
        return;
      })
      .catch(() => {
        message.error("uh oh, something went wrong...");
        return;
      })
      .finally(() => {
        setTriggerRefresh(triggerRefresh + 1);
      });
  };

  return (
    <>
      {loading ? (
        <Spin tip="Loading..." size="large">
          <div style={{ minHeight: "200px" }} />
        </Spin>
      ) : (
        <ParentTable>
          <thead>
            <tr>
              <th />
              <th onClick={handleSortToggle}>
                Name of Parent/Guardian{" "}
                {sortedAscending ? (
                  <SortAscendingOutlined />
                ) : (
                  <SortDescendingOutlined />
                )}
              </th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Permission</th>
              <th>Edit User</th>
            </tr>
          </thead>
          <tbody>
            {sortedParents.length === 0 ? (
              <tr>
                <td colSpan={6}>
                  <Empty />
                </td>
              </tr>
            ) : (
              sortedParents.map((guardian) => {
                const lastLogin = guardian.lastLogin || guardian.updatedAt;
                const lastLoginFormatted = lastLogin
                  ? `Last login ${new Date(lastLogin).toLocaleDateString(
                      "en-US"
                    )}`
                  : "Last login unknown";

                return (
                  <React.Fragment key={`${guardian.userId}_row_group`}>
                    <tr key={guardian.userId}>
                      <td
                        onClick={() => {
                          if (expanded !== guardian.userId) {
                            setExpanded(guardian.userId);
                          } else {
                            setExpanded(null);
                          }
                        }}
                      >
                        {expanded === guardian.userId ? (
                          <MinusCircleOutlined style={{ cursor: "pointer" }} />
                        ) : (
                          <PlusCircleOutlined style={{ cursor: "pointer" }} />
                        )}
                        <Badge
                          style={{ marginLeft: "10px" }}
                          count={
                            guardian.swimmers?.filter(
                              (swimmer) =>
                                swimmer.guardianStatus !== "confirmed"
                            ).length || 0
                          }
                        />
                      </td>
                      <td>{guardian.name}</td>
                      <td>{guardian.email}</td>
                      <td>{guardian.phoneNumber}</td>
                      <td>
                        {getPermissionBadge(guardian)}
                        <div
                          style={{
                            fontSize: "smaller",
                            fontStyle: "italic",
                            color: "#8898aa",
                          }}
                        >
                          {lastLoginFormatted}
                        </div>
                      </td>
                      <td>
                        {guardian.role === 5 && (
                          <Tooltip title="Edit or promote this user">
                            <span
                              onClick={() => editUser(guardian)}
                              style={{ cursor: "pointer" }}
                            >
                              <EditOutlined />
                            </span>
                          </Tooltip>
                        )}
                      </td>
                    </tr>
                    {expanded === guardian.userId && (
                      <tr key={`${guardian.userId}_children`}>
                        <td colSpan={6}>
                          <table style={{ width: "100%" }}>
                            <thead>
                              <tr>
                                <th>Swimmer</th>
                                <th>Age</th>
                                <th>Birthday</th>
                                <th>Gender</th>
                                <th>Active</th>
                                <th>Guardian</th>
                              </tr>
                            </thead>
                            <tbody>
                              {guardian.swimmers.map(
                                ({ swimmer, guardianStatus }) => (
                                  <tr
                                    key={`${guardian.userId}_${swimmer.swimmerId}`}
                                  >
                                    <td>
                                      {swimmer.firstName} {swimmer.lastName}
                                    </td>
                                    <td>
                                      {moment().diff(
                                        moment(swimmer.dateOfBirth),
                                        "years"
                                      )}
                                    </td>
                                    <td>
                                      {moment(swimmer.dateOfBirth).format(
                                        DateFormats.default // Updated date format
                                      )}
                                    </td>
                                    <td>
                                      {GenderFormatter.format(
                                        swimmer.gender
                                      )}
                                    </td>
                                    <td>
                                      {swimmer.isActive ? (
                                        <CheckCircleTwoTone
                                          twoToneColor="#52c41a"
                                          style={{
                                            fontSize: "1.75rem",
                                          }}
                                        />
                                      ) : (
                                        <CloseCircleTwoTone
                                          twoToneColor="#eb2f96"
                                          style={{
                                            fontSize: "1.75rem",
                                          }}
                                        />
                                      )}
                                    </td>
                                    <td>
                                      {guardianStatus === "confirmed" ? (
                                        <CheckCircleTwoTone
                                          twoToneColor="#52c41a"
                                          style={{
                                            fontSize: "1.75rem",
                                          }}
                                        />
                                      ) : (
                                        <>
                                          <CloseCircleTwoTone
                                            twoToneColor="#eb2f96"
                                            style={{
                                              marginRight: "10px",
                                              fontSize: "1.75rem",
                                            }}
                                          />
                                          <ApproveButton
                                            action={() =>
                                              approveParent(
                                                swimmer,
                                                guardian
                                              )
                                            }
                                          />
                                          <RejectButton
                                            action={() =>
                                              rejectParent(
                                                swimmer,
                                                guardian
                                              )
                                            }
                                          />
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })
            )}
          </tbody>
          <EditUserClubModal
            userIdEdit={editingUser.userIdEdit}
            email={editingUser.email}
            firstName={editingUser.firstName}
            lastName={editingUser.lastName}
            phoneNumber={editingUser.phoneNumber}
            title={editingUser.title}
            role={editingUser.role}
            visible={isEditModalVisible}
            setVisible={setIsEditModalVisible}
            clubId={impersonateClub?.clubId}
            user={user}
            onUserUpdated={() => {
              setLoading(true);
              fetchParentsData();
            }}
          />
        </ParentTable>
      )}
    </>
  );
}