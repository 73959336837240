import React, {
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useReducer,
} from "react"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../../appData/types"
import { IRegistrationFormProps } from "."
import { gen, IClubSeasonInfo, swimminglyApi } from "../utils"
import { IWaiver } from "../ClubSeasonManagement/sharedState"
import { GuardianWaiverResponse } from "../ClubSeasonManagement/RegistrationConfiguration/RegistrationHelper"

type TFormStatus = "created" | "in progress" | "payment completed"


interface IRegistrationFormData {
  previewMode: boolean
  clubId: number
  seasonId: number
  registrationFormId: number
  guardianRegistrationFormId: number
  waiverIds: number[]
  waivers: IWaiver[]
  waiverResponses: Record<string,GuardianWaiverResponse>
  guardianWaiverIds: number[]
  clubDuesIds: number[]
  guardianClubDuesIds: number[]
  priceModificationCodeIds: number[]
  guardianFormStatus?: "created" | "in progress" | "payment completed"
}

interface IRegistrationFormContext extends IRegistrationFormData {}

const defaultData: IRegistrationFormContext = {
  previewMode: true,
  clubId: 0,
  seasonId: 0,
  registrationFormId: 0,
  guardianRegistrationFormId: 0,
  waiverIds: [],
  waivers: [],
  waiverResponses: {},
  guardianWaiverIds: [],
  clubDuesIds: [],
  guardianClubDuesIds: [],
  priceModificationCodeIds: [],
  guardianFormStatus: undefined,
}

const SharedContext = createContext<IRegistrationFormContext>(defaultData)

type TRegistrationFormActions = {
  type: "updateStore"
  newData: Partial<IRegistrationFormData>
}

const reducer = (
  prevState: IRegistrationFormData,
  action: TRegistrationFormActions,
): IRegistrationFormData => {
  if (action.type === "updateStore") {
    const newState: IRegistrationFormData = { ...prevState }
    Object.assign(newState, action.newData)
    return newState
  }
  return prevState
}


export interface IRegistrationFormContextProps {
  previewMode: boolean
  clubId: number
  seasonId: number
  waivers: IWaiver[]
  waiverResponses?: Record<string,GuardianWaiverResponse>
  selectedClubSeason?: IClubSeasonInfo | undefined
  children: React.ReactNode
}


export const RegistrationFormContextProvider: FunctionComponent<IRegistrationFormContextProps> =
  ({ clubId, seasonId, previewMode, children, waivers, waiverResponses, selectedClubSeason=undefined }) => {
    const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)
    const [state, dispatch] = useReducer(reducer, defaultData)

    useEffect(() => {
      dispatch({
        type: "updateStore",
        newData: { clubId, seasonId, previewMode, waivers, waiverResponses: waiverResponses ?? {}},
      })
    }, [clubId, previewMode, seasonId])

    useEffect(() => {

    }, [waivers, waiverResponses])

    return (
      <SharedContext.Provider value={{ ...state }}>
        {children}
      </SharedContext.Provider>
    )
  }

export function useRegistrationFormContext() {
  const context = useContext(SharedContext)

  if (!context)
    throw new Error(
      "useRegistrationFormContext must be used inside a `RegistrationFormContextProvider`",
    )

  return context
}
