import React, { Fragment, useEffect, useState } from "react"
import { DownOutlined } from "@ant-design/icons"
import { Menu, Dropdown, message, Modal, Select } from "antd"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../appData/types"
import { gen, IClub, ILeague, IUser, swimminglyApi } from "./utils"
const { Option } = Select

function changeToLeagueAdmin(targetUserId: number, leagueId: number | null) {
  // console.log(this.state)
  if (!targetUserId) {
    alert("No user selected")
    return
  }
  swimminglyApi
    .post(gen("/api/changeToLeagueAdmin"))
    .body({
      userId: targetUserId,
      leagueId,
    })
    .then((data) => {
      if (data.error) {
        message.error("Can't complete this action")
      } else {
        window.location.reload()
      }
    })
}

function changeToClubAdmin(targetUserId: number, clubId: number | null) {
  if (!targetUserId) {
    alert("No user selected")
    return
  }
  swimminglyApi
    .post("/api/changeToClubAdmin")
    .body({
      userId: targetUserId,
      clubId,
    })
    .then((data) => {
      if (data.error) {
        message.error("Can't complete this action")
      } else {
        window.location.reload()
      }
    })
}

// trace todo
function ChangeToParent({
  user,
  targetUser,
  visible,
  setVisible,
}: {
  user: IUser
  targetUser: IUser
  visible: boolean
  setVisible: (val: boolean) => void
}) {
  const onOk = () => {
    const targetUserId: number | null | undefined = targetUser.userId
    if (!targetUserId) {
      alert("No user selected")
      return
    }
    swimminglyApi
      .post(gen("/api/changeToParent"))
      .body({
        userId: targetUserId,
      })
      .then((data) => {
        if (data.error) {
          message.error("Can't complete this action")
        } else {
          setVisible(false)
          // trace todo reload data
        }
      })
  }

  return (
    <Modal
      title="Are you sure?"
      visible={visible}
      onOk={onOk}
      onCancel={() => setVisible(false)}
    >
      Take this action for user <b>{targetUser.email}</b>?
    </Modal>
  )
}

function ChangeToClub({
  user,
  targetUser,
  visible,
  setVisible,
  allClubs,
  selectedClub,
  setSelectedClub,
}: {
  user: IUser
  targetUser: IUser
  visible: boolean
  setVisible: (val: boolean) => void
  allClubs: IClub[]
  selectedClub: IClub | null
  setSelectedClub: (newClub: IClub) => void
}) {
  let word = "demote"
  if (targetUser.role > 4) {
    word = "promote"
  }

  let clubId: number | null = null
  if (user.role === 4) {
    clubId = user.clubId || null
  }

  return (
    <Modal
      title="Are you sure?"
      visible={visible}
      onOk={() =>
        changeToClubAdmin(
          targetUser.userId,
          selectedClub?.clubId || clubId || -1,
        )
      }
      onCancel={() => setVisible(false)}
    >
      This will {word} <b>{targetUser.email}</b> to a club admin.
      {allClubs.length > 0 && (
        <div>
          Select club below.
          <Select
            value={selectedClub?.clubId || -1}
            onChange={(val) => {
              const newSelectedClub = allClubs.find((cl) => cl.clubId === val)
              if (newSelectedClub) {
                setSelectedClub(newSelectedClub)
              }
            }}
            showSearch
            filterOption={(input, option) =>
              option?.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: "200px" }}
          >
            {allClubs.map((el, idx) => (
              <Option
                key={`ChaneToClub_option_${el.clubId || -1}_${idx}`}
                value={el.clubId || -1}
              >
                {el.name}
              </Option>
            ))}
          </Select>
        </div>
      )}
    </Modal>
  )
}

function ChangeToLeague({
  user,
  targetUser,
  visible,
  setVisible,
}: {
  user: IUser
  targetUser: IUser
  visible: boolean
  setVisible: (value: boolean) => void
}) {
  const [allLeagues, setAllLeagues] = useState<ILeague[]>([])
  const [selectedLeague, setSelectedLeague] = useState<ILeague | null>(null)

  useEffect(() => {
    if (user?.role === 2) {
      swimminglyApi.get(gen("/api/getAllLeagues")).then((data) => {
        setAllLeagues(data.leaguesArr)
      })
    }
  }, [user?.userId, user?.role])

  let leagueId: number | null = null
  if (user.role === 3) {
    leagueId = user.leagueId || null
  }

  return (
    <Modal
      title="Are you sure?"
      visible={visible}
      onOk={() =>
        changeToLeagueAdmin(targetUser.userId, selectedLeague?.id || leagueId)
      }
      onCancel={() => setVisible(false)}
    >
      This will promote <b>{targetUser.email}</b> to a league admin.
      {allLeagues.length > 0 && (
        <div>
          Select league below.
          <Select
            value={selectedLeague?.id || -1}
            onChange={(val) => {
              const newSelectedLeague = allLeagues.find(
                (league) => league.id === val,
              )
              setSelectedLeague(newSelectedLeague || null)
            }}
            showSearch
            filterOption={(input, option) =>
              option?.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: "200px" }}
          >
            {allLeagues.map((el) => (
              <Option key={`Change_to_league_${el.id}`} value={el.id}>
                {el.name}
              </Option>
            ))}
          </Select>
        </div>
      )}
    </Modal>
  )
}

interface IPromoteDemoteUserProps {
  targetUserId: number
  userId: number
}
export default function PromoteDemoteUser({
  targetUserId,
}: IPromoteDemoteUserProps) {
  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)
  const [targetUser, setTargetUser] = useState<IUser | null>(null)
  const [visible, setVisible] = useState(false)
  const [allClubs, setAllClubs] = useState<IClub[]>([])
  const [selectedClub, setSelectedClub] = useState<IClub | null>(null)
  const [parentVisible, setParentVisible] = useState(false)
  const [clubVisible, setClubVisible] = useState(false)
  const [leagueVisible, setLeagueVisible] = useState(false)

  useEffect(() => {
    if (user?.role === 2) {
      swimminglyApi
        .post(gen("/api/getAllClubsPost2"))
        .body({})
        .then((data) => {
          setAllClubs(data.clubsArr)
        })
    } else if (user?.role === 3 && user?.leagueId) {
      swimminglyApi
        .post(gen("/api/allClubsInLeaguePost"))
        .body({
          leagueId: user?.leagueId,
        })
        .then((data) => {
          setAllClubs(data.clubs)
        })
    }
  }, [user?.userId, user?.leagueId, user?.role])

  useEffect(() => {
    if (targetUserId) {
      swimminglyApi
        .get(`/api/user/${targetUserId}`)
        .then((data) => {
          if (data.status === "success" && data.user) {
            setTargetUser(data.user)
          } else {
            console.log(data)
            message.error("Problem finding user")
          }
        })
        .catch((err) => {
          console.log(err)
          message.error("Problem finding user")
        })
    }
  }, [targetUserId])

  const renderPromoteToLeague = () => {
    if (
      [2, 3].includes(user?.role || 0) &&
      [4, 5].includes(targetUser?.role || 0)
    ) {
      return (
        <Menu.Item key="promoteToLeague">
          <span>Promote to League Admin</span>
        </Menu.Item>
      )
    }
    return null
  }

  const renderPromoteToClub = () => {
    if (
      [2, 3, 4].includes(user?.role || 0) &&
      [5].includes(targetUser?.role || 0)
    ) {
      return (
        <Menu.Item key="promoteToClub">
          <span>Promote to Club Admin</span>
        </Menu.Item>
      )
    }
    return null
  }

  const renderDemoteToClub = () => {
    if (
      [2, 3].includes(user?.role || 0) &&
      [3].includes(targetUser?.role || 0)
    ) {
      return (
        <Menu.Item key="demoteToClub">
          <span>Demote to Club Admin</span>
        </Menu.Item>
      )
    }
    return null
  }


  const renderDemoteWithTitle = (title: string,key: "demoteToUnaffiliated"|"demoteToParent") => {
    if (
      [2, 3, 4].includes(user?.role || 0) &&
      [3, 4].includes(targetUser?.role || 0) &&
      (user?.role || 0) <= (targetUser?.role || 0)
    ) {
      return (
        <Menu.Item key={key}>
          <span>{title}</span>
        </Menu.Item>
      )
    }
    return null
  }

  return (
    <React.Fragment>
      <Dropdown
        overlay={
          <Menu
            onClick={({ item, key, keyPath, domEvent }) => {
              if (key === "promoteToLeague") {
                setLeagueVisible(true)
              }
              if (key === "promoteToClub") {
                setClubVisible(true)
              }
              if (key === "demoteToClub") {
                setClubVisible(true)
              }
              if (key === "demoteToParent" || key === "demoteToUnaffiliated") {
                setParentVisible(true)
              }
            }}
          >
            {renderPromoteToLeague()}
            {renderPromoteToClub()}
            {renderDemoteToClub()}
            {renderDemoteWithTitle("Demote to Parent", "demoteToParent")}
            {renderDemoteWithTitle("No longer affiliated with my club", "demoteToParent")}
          </Menu>
        }
      >
        <span
          className="ant-dropdown-link"
          style={{
            marginRight: "20px",
            cursor: "pointer",
          }}
        >
          Promote / Demote <DownOutlined />
        </span>
      </Dropdown>

      <Modal
        title="Are you sure?"
        visible={visible}
        onOk={() =>
          // trace todo
          changeToClubAdmin(targetUserId, selectedClub?.clubId || null)
        }
        onCancel={() => setVisible(false)}
      >
        This will promote <b>{targetUser?.email}</b> to a club admin. Select
        club below.
        <Select
          value={selectedClub?.clubId || -1}
          onChange={(newClubId) => {
            const newSelectedClub = allClubs.find(
              (club) => club.clubId === newClubId,
            )
            setSelectedClub(newSelectedClub || null)
          }}
          style={{ width: "200px" }}
        >
          {allClubs.map((el, idx) => (
            <Option
              key={`club_option_${el.clubId || -1}_${idx}`}
              value={el.clubId || -1}
            >
              {el.name}
            </Option>
          ))}
        </Select>
      </Modal>
      {user && targetUser && (
        <Fragment>
          <ChangeToParent
            user={user}
            targetUser={targetUser}
            visible={parentVisible}
            setVisible={setParentVisible}
          />
          <ChangeToClub
            user={user}
            allClubs={allClubs}
            selectedClub={selectedClub}
            setSelectedClub={setSelectedClub}
            targetUser={targetUser}
            visible={clubVisible}
            setVisible={setClubVisible}
          />
          <ChangeToLeague
            user={user}
            targetUser={targetUser}
            visible={leagueVisible}
            setVisible={setLeagueVisible}
          />
        </Fragment>
      )}
    </React.Fragment>
  )
}
