import { FunctionComponent, useEffect, useState, useRef } from "react";
import { Tooltip, Tabs, Select } from "antd";
import { useStoreState, StateMapper } from "easy-peasy";
import { AppDataStore } from "../../../appData/types";
import ContentPanel from "../../ContentPanel";
import { useClubSeasonManagementContext } from "../sharedState";
import { StyledLeagueInfoTable } from "./styles";
import ClubSignUpAlert from "../../ClubSignUpAlert";
import QrCode from "../../PoolsideQrCode";

const { TabPane } = Tabs;
const { Option } = Select;

export const SeasonSelection: FunctionComponent = () => {
  const impersonateClub = useStoreState(
    (state: StateMapper<AppDataStore>) => state.aliasedClub
  );

  const {
    clubSeasons,
    selectedClubSeason,
    clubsInSeason,
    loadClubSeasons,
    selectSeason,
    allUpcomingSeasons,
    loadUpcomingSeason,
    setSelectedClubSeason,
    setClubsInSeason,
  } = useClubSeasonManagementContext();

  const [dropdownWidth, setDropdownWidth] = useState(200); // Default width

  useEffect(() => {
    loadClubSeasons();
    loadUpcomingSeason();
  }, [loadClubSeasons, loadUpcomingSeason]);

  useEffect(() => {
    if (clubSeasons.length > 0) {
      const longestSeasonName = clubSeasons.reduce((longest, season) => {
        return season.seasonName.length > longest.length
          ? season.seasonName
          : longest;
      }, "");

      // Create a temporary span element to measure the width
      const span = document.createElement("span");
      span.style.visibility = "hidden";
      span.style.whiteSpace = "nowrap";
      span.style.fontSize = "16px"; // Adjust to match the dropdown font size
      span.innerText = longestSeasonName;
      document.body.appendChild(span);

      const width = span.getBoundingClientRect().width;
      document.body.removeChild(span);

      setDropdownWidth(width + 50); // Add some padding to the width
    }
  }, [clubSeasons]);

  const handleSeasonChange = (seasonId: number) => {
    setSelectedClubSeason(null);
    setClubsInSeason([]);
    selectSeason(seasonId);
  };

  return (
    <ContentPanel title="Season Registration:">
      <span style={{ color: "#111111", fontSize: "18px", backgroundColor: "#AAFF00" }}>
        {impersonateClub?.nameLong} 
      </span>{" "}{" "}
      <Select
        size="large"
        style={{ marginLeft: "0px", width: `${dropdownWidth}px` }}
        dropdownStyle={{ width: `${dropdownWidth}px` }}
        value={selectedClubSeason?.seasonId || undefined}
        onChange={handleSeasonChange}
        placeholder="Please select a season..."
      >
        {clubSeasons.map((clubSeason) => (
          <Option key={clubSeason.seasonId} value={clubSeason.seasonId}>
            {clubSeason.seasonName}
          </Option>
        ))}
      </Select>

      {selectedClubSeason && (
        <div>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Overview" key="1">
              <StyledLeagueInfoTable>
                <thead>
                  <tr>
                    <th>League</th>
                    <th>Location</th>
                    <th>Age Cutoff Date</th>
                    <th>Season Starts</th>
                    <th>Season Ends</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{selectedClubSeason.leagueName}</td>
                    <td>
                      {selectedClubSeason.leagueCity &&
                        selectedClubSeason.leagueState &&
                        `${selectedClubSeason.leagueCity}, ${selectedClubSeason.leagueState}`}
                    </td>
                    <td>{selectedClubSeason.cutOffDate}</td>
                    <td>{selectedClubSeason.startDate}</td>
                    <td>{selectedClubSeason.endDate}</td>
                  </tr>
                </tbody>
              </StyledLeagueInfoTable>
            </TabPane>

            <TabPane tab="Clubs" key="2">
              {clubsInSeason.length > 0 && (
                <div>
                  {[...clubsInSeason]
                    .sort((a, b) =>
                      (a?.name || "") > (b?.name || "") ? 1 : -1
                    )
                    .map((cl) => (
                      <Tooltip
                        key={`season_club_tooltip_${cl.clubId}`}
                        title={`${cl.nameLong}`}
                      >
                        <span
                          key={`season_club_span_${cl.clubId}`}
                          style={{
                            border: `1px solid ${
                              cl.clubId === impersonateClub?.clubId
                                ? "var(--primaryblue)"
                                : "lightgrey"
                            }`,
                            padding: "1px",
                            borderRadius: "5px",
                            marginTop: "0px",
                            marginLeft: "0px",
                            marginRight: "5px",
                            backgroundColor:
                              cl.clubId === impersonateClub?.clubId
                                ? "var(--primaryblue)"
                                : "lightgrey",
                            color: "white",
                          }}
                        >
                          {cl.name}
                        </span>
                      </Tooltip>
                    ))}
                </div>
              )}
            </TabPane>
            <TabPane
              tab="Club Registration Code"
              disabled={!allUpcomingSeasons.some(
                (season) => Boolean(season.seasonSetupConfirmedOn)
              )}
              key="3"
            >
              <ClubSignUpAlert />
            </TabPane>
            <TabPane
              tab="QR Code"
              disabled={!allUpcomingSeasons.some(
                (season) => Boolean(season.seasonSetupConfirmedOn)
              )}
              key="4"
            >
              <QrCode />
            </TabPane>
          </Tabs>
        </div>
      )}
    </ContentPanel>
  );
};
