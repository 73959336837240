import styled from "styled-components"

export const StyledLeagueInfoTable = styled.table`
  border: 1px solid var(--mediumgrey);
  border-color: #d2cfcf;
  color: #333;
  font-family: Helvetica, Arial, sans-serif;
  width: 100%;
  border-collapse: collapse;

  .rounded-button {
    border-radius: 5px;
  }

  th,
  td {
    padding: 5px;
    border: 1px solid var(--mediumgrey);
    border-collapse: collapse;
  }

  th {
    background: #f6f6f6;
    color: #757575;
  }

  td {
    color: #777;
  }
`
