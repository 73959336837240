import { DatePicker, message, Form, Input, Button, Layout, Row, Col, Typography, Popconfirm, Divider, Select, Alert, Spin, Tooltip, InputNumber } from "antd";
import { CheckCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import { useState, useEffect } from "react";
import styled from "styled-components";
import ContentPanel from "./ContentPanel";
import { gen, swimminglyApi } from "./utils";

const { Content } = Layout;
const { Title, Text } = Typography;

const StyledContent = styled(Content)`
  padding: 20px;
  background: #fff;
  margin: 20px auto;
  max-width: 800px;
  box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.04);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 5px;
`;

interface FormValues {
  referenceName: string;
  paymentType: string;
  paymentAmount: number;
  paymentReceived: moment.Moment;
  payingEntity: string;
  stripePaymentIntentId: string;
  intendedPricePerSwimmer: number;
  actualNumberOfSwimmers: number;
  expectedNumberOfSwimmers: number;
  notes: string;
}

export default function LumpSumPayment() {
  const [referenceName, setReferenceName] = useState<string>("");
  const [paymentType, setPaymentType] = useState<string>("");
  const [paymentAmount, setPaymentAmount] = useState<number>(0);
  const [paymentReceived, setPaymentReceived] = useState<moment.Moment>(moment());
  const [payingEntity, setPayingEntity] = useState<string>("");
  const [intendedPricePerSwimmer, setIntendedPricePerSwimmer] = useState<number>(0);
  const [notes, setNotes] = useState<string>("");
  const [expectedNumberOfSwimmers, setExpectedNumberOfSwimmers] = useState<number>(0);
  const [actualNumberOfSwimmers, setActualNumberOfSwimmers] = useState<number>(0);
  const [stripePaymentIntentId, setStripePaymentIntentId] = useState<string>("");
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const showAlert = localStorage.getItem("showSuccessAlert");
    if (showAlert) {
      setShowSuccessAlert(true);
    }
  }, []);

  useEffect(() => {
    if (showSuccessAlert) {
      const timer = setTimeout(() => {
        setShowSuccessAlert(false);
        localStorage.removeItem("showSuccessAlert");
      }, 15000); // 15 seconds

      return () => clearTimeout(timer);
    }
  }, [showSuccessAlert]);

  const resetForm = () => {
    setReferenceName("");
    setPaymentType("");
    setPayingEntity("");
    setStripePaymentIntentId("");
    setPaymentAmount(0);
    setPaymentReceived(moment());
    setIntendedPricePerSwimmer(0);
    setNotes("");
    setExpectedNumberOfSwimmers(0);
    setActualNumberOfSwimmers(0);
  };

  const submitForm = (values: FormValues) => {
    let shouldReturn = false;
    if (referenceName.trim() === "") {
      message.error("Add reference name", 5);
      shouldReturn = true;
    }
    if (paymentType === "") {
      message.error(
        "You have to select a payment type. If you're not sure what payment type to use or they haven't actually paid yet, use 'other'.",
        5
      );
      shouldReturn = true;
    }
    if (payingEntity.trim() === "") {
      message.error(
        "Write a description of who is paying under 'Paying Entity'",
        5
      );
      shouldReturn = true;
    }
    if (intendedPricePerSwimmer === 0) {
      message.error(
        "Add an intended price per swimmer so we can reconcile the payment amount later.",
        5
      );
      shouldReturn = true;
    }
    if (notes.trim() === "") {
      message.error(
        "Please add notes. We need to be able to review this later and understand what happened!",
        5
      );
      shouldReturn = true;
    }
    if (shouldReturn) {
      return;
    }
  
    const formData = {
      paymentType,
      paymentAmount,
      referenceName,
      paymentReceived: paymentReceived.format('YYYY-MM-DD'),
      payingEntity,
      intendedPricePerSwimmer,
      notes,
      expectedNumberOfSwimmers,
      actualNumberOfSwimmers,
      stripePaymentIntentId,
    };
  
    
  
    swimminglyApi
      .post(gen(`/api/createLumpSumPayment`))
      .body(formData)
      .then((data) => {
        if (data.status === "success") {
          setShowSuccessAlert(true);
          setLoading(true);
          setTimeout(() => {
            setLoading(false);
            resetForm();
          }, 1000); // Display spinner for 1 seconds
        } else {
          message.error("Uh oh, something went wrong");
          
        }
      })
      .catch((error) => {
        message.error("Uh oh, something went wrong");
        
      });
  };
  

  return (
    <Layout>
      {showSuccessAlert && (
        <Alert
          message="Success"
          description="Corporate / Offline payment record has been successfully created."
          type="success"
          showIcon
          closable
          onClose={() => setShowSuccessAlert(false)}
        />
      )}
      {loading ? (
        <Spin size="large" style={{ display: 'block', margin: '20px auto' }} />
      ) : (
        <StyledContent>
          <Title level={2}>Create Corporate / Offline Payment Record</Title>
          <Text>
            Corporate / Offline payment records can and should be updated as new information for the record becomes available.
            After creating a record, you can assign it to a club's season payment method, or, you can update their existing payment method.
          </Text>
          <Divider />
          <Form
            layout="vertical"
            onFinish={submitForm}
            initialValues={{
              paymentReceived: moment(),
            }}
          >
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item
                  label="Reference Name"
                  name="referenceName"
                  rules={[{ required: true, message: "Please input the reference name!" }]}
                >
                  <Input
                    value={referenceName}
                    onChange={(e) => setReferenceName(e.target.value)}
                    placeholder="[Year] - [Season Name] - [Name of Org]"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label="Payment Type"
                  name="paymentType"
                  rules={[{ required: true, message: "Please select a payment type!" }]}
                >
                  <Select
                    value={paymentType}
                    placeholder="select payment type..."
                    onChange={(value) => setPaymentType(value)}
                    options={[
                      { value: 'check', label: 'Check' },
                      { value: 'card', label: 'Card' },
                      { value: 'ach', label: 'ACH' },
                      { value: 'cash', label: 'Cash' },
                      { value: 'other', label: 'Other' },
                      { value: 'venmo', label: 'Venmo' },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
              <Form.Item
                label={
                  <>
                    Expected Date Payment Received
                    <Tooltip title="If unknown, this can be left blank/estimated date filled in, and updated to actual date later">
                      <QuestionCircleOutlined style={{ marginLeft: 8 }} />
                    </Tooltip>
                  </>
                }
                name="paymentReceived"
                rules={[{ required: true, message: "Please select the expected date!" }]}
              >
                <DatePicker
                  value={paymentReceived}
                  onChange={(date) => setPaymentReceived(date || moment())}
                />
              </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label="Paying Entity"
                  name="payingEntity"
                  rules={[{ required: true, message: "Please input the paying entity!" }]}
                >
                  <Input
                    value={payingEntity}
                    onChange={(e) => setPayingEntity(e.target.value)}
                    placeholder="[Person/Email] responsible for payment"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
              <Form.Item
                label="Total Payment Amount"
                name="paymentAmount"
                rules={[{ required: true, message: "Please input the payment amount!" }]}
              >
                <InputNumber
                  value={paymentAmount / 100}
                  min={0}
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
                  onChange={(value) => {
                    if (value === undefined || value === null) setPaymentAmount(0);
                    else setPaymentAmount(Math.round(value * 100));
                  }}
                  placeholder="Amount"
                  prefix="$"
                />
              </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label="Membership Price Per Swimmer"
                  name="intendedPricePerSwimmer"
                  rules={[{ required: true, message: "Please input the intended payment per swimmer!" }]}
                >
                  <InputNumber
                    value={intendedPricePerSwimmer / 100}
                    min={0}
                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
                    onChange={(value) => {
                      if (value === undefined || value === null) setIntendedPricePerSwimmer(0);
                      else setIntendedPricePerSwimmer(Math.round(value * 100));
                    }}
                    placeholder="How much are they (supposed) to pay per swimmer?"
                    prefix="$"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Expected # of Swimmers"
                name="expectedNumberOfSwimmers"
                rules={[{ required: true, message: "Please input the expected number of swimmers!" }]}
              >
                <InputNumber
                  value={expectedNumberOfSwimmers}
                  min={0}
                  onChange={(value) => setExpectedNumberOfSwimmers(value || 0)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Actual # of Swimmers"
                name="actualNumberOfSwimmers"
                rules={[{ required: true, message: "Please input the actual number of swimmers!" }]}
              >
                <InputNumber
                  value={actualNumberOfSwimmers}
                  min={0}
                  onChange={(value) => setActualNumberOfSwimmers(value || 0)}
                />
              </Form.Item>
            </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item
                label={
                  <>
                    Stripe Payment Intent ID
                    <Tooltip title="This should be the Stripe Payment Intent ID on the invoice. If the invoice has not yet been created in Stripe, you can fill this out later.">
                      <QuestionCircleOutlined style={{ marginLeft: 8 }} />
                    </Tooltip>
                  </>
                }
                  name="stripePaymentIntentId"
                  rules={[{ required: true, message: "Please input the Stripe Payment Intent ID!" }]}
                >
                  <Input
                    value={stripePaymentIntentId}
                    onChange={(e) => setStripePaymentIntentId(e.target.value)}
                    placeholder="Stripe Payment Intent ID"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label="Notes"
                  name="notes"
                  rules={[{ required: true, message: "Please add notes!" }]}
                >
                  <Input.TextArea
                    value={notes}
                    onChange={(e) => {
                      const newText = e.target.value;
                      if (newText.length < 1000) {
                        setNotes(newText);
                      }
                    }}
                    placeholder="Notes"
                    maxLength={1000}
                    showCount
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Popconfirm
                title="Are you sure you want to create this record?"
                overlayClassName="custom-popconfirm"
                onConfirm={() => submitForm({ 
                  referenceName, 
                  paymentType, 
                  paymentAmount, 
                  paymentReceived, 
                  payingEntity, 
                  stripePaymentIntentId,
                  intendedPricePerSwimmer, 
                  actualNumberOfSwimmers,
                  expectedNumberOfSwimmers,  
                  notes
                })}
                okText="Yes, Create Record"
                cancelText="Cancel"
              >
                <Button type="primary" htmlType="submit">
                  Create Corporate / Offline Payment Record
                </Button>
              </Popconfirm>
            </Form.Item>
          </Form>
        </StyledContent>
      )}
    </Layout>
  );
}
