import React, { useEffect, useState } from "react";
import { useStoreState, useStoreActions, StateMapper } from "easy-peasy";
import { AppDataStore } from "../appData/types";
import { validateEmail, gen, IClub, swimminglyApi } from "./utils";
import {
  message,
  Row,
  Col,
  Input,
  Select,
  Button,
  Tag,
  Drawer,
  Card,
  Tabs,
  Divider,
  Tooltip,
  Badge,
} from "antd";
import {
  MailOutlined,
  UserOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import HelpCenter from "../images/HelpCenter.png";
import HappySwimTeam from "../images/HappySwimTeam.png";
import EmailSupport from "../images/email-support-icon.png";
import PhoneSupport from "../images/phone-support-icon.png";
import ProvideFeedback from "../images/feedback-icon.png";
import FacebookCommunity from "../images/Facebook_Community.png";
import { Colors } from "./ClubSeasonManagement/styles";
import { FaLifeRing } from "react-icons/fa";
import UserReleaseNotes from "./UserReleaseNotes";

const { Option } = Select;
const { TabPane } = Tabs;

interface IEditUserProfileProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
}

export default function EditUserProfile({
  visible,
  setVisible,
}: IEditUserProfileProps) {
  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user);
  const setUser = useStoreActions((actions: AppDataStore) => actions.setUser);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [title, setTitle] = useState("");
  const [role, setRole] = useState(0);
  const [email, setEmail] = useState("");
  const [emailMessage, setEmailMessage] = useState("");
  const [firstNameMessage, setFirstNameMessage] = useState("");
  const [lastNameMessage, setLastNameMessage] = useState("");
  const [phoneNumberMessage, setPhoneNumberMessage] = useState("");
  const [userClubId, setUserClubId] = useState<number | undefined>(undefined);
  const [leagueClubs, setLeagueClubs] = useState<IClub[]>([]);
  const [triggerRefresh, setTriggerRefresh] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasNewRelease, setHasNewRelease] = useState(false); // Added state to track new release

  const sortClubs = (a: IClub, b: IClub) => {
    const aName = a.name && a.name.toLowerCase();
    const bName = b.name && b.name.toLowerCase();

    if (!aName || !bName) return 0;

    if (aName > bName) {
      return 1;
    }
    if (aName < bName) {
      return -1;
    }
    return 0;
  };

  useEffect(() => {
    const nameSplit: Array<string> = user?.name.split(" ") || ["", ""];
    if (nameSplit?.length > 0) setFirstName(nameSplit[0]);
    if (nameSplit?.length > 1)
      setLastName(nameSplit.slice(1, nameSplit.length).join(" "));

    setPhoneNumber(user?.phoneNumber || "");
    setTitle(user?.title || "");
    setRole(user?.role || 0);
    setEmail(user?.email || "");
    setEmailMessage("");
    setFirstNameMessage("");
    setLastNameMessage("");
    setPhoneNumberMessage("");
    setUserClubId(user?.clubId || -1);
  }, [user, triggerRefresh, visible]);

  // Check if there's a new release
  useEffect(() => {
    const fetchReleaseStatus = async () => {
      try {
        const response = await swimminglyApi.get(gen("/api/checkForNewRelease"));
        if (response.hasNewRelease !== undefined) {
          setHasNewRelease(response.hasNewRelease);
        }
      } catch (error) {
        console.error("Failed to check for new releases", error);
      }
    };

    if (user?.userId) {
      fetchReleaseStatus();
    }
  }, [user?.userId]);

  useEffect(() => {
    if (user?.role === 3 || user?.role === 2) {
      swimminglyApi
        .post(gen("/api/allClubsInLeaguePost"))
        .body({ leagueId: user.leagueId })
        .then((data) => {
          data.clubs.sort(sortClubs);
          setLeagueClubs(data.clubs);
        });
    }
  }, [user?.userId, user?.role, user?.leagueId]);

  const onSubmitFunc = () => {
    let isGood = onCheckValues();
    if (!isGood) {
      return;
    }
    setLoading(true);

    let data = {
      page: "profile",
      userId: user?.userId,
      name: `${firstName} ${lastName}`,
      phoneNumber,
      title,
      userClubId,
    };
    swimminglyApi
      .post(gen("/api/editUser"))
      .body(data)
      .then((response) => {
        setLoading(false);
        setVisible(false);
        if (response.error) {
          message.error(response.error);
        } else if (response.user) {
          setUser(response.user);
          message.success("Your profile changes saved successfully!");
        } else {
          message.warn("Huh, something may have gone wrong...");
        }
      })
      .catch(() => {
        message.error("Can't perform this action");
        setLoading(false);
      });
  };

  const onCheckValues = () => {
    let isGood = true;

    if (!user?.email || !validateEmail(user?.email)) {
      setEmailMessage("Not a valid email");
      isGood = false;
    }

    if (!firstName) {
      setFirstNameMessage("Not a valid first name");
      isGood = false;
    } else if (firstName.length < 1) {
      setFirstNameMessage("First name must be at least 1 character");
      isGood = false;
    } else if (firstName.length > 255) {
      setFirstNameMessage("First name must be at less than 255 characters");
      isGood = false;
    }

    if (!lastName) {
      setLastNameMessage("Not a valid last name");
      isGood = false;
    }

    if (lastName.length < 2) {
      setLastNameMessage("Last name must be at least 2 characters");
      isGood = false;
    }

    if (lastName.length > 255) {
      setLastNameMessage("Last name must be at less than 255 characters");
      isGood = false;
    }

    if (!phoneNumber) {
      setPhoneNumberMessage("Not a valid phone number");
      isGood = false;
    }

    const onlyDigits = phoneNumber.replace(/[^0-9]/, "");
    if (onlyDigits.length < 10) {
      setPhoneNumberMessage("Needs at least 10 digits");
      isGood = false;
    }

    return isGood;
  };

  // Handle Tab Change
  const handleTabChange = (activeKey: string) => {
    if (activeKey === "3") {
      setHasNewRelease(false); // Reset the badge when Updates tab is clicked
    }
  };

  return (
    <Drawer
      visible={visible}
      width="auto"
      placement="right"
      onClose={() => {
        setLoading(false);
        setVisible(false);
        setTriggerRefresh(triggerRefresh + 1);
      }}
    >
      <Tabs defaultActiveKey="1" onChange={handleTabChange}>
        <TabPane
          tab={
            <span>
              <UserOutlined />
              My Profile
            </span>
          }
          key="1"
        >
          <div>
            <br />
            <Row style={{ maxWidth: "440", paddingBottom: "15px" }}>
              <Col span={6}>Email</Col>
              <Col span={18}>
                <Input
                  prefix={<MailOutlined />}
                  value={email}
                  onChange={() => {}}
                  placeholder="Email Address"
                  type="email"
                  disabled={true}
                />
                {emailMessage && (
                  <span
                    id="spanUserName"
                    style={{ color: "var(--rejectionred)" }}
                  >
                    {emailMessage}
                  </span>
                )}
              </Col>
            </Row>

            <Row style={{ maxWidth: "440", paddingBottom: "15px" }}>
              <Col span={6}>First Name</Col>
              <Col span={18}>
                <Input
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    setFirstNameMessage("");
                  }}
                />
                {firstNameMessage && (
                  <span
                    id="spanUserName"
                    style={{ color: "var(--rejectionred)" }}
                  >
                    {firstNameMessage}
                  </span>
                )}
              </Col>
            </Row>

            <Row style={{ maxWidth: "440px", paddingBottom: "15px" }}>
              <Col span={6}>Last Name</Col>
              <Col span={18}>
                <Input
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    setLastNameMessage("");
                  }}
                />
                {lastNameMessage && (
                  <span
                    id="spanUserName"
                    style={{ color: "var(--rejectionred)" }}
                  >
                    {lastNameMessage}
                  </span>
                )}
              </Col>
            </Row>

            <Row style={{ maxWidth: "440px", paddingBottom: "15px" }}>
              <Col span={6}>Phone</Col>
              <Col span={18}>
                <Input
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                    setPhoneNumberMessage("");
                  }}
                />
                {phoneNumberMessage && (
                  <span
                    id="spanUserName"
                    style={{ color: "var(--rejectionred)" }}
                  >
                    {phoneNumberMessage}
                  </span>
                )}
              </Col>
            </Row>

            {role !== 5 && (
              <Row style={{ maxWidth: "440px", paddingBottom: "15px" }}>
                <Col span={6}>Title</Col>
                <Col span={18}>
                  <Select
                    style={{ width: "100%" }}
                    value={title}
                    onChange={setTitle}
                  >
                    <Option value="SwimminglyRep">Swimmingly Rep</Option>
                    <Option value="HeadCoach">Head Coach</Option>
                    <Option value="Assistant Coach">Assistant Coach</Option>
                    <Option value="ClubTreasure">Club Treasurer</Option>
                    <Option value="LeaguePresident">League President</Option>
                    <Option value="LeagueTreasurer">League Treasurer</Option>
                    <Option value="League Board">League Board</Option>
                  </Select>
                </Col>
              </Row>
            )}

            {(role === 3 || role === 2) && (
              <Row style={{ maxWidth: "400px", paddingBottom: "15px" }}>
                <Col span={6}>Your Club</Col>
                <Col span={18}>
                  <Select
                    style={{ width: "100%" }}
                    value={userClubId}
                    onChange={setUserClubId}
                  >
                    <Option value="">No Active Club</Option>
                    {leagueClubs.map((el) => (
                      <Option value={el.clubId as number} key={el.clubId}>
                        {el.name_long}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            )}
            <div>
              <Button
                key="submit"
                type="primary"
                block={true}
                icon={<UserOutlined />}
                loading={loading}
                onClick={() => {
                  onSubmitFunc();
                }}
              >
                Save Changes
              </Button>
            </div>
            <Divider />
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                justifyContent: "center",
                color: "#8898aa",
              }}
            >
              Your Clubhouse permission level
            </div>

            <div
              style={{
                display: "flex",
                marginTop: "10px",
                justifyContent: "left",
              }}
            >
              <Tooltip
                title="Manage all clubs in your league"
                mouseEnterDelay={1.0}
                placement="bottom"
                color={Colors.veryDarkBlue}
              >
                <Tag color={role === 3 ? "#25bfea" : "#a9bfcf"}>League Admin</Tag>
              </Tooltip>
              <Tooltip
                title="Manage your club only"
                mouseEnterDelay={1.0}
                placement="bottom"
                color={Colors.veryDarkBlue}
              >
                <Tag color={role === 4 ? "#25bfea" : "#a9bfcf"}>Club Admin</Tag>
              </Tooltip>
              <Tooltip
                title="Manage your swimmers, register new swimmers, and invite other guardians to follow your swimmers. At your admin's discretion, guardians can register for the season and pay their club, complete meet declarations, access heat sheets and results. View personalized athlete profiles for each of your swimmers at any time!"
                placement="bottom"
                mouseEnterDelay={1.0}
                color={Colors.veryDarkBlue}
              >
                <Tag color={role === 5 ? "#25bfea" : "#a9bfcf"}>Parent/Guardian</Tag>
              </Tooltip>
            </div>
          </div>
        </TabPane>

        <TabPane
          tab={
            <span>
              <FaLifeRing />
              &nbsp;Support
            </span>
          }
          key="2"
        >
          <div className="site-card-wrapper">
            {role !== 5 ? (
              <Row gutter={16}>
                <Col flex={2}>
                  <Card
                    title={
                      <a
                        href="https://academy.swimmingly.app/"
                        target="_blank"
                      >
                        Swimmingly Academy
                      </a>
                    }
                    bordered={true}
                    size="default"
                    type="inner"
                    cover={
                      <img
                        alt="Go to Swimmingly Academy"
                        src={HappySwimTeam}
                        style={{
                          maxWidth: 250,
                          maxHeight: 250,
                          margin: "auto",
                          width: "50%",
                          padding: "10px",
                        }}
                      />
                    }
                  >
                    Enroll in Swimmingly Academy and take
                    <br />
                    a free course, designed just for you!
                    <br />
                    Learn your role for your swim team
                    <br />
                    in minutes!
                  </Card>
                </Col>
              </Row>
            ) : (
              <Row gutter={16}>
                <Col flex={2}>
                  <Card
                    title={
                      <a
                        href="https://academy.swimmingly.app/p/swimmingly-parent"
                        target="_blank"
                      >
                        Swimmingly Academy
                      </a>
                    }
                    bordered={true}
                    size="default"
                    type="inner"
                    cover={
                      <img
                        alt="Go to Swimmingly Academy"
                        src={HappySwimTeam}
                        style={{
                          maxWidth: 250,
                          maxHeight: 250,
                          margin: "auto",
                          width: "50%",
                          padding: "10px",
                        }}
                      />
                    }
                  >
                    Enroll in Swimmingly Academy and take
                    <br />
                    a free course, designed just for you!
                    <br />
                    Learn your role for your swim team
                    <br />
                    in minutes!
                  </Card>
                </Col>
              </Row>
            )}
            <Row gutter={16}>
              <Col flex={2}>
                <Card
                  title={
                    <a
                      href="https://support.swimmingly.app"
                      target="_blank"
                    >
                      Help Center
                    </a>
                  }
                  bordered={true}
                  size="default"
                  type="inner"
                  cover={
                    <img
                      alt="Help Center"
                      src={HelpCenter}
                      style={{
                        maxWidth: 250,
                        maxHeight: 250,
                        margin: "auto",
                        width: "50%",
                        padding: "10px",
                      }}
                    />
                  }
                >
                  Your knowledge base for quick how-to
                  <br />
                  tutorials. This is a great resource for
                  <br />
                  self-service, education, and discovery
                  <br />
                  of new features.
                </Card>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col flex={2}>
                <Card
                  title={<a href="mailto:team@swimmingly.app">Email Support</a>}
                  bordered={true}
                  size="default"
                  type="inner"
                  cover={
                    <img
                      alt="Email Support"
                      src={EmailSupport}
                      style={{
                        maxWidth: 150,
                        maxHeight: 150,
                        margin: "auto",
                        width: "50%",
                        padding: "10px",
                      }}
                    />
                  }
                >
                  If you can't find an answer to your
                  <br />
                  question,{" "}
                  <a href="mailto:team@swimmingly.app">Email us for support</a>.
                </Card>
              </Col>
            </Row>
            {role !== 5 && (
              <Row gutter={16}>
                <Col flex={2}>
                  <Tooltip
                    title="Phone Support on the pool deck is available to North American customers"
                    placement="left"
                    color={Colors.veryDarkBlue}
                  >
                    <Card
                      title="Phone Support"
                      bordered={true}
                      size="default"
                      type="inner"
                      cover={
                        <img
                          alt="Phone Support"
                          src={PhoneSupport}
                          style={{
                            maxWidth: 150,
                            maxHeight: 150,
                            margin: "auto",
                            width: "50%",
                            padding: "10px",
                          }}
                        />
                      }
                    >
                      The Swimmingly hotline is available
                      <br />
                      to administrators for timely phone
                      <br />
                      calls while on the pool deck.
                      <br />
                      (866) 377-SWIM (7946)
                    </Card>
                  </Tooltip>
                </Col>
              </Row>
            )}
            <Row gutter={16}>
              <Col flex={2}>
                <Card
                  title={
                    <a
                      href="https://survey.hsforms.com/1c3AQf-6zSNSRoHrfJ0lmRQcapxb"
                      target="_blank"
                    >
                      Provide Feedback
                    </a>
                  }
                  bordered={true}
                  size="default"
                  type="inner"
                  cover={
                    <img
                      alt="Provide Feedback"
                      src={ProvideFeedback}
                      style={{
                        maxWidth: 150,
                        maxHeight: 150,
                        margin: "auto",
                        width: "50%",
                        padding: "10px",
                      }}
                    />
                  }
                >
                  Provide Feedback to
                  <br />
                  the Swimmingly Team.
                  <br />
                  Help us improve the platform!
                </Card>
              </Col>
            </Row>
            {role !== 5 && (
              <Row gutter={16}>
                <Col flex={2}>
                  <Card
                    title={
                      <a
                        href="https://www.facebook.com/groups/1127566450663770"
                        target="_blank"
                      >
                        Facebook Community
                      </a>
                    }
                    bordered={true}
                    size="default"
                    type="inner"
                    cover={
                      <img
                        alt="Join the Swimmingly Facebook Community"
                        src={FacebookCommunity}
                        style={{
                          maxWidth: 150,
                          maxHeight: 150,
                          margin: "auto",
                          width: "50%",
                          padding: "10px",
                        }}
                      />
                    }
                  >
                    Connect, learn, and share
                    <br />
                    with other users on the
                    <br />
                    <a
                      href="https://www.facebook.com/groups/1127566450663770"
                      target="_blank"
                    >
                      Swimmingly Facebook Community
                    </a>
                    !
                  </Card>
                </Col>
              </Row>
            )}
          </div>
        </TabPane>

        <TabPane
          tab={
            <span>
              <Badge 
                dot={hasNewRelease}
                color="#52c41a"
              >
                <NotificationOutlined />
                Updates
              </Badge>
            </span>
          }
          key="3"
        >
          <UserReleaseNotes />
        </TabPane>
      </Tabs>
    </Drawer>
  );
}
