import { useEffect, useState } from "react"
import AddUserLeague from "./AddUserLeague"
import AddUserClub from "./AddUserClub"
import NewClub from "./NewClub"
import NewLeague from "./NewLeague"
import { IClub, ILeague, ISwimmer, roleMapping, swimminglyApi } from "./utils"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../appData/types"
import { SearchOutlined } from "@ant-design/icons"
import { Button, Input, message, Steps, Tag } from "antd"
import { Link, useHistory, useLocation } from "react-router-dom"
import swimHeader from "../images/swimmingly_final_header.png"
import { gen } from "./utils"
import AddParent from "./AddParent"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons"
import styled from "styled-components"
import { screenSizes } from "../styles/GlobalStyles"
const { Step } = Steps

function FindKids({
  clubId,
  myKids,
  setMyKids,
}: {
  clubId: number
  myKids: number[]
  setMyKids: (ids: number[]) => void
}): JSX.Element {
  const [value, setValue] = useState("")
  const [allSwimmers, setAllSwimmers] = useState<ISwimmer[]>([])

  useEffect(() => {
    swimminglyApi
      .post(gen("/api/getAllClubsKidsPost"))
      .body({ clubId })
      .then((data) => {
        console.log(data.swimmerArr)
        setAllSwimmers(data.swimmerArr)
      })
  }, [clubId])

  const isMatch = (searchString: string, swimmerLastName: string) => {
    if (swimmerLastName && swimmerLastName.toLowerCase() === searchString) {
      return true
    }
    if (searchString.length < 3) {
      return false
    }
    return (
      swimmerLastName && swimmerLastName.toLowerCase().includes(searchString)
    )
  }

  const onClickSwimmer = (swimmer: ISwimmer) => {
    const newMyKids = [...myKids]
    if (!myKids.includes(swimmer.swimmerId)) {
      newMyKids.push(swimmer.swimmerId)
      setMyKids(newMyKids)
    }
  }

  const getName = (id: number) => {
    let elements = allSwimmers.filter((el) => el.swimmerId === id)
    let first = elements[0]
    if (first) {
      return first.firstName + " " + first.lastName
    }
    return "No name"
  }

  return (
    <div className="form-group mb-lg">
      {myKids.length > 0 && (
        <div style={{ marginBottom: "10px", marginLeft: "10px" }}>
          <div style={{ marginBottom: "10px", fontSize: "16px" }}>My Kids</div>
          {myKids.map((el, i) => (
            <Tag
              key={el}
              closable
              onClose={() => {
                let newMyKids = [...myKids]
                newMyKids.splice(i, 1)
                setMyKids(newMyKids)
              }}
            >
              {getName(el)}
            </Tag>
          ))}
        </div>
      )}

      <label>Type in the LAST name of your kids</label>
      <Input
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        suffix={<SearchOutlined />}
        size="large"
      />
      <br />
      {allSwimmers
        .filter((theSwimmer) =>
          isMatch(value.toLowerCase(), theSwimmer.lastName),
        )
        .map((el) => (
          <Button
            key={el.swimmerId}
            type="primary"
            style={{
              height: "50px",
              fontSize: "20px",
              width: "100%",
              marginBottom: "3px",
            }}
            onClick={() => onClickSwimmer(el)}
          >
            {`${el.firstName} ${el.lastName}`}
          </Button>
        ))}
    </div>
  )
}

function FindClub({
  dontShow,
  onClickNoClub,
  onClickClub,
}: {
  dontShow: boolean
  onClickNoClub: () => void
  onClickClub: (theClub: IClub) => void
}) {
  const [value, setValue] = useState("")
  const [allClubs, setAllClubs] = useState<IClub[]>([])

  useEffect(() => {
    swimminglyApi
      .post(gen("/api/getAllClubsPost"))
      .body({})
      .then((data) => {
        setAllClubs(data.clubsArr)
      })
  }, [])

  const isMatch = (searchString: string, clubName: string) => {
    if (searchString.length < 3) {
      return false
    }
    return clubName && clubName.toLowerCase().includes(searchString)
  }

  return (
    <div className="form-group mb-lg">
      <label>Type in the FULL name of your Club</label>
      <Input
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        suffix={<SearchOutlined />}
        size="large"
      />
      <br />
      {value.length > 3 && !dontShow && (
        <Button
          type="primary"
          style={{
            height: "50px",
            fontSize: "20px",
            width: "100%",
            marginBottom: "3px",
          }}
          onClick={onClickNoClub}
        >
          Can't find my club
        </Button>
      )}
      {allClubs
        .filter((el) => isMatch(value.toLowerCase(), el.club_name))
        .map((el) => (
          <Button
            key={el.clubId}
            type="primary"
            style={{
              height: "50px",
              fontSize: "20px",
              width: "100%",
              marginBottom: "3px",
            }}
            onClick={() => onClickClub(el)}
          >
            {el.club_name} &nbsp; | &nbsp; {el.league_name}
          </Button>
        ))}
    </div>
  )
}

function FindLeague({
  onClickNoLeague,
  onClickLeague,
}: {
  onClickNoLeague: () => void
  onClickLeague: (theLeague: ILeague) => void
}): JSX.Element {
  const [value, setValue] = useState("")
  const [allLeagues, setAllLeagues] = useState<ILeague[]>([])

  useEffect(() => {
    swimminglyApi.get(gen("/api/getAllLeagues")).then((data) => {
      setAllLeagues(data.leaguesArr)
    })
  }, [])

  const isMatch = (searchString: string, leagueName: string) => {
    if (searchString.length < 3) {
      return false
    }
    return leagueName && leagueName.toLowerCase().includes(searchString)
  }

  return (
    <div className="form-group mb-lg">
      <label>Type in the FULL name of your League</label>
      <Input
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        suffix={<SearchOutlined />}
        size="large"
      />
      <br />
      {value.length > 3 && (
        <Button
          type="primary"
          style={{
            height: "50px",
            fontSize: "20px",
            width: "100%",
            marginBottom: "3px",
          }}
          onClick={() => onClickNoLeague()}
        >
          Can't find my league
        </Button>
      )}
      {allLeagues
        .filter((theLeague) => isMatch(value.toLowerCase(), theLeague.name))
        .map((el) => (
          <Button
            key={el.id}
            type="primary"
            style={{
              height: "50px",
              fontSize: "20px",
              width: "100%",
              marginBottom: "3px",
            }}
            onClick={() => onClickLeague(el)}
          >
            {el.name} | {el.City ? el.City + "," : ""} {el.StateID}
          </Button>
        ))}
    </div>
  )
}

const StepsDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  @media (max-width: ${screenSizes.medium}px) {
    display: none;
  }
`

const StyledButton = styled(Button)`
  margin: 0;
  height: 50px;
  font-size: 22px;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 3px;
  padding-bottom: 3px;

  @media (max-width: ${screenSizes.small}px) {
    justify-content: center;
    justify-self: center;
  }
`

const StyledButtonDisplay = styled.div`
  display: grid;
  margin: auto;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 5px;
  column-gap: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  @media (max-width: ${screenSizes.small}px) {
    grid-template-columns: 1fr;
  }
`

interface TokenLocationState {
  clubToken?: string | null
}
 
export default function ClubLeagueLogin({
  currentStep,
  initialEmail,
  initialLeagueId,
  initialClubId,
  active,
}: {
  currentStep: string | null
  initialEmail: string | null
  initialLeagueId: number | null
  initialClubId: number | null
  active: boolean
}): JSX.Element {
  const acceptedCookies = useStoreState(
    (state: StateMapper<AppDataStore>) => state.acceptedCookies,
  )
  const [curStep, setCurStep] = useState(currentStep ? currentStep : "1a")
  const [email, setEmail] = useState(initialEmail ? initialEmail : "")
  const [myKids, setMyKids] = useState<number[]>([])
  const [clubId, setClubId] = useState<number | null>(initialClubId || null)
  const [clubName, setClubName] = useState("")
  const [leagueId, setLeagueId] = useState<number | null>(
    initialLeagueId ? initialLeagueId : null,
  )
  const [leagueName, setLeagueName] = useState("")
  const [userRole, setUserRole] = useState<number | null>(null)
  const location = useLocation<TokenLocationState>();
  const clubToken = location?.state?.clubToken

  const history = useHistory()

  useEffect(() => {
    if (!acceptedCookies && history) {
      message.warn("You must accept cookies to interact with our site.")
      history.push("/login")
    }
  }, [history, acceptedCookies])

  const onClickLeague = (league: ILeague) => {
    setCurStep("3a")
    setLeagueId(league.id)
    setLeagueName(league.name)
  }

  const onClickClub = (club: IClub) => {
    setCurStep("3b")
    setClubId(club?.clubId || null)
    setClubName(club?.nameLong || "")
    setLeagueName(club?.leagueName || "")
  }

  const onClickClubParent = (club: IClub) => {
    setCurStep("2i")
    setClubId(club.clubId || null)
    setClubName(club.club_name)
    setLeagueName(club.league_name)
  }

  const onClickParentNext = () => {
    setCurStep("3c")
  }

  const checkIfEmailExists = () => {
    if (!email) {
      setCurStep("1b")
    }
    swimminglyApi
      .post(gen("/api/checkIfEmailExistsPost"))
      .body({ email })
      .then((data) => {
        if (data.exists) {
          if (data.role === 6) {
            setCurStep("2a")
          } else {
            setCurStep("1c")
            setUserRole(data.role)
          }
        } else {
          setCurStep("2a")
        }
      })
  }

  const renderInner = () => {
    if (curStep === "1a") {
      return (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "24px",
              marginTop: "20px",
            }}
          >
            Let's get started
          </div>
          <p
            style={{
              // display: "flex",
              // justifyContent: "center",
              textAlign: "center",
              fontSize: "16px",
              marginTop: "10px",
            }}
          >
            Enter your&nbsp; <b style={{ color: "black" }}>email address</b>{" "}
            &nbsp;to check if it's already tagged to an account in the
            Swimmingly Clubhouse.
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingRight: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <label style={{ marginTop: "5px", marginRight: "10px" }}>
                Email:
              </label>
              <Input
                style={{ width: "200px" }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    checkIfEmailExists()
                  }
                }}
              />
            </div>
          </div>
        </div>
      )
    }
    if (curStep === "1b") {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginTop: "30px",
              width: "80%",
              marginLeft: "70px",
              marginRight: "70px",
              fontSize: "15px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              Your email is already tagged to an account in the Swimmingly
              Clubhouse.
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              Please login here or reset your password here.
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              Contact us if you have any questions
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <a href="mailto:team@swimmingly.app">team@swimmingly.app</a>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              866-377-SWIM(7946)
            </div>
          </div>
        </div>
      )
    }
    if (curStep === "1c") {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginTop: "30px",
              width: "80%",
              marginLeft: "70px",
              marginRight: "70px",
              fontSize: "15px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              Your email is already tagged to an{" "}
              {userRole ? roleMapping[userRole] : " user "}
              account in the Swimmingly Clubhouse.
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              Please login&nbsp;<Link to="/">here</Link>&nbsp;or reset your
              password&nbsp;
              <Link to="/passwordReset">here</Link>&nbsp;.
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              To change the permission level on your account, please contact
              your club or league administrator.
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              Contact us if you have any questions
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <a href="mailto:team@swimmingly.app">team@swimmingly.app</a>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              866-377-SWIM(7946)
            </div>
          </div>
        </div>
      )
    }
    if (curStep === "2a") {
      return (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginTop: "30px",
                fontSize: "16px",
                width: "70%",
                fontWeight: 600,
              }}
            >
              Select your role. If you oversee more than one team in your
              league, select League Admin. If you only oversee your team, select
              Club Admin. If you are a parent select parent.
            </div>
          </div>
          <StyledButtonDisplay>
            <StyledButton type="primary" onClick={() => setCurStep("2b")}>
              I am a League Admin
            </StyledButton>
            <StyledButton type="primary" onClick={() => setCurStep("2c")}>
              I am a Club Admin
            </StyledButton>
            <StyledButton
              type="primary"
              onClick={() => {
                history.push("/registerParent", { email, clubToken })
                return
              }}
            >
              I am a Parent
            </StyledButton>
          </StyledButtonDisplay>
        </div>
      )
    }
    if (curStep === "2b") {
      return (
        <div style={{ marginTop: "20px" }}>
          <FindLeague
            onClickLeague={onClickLeague}
            onClickNoLeague={() => setCurStep("2g")}
          />
        </div>
      )
    }
    if (curStep === "2c") {
      return (
        <div style={{ marginTop: "20px" }}>
          <FindClub
            dontShow={false}
            onClickClub={onClickClub}
            onClickNoClub={() => setCurStep("2f")}
          />
        </div>
      )
    }
    if (curStep === "2d") {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginTop: "30px",
              width: "80%",
              marginLeft: "70px",
              marginRight: "70px",
              fontSize: "15px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
              }}
            >
              It seems you can't find your league. Don't fret. Contact the
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              Swimmingly team with your league name, team names, and your email
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              and phone number and we will get you activated today.
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <a href="mailto:team@swimmingly.app">team@swimmingly.app</a>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              866-377-SWIM(7946)
            </div>
          </div>
        </div>
      )
    }
    if (curStep === "2e") {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginTop: "30px",
              width: "80%",
              marginLeft: "70px",
              marginRight: "70px",
              fontSize: "15px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
              }}
            >
              It seems you can't find your club. Don't fret. Contact the
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              Swimmingly team with your league name, team name, and your email
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              and phone number and we will get you activated today.
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <a href="mailto:team@swimmingly.app">team@swimmingly.app</a>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              866-377-SWIM(7946)
            </div>
          </div>
        </div>
      )
    }
    if (curStep === "2f") {
      return (
        <div style={{ marginTop: "20px" }}>
          <NewClub callAfterSuccess={() => setCurStep("4a")} />
        </div>
      )
    }
    if (curStep === "2g") {
      return (
        <div style={{ marginTop: "20px" }}>
          <NewLeague callAfterSuccess={() => setCurStep("4a")} />
        </div>
      )
    }
    if (curStep === "2h") {
      return (
        <div style={{ marginTop: "20px" }}>
          <FindClub
            onClickClub={onClickClubParent}
            onClickNoClub={() => {}}
            dontShow={true}
          />
        </div>
      )
    }
    if (curStep === "2i") {
      return (
        <div style={{ marginTop: "20px" }}>
          <FindKids
            clubId={clubId || -1}
            myKids={myKids}
            setMyKids={(arr) => setMyKids(arr)}
          />
        </div>
      )
    }
    if (curStep === "3a") {
      return (
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <AddUserLeague
            isOutside={true}
            leagueId={leagueId}
            leagueName={leagueName}
            callAfterSuccess={() => {
              message.success(
                "You created a league user. Now login. Some parts of the site will be disabled until you are activated by your team members.",
              )
              history.push("/")
            }}
            email={email}
            active={active}
          />
        </div>
      )
    }
    if (curStep === "3b") {
      return (
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <AddUserClub
            isOutside={true}
            clubId={clubId}
            clubName={clubName}
            leagueName={leagueName}
            callAfterSuccess={() => {
              message.success(
                "You created a club user. Now login. Some parts of the site will be disabled until you are activated by your team members.",
              )
              history.push("/")
            }}
            email={email}
            active={active}
          />
        </div>
      )
    }
    if (curStep === "3c") {
      return (
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <AddParent
            email={email}
            myKids={myKids}
            clubId={clubId}
            callAfterSuccess={() => {
              message.success(
                "You created a parent account. Now login. Some parts of the site will be disabled until you are activated by your club admins.",
              )
              history.push("/")
            }}
          />
        </div>
      )
    }
  }

  const getNextFunction = () => {
    if (curStep === "1a") {
      return checkIfEmailExists
    } else if (curStep === "2i") {
      return () => onClickParentNext()
    } else {
      return () => setCurStep(parseInt(curStep) - 1 + "a")
    }
  }

  const getPreviousFunction = () => {
    return () => setCurStep(parseInt(curStep) - 1 + "a")
  }

  const isVisibleNext = () => {
    if (curStep === "1a" || curStep === "2i") {
      return true
    }

    return false
  }

  const isVisiblePrevious = () => {
    let step = parseInt(curStep)

    if (step === 1) {
      return false
    }

    return true
  }

  const isDisabledNext = () => {
    if (curStep === "1a") {
      return !(email.length > 0)
    }
    if (curStep === "2i") {
      return myKids.length === 0
    }

    return false
  }

  const renderButtons = () => {
    let isVisibleNextVal = isVisibleNext()
    let isVisiblePreviousVal = isVisiblePrevious()
    let isDisabledNextVal = isDisabledNext()
    return (
      <div
        style={{
          marginTop: "50px",
          paddingTop: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingRight: "20px",
          paddingLeft: "20px",
          paddingBottom: "10px",
        }}
      >
        <Button
          style={!isVisiblePreviousVal ? { visibility: "hidden" } : {}}
          type="default"
          onClick={getPreviousFunction()}
        >
          Prev
        </Button>
        <div>
          <Button
            style={!isVisibleNextVal ? { visibility: "hidden" } : {}}
            disabled={isDisabledNextVal}
            type="primary"
            onClick={getNextFunction()}
          >
            Next
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div>
      <section
        className="panel"
        style={{
          marginTop: "30px",
          width: "80%",
          marginLeft: "auto",
          marginRight: "auto",
          backgroundColor: "white",
        }}
      >
        <header
          className="panel-heading"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div>
            <img
              src={swimHeader}
              width="150px"
              style={{ marginRight: "20px" }}
              alt="Swimmingly Header"
            />
          </div>
          <div>
            <h2 className="panel-title">
              Create Your Account or{" "}
              <Link to="/" style={{ color: "#0000ee" }}>
                Login here
              </Link>
            </h2>
          </div>
        </header>
        <StepsDiv className="ourpanel-body">
          <Steps current={parseInt(curStep) - 1}>
            <Step title="Check" description="Check your account" />
            <Step
              title="Select"
              description="Select league, club or parent user"
            />
            <Step title="Sign Up" description="Finish your sign up" />
          </Steps>
        </StepsDiv>
        {renderInner()}
        <div style={{ marginTop: "10px" }} />
        {renderButtons()}
      </section>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          color: "black",
          fontSize: "16px",
        }}
      >
        Contact
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FontAwesomeIcon
          icon={faEnvelope}
          style={{ color: "grey", marginRight: "10px" }}
        />
        <a href="mailto:team@swimmingly.app">team@swimmingly.app</a>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FontAwesomeIcon
          icon={faPhone}
          style={{ color: "grey", marginRight: "10px" }}
        />
        <div style={{ display: "flex", justifyContent: "center" }}>
          866.377.7946
        </div>
      </div>
    </div>
  )
}
