import React from "react"
import { Spin } from "antd"

export default function CenterSpin() {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Spin size="large" />
    </div>
  )
}
