import moment from "moment-timezone"
import { Fragment, FunctionComponent, useEffect } from "react"
import { FeeHelper } from "../ClubSeasonManagement/Fees"
import { IRegistrationClubDue, useClubSeasonManagementContext } from "../ClubSeasonManagement/sharedState"
import { formatMoney, IClubSeasonInfo } from "../utils"

export interface ISeasonPricingProps {
  selectedClubSeason: IClubSeasonInfo
  pricePoints: IRegistrationClubDue[]
}


export const SeasonPricing: FunctionComponent<ISeasonPricingProps> = ({ selectedClubSeason, pricePoints} ) => {

  const swimminglyMembershipFees = new FeeHelper().swimminglyMembershipFees(selectedClubSeason, true)

  return (
    <Fragment>
      <h4>Season Pricing</h4>
      <div
        style={{
          border: "2px solid lightgrey",
          borderRadius: "5px",
          padding: "5px",
          minHeight: "45px",
          marginTop: "5px",
        }}
      >
        <p>
          Below is the schedule of dues for your club. Please note that there is
          an {swimminglyMembershipFees} Swimmingly membership fee per swimmer for the season, which
          includes the parent portal, access to your swimmer's athlete profile,
          (meet results, best times, and time progressions) and access to
          SwimminglyFan. Also included in the Swimmingly membership is all team
          and meet management for your club and usage of the Swimmingly App
          timing system for your club's swim meets.
        </p>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 50px 1fr",
            width: "60%",
            margin: "auto",
          }}
        >
          <div style={{ borderRadius: "5px", border: "1px solid lightgrey" }}>
            {
              pricePoints.map((pricePoint, index) => {
                const isFirst = index === 0
                const isLast = index === pricePoints.length - 1
                const swimmerDesignation = (() => {
                  if (isFirst && isLast) {
                    return " each "
                  } else if (isLast) {
                    return " each additional "
                  } else {
                    return moment.localeData().ordinal(pricePoint.numSwimmer)
                  }
                })()
                return (
                  <div>
                    {formatMoney(pricePoint.price)} for { swimmerDesignation } swimmer.
                  </div>
                )
              })
            }
          </div>
          <span style={{ textAlign: "center", alignSelf: "center" }}>+</span>
          <div
            style={{
              borderRadius: "5px",
              border: "1px solid lightgrey",
              margin: "auto",
            }}
          >
            {swimminglyMembershipFees} per swimmer
          </div>
        </div>
      </div>
    </Fragment>
  )
}
