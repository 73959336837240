import { Input } from "antd"
import { FunctionComponent, ReactNode, useEffect, useState } from "react"
import { GuardianWaiverResponse } from "../ClubSeasonManagement/RegistrationConfiguration/RegistrationHelper"
import {
  IRegistrationWaiverSection
} from "../ClubSeasonManagement/RegistrationConfiguration/RegistrationWaiverSubsection"
import { GuardianWaiverMonitor } from "../Helpers/GuardianWaiverMonitor"
import { ParagraphedText } from "../ui"
import { useRegistrationFormContext } from "./sharedState"
import { StyledWaiver } from "./styles"





type TSingleWaiverSectionProps = {
  section: IRegistrationWaiverSection
  waiverResponses?: Record<string,GuardianWaiverResponse>
  waiverMonitor?: GuardianWaiverMonitor
}

const { TextArea } = Input



export const SingleWaiverSection: FunctionComponent<TSingleWaiverSectionProps> =
  (  {section, waiverResponses, waiverMonitor} ) => {
    const [freeformResponse, setFreeformResponse] = useState("")
    const [guardianAcknowledged, setGuardianAcknowledged] = useState(false)
    const { previewMode } = useRegistrationFormContext()
 
  useEffect(() => {
    if (waiverResponses !== undefined) {
      const waiverResponse = waiverResponses[`${section.waiverSectionId}`]
      setFreeformResponse(waiverResponse?.freeformResponse ?? "")
      setGuardianAcknowledged(waiverResponse?.guardianAcknowledged ?? false)
    }
  }, [section.waiverSectionId, waiverResponses])

    const paragraphs = section.wording
    .split(/(\r?\n|\r)\d*(\r?\n|\r)/g)
    .filter((paragraph) => paragraph.trim() !== "")

    return (
      <StyledWaiver >
        <h6 >{section.sectionTitle}</h6>
        <p>
          <ParagraphedText idPrefix={`p_${section.waiverSectionId}`} linkify={true}>
            {section.wording}
          </ParagraphedText>
        </p>
        {section.acknowledgmentRequirement === "text input" && (
          <TextArea
            disabled={previewMode}
            value={freeformResponse}
            onChange={(e) => {
              const response = e.target.value
              setFreeformResponse(response)
              waiverMonitor?.addResponse(`${section.waiverSectionId}`, undefined, response)
            }}
            autoSize={{ minRows: 3, maxRows: 10 }}
          />
        )}
        {section.acknowledgmentRequirement === "mandatory" && (
          <label>
            <input
              type="checkbox"
              checked={guardianAcknowledged}
              onChange={(e) => {
                  setGuardianAcknowledged(e.target.checked)
                waiverMonitor?.addResponse(`${section.waiverSectionId}`, e.target.checked, undefined)
              }}
              disabled={previewMode}
            />{" "}
            I agree
          </label>
        )}
      </StyledWaiver>
    )
    
  }
