import React from "react"
import moment from "moment-timezone"
import { ageString, dateString, raceTypeArr, convertCourse, gen, swimminglyApi } from "./utils"
import { DatePicker, Button, Table, Row, Col, Input, Select, Radio } from "antd"
const { RangePicker } = DatePicker
const { Option } = Select

export default class ClubCard extends React.Component {
  constructor(props) {
    super(props)

    let rightNow = moment()
    // start date auto set to may of the last summer swim season
    let start = moment(rightNow.format("YYYY") + "-05-16", "YYYY-MM-DD")
    if (parseInt(rightNow.format("M"), 10) < 6) {
      start = start.subtract(1, "years")
    }

    this.state = {
      distance: 50,
      stroke: 1,
      course: 0,
      ageStart: 1,
      ageEnd: 18,
      gender: "all",
      resultsNumb: "10",
      startDate: start,
      endDate: moment(),
      swimmerArr: [],
      loaded: false,
      loading: false,
    }
  }

  handleOptionChange = (changeEvent) => {
    this.setState({
      gender: changeEvent.target.value,
      loaded: false,
    })
  }

  onClick = () => {
    this.setState({ loading: true })

    let { ageStart, ageEnd } = this.state

    if (!ageStart) {
      ageStart = "null"
    }
    if (!ageEnd) {
      ageEnd = "null"
    }

    let startDate = this.state.startDate
      ? this.state.startDate.format("YYYY-MM-DD")
      : "null"
    let endDate = this.state.endDate
      ? this.state.endDate.format("YYYY-MM-DD")
      : "null"

    const data = {
      ...this.state,
      startDate,
      endDate,
      ageStart,
      ageEnd,
      clubId: this.props.club.clubId,
    }

    swimminglyApi
      .post(gen("/api/ClubCardPost"))
      .body(data)
      .then((returnData) =>{
        this.setState({
        swimmerArr: returnData.swimmerArr,
         loaded: true,
         loading: false,
        })
      })
  }
  render() {
    const radioStyle = {
      display: "block",
      height: "30px",
      lineHeight: "30px",
    }
    return (
      <div>
        <Row>
          <Col span={4}>Distance</Col>
          <Col span={6}>
            <Select
              style={{ width: "150px" }}
              value={this.state.distance}
              onChange={(val) =>
                this.setState({ distance: val, loaded: false })
              }
            >
              <Option value={12}>12</Option>
              <Option value={15}>15</Option>
              <Option value={25}>25</Option>
              <Option value={50}>50</Option>
              <Option value={75}>75</Option>
              <Option value={100}>100</Option>
              <Option value={125}>125</Option>
              <Option value={150}>150</Option>
              <Option value={175}>175</Option>
              <Option value={200}>200</Option>
              <Option value={400}>400</Option>
              <Option value={500}>500</Option>
              <Option value={800}>800</Option>
              <Option value={1000}>1000</Option>
              <Option value={1500}>1500</Option>
              <Option value={1650}>1650</Option>
            </Select>
          </Col>
          <Col span={4}>Stroke</Col>
          <Col span={6}>
            <Select
              style={{ width: "230px" }}
              value={this.state.stroke}
              onChange={(val) => this.setState({ stroke: val, loaded: false })}
            >
              <Option value={5}>Freestyle Relay</Option>
              <Option value={3}>Breaststroke</Option>
              <Option value={0}>Medley Relay</Option>
              <Option value={6}>Individual Medley</Option>
              <Option value={4}>Butterfly</Option>
              <Option value={2}>Backstroke</Option>
              <Option value={1}>Freestyle</Option>
              <Option value={7}>Butterfly Relay</Option>
              <Option value={8}>Backstroke Relay</Option>
              <Option value={9}>Breaststroke Relay</Option>
              <Option value={10}>Fun</Option>
            </Select>
          </Col>
        </Row>

        <Row>
          <Col span={4} style={{ marginTop: "20px" }}>
            Course
          </Col>
          <Col span={6}>
            <Select
              style={{ marginTop: "15px", width: "150px" }}
              value={this.state.course}
              onChange={(val) => this.setState({ course: val, loaded: false })}
            >
              <Option value={0}>SCY</Option>
              <Option value={1}>SCM</Option>
              <Option value={2}>LCM</Option>
            </Select>
          </Col>
          <Col span={4} style={{ marginTop: "25px" }}>
            Date Range
          </Col>
          <Col span={6}>
            <RangePicker
              style={{ marginTop: "15px" }}
              value={[this.state.startDate, this.state.endDate]}
              onChange={(dates) =>
                this.setState({
                  startDate: dates[0],
                  endDate: dates[1],
                  loaded: false,
                })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={4} style={{ marginTop: "20px" }}>
            Ages
          </Col>
          <Col span={4}>
            <Input
              style={{ marginTop: "15px" }}
              type="number"
              name="startAge"
              value={this.state.ageStart}
              onChange={(e) =>
                this.setState({ ageStart: e.target.value, loaded: false })
              }
            />
          </Col>
          <Col span={1} style={{ textAlign: "center", paddingTop: "20px" }}>
            to
          </Col>
          <Col span={4}>
            <Input
              style={{ marginTop: "15px" }}
              type="number"
              name="endAge"
              value={this.state.ageEnd}
              onChange={(e) =>
                this.setState({ ageEnd: e.target.value, loaded: false })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={3}>
            <Radio.Group
              onChange={this.handleOptionChange}
              value={this.state.gender}
            >
              <Radio style={radioStyle} value={"male"}>
                Male
              </Radio>
              <Radio style={radioStyle} value={"female"}>
                Female
              </Radio>
              <Radio style={radioStyle} value={"all"}>
                All
              </Radio>
            </Radio.Group>
          </Col>
        </Row>
        <div>
          <div>
            <Row>
              <Col span={4} style={{ marginTop: "20px" }}>
                #&nbsp;of&nbsp;Results
              </Col>
              <Col span={4}>
                <Select
                  style={{ marginTop: "10px", width: "150px" }}
                  value={this.state.resultsNumb}
                  onChange={(val) =>
                    this.setState({
                      resultsNumb: val,
                      loaded: false,
                    })
                  }
                >
                  <Option value={10}>10</Option>
                  <Option value={25}>25</Option>
                  <Option value={100}>100</Option>
                </Select>
              </Col>
            </Row>
          </div>
          <div style={{ display: "flex" }}>
            <Button
              style={{ marginTop: "15px" }}
              type="primary"
              onClick={this.onClick}
              loading={this.state.loading}
            >
              Search Times
            </Button>
          </div>
          {this.state.loaded && (
            <ClubTable
              swimmerArr={this.state.swimmerArr}
              distance={this.state.distance}
              course={this.state.course}
              stroke={this.state.stroke}
              ageStart={this.state.ageStart}
              ageEnd={this.state.ageEnd}
            />
          )}
        </div>
      </div>
    )
  }
}

class ClubTable extends React.Component {
  render() {
    let { ageStart, ageEnd, distance, startDate, endDate, course, stroke } =
      this.props
    let courseString = convertCourse[course]
    let strokeString = raceTypeArr[stroke]
    return (
      <div style={{ backgroundColor: "#F6F6F6" }} className="ourpanel-body">
        <div
          style={{ fontSize: "16px", lineHeight: "40px", fontWeight: "bold" }}
        >
          {`${ageString(
            ageStart,
            ageEnd,
          )} | ${distance} ${strokeString} ${courseString} | ${dateString(
            startDate,
            endDate,
          )}`}
          {/*<span
            className="fa fa-file-excel-o"
            style={{ marginLeft: "40px", cursor: "pointer" }}
            onclick="downloadTable('datatable-default', ['Name', 'Time', 'Course', 'Date', Meet'],[{'id': 'raceDistance', 'name' : 'distance'},
                      {'id': 'raceStroke', 'name' : 'stroke'},
                      ])"
          />*/}
        </div>

        <Table
          rowKey={(record) => record.rank}
          dataSource={this.props.swimmerArr}
          columns={[
            { title: "Name", dataIndex: "name", key: "name" },
            { title: "Time", dataIndex: "time", key: "time" },
            { title: "Course", render: () => courseString, key: "course" },
            { title: "Date", dataIndex: "date", key: "date" },
            { title: "Meet", dataIndex: "meet", key: "meet" },
          ]}
        />
      </div>
    )
  }
}
