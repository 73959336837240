import { FunctionComponent, useEffect, useRef, useState } from "react"
import {
  CheckCircleTwoTone,
  DownOutlined,
  ExclamationCircleTwoTone,
  RightOutlined,
} from "@ant-design/icons"
import { Tooltip } from "antd"
import { SingleWaiverSection } from "./SingleWaiverSection"
import { IWaiver } from "../ClubSeasonManagement/sharedState"
import { StyledSingleWaiverSection } from "./styles"
import { IRegistrationWaiverSection } from "../ClubSeasonManagement/RegistrationConfiguration/RegistrationWaiverSubsection"
import { GuardianWaiverResponse } from "../ClubSeasonManagement/RegistrationConfiguration/RegistrationHelper"
import { GuardianWaiverMonitor } from "../Helpers/GuardianWaiverMonitor"
import { ParagraphedText } from "../ui"

type TSingleWaiverProps = {
  waiver: IWaiver
  waiverResponses?: Record<string, GuardianWaiverResponse>
  waiverMonitor?: GuardianWaiverMonitor
  setExpandedWaiverId: (value?: number) => void
  expandedWaiverId?: number
}

export const SingleWaiver: FunctionComponent<TSingleWaiverProps> = ({ waiver, waiverResponses, waiverMonitor, expandedWaiverId, setExpandedWaiverId }) => {
  const expanded = waiver.waiverId === expandedWaiverId
  const [completed, setCompleted] = useState(false)
  const waiverRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (waiver.waiverId === expandedWaiverId && waiverRef.current) {
      waiverRef.current.scrollIntoView({
        block: "start",
        behavior: "smooth",
      })
    }
  }, [waiver.waiverId, expandedWaiverId]);

  useEffect(() => {
    if (waiverResponses) {
      const requiredSections = waiver.sections?.filter((waiverSection) => waiverSection.acknowledgmentRequirement === "mandatory")
      const uncompletedRequiredSections = requiredSections?.filter((waiverSection) => {
        const waiverResponse = waiverResponses[`${waiverSection.waiverSectionId}`]
        const sectionCompleted = waiverResponse && Boolean(waiverResponse.guardianAcknowledged)
        return !sectionCompleted
      }) ?? []  
      setCompleted(uncompletedRequiredSections.length === 0)
    }
      


  }, [waiverResponses]);

  const toggleSingleWaiver = () => {
    setExpandedWaiverId(expandedWaiverId === waiver.waiverId ? undefined : waiver.waiverId)
  }
  const completenessTitle = completed ? "Section complete" : "Section incomplete"
  const expandednessTitle = expanded ? "Tap to collapse" : "Tap to expand"

  return (
    <div
      style={{
        border: "2px solid lightgrey",
        borderRadius: "5px",
        padding: "5px",
        minHeight: "45px",
        marginTop: "5px",
      }}
    >
      <h5 onClick={toggleSingleWaiver}>
        <span ref={waiverRef} id={`${waiver.waiverId}`}>{waiver.waiverName}{" "} </span>
        <Tooltip title={expandednessTitle}>
          {expanded ? (
            <DownOutlined style={{ cursor: "pointer" }} />
          ) : (
            <RightOutlined style={{ cursor: "pointer" }} />
          )}
        </Tooltip>

        <Tooltip title={completenessTitle}>
          {completed ? (
            <CheckCircleTwoTone
              twoToneColor="#52c41a"
              style={{ marginLeft: "7px" }}
            />
          ) : (
            <ExclamationCircleTwoTone
              twoToneColor="#ffe66b"
              style={{ marginLeft: "7px" }}
            />
          )}
        </Tooltip>


      </h5>
      {expanded ? (
        <div >
          <div
            style={{
              paddingLeft: "3px",
              paddingRight: "3px",
              lineHeight: "1em",
            }}
          >
          <ParagraphedText idPrefix={`p_waiver_${waiver.waiverId}`} linkify={true}>
              {waiver.waiverDescription}
          </ParagraphedText>
          </div>

          <StyledSingleWaiverSection>
            {
              waiver.sections?.map((section: IRegistrationWaiverSection, key: number) => {

                return (
                  <SingleWaiverSection waiverResponses={waiverResponses} waiverMonitor={waiverMonitor} section={section} key={key} />
                )

              })
            }
          </StyledSingleWaiverSection>
        </div>
      ) : null}
    </div>
  )
}