import { Modal } from "antd"
import {
  formatTimeWithoutLeadingZeros,
  ISwimmerWithNumericGender,
} from "../utils"

interface IBestRelayModalProps {
  bestRelays: { [key: string]: { swimmer_name: string; time: number } }[]
  // meetSwimmersArr: {
  //   age: number
  //   first_name: string
  //   gender: 1 | 2
  //   id: number
  //   last_name: string
  //   middle_initials: string | null
  //   swimmer_best_time: number | null
  //   swimmer_number: number
  //   unit: 0 | 1 | 2
  // }[]
  availableSwimmers: {
    attendingMeet: boolean | null
    swimmer: ISwimmerWithNumericGender
    meetAge: number
    individualEntries: number
    relayEntries: number
    seedTime: number | null
    previousBest: number | null
    converted: boolean
  }[]
  relayDisplayName: string
  bestRelayModalVisible: boolean
  relayStrokeType: string
  bestRelaysSeedTime: number | null
  handleBestRelayOk: () => void
  handleBestRelayCancel: () => void
}

export default function BestRelayModal({
  bestRelays,
  availableSwimmers,
  relayDisplayName,
  bestRelayModalVisible,
  relayStrokeType,
  bestRelaysSeedTime,
  handleBestRelayOk,
  handleBestRelayCancel,
}: IBestRelayModalProps): JSX.Element {
  if (bestRelays.length === 0) {
    let message
    if (!availableSwimmers?.length || availableSwimmers?.length < 4) {
      message = <p>You don't have enough swimmers selected to make a relay!</p>
    } else {
      message = (
        <p>
          Even though you have {availableSwimmers.length || 0} swimmers
          selected, you don't have enough swimmers with valid seed times to make
          a {relayDisplayName}.
        </p>
      )
    }
    return (
      <Modal
        title={<h3 style={{ color: "#ff9505" }}>No Relay Found</h3>}
        visible={bestRelayModalVisible}
        onOk={handleBestRelayOk}
        onCancel={handleBestRelayCancel}
      >
        {message}
      </Modal>
    )
  } else {
    let relayConfigs = []
    if (/medley/.test(relayDisplayName.toLowerCase())) {
      relayConfigs = bestRelays.map((relay, idx) => {
        return (
          <li key={`relay ${idx}`}>
            <p>
              <b>Backstroke:</b> {relay.back?.swimmer_name} -{" "}
              {formatTimeWithoutLeadingZeros(relay.back?.time)}
              <br />
              <b>Breaststroke:</b> {relay.breast?.swimmer_name} -{" "}
              {formatTimeWithoutLeadingZeros(relay.breast?.time)}
              <br />
              <b>Butterfly:</b> {relay.fly?.swimmer_name} -{" "}
              {formatTimeWithoutLeadingZeros(relay.fly?.time)}
              <br />
              <b>Freestyle:</b> {relay.free?.swimmer_name} -{" "}
              {formatTimeWithoutLeadingZeros(relay.free?.time)}
            </p>
          </li>
        )
      })
    } else {
      relayConfigs = bestRelays.map((relay, idx) => {
        return (
          <li key={`relay ${idx}`}>
            <p>
              {relay[relayStrokeType + "1"]?.swimmer_name} -{" "}
              {formatTimeWithoutLeadingZeros(
                relay[relayStrokeType + "1"]?.time,
              )}
              ,
              <br />
              {relay[relayStrokeType + "2"]?.swimmer_name} -{" "}
              {formatTimeWithoutLeadingZeros(
                relay[relayStrokeType + "2"]?.time,
              )}
              ,
              <br />
              {relay[relayStrokeType + "3"]?.swimmer_name} -{" "}
              {formatTimeWithoutLeadingZeros(
                relay[relayStrokeType + "3"]?.time,
              )}
              ,
              <br />
              {relay[relayStrokeType + "4"]?.swimmer_name} -{" "}
              {formatTimeWithoutLeadingZeros(
                relay[relayStrokeType + "4"]?.time,
              )}
              ,
            </p>
          </li>
        )
      })
    }
    return (
      <Modal
        title={<h3 style={{ color: "#4492F8" }}>Best Relay</h3>}
        visible={bestRelayModalVisible}
        onOk={handleBestRelayOk}
        onCancel={handleBestRelayCancel}
      >
        <p>
          {bestRelays.length === 1
            ? "The fastest relay (based on each swimmer's best time converted to the course of the meet) is:"
            : "The fastest relays (based on each swimmer's best time converted to the course of the meet) are:"}
        </p>
        <ul>{relayConfigs}</ul>
        <p>
          {bestRelays.length === 1
            ? "This relay has a seed time of "
            : "These relays have a seed time of "}
          {bestRelaysSeedTime !== null &&
            formatTimeWithoutLeadingZeros(bestRelaysSeedTime)}
        </p>
      </Modal>
    )
  }
}
