import React from "react"
import { isRaceRelay } from "./utils"
function lineUpLines(arr, index) {
  let arrEl = arr[index]
  let records = arrEl.records
  let line_count = 3
  let numb_records = records.length
  let long_records = records.filter((el) => el.event_name.length > 42).length
  return line_count + numb_records + long_records
}

function pullOffLineUpChunk(arr, index) {
  let len = arr.length
  if (index < 0 || index >= len) {
    return len
  }

  let numberOnAPage = 38
  let lineCount = lineUpLines(arr, index)
  for (let i = index + 1; i < len; i++) {
    lineCount = lineCount + lineUpLines(arr, i)
    if (lineCount > numberOnAPage) {
      return i
    }
  }
  return len
}

function SingleResult({
  event_numb,
  is_exhibition,
  event_name,
  lane,
  heat,
  isLineUp,
}) {
  return (
    <div className="row" style={{ fontSize: "10px" }}>
      <span className="col-xs-1">#{event_numb}</span>
      <span className="col-xs-8">
        {is_exhibition === 1 && "x"} {event_name}
      </span>
      <span className="col-xs-1">{isLineUp ? "" : `H:${heat}`}</span>
      <span className="col-xs-2">{isLineUp ? "" : `L:${lane}`}</span>
    </div>
  )
}

function HalfPage({ halfData, isLineUp }) {
  return halfData.map((singleRecord, idx) => {
    let records = singleRecord.records
    let firstRecord = records[0]
    let numbExhibition = records.filter((el) => el.is_exhibition).length
    let numbRelays = records.filter((el) => isRaceRelay[el.race]).length
    return (
      <React.Fragment key={`half_data_single_record_${idx}`}>
        <div style={{ fontWeight: "bold", fontSize: "12" }}>
          {firstRecord.name} ({String(firstRecord.swim_numb).padStart(3, "0")})
          - {firstRecord.age}
        </div>
        <div>
          <div style={{ fontSize: "12px" }}>
            Individual :{" "}
            {Number(singleRecord.individual) - numbExhibition - numbRelays}
          </div>
          {numbExhibition > 0 ? (
            <div style={{ fontSize: "12px" }}>
              Individual Exhibition: {numbExhibition}
            </div>
          ) : null}
          <div style={{ fontSize: "12px" }}>Relay : {numbRelays}</div>
        </div>

        {singleRecord.records.map((oneResult, idx2) => (
          <SingleResult
            key={`single_result_${idx2}`}
            {...oneResult}
            isLineUp={isLineUp}
          />
        ))}

        <div style={{ marginTop: "8px" }} />
      </React.Fragment>
    )
  })
}

export default function LineUpPdf({
  meetName,
  meetDate,
  recordsAggregated,
  isLineUp,
}) {
  let chunks = []

  let startIndex = 0
  while (startIndex < recordsAggregated.length) {
    let nextIndex = pullOffLineUpChunk(recordsAggregated, startIndex)
    let firstHalf = recordsAggregated.slice(startIndex, nextIndex)
    let finalIndex = pullOffLineUpChunk(recordsAggregated, nextIndex)
    let secondHalf = recordsAggregated.slice(nextIndex, finalIndex)
    startIndex = finalIndex
    chunks.push({ firstHalf, secondHalf, startIndex })
  }

  return chunks.map((el) => {
    return (
      <div
        style={{
          color: "black",
          pageBreakAfter: "always",
          marginLeft: "10px",
          marginRight: "10px",
        }}
        key={startIndex}
      >
        <div
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "18px",
            marginBottom: "4px",
          }}
        >
          LineUp by Swimmer : {meetName} | {meetDate}
        </div>
        <div
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "12px",
            marginBottom: "10px",
          }}
        >
          powered by Swimmingly&reg;
        </div>

        <div className="row">
          <div
            className="col-xs-6"
            style={{ borderRight: "1px dotted black", height: "88%" }}
          >
            <HalfPage halfData={el.firstHalf} isLineUp={isLineUp} />
          </div>
          <div className="col-xs-6">
            <HalfPage halfData={el.secondHalf} isLineUp={isLineUp} />
          </div>
        </div>
      </div>
    )
  })
}
