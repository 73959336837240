import React from "react"
import { DownOutlined } from "@ant-design/icons"
import { Popover, Button } from "antd"

export default function ActionsMenu(props) {
  return (
    <Popover
      placement="bottom"
      title={""}
      content={props.children}
      trigger="hover"
      visible={props.visible}
      onVisibleChange={(val) => props.setVisible(val)}
    >
      <Button type="link" style={{ fontSize: "16px" }}>
        Actions <DownOutlined style={{ fontSize: "12px" }} />
      </Button>
    </Popover>
  )
}
