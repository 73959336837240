import { Button, Skeleton, Divider, Row, Col, message } from "antd";
import { CSSProperties, FunctionComponent, useEffect, useState } from "react";
import ContentPanel from "../ContentPanel";
import { useClubSeasonManagementContext } from "./sharedState";
import { Colors as SeasonSetupColors } from "../ClubSeasonManagement/styles";
import Footer from "../Footer";
import PremiumRegistrationCard from "./PremiumRegistrationCard";
import BasicRegistrationCard from "./BasicRegistrationCard";
import axios from 'axios';

const cardStyle: CSSProperties = {
  margin: '16px auto',
};

const centeredCardStyle: CSSProperties = {
  ...cardStyle,
  marginLeft: 'auto',
  marginRight: 'auto',
};

const HorizontalLine = () => {
  return (
    <Divider style={{ borderWidth: 1, borderColor: SeasonSetupColors.primaryBlue }}> 
    </Divider>
  )
}

interface IRegistrationReviewableProps {
  setReviewRegistration: (value: boolean) => void;
}

export const RegistrationChoice: FunctionComponent<IRegistrationReviewableProps> = ({ setReviewRegistration }) => {
  const { selectedClubSeason, chooseRegistration } = useClubSeasonManagementContext();
  const [seedPremium, setSeedPremium] = useState<number | null>(null);

  useEffect(() => {
    if (selectedClubSeason?.seasonId && selectedClubSeason?.clubId) {
      axios.get(`/api/getSeedPremiumStatus/${selectedClubSeason.seasonId}/${selectedClubSeason.clubId}`)
        .then(response => {
          console.log('Response:', response);
          if (response.data.status === 'success') {
            const seedPremiumStatus = response.data.data.seedPremium;
            setSeedPremium(seedPremiumStatus !== null ? seedPremiumStatus : 0);
          } else {
            message.error(response.data.message || 'Failed to fetch seed premium status');
          }
        })
        .catch(error => {
          console.error('Error fetching seed premium status:', error.message);
          console.error('Stack trace:', error.stack);
          message.error('Error fetching seed premium status');
        });
    }
  }, [selectedClubSeason?.seasonId, selectedClubSeason?.clubId]);

  if (!selectedClubSeason?.seasonId || !selectedClubSeason?.clubId) return <Skeleton />

  const renderContent = () => (
    <ContentPanel title="It's Time to Open Your Season for Registration">
      <p
        style={{
          textAlign: "center",
          width: "95%",
          marginTop: "25px",
          marginBottom: "50px",
          color: "black",
          fontSize: "22px",
          marginLeft: "25px",
        }}
      >
        <h2>
          <b>{seedPremium === 1 ? "Start Your Season" : "Choose your Registration Option Below to Open Season for Your Swim Families"}</b>
        </h2>
        <Row gutter={48}>
          <Col xs={24} sm={24} md={24} lg={seedPremium === 1 ? 24 : 12} xl={seedPremium === 1 ? 24 : 12}>
            <PremiumRegistrationCard
              cardStyle={seedPremium === 1 ? centeredCardStyle : cardStyle}
              chooseRegistration={chooseRegistration}
              setReviewRegistration={setReviewRegistration}
              selectedClubSeason={selectedClubSeason}
            />
          </Col>
          {seedPremium !== 1 && (
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <BasicRegistrationCard
                cardStyle={cardStyle}
                chooseRegistration={chooseRegistration}
                setReviewRegistration={setReviewRegistration}
                selectedClubSeason={selectedClubSeason}
              />
            </Col>
          )}
        </Row>
      </p>
      <HorizontalLine />
      <Footer />
    </ContentPanel>
  );

  return (
    <div style={{ marginTop: "25px" }}>
      {seedPremium !== null ? renderContent() : <Skeleton />}
    </div>
  );
};

export default RegistrationChoice;
