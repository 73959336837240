import React, { Fragment, useEffect, useState } from "react"
import ParentsTab from "./ParentsTab"
import { Empty, message, Radio, Tooltip, Steps, Button, Menu, Dropdown, Input, Row, Col, Tabs } from "antd"
import { DownOutlined, SendOutlined, UserOutlined, SyncOutlined } from '@ant-design/icons'
import styled from "styled-components"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../appData/types"
import ContentPanel from "./ContentPanel"
import ClubBlock from "./ClubBlock"
import { download, gen, ISwimmerWithoutGender, swimminglyApi } from "./utils"
import ClubSwimmers from "./ClubSwimmers"
import ClubParentsTable from "./ClubParentsTable"
import ActionAlerts from "./ActionAlerts"
import moment from "moment"
import { cloneDeep } from "lodash"
import GenericModal from "./GenericModal"
import ClubSeasonSwimmerInvoices from "./ClubInvoices/ClubSeasonSwimmerInvoices"

const { Step } = Steps


const StyledTitle = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;

  select,
  option {
    font-size: 1.35rem;
    height: 30px;
    padding: 0;
  }
`

const StyledSubmitButton = styled.button`
  background-color: var(--none);
  color: var(--black);
  border: none;
  border-radius: 0px;
  padding: 0px;

  ${({ buttonDisabled }: { buttonDisabled: boolean }) =>
    buttonDisabled &&
    `
  {
    background-color: var(--lightgrey);
    color: var(--darkgrey);
    cursor: not-allowed;
    border: 1px solid var(--darkgrey);
  }`}
`

const StyledRosterBlock = styled.div`
  h2 {
    text-align: center;
    margin-top: 125px;
  }

  h2.first {
    margin-top: 25px;
  }

  ${StyledSubmitButton} {
    float: right;
  }

  .control-row {
    display: flex;
    justify-content: space-between;
  }
`

interface IClubSeason {
  seasonId: number
  seasonName: string
  seasonType: "summer" | "yearRound"
  startDate: string
  endDate: string
  isSwimminglyCustomer: 0 | 1
  didNotHaveSeason: 0 | 1 | null
  clubSeasonId: number
  cutOffType: "meetStart" | "fixedCutOff"
  cutOffDate: string | null
}

export interface IGuardianRecord {
  swimmerId: number
  guardianId: number
  guardianEmail: string
  guardianName: string
}

export interface IManageRosterSeasonSwimmer extends ISwimmerWithoutGender {
  lastName: string
  firstName: string
  middleInitials: string | null
  dateOfBirth: string
  swimmerId: number
  swimmerShoulderNumber: number | null
  gender: "Boy" | "Girl"
  cutOffType: "meetStart" | "fixedCutOff"
  cutOffDate: string | null
  swimmerSeasonAge: number | null
  signedUp: 0 | 1 | null
  usaSwimmingId: string | null
  importedFromResults: 0 | 1 | null
  phoneNumber: string | null
  hasPaid: boolean
  // isActive: 0 | 1 | null
  activeMonths: number[]
  guardians: IGuardianRecord[]
}

const StyledStepsContainer = styled.div`
  max-width: ${({
    maxWidth,
    fontSize,
  }: {
    maxWidth?: string
    fontSize?: string
  }) => (maxWidth ? maxWidth : "700px")};
  margin: auto;
  margin-top: 0;
  margin-bottom: 0px;
  font-size: ${({
    maxWidth,
    fontSize,
  }: {
    maxWidth?: string
    fontSize?: string
  }) => (fontSize ? fontSize : "1rem")};
  align-self: center;
  align-items: center;

  h2.header {
    font-size: ${({
      maxWidth,
      fontSize,
    }: {
      maxWidth?: string
      fontSize?: string
    }) => (fontSize ? fontSize : "1.8rem")};
    margin-top: 0;
    padding-top: 3px;
  }

  .not-current {
    color: var(--mediumgrey);
  }
`

export const ProgressSteps = ({
  currentStep,
  fontSize,
  maxWidth,
  size,
  labelPlacement = "horizontal",
}: {
  currentStep: 0 | 1 | 2
  fontSize?: string
  maxWidth?: string
  size: "default" | "small"
  labelPlacement?: "horizontal" | "vertical"
}) => {
  return (
    <StyledStepsContainer fontSize={fontSize} maxWidth={maxWidth}>
      <Steps
        className="steps"
        size={size}
        current={currentStep}
        labelPlacement={labelPlacement}
      >
        <Step
          title={
            <Tooltip title="These swimmers' guardians have been invited by an admin to sign-up their swimmers. These swimmers were invited from a previous season. These swimmers' guardians have received your Season Sign-Up Email request and can register their swimmers at anytime.">
              <h2
                className={"header" + (currentStep !== 0 ? " not-current" : "")}
              >
                Invited
              </h2>
            </Tooltip>
          }
        />
        <Step
          title={
            <Tooltip title="These swimmers' guardians have signed them up for the season using your Club Registration Code.">
              <h2
                className={"header" + (currentStep !== 1 ? " not-current" : "")}
              >
                Signed Up
              </h2>
            </Tooltip>
          }
        />
        <Step
          title={
            <Tooltip title="These swimmers are active and have received their Swimmer # because their Swimmingly membership has been paid.">
              <h2
                className={"header" + (currentStep !== 2 ? " not-current" : "")}
              >
                Active
              </h2>
            </Tooltip>
          }
        />
      </Steps>
    </StyledStepsContainer>
    
  )
}

export default function ManageRoster(): JSX.Element {
  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)
  const impersonateClub = useStoreState(
    (state: StateMapper<AppDataStore>) => state.aliasedClub,
  )
  const [tab, setTab] = useState("swimmers")
  const [seasons, setSeasons] = useState<IClubSeason[]>([])
  const [rosterSwimmers, setRosterSwimmers] = useState<
    IManageRosterSeasonSwimmer[]
  >([])
  const [activeRosterSwimmers, setActiveRosterSwimmers] = useState<
    IManageRosterSeasonSwimmer[]
  >([])
  const [unpaidRosterSwimmers, setUnpaidRosterSwimmers] = useState<
    IManageRosterSeasonSwimmer[]
  >([])
  const [requestedRosterSwimmers, setRequestedRosterSwimmers] = useState<
    IManageRosterSeasonSwimmer[]
  >([])
  const [selectedSeasonId, setSelectedSeasonId] = useState<number>(0)
  const [showUsaSwimmingIds, setShowUsaSwimmingIds] = useState(false)
  const [selectedSwimmersToRollForward, setSelectedSwimmersToRollForward] =
    useState<IManageRosterSeasonSwimmer[]>([])
  const [rollSwimmersModalVisible, setRollSwimmersModalVisible] =
    useState(false)
  const [selectedSeasonForRollForward, setSelectedSeasonForRollForward] =
    useState<number | null>(null)
  const [searchText, setSearchText] = useState("")

  const changeSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
  }
  const [triggerRefresh, setTriggerRefresh] = useState(0)
  const [isSyncing, setIsSyncing] = useState(false);

  useEffect(() => {
    swimminglyApi
      .get(gen(`/api/getClubSeasons/${impersonateClub?.clubId || 0}`))
      .then((response) => {
        if (response.status === "success") {
          setSeasons(response.clubSeasons)
        }
      })
  }, [impersonateClub?.clubId])

  useEffect(() => {
    setActiveRosterSwimmers(
      rosterSwimmers.filter((s) => s.signedUp && s.hasPaid),
    )
    setUnpaidRosterSwimmers(
      rosterSwimmers.filter((s) => s.signedUp && !s.hasPaid),
    )
    setRequestedRosterSwimmers(rosterSwimmers.filter((s) => !s.signedUp))
  }, [rosterSwimmers])

  useEffect(() => {
    const selectedSeason = seasons.find(
      (season) => season.seasonId === selectedSeasonId,
    )
    if (!selectedSeason) {
      setRosterSwimmers([])
      setSelectedSwimmersToRollForward([])
    } else {
      swimminglyApi
        .get(gen(`/api/getRosterForClubSeason/${selectedSeason.clubSeasonId}`))
        .then(
          ({
            status,
            swimmers,
          }: {
            status: string
            swimmers: IManageRosterSeasonSwimmer[]
          }) => {
            if (status === "success") {
              setRosterSwimmers(swimmers)
              setSelectedSwimmersToRollForward([])
            }
          },
        )
        .catch((err) => {
          console.error(err)
          setRosterSwimmers([])
          setSelectedSwimmersToRollForward([])
          message.error("Problem finding roster for this season")
        })
    }
  }, [selectedSeasonId, seasons, triggerRefresh])

  if (!impersonateClub) return <p>Uh oh... no club!</p>
  if (![2, 3, 4].includes(user?.role || 0)) return <p>Access denied</p>

  const menu = (
              <Menu>
                <Menu.Item>
                <Tooltip
                    title="Use the Meet Check-In Sheet at your venue's check-in table. We recommend using a grease pen to
                          write the Swimmer # on each swimmer's forearm or shoulder"
                    >
                {selectedSeasonId && (
                    <a
                      type="text"
                      onClick={() => {
                        let data =
                          "Swimmer,Swimmer #,Age,Birthdate (mm/dd/yyyy),Gender\n"
                        activeRosterSwimmers.forEach((sw) => {
                          let row = sw.firstName + " " + sw.lastName + ","
                          row +=
                            "'" +
                            `${sw.swimmerShoulderNumber || 0}`.padStart(
                              3,
                              "0",
                            ) +
                            "',"
                          row += `${sw.swimmerSeasonAge},${moment(
                            sw.dateOfBirth,
                            "YYYY-MM-DD",
                          ).format("MM/DD/YYYY")},`
                          row += sw.gender
                          row += "\n"
                          data += row
                        })
                        download(data, "meet_checkin_sheet_swimmer_numbers.csv")
                      }}
                    >
                      Download Meet Check-In Sheet (w Swimmer #)
                    </a>
                  )}
                  </Tooltip>
                </Menu.Item>
                <Menu.Item>
                  <Tooltip
                    title="USA/Canadian IDs are used for any club integrating results/entries into a different system"
                    >
                <a
                    type="text"
                    onClick={() => setShowUsaSwimmingIds(!showUsaSwimmingIds)}
                  >
                    Show USA Swimming IDs
                  </a>
                  </Tooltip>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item disabled>
                  <Tooltip
                    title={
                      selectedSwimmersToRollForward.length === 0
                        ? "Multi-select swimmers from a previous season to invite their guardians to sign-up them up for the upcoming season!"
                        : "Send season sign-up email to guardians of the selected swimmers"
                    }
                  >
                    <Button 
                      type="text"
                      icon={<SendOutlined />}
                      disabled={
                        selectedSwimmersToRollForward.length === 0
                          ? true
                          : false
                      }
                      onClick={() => {
                        if (selectedSwimmersToRollForward.length === 0) {
                          return
                        }
                        setRollSwimmersModalVisible(true)
                      }}
                    >
                      Send Season Sign-Up Email to Guardians
                    </Button>
                  </Tooltip>
                </Menu.Item>
              </Menu>
  );
 
  return (
    <div>
      <header className="page-header">
        <h2>Roster: {impersonateClub && impersonateClub.nameLong}</h2>
      </header>
      <ActionAlerts />
      <ClubBlock />
      <ContentPanel>
          <StyledTitle>
            <div>
            
              <span>
              <h2 className="first">🏊‍♀️ {impersonateClub && impersonateClub.nameLong} 🏊‍♂️
              <select
                value={selectedSeasonId}
                onChange={(e) => setSelectedSeasonId(parseInt(e.target.value, 10))}
              >
                <option value={0} disabled>
                  Choose Season
                </option>
                {seasons.map((season) => (
                  <option
                    key={`season_${season.seasonId}`}
                    value={season.seasonId}
                  >
                    {season.seasonName}
                  </option>
                ))}
              </select>
              
              </h2>
              </span>
              
              {/* Conditional rendering of Tab Group */}
              {selectedSeasonId > 0 && (
                <Tabs
                  defaultActiveKey="swimmers"
                  onChange={setTab}
                  type="line"
                  size="middle"
                >
                  <Tabs.TabPane tab="Swimmers" key="swimmers">
                    {/* Content for swimmers could go here, or you can keep it outside */}
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Parents / Guardians" key="parents">
                    {/* Wrap your ParentsTab component in TabPane if it's self-contained */}
                  </Tabs.TabPane>
                  
                </Tabs>
              )}
            </div>
          </StyledTitle>

        {tab === "swimmers" ? (
          <StyledRosterBlock>
            {selectedSeasonId ? (
              <div>
            <Row>
              <Col flex="200px">
                <Input 
                  placeholder="Search Swimmers..."
                  allowClear={true}
                  prefix={<UserOutlined />}
                  onChange={changeSearchText}
                />
              </Col>
              <Col
                flex="auto"
              >
                <div 
                  style={{ 
                    textAlign: "right" 
                  }}
                >
                  <Dropdown 
                    overlay={menu}
                  >
                  <a 
                    className="ant-dropdown-link"
                    onClick={e => e.preventDefault()}
                  >
                    
                      Actions <DownOutlined />
                  </a>
              </Dropdown>
              </div>
              </Col>
            </Row>
              </div>
            ) : null}
            {selectedSeasonId ? (
              <React.Fragment>
                  {[2, 3, 4].includes(user?.role || -1) && user !== null && (
                    <Tooltip 
                      title="Clicking this will re-sync your active roster to the SwimminglyFan app."
                    >
                    <StyledSubmitButton
                      buttonDisabled={![2, 3, 4].includes(user.role)}
                      onClick={() => {
                        setIsSyncing(true); // Start the spinner

                        const swimmerIds = activeRosterSwimmers.map(
                          (s) => s.swimmerId,
                        );

                        swimminglyApi
                          .post(gen("/api/syncSwimmersWithFirebase"))
                          .body({ swimmerIds })
                          .then((data) => {
                            if (data.status === "success") {
                              message.success("Success!");
                            } else if (data.message) {
                              message.error(data.message);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                            message.error("Please try re-syncing your roster again later");
                          })
                          .finally(() => {
                            setIsSyncing(false); // Stop the spinner when the operation is complete
                          });
                      }}
                    >
                      Sync Roster With SwimminglyFan {isSyncing ? <SyncOutlined spin /> : <SyncOutlined />}
                    </StyledSubmitButton>
                    </Tooltip>
                  )}
                
                
                {activeRosterSwimmers.length > 0 ? (
                  <Fragment>
                    <ProgressSteps size="small" currentStep={2} />
                    <ClubSwimmers
                      swimmerArr={activeRosterSwimmers}
                      leagueCutOff={
                        seasons.find(
                          (season) => season.seasonId === selectedSeasonId,
                        )?.cutOffDate
                      }
                      searchText={searchText}
                      isSwimminglyCustomer={
                        seasons.find(
                          (season) => season.seasonId === selectedSeasonId,
                        )?.isSwimminglyCustomer === 1
                      }
                      seasonEnd={
                        seasons.find(
                          (season) => season.seasonId === selectedSeasonId,
                        )?.endDate
                      }
                      refreshSwimmers={() =>
                        setTriggerRefresh(triggerRefresh + 1)
                      }
                      isParent={false}
                      showUSASwimmingIds={showUsaSwimmingIds}
                      setShowUSASwimmingIds={setShowUsaSwimmingIds}
                      seasonId={selectedSeasonId}
                      canRollSwimmers={
                        seasons.filter(
                          (season) =>
                            season.seasonId !== selectedSeasonId &&
                            moment(season.endDate).diff(moment()) > 0,
                        ).length > 0
                      }
                      selectedSwimmersToRollForward={
                        selectedSwimmersToRollForward
                      }
                      updateRolloverSwimmers={(
                        swimmerId: number,
                        addOrRemove: "add" | "remove",
                      ) => {
                        let newRolloverSwimmers = cloneDeep(
                          selectedSwimmersToRollForward,
                        )
                        const selectedSwimmer = rosterSwimmers.find(
                          (rS) => rS.swimmerId === swimmerId,
                        )
                        if (
                          selectedSwimmer &&
                          addOrRemove === "add" &&
                          !newRolloverSwimmers
                            .map((nRS) => nRS.swimmerId)
                            .includes(swimmerId)
                        ) {
                          newRolloverSwimmers.push(selectedSwimmer)
                        } else if (addOrRemove === "remove") {
                          newRolloverSwimmers = newRolloverSwimmers.filter(
                            (s) => s.swimmerId !== swimmerId,
                          )
                        }
                        setSelectedSwimmersToRollForward(newRolloverSwimmers)
                      }}
                    />
                  </Fragment>
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={<span>No Swimmers Found</span>}
                  />
                )}
                <h2>Signed-Up Swimmers Awaiting Payment</h2>
                {unpaidRosterSwimmers.length > 0 ? (
                  <Fragment>
                    <ProgressSteps size="small" currentStep={1} />
                    <ClubSwimmers
                      swimmerArr={unpaidRosterSwimmers}
                      leagueCutOff={
                        seasons.find(
                          (season) => season.seasonId === selectedSeasonId,
                        )?.cutOffDate
                      }
                      searchText={searchText}
                      isSwimminglyCustomer={
                        seasons.find(
                          (season) => season.seasonId === selectedSeasonId,
                        )?.isSwimminglyCustomer === 1
                      }
                      seasonEnd={
                        seasons.find(
                          (season) => season.seasonId === selectedSeasonId,
                        )?.endDate
                      }
                      refreshSwimmers={() =>
                        setTriggerRefresh(triggerRefresh + 1)
                      }
                      isParent={false}
                      showUSASwimmingIds={showUsaSwimmingIds}
                      setShowUSASwimmingIds={setShowUsaSwimmingIds}
                      seasonId={selectedSeasonId}
                      canRollSwimmers={
                        seasons.filter(
                          (season) =>
                            season.seasonId !== selectedSeasonId &&
                            moment(season.endDate).diff(moment()) > 0,
                        ).length > 0
                      }
                      selectedSwimmersToRollForward={
                        selectedSwimmersToRollForward
                      }
                      updateRolloverSwimmers={(
                        swimmerId: number,
                        addOrRemove: "add" | "remove",
                      ) => {
                        let newRolloverSwimmers = cloneDeep(
                          selectedSwimmersToRollForward,
                        )
                        const selectedSwimmer = rosterSwimmers.find(
                          (rS) => rS.swimmerId === swimmerId,
                        )
                        if (
                          selectedSwimmer &&
                          addOrRemove === "add" &&
                          !newRolloverSwimmers
                            .map((nRS) => nRS.swimmerId)
                            .includes(swimmerId)
                        ) {
                          newRolloverSwimmers.push(selectedSwimmer)
                        } else if (addOrRemove === "remove") {
                          newRolloverSwimmers = newRolloverSwimmers.filter(
                            (s) => s.swimmerId !== swimmerId,
                          )
                        }
                        setSelectedSwimmersToRollForward(newRolloverSwimmers)
                      }}
                    />
                  </Fragment>
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={<span>No Swimmers Found</span>}
                  />
                )}
                <h2>
                  Swimmers Awaiting Sign-Up By Guardian (Invite email sent)
                </h2>
                {requestedRosterSwimmers.length > 0 ? (
                  <Fragment>
                    <ProgressSteps size="small" currentStep={0} />
                    <ClubSwimmers
                      swimmerArr={requestedRosterSwimmers}
                      leagueCutOff={
                        seasons.find(
                          (season) => season.seasonId === selectedSeasonId,
                        )?.cutOffDate
                      }
                      searchText={searchText}
                      isSwimminglyCustomer={
                        seasons.find(
                          (season) => season.seasonId === selectedSeasonId,
                        )?.isSwimminglyCustomer === 1
                      }
                      seasonEnd={
                        seasons.find(
                          (season) => season.seasonId === selectedSeasonId,
                        )?.endDate
                      }
                      refreshSwimmers={() =>
                        setTriggerRefresh(triggerRefresh + 1)
                      }
                      isParent={false}
                      showUSASwimmingIds={showUsaSwimmingIds}
                      setShowUSASwimmingIds={setShowUsaSwimmingIds}
                      seasonId={selectedSeasonId}
                      canRollSwimmers={
                        seasons.filter(
                          (season) =>
                            season.seasonId !== selectedSeasonId &&
                            moment(season.endDate).diff(moment()) > 0,
                        ).length > 0
                      }
                      selectedSwimmersToRollForward={
                        selectedSwimmersToRollForward
                      }
                      updateRolloverSwimmers={(
                        swimmerId: number,
                        addOrRemove: "add" | "remove",
                      ) => {
                        let newRolloverSwimmers = cloneDeep(
                          selectedSwimmersToRollForward,
                        )
                        const selectedSwimmer = rosterSwimmers.find(
                          (rS) => rS.swimmerId === swimmerId,
                        )
                        if (
                          selectedSwimmer &&
                          addOrRemove === "add" &&
                          !newRolloverSwimmers
                            .map((nRS) => nRS.swimmerId)
                            .includes(swimmerId)
                        ) {
                          newRolloverSwimmers.push(selectedSwimmer)
                        } else if (addOrRemove === "remove") {
                          newRolloverSwimmers = newRolloverSwimmers.filter(
                            (s) => s.swimmerId !== swimmerId,
                          )
                        }
                        setSelectedSwimmersToRollForward(newRolloverSwimmers)
                      }}
                    />
                  </Fragment>
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={<span>No Swimmers Found</span>}
                  />
                )}
              </React.Fragment>
            ) : (
              <h2 className="first">No Season Selected</h2>
            )}
          </StyledRosterBlock>
        ) : (
          <ClubParentsTable seasonId={selectedSeasonId} />
        )}
      </ContentPanel>
      <GenericModal
        title="Send Season Sign-Up Email to Guardians"
        visible={rollSwimmersModalVisible}
        setVisible={setRollSwimmersModalVisible}
        footer={() => (
          <Fragment>
            <button
              onClick={() => {
                setRollSwimmersModalVisible(false)
              }}
            >
              Cancel
            </button>
            <StyledSubmitButton
              buttonDisabled={selectedSeasonForRollForward === null}
              onClick={async () => {
                if (selectedSeasonForRollForward === null) {
                  return
                }

                const requestSwimmers: Promise<void | boolean>[] = []
                selectedSwimmersToRollForward.forEach((swimmer) => {
                  const requestOneSwimmer = swimminglyApi
                    .post(gen("/api/createRegistration"))
                    .body({
                      clubId: impersonateClub?.clubId,
                      registrationStatus: "requested",
                      seasonId: selectedSeasonForRollForward,
                      swimmerId: swimmer.swimmerId,
                      userId: user?.userId,
                    })
                    .then((results) => {
                      if (results.status === "success") {
                        message.success(
                          `Requested sign-up for ${swimmer.firstName} ${swimmer.lastName}!`,
                        )
                      } else {
                        message.error(
                          `Something went wrong requesting signing-up for ${swimmer.firstName} ${swimmer.lastName}!`,
                        )
                      }
                    })
                    .catch((err) => {
                      console.log(err)
                      message.error(
                        `Something went wrong requesting signing-up for ${swimmer.firstName} ${swimmer.lastName}!`,
                      )
                    })
                  requestSwimmers.push(requestOneSwimmer)
                })
                await Promise.all(requestSwimmers).then(() => {
                  setSelectedSwimmersToRollForward([])
                })
                setRollSwimmersModalVisible(false)
                setSelectedSeasonForRollForward(null)
              }}
            >
              Send Season Sign-Up Email for Selected Swimmers
            </StyledSubmitButton>
          </Fragment>
        )}
      >
        <p>
          You are about to email the guardians of the selected swimmers an
          invite to sign-up their swimmers for your upcoming season.
          As soon as these parents sign-up their swimmers, you will see
          the swimmers activated on your chosen season's roster.
        </p>
        <form>
          {seasons
            .filter(
              (season) =>
                season.seasonId !== selectedSeasonId &&
                moment(season.endDate).diff(moment()) > 0,
            )
            .map((season) => {
              return (
                <fieldset
                  style={{ display: "flex", alignContent: "center" }}
                  key={`season_${season.seasonId}`}
                >
                  <input
                    id={`season-${season.seasonId}`}
                    type="checkbox"
                    checked={selectedSeasonForRollForward === season.seasonId}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedSeasonForRollForward(season.seasonId)
                      } else {
                        if (selectedSeasonForRollForward === season.seasonId) {
                          setSelectedSeasonForRollForward(null)
                        }
                      }
                    }}
                  />
                  <label htmlFor={`season-${season.seasonId}`}>
                    &nbsp;{season.seasonName}
                  </label>
                </fieldset>
              )
            })}
        </form>
      </GenericModal>
    </div>
  )
}
