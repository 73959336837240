import { useState, useEffect, FunctionComponent } from "react"
import moment, { Moment } from "moment-timezone"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../../appData/types"
import { DateFormats } from "../utils"
import { RegistrationChoice } from "./RegistrationChoice"
import { ConfirmSignupOpenDate, SeasonConfigurationNoRegistration } from "./SeasonConfigurationNoRegistration"
import { RegistrationConfiguration } from "./RegistrationConfiguration"
import {
  useClubSeasonManagementContext,
} from "./sharedState"
import { SeasonSelection } from "./SeasonSelection"
import { ConfirmRegistrationModal } from "./ConfirmRegistrationModal"
import { ISeasonDetails } from "../../App"

const SelectedSeasonConfiguration: FunctionComponent = () => {
  const [signupsOpen, setSignupsOpen] = useState<Moment | null>(null)
  const [settingsConfirmed, setSettingsConfirmed] = useState(false)
  const [reviewRegistration, setReviewRegistration] = useState(false)
  const [seasonInfo, setSeasonInfo] = useState<ISeasonDetails | null>(null)

  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)
  const impersonateClub = useStoreState(
    (state: StateMapper<AppDataStore>) => state.aliasedClub,
  )

  const {
    selectedClubSeason,
    setDbUsingRegistration,
    frontendUsingRegistration,
    loadRegistrationInfo,
    loadClubSeasons,
    selectSeason,
    loadBillingInfo, 
    billingInfo
  } = useClubSeasonManagementContext()

  useEffect(() => {
    const registrationChoice =
      selectedClubSeason?.usingRegistration === undefined
        ? null
        : selectedClubSeason?.usingRegistration
    const seasonId = selectedClubSeason?.seasonId
    if (seasonId) {
      if (registrationChoice === false && settingsConfirmed) {
        setDbUsingRegistration(false)
      } else if (registrationChoice === false) {
        setDbUsingRegistration(null)
      } else {
        setDbUsingRegistration(registrationChoice)
      }
    }
  }, [selectedClubSeason, setDbUsingRegistration])

  useEffect(() => {
    //on settings confirm, reselect the club season to make sure that the club season is up to date
    //having to do this suggests that we could probably do a better job with the local state
    if (selectedClubSeason?.seasonId != undefined) {
      loadClubSeasons()
      selectSeason(selectedClubSeason?.seasonId)
    }

  }, [settingsConfirmed])

  //keith thinks that having frontendUsingRegistration and dbUsingRegistration is WAY to much state
  useEffect(() => {
    setSignupsOpen(null)
  }, [frontendUsingRegistration])

  useEffect(() => {

    if (selectedClubSeason?.seasonSetupConfirmedBy) {
      setSettingsConfirmed(true)
    } else {
      setSettingsConfirmed(false)
    }

    if (selectedClubSeason?.signUpsOpenDate) {
      const signUpsOpenMoment = moment(
        selectedClubSeason?.signUpsOpenDate,
        DateFormats.default,
      )
      setSignupsOpen(signUpsOpenMoment)
    }
  }, [selectedClubSeason])

  useEffect(() => {
    if (selectedClubSeason?.seasonId) {
      loadRegistrationInfo(selectedClubSeason.seasonId)
    }
  }, [loadRegistrationInfo, selectedClubSeason])

  useEffect(() => {
    //keith says: this is getting pretty stringy with how we manage signup date and its presence on selectedClubSeason
    if (selectedClubSeason !== undefined && selectedClubSeason != null) {
      const signupDateAsString = signupsOpen?.format(DateFormats.shortMonthAndDay) ?? null
      selectedClubSeason.signUpsOpenDate = signupDateAsString
    }    
  }, [signupsOpen])

  useEffect(() => {
    if (
      Boolean(selectedClubSeason?.seasonId) &&
      Boolean(impersonateClub?.clubId)
    ) {
      loadBillingInfo(impersonateClub?.clubId, selectedClubSeason?.seasonId)
    }
  }, [selectedClubSeason?.seasonId, impersonateClub?.clubId, loadBillingInfo])

  useEffect(() => {
    if (billingInfo.seasonInfo !== undefined) {
      setSeasonInfo(billingInfo.seasonInfo)
    } 
    else {
      setSeasonInfo(null)
    }
  }, [loadBillingInfo, billingInfo])
  
  return (
    <div>
      {
      selectedClubSeason?.isSwimminglyCustomer === 0 ? (
      <ConfirmSignupOpenDate 
        signupsOpen={signupsOpen}
        setSignupsOpen ={setSignupsOpen}
        settingsConfirmed={settingsConfirmed}
        setSettingsConfirmed={setSettingsConfirmed}
        seasonInfo={seasonInfo}
        selectedClubSeason={selectedClubSeason}
        user={user}
        frontendUsingRegistration={false}
      />
      ):
      (
        frontendUsingRegistration === null || reviewRegistration === true) && (
        <RegistrationChoice setReviewRegistration={setReviewRegistration} />
      ) || 
     frontendUsingRegistration === true && reviewRegistration === false && (
        <RegistrationConfiguration
          setReviewRegistration={setReviewRegistration}
          signupsOpen={signupsOpen}
          setSignupsOpen={setSignupsOpen}
          setSettingsConfirmed={setSettingsConfirmed}
          settingsConfirmed={settingsConfirmed}
          frontendUsingRegistration={frontendUsingRegistration}
        />
      ) ||
      frontendUsingRegistration === false && reviewRegistration === false && (
        <SeasonConfigurationNoRegistration
          signupsOpen={signupsOpen}
          setSignupsOpen={setSignupsOpen}
          settingsConfirmed={settingsConfirmed}
          setSettingsConfirmed={setSettingsConfirmed}
          setReviewRegistration={setReviewRegistration}
        />
      )}
  
      <ConfirmRegistrationModal />
    </div>
  )
}

const ClubSeasonManagementInner: FunctionComponent = () => {
  const impersonateClub = useStoreState(
    (state: StateMapper<AppDataStore>) => state.aliasedClub,
  )

  const { selectedClubSeason } = useClubSeasonManagementContext()

  return (
    <div>
      <header className="page-header">
        <h2>{impersonateClub?.name}'s Season Registration</h2>
      </header>
      <SeasonSelection />
      {selectedClubSeason && <SelectedSeasonConfiguration />}
    </div>
  )
}

export const ClubSeasonManagement: FunctionComponent = () => {
  return (
    <ClubSeasonManagementInner />
    
  )
}
