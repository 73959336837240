import React from "react"
import moment from "moment-timezone"
import { Tag } from "antd"

export default function EntryHeader({ date, isLocked, meet }) {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div>
        <div>
          {date ? (
            <div>
              Deadline to complete is{" "}
              <b>
                {moment(date)
                  .utcOffset(-new Date().getTimezoneOffset())
                  .format("MM/DD/YYYY hh:mma")}
              </b>
            </div>
          ) : null}
        </div>
        <div>
          Your team leadership will have final review &amp; complete relay
          entries{" "}
        </div>
        <div>
          Official Individual Entry Limit: <b>{meet?.individualLimit}</b>
        </div>
        <div>
          Official Relay Entry Limit: <b>{meet?.relayLimit}</b>
        </div>
      </div>
      {isLocked !== null && (
        <div>
          <Tag color={isLocked ? "#f50" : null}>Locked</Tag>
          <Tag color={!isLocked ? "#87d068" : null}>Open</Tag>
        </div>
      )}
    </div>
  )
}
