import React from "react"
import styled, { StyledComponent } from "styled-components"

const PanelSection: StyledComponent<
  "section",
  any,
  { marginTop?: string | undefined; contentPaddingTop?: number | undefined },
  never
> = styled.section`
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  ${({
    marginTop,
    contentPaddingTop,
  }: {
    marginTop?: string | undefined
    contentPaddingTop?: number | undefined
  }) => marginTop && "margin-top: " + marginTop + ";"}
  /* width: 400px; */
  padding-bottom: 20px;

  header {
    background: #f6f6f6;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #dadada;
    padding: 18px;
    position: relative;
  }

  .hidden {
    display: none;
  }

  header h2 {
    color: #33353f;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    padding: 0;
    text-transform: none;
  }

  .content {
    background: #fdfdfd;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 15px;
    padding-top: ${({
      marginTop,
      contentPaddingTop,
    }: {
      marginTop?: string | undefined
      contentPaddingTop?: number | undefined
    }) => (contentPaddingTop === undefined ? "15" : `${contentPaddingTop}`)}px;
  }

  header + .content {
    border-radius: 0 0 5px 5px;
  }
`

export default function ContentPanel({
  title,
  children,
  marginTop,
  contentPaddingTop,
}: {
  title?: string | JSX.Element
  children: React.ReactNode
  marginTop?: string
  contentPaddingTop?: number
}): JSX.Element {
  return (
    <PanelSection marginTop={marginTop} contentPaddingTop={contentPaddingTop}>
      <header className={title === undefined ? "hidden" : "seen"}>
        <h2>{title}</h2>
      </header>
      <div className={children !== null ? "content" : "hidden"}>{children}</div>
    </PanelSection>
  )
}
