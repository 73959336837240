import styled from "styled-components"

export const StyledBillingRow = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr;
  align-items: center;
  column-gap: 10px;
  border-bottom: 0px solid lightgray;
  padding: 0px 0px 0px 0px;

 

  .header {
    font-weight: bold;
  }
`
