import React from "react";
import InviteClubAdmin from "./InviteClubAdmin";
import AdminUsersClub from "./AdminUsersClub";
// import ManageParents from "./ManageParents";
import CenterSpin from "./CenterSpin";
import ClubBlock from "./ClubBlock";
import { gen } from "./utils";
import ActionAlerts from "./ActionAlerts";

export default class ManageClubUsers extends React.Component {
  state = {
    adminUsers: [],
    loaded: false,
  };

  componentDidMount() {
    this.getClubUsers();
  }

  getClubUsers = async () => {
    try {
      const response = await fetch(gen("/api/getClubUsers2Post"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user: this.props.user,
          clubId: this.props.club.clubId,
        }),
      });
      const data = await response.json();
      this.setState({ adminUsers: data.clubUsersArr, loaded: true });
    } catch (error) {
      console.error("Failed to fetch club users:", error);
    }
  };

  render() {
    if (!this.state.loaded) {
      return (
        <div>
          <header className="page-header">
            <h2>Club Admins</h2>
          </header>

          <ClubBlock />

          <section className="panel">
            <header className="panel-heading">
              <h2 className="panel-title">Club Admins</h2>
            </header>
            <div className="ourpanel-body">
              <CenterSpin />
            </div>
          </section>
        </div>
      );
    }
    return (
      <div>
        <header className="page-header">
          <h2>Club Admins</h2>
        </header>

        <ActionAlerts />

        <ClubBlock />

        <section className="panel">
          <header className="panel-heading">
            <h2 className="panel-title" style={{ display: "flex", alignItems: "center" }}>
              Club Admins{" "}
              <span style={{ fontSize: "16px", marginLeft: "8px" }}>
                ({this.state.adminUsers.length} users)
              </span>
              <span style={{ marginLeft: "8px" }}>
                <InviteClubAdmin
                  user={this.props.user}
                  clubId={this.props.club.clubId}
                  reloadUserData={this.getClubUsers}  // Pass the reload function
                />
              </span>
            </h2>
          </header>

          <div className="ourpanel-body">
            <AdminUsersClub
              reloadUserData={this.getClubUsers}  // Pass the reload function
              adminUsers={this.state.adminUsers}
              club={this.props.club}
              user={this.props.user}
            />
          </div>
        </section>
        
        {/*<ManageParents user={this.props.user} club={this.props.club} />*/}
      </div>
    );
  }
}
