import React, { FunctionComponent, CSSProperties, } from 'react'
import { Colors as SeasonSetupColors} from '../ClubSeasonManagement/styles'
import { Button} from 'antd'
import { BulbOutlined } from '@ant-design/icons'
import { URLs } from '../utils'

type TextContainerProps = {
  children?: React.ReactNode
  present?: boolean
  style?: CSSProperties
}
const Icons = {
  linkOut: <BulbOutlined style={{ color: "white" }} />,
}

const TextContainer: FunctionComponent<TextContainerProps> = ({
    children,
    present = true,
    style,
}: TextContainerProps) => {
    if (!present) {
        return null
    }
    return (
        <p
            style={Object.assign(
            {
                textAlign: "center",
                width: "60%",
                marginRight: "auto",
                marginLeft: "auto",
            },
            style,)}
        >
        {children}
        </p>
    )
}

export default function Footer() {
    return (
        <div style={{ marginTop: "25px" }}>
            <TextContainer style={{ color: SeasonSetupColors.primaryBlue, fontWeight: "bold" }}>
                <a href="https://splash.swimmingly.app/benefits" target="blank">
                    Features and Benefits of Swimmingly®
                </a>
               <a href="https://swimmingly.app/customer-policy" target="_blank" rel="noopener noreferrer"> Child Protection Policy & Customer Policy</a>
                <a href="https://support.swimmingly.app" target="blank" style={{ display: "block"}}>
                    Want to learn more about registration setup?
                </a>
            </TextContainer>

            <TextContainer style={{ fontWeight: "bold" }}>
                <Button
                    shape="round"
                    style={{
                        color: "white",
                        backgroundColor: SeasonSetupColors.linkButtonBackground,
                    }}
                    href={URLs.swimminglyUniversity}
                    target="_blank"
                >
                    Swimmingly® Help Center {Icons.linkOut}
                </Button>
            </TextContainer>
        </div>
    )
}
