import React from "react"
import $ from "jquery"
import { withRouter } from "react-router-dom"
import { RightOutlined } from "@ant-design/icons"
import { Modal, message, Button } from "antd"
import heatsheetAccepted from "../images/heatsheetAccepted.png"
import lineupSet from "../images/lineupSet.png"
import meetCreated from "../images/meetCreated.png"
import imageTrue from "../images/true.png"
import imageFalse from "../images/false.png"
import { gen } from "./utils"

/*
Start Copy
*/
let laneMappingArr = {
  1: [1],
  2: [1, 2],
  3: [2, 1, 3],
  4: [2, 3, 1, 4],
  5: [3, 2, 4, 1, 5],
  6: [3, 4, 2, 5, 1, 6],
  7: [4, 3, 5, 2, 6, 1, 7],
  8: [4, 5, 3, 6, 2, 7, 1, 8],
  9: [5, 4, 6, 3, 7, 2, 8, 1, 9],
  10: [5, 6, 4, 7, 3, 8, 2, 9, 1, 10],
}

/*

1. Put them in increasing lanes with no regard for club
2. Put them into proper lanes for club
3. Make sure you put in the athletes fast to slow
4. Make sure you put in athletes fast to slow but then reverse heats if need be
5. Write the by time version
// And then you are done. Holy fuck

*/

function getAllEvents(entries) {
  let set = new Set(entries.map((el) => el.swim_event_id))
  return Array.from(set)
}

function clubToRecordMapIsEmpty(clubToRecordMap) {
  let keys = Object.keys(clubToRecordMap)
  for (let i = 0; i < keys.length; i++) {
    let key = keys[i]
    if (clubToRecordMap[key].length !== 0) {
      return false
    }
  }
  return true
}
// Change for exhibition
function sortAthletesByTime(a, b) {
  if (a.is_exhibition && !b.is_exhibition) {
    return 1
  }
  if (!a.is_exhibition && b.is_exhibition) {
    return -1
  }
  if (a.best_time_at_merge && !b.best_time_at_merge) {
    return -1
  }
  if (!a.best_time_at_merge && b.best_time_at_merge) {
    return 1
  }
  if (a.best_time_at_merge && b.best_time_at_merge) {
    let aNumb = Number(a.best_time_at_merge)
    let bNumb = Number(b.best_time_at_merge)
    if (aNumb < bNumb) {
      return -1
    }
    if (aNumb > bNumb) {
      return 1
    }
    return 0
  }
  return 0
}

function reverseHeats(entries) {
  let heats = entries.map((el) => el.updated_heat)
  let maxHeat = Math.max(...heats)
  let minHeat = Math.min(...heats) // should just be 1

  // Let's say max is 5, min is 1. 1 -> 5, 2 -> 4, and so on
  for (let i = 0; i < entries.length; i++) {
    let curEntry = entries[i]
    curEntry.updated_heat = minHeat + maxHeat - curEntry.updated_heat
  }
}

function mergeSingleEventByTime(entries, meet, swim_event_id, clubLanes) {
  // All entries in this event
  let allPeople = entries.filter((el) => el.swim_event_id === swim_event_id)
  allPeople.sort(sortAthletesByTime)

  let curLaneArr = laneMappingArr[meet.lanes]
  let curLaneIndex = 0
  let curHeat = 1

  while (allPeople.length > 0) {
    let lane = curLaneArr[curLaneIndex]
    let record = allPeople.shift()
    record.updated_lane = lane
    record.updated_heat = curHeat

    curLaneIndex += 1
    if (curLaneIndex >= curLaneArr.length) {
      curLaneIndex = 0
      curHeat += 1
    }
  }
  // Slowest to Fastest
  if (meet.lanesassignemnt === 2) {
    let allPeople2 = entries.filter((el) => el.swim_event_id === swim_event_id)
    reverseHeats(allPeople2)
  }
}

function mergeSingleEventFixed(
  entries,
  meet,
  swim_event_id,
  clubLanes,
  condense = true,
) {
  // All entries in this event
  let allPeople = entries.filter((el) => el.swim_event_id === swim_event_id)
  allPeople.sort(sortAthletesByTime)
  let clubLaneToId = {} // Maps lane number to clubId {1 : '6000'}
  let clubToRecordMap = {} // Maps clubId to list { 6000: [meetEntry1, meetEntry2] }

  // console.log("clubLanes")
  // console.log(clubLanes)

  for (let i = 0; i < clubLanes.length; i++) {
    let cur = clubLanes[i]
    clubLaneToId[cur.lane_number] = cur.club_id
    clubToRecordMap[cur.club_id] = []
  }

  // Make the clubToRecord Map
  // console.log("allPeople")
  // console.log(allPeople)
  for (let i = 0; i < allPeople.length; i++) {
    let person = allPeople[i]
    let club_id = person.club_id

    clubToRecordMap[club_id].push(person)
  }

  let allLanes = meet.lanes

  let curLaneIndex = 0
  let curLaneArr = laneMappingArr[allLanes]
  let curHeat = 1
  // let loopGood = false

  while (!clubToRecordMapIsEmpty(clubToRecordMap)) {
    let lane = curLaneArr[curLaneIndex]
    let clubForThisLane = clubLaneToId[String(lane)]
    // How can we not have a club for a lane.
    // Well if someone fucks up
    if (clubForThisLane) {
      let theseAthletes = clubToRecordMap[clubForThisLane]
      if (theseAthletes.length !== 0) {
        // Do stuff
        let record = theseAthletes.shift()
        record.updated_lane = lane
        record.updated_heat = curHeat
        // loopGood = true
      } else {
        if (condense) {
          let throwIn = true
          let values = Object.values(clubToRecordMap)
          for (let i = 0; i < values.length; i++) {
            let curArr = values[i]
            if (curArr.length > 0 && throwIn) {
              let record = curArr.shift()
              record.updated_lane = lane
              record.updated_heat = curHeat
              throwIn = false
            }
          }
        }
      }
    }

    curLaneIndex += 1
    if (curLaneIndex >= curLaneArr.length) {
      curLaneIndex = 0
      curHeat += 1
    }
  }
  // Slowest to Fastest
  if (meet.lanesassignemnt === 2) {
    let allPeople2 = entries.filter((el) => el.swim_event_id === swim_event_id)
    reverseHeats(allPeople2)
  }
}

function mergeAll(entries, meet, clubLanes, condense) {
  let theMeet
  if (Array.isArray(meet)) {
    theMeet = meet[0]
  } else {
    theMeet = meet
  }
  let allEvents = getAllEvents(entries)
  for (let i = 0; i < allEvents.length; i++) {
    let curEvent = allEvents[i]
    if (theMeet.laneassigmenttype === 2) {
      mergeSingleEventByTime(entries, theMeet, curEvent, clubLanes)
    } else if (theMeet.laneassigmenttype === 1) {
      mergeSingleEventFixed(entries, theMeet, curEvent, clubLanes, condense)
    } else {
      message.error("Meet isn't by time or fixed!")
    }
  }
}

async function moveEntries(meet_id) {
  try {
    const data = await $.ajax({
      url: gen(`/api/moveEntriesPost`),
      method: "POST",
      dataType: "json",
      data: {
        meetId: meet_id,
      },
    })
    return data
  } catch (error) {
    console.error(error)
  }
}

async function getMeetEntries(meetId) {
  try {
    const result = await $.ajax({
      url: gen(`/api/getMeetEntriesPost`),
      method: "POST",
      dataType: "json",
      data: {
        meetId: meetId,
      },
    })

    return result
  } catch (error) {
    console.error(error)
  }
}

async function setMeetEntries(meet, returnEntriesString) {
  try {
    const data = await $.ajax({
      url: gen(`/api/setMeetEntriesPost`),
      method: "POST",
      dataType: "json",
      data: {
        meet: meet,
        entriesString: returnEntriesString,
      },
    })
    return data
  } catch (error) {
    console.error(error)
  }
}

async function mergeBack2(meet, entries, meetId) {
  if (!meet) {
    message.error("No meet")
    return
  }

  let returnEntriesString = entries
    .map((el) => {
      return `${el.id},${el.swim_event_id},${el.updated_lane},${el.updated_heat}`
    })
    .join("|")

  await setMeetEntries(meet, returnEntriesString)
  await moveEntries(meetId)
}

async function doTheWholeDeal(meetId, condense, history) {
  if (!meetId) {
    return
  }

  const data = await getMeetEntries(meetId)

  let { entries, meet, clubLanes } = data
  mergeAll(entries, meet, clubLanes, condense)

  await mergeBack2(meet, entries, meetId)

  history.push(`/app/club/heatSheet2/${meetId}`)
}
/*
End Copy
*/

async function saveMeet(data) {
  try {
    const response = await fetch(gen("/api/saveMeet5Post"), {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
      method: "post",
      body: JSON.stringify(data),
    })
    const resultData = await response.json()
    return resultData
  } catch (error) {
    console.error(error)
  }
}

class HeatSheetModalInner extends React.Component {
  state = {
    meetInfoArr: {},
    meetClubs: [],
    laneAssignmentSelection: "1",
    selectedLanes: "1",
    laneAssignmentTypesSelection: "1",
    laneMapping: {},
    confirmLineup: false,
    oneSidedHeatSheet: false,
    notAutoCondense: false,

    loading: false,
  }
  componentDidMount() {
    this.refreshState()
  }
  saveFieldsAndRefresh = async () => {
    let {
      laneAssignmentSelection,
      selectedLanes,
      laneAssignmentTypesSelection,
      laneMapping,
      oneSidedHeatSheet,
      notAutoCondense,
    } = this.state
    let clubList = this.state.meetClubs.map((el) => el.club_id)
    let isValid = true
    if (this.state.laneAssignmentTypesSelection === "1") {
      let laneIssues = false
      for (let i = 1; i <= Number(selectedLanes); i++) {
        if (!laneMapping[i]) {
          isValid = false
          laneIssues = true
          this.setState({ laneMappingError: "Select club for all lanes" })
        }
      }
      let laneMappingValues = Object.values(laneMapping)

      for (let i = 0; i < clubList.length; i++) {
        let curClub = clubList[i]
        let inThere = laneMappingValues.includes(curClub)
        if (!inThere) {
          isValid = false
          laneIssues = true
          this.setState({ laneMappingError: "Some clubs aren't in any lanes" })
        }
      }

      if (!laneIssues) {
        this.setState({ laneMappingError: "" })
      }
    }

    let data = {
      laneAssignmentSelection,
      selectedLanes,
      laneAssignmentTypesSelection,
      laneMapping,
      one_sided_heat_sheet: oneSidedHeatSheet,
      meet_id: this.props.meetId,
      club_id: this.props.clubId,
      clubId: this.props.clubId,
      confirm_lineup: this.state.confirmLineup,
    }

    if (!isValid) {
      return
    }

    let condense = !(notAutoCondense && laneAssignmentTypesSelection === "1")

    this.setState({ loading: true })

    const resultData = await saveMeet(data)

    this.setState({ loading: false })
    if (resultData.error) {
      message.error(
        "There was an error entering this meet. Contact Swimmingly.",
      )
    } else {
      let allGood = this.allGoodThenNavigate()

      if (allGood) {
        let meet_id = this.props.meetId
        this.setState({ loading: true })
        await doTheWholeDeal(meet_id, condense, this.props.history)
        this.setState({ loading: false })
      } else {
        window.location.reload()
      }
    }
  }
  refreshState = () => {
    // Do a thing that sets up meetInfoArr
    $.ajax({
      url: gen("/api/getHeatSheetView2Post"),
      method: "POST",
      dataType: "json",
      data: { meetId: this.props.meetId, user: this.props.user },
    }).done((data) => {
      let { meetInfoArr, meetClubs, meetLanes } = data

      if (meetLanes) {
        if (String(meetInfoArr.laneassigmenttype) === "1") {
          let laneMapping = {}
          for (let i = 0; i < meetLanes.length; i++) {
            let curVal = meetLanes[i]
            laneMapping[curVal.lane_number] = curVal.club_id
          }

          this.setState({ laneMapping })
        }
      }
      let club = meetClubs.find((el) => el.club_id === this.props.clubId)
      if (club) {
        this.setState({ confirmLineup: club.confirm_lineup })
      }

      this.setState({
        meetInfoArr: meetInfoArr,
        meetClubs: meetClubs,
        selectedLanes: String(meetInfoArr.lanes),
        laneAssignmentSelection: String(meetInfoArr.lanesassignemnt),
        laneAssignmentTypesSelection: String(meetInfoArr.laneassigmenttype),
        oneSidedHeatSheet: meetInfoArr.one_sided_heat_sheet,
      })
    })
  }
  renderRow = (club) => {
    let arrayMeet = []
    arrayMeet.push(<td key={club.name}>{club.name}</td>)

    if (club.heat_sheet_accepted === 1) {
      arrayMeet.push(
        <td colSpan="4" align="center" key="Accepted">
          <img src={heatsheetAccepted} height="38" width="90%" alt="Accepted" />
        </td>,
      )
    } else if (club.confirm_lineup === 1) {
      arrayMeet.push(
        <td colSpan="4" align="center" key="LineUp">
          <img src={lineupSet} height="38" width="90%" alt="Line up set" />
        </td>,
      )
    } else {
      arrayMeet.push(
        <td colSpan="4" align="center" key="Meet Created">
          <img src={meetCreated} height="38" width="90%" alt="Meet Created" />
        </td>,
      )
    }

    if (club.confirm_lineup === 1 && club.heat_sheet_accepted === 1) {
      arrayMeet.push(
        <td colSpan="2" align="center" key="ready">
          <img src={imageTrue} width="20px" alt="ready to go" />
        </td>,
      )
    } else {
      arrayMeet.push(
        <td colSpan="2" align="center" key="not ready">
          <img src={imageFalse} width="20px" alt="not ready" />
        </td>,
      )
    }

    return <tr key={club.name}>{arrayMeet}</tr>
  }

  handleCheckboxChange = (i, clubId) => {
    let laneMapping = this.state.laneMapping
    if (laneMapping[i + 1] === clubId) {
      laneMapping[i + 1] = null
    } else {
      laneMapping[i + 1] = clubId
    }
    this.setState({ laneMapping })
  }

  renderCheckbox = (clubId, row) => {
    let mine = []
    for (let i = 0; i < Number(this.state.selectedLanes); i++) {
      mine.push(
        <input
          type="checkbox"
          value={i + 1}
          id={`checkbox${row}${i}`}
          key={`checkbox${row}${i}`}
          className="laneCheckBoxes"
          checked={this.state.laneMapping[i + 1] === clubId}
          onChange={() => this.handleCheckboxChange(i, clubId)}
        />,
      )
      mine.push(
        <label htmlFor={`checkbox${row}${i}`} key={i}>
          {i + 1}
        </label>,
      )
    }
    return mine
  }

  renderRow2 = (clubId, row) => {
    let meet_clubs = this.state.meetClubs
    let val = meet_clubs.find((el) => el.club_id === clubId)
    let name = val ? val.name : clubId

    return (
      <tr key={clubId}>
        <td key={clubId + "0"} width="200">
          {name}
        </td>
        <td key={clubId + "1"}>{this.renderCheckbox(clubId, row)}</td>
      </tr>
    )
  }

  allGoodThenNavigate = () => {
    let allGood = true

    for (let i = 0; i < this.state.meetClubs.length; i++) {
      let club = this.state.meetClubs[i]
      if (club.confirm_lineup !== 1 || club.heat_sheet_accepted !== 1) {
        allGood = false
      }
    }
    return allGood
  }

  renderButtonText = () => {
    if (this.allGoodThenNavigate()) {
      return "Merge Heat Sheet"
    }
    return "Save Status"
  }

  render() {
    return (
      <Modal
        visible={this.props.visible}
        title="Heat Sheet Status"
        width="700px"
        style={{ top: -60 }}
        onCancel={() => this.props.changeHeatSheetVisibility(false)}
        footer={[
          <Button
            type="default"
            key="Cancel"
            onClick={() => this.props.changeHeatSheetVisibility(false)}
          >
            Cancel
          </Button>,
          <Button
            type="primary"
            loading={this.state.loading}
            key="Ok"
            onClick={this.saveFieldsAndRefresh}
          >
            {this.renderButtonText()}
          </Button>,
        ]}
      >
        <p>
          For heat and lane assignments, double check your info below. Once all
          clubs are "heat sheet ready", you can merge the heat sheet! You can
          save and go back to revise your lineup anytime before the heat sheet
          is created.
        </p>
        <table className="scroll" style={{ width: "100%" }}>
          <thead>
            <tr align="center">
              <th>Club</th>
              <th>Meet Created</th>
              <th>Line Up Started</th>
              <th>Line Up Set</th>
              <th>Heat Sheet</th>
              <th>Ready</th>
            </tr>
          </thead>
          <tbody className="scrollContent">
            {this.state.meetClubs.map((club) => this.renderRow(club))}
          </tbody>
        </table>
        <table id="tblLanes" width="100%">
          <tbody>
            <tr>
              <td>Sort Heats</td>
              <td>
                <select
                  value={this.state.laneAssignmentSelection}
                  onChange={(e) =>
                    this.setState({ laneAssignmentSelection: e.target.value })
                  }
                >
                  <option value={"1"}>Fastest to Slowest</option>
                  <option value={"2"}>Slowest to Fastest</option>
                </select>
              </td>
            </tr>
            <tr>
              <td>Number of Lanes</td>
              <td align="left">
                <select
                  value={this.state.selectedLanes}
                  onChange={(e) =>
                    this.setState({ selectedLanes: e.target.value })
                  }
                >
                  <option value={"1"}>1 Lane</option>
                  <option value={"2"}>2 Lane</option>
                  <option value={"3"}>3 Lane</option>
                  <option value={"4"}>4 Lane</option>
                  <option value={"5"}>5 Lane</option>
                  <option value={"6"}>6 Lane</option>
                  <option value={"7"}>7 Lane</option>
                  <option value={"8"}>8 Lane</option>
                  <option value={"9"}>9 Lane</option>
                  <option value={"10"}>10 Lane</option>
                </select>
              </td>
            </tr>
            <tr>
              <td>Lane Assignments</td>
              <td>
                <select
                  value={this.state.laneAssignmentTypesSelection}
                  onChange={(e) =>
                    this.setState({
                      laneAssignmentTypesSelection: e.target.value,
                    })
                  }
                >
                  <option value={"1"}>Fixed</option>
                  <option value={"2"}>By Time</option>
                </select>
              </td>
            </tr>
            <tr>
              <td />
              <td />
            </tr>
          </tbody>
        </table>
        {this.state.laneAssignmentTypesSelection === "1" && (
          <div id="divMeetlines">
            <table id="MeetClubLanes">
              <tbody>
                {this.state.meetClubs.map((meet_club, i) => {
                  return this.renderRow2(meet_club.club_id, i)
                })}
              </tbody>
            </table>
            <div id="lane_error">{this.state.laneMappingError}</div>
          </div>
        )}

        {this.state.laneAssignmentTypesSelection === "1" &&
          this.allGoodThenNavigate() && (
            <div
              style={{
                display: "flex",
                fontWeight: "700",
                fontSize: "25px",
                marginTop: "20px",
              }}
            >
              <div>
                {"Turn off auto-condense heat sheet"}
                <div style={{ fontWeight: 400, fontSize: "12px" }}>
                  {" "}
                  Only check this if you want unused lanes in a heat
                </div>
              </div>
              <div
                style={{
                  marginLeft: "15px",
                  transform: "scale(2)",
                  MsTransform: "scale(2)",
                  WebkitTransform: "scale(2)",
                  OTransform: "scale(2)",
                  MozTransform: "scale(2)",
                }}
              >
                <input
                  type="checkbox"
                  checked={this.state.notAutoCondense}
                  onChange={(e) =>
                    this.setState({ notAutoCondense: e.target.checked })
                  }
                />
              </div>
            </div>
          )}

        <div
          style={{
            display: "flex",
            fontWeight: "700",
            fontSize: "25px",
            marginTop: "20px",
          }}
        >
          <div>
            {"One sided heatsheet"}
            <div style={{ fontWeight: 400, fontSize: "12px" }}>
              {" "}
              Only check this if you want to hide swimmers from view on opposing
              team(s)
            </div>
          </div>
          <div
            style={{
              marginLeft: "15px",
              transform: "scale(2)",
              MsTransform: "scale(2)",
              WebkitTransform: "scale(2)",
              OTransform: "scale(2)",
              MozTransform: "scale(2)",
            }}
          >
            <input
              type="checkbox"
              checked={this.state.oneSidedHeatSheet}
              onChange={(e) =>
                this.setState({ oneSidedHeatSheet: e.target.checked })
              }
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            fontWeight: "700",
            fontSize: "25px",
            marginTop: "20px",
          }}
        >
          <div>{"My club is heat sheet ready"}</div>
          <div
            style={{
              marginLeft: "15px",
              transform: "scale(2)",
              MsTransform: "scale(2)",
              WebkitTransform: "scale(2)",
              OTransform: "scale(2)",
              MozTransform: "scale(2)",
            }}
          >
            <input
              type="checkbox"
              checked={this.state.confirmLineup}
              onChange={(e) =>
                this.setState({ confirmLineup: e.target.checked })
              }
            />
          </div>
        </div>

        <br />
      </Modal>
    )
  }
}

const HeatSheetModal = withRouter(HeatSheetModalInner)

class ConfirmHeatSheet extends React.Component {
  state = {
    loading: false,
  }
  btnHeatSheetCancel = () => {
    this.props.changeConfirmVisibility(false)
  }

  btnHeatSheetAccepted = async () => {
    this.setState({ loading: true })
    try {
      // console.log(this.props.clubId, this.props.meetId, 1)
      await $.ajax({
        url: gen("/api/confirmHeatLineUpPost"),
        method: "POST",
        dataType: "json",
        data: {
          clubId: this.props.clubId,
          meet_id: this.props.meetId,
          heat_sheet_accepted: 1,
        },
      })
      this.props.changeConfirmVisibility(false)
      this.props.changeHeatSheetVisibility(true)
      this.setState({ loading: false })
    } catch (error) {
      console.error(error)
      message.error(
        "There was an error displaying the heatsheet. Contact swimmingly",
      )
      this.props.changeConfirmVisibility(false)
      this.setState({ loading: false })
    }
  }

  render() {
    return (
      <Modal
        title="By Clicking 'OK' below"
        onCancel={this.btnHeatSheetCancel}
        footer={[
          <Button type="default" key="Cancel" onClick={this.btnHeatSheetCancel}>
            Cancel
          </Button>,
          <Button
            type="primary"
            loading={this.state.loading}
            key="Ok"
            onClick={this.btnHeatSheetAccepted}
          >
            Ok
          </Button>,
        ]}
        visible={this.props.visible}
      >
        <p>
          1. Your swimmers will receive heat and lane assignments when you're
          ready. <br />
          2. See status of other clubs in the meet. <br />
          3. Choose the number of lanes in your meet. It will default to the
          number of lanes in the home team's pool automatically.
          <br />
          4. Sort your events fastest-to-slowest or slowest-to-fastest.
          <br />
          5. Assign lanes by time or fixed.
          <br />
          6. Create a heat sheet where you can either see all the clubs or only
          your club.
        </p>
      </Modal>
    )
  }
}

export default class MergeHeatSheetButton extends React.Component {
  state = {
    confirmHeatSheetVisible: false,
    heatSheetModalVisible: false,
  }

  btnMergeHeatSheet = () => {
    this.setState({ confirmHeatSheetVisible: true })
  }

  render() {
    return (
      <div>
        <Button type="primary" onClick={this.btnMergeHeatSheet}>
          Assign Heat/Lanes <RightOutlined />
        </Button>
        <ConfirmHeatSheet
          meetId={this.props.meetId}
          clubId={this.props.clubId}
          visible={this.state.confirmHeatSheetVisible}
          changeConfirmVisibility={(val) =>
            this.setState({ confirmHeatSheetVisible: val })
          }
          changeHeatSheetVisibility={(val) =>
            this.setState({ heatSheetModalVisible: val })
          }
        />
        <HeatSheetModal
          meetId={this.props.meetId}
          clubId={this.props.clubId}
          visible={this.state.heatSheetModalVisible}
          changeHeatSheetVisibility={(val) =>
            this.setState({ heatSheetModalVisible: val })
          }
        />
      </div>
    )
  }
}
