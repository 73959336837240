import { FunctionComponent, useEffect, useState } from "react"
import { IClubSeasonInfo } from "../utils"
import { RegistrationFormContextProvider, useRegistrationFormContext,  } from "./sharedState"
import { IRegistrationClubDue, IWaiver,IPriceModificationCode, useClubSeasonManagementContext } from "../ClubSeasonManagement/sharedState"
import { StyledRegistrationForm } from "./styles"
import { WaiversAndDisclosures } from "./WaiversAndDisclosures"
import { ISeasonPricingProps, SeasonPricing } from "./SeasonPricing"
import { AdditionalItemsPreview } from "./AdditionalItemsPreview"
import { GuardianWaiverResponse } from "../ClubSeasonManagement/RegistrationConfiguration/RegistrationHelper"
import { GuardianWaiverMonitor } from "../Helpers/GuardianWaiverMonitor"
import { Alert } from "antd"

export interface IRegistrationFormProps  {
  selectedClubSeason: IClubSeasonInfo
  pricePoints: IRegistrationClubDue[]
  waivers: IWaiver[]
  waiverResponses?: Record<string,GuardianWaiverResponse>
  waiverMonitor?: GuardianWaiverMonitor
  previewMode: boolean
  children: React.ReactNode
}

interface IRegistrationFormInnerProps {
  selectedClubSeason: IClubSeasonInfo
  pricePoints: IRegistrationClubDue[]
  description: string
  waiverResponses?: Record<string,GuardianWaiverResponse>
  waiverMonitor?: GuardianWaiverMonitor
  additionalItems: IPriceModificationCode[] 
}

const RegistrationFormInner: FunctionComponent<IRegistrationFormInnerProps> = ({ selectedClubSeason,pricePoints, description, additionalItems, waiverResponses, waiverMonitor}) => {
  const { previewMode } = useRegistrationFormContext()

  return (
    <div>
      <p>
        {description}
      </p>
      <hr style={previewMode ? {} : {visibility:"hidden"}} />
      <AdditionalItemsPreview additonalItems={previewMode ? additionalItems : []} />
      <hr />
      <WaiversAndDisclosures
            waiverResponses={waiverResponses}
            waiverMonitor={waiverMonitor}
            selectedClubSeason={selectedClubSeason}
       />
      <hr />
      <SeasonPricing
        selectedClubSeason={selectedClubSeason}
        pricePoints={pricePoints}
      />
      <hr/>
    </div>
  )
}

export const RegistrationForm: FunctionComponent<IRegistrationFormProps> = ({
  previewMode,
  selectedClubSeason,
  pricePoints,
  waivers,
  waiverResponses,
  waiverMonitor,
  children
}) => {
  const [additionalItems, setAdditionalItems] = useState<IPriceModificationCode[]>([])
  const { priceModificationCodes } = useClubSeasonManagementContext()

  useEffect(() => {
    setAdditionalItems(
      priceModificationCodes.filter(
        (priceModificationCode) =>  priceModificationCode.priceModificationAmount >= 0 && priceModificationCode.isActive
      ),
    )
  }, [priceModificationCodes])
  
  return (
    <RegistrationFormContextProvider
      clubId={selectedClubSeason.clubId}
      seasonId={selectedClubSeason.seasonId}
      selectedClubSeason={selectedClubSeason}
      previewMode={previewMode}
      waivers={waivers}
      waiverResponses={waiverResponses}
    >
      
      {
        previewMode ? (
          <Alert
          message="Preview Registration"
          description="This is a preview of what guardians will see when registering"
          type="info"
          showIcon />
    
        ) : (<></>)
      }

      <StyledRegistrationForm>
        <RegistrationFormInner 
          description="Please complete any waivers below and proceed to register your swimmers. Registration includes Swimmingly® membership, which gives you access to your swimmer's athlete profile (meet results, best times, and time improvements) through Clubhouse and access to SwimminglyFan! All team and meet management for your club's leadership is also included to make sure your season goes swimmingly."
        selectedClubSeason={selectedClubSeason}
        additionalItems={additionalItems}   
        pricePoints={pricePoints}
        waiverMonitor={waiverMonitor}
        waiverResponses={waiverResponses}
  
        />
      </StyledRegistrationForm>
    </RegistrationFormContextProvider>
  )
}
