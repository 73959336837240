import { createGlobalStyle } from "styled-components"

export const screenSizes = {
  extraSmall: 650,
  small: 900,
  medium: 1200,
  big: 1550,
}

const GlobalStyles = createGlobalStyle`
  :root {
    --primaryblue: #25bfea;
    --linkblue: #1b9df1;
    --buttonblue: #1890ff;
    --tableblue: rgb(183, 228, 247,0.5);
    --deepdarkblue: rgb(16,18,66);
    --white: #ffffff;
    --snow: #fafafa;
    --lightgrey: #ecedf0;
    --mediumgrey: #cccccc;
    --darkgrey: #8c8c8c;
    --black: #2E2E2E;
    --cream: #FFFDD0;
    --approvalgreen: #52c41a;
    --rejectionred: #b94a48;
    --rejectionbackground: #ffc2c2;
  }

  html,
  body {
    height: 100%;
  }

  .page-header {
    background: var(--primaryblue);
    border-bottom: none;
    border-left: 1px solid #3a3a3a;
    box-shadow: 1px 3px 0 1px var(--mediumgrey);
    height: 50px;
    margin: -40px -40px 40px -40px;
    padding: 0;
    z-index: 8;

    h2 {
      color: #fff;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
      display: block;
      float: left;
      height: 50px;
      font-size: 20px;
      font-size: 2rem;
      letter-spacing: normal;
      line-height: 50px;
      margin: 0 0 0 -1px;
      padding: 0 22px 0 20px;
    }
  }

  .ant-picker-dropdown {
    z-index: 100000000;
  }

  @media only screen and (max-width: ${screenSizes.small}px) {
      .page-header {
        margin: 0 -15px 20px;
        padding-right: 80px;
      }

      .page-header h2 {
        font-size: 16px;
        padding: 0 15px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
      }
    }

`

export default GlobalStyles
