import styled from "styled-components"

export const StyledInvoice = styled.table`
  /* border-collapse: collapse; */
  width: 95%;
  margin: auto;
  margin-top: 15px;

  thead tr th:first-child {
    border-top-left-radius: 5px;
  }
  thead tr th:last-child {
    border-top-right-radius: 5px;
  }

  tbody {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  tbody tr:last-child td:first-child {
    border-bottom-left-radius: 5px;
  }
  tbody tr:last-child td:last-child {
    border-bottom-right-radius: 5px;
  }
  tbody tr:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  tbody tr {
    border: 1px solid var(--mediumgrey);
  }

  td,
  th {
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #25bfea70;
    color: white;
  }
`

export const StyledNameInput = styled.input`
  margin-left: 35px;
  width: 500px;
  max-width: 90%;
`

export const StyledArrowImage = styled.img`
  width: 20px;
  height: 15px;
`
