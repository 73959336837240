import { gen, IClub, swimminglyApi } from "../utils"
import { IParentsMeet } from "./ParentMeetScheduleInner"

interface IMeetScheduleForParents {
    eventsGroupedByYear: { [key: string]: IParentsMeet[] }
    clubsByClubId: { [key: string]: IClub }
    guardianClubIds: number[]
}

export class ParentMeetScheduleHelper {
    api = swimminglyApi

    public constructor(api = swimminglyApi) {
        this.api = api
    }

    public async getMeetScheduleForParents(userId?: number): Promise<IMeetScheduleForParents> {
        return this.api.post(gen("/api/getMeetScheduleForParents"))
            .body({
                userId: userId,
            })
    }

}  