import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Button,
  Divider,
  Modal,
  Select,
  Input,
  Popconfirm,
  message,
} from "antd"
import AddEventOrderModal from "./AddEventOrderModal"
import AddEventModal from "./AddEventModal"
import DragSortingTable from "./ReactDragAndDropTable"
import { gen, CallbackNoArg, swimminglyApi } from "./utils"
import {
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons"

const { Option } = Select

interface IEventOrdersProps {
  club?: {
    clubId: number
  }
  league?: {
    id: number
  }
}

interface IEventOrderRow {
  id: number
  name: string
}

export default function EventOrders({ league, club }: IEventOrdersProps) {
  const [events, setEvents] = useState([])
  const [eventOrdersArr, setEventOrdersArr] = useState<Array<IEventOrderRow>>(
    [],
  )
  const [eventOrderId, setEventOrderId] = useState<number | null>(null)
  const [acrhiveInProcess, setAcrhiveInProcess] = useState(false)

  const [editEventModal, setEditEventModal] = useState(false)
  const [editEventOrderName, setEditEventOrderName] = useState("")

  const getEventOrders = async (): Promise<void> => {
    let requestBody: { [key: string]: any } = {}
    let requestUrl = ""

    if (club) {
      requestBody.clubId = club.clubId
      requestUrl = gen("/api/getClubEventOrders")
    } else if (league) {
      requestBody.league_id = league.id
      requestUrl = gen("/api/getUsersEventOrders2Post")
    }

    await fetch(requestUrl, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      credentials: "same-origin",
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then(async (data) => {
        let eventOrderArr = data.eventOrderArray
        let lastOne =
          eventOrderArr.length > 0
            ? eventOrderArr[eventOrderArr.length - 1].id
            : null
        let name = getName(lastOne, eventOrderArr)
        setEventOrdersArr(eventOrderArr)
        setEventOrderId(lastOne)
        setEditEventOrderName(name || "")
        getEvents(lastOne, undefined)
      })
  }

  const getEvents = async (
    theEventOrder: number | null,
    cb: CallbackNoArg | undefined,
  ) => {
    console.log(theEventOrder)
    fetch(gen("/api/getUsersEvents2Post"), {
      method: "post",
      headers: { "Content-Type": "application/json" },
      credentials: "same-origin",
      body: JSON.stringify({
        eventOrderId: String(theEventOrder || eventOrderId),
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        if (cb) {
          setEvents(data.eventArray)
          cb()
        } else {
          setEvents(data.eventArray)
        }
      })
  }

  const deleteEvent = (id: number) => {
    fetch(gen("/api/deleteUserEventPost"), {
      method: "post",
      headers: { "Content-Type": "application/json" },
      credentials: "same-origin",
      body: JSON.stringify({ id }),
    })
      .then((response) => response.json())
      .then((data) => {
        getEvents(null, () => resortEvents(events, getEvents as CallbackNoArg))
      })
  }

  const resortEvents = (events: Array<any>, cb: CallbackNoArg | undefined) => {
    if (events.length > 0) {
      fetch(gen("/api/sortUserEventsPost"), {
        method: "post",
        headers: { "Content-Type": "application/json" },
        credentials: "same-origin",
        body: JSON.stringify({ order: events }),
      })
        .then((response) => response.json())
        .then(() => {
          if (cb) {
            cb()
          }
        })
    }
  }

  useEffect(() => {
    let mounted = true
    let requestBody: { [key: string]: any } = {}
    let requestUrl = ""

    if (club) {
      requestBody.clubId = club.clubId
      requestUrl = gen("/api/getClubEventOrders")
    } else if (league) {
      requestBody.league_id = league.id
      requestUrl = gen("/api/getUsersEventOrders2Post")
    }

    swimminglyApi
      .post(requestUrl)
      .body(requestBody)
      .then((data) => {
        let eventOrderArr = data.eventOrderArray
        if (mounted) {
          setEventOrdersArr(eventOrderArr)
          swimminglyApi
            .post(gen("/api/getUsersEvents2Post"))
            .body({
              eventOrderId: String(eventOrderId),
            })
            .then((data) => setEvents(data.eventArray))
        }
      })
    return () => {
      mounted = false
    }
  }, [club, eventOrderId, league])

  const changeIt = (val: number) => {
    let name = getName(val, eventOrdersArr)
    setEventOrderId(val)
    setEditEventOrderName(name || "")
  }

  const archive = () => {
    setAcrhiveInProcess(true)
    fetch(gen("/api/archiveEventOrderPost"), {
      method: "post",
      headers: { "Content-Type": "application/json" },
      credentials: "same-origin",
      body: JSON.stringify({ eventOrderId }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        await getEventOrders()
        setAcrhiveInProcess(false)
      })
  }

  const getName = (
    eventOrderId: number,
    eventOrdersArr: Array<{ name: string; [key: string]: any }>,
  ) => {
    for (let i = 0; i < eventOrdersArr.length; i++) {
      if (eventOrdersArr[i].id === eventOrderId) {
        return eventOrdersArr[i].name
      }
    }
  }

  const saveName = (eventOrderId: number | null, newName: string | null) => {
    if (!newName) {
      message.error("You must name your Event Order!")
    }
    if (!eventOrderId) {
      message.error("Must have an event order id")
    }

    fetch(gen("/api/editEventOrderName"), {
      method: "post",
      headers: { "Content-Type": "application/json" },
      credentials: "same-origin",
      body: JSON.stringify({ eventOrderId, newName }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        await getEventOrders()
        setEventOrderId(eventOrderId)
      })
  }

  return (
    <div>
      {/*<Row type="flex" align="middle">*/}
      <Row align="top">
        <Col span={4}><h4>Select Existing Event Order:</h4></Col>
        <Col span={10}>
          <Select
            value={eventOrderId || undefined}
            onChange={changeIt}
            style={{ width: "95%" }}
          >
            {eventOrdersArr.map((el) => (
              <Option value={el.id} key={el.id}>
                {el.name}
              </Option>
            ))}
          </Select>
          <div
            style={{
              display: "flex",
              alignItems: "left",
              cursor: "pointer",
              marginTop: "10px",
            }}
          >
            <EditOutlined
              onClick={() => setEditEventModal(true)}
              style={{ color: "#25bfea", fontSize: "20px" }}
            />
            <Button type="link" onClick={() => setEditEventModal(true)}>
              Edit Name of Selected Event Order
            </Button>
            </div>
            <div>
            <DeleteOutlined
              style={{ color: "#25bfea", fontSize: "20px" }}
            />
          <Popconfirm
            id="archiveEvent"
            title={
              <div>
                Are you sure you want to archive the  <br />
                event order currently selected in the <br />
                event order dropdown? Doing so will   <br />
                remove this event order from any future<br />
                use when creating swim meets.
              </div>
            }
            okText="Yes"
            cancelText="No"
            onConfirm={archive}
          >
            <Button type="link" loading={acrhiveInProcess}>Archive Selected Event Order</Button>
          </Popconfirm>
          </div>
        </Col>
        <Col span={1} />
        <Col span={1}>
          &nbsp;<b>Or</b>
        </Col>
        <Col span={1} />
        <Col span={6}>
        
          <AddEventOrderModal
            club={club}
            league={league}
            getEventOrders={getEventOrders}
          />
        </Col>
        
      </Row>

      <Divider />

      <AddEventModal
        getEvents={getEvents}
        club={club}
        league={league}
        eventOrderId={eventOrderId}
      />

      <DragSortingTable
        data={events}
        deleteEvent={deleteEvent}
        resortEvents={resortEvents}
        getEvents={getEvents}
      />

      <Modal
        title="Edit Name of Event Order"
        visible={editEventModal}
        onOk={() => {
          saveName(eventOrderId, editEventOrderName)
          setEditEventModal(false)
        }}
        onCancel={() => setEditEventModal(false)}
      >
        <Input
          type="text"
          value={editEventOrderName}
          onChange={(e) => setEditEventOrderName(e.target.value)}
        />
      </Modal>
    </div>
  )
}
