import { useState, useEffect } from "react"
import { IClub, IUser } from "../utils"
import ParentMeetScheduleInner, { IParentsMeet } from './ParentMeetScheduleInner'
import ContentPanel from "../ContentPanel"
import { ParentMeetScheduleHelper } from "./ParentMeetScheduleHelper"


interface IParentMeetScheduleProps {
    club: IClub | null
    isParent: Boolean
    user: IUser | null
}

export default function ParentMeetSchedule({ club, isParent, user }: IParentMeetScheduleProps) {   
    const [guardianMeets, setGuardianMeets] = useState<{[key: string]: IParentsMeet[]}>({})
    const [clubsByClubId, setClubsByClubId] = useState<{[key: string]: IClub}>({})
    const [guardianClubIds, setGuardianClubIds] = useState<number[]>([])

    useEffect(() => {
        new ParentMeetScheduleHelper()
            .getMeetScheduleForParents(user?.userId)
            .then((data) => {
                setClubsByClubId(data.clubsByClubId)
                setGuardianMeets(data.eventsGroupedByYear)
                setGuardianClubIds(data.guardianClubIds)

            })

    }, [club, isParent, user])

    return (
        <div>
            <header className="page-header">
                <h2>Meet Schedule</h2>
            </header>
            <ContentPanel
            >
            <ParentMeetScheduleInner    
                eventsGroupedByYear={guardianMeets}
                clubsByClubId={clubsByClubId}
                guardianClubIds={guardianClubIds}         
            />
            </ContentPanel>
        </div>
    )
}
