import { Card, Button, Divider } from "antd";
import { CheckSquareTwoTone } from "@ant-design/icons";
import PremiumRegistration from "../../images/HappyCampers.png";
import { CSSProperties } from "react";

const bodyStyle: CSSProperties = {
  backgroundColor: '#f9f9f9',
  padding: "10px 15px 10px 15px",
  fontFamily: '"Open Sans", Arial, sans-serif',
  fontSize: '18px',
  textAlign: 'left',
  border: '1px solid lightgray',
};

const headStyle: CSSProperties = {
  backgroundColor: '#8de2f5',
  padding: '5px',
  fontFamily: '"Open Sans", Arial, sans-serif',
  fontSize: '16px',
  fontWeight: 'bold',
};

interface PremiumRegistrationCardProps {
  cardStyle: CSSProperties;
  chooseRegistration: (isPremium: boolean, seasonId: number) => void;
  setReviewRegistration: (value: boolean) => void;
  selectedClubSeason: {
    seasonId: number;
    usingRegistration?: boolean;
    paymentType?: string;
  };
}

const PremiumRegistrationCard: React.FC<PremiumRegistrationCardProps> = ({ cardStyle, chooseRegistration, setReviewRegistration, selectedClubSeason }) => {
  const handleCardClick = () => {
    chooseRegistration(true, selectedClubSeason.seasonId);
    setReviewRegistration(false);
  };

  return (
    <Card 
      style={{ ...cardStyle, cursor: 'pointer' }} 
      headStyle={headStyle}
      cover={
        <img
          alt="PREMIUM Club Registration"
          src={PremiumRegistration}
          style={{
            width: "33%",
            margin: "2% 50% 0% 30%",
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center'
          }}
        />
      }
      title="PREMIUM Club Registration (no additional cost)"
      hoverable={true}
      bordered={true}
      onClick={handleCardClick}
    >
      <Card.Meta
        avatar={<CheckSquareTwoTone twoToneColor="#52c41a" />}
        description={<>Full Swimmingly® app access featuring in-app timing, officiating, & scoring. <a href="https://splash.swimmingly.app/benefits">Benefits</a></>}
        style={bodyStyle}
      />
      <Card.Meta
        avatar={<CheckSquareTwoTone twoToneColor="#52c41a" />}
        description={<>Parent/guardians register their swimmers for full membership & benefits in both the Clubhouse and SwimminglyFan™. <a href="https://splash.swimmingly.app/benefits">Benefits</a></>}
        style={bodyStyle}
      />
      <Card.Meta
        avatar={<CheckSquareTwoTone twoToneColor="#52c41a" />}
        description={<>Full league & club admins membership & benefits in the Clubhouse. <a href="https://splash.swimmingly.app/benefits">Benefits</a></>}
        style={bodyStyle}
      />
      <Card.Meta
        avatar={<CheckSquareTwoTone twoToneColor="#52c41a" />}
        description={<>Coaches can open Meet Declarations to parents/guardians and optionally allow them to complete their swimmers' individual entries per meet. <a href="https://splash.swimmingly.app/benefits">Benefits</a></>}
        style={bodyStyle}
      />
      <Card.Meta
        avatar={<CheckSquareTwoTone twoToneColor="#52c41a" />}
        description="Team Communication"
        style={bodyStyle}
      />
      <Card.Meta
        avatar={<CheckSquareTwoTone twoToneColor="#52c41a" />}
        description={<>Customize your club's registration dues & collect payments directly from your swim families here in the Swimmingly Clubhouse. <a href="https://splash.swimmingly.app/premium-club-registration">Learn more</a></>}
        style={bodyStyle}
      />
      <Card.Meta
        avatar={<CheckSquareTwoTone twoToneColor="#52c41a" />}
        description={<>Customize waivers & disclosures for your club - use ready-made templates or copy and paste your own. <a href="https://splash.swimmingly.app/premium-club-registration">Learn more</a></>}
        style={bodyStyle}
      />
      <Card.Meta
        avatar={<CheckSquareTwoTone twoToneColor="#52c41a" />}
        description={<>Add additional checkout items like team shirts, trophies, towels, city/champs meet entries, and more - and generate more revenue for your club. <a href="https://splash.swimmingly.app/premium-club-registration">Learn more</a></>}
        style={bodyStyle}
      />
      <Card.Meta
        avatar={<CheckSquareTwoTone twoToneColor="#52c41a" />}
        description={<>Pause/Resume/Close PREMIUM Registration on-demand at anytime. <a href="https://splash.swimmingly.app/premium-club-registration">Learn more</a></>}
        style={bodyStyle}
      />
      <Divider />
      <Button
        type="primary"
        block={true}
        size="large"
        icon={<CheckSquareTwoTone />}
        shape="round"
        onClick={handleCardClick}
      >
        PREMIUM Club Registration
      </Button>
      <p>
        <br />
        <TextContainer>
          Upgrade to PREMIUM at no additional cost!
          <br />
          Still not sure?{" "}
          <a
            href="https://splash.swimmingly.app/premium-club-registration"
            target="_blank" rel="noreferrer"
          >
            Click here to schedule a demo & learn more.
          </a>
        </TextContainer>
      </p>
    </Card>
  );
};

export default PremiumRegistrationCard;

const TextContainer: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <p
    style={{
      textAlign: "center",
      width: "60%",
      marginRight: "auto",
      marginLeft: "auto",
    }}
  >
    {children}
  </p>
);
