import React, { useState, useEffect } from "react"
import moment, { Moment } from "moment-timezone"
import { Link } from "react-router-dom"
import { DollarCircleTwoTone, CaretUpOutlined, CaretDownOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import { Tooltip, message } from "antd"
import { FaRegIdCard } from "react-icons/fa"
import styled from "styled-components"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../appData/types"
import { IManageRosterSeasonSwimmer } from "./ManageRoster"
import {
  gen,
  IClub,
  IUser,
  swimminglyApi,
  validateUsaSwimmingId,
} from "./utils"
import { SwimmerBirthdayPicker } from "./ParentSwimmers"

const StyledCheckbox = styled.input`
  ${({ checkboxDisabled }: { checkboxDisabled: boolean }) =>
    checkboxDisabled &&
    `
    appearance:none;
    -webkit-appearance: none;
    background-color:var(--lightgrey);
    color: var(--mediumgrey);
    border: 1px solid var(--mediumgrey);
    height: 15px;
    width: 15px;
    border-radius:3px;
    cursor: not-allowed;

    `}
  &:active {
    outline: none;
    border: none;
  }

  &:focus {
    outline: none;
    border: none;
  }
`
function pad(num: number, size: number): string {
  let s = num + ""
  while (s.length < size) s = "0" + s
  return s
}

function EditUSAId({
  swimmer,
}: {
  swimmer: IManageRosterSeasonSwimmer
}): JSX.Element {
  const [editing, setEditing] = useState(false)
  const [usaSwimmingId, setUsaSwimmingId] = useState(swimmer?.usaSwimmingId)
  const [inProgressId, setInProgressId] = useState(swimmer?.usaSwimmingId || "")

  useEffect(() => {
    if (!editing) {
      setInProgressId(usaSwimmingId || "")
    }
  }, [editing, usaSwimmingId])

  const changeEditing = () => {
    setEditing(!editing)
  }

  const onEditKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Escape") {
      e.preventDefault()
      setInProgressId(usaSwimmingId || "")
      setEditing(false)
    }
    if (e.key === "Enter") {
      e.preventDefault()
      let shouldSubmit = true
      if (inProgressId.trim() === "") {
        setUsaSwimmingId(null)
        setInProgressId("")
      } else if (validateUsaSwimmingId(inProgressId.trim())) {
        setUsaSwimmingId(inProgressId.trim())
        setInProgressId(inProgressId.trim())
      } else {
        setInProgressId(usaSwimmingId || "")
        shouldSubmit = false
      }
      setEditing(false)
      if (shouldSubmit && swimmer.swimmerId) {
        swimminglyApi
          .post(gen("/api/updateSwimmerUsaId"))
          .body({
            swimmerId: swimmer.swimmerId,
            usaSwimmingId: inProgressId.trim(),
          })
          .then((data) => {
            if (data.status === "success") {
              if (swimmer.swimmerId && inProgressId.trim().length === 9) {
                message.success("Successfully updated Canadian Swimming Id!")
              } else {
                message.success("Successfully updated USA Swimming Id!")
              }
            } else {
              console.log("Something went wrong updating USA Swimming Id")
              console.error(data)
              message.error("Something went wrong updating USA Swimming Id.")
            }
          })
      } else {
        message.error("Invalid USA Swimming Id.")
      }
    }
  }

  if (editing) {
    return (
      <input
        value={inProgressId}
        onChange={(e) => setInProgressId(e.target.value)}
        onKeyDown={onEditKeyDown}
      />
    )
  }

  if (!usaSwimmingId || usaSwimmingId.trim() === "") {
    return <button onClick={changeEditing}>Add Id...</button>
  }
  return (
    <span
      onClick={changeEditing}
      style={{ cursor: "pointer", color: "dodgerblue" }}
    >
      {usaSwimmingId}
    </span>
  )
}

interface ISingleSwimmerProps {
  user: IUser | null
  swimmer: IManageRosterSeasonSwimmer
  leagueCutOff?: string | null
  showUSASwimmingId: boolean
  isParent: boolean
  isSwimminglyCustomer?: boolean
  club: IClub | null
  refreshSwimmers: () => void
  seasonEnd?: string
  canRollSwimmers: boolean
  updateRolloverSwimmers: (
    swimmerId: number,
    addOrRemove: "add" | "remove",
  ) => void
  checked: boolean
}

function SingleSwimmer({
  user,
  swimmer,
  leagueCutOff,
  showUSASwimmingId,
  isParent,
  isSwimminglyCustomer,
  club,
  refreshSwimmers,
  seasonEnd,
  canRollSwimmers,
  updateRolloverSwimmers,
  checked,
}: ISingleSwimmerProps) {
  const renderSwimmerName = (swimmer: IManageRosterSeasonSwimmer) => {
    let middle = swimmer.middleInitials || ""
    let name = swimmer.firstName + " " + middle + " " + swimmer.lastName
    if (user?.role === 5) {
      return <span>{name}</span>
    }
    return <Link to={`/app/athleteProfile/${swimmer.swimmerId}`}>{name}</Link>
  }

  const formatGuardianEmail = () => {
    if (swimmer.guardians.length === 0) {
      return <div />
    } else if (swimmer.guardians.length === 1) {
      return (
        <span>
          <a href={`mailto:${swimmer.guardians[0].guardianEmail}`}>
            {swimmer.guardians[0].guardianEmail}
          </a>
        </span>
      )
    }

    return (
      <>
        {swimmer.guardians.map((g, idx) => {
          return (
            <p
              key={`guardian_email_${g.guardianId}_${idx}`}
              style={{ marginBottom: "1px", marginTop: "1px" }}
            >
              <a href={`mailto:${g.guardianEmail}`}>{g.guardianEmail}</a>
              {idx === swimmer.guardians.length - 1 ? null : ","}
            </p>
          )
        })}
      </>
    )
  }

  const getAge = (theSwimmer: IManageRosterSeasonSwimmer) => {
    // console.log(theSwimmer, leagueCutOff)
    if (!theSwimmer.dateOfBirth) {
      return null
    }
    if (!theSwimmer.cutOffDate) {
      return moment().diff(moment(theSwimmer.dateOfBirth), "years")
    }
    return moment(theSwimmer.cutOffDate).diff(
      moment(theSwimmer.dateOfBirth),
      "years",
    )
  }

  const updateSwimmerBirthday = ({
    swimmer,
    newDate,
  }: {
    swimmer: IManageRosterSeasonSwimmer
    newDate: Moment | null
  }) => {
    if (swimmer.swimmerId && newDate !== null) {
      swimminglyApi
        .post(gen(`/api/updateSwimmer/${swimmer.swimmerId}`))
        .body({
          dateOfBirth: newDate.format("YYYY-MM-DD"),
        })
        .then((data) => {
          if (data.status === "success") {
            message.success(
              `Updated ${swimmer.firstName} ${swimmer.lastName}'s date of birth!`,
            )
            refreshSwimmers()
          } else {
            message.error("Problem updating date of birth")
          }
        })
    }
  }

  return (
    <tr
      style={swimmer.importedFromResults ? { backgroundColor: "#fffda7" } : {}}
    >
      <td>
        {moment().diff(moment(seasonEnd), "days") > 30 ? (
          <span style={{ color: "lightgray" }}>(season over)</span>
        ) : swimmer.swimmerShoulderNumber &&
          swimmer.activeMonths.includes(moment().month() + 1) ? (
          pad(swimmer.swimmerShoulderNumber, 3)
        ) : isSwimminglyCustomer === true ||
          isSwimminglyCustomer === undefined ? (
          "Inactive"
        ) : (
          pad(swimmer?.swimmerShoulderNumber || 0, 3)
        )}
      </td>
      <td>{renderSwimmerName(swimmer)}</td>
      <td>{swimmer.swimmerSeasonAge || getAge(swimmer)}</td>
      <td>
        <SwimmerBirthdayPicker
          value={swimmer.dateOfBirth ? moment(swimmer.dateOfBirth) : null}
          onChange={(newDate) => updateSwimmerBirthday({ swimmer, newDate })}
        />
      </td>
      <td>{swimmer.gender}</td>
      <td>{formatGuardianEmail()}</td>
      {showUSASwimmingId && (
        <td>
          <EditUSAId swimmer={swimmer} />
        </td>
      )}
      <td>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            columnGap: "10px",
          }}
        >
          {swimmer.signedUp ? (
            <Tooltip title="Signed Up">
              <FaRegIdCard
                style={{
                  fontSize: "2rem",
                  color: "#52c41a",
                  justifySelf: "center",
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Not Signed Up">
              <FaRegIdCard
                onClick={() =>
                  alert(
                    "A coach or club administrator has requested that " +
                      `${swimmer.firstName} ${swimmer.lastName}` +
                      " be added to the roster, but their parent/guardian" +
                      " hasn't approved the request yet by going to your club's" +
                      " sign-up link and adding their child for the current season.",
                  )
                }
                style={{
                  cursor: "pointer",
                  fontSize: "2rem",
                  color: "#f50",
                  justifySelf: "center",
                }}
              />
            </Tooltip>
          )}
          {swimmer.activeMonths.includes(moment().month() + 1) ? (
            <Tooltip title="Paid">
              <DollarCircleTwoTone
                style={{ fontSize: "2rem" }}
                twoToneColor="#52c41a"
              />
            </Tooltip>
          ) : swimmer.signedUp ? (
            <Tooltip title="Awaiting Payment">
              <DollarCircleTwoTone
                style={{ fontSize: "2rem" }}
                twoToneColor="#f50"
              />
            </Tooltip>
          ) : (
            <Tooltip title="Not Paid">
              <DollarCircleTwoTone
                style={{ fontSize: "2rem" }}
                twoToneColor="#f50"
              />
            </Tooltip>
          )}
        </div>
      </td>
      {canRollSwimmers && (
        <td>
          <Tooltip
            title={
              swimmer.guardians.length === 0
                ? "Cannot select a swimmer with no guardian in the system"
                : "Select swimmers and then click the 'Send season sign-up email to guardians' at the top of the screen"
            }
          >
            <StyledCheckbox
              type="checkbox"
              checkboxDisabled={swimmer.guardians.length === 0}
              checked={checked}
              onChange={(e) => {
                if (swimmer.guardians.length === 0) {
                  return
                }
                if (e.target.checked) {
                  updateRolloverSwimmers(swimmer.swimmerId, "add")
                } else {
                  updateRolloverSwimmers(swimmer.swimmerId, "remove")
                }
              }}
            />
          </Tooltip>
        </td>
      )}
    </tr>
  )
}

interface IClubSwimmersProps {
  swimmerArr: IManageRosterSeasonSwimmer[]
  isParent: boolean
  showUSASwimmingIds: boolean
  setShowUSASwimmingIds: (show: boolean) => void
  leagueCutOff?: string | null
  refreshSwimmers: () => void
  seasonEnd?: string
  seasonId?: number
  isSwimminglyCustomer?: boolean
  canRollSwimmers: boolean
  selectedSwimmersToRollForward: IManageRosterSeasonSwimmer[]
  searchText: string
  updateRolloverSwimmers: (
    swimmerId: number,
    addOrRemove: "add" | "remove",
  ) => void
}



export default function ClubSwimmers({
  swimmerArr,
  isParent,
  showUSASwimmingIds,
  setShowUSASwimmingIds,
  leagueCutOff,
  refreshSwimmers,
  seasonEnd,
  seasonId,
  isSwimminglyCustomer,
  canRollSwimmers,
  selectedSwimmersToRollForward,
  searchText,
  updateRolloverSwimmers,
}: IClubSwimmersProps) {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const requestSort = (key: any) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  }
  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)
  const impersonateClub = useStoreState(
    (state: StateMapper<AppDataStore>) => state.aliasedClub,
  )
  const [allSwimmers, setAllSwimmers] = useState(swimmerArr)

  const StyledHeaderCell = styled.th`
  cursor: pointer;
  transition: background-color 0.3s ease; // Adds transition for the background color change
  &:hover {
    background-color: rgba(201, 217, 228, 0.5); // 50% opacity
    // Show icon or placeholder on hover
    .sort-icon {
      opacity: 1;
    }
  }

  // Placeholder for the sort icon
  .sort-icon {
    opacity: 0; // Initially hidden
    transition: opacity 0.3s ease;
    margin-left: 8px; // Adjust as needed
  }

  }
`;



useEffect(() => {
  let sortedSwimmers = [...swimmerArr];
  // Include sorting logic here based on sortConfig
  // Swimmer # sorting
  if (sortConfig.key === 'swimmerShoulderNumber') {
    sortedSwimmers.sort((a, b) => {
      const numberA = a.swimmerShoulderNumber;
      const numberB = b.swimmerShoulderNumber;
      if (numberA === null || numberA === undefined) return -1;
      if (numberB === null || numberB === undefined) return 1;
      if (numberA < numberB) return sortConfig.direction === 'ascending' ? -1 : 1;
      if (numberA > numberB) return sortConfig.direction === 'ascending' ? 1 : -1;
      return 0;
    });
  }
  // Swimmer name sorting
  if (sortConfig.key === 'lastName') {
    sortedSwimmers.sort((a, b) => {
      // extract last names and compare them
      const lastNameA = a.lastName.toLowerCase();
      const lastNameB = b.lastName.toLowerCase();
      if (lastNameA < lastNameB) return sortConfig.direction === 'ascending' ? -1 : 1;
      if (lastNameA > lastNameB) return sortConfig.direction === 'ascending' ? 1 : -1;
      return 0;
    });
  }
   // Swimmer Date of Birth sorting
   if (sortConfig.key === 'dateOfBirth') {
    sortedSwimmers.sort((a, b) => {
      // Function to get timestamp from dateOfBirth or return a default value for invalid dates
      const getTimestamp = (date: string | null | undefined) => {
        if (!date) return -Infinity; // Handle null or undefined
        const timestamp = new Date(date).getTime();
        return isNaN(timestamp) ? -Infinity : timestamp; // Handle invalid date strings
      };
  
      const dateA = getTimestamp(a.dateOfBirth);
      const dateB = getTimestamp(b.dateOfBirth);
  
      return sortConfig.direction === 'ascending'
        ? dateA - dateB
        : dateB - dateA;
    });
  }
  
 // Swimmer gender sorts by gender AND by date of birth (always youngest to oldest)
 if (sortConfig.key === 'gender') {
  sortedSwimmers.sort((a, b) => {
    // Primary sort by gender
    if (a.gender !== b.gender) {
      return sortConfig.direction === 'ascending'
        ? a.gender.localeCompare(b.gender)
        : b.gender.localeCompare(a.gender);
    }

    // Secondary sort by date of birth within the same gender, always in descending order
    // Convert the dates to timestamps for comparison
    const dateA = a.dateOfBirth ? new Date(a.dateOfBirth).getTime() : -Infinity;
    const dateB = b.dateOfBirth ? new Date(b.dateOfBirth).getTime() : -Infinity;

    return dateB - dateA; // Always sort dates in descending order
  });
}

  setAllSwimmers(sortedSwimmers);
}, [swimmerArr, sortConfig]);


  useEffect(() => {
    let filteredSwimmers = swimmerArr

    if (isParent && user) {
      filteredSwimmers = filteredSwimmers.filter((fS) =>
        fS.guardians.map((g) => g.guardianEmail).includes(user.email),
      )
    }

    if (searchText && searchText.trim() !== "") {
      filteredSwimmers = filteredSwimmers.filter(
        (el) =>
          (el.firstName &&
            el.firstName.toLowerCase().includes(searchText.toLowerCase())) ||
          (el.lastName &&
            el.lastName.toLowerCase().includes(searchText.toLowerCase())),
      )
    }
    setAllSwimmers(filteredSwimmers)
  }, [user, swimmerArr, isParent, searchText])

  if (!seasonId) {
    return (
      <div className="row">
        <div className="col-md-12 center">
          <h3 style={{ color: "gray" }}>Please Choose A Season</h3>
        </div>
      </div>
    )
  } else if (allSwimmers.length === 0) {
    return (
      <div className="row">
        <div className="col-md-12 center">
          <h3>No Swimmers Found</h3>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div>
        <table id="checkerTable" className="background-red">
          <thead>
            <tr>
                <StyledHeaderCell
                    onClick={() => requestSort('swimmerShoulderNumber')}
                >
                    Swimmer # &nbsp;
                    <span className="sort-icon">⇅</span>
                    {sortConfig.key === 'swimmerShoulderNumber' && (
                      sortConfig.direction === 'ascending' ? <CaretUpOutlined style={{ color: '#a9bfcf' }} /> : <CaretDownOutlined style={{ color: '#a9bfcf' }} />
                  )}
                </StyledHeaderCell>
                <StyledHeaderCell
                    onClick={() => requestSort('lastName')}
                >
                    Name &nbsp;
                    <span className="sort-icon">⇅</span>
                    {sortConfig.key === 'lastName' && (
                      sortConfig.direction === 'ascending' ? <CaretUpOutlined style={{ color: '#a9bfcf' }}/> : <CaretDownOutlined style={{ color: '#a9bfcf' }} />
                    )}
                </StyledHeaderCell>
                <th>League Age</th>
                <StyledHeaderCell
                    onClick={() => requestSort('dateOfBirth')}
                >
                  Date of Birth &nbsp;
                  <Tooltip title="Parent / Guardian of swimmer can update this field">
                    <QuestionCircleOutlined style={{ color: '#a9bfcf' }} />
                  </Tooltip>
                  <span className="sort-icon">⇅</span>
                  {sortConfig.key === 'dateOfBirth' && (
                    sortConfig.direction === 'ascending' ? <CaretUpOutlined style={{ color: '#a9bfcf' }}/> : <CaretDownOutlined style={{ color: '#a9bfcf' }} />
                  )}
                </StyledHeaderCell>
                <StyledHeaderCell onClick={() => requestSort('gender')}>
                  Gender &nbsp;
                  <Tooltip title="Parent / Guardian of swimmer can update this field">
                    <QuestionCircleOutlined style={{ color: '#a9bfcf' }} />
                  </Tooltip>
                  <span className="sort-icon">⇅</span>
                  {sortConfig.key === 'gender' && (
                    sortConfig.direction === 'ascending' ? (
                      <CaretUpOutlined style={{ color: '#a9bfcf' }} />
                    ) : (
                      <CaretDownOutlined style={{ color: '#a9bfcf' }} />
                    )
                  )}
                </StyledHeaderCell>
              <th>Guardian Emails</th>
              {showUSASwimmingIds === true && <th>USA Swimming ID</th>}
              <th>Status</th>
              {canRollSwimmers && (
                <th>
                  <Tooltip title="Select swimmer to email Guardian a swimmer sign-up request for the upcoming season">
                    Invite to upcoming season
                  </Tooltip>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {allSwimmers.map((swimmer) => (
              <SingleSwimmer
                key={swimmer.swimmerId}
                user={user}
                swimmer={swimmer}
                club={impersonateClub}
                leagueCutOff={leagueCutOff}
                refreshSwimmers={refreshSwimmers}
                isParent={isParent}
                isSwimminglyCustomer={isSwimminglyCustomer}
                showUSASwimmingId={showUSASwimmingIds}
                seasonEnd={seasonEnd}
                canRollSwimmers={canRollSwimmers}
                updateRolloverSwimmers={updateRolloverSwimmers}
                checked={selectedSwimmersToRollForward
                  .map((s) => s.swimmerId)
                  .includes(swimmer.swimmerId)}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
