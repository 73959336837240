import React, { useState, useEffect } from 'react';
import { Table, Layout, Typography, Spin, Alert, Modal, Form, Input, InputNumber, DatePicker, Button, Select } from 'antd';
import { swimminglyApi, gen } from './utils';
import moment from 'moment-timezone';

const { Content } = Layout;
const { Title } = Typography;
const { TextArea } = Input;

const paymentTypeOptions = [
  { value: 'check', label: 'Check' },
  { value: 'card', label: 'Card' },
  { value: 'ach', label: 'ACH' },
  { value: 'cash', label: 'Cash' },
  { value: 'other', label: 'Other' },
  { value: 'venmo', label: 'Venmo' },
];

const UpdateLumpSumPayment: React.FC = () => {
  const [payments, setPayments] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [editingRecord, setEditingRecord] = useState<any | null>(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const data = await swimminglyApi.get(gen('/api/getAllLumpSumPayments'));
        if (data.status === 'success') {
          const sortedPayments = data.lumpSumPayments.sort((a: any, b: any) => {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
          });
          setPayments(sortedPayments);
        } else {
          setError('Failed to fetch payments');
        }
      } catch (err) {
        setError('Failed to fetch payments');
      } finally {
        setLoading(false);
      }
    };

    fetchPayments();
  }, []);

  const handleEdit = (record: any) => {
    const paymentDate = record.paymentDate ? moment(record.paymentDate) : moment();
    setEditingRecord({
      ...record,
      paymentDate,
      paymentAmount: record.paymentAmount / 100,
      intendedPricePerSwimmer: record.intendedPricePerSwimmer / 100
    });
    form.setFieldsValue({
      ...record,
      corporatePaymentId: record.corporatePaymentId,
      paymentDate,
      paymentAmount: record.paymentAmount / 100,
      intendedPricePerSwimmer: record.intendedPricePerSwimmer / 100
    });
    setIsModalVisible(true);
  };

  const handleUpdate = async (values: any) => {
    if (!values.corporatePaymentId) {
      return;
    }

    try {
      const updatedRecord = {
        ...editingRecord,
        ...values,
        paymentDate: values.paymentDate ? values.paymentDate.format('YYYY-MM-DD') : null,
        paymentAmount: Math.round(values.paymentAmount * 100),
        intendedPricePerSwimmer: Math.round(values.intendedPricePerSwimmer * 100)
      };
      const response = await fetch(gen('/api/updateLumpSumPayment'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedRecord),
      });
      const result = await response.json();
      if (result.status === 'success') {
        setPayments((prevPayments) =>
          prevPayments.map((payment) => (payment.corporatePaymentId === editingRecord.corporatePaymentId ? {
            ...payment,
            ...values,
            paymentDate: values.paymentDate ? values.paymentDate.format('YYYY-MM-DD') : payment.paymentDate,
            paymentAmount: Math.round(values.paymentAmount * 100),
            intendedPricePerSwimmer: Math.round(values.intendedPricePerSwimmer * 100)
          } : payment))
        );
        setIsModalVisible(false);
        setEditingRecord(null);
      }
    } catch (err) {
      // Handle the error appropriately here
    }
  };

  const columns = [
    {
      title: 'Payment ID',
      dataIndex: 'corporatePaymentId',
      key: 'corporatePaymentId',
    },
    {
      title: 'Reference Name',
      dataIndex: 'referenceName',
      key: 'referenceName',
      render: (text: string, record: any) => <a onClick={() => handleEdit(record)}>{text}</a>,
    },
    {
      title: 'Payment Type',
      dataIndex: 'paymentType',
      key: 'paymentType',
    },
    {
      title: 'Payment Amount',
      dataIndex: 'paymentAmount',
      key: 'paymentAmount',
      render: (amount: number) => `$${(amount / 100).toFixed(2)}`,
    },
    {
      title: 'Payment Received',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      render: (date: string | null) => (date ? moment(date).format('YYYY-MM-DD') : '-'),
    },
    {
      title: 'Paying Entity',
      dataIndex: 'payingEntity',
      key: 'payingEntity',
    },
    {
      title: 'Intended Price Per Swimmer',
      dataIndex: 'intendedPricePerSwimmer',
      key: 'intendedPricePerSwimmer',
      render: (amount: number) => `$${(amount / 100).toFixed(2)}`,
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
    },
    {
      title: 'Expected # of Swimmers',
      dataIndex: 'expectedNumberOfSwimmers',
      key: 'expectedNumberOfSwimmers',
    },
    {
      title: 'Actual # of Swimmers',
      dataIndex: 'actualNumberOfSwimmers',
      key: 'actualNumberOfSwimmers',
    },
    {
      title: 'Stripe Payment Intent ID',
      dataIndex: 'stripePaymentIntentId',
      key: 'stripePaymentIntentId',
    },
  ];

  return (
    <Layout>
      <Content style={{ paddingTop: '20px' }}>
        <Title level={2}>Update Corporate / Offline Payment Records</Title>
        {loading ? (
          <Spin size="large" style={{ display: 'block', margin: '20px auto' }} />
        ) : error ? (
          <Alert message="Error" description={error} type="error" showIcon />
        ) : (
          <Table
            columns={columns}
            dataSource={payments}
            rowKey="corporatePaymentId"
            pagination={{ pageSize: 50 }}
            scroll={{ x: true }}
          />
        )}
      </Content>

      {editingRecord && (
        <Modal
          title="Update Corporate/Offline Payment Record"
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <Form
            form={form}
            initialValues={editingRecord}
            onFinish={handleUpdate}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Form.Item name="corporatePaymentId" hidden>
              <Input size="large"/>
            </Form.Item>
            <Form.Item label="Reference Name" name="referenceName">
              <Input />
            </Form.Item>
            <Form.Item label="Payment Type" name="paymentType" style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginRight: '16px' }}>
              <Select options={paymentTypeOptions} />
            </Form.Item>
            <Form.Item label="Payment Date" name="paymentDate" style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}>
              <DatePicker />
            </Form.Item>
            <Form.Item label="Payment Amount" name="paymentAmount" style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginRight: '16px' }}>
              <InputNumber
                min={0}
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => {
                  const parsedValue = value ? parseFloat(value.replace(/\$\s?|(,*)/g, '')) : 0;
                  return parsedValue as unknown as 0;
                }}
              />
            </Form.Item>
            <Form.Item label="Intended Price Per Swimmer" name="intendedPricePerSwimmer" style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}>
              <InputNumber
                min={0}
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => {
                  const parsedValue = value ? parseFloat(value.replace(/\$\s?|(,*)/g, '')) : 0;
                  return parsedValue as unknown as 0;
                }}
              />
            </Form.Item>
            <Form.Item label="Expected Number of Swimmers" name="expectedNumberOfSwimmers" style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginRight: '16px' }}>
              <InputNumber min={0} />
            </Form.Item>
            <Form.Item label="Actual Number of Swimmers" name="actualNumberOfSwimmers" style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}>
              <InputNumber min={0} />
            </Form.Item>
            <Form.Item label="Paying Entity" name="payingEntity">
              <Input />
            </Form.Item>
            <Form.Item label="Stripe Payment Intent ID" name="stripePaymentIntentId">
              <Input />
            </Form.Item>
            <Form.Item label="Notes" name="notes">
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ marginRight: '8px' }}>Update Record</Button>
              <Button onClick={() => setIsModalVisible(false)}>Cancel</Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </Layout>
  );
};

export default UpdateLumpSumPayment;
