import React, { useEffect, useState } from "react";
import { List, Typography, Divider, Tooltip, Tag, Skeleton, Button } from "antd";
import { AppleOutlined, AndroidOutlined, LaptopOutlined, DownOutlined, UpOutlined, ExportOutlined, PhoneOutlined, BellOutlined, PhoneFilled, BellFilled, MobileFilled } from "@ant-design/icons";
import { swimminglyApi } from "./utils";
import moment from "moment";

const { Title } = Typography;

interface ReleaseNote {
  releaseId: number;
  releaseDate: string;
  appUpdated: string;
  releaseVersion: string;
  releaseDescription: string;
  deletedAt: string | null;
}

interface MostRecentReleases {
  clubhouse: ReleaseNote | null;
  ios: ReleaseNote | null;
  fanIos: ReleaseNote | null;
  android: ReleaseNote | null;
  fanAndroid: ReleaseNote | null;
}

const getAppIcon = (appUpdated: string) => {
  switch (appUpdated.toLowerCase()) {
    case "clubhouse":
      return <LaptopOutlined style={{ fontSize: '18px', color: 'magenta' }} />;
    case "swimminglyios":
      return <AppleOutlined style={{ fontSize: '18px', color: 'blue' }} />;
    case "swimminglyfanios":
      return <AppleOutlined style={{ fontSize: '18px', color: 'blue' }} />;
    case "swimminglyandroid":
      return <AndroidOutlined style={{ fontSize: '18px', color: 'green' }} />;
    case "swimminglyfanandroid":
      return <AndroidOutlined style={{ fontSize: '18px', color: 'green' }} />;
    default:
      return null;
  }
};

const getAppUserFacingName = (appUpdated: string) => {
  switch (appUpdated.toLowerCase()) {
    case "clubhouse":
      return "Clubhouse";
    case "swimminglyios":
      return "Swimmingly";
    case "swimminglyfanios":
      return "SwimminglyFan";
    case "swimminglyandroid":
      return "Swimmingly Timer";
    case "swimminglyfanandroid":
      return "SwimminglyFan";
    default:
      return appUpdated;
  }
};

const UserReleaseNotes: React.FC = () => {
  const [releaseNotes, setReleaseNotes] = useState<ReleaseNote[]>([]);
  const [mostRecentReleases, setMostRecentReleases] = useState<MostRecentReleases>({
    clubhouse: null,
    ios: null,
    fanIos: null,
    android: null,
    fanAndroid: null,
  });
  const [loading, setLoading] = useState(true);
  const [expandedApp, setExpandedApp] = useState<string | null>(null); // Track which app is expanded

  // Fetch release notes from the backend
  const fetchReleaseNotes = async () => {
    setLoading(true);
    try {
      const response = await swimminglyApi.get("/api/releaseNotes");
      const validReleaseNotes = response.releaseNotes.filter((note: ReleaseNote) => !note.deletedAt);
      setReleaseNotes(validReleaseNotes);

      const recentReleases = getMostRecentReleases(validReleaseNotes);
      setMostRecentReleases(recentReleases);
    } catch (error) {
      console.error("Failed to fetch release notes:", error);
    } finally {
      setLoading(false);
    }
  };

  // Helper function to find the most recent releases
  const getMostRecentReleases = (notes: ReleaseNote[]): MostRecentReleases => {
    const apps: MostRecentReleases = {
      clubhouse: null,
      ios: null,
      fanIos: null,
      android: null,
      fanAndroid: null,
    };

    notes.forEach((note) => {
      const normalizedApp = note.appUpdated.toLowerCase();
      if (normalizedApp === "clubhouse" && isNewerVersion(apps.clubhouse, note)) {
        apps.clubhouse = note;
      } else if (normalizedApp === "swimminglyios" && isNewerVersion(apps.ios, note)) {
        apps.ios = note;
      } else if (normalizedApp === "swimminglyfanios" && isNewerVersion(apps.fanIos, note)) {
        apps.fanIos = note;
      } else if (normalizedApp === "swimminglyandroid" && isNewerVersion(apps.android, note)) {
        apps.android = note;
      } else if (normalizedApp === "swimminglyfanandroid" && isNewerVersion(apps.fanAndroid, note)) {
        apps.fanAndroid = note;
      }
    });

    return apps;
  };

  // Helper function to check for newer version
  const isNewerVersion = (current: ReleaseNote | null, candidate: ReleaseNote): boolean => {
    if (!current) return true;
    return current.releaseVersion.localeCompare(candidate.releaseVersion, undefined, { numeric: true }) < 0;
  };

  useEffect(() => {
    fetchReleaseNotes();
  }, []);

  const formatReleaseDate = (releaseDate: string) => {
    return `${moment(releaseDate).format("MM-DD-YYYY")}`;
  };

  const handleToggle = (appUpdated: string) => {
    setExpandedApp((prev) => (prev === appUpdated ? null : appUpdated)); // Toggle expansion
  };

  return (
    <div style={{ width: "100%", maxWidth: "440px" }}>
      {loading ? (
        <Skeleton active />
      ) : (
        <>
         <Title level={3}>
            <Tooltip title="Go to device compatibility" placement="right">
              <a href="https://support.swimmingly.app/device-compatibility" target="_blank" rel="noopener noreferrer">
                Current App Versions
              </a>
            </Tooltip>
            <Tooltip title="Go to Device Compatibility">
                      <MobileFilled
                        onClick={() => window.open("https://support.swimmingly.app/device-compatibility", "_blank")}
                        style={{ fontSize: '18px', color: '#1890ff', marginLeft: '10px', cursor: 'pointer' }}
                      />
                    </Tooltip>
                    {/* Add BellFilled icon with Tooltip */}
                    <Tooltip title="Go to Update Announcements">
                      <BellFilled
                        onClick={() => window.open("https://swimmingly.app/updates", "_blank")}
                        style={{ fontSize: '18px', color: '#1890ff', marginLeft: '10px', cursor: 'pointer' }}
                      />
                    </Tooltip>
          </Title>

          {[mostRecentReleases.clubhouse, mostRecentReleases.ios, mostRecentReleases.fanIos, mostRecentReleases.android, mostRecentReleases.fanAndroid]
            .filter((release): release is ReleaseNote => release !== null)
            .map((release) => (
              <div key={release.releaseId} style={{ marginBottom: '20px' }}>
                <div
                  onClick={() => handleToggle(release.appUpdated)}
                  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f0f0f0')}
                  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
                  style={{ 
                    cursor: 'pointer', 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center', 
                    padding: '12px 5px', 
                    transition: 'background-color 0.2s', 
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                    {getAppIcon(release.appUpdated)}
                    <Tag color={getAppIcon(release.appUpdated)?.props?.style?.color} style={{ marginLeft: '10px', fontSize: '18px' }}>
                      {getAppUserFacingName(release.appUpdated)}
                    </Tag>
                    <Tag color={getAppIcon(release.appUpdated)?.props?.style?.color} style={{ marginLeft: 'auto', fontSize: '18px' }}>
                        {release.releaseVersion}
                    </Tag>
                  </div>
                  {expandedApp === release.appUpdated ? <UpOutlined /> : <DownOutlined />}
                </div>
                {expandedApp === release.appUpdated && (
                  <List
                    size="small"
                    header={<Typography.Title level={4} style={{ marginBottom: 0, color: "#101242" }}>Release Notes</Typography.Title>}
                    bordered={true}
                    dataSource={releaseNotes.filter(note => note.appUpdated.toLowerCase() === release.appUpdated.toLowerCase())}
                    renderItem={(item: ReleaseNote) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={getAppIcon(item.appUpdated)}
                          title={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              
                              
                              {/* Release Version */}
                              <Tag color={getAppIcon(item.appUpdated)?.props?.style?.color} style={{ fontSize: '14px', marginRight: '8px' }}>
                                {item.releaseVersion}
                              </Tag>
                  
                              {/* Release Date */}
                              <span style={{ color: '#888', fontSize: '14px' }}>
                                {formatReleaseDate(item.releaseDate)}
                              </span>
                            </div>
                          }
                          description={
                            <>
                              <div style={{ marginTop: '10px' }}>{item.releaseDescription}</div>
                            </>
                          }
                        />
                      </List.Item>
                    )}
                  />
                )}
                
              </div>
            ))}
        </>
      )}
    </div>
  );
};

export default UserReleaseNotes;
