import React from "react"
import $ from "jquery"
import { ageString, dateString, raceTypeArr, convertCourse, gen } from "./utils"
import { DatePicker, Button, Table, Row, Col, Select } from "antd"
const { RangePicker } = DatePicker
const { Option } = Select

export default class SwimmerCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      swimmerId: props.swimmers && props.swimmers[0] && props.swimmers[0].id,
      distance: 50,
      stroke: 1,
      course: 0,
      swimmers: [],
      startDate: null,
      endDate: null,

      swimmerArr: [],
      loaded: false,
      loading: false,
    }
  }

  handleOptionChange = (changeEvent) => {
    this.setState({
      gender: changeEvent.target.value,
    })
  }

  onClick = () => {
    this.setState({ loading: true })

    let startDate = this.state.startDate
      ? this.state.startDate.format("YYYY-MM-DD")
      : "null"
    let endDate = this.state.endDate
      ? this.state.endDate.format("YYYY-MM-DD")
      : "null"

    const data = {
      ...this.state,
      startDate,
      endDate,
      clubId: this.props.club.clubId,
    }

    $.ajax({
      url: gen("/api/swimmerCardPost"),
      dataType: "json",
      method: "POST",
      data: data,
    }).done((returnData) => {
      returnData.swimmerArr.sort((a, b) => (b.date > a.date ? 1 : -1))
      this.setState({
        swimmerArr: returnData.swimmerArr,
        loaded: true,
        loading: false,
      })
    })
  }

  render() {
    let theSortedSwimmers = [...this.props.swimmers]
    theSortedSwimmers.sort((a, b) =>
      `${b.last_name} ${b.first_name}` < `${a.last_name} ${a.first_name}`
        ? 1
        : -1,
    )
    return (
      <div>
        <Row>
          <Col span={4} style={{ marginTop: "10px" }}>
            Name
          </Col>
          <Col span={12}>
            <Select
              style={{ width: "200px" }}
              className="js-example-basic-single"
              value={this.state.swimmerId}
              onChange={(e) => this.setState({ swimmerId: e, loaded: false })}
            >
              {theSortedSwimmers.map((el) => (
                <Option value={el.id} key={el.id}>
                  {el.first_name + " " + el.last_name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>

        <Row>
          <Col span={4} style={{ paddingTop: "25px" }}>
            Distance
          </Col>
          <Col span={6}>
            <Select
              style={{ marginTop: "15px", width: "200px" }}
              value={this.state.distance}
              onChange={(e) => this.setState({ distance: e, loaded: false })}
            >
              <Option value={12}>12</Option>
              <Option value={15}>15</Option>
              <Option value={25}>25</Option>
              <Option value={50}>50</Option>
              <Option value={75}>75</Option>
              <Option value={100}>100</Option>
              <Option value={125}>125</Option>
              <Option value={150}>150</Option>
              <Option value={175}>175</Option>
              <Option value={200}>200</Option>
              <Option value={400}>400</Option>
              <Option value={500}>500</Option>
              <Option value={800}>800</Option>
              <Option value={1000}>1000</Option>
              <Option value={1500}>1500</Option>
              <Option value={1650}>1650</Option>
            </Select>
          </Col>

          <Col span={4} style={{ paddingTop: "5px", marginTop: "15px" }}>
            Stroke
          </Col>
          <Col span={6}>
            <Select
              style={{ marginTop: "15px", width: "200px" }}
              value={this.state.stroke}
              onChange={(e) => this.setState({ stroke: e, loaded: false })}
            >
              <Option value={5}>Freestyle Relay</Option>
              <Option value={3}>Breaststroke</Option>
              <Option value={0}>Medley Relay</Option>
              <Option value={6}>Individual Medley</Option>
              <Option value={4}>Butterfly</Option>
              <Option value={2}>Backstroke</Option>
              <Option value={1}>Freestyle</Option>
              <Option value={7}>Butterfly Relay</Option>
              <Option value={8}>Backstroke Relay</Option>
              <Option value={9}>Breaststroke Relay</Option>
              <Option value={10}>Fun</Option>
            </Select>
          </Col>
        </Row>

        <Row>
          <Col span={4} style={{ paddingTop: "25px" }}>
            Course
          </Col>
          <Col span={6} style={{ marginTop: "15px" }}>
            <Select
              style={{ width: "200px" }}
              value={this.state.course}
              onChange={(e) => this.setState({ course: e, loaded: false })}
            >
              <Option value={0} key="0">
                SCY
              </Option>
              <Option value={1} key="1">
                SCM
              </Option>
              <Option value={2} key="2">
                LCM
              </Option>
            </Select>
          </Col>

          <Col span={4} style={{ paddingTop: "30px" }}>
            Date Range
          </Col>

          <RangePicker
            style={{ marginTop: "20px" }}
            value={[this.state.startDate, this.state.endDate]}
            onChange={(dates) =>
              this.setState({
                startDate: dates[0],
                endDate: dates[1],
                loaded: false,
              })
            }
          />
        </Row>
        <Row>
          <Col>
            <Button
              style={{ marginTop: "15px" }}
              onClick={this.onClick}
              type="primary"
              loading={this.state.loading}
            >
              Search Swimmers
            </Button>
          </Col>
          {this.state.loaded && (
            <SwimmerTable
              swimmerArr={this.state.swimmerArr}
              distance={this.state.distance}
              course={this.state.course}
              stroke={this.state.stroke}
              ageStart={this.state.ageStart}
              ageEnd={this.state.ageEnd}
            />
          )}
        </Row>
      </div>
    )
  }
}

class SwimmerTable extends React.Component {
  render() {
    let { ageStart, ageEnd, distance, startDate, endDate, course, stroke } =
      this.props
    let courseString = convertCourse[course]
    let strokeString = raceTypeArr[stroke]
    return (
      <div style={{ backgroundColor: "#F6F6F6" }} className="ourpanel-body">
        <div
          style={{ fontSize: "16px", lineHeight: "40px", fontWeight: "bold" }}
        >
          {`${ageString(
            ageStart,
            ageEnd,
          )} | ${distance} ${strokeString} ${courseString} | ${dateString(
            startDate,
            endDate,
          )}`}
          {/*<span
            className="fa fa-file-excel-o"
            style={{ marginLeft: "40px", cursor: "pointer" }}
            onclick="downloadTable('datatable-default', ['Name', 'Time', 'Course', 'Date', Meet'],[{'id': 'raceDistance', 'name' : 'distance'},
                      {'id': 'raceStroke', 'name' : 'stroke'},
                      ])"
          />*/}
        </div>

        <Table
          rowKey={(record) =>
            `${record.time}${record.name}${record.date}${record.meet}`
          }
          dataSource={this.props.swimmerArr}
          columns={[
            { title: "Name", dataIndex: "name", key: "name" },
            { title: "Time", dataIndex: "time", key: "time" },
            { title: "Course", render: () => courseString, key: "course" },
            { title: "Date", dataIndex: "date", key: "date" },
            { title: "Meet", dataIndex: "meet", key: "meet" },
          ]}
        />
      </div>
    )
  }
}
