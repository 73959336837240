import React from "react"
import { Button } from "antd"

interface IButtonProps {
  loading?: boolean
  action: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  style?: { [key: string]: any }
}

export function ApproveButton({
  loading = false,
  action,
  style = {},
}: IButtonProps) {
  return (
    <Button
      loading={loading}
      type="default"
      size="small"
      onClick={action}
      style={{
        backgroundColor: "#369423",
        color: "white",
        ...style,
      }}
    >
      Approve
    </Button>
  )
}

export function RejectButton({
  loading = false,
  action,
  style = {},
}: IButtonProps) {
  return (
    <Button
      loading={loading}
      type="default"
      size="small"
      onClick={action}
      style={{ backgroundColor: "#f50", color: "white", ...style }}
    >
      Reject
    </Button>
  )
}
