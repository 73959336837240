import React from "react"
import { Button } from "antd"
import { PlusOutlined, } from "@ant-design/icons"

// first, firstChange, second, secondChange
class SingleRow extends React.Component {
  render() {
    let { first, second, firstChange, secondChange, onRemove } = this.props

    let firstInput =
      first === 0 ? (
        <input value={0} onChange={() => {}} type="number" />
      ) : (
        <input
          value={first === null ? "" : first}
          onChange={firstChange}
          type="number"
        />
      )

    let secondInput =
      second === -1 ? (
        <input value={"Inf"} onChange={() => {}} type="text" />
      ) : (
        <input
          value={second === null ? "" : second}
          onChange={secondChange}
          type="number"
        />
      )
    return (
      <div style={{ display: "flex" }}>
        <div>{firstInput}</div>
        <div
          style={{ width: "20px", display: "flex", justifyContent: "center" }}
        >
          -
        </div>
        <div>{secondInput}</div>
        {onRemove ? (
          <div
            style={{
              width: "20px",
              fontSize: "20px",
              marginLeft: "10px",
              cursor: "pointer",
            }}
            onClick={onRemove}
          >
            &#10754;
          </div>
        ) : null}
      </div>
    )
  }
}

export default class AgeCliffs extends React.Component {
  onChangeAtIndex = (i, e, isFirstBool) => {
    let val = e.target.value
    if (val === "") {
      let cutOffs = this.props.cutOffs.slice()
      cutOffs[i] = null
      this.props.setCutOffs(cutOffs)
      return
    }
    let num = Number(val)
    if (isNaN(num)) {
      return
    }
    if (num < 0) {
      return
    }
    if (num >= 100) {
      return
    }

    let cutOffs = this.props.cutOffs.slice()
    cutOffs[i] = isFirstBool ? num : num - 1
    this.props.setCutOffs(cutOffs)
  }

  addCutOff = () => {
    let cutOffs = this.props.cutOffs
    cutOffs.push(null)
    this.props.setCutOffs(cutOffs)
  }

  onRemove = (i) => {
    let cutOffs = this.props.cutOffs
    cutOffs.splice(i, 1)
    this.props.setCutOffs(cutOffs)
  }

  componentDidMount() {
    let age_cliffs = this.props.league.age_cliffs
    if (!age_cliffs) {
      this.props.setCutOffs([])
      return
    }
    let split = age_cliffs.split(",")
    this.props.setCutOffs(split)
  }

  sanityCheck = (arr) => {
    for (let i = 0; i < arr.length; i++) {
      let el = arr[i]
      let val = Number(el)
      if (isNaN(val)) {
        return false
      }
      if (val <= 0) {
        return false
      }
      if (val >= 100) {
        return false
      }
    }
    return true
  }

  render() {
    let numArray = []

    // let isGood = this.sanityCheck(this.props.cutOffs)
    // if (!isGood) {
    //   console.log("Something ain't right")
    // }

    let cutOffs = this.props.cutOffs
      .map((el) => (el === null ? null : Number(el)))
      .slice()
    cutOffs.unshift(0)
    cutOffs.push(-1)

    for (let i = 0; i < cutOffs.length - 1; i++) {
      let first = null
      if (cutOffs[i] === null) {
        first = null
      } else {
        first = Number(cutOffs[i]) === 0 && i === 0 ? 0 : Number(cutOffs[i]) + 1
      }
      let second = cutOffs[i + 1]
      numArray.push({ first, second, index: i })
    }
    // console.log(cutOffs, numArray)
    return (
      <div style={{ paddingBottom: "10px" }}>
        <Button onClick={this.addCutOff}
          type="primary"
          shape="circle"
          icon={<PlusOutlined />}
         >
        </Button>
      &nbsp;Add Age Group for Reporting
       
        <div style={{ display: "flex", marginLeft: "20px" }}>
          <div>
            {numArray.map((el, i) => (
              <SingleRow
                key={i}
                first={el.first}
                second={el.second}
                firstChange={(e) =>
                  this.onChangeAtIndex(el.index - 1, e, false)
                }
                secondChange={(e) => this.onChangeAtIndex(el.index, e, true)}
                onRemove={
                  i === numArray.length - 1
                    ? null
                    : () => this.onRemove(el.index)
                }
              />
            ))}
          </div>
        </div>
      </div>
    )
  }
}
