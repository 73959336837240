import { useState, useEffect, useCallback, Fragment } from "react"
import moment, { Moment } from "moment-timezone"
import Modal from "antd/lib/modal/Modal"
import styled from "styled-components"
import { message, DatePicker, Tooltip, Select, Button, Popconfirm } from "antd"
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons"
import { TiHomeOutline } from "react-icons/ti"
import { AiOutlineCloseCircle } from "react-icons/ai"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../appData/types"
import {
  CustomEventEmitter,
  DistanceFormatter,
  gen,
  IClub,
  IClubSeason,
  swimminglyApi,
  yardsPerMeter,
} from "./utils"

import { cloneDeep, size } from "lodash"
import SingleRank from "./SingleRank"
import { updateSeasonMeetArrayListener } from "./MeetSchedulePageNew"

interface IUpdateListener {
  on(event: "removeClub", listener: (clubId: number) => void): void
  emit(event: "removeClub", clubId: number): void
  removeAllListeners(event: "removeClub"): void
  on(event: "setHomeClub", listener: (clubId: number | null) => void): void
  emit(event: "setHomeClub", clubId: number | null): void
  removeAllListeners(event: "setHomeClub"): void
  on(event: "setPool", listener: (newPool: IMeetPool | null) => void): void
  emit(event: "setPool", newPool: IMeetPool | null): void
  removeAllListeners(event: "setPool"): void
  on(
    event: "updateSeasonForClubInMeet",
    listener: (
      clubId: number,
      newSeasonId: number,
      newSeasonName: string,
    ) => void,
  ): void
  emit(
    event: "updateSeasonForClubInMeet",
    clubId: number,
    newSeasonId: number,
    newSeasonName: string,
  ): void
  removeAllListeners(event: "updateSeasonForClubInMeet"): void
  on(
    event: "swapClubsInMeet",
    listener: (
      oldClubId: number,
      newClubId: number,
      seasonId: number | null,
      seasonName: string | null,
    ) => void,
  ): void
  emit(
    event: "swapClubsInMeet",
    oldClubId: number,
    newClubId: number,
    seasonId: number | null,
    seasonName: string | null,
  ): void
  removeAllListeners(event: "swapClubsInMeet"): void
  on(event: "addNextClub", listener: () => void): void
  emit(event: "addNextClub"): void
  removeAllListeners(event: "addNextClub"): void
  on(
    event: "updateIndividualScoringConfig",
    listener: (newSettings: { place: number; points: number }[]) => void,
  ): void
  emit(
    event: "updateIndividualScoringConfig",
    newSettings: { place: number; points: number }[],
  ): void
  removeAllListeners(event: "updateIndividualScoringConfig"): void
  on(
    event: "updateRelayScoringConfig",
    listener: (newSettings: { place: number; points: number }[]) => void,
  ): void
  emit(
    event: "updateRelayScoringConfig",
    newSettings: { place: number; points: number }[],
  ): void
  removeAllListeners(event: "updateRelayScoringConfig"): void
}

const componentUpdateListner: IUpdateListener = new CustomEventEmitter()

interface ICreateMeetModalProps {
  visible: boolean
  setVisible: (newVisible: boolean) => void
  season: {
    clubSeasonId: number
    seasonId: number
    seasonName: string
    startDate: Moment
    endDate: Moment
  } | null
}

interface IMeetPool {
  poolName: string
  poolId: number
  address: string | null
  length: number
  course: "SCY" | "SCM" | "LCM"
}

const StyledMeetModal = styled(Modal)`
  max-width: 40%;
  min-width: 725px;

  .ant-modal-content {
    max-height: calc(95vh - 100px);
    overflow: scroll;
  }

  .overview-info {
    display: grid;
    grid-template-columns: 100px 1fr;
    align-items: center;
    row-gap: 8px;
  }

  .overview-info.meet-type-container .choice {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5px;
  }

  .entry-limits {
    display: grid;
    align-items: center;
    grid-template-columns: 100px 100px 1fr;
    row-gap: 5px;
  }

  .title {
    color: var(--primaryblue);
  }

  .entry-limits .title {
    grid-column: span 3;
  }

  input:disabled {
    color: var(--mediumgrey);
  }

  .two-columns {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 25px;
  }

  .two-columns p {
    text-align: center;
  }

  .virtual-course {
    display: flex;
    align-items: center;
  }

  label {
    margin: 0;
  }

  input[type="checkbox"] {
    margin: 0;
  }

  select {
    padding: 0;
  }

  input,
  select {
    border: 1px solid var(--mediumgrey);
    border-radius: 3px;
    height: 35px;
  }

  .shadowed {
    box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    padding: 5px;
  }

  .choose-dates {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .date-pickers {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  .overview-info input:disabled {
    color: var(--darkgrey);
  }

  .chosen,
  .not-chosen {
    text-align: center;
    border-radius: 2px;
  }
  .chosen {
    background-color: var(--buttonblue);
    color: var(--white);
    border: none;
  }
  .not-chosen {
    background-color: var(--white);
    color: var(--darkgrey);
    border: 1.5px solid var(--mediumgrey);
  }

  .form-select {
    padding: 0;
    max-height: 50px;
  }

  @media (max-width: 750px) {
    min-width: 0;
    max-width: 97%;

    .ant-modal-body {
      padding: 5px;
    }

    .two-columns {
      grid-template-columns: 1fr;
    }

    .two-columns.no-mobile {
      grid-template-columns: 1fr 1fr;
    }
  }
`

const AddClubsTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th {
    border-bottom: 2px solid var(--primaryblue);
    padding-bottom: 5px;
  }

  tbody tr td {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  tbody tr:first-child td {
    padding-top: 20px;
  }

  tbody tr:last-child td {
    padding-bottom: 5px;
  }

  input,
  select,
  option {
    border: 2px solid var(--primaryblue);
    border-radius: 10px;
    height: 35px;
  }

  select {
    padding: 0;
    width: 100%;
  }
`

const StyledVirtualClubs = styled.div`
  display: grid;
  grid-template-columns: 7fr 1fr 9fr;
  padding-left: 5px;
  padding-right: 5px;

  .header {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 2px solid var(--primaryblue);
  }

  .row {
    padding: 5px;
    padding-top: 3px;
    padding-bottom: 2px;
    grid-column: span 3;
    display: grid;
    grid-template-columns: 7fr 1fr 9fr;
    align-items: center;
  }

  .row:last-child {
    padding-bottom: 10px;
  }

  .row .ant-select-selector {
    padding-left: 5px;
    padding-right: 0;
    border: 2px solid var(--primaryblue);
    border-radius: 10px;
    height: 35px;
  }

  input,
  select,
  option {
    border: 2px solid var(--primaryblue);
    border-radius: 10px;
    height: 35px;
    padding: 0;
  }

  &:before,
  &:after,
  span::before,
  span::after,
  .row::before,
  .row::after {
    display: none;
    visibility: hidden;
    content: none;
  }
  &:after {
    display: none;
    visibility: hidden;
    content: none;
  }
`

function IndividualScoringConfig({
  individualScoring,
}: {
  individualScoring: { place: number; points: number }[]
}) {
  const changePlace = (
    index: number,
    place: number | null,
    points: number | null,
  ) => {
    const newIndividualScoring = cloneDeep(individualScoring)
    const updateItem = newIndividualScoring[index]
    if (place) updateItem.place = place
    if (points) updateItem.points = points
    componentUpdateListner.emit(
      "updateIndividualScoringConfig",
      newIndividualScoring,
    )
  }

  const removeIndex = (index: number) => {
    const newIndividualScoring = cloneDeep(individualScoring)
    newIndividualScoring.splice(index, 1)
    componentUpdateListner.emit(
      "updateIndividualScoringConfig",
      newIndividualScoring,
    )
  }

  const add = () => {
    const newIndividualScoring = cloneDeep(individualScoring)
    const maxPlace = Math.max(...newIndividualScoring.map((el) => el.place), 0)
    newIndividualScoring.push({ place: maxPlace + 1, points: 1 })
    componentUpdateListner.emit(
      "updateIndividualScoringConfig",
      newIndividualScoring,
    )
  }

  return (
    <div style={{ display: "flex", justifyContent: "space-around" }}>
      <div>
        <div style={{ textAlign: "center", fontSize: "20px" }}>Individual</div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "20%" }} />
          <div style={{ width: "100px" }}>Place</div>
          <div style={{ width: "100px" }}>Points</div>
        </div>
        {individualScoring.map((el, i) => (
          <SingleRank
            key={`individual_scoring_${i}`}
            place={el.place}
            points={el.points}
            changePlace={(place) => changePlace(i, place, null)}
            changePoints={(points) => changePlace(i, null, points)}
            remove={() => removeIndex(i)}
          />
        ))}
        <Button
          onClick={add}
          shape="circle"
          icon={<PlusOutlined />}
          style={{ marginTop: "5px" }}
        />
      </div>
    </div>
  )
}

function RelayScoringConfig({
  relayScoring,
}: {
  relayScoring: { place: number; points: number }[]
}) {
  const changePlace = (
    index: number,
    place: number | null,
    points: number | null,
  ) => {
    const newRelayScoring = cloneDeep(relayScoring)
    const updateItem = newRelayScoring[index]
    if (place) updateItem.place = place
    if (points) updateItem.points = points
    componentUpdateListner.emit("updateRelayScoringConfig", newRelayScoring)
  }

  const removeIndex = (index: number) => {
    const newRelayScoring = cloneDeep(relayScoring)
    newRelayScoring.splice(index, 1)
    componentUpdateListner.emit("updateRelayScoringConfig", newRelayScoring)
  }

  const add = () => {
    const newRelayScoring = cloneDeep(relayScoring)
    const maxPlace = Math.max(...newRelayScoring.map((el) => el.place), 0)
    newRelayScoring.push({ place: maxPlace + 1, points: 1 })
    componentUpdateListner.emit("updateRelayScoringConfig", newRelayScoring)
  }

  return (
    <div style={{ display: "flex", justifyContent: "space-around" }}>
      <div>
        <div style={{ textAlign: "center", fontSize: "20px" }}>Relay</div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "20%" }} />
          <div style={{ width: "100px" }}>Place</div>
          <div style={{ width: "100px" }}>Points</div>
        </div>
        {relayScoring.map((el, i) => (
          <SingleRank
            key={`relay_scoring_${i}`}
            place={el.place}
            points={el.points}
            changePlace={(place) => changePlace(i, place, null)}
            changePoints={(points) => changePlace(i, null, points)}
            remove={() => removeIndex(i)}
          />
        ))}
        <Button
          onClick={add}
          shape="circle"
          icon={<PlusOutlined />}
          style={{ marginTop: "5px" }}
        />
      </div>
    </div>
  )
}

function VirtualClubRow({
  club,
  clubSeasonsInMeet,
  availableClubs,
  meetEndDate,
}: {
  club: IClub
  clubSeasonsInMeet: {
    club: IClub
    season: { seasonId: number; seasonName: string } | null
  }[]
  availableClubs: IClub[]
  meetEndDate: Moment
}) {
  const [seasonId, setSeasonId] = useState<number>(-1)
  const [seasonsList, setSeasonsList] = useState<
    {
      seasonId: number
      seasonName: string
      startDate: Moment
      endDate: Moment
    }[]
  >([])

  useEffect(() => {
    const thisClubSeason = clubSeasonsInMeet.find(
      (cS) => cS.club.clubId === club.clubId,
    )
    if (thisClubSeason) {
      setSeasonId(thisClubSeason.season?.seasonId || -1)
    }
  }, [clubSeasonsInMeet, club])

  useEffect(() => {
    let mounted = true
    // get seasonsList for club on meetDate
    if (club.clubId) {
      swimminglyApi
        .get(gen(`/api/getSeasonsForClub/${club.clubId}`))
        .then(
          ({
            status,
            clubSeasons,
          }: {
            status: string
            clubSeasons: IClubSeason[]
          }) => {
            if (status === "success") {
              const theSeasons: {
                seasonId: number
                seasonName: string
                startDate: Moment
                endDate: Moment
              }[] = clubSeasons.map((season: IClubSeason) => ({
                seasonId: season.seasonId,
                seasonName: season.seasonName,
                startDate: moment(season.startDate),
                endDate: moment(season.endDate),
              }))
              if (mounted) {
                setSeasonsList(
                  theSeasons.filter(
                    (season) =>
                      season.endDate >= meetEndDate &&
                      season.startDate <= meetEndDate,
                  ),
                )
              }
            }
          },
        )
    }
    return () => {
      mounted = false
    }
  }, [club.clubId, meetEndDate])

  return (
    <div className="row">
      <Select
        value={club.clubId || -1}
        filterOption={(inputVal, option) => {
          if (option?.children && typeof option?.children === "string") {
            return option.children
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes(inputVal.toLowerCase().replace(/\s+/g, ""))
          }
          return true
        }}
        onChange={(newClubId) => {
          componentUpdateListner.emit(
            "swapClubsInMeet",
            club.clubId || -1,
            newClubId,
            null,
            null,
          )
        }}
        showSearch={true}
      >
        {availableClubs.map((aC) => (
          <Select.Option
            key={`available_club_virtual_choice_${club.clubId || -1}_${
              aC.clubId
            }`}
            value={aC.clubId || -1}
          >
            {`${aC.nameLong || aC.name_long} (${aC.name})`}
          </Select.Option>
        ))}
      </Select>
      <AiOutlineCloseCircle
        onClick={() =>
          componentUpdateListner.emit("removeClub", club.clubId || -1)
        }
        style={{ color: "red", fontSize: "2rem", cursor: "pointer", paddingLeft: "2px" }}
      />
      <select
        value={seasonId}
        onChange={(e) => {
          const newSeasonId = parseInt(e.target.value)
          const newSeasonName = seasonsList.find(
            (season) => season.seasonId === newSeasonId,
          )?.seasonName
          if (newSeasonName) {
            componentUpdateListner.emit(
              "updateSeasonForClubInMeet",
              club.clubId || -1,
              newSeasonId,
              newSeasonName,
            )
          }
        }}
      >
        <option value={-1} disabled hidden>
          Choose Roster...
        </option>
        {seasonsList.map((season) => (
          <option
            key={`season_option_${club.clubId}_${seasonId}_${season.seasonId}`}
            value={season.seasonId}
          >
            {season.seasonName}
          </option>
        ))}
      </select>
    </div>
  )
}
function VirtualClubsInMeetDisplay({
  clubSeasonsInMeet,
  availableClubs,
  season,
  meetEndDate,
}: {
  clubSeasonsInMeet: {
    club: IClub
    season: { seasonId: number; seasonName: string } | null
  }[]
  availableClubs: IClub[]
  season: { seasonId: number; seasonName: string }
  meetEndDate: Moment
}) {
  const impersonateClub = useStoreState(
    (state: StateMapper<AppDataStore>) => state.aliasedClub,
  )
  return (
    <Fragment>
      <StyledVirtualClubs>
        <div className="header row">
          <span>
            <b>Club</b>
          </span>
          <div />
          <span>
            <b>Select Roster for Club</b>
          </span>
        </div>
        <div className="row">
          <select>
            <option>
              {impersonateClub?.nameLong || impersonateClub?.name_long}
            </option>
          </select>
          <div />
          <select>
            <option>{season.seasonName}</option>
          </select>
        </div>
        {clubSeasonsInMeet
          .filter((cl) => cl.club.clubId !== impersonateClub?.clubId)
          .map((cSIM) => (
            <VirtualClubRow
              key={`club_season_item_${cSIM.club.clubId}_${cSIM.season}`}
              club={cSIM.club}
              clubSeasonsInMeet={clubSeasonsInMeet}
              availableClubs={availableClubs}
              meetEndDate={meetEndDate}
            />
          ))}
      </StyledVirtualClubs>
      <Tooltip title="Add all clubs participating in this virtual swim meet">
        <Button 
          onClick={() => componentUpdateListner.emit("addNextClub")}
          type="primary"
          shape="circle"
          size="large"
          icon={<PlusOutlined />}
        />
        &nbsp;Add Club Participating in Meet
        </Tooltip>
    </Fragment>
  )
}

function RegularClubsInMeetDisplay({
  clubSeasonsInMeet,
  availableClubs,
  seasonId,
  seasonName,
  homeClub,
  pools,
  pool,
}: {
  clubSeasonsInMeet: {
    club: IClub
    season: { seasonId: number; seasonName: string } | null
  }[]
  availableClubs: IClub[]
  seasonId: number
  seasonName: string
  homeClub: number | null
  pools: IMeetPool[]
  pool: IMeetPool | null
}) {
  const impersonateClub = useStoreState(
    (state: StateMapper<AppDataStore>) => state.aliasedClub,
  )
  const [clubsToSelect, setClubsToSelect] = useState<IClub[]>([])

  useEffect(() => {
    const clubsInMeet = clubSeasonsInMeet.map((cS) => cS.club.clubId || -1)
    const newClubsToSelect = availableClubs.filter(
      (aC) => !clubsInMeet.includes(aC.clubId || -1),
    )
    setClubsToSelect(newClubsToSelect)
  }, [availableClubs, clubSeasonsInMeet])

  return (
    <div>
      <AddClubsTable className="add-clubs">
        <colgroup>
          <col style={{ width: "40%" }} />
          <col style={{ width: "4%" }} />
          <col style={{ width: "19%" }} />
          <col style={{ width: "36%" }} />
        </colgroup>
        <thead>
          <tr>
            <th>Club</th>
            <th></th>
            <th style={{ textAlign: "center" }}>Home Club</th>
            <th style={{ textAlign: "center" }}>Pool</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <select>
                <option>
                  {impersonateClub?.nameLong || impersonateClub?.name_long}
                </option>
              </select>
            </td>
            <td></td>
            <td style={{ textAlign: "center" }}>
              <TiHomeOutline
                onClick={() =>
                  componentUpdateListner.emit(
                    "setHomeClub",
                    impersonateClub?.clubId || null,
                  )
                }
                style={{
                  color:
                    homeClub === impersonateClub?.clubId
                      ? "var(--primaryblue)"
                      : "lightgray",
                  fontSize: "3rem",
                  alignSelf: "center",
                  cursor: "pointer",
                }}
              />
            </td>
            <td>
              {homeClub === impersonateClub?.clubId ? (
                <select
                  value={pool?.poolId || -1}
                  onChange={(e) => {
                    const newPool = pools.find(
                      (p) => p.poolId === parseInt(e.target.value, 10),
                    )
                    componentUpdateListner.emit("setPool", newPool || null)
                  }}
                >
                  <option value={-1} disabled hidden>
                    Choose Pool
                  </option>
                  {pools.map((thePool) => (
                    <option
                      key={`pool_option_${thePool.poolId}`}
                      value={thePool.poolId}
                    >
                      {thePool.poolName}
                    </option>
                  ))}
                </select>
              ) : null}
            </td>
          </tr>
          {clubSeasonsInMeet
            .filter((cSIM) => cSIM.club.clubId !== impersonateClub?.clubId)
            .map((cS) => (
              <tr key={`club_season_row_${cS.club.clubId}_${cS.season || -1}`}>
                <td>
                  <select
                    value={cS.club.clubId || -1}
                    onChange={(e) => {
                      componentUpdateListner.emit(
                        "swapClubsInMeet",
                        cS.club.clubId as number,
                        parseInt(e.target.value, 10),
                        seasonId,
                        seasonName,
                      )
                    }}
                  >
                    <option value={-1} disabled hidden>
                      Choose Club...
                    </option>
                    {availableClubs.map((cTS) => (
                      <option
                        key={`club_${cS.club.clubId}_${
                          cS.season || -1
                        }_alternate_option_${cTS.clubId}`}
                        value={cTS.clubId}
                      >
                        {cTS.nameLong || cTS.name_long} - {cTS.clubId}
                      </option>
                    ))}
                  </select>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Tooltip title="Remove this club from the meet">
                  <AiOutlineCloseCircle
                    onClick={() =>
                      componentUpdateListner.emit(
                        "removeClub",
                        cS.club.clubId || -1,
                      )
                    }
                    style={{ color: "red", fontSize: "2rem", cursor: "pointer" }}
                  />
                  </Tooltip>
                </td>
                <td style={{ textAlign: "center" }}>
                <Tooltip title="Designate this club as the home team">
                  <TiHomeOutline
                    onClick={() =>
                      componentUpdateListner.emit(
                        "setHomeClub",
                        cS.club.clubId || null,
                      )
                    }
                    style={{
                      color:
                        homeClub === cS.club.clubId
                          ? "var(--primaryblue)"
                          : "lightgray",
                      fontSize: "3rem",
                      alignSelf: "center",
                      cursor: "pointer",
                    }}
                  />
                  </Tooltip>
                </td>
                <td>
                  {homeClub === cS.club.clubId ? (
                    <select
                      value={pool?.poolId || -1}
                      onChange={(e) => {
                        const newPool = pools.find(
                          (p) => p.poolId === parseInt(e.target.value, 10),
                        )
                        componentUpdateListner.emit("setPool", newPool || null)
                      }}
                    >
                      <option value={-1} disabled hidden>
                        Choose Pool
                      </option>
                      {pools.map((thePool) => (
                        <option
                          key={`pool_option_${thePool.poolId}`}
                          value={thePool.poolId}
                        >
                          {thePool.poolName}
                        </option>
                      ))}
                    </select>
                  ) : null}
                </td>
              </tr>
            ))}
        </tbody>
      </AddClubsTable>
      {clubsToSelect.length > 0 ? (
        <Tooltip title="Add all clubs participating in this swim meet">
        <Button 
          onClick={() => componentUpdateListner.emit("addNextClub")}
          type="primary"
          shape="circle"
          size="large"
          icon={<PlusOutlined />}
        />
        &nbsp;Add Club Participating in Meet
        </Tooltip>
      ) : null}
    </div>
  )
}

export default function CreateMeetModal({
  visible,
  setVisible,
  season,
}: ICreateMeetModalProps) {
  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)
  const impersonateClub = useStoreState(
    (state: StateMapper<AppDataStore>) => state.aliasedClub,
  )

  const [isVirtual, setIsVirtual] = useState<boolean | undefined>()
  const [loading, setLoading] = useState(false)
  const [meetEndDate, setMeetEndDate] = useState(
    moment.max(season?.startDate || moment(), moment()),
  )
  const [meetStartDate, setMeetStartDate] = useState(
    moment.max(season?.startDate || moment(), moment()),
  )
  const [meetName, setMeetName] = useState("")
  const [availableEventOrders, setAvailableEventOrders] = useState<
    { eventOrderId: number; name: string }[]
  >([])
  const [eventOrder, setEventOrder] =
    useState<{
      eventOrderId: number
      name: string
    } | null>(null)
  const [availableClubsToAddToMeet, setAvailableClubsToAddToMeet] = useState<
    IClub[]
  >([])
  const [clubSeasonsInMeet, setClubSeasonsInMeet] = useState<
    { club: IClub; season: { seasonId: number; seasonName: string } | null }[]
  >(
    impersonateClub
      ? [
          {
            club: impersonateClub,
            season: season
              ? { seasonId: season.seasonId, seasonName: season.seasonName }
              : null,
          },
        ]
      : [],
  )
  const [homeClub, setHomeClub] = useState<number | null>(
    impersonateClub?.clubId || null,
  )
  const [hasIndividualEntryLimit, setHasIndividualEntryLimit] = useState(true)
  const [individualEntryLimit, setIndividualEntryLimit] = useState<number>(3)
  const [hasIndividualScoringLimit, setHasIndividualScoringLimit] =
    useState(true)
  const [individualScoringLimit, setIndividualScoringLimit] = useState(5)
  const [hasRelayEntryLimit, setHasRelayEntryLimit] = useState(true)
  const [relayEntryLimit, setRelayEntryLimit] = useState<number>(2)
  const [hasRelayScoringLimit, setHasRelayScoringLimit] = useState(true)
  const [relayScoringLimit, setRelayScoringLimit] = useState(5)
  const [poolOptions, setPoolOptions] = useState<IMeetPool[]>([])
  const [pool, setPool] = useState<IMeetPool | null>(null)
  const [virtualCourse, setVirtualCourse] =
    useState<"SCY" | "SCM" | "LCM">("SCY")
  const [individualScoringSettings, setIndividualScoringSettings] = useState([
    { place: 1, points: 5 },
    { place: 2, points: 3 },
    { place: 3, points: 1 },
  ])
  const [relayScoringSettings, setRelayScoringSettings] = useState([
    { place: 1, points: 5 },
  ])

  const getSeasonStartAndEnd = useCallback(() => {
    const seasonStart = season?.startDate || moment().add(1, "days")
    const tomorrow = moment().add(1, "day")
    const seasonEnd = season?.endDate || moment().add(7, "days")
    const meetWindowStart = moment.max(seasonStart, tomorrow)
    const weekFromSeasonStart = meetWindowStart.clone().add(7, "days")
    const meetWindowEnd = moment.min(weekFromSeasonStart, seasonEnd)
    if (meetWindowStart < meetWindowEnd) {
      return [
        moment(meetWindowStart.format("YYYY-MM-DD")),
        moment(meetWindowEnd.format("YYYY-MM-DD")),
      ]
    } else if (meetWindowStart > seasonStart && meetWindowStart < seasonEnd) {
      return [
        moment(meetWindowStart.format("YYYY-MM-DD")),
        moment(meetWindowStart.format("YYYY-MM-DD")),
      ]
    } else if (meetWindowEnd > seasonStart && meetWindowEnd < seasonEnd) {
      return [
        moment(meetWindowEnd.format("YYYY-MM-DD")),
        moment(meetWindowEnd.format("YYYY-MM-DD")),
      ]
    } else {
      return [moment(), moment()]
    }
  }, [season])

  useEffect(() => {
    const [meetWindowStart, meetWindowEnd] = getSeasonStartAndEnd()
    setMeetStartDate(meetWindowStart)
    setMeetEndDate(meetWindowEnd)
  }, [visible, getSeasonStartAndEnd, isVirtual])

  // set up removeClub capability for all components
  useEffect(() => {
    const removeClub = (clubId: number) => {
      const newClubsInMeet = [...clubSeasonsInMeet]
      const clubIds = newClubsInMeet.map((club) => club.club.clubId)
      const idx = clubIds.indexOf(clubId)
      if (idx !== -1) {
        if (newClubsInMeet[idx].club.clubId === homeClub)
          setHomeClub(impersonateClub?.clubId || null)
        newClubsInMeet.splice(idx, 1)
      }
      setClubSeasonsInMeet(newClubsInMeet)
    }

    componentUpdateListner.on("removeClub", removeClub)
    return () => componentUpdateListner.removeAllListeners("removeClub")
  }, [
    clubSeasonsInMeet,
    setClubSeasonsInMeet,
    homeClub,
    setHomeClub,
    impersonateClub,
  ])

  // set up ability to update scoring configuration for individual events
  useEffect(() => {
    componentUpdateListner.on(
      "updateIndividualScoringConfig",
      setIndividualScoringSettings,
    )
    return () =>
      componentUpdateListner.removeAllListeners("updateIndividualScoringConfig")
  }, [setIndividualScoringSettings])

  // set up ability to update scoring configuration for relay events
  useEffect(() => {
    componentUpdateListner.on(
      "updateRelayScoringConfig",
      setRelayScoringSettings,
    )
    return () =>
      componentUpdateListner.removeAllListeners("updateRelayScoringConfig")
  }, [setRelayScoringSettings])

  // set up ability to update a club's season - to use for virtual meets
  useEffect(() => {
    const updateSeasonForClubInMeet = (
      clubId: number,
      newSeasonId: number,
      newSeasonName: string,
    ) => {
      const newClubSeasonsInMeet = cloneDeep(clubSeasonsInMeet)
      const thisClub = newClubSeasonsInMeet.find(
        (cl) => cl.club.clubId === clubId,
      )
      if (thisClub) {
        thisClub.season = { seasonId: newSeasonId, seasonName: newSeasonName }
      }
      setClubSeasonsInMeet(newClubSeasonsInMeet)
    }
    componentUpdateListner.on(
      "updateSeasonForClubInMeet",
      updateSeasonForClubInMeet,
    )
    return () =>
      componentUpdateListner.removeAllListeners("updateSeasonForClubInMeet")
  }, [clubSeasonsInMeet, setClubSeasonsInMeet])

  // set up addNextClub capability for all components
  useEffect(() => {
    const addNextClub = () => {
      const clubIdsInMeet = clubSeasonsInMeet.map((c) => c.club.clubId)
      const clubsToSelect = availableClubsToAddToMeet.filter(
        (c) => !clubIdsInMeet.includes(c.clubId),
      )
      if (clubsToSelect.length > 0) {
        const newClubsInMeet = [...clubSeasonsInMeet]
        const nextClub = clubsToSelect[0]
        newClubsInMeet.push({
          club: nextClub,
          season:
            season && !isVirtual
              ? { seasonId: season.seasonId, seasonName: season.seasonName }
              : null,
        })
        setClubSeasonsInMeet(newClubsInMeet)
      }
    }
    componentUpdateListner.on("addNextClub", addNextClub)
    return () => componentUpdateListner.removeAllListeners("addNextClub")
  }, [
    availableClubsToAddToMeet,
    clubSeasonsInMeet,
    setClubSeasonsInMeet,
    season,
    isVirtual,
  ])

  // set up ability to swap a club that is signed up for the meet with one that is not
  useEffect(() => {
    const swapClubsInMeet = (
      oldClubId: number,
      newClubId: number,
      seasonId: number | null,
      seasonName: string | null,
    ) => {
      const clubIds = clubSeasonsInMeet.map(
        (clubSeasonInMeet) => clubSeasonInMeet.club.clubId,
      )

      if (clubIds.includes(newClubId)) return

      const newClubSeasonsInMeet = cloneDeep(clubSeasonsInMeet)
      const newClub = availableClubsToAddToMeet.find(
        (searchClub) => searchClub.clubId === newClubId,
      )
      const idx = newClubSeasonsInMeet
        .map((nCSIM) => nCSIM.club.clubId)
        .indexOf(oldClubId)
      if (newClub) {
        if (seasonId === null || seasonName === null) {
          newClubSeasonsInMeet.splice(idx, 1, {
            club: newClub,
            season: null,
          })
        } else {
          newClubSeasonsInMeet.splice(idx, 1, {
            club: newClub,
            season: { seasonId, seasonName },
          })
        }
      }
      if (homeClub === oldClubId) {
        setHomeClub(impersonateClub?.clubId || -1)
      }
      setClubSeasonsInMeet(newClubSeasonsInMeet)
    }

    componentUpdateListner.on("swapClubsInMeet", swapClubsInMeet)
    return () => componentUpdateListner.removeAllListeners("swapClubsInMeet")
  }, [availableClubsToAddToMeet, clubSeasonsInMeet, homeClub, impersonateClub])

  // update the list of available pools for traditional meets based on the season
  useEffect(() => {
    if (season?.seasonId) {
      swimminglyApi
        .get(gen(`/api/getSeasonHomePools/${season?.seasonId}`))
        .then((data) => {
          if (data.status === "success") {
            const poolOptionData = data.pools.map((pool: any) => ({
              poolName: pool.pool_name,
              poolId: pool.poolId,
              address: pool.address,
              length: pool.pool_length,
              course: pool.course_label,
            }))
            setPoolOptions(poolOptionData)
          }
        })
    }
  }, [season?.seasonId])

  // set up ability to set the meet's pool from other components without prop drilling
  useEffect(() => {
    componentUpdateListner.on("setPool", setPool)
    return () => componentUpdateListner.removeAllListeners("setPool")
  }, [setPool])

  // set up ability to set the meet's home club from other components without prop drilling
  useEffect(() => {
    componentUpdateListner.on("setHomeClub", setHomeClub)
    return () => componentUpdateListner.removeAllListeners("setHomeClub")
  }, [setHomeClub])

  // set the meet's home club to the user's aliased club by default (can be overridden later by user)
  useEffect(() => {
    setHomeClub(impersonateClub?.clubId || null)
  }, [impersonateClub])

  // get the list of clubs that we can add to the meet based on whether or not the meet is virtual & the season
  useEffect(() => {
    if (!season?.seasonId) return
    if (!isVirtual) {
      swimminglyApi
        .get(`/api/getClubsInSeason/${season.seasonId}`)
        .then((data) => {
          if (data.status === "success") {
            setAvailableClubsToAddToMeet(data.clubs)
          }
        })
    } else {
      swimminglyApi.get(`/api/getAllClubsRawFormat`).then((data) => {
        if (data.status === "success") {
          setAvailableClubsToAddToMeet(data.clubs)
        }
      })
    }
  }, [season?.seasonId, isVirtual])

  // whenever the user's aliased club or the selected season changes, or whenever the modal is opened or
  // closed, by default choose the user's aliased club to be the only club included in the meet
  useEffect(() => {
    setClubSeasonsInMeet(
      impersonateClub
        ? [
            {
              club: impersonateClub,
              season: season
                ? { seasonId: season.seasonId, seasonName: season.seasonName }
                : null,
            },
          ]
        : [],
    )
  }, [impersonateClub, season, visible])

  // if a season is chosen or if the meet is changed from a virtual meet to a traditional meet, update
  // the season for each club to be the selected season
  useEffect(() => {
    if (season?.seasonId && !isVirtual) {
      const numBadSeasons = clubSeasonsInMeet.filter(
        (cl) => cl.season?.seasonId !== season.seasonId,
      ).length
      if (numBadSeasons > 0) {
        setClubSeasonsInMeet(
          clubSeasonsInMeet.map((cl) => ({
            ...cl,
            season: {
              seasonId: season.seasonId,
              seasonName: season.seasonName,
            },
          })),
        )
      }
    }
  }, [season, clubSeasonsInMeet, isVirtual])

  // if the user switches from a virtual meet to a regular meet, remove the out-of-conference
  // teams from the clubs in the meet
  useEffect(() => {
    if (!isVirtual) {
      const clubIdsInMeet = clubSeasonsInMeet.map((cS) => cS.club.clubId)
      const possibleClubIds = availableClubsToAddToMeet.map((aC) => aC.clubId)
      let badClubs = clubIdsInMeet.filter(
        (id) => !possibleClubIds.includes(id) && id !== impersonateClub?.clubId,
      )
      // figure out if there are clubs in the meet that shouldn't be there
      if (badClubs.length > 0) {
        // get an array of the clubs in the meet and a corresponding numeric array of their ids
        const newClubSeasonsInMeet = cloneDeep(clubSeasonsInMeet).filter(
          (cl) => cl.club.clubId !== undefined,
        )
        const newClubIds = newClubSeasonsInMeet.map(
          (nCS) => nCS.club.clubId as number,
        )
        // find the clubIds that specifically should NOT be in the meet but are there
        // these ids need to be removed
        badClubs = badClubs.filter((bC) => bC !== undefined)
        // for each of the clubIds that needs to be removed, go through the existing list of clubs in
        // the meet and splice out any clubSeasons with that clubId
        for (let badClubId of badClubs) {
          let badIdx: number | undefined
          // as long as the badClubId is still in the list of clubIds in the meet, keep removing occurrences
          while ((badIdx = newClubIds.indexOf(badClubId as number)) !== -1) {
            // if we're inside this loop it means we found the bad clubId in the meet
            // this is where we remove it
            newClubSeasonsInMeet.splice(badIdx, 1)
            newClubIds.splice(badIdx, 1)
          }
        }
        // at this point we've gone through every clubId that shouldn't be in the meet and we've removed
        // the corresponding clubs. now let's update our state
        setClubSeasonsInMeet(newClubSeasonsInMeet)
      }
    }
  }, [
    impersonateClub?.clubId,
    clubSeasonsInMeet,
    availableClubsToAddToMeet,
    isVirtual,
  ])

  const getAvailableEventOrdersForClub = useCallback(
    (seasonId: number) => {
      const getLeagueEventOrders = swimminglyApi
        .get(gen(`/api/getLeagueEventOrdersForSeason/${seasonId}`))
        .catch((err) => {
          message.error("Problem finding league event orders")
          return []
        })
      const getClubEventOrders = swimminglyApi
        .post(gen(`/api/getClubEventOrders`))
        .body({ clubId: impersonateClub?.clubId })
        .catch((err) => {
          message.error("Problem finding club event orders")
          return []
        })
      Promise.all([getLeagueEventOrders, getClubEventOrders])
        .then(([leagueData, clubData]: any[]) => {
          const newAvailableEventOrders: {
            eventOrderId: number
            name: string
          }[] = []
          if (leagueData.status === "success") {
            const leagueEventOrders: {
              eventOrderId: number
              name: string
            }[] = leagueData.eventOrderArray.map((evtOrd: any) => ({
              eventOrderId: evtOrd.id,
              name: evtOrd.name,
            }))
            newAvailableEventOrders.push(...leagueEventOrders)
          }
          if (clubData.status === "success") {
            const clubEventOrders: {
              eventOrderId: number
              name: string
            }[] = clubData.eventOrderArray.map((evtOrd: any) => ({
              eventOrderId: evtOrd.id,
              name: evtOrd.name,
            }))
            newAvailableEventOrders.push(...clubEventOrders)
          }
          return newAvailableEventOrders
        })
        .then(setAvailableEventOrders)
    },
    [impersonateClub],
  )

  // get all of the available event orders based on the aliased club and season
  useEffect(() => {
    let seasonId = season?.seasonId
    if (seasonId) {
      getAvailableEventOrdersForClub(seasonId)
    }
  }, [getAvailableEventOrdersForClub, season?.seasonId])

  const resetState = useCallback(() => {
    setIsVirtual(undefined)
    setLoading(false)
    const [meetWindowStart, meetWindowEnd] = getSeasonStartAndEnd()
    setMeetStartDate(meetWindowStart)
    setMeetEndDate(meetWindowEnd)
    setMeetName("")
    setEventOrder(null)
    setClubSeasonsInMeet(
      impersonateClub ? [{ club: impersonateClub, season: null }] : [],
    )
    setHomeClub(impersonateClub?.clubId || null)
    setHasIndividualEntryLimit(true)
    setIndividualEntryLimit(3)
    setHasRelayEntryLimit(true)
    setRelayEntryLimit(2)
    setPool(null)
    setIndividualScoringSettings([
      { place: 1, points: 5 },
      { place: 2, points: 3 },
      { place: 3, points: 1 },
    ])
    setIndividualScoringLimit(5)
    setHasIndividualScoringLimit(true)
    setRelayScoringSettings([{ place: 1, points: 5 }])
    setRelayScoringLimit(5)
    setHasRelayScoringLimit(true)
  }, [impersonateClub, getSeasonStartAndEnd])

  // reset everything when the modal is opened or closed
  useEffect(resetState, [resetState, visible])

  const addMeet = async () => {
    if (!impersonateClub) {
      message.error("You have to be aliased to a club to create a meet")
      return
    }
    if (!meetName || meetName.trim() === "") {
      message.error("Please enter a valid meet name")
      return
    }
    if (meetName.trim().length < 2) {
      message.error("Please enter a longer meet name")
      return
    }
    if (!eventOrder) {
      message.error("Must choose event order")
      return
    }
    let validClubSeasons = true
    clubSeasonsInMeet.forEach((clubSeason) => {
      if (!clubSeason.season?.seasonId) {
        validClubSeasons = false
      }
    })
    if (!validClubSeasons && isVirtual) {
      message.error(
        "Please select a season roster for each club in the virtual meet",
      )
      return
    }
    if (!validClubSeasons) {
      message.error(
        "It looks like you haven't tagged this meet to a swim season.",
      )
      return
    }
    if (!isVirtual && !pool) {
      message.error("Please choose a home pool for this meet")
      return
    }
    const postBody = {
      aliasedClubId: impersonateClub.clubId,
      clubSeasons: clubSeasonsInMeet.map((cS) => ({
        clubId: cS.club.clubId,
        seasonId: cS.season?.seasonId,
      })),
      course: isVirtual ? virtualCourse : pool?.course || "SCY",
      eventOrderId: eventOrder.eventOrderId,
      homeClubId: homeClub,
      individualEntryLimit: hasIndividualEntryLimit
        ? individualEntryLimit
        : null,
      individualScoringArray: individualScoringSettings,
      individualScoringLimit: hasIndividualScoringLimit
        ? individualScoringLimit
        : null,
      meetDate: isVirtual
        ? meetEndDate.format("YYYY-MM-DD")
        : meetStartDate.format("YYYY-MM-DD"),
      meetName,
      meetPoolId: pool?.poolId,
      meetTime: isVirtual
        ? meetEndDate.format("hh:mma")
        : meetStartDate.format("hh:mma"),
      relayEntryLimit: hasRelayEntryLimit ? relayEntryLimit : null,
      relayScoringArray: relayScoringSettings,
      relayScoringLimit: hasRelayScoringLimit ? relayScoringLimit : null,
      startDate: meetStartDate.format("YYYY-MM-DD"),
      createdBy: user?.userId,
    }
    const url = isVirtual
      ? gen(`/api/createVirtualSwimMeet`)
      : gen(`/api/createTraditionalSwimMeet`)
    swimminglyApi
      .post(url)
      .body(postBody)
      .then((data) => {
        if (data.status === "success") {
          message.success("Success!")
          if (season?.clubSeasonId) {
            // this will refresh the list of meets in the season in the MeetSchedulePage component
            updateSeasonMeetArrayListener.emit(
              `updateSeasonMeets${season?.clubSeasonId}`,
            )
            setVisible(false)
          }
        }
      })
  }

  return (
    <StyledMeetModal
      title={
        <span>
          Add Meet to Season:{" "}
          <b>{season?.seasonName || "NO SEASON SELECTED"}</b>
        </span>
      }
      width="100%"
      visible={visible}
      onOk={addMeet}
      onCancel={() => setVisible(false)}
      confirmLoading={loading}
      footer={[
        <div style={{ marginLeft: 70, clear: 'both', whiteSpace: 'normal' }}>
        <Button
          key="cancel"
          type="default"
          shape="round"
          loading={loading} 
          onClick={() => setVisible(false)}
        >
          Cancel
        </Button>,
        <Popconfirm
          title="Please Confirm all meet settings are accurate, including adding all participating clubs to this swim meet."
          onConfirm={addMeet}
          cancelText="Continue Working"
          okText="Confirmed - Create My Swim Meet"
          placement="bottom"
          autoAdjustOverflow={true}
          icon={<ExclamationCircleOutlined />}
      >
        <Button 
          key="submit" 
          type="primary"
          shape="round"
        >
          Create Swim Meet
        </Button>,
        </Popconfirm>
        </div>
      ]}
    >
     <div className="overview-info">
        <label htmlFor="meet-name-input">Meet Name:</label>
        <input
          id="meet-name-input"
          placeholder="Type the name of your swim meet..."
          value={meetName}
          onChange={(e) => setMeetName(e.target.value)}
        />
        
       <label htmlFor="event-order-input">Event Order:</label>
        <select
          className="form-select"
          value={eventOrder?.eventOrderId || -1}
          onChange={(e) => {
            const selectedEventOrder = availableEventOrders.find(
              (aEO) => aEO.eventOrderId === parseInt(e.target.value, 10),
            )
            setEventOrder(selectedEventOrder || null)
          }}
        >
          <option 
            value={-1} 
            disabled 
            hidden
            
          >
            
          </option>
          <option disabled>Available League & Club Event Orders</option>
          {availableEventOrders.map((eO) => (
            <option
              key={`event_order_${eO.eventOrderId}`}
              value={eO.eventOrderId}
            >
              {eO.name}
            </option>
          ))}
        </select>
        <div className="overview-info meet-type-container">
        <label>Meet Type:</label>
          <div className="choice">
            <Tooltip title="All teams swim together in the same location">
              <button
                className={
                  isVirtual === undefined || isVirtual ? "not-chosen" : "chosen"
                }
                onClick={() => setIsVirtual(false)}
              >
                Traditional Meet
              </button>
            </Tooltip>
            <Tooltip title="Each team swims safely from its own location. Results are combined automatically when you publish the meet!">
              <button
                className={
                  isVirtual !== undefined && isVirtual ? "chosen" : "not-chosen"
                }
                onClick={() => setIsVirtual(true)}
              >
                Virtual Meet
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
      {isVirtual !== undefined ? (
        <Fragment>
          <hr />
          <h4 
            className="title"
          >
          <Tooltip title="Make sure you add all the clubs to this swim meet that are participating">
            Add All Clubs to Swim Meet
          </Tooltip>
          </h4>
          {isVirtual ? (
            season?.seasonId && (
              <VirtualClubsInMeetDisplay
                clubSeasonsInMeet={clubSeasonsInMeet}
                availableClubs={availableClubsToAddToMeet}
                season={season}
                meetEndDate={meetEndDate}
              />
            )
          ) : (
            <div>
              {season?.seasonId && (
                <RegularClubsInMeetDisplay
                  clubSeasonsInMeet={clubSeasonsInMeet}
                  availableClubs={availableClubsToAddToMeet}
                  seasonId={season.seasonId}
                  seasonName={season.seasonName}
                  homeClub={homeClub}
                  pools={poolOptions}
                  pool={pool}
                />
              )}
            </div>
          )}
          <hr />
          {isVirtual ? (
            <div className="virtual-course">
              <Tooltip title="This will be the course used for the Virtual Results - all legs of the virtual meet will be run in their pool's respective course & automatically be converted to this virtual course.">
                <label>Virtual Course:&nbsp;</label>
              </Tooltip>
              <select
                value={virtualCourse}
                onChange={(e) =>
                  setVirtualCourse(e.target.value as "SCY" | "SCM" | "LCM")
                }
              >
                <option value="SCY">SCY</option>
                <option value="SCM">SCM</option>
                <option value="LCM">LCM</option>
              </select>
            </div>
          ) : (
            <Tooltip title="The Course and Address are determined by the Pool selected above">
            <div className="overview-info">
              <label>Course</label>
              <input
                value={
                  !pool?.course
                    ? ""
                    : pool.course === "SCY"
                    ? `SCY (${pool.length}y)`
                    : `${pool.course} (${DistanceFormatter.metersFromYards(pool.length)})`
                }
                disabled
              />
              <label>Address</label>
              <input value={pool?.address || ""} disabled />
            </div>
            </Tooltip>
          )}
          <hr />
          <h4 className="title">{isVirtual ? "Meet Date Window" : "Meet Date"}</h4>
          <span className="date-pickers">
            {/* start date */}
            <span>
              <label>{isVirtual ? "Meet Window Starts:" : "Meet Date:"}</label>
              <DatePicker
                format="YYYY-MM-DD hh:mm a"
                showTime={{ format: "hh:mm a" }}
                showNow={false}
                value={meetStartDate}
                disabledDate={(dateToCheck) => {
                  if (!dateToCheck) return true
                  if (!season?.endDate) return true
                  if (!season?.startDate) return true
                  if (season.endDate.diff(dateToCheck, "days") < 0) return true
                  if (season.startDate.diff(dateToCheck, "days") > 0)
                    return true
                  if (isVirtual) {
                    if (meetEndDate.diff(dateToCheck, "days") < 0) return true
                  }
                  return false
                }}
                onChange={(newDate) => {
                  if (newDate !== null) {
                    setMeetStartDate(newDate)
                    if (!isVirtual) {
                      setMeetEndDate(newDate)
                    }
                  }
                }}
              />
            </span>
            {/* end date */}
            {isVirtual && (
              <span>
                <label>Meet Window Ends:</label>
                <DatePicker
                  format="YYYY-MM-DD hh:mm a"
                  showTime={{ format: "hh:mm a" }}
                  showNow={false}
                  value={meetEndDate}
                  disabledDate={(dateToCheck) => {
                    if (!dateToCheck) return true
                    if (!season?.endDate) return true
                    if (!season?.startDate) return true
                    if (season.endDate.diff(dateToCheck, "days") < 0)
                      return true
                    if (season.startDate.diff(dateToCheck, "days") > 0)
                      return true
                    if (isVirtual) {
                      if (meetStartDate.diff(dateToCheck, "days") > 0)
                        return true
                    }
                    return false
                  }}
                  onChange={(newDate) => {
                    if (newDate !== null) {
                      setMeetEndDate(newDate)
                      if (!isVirtual) {
                        setMeetStartDate(newDate)
                      }
                    }
                  }}
                />
              </span>
            )}
          </span>
          <hr />
          <span className="entry-limits">
            <h4 className="title">Entry Limits</h4>
            <Tooltip title="Maximum number of individual events for a swimmer in this meet.">
              <label className="entry-limit-label">Individual Limit</label>
            </Tooltip>
            <input
              type="number"
              disabled={!hasIndividualEntryLimit}
              value={individualEntryLimit}
              onChange={(e) =>
                setIndividualEntryLimit(parseInt(e.target.value, 10))
              }
            />
            <label
              style={{
                paddingLeft: "5px",
                display: "inline-block",
                alignItems: "center",
                alignSelf: "center",
              }}
            >
              <input
                style={{ verticalAlign: "middle" }}
                type="checkbox"
                checked={!hasIndividualEntryLimit}
                onChange={(e) => setHasIndividualEntryLimit(!e.target.checked)}
              />
              &nbsp;No Limit
            </label>
            <Tooltip title="Maximum number of relay events for a swimmer in this meet.">
              <label className="entry-limit-label">Relay Limit</label>
            </Tooltip>
            <input
              type="number"
              disabled={!hasRelayEntryLimit}
              value={relayEntryLimit}
              onChange={(e) => setRelayEntryLimit(parseInt(e.target.value, 10))}
            />
            <label
              style={{
                paddingLeft: "5px",
                display: "inline-block",
                alignItems: "center",
                alignSelf: "center",
              }}
            >
              <input
                style={{ verticalAlign: "middle" }}
                type="checkbox"
                checked={!hasRelayEntryLimit}
                onChange={(e) => setHasRelayEntryLimit(!e.target.checked)}
              />
              &nbsp;No Limit
            </label>
          </span>
          {isVirtual ? (
            <Fragment>
              <hr />
              <h4 className="title">Scoring Configuration</h4>
              <div className="two-columns">
                <IndividualScoringConfig
                  individualScoring={individualScoringSettings}
                />
                <RelayScoringConfig relayScoring={relayScoringSettings} />
              </div>
              <div className="two-columns">
                <div className="shadowed">
                  <p>Individual Scoring Limit</p>
                  <div className="two-columns no-mobile">
                    <input
                      type="number"
                      disabled={!hasIndividualScoringLimit}
                      value={individualScoringLimit}
                      onChange={(e) =>
                        setIndividualScoringLimit(parseInt(e.target.value, 10))
                      }
                    />
                    <label
                      style={{
                        paddingLeft: "5px",
                        display: "inline-block",
                        alignItems: "center",
                        alignSelf: "center",
                      }}
                    >
                      <input
                        style={{ verticalAlign: "middle" }}
                        type="checkbox"
                        checked={!hasIndividualScoringLimit}
                        onChange={(e) =>
                          setHasIndividualScoringLimit(!e.target.checked)
                        }
                      />
                      &nbsp;No Limit
                    </label>
                  </div>
                </div>
                <div className="shadowed">
                  <p>Relay Scoring Limit</p>
                  <div className="two-columns no-mobile">
                    <input
                      type="number"
                      disabled={!hasRelayScoringLimit}
                      value={relayScoringLimit}
                      onChange={(e) =>
                        setRelayScoringLimit(parseInt(e.target.value, 10))
                      }
                    />
                    <label
                      style={{
                        paddingLeft: "5px",
                        display: "inline-block",
                        alignItems: "center",
                        alignSelf: "center",
                      }}
                    >
                      <input
                        style={{ verticalAlign: "middle" }}
                        type="checkbox"
                        checked={!hasRelayScoringLimit}
                        onChange={(e) =>
                          setHasRelayScoringLimit(!e.target.checked)
                        }
                      />
                      &nbsp;No Limit
                    </label>
                  </div>
                </div>
              </div>
            </Fragment>
          ) : null}
          
        </Fragment>
      ) : null}
    </StyledMeetModal>
  )
}
