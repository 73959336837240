import { Table } from "antd"
import { Fragment, FunctionComponent } from "react"
import { IPriceModificationCode } from "../ClubSeasonManagement/sharedState"
import { formatMoney } from "../utils"

type TAdditionalItemsPreviewProps = {
   additonalItems:  IPriceModificationCode[] 
}

export const AdditionalItemsPreview: FunctionComponent<TAdditionalItemsPreviewProps> = ({additonalItems}) => {
    if (additonalItems.length === 0) {
        return null
    }
    const tableData = additonalItems.map((additionalItem) => {
    return (
        {
            itemName: additionalItem.code,
            costOfItem: formatMoney(additionalItem.priceModificationAmount)
        }
    )   
    })

    const columns = [{
        title: 'Additional Checkout Item', 
        dataIndex: 'itemName',
        key: 'itemName' 
    }, {
        title: '$ Cost of Item',
        dataIndex: 'costOfItem',
        key: 'costOfItem"'
    }
]

    return (
        <Fragment>
            <div style={{
                marginTop: "15px",
                marginBottom: "15px",
                textAlign: "center",
                fontSize: "18px",
            }}>
                <p>
                    The following table shows additional checkout items that will be made available to parents/guardians at checkout and their list price. Please verify their accuracy and make any necessary corrections on the "Setup Additional Checkout Items" tab under Step 3 of Club Registration.
                </p>
                         <Table  size="small" dataSource={tableData}
                         columns={columns}
                        pagination={false} 
                         />                 
            </div>



        </Fragment>
    )
}