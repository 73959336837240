import { PlusOutlined } from "@ant-design/icons"
import { Button } from "antd"
import { FunctionComponent } from "react"

interface IAddItemButtonProps {
  style?: React.CSSProperties | undefined
  buttonStyle?: React.CSSProperties | undefined
  onClick?: React.MouseEventHandler<HTMLElement> | undefined
  text: string
}
export const AddItemButton: FunctionComponent<IAddItemButtonProps> = ({
  style,
  buttonStyle,
  onClick,
  text,
}) => {
  return (
    <span style={style || { display: "flex", alignItems: "center" }}>
      <Button
        type="primary"
        style={
          buttonStyle || {
            borderRadius: "50px",
            padding: "10px",
            marginRight: "5px",
            display: "flex",
            alignItems: "center",
          }
        }
        onClick={
          onClick ||
          (() => {
            console.log("no onClick")
          })
        }
      >
        <PlusOutlined />
      </Button>{" "}
      {text}
    </span>
  )
}
