import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { message, Form, InputNumber, Button, DatePicker, Select, Table, Tooltip, Switch, Input, Modal } from 'antd';
import moment from 'moment';
import { swimminglyApi, gen } from './utils';
import { QuestionCircleOutlined, DeleteOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;
const { Option } = Select;

interface Club {
  clubId: number;
  name_long: string;
  isSwimminglyCustomer: number;
  corporatePaymentId: number | null;
  clubPays: number;
  parentPays: number;
  seedPremium: number;
  seasonSetupConfirmedOn: string | null;
  seasonSetupConfirmedBy: string | null;
  signUpsOpenDate: string | null;
  confirmedByName?: string | null;
  confirmedByEmail?: string | null;
  registrationStarted: number | null;
  usingRegistration: number;
  isNew?: boolean;
}

interface SeasonFormValues {
  seasonId: number;
  seasonName: string;
  startDate: string;
  endDate: string;
  seasonType: string;
  cutOffType: string;
  cutOffDate: string | null;
  selectedClubs: Club[];
}

const UpdateSwimSeason: React.FC = () => {
  const { seasonId } = useParams<{ seasonId: string }>();
  const [seasonData, setSeasonData] = useState<SeasonFormValues | null>(null);
  const [allSeasons, setAllSeasons] = useState<SeasonFormValues[]>([]);
  const [selectedSeasonId, setSelectedSeasonId] = useState<number | null>(null);
  const [form] = Form.useForm();
  const [showCutOffDate, setShowCutOffDate] = useState<boolean>(true);
  const [corporatePayments, setCorporatePayments] = useState<any[]>([]);
  const [availableClubs, setAvailableClubs] = useState<Club[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // New state variables for add club modal
  const [isAddClubModalVisible, setIsAddClubModalVisible] = useState(false);
  const [clubToBeAdded, setClubToBeAdded] = useState<Club | null>(null);

  useEffect(() => {
    const fetchAllSeasons = async () => {
      try {
        const response = await swimminglyApi.get(gen(`/api/getAllSeasons`));
        if (response.status === 'success') {
          setAllSeasons(response.seasons);
        } else {
          message.error(response.message || 'Failed to fetch seasons');
        }
      } catch (error) {
        message.error('Error fetching all seasons');
      }
    };
    fetchAllSeasons();
  }, []);

  useEffect(() => {
    if (selectedSeasonId) {
      fetchSeasonData(selectedSeasonId);
    }
  }, [selectedSeasonId]);

  useEffect(() => {
    const fetchCorporatePayments = async () => {
      try {
        const response = await swimminglyApi.get(gen(`/api/getAllLumpSumPayments`));
        if (response.status === 'success') {
          setCorporatePayments(response.lumpSumPayments.sort((a: any, b: any) => b.corporatePaymentId - a.corporatePaymentId));
        } else {
          message.error(response.message || 'Failed to fetch corporate payments');
        }
      } catch (error) {
        message.error('Error fetching corporate payments');
      }
    };
    fetchCorporatePayments();
  }, []);

  useEffect(() => {
    const fetchAvailableClubs = async () => {
      try {
        const response = await swimminglyApi.get(gen(`/api/getAllClubsWithHistoricalLeagues`));
        if (response.status === 'success') {
          setAvailableClubs(response.clubs);
        } else {
          message.error(response.message || 'Failed to fetch available clubs');
        }
      } catch (error) {
        message.error('Error fetching available clubs');
      }
    };
    fetchAvailableClubs();
  }, []);

  useEffect(() => {
    if (seasonData) {
      form.setFieldsValue({
        seasonName: seasonData.seasonName,
        seasonDates: [moment(seasonData.startDate), moment(seasonData.endDate)],
        seasonType: seasonData.seasonType,
        cutOffType: seasonData.cutOffType,
        cutOffDate: seasonData.cutOffDate ? moment(seasonData.cutOffDate) : null,
      });
      setShowCutOffDate(seasonData.cutOffType !== 'meetStart');
    }
  }, [seasonData, form]);

  const fetchSeasonData = async (seasonId: number) => {
    try {
      const seasonResponse = await swimminglyApi.get(gen(`/api/getSwimSeasonById/${seasonId}`));
      if (seasonResponse.status === 'success') {
        setSeasonData({
          ...seasonResponse.season,
          selectedClubs: seasonResponse.clubs,
        });
      } else {
        message.error(seasonResponse.message || 'Failed to fetch season data');
      }
    } catch (error) {
      message.error('Error fetching season data');
    }
  };

  const handleCutOffTypeChange = (value: string) => {
    setShowCutOffDate(value !== 'meetStart');
    if (value === 'meetStart') {
      form.setFieldsValue({ cutOffDate: null });
    }
  };

  const handleInputChange = (clubId: number, field: string, value: number | null) => {
    setSeasonData((prevState) => {
      if (!prevState) return prevState;
      const updatedClubs = prevState.selectedClubs.map((club) => {
        if (club.clubId === clubId) {
          const updatedClub = { ...club, [field]: value !== null ? Math.round(value * 100) : 0 }; // Convert dollars to pennies

          if (field === 'parentPays' && value !== 0) {
            updatedClub.clubPays = 0;
            updatedClub.corporatePaymentId = null;
          }

          if (field === 'clubPays' && value !== 0) {
            updatedClub.parentPays = 0;
            updatedClub.corporatePaymentId = null;
          }

          return updatedClub;
        }
        return club;
      });
      return { ...prevState, selectedClubs: updatedClubs };
    });
  };

  const handleSwitchChange = (clubId: number, field: string, checked: boolean) => {
    setSeasonData((prevState) => {
      if (!prevState) return prevState;
      const updatedClubs = prevState.selectedClubs.map((club) => {
        if (club.clubId === clubId) {
          const updatedClub = { ...club, [field]: checked ? 1 : 0 };
  
          if (!checked) { // If switched to Guest
            updatedClub.seedPremium = 0; // Set Premium/Basic to Basic (0)
            updatedClub.parentPays = 0; // Set By Parent Payment to 0
            updatedClub.clubPays = 0; // Set By Club Payment to 0
            updatedClub.corporatePaymentId = null; // Set Offline Payment to None
            updatedClub.usingRegistration = 0; // Ensure usingRegistration is 0
          }
  
          return updatedClub;
        }
        return club;
      });
      return { ...prevState, selectedClubs: updatedClubs };
    });
  };

  const handleSelectChange = (clubId: number, field: string, value: any) => {
    setSeasonData((prevState) => {
      if (!prevState) return prevState;
      const updatedClubs = prevState.selectedClubs.map((club) => {
        if (club.clubId === clubId) {
          const updatedClub = { ...club, [field]: value };

          if (field === 'corporatePaymentId' && value !== null) {
            updatedClub.parentPays = 0;
            updatedClub.clubPays = 0;
          }

          return updatedClub;
        }
        return club;
      });
      return { ...prevState, selectedClubs: updatedClubs };
    });
  };

  const handleDateChange = (clubId: number, date: moment.Moment | null) => {
    setSeasonData((prevState) => {
      if (!prevState) return prevState;
      
      const updatedClubs = prevState.selectedClubs.map((club) => {
        if (club.clubId === clubId) {
          if ((club.registrationStarted ?? 0) > 0 && date === null) {
            // If registration has started and date is being set to null, prevent the change
            message.error("Registration has started, you cannot nullify the Registration Date.");
            return club; // Return the club as is without modifying the date
          }
  
          // Update only the signUpsOpenDate field
          return { 
            ...club, 
            signUpsOpenDate: date ? date.format('YYYY-MM-DD') : null
          };
        }
        return club;
      });
  
      return { ...prevState, selectedClubs: updatedClubs };
    });
  };

  const handleAddClub = (clubId: number) => {
    const selectedClub = availableClubs.find(club => club.clubId === clubId);
    if (!selectedClub) return;

    const isClubAlreadyAdded = seasonData?.selectedClubs.some(club => club.clubId === clubId);
    if (isClubAlreadyAdded) {
      message.error('This club is already added to the season.');
      return;
    }

    // Store the selected club and show the confirmation modal
    setClubToBeAdded(selectedClub);
    setIsAddClubModalVisible(true);
  };

  const handleRemoveClub = (clubId: number) => {
    setSeasonData((prevState) => {
      if (!prevState) return prevState;

      const updatedClubs = prevState.selectedClubs.filter(club => !(club.clubId === clubId && club.isNew));

      return { ...prevState, selectedClubs: updatedClubs };
    });
  };

  const handleSubmit = async () => {
    const values = await form.validateFields();
    const updatedClubs = seasonData?.selectedClubs.map(club => {
        // If the club is not a customer, ensure usingRegistration is set to 0
        if (club.isSwimminglyCustomer !== 1) {
            return { ...club, usingRegistration: 0 };
        }
        return club;
    }) || [];

    const payload = {
        ...values,
        seasonId: selectedSeasonId,
        startDate: values.seasonDates ? values.seasonDates[0].format('YYYY-MM-DD') : null,
        endDate: values.seasonDates ? values.seasonDates[1].format('YYYY-MM-DD') : null,
        cutOffDate: values.cutOffType === 'meetStart' ? null : values.cutOffDate ? values.cutOffDate.format('YYYY-MM-DD') : null,
        selectedClubs: updatedClubs
    };

    console.log('Submitting payload:', payload);  // Log the payload being sent to the backend

    try {
      const response = await swimminglyApi.put(gen(`/api/updateSwimSeason`)).body(payload);
      if (response.status === 'success') {
        message.success('Season updated successfully');
        setIsModalVisible(false);
      } else {
        message.error(response.message || 'Failed to update season');
      }
    } catch (error) {
      console.error('Error during API call:', error);  // Log the error for more details
      message.error('Error updating season');
    }
  };

  const validateCutOffDate = (_: any, value: moment.Moment | null) => {
    if (!value || !seasonData) {
      return Promise.resolve();
    }
    if (value.isBefore(seasonData.startDate) || value.isAfter(seasonData.endDate)) {
      return Promise.reject(new Error('Cut Off Date must be within the season start and end dates'));
    }
    return Promise.resolve();
  };

  const columns = [
    {
      title: '',
      key: 'remove',
      render: (text: string, record: Club) => (
        <Tooltip title="You cannot remove a club from the season once it has been added.">
          <DeleteOutlined style={{ color: 'gray', cursor: 'not-allowed' }} />
        </Tooltip>
      ),
      width: '50px',
    },
    {
      title: <strong>Club Name</strong>,
      dataIndex: 'name_long',
      key: 'name_long',
      render: (text: string) => <div style={{ textAlign: 'left' }}>{text}</div>,
      ellipsis: false,
    },
    {
      title: <strong>Club ID</strong>,
      dataIndex: 'clubId',
      key: 'clubId',
      ellipsis: false,
    },
    {
      title: (
        <div>
          <strong>Customer / Guest</strong>
        </div>
      ),
      dataIndex: 'isSwimminglyCustomer',
      key: 'isSwimminglyCustomer',
      render: (text: number, record: Club) => {
        const registrationStarted = record.registrationStarted !== null && record.registrationStarted > 0;

        return (
          <Tooltip title={registrationStarted ? "Cannot be changed because swimmers have been registered for this season." : ""}>
            <Switch
              checkedChildren="Customer"
              unCheckedChildren="Guest"
              checked={!!text}
              onChange={(checked) => handleSwitchChange(record.clubId, 'isSwimminglyCustomer', checked)}
              disabled={registrationStarted}
            />
          </Tooltip>
        );
      },
      ellipsis: false,
    },
    {
      title: (
        <div>
          <strong>Payment Methods to Display</strong>
          <Tooltip title="Setting to Premium will only show Premium opt-in option for the club. Setting to Basic will show both Premium / Basic opt-in options.">
            <QuestionCircleOutlined style={{ marginLeft: 8 }} />
          </Tooltip>
        </div>
      ),
      dataIndex: 'seedPremium',
      key: 'seedPremium',
      render: (text: number, record: Club) => {
        const isGuest = !record.isSwimminglyCustomer;
        const registrationStarted = record.registrationStarted !== null && record.registrationStarted > 0;

        return (
          <Tooltip title={registrationStarted ? "Cannot be changed because swimmers have been registered for this season." : isGuest ? "Cannot be modified for Guest" : ""}>
            <Switch
              checked={!!text}
              onChange={(checked) => handleSwitchChange(record.clubId, 'seedPremium', checked)}
              checkedChildren="Premium"
              unCheckedChildren="Basic"
              disabled={isGuest || registrationStarted}
            />
          </Tooltip>
        );
      },
      ellipsis: false,
    },
    {
      title: (
        <div>
          <strong>By Parent Payment</strong>
          <Tooltip title="Membership (in $) paid by parents upon registration">
            <QuestionCircleOutlined style={{ marginLeft: 8 }} />
          </Tooltip>
        </div>
      ),
      dataIndex: 'parentPays',
      key: 'parentPays',
      render: (text: number, record: Club) => {
        const isGuest = !record.isSwimminglyCustomer;
        const registrationStarted = record.registrationStarted !== null && record.registrationStarted > 0;

        return (
          <Tooltip title={registrationStarted ? "Cannot be changed because swimmers have been registered for this season." : isGuest ? "Cannot be modified for Guest" : ""}>
            <InputNumber
              value={text / 100}  // Convert pennies to dollars
              onChange={(value) => handleInputChange(record.clubId, 'parentPays', value)}
              formatter={(value) => `$ ${value}`}  // Add $ prefix
              parser={(value) => value ? parseFloat(value.replace(/\$\s?|(,*)/g, '')) : 0}  // Parse the input as a number
              min={0}  // Ensure value is not less than 0
              disabled={isGuest || registrationStarted}
            />
          </Tooltip>
        );
      },
      ellipsis: false,
    },
    {
      title: (
        <div>
          <strong>By Club Payment</strong>
          <Tooltip title="Membership (in $) paid by club card on file upon each parent registration">
            <QuestionCircleOutlined style={{ marginLeft: 8 }} />
          </Tooltip>
        </div>
      ),
      dataIndex: 'clubPays',
      key: 'clubPays',
      render: (text: number, record: Club) => {
        const isGuest = !record.isSwimminglyCustomer;
        const registrationStarted = record.registrationStarted !== null && record.registrationStarted > 0;

        return (
          <Tooltip title={registrationStarted ? "Cannot be changed because swimmers have been registered for this season." : isGuest ? "Cannot be modified for Guest" : ""}>
            <InputNumber
              value={text / 100}  // Convert pennies to dollars
              onChange={(value) => handleInputChange(record.clubId, 'clubPays', value)}
              formatter={(value) => `$ ${value}`}  // Add $ prefix
              parser={(value) => value ? parseFloat(value.replace(/\$\s?|(,*)/g, '')) : 0}  // Parse the input as a number
              min={0}  // Ensure value is not less than 0
              disabled={isGuest || registrationStarted}
            />
          </Tooltip>
        );
      },
      ellipsis: false,
    },
    {
      title: <strong>Offline Payment</strong>,
      dataIndex: 'corporatePaymentId',
      key: 'corporatePaymentId',
      render: (text: number | null, record: Club) => {
        const isGuest = !record.isSwimminglyCustomer;
        const registrationStarted = record.registrationStarted !== null && record.registrationStarted > 0;

        return (
          <Tooltip title={registrationStarted ? "Cannot be changed because swimmers have been registered for this season." : isGuest ? "Cannot be modified for Guest" : ""}>
            <Select
              value={text || 'None'}
              onChange={(value) => handleSelectChange(record.clubId, 'corporatePaymentId', value === 'None' ? null : value)}
              style={{ width: '100%' }}
              dropdownAlign={{ offset: [-400, 4] }}
              dropdownMatchSelectWidth={false}
              disabled={isGuest || registrationStarted}
            >
              <Option value="none">None</Option>
              {corporatePayments.map(payment => (
                <Option key={payment.corporatePaymentId} value={payment.corporatePaymentId}>
                  {`${payment.corporatePaymentId} | ${payment.referenceName}`}
                </Option>
              ))}
            </Select>
          </Tooltip>
        );
      },
      ellipsis: false,
    },
    {
      title: (
        <div>
          <strong>Confirmation on</strong>
          <Tooltip title="This is the date the club confirmed their registration settings for the season">
            <QuestionCircleOutlined style={{ marginLeft: 8 }} />
          </Tooltip>
        </div>
      ),
      dataIndex: 'seasonSetupConfirmedOn',
      key: 'seasonSetupConfirmedOn',
      render: (text: string | null, record: Club) => {
        if (record.isNew) {
          return null;
        }
        if (!text) {
          return <span style={{ backgroundColor: '#FFD580', padding: '0 4px' }}>Not Confirmed</span>;
        }
        return <span>{moment(text).format('MM-DD-YYYY')}</span>;
      },
      ellipsis: false,
    },
    {
      title: (
        <div>
          <strong>Confirmed by</strong>
          <Tooltip title="This is the user that confirmed registration">
            <QuestionCircleOutlined style={{ marginLeft: 8 }} />
          </Tooltip>
        </div>
      ),
      dataIndex: 'seasonSetupConfirmedBy',
      key: 'seasonSetupConfirmedBy',
      render: (text: string | null, record: Club) => {
        if (record.isNew) {
          return null;
        }
        return (
          <span>{record.confirmedByName ? (
              <>
                {record.confirmedByName}
                <br />
                {record.confirmedByEmail}
              </>
            ) : 'N/A'}</span>
        );
      },
      ellipsis: false,
    },
    {
  title: (
    <div>
      <strong>Registration Date</strong>
      <Tooltip title="This is the date Registration opens to families on this club">
        <QuestionCircleOutlined style={{ marginLeft: 8 }} />
      </Tooltip>
    </div>
  ),
  dataIndex: 'signUpsOpenDate',
  key: 'signUpsOpenDate',
  render: (text: string | null, record: Club) => {
    if (record.isNew) {
      return null;
    }
    const date = text ? moment(text) : null;
    const registrationStarted = record.registrationStarted !== null && record.registrationStarted > 0;

    // Check if date is null
    if (date === null) {
      return (
        <Tooltip title="The club must set their registration date when they open their season.">
          <DatePicker
            value={null}
            disabled
            format="MM-DD-YYYY"
          />
        </Tooltip>
      );
    }

    return (
      <DatePicker
        value={date}
        onChange={(date) => handleDateChange(record.clubId, date)}
        format="MM-DD-YYYY"
        disabledDate={(currentDate) => {
            if (!seasonData) return false;
            return currentDate && (currentDate.isBefore(seasonData.startDate) || currentDate.isAfter(seasonData.endDate));
        }}
        allowClear={false} // Disable the ability to clear the date
      />
    );
  },
  ellipsis: false,
  width: '180px',  // Updated width
},
    {
      title: (
        <div>
          <strong>Registration Started</strong>
          <Tooltip title="Indicates any registrations activity for this club.">
            <QuestionCircleOutlined style={{ marginLeft: 8 }} />
          </Tooltip>
        </div>
      ),
      dataIndex: 'registrationStarted',
      key: 'registrationStarted',
      render: (registrationStarted: number | null, record: Club) => {
        if (record.isNew) {
          return null;
        }
        return <span>{registrationStarted !== null && registrationStarted > 0 ? 'Yes' : '-'}</span>;
      },
      ellipsis: false,
    },
    {
      title: (
        <div>
          <strong>Premium / Basic Opt-in</strong>
          <Tooltip title="Select Premium or Basic based on the club's opt-in choice.">
            <QuestionCircleOutlined style={{ marginLeft: 8 }} />
          </Tooltip>
        </div>
      ),
      dataIndex: 'usingRegistration',
      key: 'usingRegistration',
      render: (usingRegistration: number, record: Club) => {
        if (record.isNew) {
          return null;
        }
        const isGuest = !record.isSwimminglyCustomer;
        const registrationDate = record.signUpsOpenDate;
        const registrationStarted = record.registrationStarted !== null && record.registrationStarted > 0;
    
        // If set to Guest and Registration Date is not null, set to Basic and make uneditable
        if (isGuest && registrationDate) {
          return (
            <Tooltip title="Cannot be modified for Guest">
              <Select
                value="Basic"
                style={{ width: '100%' }}
                disabled={true}
                bordered={false}
              >
                <Option value="Basic">Basic</Option>
              </Select>
            </Tooltip>
          );
        }
    
        // If registrationDate is null, display '-'
        if (!registrationDate) {
          return (
            <Tooltip title="Premium or Basic not selected because club has not opted-into season yet.">
              <span>-</span>
            </Tooltip>
          );
        }
    
        // If set to Customer and Registration Date is not null, allow editing
        if (!isGuest && registrationDate) {
          return (
            <Select
              value={usingRegistration === 1 ? 'Premium' : 'Basic'}
              onChange={(value) => handleSelectChange(record.clubId, 'usingRegistration', value === 'Premium' ? 1 : 0)}
              style={{ width: '100%' }}
              bordered={false}
              disabled={registrationStarted}
            >
              <Option value="Basic">Basic</Option>
              <Option value="Premium">Premium</Option>
            </Select>
          );
        }
    
        // Default case for Guest without Registration Date or other undefined states
        return (
          <Tooltip title="This option is not available.">
            <span>-</span>
          </Tooltip>
        );
      },
      ellipsis: false,
    }
  ];

  return (
    <div>
      <Select
        showSearch
        placeholder="Select a season"
        optionFilterProp="children"
        onChange={value => setSelectedSeasonId(Number(value))}
        filterOption={(input, option) =>
          typeof option?.children === 'string' && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        style={{ width: '100%', marginBottom: 16 }}
      >
        {allSeasons.map(season => (
          <Option key={season.seasonId} value={season.seasonId}>
            {`${season.seasonId} - ${season.seasonName}`}
          </Option>
        ))}
      </Select>

      {seasonData && (
        <>
          <Form
            form={form}
            initialValues={{
              seasonName: seasonData.seasonName,
              seasonDates: [moment(seasonData.startDate), moment(seasonData.endDate)],
              seasonType: seasonData.seasonType,
              cutOffType: seasonData.cutOffType,
              cutOffDate: seasonData.cutOffDate ? moment(seasonData.cutOffDate) : null,
            }}
          >
            <Form.Item
              label="Season Name"
              name="seasonName"
              rules={[{ required: true, message: 'Please enter Season Name' }]}  // Validation rule for Season Name
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item
              label="Season Dates"
              name="seasonDates"
              rules={[{ required: true, message: 'Please select a season start/end date' }]}  // Validation rule for Season Dates
            >
              <RangePicker />
            </Form.Item>
            <Form.Item label="Season Type" name="seasonType">
              <Select>
                <Option value="summer">Summer</Option>
                <Option value="yearRound">Year-Round</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Cut Off Type" name="cutOffType">
              <Select onChange={handleCutOffTypeChange}>
                <Option value="meetStart">Day of Meet</Option>
                <Option value="fixedCutOff">Fixed Date</Option>
              </Select>
            </Form.Item>
            {showCutOffDate && (
              <Form.Item
                label="Cut Off Date"
                name="cutOffDate"
                rules={[{ required: true, message: 'Please select a cut-off date' }, { validator: validateCutOffDate }]}  // Validation rule for Cut Off Date
              >
                <DatePicker />
              </Form.Item>
            )}
          </Form>

          <h3 style={{ textAlign: 'center', backgroundColor: '#8de2f5', padding: '10px' }}>
            Manage & Update Clubs in this Season
          </h3>
          <Table
            dataSource={seasonData.selectedClubs}
            columns={columns}
            rowKey="clubId"
            pagination={false}
            style={{ marginTop: '16px' }}
            scroll={{ x: 'max-content' }}
          />

          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <Select
              showSearch
              placeholder="Add a club to this season"
              optionFilterProp="children"
              onSelect={(value) => handleAddClub(Number(value))}
              filterOption={(input, option) =>
                typeof option?.children === 'string' && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: '100%', marginBottom: 16 }}
            >
              {availableClubs
                .filter(club => !seasonData.selectedClubs.some(selectedClub => selectedClub.clubId === club.clubId))
                .map(club => (
                  <Option key={club.clubId} value={club.clubId}>
                    {`${club.name_long} (${club.clubId})`}
                  </Option>
                ))}
            </Select>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <Button
              type="primary"
              style={{ height: '64px', width: '50%' }}
              onClick={() => setIsModalVisible(true)}
            >
              Update Season
            </Button>
          </div>

          <Modal
            title="Confirmation"
            visible={isModalVisible}
            onOk={handleSubmit}
            onCancel={() => setIsModalVisible(false)}
            okText="Yes, Update & Make Changes"
            cancelText="Cancel"
          >
            <p>Are you sure you want to make these changes to this season?</p>
          </Modal>

          <Modal
            title="Add Club to Season"
            visible={isAddClubModalVisible}
            onOk={() => {
              if (clubToBeAdded) {
                const newClub = {
                  ...clubToBeAdded,
                  isNew: true,
                  isSwimminglyCustomer: 1,
                  seedPremium: 0,
                  usingRegistration: 0,
                  parentPays: 1800,
                  clubPays: 0,
                };
          
                setSeasonData((prevState) => {
                  if (!prevState) return null;
                
                  return {
                    ...prevState,
                    selectedClubs: [...prevState.selectedClubs, newClub],
                  };
                });
          
                // Show success message
                message.success('Club added to season. Add desired settings and be sure to Update Season.');
              }
              setIsAddClubModalVisible(false);
            }}
            onCancel={() => setIsAddClubModalVisible(false)}
            okText="Yes, add this club to the season"
            cancelText="Cancel"
          >
            <p>Are you sure you want to add this club to the season? This change cannot be undone.</p>
          </Modal>
        </>
      )}
    </div>
  );
};

export default UpdateSwimSeason;