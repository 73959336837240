import React from "react"
import { Modal, message } from "antd"
import { gen } from "./utils"

class EditEventSwimmerModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      eventSwimmers: [],
      otherSwimmers: [],
      swimmerUpdates: [],
    }
  }

  handleOk = async () => {
    let isValid = true

    for (let i = 0; i < this.state.swimmerUpdates.length; i++) {
      if (parseInt(this.state.swimmerUpdates[i].newSwimmer) === 0) {
        isValid = false
      }
    }

    if (isValid === false) {
      message.error("You've selected an invalid swimmer.")
      return
    }

    await fetch(gen("/api/updateSwimmerInResults"), {
      method: "post",
      headers: { "Content-Type": "application/json" },
      credentials: "same-origin",
      body: JSON.stringify({
        resultId: this.props.resultId.split("_")[1],
        swimmerUpdates: this.state.swimmerUpdates,
      }),
    })

    message.success("Successfully updated swimmer!")
    await this.props.setVisible(false)
  }

  handleCancel = () => {
    this.props.setVisible(false)
  }

  async componentDidMount() {
    if (this.props.resultId) {
      let response = await fetch(gen("/api/getResultSwimmers"), {
        method: "post",
        headers: { "Content-Type": "application/json" },
        credentials: "same-origin",
        body: JSON.stringify({ resultId: this.props.resultId.split("_")[1] }),
      })
      let result = await response.json()
      if (result.status === "success") {
        let swimmerUpdates = []
        for (let eventSwimmer of result.eventSwimmers) {
          swimmerUpdates.push({
            oldSwimmer: eventSwimmer.swimmerId,
            newSwimmer: eventSwimmer.swimmerId,
          })
        }
        this.setState({
          eventSwimmers: result.eventSwimmers,
          otherSwimmers: result.otherSwimmers,
          swimmerUpdates,
        })
      } else {
        console.log(result.error)
      }
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (!this.props.resultId) {
      return
    }
    if (prevProps.resultId && this.props.resultId === prevProps.resultId) {
      return
    }

    if (prevProps.visible === false && this.props.visible === false) {
      return
    }

    if (this.props.resultId) {
      let response = await fetch(gen("/api/getResultSwimmers"), {
        method: "post",
        headers: { "Content-Type": "application/json" },
        credentials: "same-origin",
        body: JSON.stringify({ resultId: this.props.resultId.split("_")[1] }),
      })
      let result = await response.json()
      if (result.status === "success") {
        let swimmerUpdates = []
        for (let eventSwimmer of result.eventSwimmers) {
          swimmerUpdates.push({
            oldSwimmer: eventSwimmer.swimmerId,
            newSwimmer: eventSwimmer.swimmerId,
          })
        }
        this.setState({
          eventSwimmers: result.eventSwimmers,
          otherSwimmers: result.otherSwimmers,
          swimmerUpdates,
        })
      } else {
        console.log(result.error)
      }
    }
  }

  render() {
    return (
      <Modal
        title="Change Swimmer"
        visible={this.props.visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
      >
        {this.state.eventSwimmers.map((swimmer, idx) => {
          return (
            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
              key={`swimmer_${idx}`}
            >
              <div style={{ fontSize: "16px", flex: 1 }}>
                {swimmer.last_name}, {swimmer.first_name}
              </div>
              <div style={{ flex: 1 }}>
                <select
                  value={
                    (this.state.swimmerUpdates[idx] &&
                      this.state.swimmerUpdates[idx].newSwimmer) ||
                    ""
                  }
                  onChange={(e) => {
                    let swimmerUpdates = [...this.state.swimmerUpdates]
                    swimmerUpdates[idx].newSwimmer = parseInt(e.target.value)
                    // this is where the team mapping happens
                    this.setState({ swimmerUpdates })
                  }}
                >
                  <option value={0}>Select Swimmer</option>
                  {this.state.otherSwimmers.map((el) => (
                    <option value={parseInt(el.swimmerId)} key={el.swimmerId}>
                      {el.last_name}, {el.first_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )
        })}
        {/*<p>{this.props.resultId ? this.props.resultId.split("_")[1] : "null"}</p>
        <p>{JSON.stringify(this.state.eventSwimmers)}</p>
        <p>{JSON.stringify(this.state.otherSwimmers)}</p>*/}
      </Modal>
    )
  }
}

export default EditEventSwimmerModal
