import React from "react"
import $ from "jquery"
import { validateEmail } from "./utils"
import { Button, message } from "antd"
import { gen } from "./utils"

class NewLeague extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      clubName: "",
      leagueName: "",
      otherClubNames: [],

      emailMessage: "",
      firstNameMessage: "",
      lastNameMessage: "",
      phoneNumberMessage: "",
      clubNameMessage: "",
      leagueNameMessage: "",
      isLoading: false,
    }
  }

  onSubmitFunc = (e) => {
    this.setState({ isLoading: true })
    let isGood = this.onCheckValues()
    if (!isGood) {
      return
    }

    // let val = confirm("Do you want to send this user their activation email now? If you cancel you can always send it later.")

    let data = {
      email: this.state.email,
      name: `${this.state.firstName} ${this.state.lastName}`,
      phoneNumber: this.state.phoneNumber,
      clubName: this.state.clubName,
      leagueName: this.state.leagueName,
    }

    $.ajax({
      url: gen("/api/leagueSignUpRequestPost"),
      method: "POST",
      data: data,
      error: () => {
        message.error("Failure sending request. Contact swimmingly support.")
        this.setState({ isLoading: false })
      },
      success: () => {
        message.success(
          "Thank you. You will be receiving an invitation to Swimmingly shortly.",
        )
        this.setState({ isLoading: false })
        if (this.props.callAfterSuccess) {
          this.props.callAfterSuccess()
        }
      },
    })
  }

  onCheckValues = () => {
    let isGood = true
    if (!validateEmail(this.state.email)) {
      this.setState({ emailMessage: "Not a valid email" })
      isGood = false
    }

    if (!this.state.firstName) {
      this.setState({ firstNameMessage: "Not a valid first name" })
      isGood = false
    }

    if (this.state.firstName.length < 3) {
      this.setState({
        firstNameMessage: "First name must be at least 3 characters",
      })
      isGood = false
    }

    if (this.state.firstName.length > 255) {
      this.setState({
        firstNameMessage: "First name must be at less than 255 characters",
      })
      isGood = false
    }

    if (!this.state.lastName) {
      this.setState({ lastNameMessage: "Not a valid last name" })
      isGood = false
    }

    if (this.state.lastName.length < 3) {
      this.setState({
        lastNameMessage: "Last name must be at least 3 characters",
      })
      isGood = false
    }

    if (this.state.lastName.length > 255) {
      this.setState({
        lastNameMessage: "Last name must be at less than 255 characters",
      })
      isGood = false
    }

    if (!this.state.phoneNumber) {
      this.setState({ phoneNumberMessage: "Not a valid phone number" })
      isGood = false
    }
    let onlyDigits = this.state.phoneNumber.replace(/[^0-9]/, "")
    if (onlyDigits.length < 10) {
      this.setState({ phoneNumberMessage: "Needs at least 10 digits" })
      isGood = false
    }

    // if (!this.state.clubName) {
    //   this.setState({ clubNameMessage: "Not a valid club name" })
    //   isGood = false
    // }

    // if (this.state.clubName.length < 3) {
    //   this.setState({ clubNameMessage: "Club Name too short" })
    //   isGood = false
    // }
    // if (this.state.clubName.length > 155) {
    //   this.setState({ clubNameMessage: "Club Name too long" })
    //   isGood = false
    // }

    if (!this.state.leagueName) {
      this.setState({ leagueNameMessage: "Not a valid league name" })
      isGood = false
    }

    if (this.state.leagueName.length < 3) {
      this.setState({ leagueNameMessage: "League Name too short" })
      isGood = false
    }
    if (this.state.leagueName.length > 155) {
      this.setState({ leagueNameMessage: "League Name too long" })
      isGood = false
    }

    return isGood
  }

  render() {
    return (
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "center",
          width: "100%",
          background: "#f5f5f5",
          padding: "20px",
        }}
      >
        <div className="row">
          <div className={"col-md-12"}>
            <div classs="form-horizontal">
              <div className="row">
                <div className="col-lg-12">
                  <h2 className="panel-title">Add New League </h2>
                </div>
              </div>

              <div className="ourpanel-body marg-t20 bornone">
                <div className="form-group">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="col-sm-4 ">Email</div>
                    <div className="col-sm-8">
                      <input
                        id="email"
                        name="email"
                        value={this.state.email}
                        onChange={(e) =>
                          this.setState({
                            email: e.target.value,
                            emailMessage: "",
                          })
                        }
                        className="form-control"
                        placeholder="Email Address"
                        type="email"
                      />
                      {this.state.emailMessage && (
                        <span
                          id="spanUserName"
                          className="field-validation-error"
                        >
                          {this.state.emailMessage}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="col-sm-4 ">First Name</div>
                    <div className="col-sm-8">
                      <input
                        id="FirstName"
                        name="FirstName"
                        className="form-control"
                        placeholder="First Name"
                        type="text"
                        value={this.state.firstName}
                        onChange={(e) =>
                          this.setState({
                            firstName: e.target.value,
                            firstNameMessage: "",
                          })
                        }
                      />
                      {this.state.firstNameMessage && (
                        <span
                          id="spanUserName"
                          className="field-validation-error"
                        >
                          {this.state.firstNameMessage}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="col-sm-4 ">Last Name</div>
                    <div className="col-sm-8">
                      <input
                        name="LastName"
                        id="LastName"
                        className="form-control"
                        placeholder="Last Name"
                        type="text"
                        value={this.state.lastName}
                        onChange={(e) =>
                          this.setState({
                            lastName: e.target.value,
                            lastNameMessage: "",
                          })
                        }
                      />
                      {this.state.lastNameMessage && (
                        <span
                          id="spanUserName"
                          className="field-validation-error"
                        >
                          {this.state.lastNameMessage}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="col-sm-4 ">Phone Number</div>
                    <div className="col-sm-8">
                      <input
                        name="PhoneNumber"
                        id="PhoneNumber"
                        className="form-control"
                        placeholder="Phone Number"
                        type="text"
                        value={this.state.phoneNumber}
                        onChange={(e) =>
                          this.setState({
                            phoneNumber: e.target.value,
                            phoneNumberMessage: "",
                          })
                        }
                      />
                      {this.state.phoneNumberMessage && (
                        <span
                          id="spanUserName"
                          className="field-validation-error"
                        >
                          {this.state.phoneNumberMessage}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="col-sm-4 ">League Name</div>
                    <div className="col-sm-8">
                      <input
                        name="LeagueName"
                        id="LeagueName"
                        className="form-control"
                        placeholder="League Name"
                        type="text"
                        value={this.state.leagueName}
                        onChange={(e) =>
                          this.setState({
                            leagueName: e.target.value,
                            leagueNameMessage: "",
                          })
                        }
                      />
                      {this.state.leagueNameMessage && (
                        <span
                          id="spanUserName"
                          className="field-validation-error"
                        >
                          {this.state.leagueNameMessage}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <label
                      style={{ visibility: "hidden" }}
                      className="col-sm-4 "
                    >
                      Submit
                    </label>
                    <div className="col-sm-8">
                      <Button
                        type="primary"
                        onClick={this.onSubmitFunc}
                        loading={this.state.isLoading}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default NewLeague
