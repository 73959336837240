import moment from "moment-timezone"
import { MeetEvent } from "../ImportSd3Modals/ImportEntriesModal"

// ###########################################
// ### Create a function to map relay team ###
// ### letter to number for database       ###
// ###########################################
const relayMap = {
  A: 1,
  B: 2,
  C: 3,
  D: 4,
  E: 5,
  F: 6,
  G: 7,
  H: 8,
  I: 9,
  J: 10,
  K: 11,
  L: 12,
  M: 13,
  N: 14,
  O: 15,
  P: 16,
  Q: 17,
  R: 18,
  S: 19,
}

function mapTeamNum(teamLetter: keyof typeof relayMap): number {
  let out = relayMap[teamLetter]
  return out ? out : 20
}

const orgMap = {
  "1": "USA",
  "2": "Masters",
  "3": "NCAA",
  "4": "NCAA D1",
  "5": "NCAA D2",
  "6": "NCAA D3",
  "7": "YMCA",
  "8": "FINA",
  "9": "HS",
}

function mapOrgCode(theOrgCode: keyof typeof orgMap): string {
  let out = orgMap[theOrgCode]
  return out ? out : "Other"
}

const fileMap = {
  "01": "Meet Entries",
  "02": "Meet Results",
  "03": "OVC",
  "04": "National Age Group Record",
  "05": "LSC Age Group Record",
  "06": "LSC Motivational List",
  "07": "National Records and Rankings",
  "08": "Team Selection",
  "09": "LSC Best Times",
  "10": "USS Registration",
  "16": "Top 16",
  "20": "Vendor-defined code",
}

function mapFileCode(theFileCode: keyof typeof fileMap): string {
  let out = fileMap[theFileCode]
  return out ? out : "not defined"
}

const strokeMap = {
  "1": "Freestyle",
  "2": "Backstroke",
  "3": "Breaststroke",
  "4": "Butterfly",
  "5": "Individual Medley",
  "6": "Freestyle Relay",
  "7": "Medley Relay",
}
function mapStrokeCode(theStrokeCode: keyof typeof strokeMap): string {
  let out = strokeMap[theStrokeCode]
  return out ? out : "fun"
}

function formatTime(inputTime: string): string {
  try {
    if (["DQ", "NS", "NT", "DFS", "DNF"].includes(inputTime.trim())) {
      return inputTime.trim()
    }
    if (!/^[0-9]+$/.test(inputTime.replace(/:/g, "").replace(/\./g, ""))) {
      return "NT"
    }

    let hundredths = 0
    if (/\./.test(inputTime)) {
      hundredths = Math.floor(parseFloat("." + inputTime.split(".")[1]) * 100)
      inputTime = inputTime.split(".")[0]
    }
    const timeComponents = inputTime.split(":")

    let timeAsNumber = hundredths / 100
    for (let i = timeComponents.length - 1; i >= 0; i--) {
      timeAsNumber +=
        60 ** (timeComponents.length - i - 1) * parseInt(timeComponents[i], 10)
    }
    return String(timeAsNumber)
  } catch (err) {
    return "err"
  }
}

function findUniqueSwimmers(meetData: ISwimDatum) {
  let allSwimmerEntries: string[] = []
  const allClubs = meetData.clubs
  const clubs = allClubs.map((x) => x.teamFullName)
  for (let i = 0; i < clubs.length; i++) {
    let thisClub = allClubs.filter((x) => x.teamFullName === clubs[i])[0]
    // find all swimmers from this club entered in individual events
    let individualEntries = thisClub.individualEntries
      .map((x) => {
        return `${thisClub.teamFullName}|${x.swimmerFirstName}|${x.swimmerLastName}|${x.swimmerGender}|${x.swimmerDOB}`
      })
      .filter((v, i, a) => a.indexOf(v) === i)
    // add those swimmers on to the running list of swimmers in the meet
    allSwimmerEntries.push.apply(allSwimmerEntries, individualEntries)
    // now find all swimmers from this club entered in relay events
    let thisClubRelayEntrants: string[] = []
    for (let j = 0; j < thisClub.relayEntries.length; j++) {
      let thisRelayEntry = thisClub.relayEntries[j]
      let theLegs = thisRelayEntry.relayLegs.map((x) => {
        return `${thisClub.teamFullName}|${x.swimmerFirstName}|${x.swimmerLastName}|${x.swimmerGender}|${x.swimmerDOB}`
      })
      thisClubRelayEntrants.push.apply(thisClubRelayEntrants, theLegs)
    }
    thisClubRelayEntrants = thisClubRelayEntrants.filter(
      (v, i, a) => a.indexOf(v) === i,
    )
    allSwimmerEntries.push.apply(allSwimmerEntries, thisClubRelayEntrants)
  }
  allSwimmerEntries = allSwimmerEntries.filter((v, i, a) => a.indexOf(v) === i)
  let uniqueSwimmers = allSwimmerEntries.map((x) => {
    let theSwimmerData = x.split("|")
    return {
      club: theSwimmerData[0],
      swimmerFirstName: theSwimmerData[1],
      swimmerLastName: theSwimmerData[2],
      swimmerGender: theSwimmerData[3],
      swimmerDOB: theSwimmerData[4],
      swimmerId: -1,
    }
  })
  return uniqueSwimmers
}

interface IIndividualSd3Entry {
  swimmerTeamPosition: number
  relayOnlyRecord: string
  isRelay: string
  relayTeam: number
  teamPosition: number
  swimmerLastName: string
  swimmerFirstName: string
  swimmerUSAId: string
  swimmerDOB: string
  swimmerGender: string
  eventGender: string
  eventStroke: string
  eventDistance: number
  eventNumber: number
  eventAge: string
  seedTime: string
  seedTimeCourse: string
  prelimTime: string
  prelimTimeCourse: string
  swimOffTime: string
  swimOffTimeCourse: string
  finalsTime: string
  finalsTimeCourse: string
  prelimHeat: string
  prelimLane: string
  prelimPlace: string
  finalsHeat: string
  finalsLane: string
  finalsPlace: string
  finalsPoints: string
  isExhibition: string
}

function newIndividualSd3Entry(): IIndividualSd3Entry {
  return {
    swimmerTeamPosition: -1,
    relayOnlyRecord: "",
    isRelay: "",
    relayTeam: -1,
    teamPosition: -1,
    swimmerLastName: "",
    swimmerFirstName: "",
    swimmerUSAId: "",
    swimmerDOB: "",
    swimmerGender: "",
    eventGender: "",
    eventStroke: "",
    eventDistance: -1,
    eventNumber: -1,
    eventAge: "",
    seedTime: "",
    seedTimeCourse: "",
    prelimTime: "",
    prelimTimeCourse: "",
    swimOffTime: "",
    swimOffTimeCourse: "",
    finalsTime: "",
    finalsTimeCourse: "",
    prelimHeat: "",
    prelimLane: "",
    prelimPlace: "",
    finalsHeat: "",
    finalsLane: "",
    finalsPlace: "",
    finalsPoints: "",
    isExhibition: "",
  }
}

interface IRelaySd3Entry {
  relayOnlyRecord: string
  isRelay: string
  relayTeam: number
  eventGender: string
  eventStroke: string
  eventDistance: number
  eventNumber: number
  eventAge: string
  seedTime: string
  seedTimeCourse: string
  prelimTime: string
  prelimTimeCourse: string
  swimOffTime: string
  swimOffTimeCourse: string
  finalsTime: string
  finalsTimeCourse: string
  prelimHeat: string
  prelimLane: string
  prelimPlace: string
  finalsHeat: string
  finalsLane: string
  finalsPlace: string
  finalsPoints: string
  isExhibition: string
  relayLegs: {
    swimmerLastName: string
    swimmerFirstName: string
    swimmerUSAId: string
    swimmerGender: string
    swimmerDOB: string
    teamPosition: number
  }[]
}

function newRelaySd3Entry(): IRelaySd3Entry {
  return {
    relayOnlyRecord: "",
    isRelay: "",
    relayTeam: -1,
    eventGender: "",
    eventStroke: "",
    eventDistance: -1,
    eventNumber: -1,
    eventAge: "",
    seedTime: "",
    seedTimeCourse: "",
    prelimTime: "",
    prelimTimeCourse: "",
    swimOffTime: "",
    swimOffTimeCourse: "",
    finalsTime: "",
    finalsTimeCourse: "",
    prelimHeat: "",
    prelimLane: "",
    prelimPlace: "",
    finalsHeat: "",
    finalsLane: "",
    finalsPlace: "",
    finalsPoints: "",
    isExhibition: "",
    relayLegs: [],
  }
}

function newRelayLegEntry() {
  return {
    swimmerLastName: "",
    swimmerFirstName: "",
    swimmerUSAId: "",
    swimmerGender: "",
    swimmerDOB: "",
    teamPosition: -1,
  }
}

interface ISd3Club {
  teamCode: string
  teamFullName: string
  teamShortName: string
  teamCity: string
  teamState: string
  teamZipCode: string
  individualEntries: IIndividualSd3Entry[]
  relayEntries: {
    relayOnlyRecord: string
    isRelay: string
    relayTeam: number
    eventGender: string
    eventStroke: string
    eventDistance: number
    eventNumber: number
    eventAge: string
    seedTime: string
    seedTimeCourse: string
    prelimTime: string
    prelimTimeCourse: string
    swimOffTime: string
    swimOffTimeCourse: string
    finalsTime: string
    finalsTimeCourse: string
    prelimHeat: string
    prelimLane: string
    prelimPlace: string
    finalsHeat: string
    finalsLane: string
    finalsPlace: string
    finalsPoints: string
    isExhibition: string
    relayLegs: {
      swimmerLastName: string
      swimmerFirstName: string
      swimmerUSAId: string
      swimmerGender: string
      swimmerDOB: string
      teamPosition: number
    }[]
  }[]
}

export interface ISwimDatum {
  clubs: ISd3Club[]
  orgCode: string
  filePurpose: string
  meetAddress: string
  city: string
  state: string
  zipCode: string
  country: string
  meetDate: string
}

function newSd3Club(): ISd3Club {
  return {
    teamCode: "",
    teamFullName: "",
    teamShortName: "",
    teamCity: "",
    teamState: "",
    teamZipCode: "",
    individualEntries: [],
    relayEntries: [],
  }
}

function newSwimDatum(): ISwimDatum {
  return {
    clubs: [],
    orgCode: "",
    filePurpose: "",
    meetAddress: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    meetDate: "",
  }
}

function parseA0Record(
  theRow: string,
  swimData: ISwimDatum[],
  meetCounter: { theMeetNumber: number },
  relayEntryNum: { num: number },
  theClubNumber: { num: number },
) {
  const swimDatum = newSwimDatum()
  swimData.push(swimDatum)
  meetCounter.theMeetNumber += 1
  relayEntryNum.num = -1
  theClubNumber.num = -1
  // update curSD3 with the org code(says what type of meet it is ex. USA, masters, college, etc.)
  swimData[meetCounter.theMeetNumber].orgCode = mapOrgCode(
    theRow.slice(2, 3).trim() as keyof typeof orgMap,
  )
  // update curSD3 with the file code (gives the purpose of the file - entries, results, etc.)
  swimData[meetCounter.theMeetNumber].filePurpose = mapFileCode(
    theRow.slice(11, 13).trim() as keyof typeof fileMap,
  )
  if (theRow.slice(11, 13).trim() === "20") {
    swimData[meetCounter.theMeetNumber].filePurpose =
      swimData[meetCounter.theMeetNumber].filePurpose +
      ": " +
      theRow.slice(13, 43).trim()
  }
}

function parseB1Record(
  theRow: string,
  swimData: ISwimDatum[],
  meetCounter: { theMeetNumber: number },
) {
  // check that org code of the B1 matches the org code of the sd3
  if (
    mapOrgCode(theRow.slice(2, 3).trim() as keyof typeof orgMap) ===
    swimData[meetCounter.theMeetNumber].orgCode
  ) {
    swimData[meetCounter.theMeetNumber].meetAddress = theRow
      .slice(41, 85)
      .trim()
    swimData[meetCounter.theMeetNumber].city = theRow.slice(85, 105).trim()
    swimData[meetCounter.theMeetNumber].state = theRow.slice(105, 107).trim()
    swimData[meetCounter.theMeetNumber].zipCode = theRow.slice(107, 117).trim()
    swimData[meetCounter.theMeetNumber].country = theRow.slice(117, 120).trim()
    swimData[meetCounter.theMeetNumber].meetDate = moment(
      theRow.slice(121, 129),
      "MMDDYYYY",
    ).format("YYYY-MM-DD")
  } else {
    // add exception handling here
  }
}

function parseC1Record(
  theRow: string,
  swimData: ISwimDatum[],
  meetCounter: { theMeetNumber: number },
  theClubNumber: { num: number },
  relayEntryNum: { num: number },
) {
  theClubNumber.num += 1
  relayEntryNum.num = -1
  let theClub: ISd3Club = newSd3Club()
  theClub.teamCode = (
    theRow.slice(11, 17).trim() + theRow.slice(149, 150)
  ).trim()
  theClub.teamFullName = theRow.slice(17, 47).trim()
  theClub.teamShortName = theRow.slice(47, 63).trim()
  theClub.teamCity = theRow.slice(107, 127).trim()
  theClub.teamState = theRow.slice(127, 129).trim()
  theClub.teamZipCode = theRow.slice(129, 139).trim()
  swimData[meetCounter.theMeetNumber].clubs.push(theClub)
}

const genderMap = {
  m: "Boys",
  f: "Girls",
  x: "Mixed",
}

const updateMeetEvents = (
  meetEvents: MeetEvent[],
  entry: IIndividualSd3Entry | IRelaySd3Entry,
) => {
  const alreadyDiscoveredMeetEvent = meetEvents.find(
    (event) =>
      event.eventNumber === entry.eventNumber &&
      parseInt(event.distance, 10) === entry.eventDistance &&
      event.stroke.toLowerCase().trim() ===
        entry.eventStroke.toLowerCase().trim() &&
      event.gender ===
        genderMap[entry.eventGender.toLowerCase() as keyof typeof genderMap],
  )
  if (!alreadyDiscoveredMeetEvent) {
    meetEvents.push({
      distance: String(entry.eventDistance),
      gender: genderMap[
        entry.eventGender.toLowerCase() as keyof typeof genderMap
      ] as "Boys" | "Girls" | "Mixed",
      ageGroup: entry.eventAge,
      eventNumber: entry.eventNumber,
      stroke: entry.eventStroke,
      isRelay: 0,
      course: "SCY", // we won't use this field for comparing event orders, just putting in a placeholder
      eventName: "", // we also won't use this field for comparing event orders
    })
  }
}

function parseD0Record(
  theRow: string,
  swimData: ISwimDatum[],
  meetCounter: { theMeetNumber: number },
  theClubNumber: { num: number },
  meetEvents: MeetEvent[],
) {
  const individualEntry = newIndividualSd3Entry()
  individualEntry.swimmerTeamPosition = 1
  individualEntry.relayOnlyRecord = "0"
  // it is a relay only record
  if ([6, 7].indexOf(parseInt(theRow.slice(71, 72), 10)) !== -1) {
    individualEntry.relayOnlyRecord = "1"
  }
  individualEntry.isRelay = "0"
  individualEntry.relayTeam = 1
  individualEntry.teamPosition = 1
  individualEntry.swimmerLastName = theRow.slice(11, 39).trim().split(",")[0]
  individualEntry.swimmerFirstName = theRow.slice(11, 39).trim().split(", ")[1]
  individualEntry.swimmerUSAId = theRow.slice(39, 51).trim()
  individualEntry.swimmerDOB = moment(theRow.slice(55, 63), "MMDDYYYY").format(
    "YYYY-MM-DD",
  )
  if (individualEntry.swimmerDOB === "Invalid date") {
    individualEntry.swimmerDOB = `age-${theRow.slice(63, 65).trim()}`
  }
  individualEntry.swimmerGender = theRow.slice(65, 66).trim()
  individualEntry.eventGender = theRow.slice(66, 67).trim()
  individualEntry.eventStroke = mapStrokeCode(
    theRow.slice(71, 72).trim() as keyof typeof strokeMap,
  )
  individualEntry.eventDistance = parseInt(theRow.slice(67, 71), 10)
  individualEntry.eventNumber = parseInt(theRow.slice(72, 76), 10)
  individualEntry.eventAge = theRow.slice(76, 80).trim()
  individualEntry.seedTime = formatTime(theRow.slice(88, 96).trim())
  individualEntry.seedTimeCourse = theRow.slice(96, 97).trim()
  individualEntry.prelimTime = formatTime(theRow.slice(97, 105).trim())
  individualEntry.prelimTimeCourse = theRow.slice(97, 106).trim()
  individualEntry.swimOffTime = formatTime(theRow.slice(106, 114).trim())
  individualEntry.swimOffTimeCourse = theRow.slice(114, 115).trim()
  individualEntry.finalsTime = formatTime(theRow.slice(115, 123).trim())
  individualEntry.finalsTimeCourse = theRow.slice(123, 124).trim()
  individualEntry.prelimHeat = theRow.slice(124, 126).trim()
  individualEntry.prelimLane = theRow.slice(126, 128).trim()
  individualEntry.prelimPlace = theRow.slice(132, 135).trim()
  individualEntry.finalsHeat = theRow.slice(128, 130).trim()
  individualEntry.finalsLane = theRow.slice(130, 132).trim()
  individualEntry.finalsPlace = theRow.slice(135, 138).trim()
  individualEntry.finalsPoints = theRow.slice(138, 142).trim()
  individualEntry.isExhibition = "0"
  if (theRow.slice(155, 156).trim() === "X") {
    individualEntry.isExhibition = "1"
  }
  swimData[meetCounter.theMeetNumber].clubs[
    theClubNumber.num
  ].individualEntries.push(individualEntry)
  updateMeetEvents(meetEvents, individualEntry)
}

function parseE0Record(
  theRow: string,
  swimData: ISwimDatum[],
  meetCounter: { theMeetNumber: number },
  theClubNumber: { num: number },
  relayEntryNum: { num: number },
  teamPosition: { current: number },
  meetEvents: MeetEvent[],
) {
  relayEntryNum.num += 1
  const relayEntry = newRelaySd3Entry()
  relayEntry.relayOnlyRecord = "0"
  relayEntry.isRelay = "1"
  relayEntry.relayTeam = mapTeamNum(
    theRow.slice(11, 12).trim() as keyof typeof relayMap,
  )
  teamPosition.current = 0
  relayEntry.eventGender = theRow.slice(20, 21).trim()
  relayEntry.eventStroke = mapStrokeCode(
    theRow.slice(25, 26).trim() as keyof typeof strokeMap,
  )
  if (!/Relay/.test(relayEntry.eventStroke)) {
    // fix for sd3/cl2 files that export relays with individual stroke codes
    if (relayEntry.eventStroke === "Individual Medley") {
      relayEntry.eventStroke = "Medley Relay"
    } else {
      relayEntry.eventStroke = relayEntry.eventStroke.trim() + " Relay"
    }
  }
  relayEntry.eventDistance = parseInt(theRow.slice(21, 25), 10)
  relayEntry.eventNumber = parseInt(theRow.slice(26, 30), 10)
  relayEntry.eventAge = theRow.slice(30, 34).trim()
  relayEntry.seedTime = formatTime(theRow.slice(45, 53).trim())
  relayEntry.seedTimeCourse = theRow.slice(53, 54).trim()
  relayEntry.prelimTime = formatTime(theRow.slice(54, 62).trim())
  relayEntry.prelimTimeCourse = theRow.slice(62, 63).trim()
  relayEntry.swimOffTime = formatTime(theRow.slice(63, 71).trim())
  relayEntry.swimOffTimeCourse = theRow.slice(71, 72).trim()
  relayEntry.finalsTime = formatTime(theRow.slice(72, 80).trim())
  relayEntry.finalsTimeCourse = theRow.slice(80, 81).trim()
  relayEntry.prelimHeat = theRow.slice(81, 83).trim()
  relayEntry.prelimLane = theRow.slice(83, 85).trim()
  relayEntry.prelimPlace = theRow.slice(89, 92).trim()
  relayEntry.finalsHeat = theRow.slice(85, 87).trim()
  relayEntry.finalsLane = theRow.slice(87, 89).trim()
  relayEntry.finalsPlace = theRow.slice(92, 95).trim()
  relayEntry.finalsPoints = theRow.slice(95, 99).trim()
  relayEntry.isExhibition = "0"
  if (theRow.slice(155, 156).trim() === "X") {
    relayEntry.isExhibition = "1"
  }
  relayEntry.relayLegs = []
  swimData[meetCounter.theMeetNumber].clubs[
    theClubNumber.num
  ].relayEntries.push(relayEntry)
  updateMeetEvents(meetEvents, relayEntry)
}

function parseF0Record(
  theRow: string,
  swimData: ISwimDatum[],
  meetCounter: { theMeetNumber: number },
  teamPosition: { current: number },
  theClubNumber: { num: number },
  relayEntryNum: { num: number },
) {
  const relayLegEntry = newRelayLegEntry()
  relayLegEntry.swimmerLastName = theRow.slice(22, 50).trim().split(",")[0]
  relayLegEntry.swimmerFirstName = theRow.slice(22, 50).trim().split(", ")[1]
  relayLegEntry.swimmerUSAId = theRow.slice(50, 62).trim()
  relayLegEntry.swimmerGender = theRow.slice(75, 76).trim()
  relayLegEntry.swimmerDOB = moment(
    theRow.slice(65, 73).trim(),
    "MMDDYYYY",
  ).format("YYYY-MM-DD")
  if (relayLegEntry.swimmerDOB === "Invalid date") {
    relayLegEntry.swimmerDOB = `age-${theRow.slice(73, 75).trim()}`
  }
  teamPosition.current += 1
  relayLegEntry.teamPosition = teamPosition.current
  swimData[meetCounter.theMeetNumber].clubs[theClubNumber.num].relayEntries[
    relayEntryNum.num
  ].relayLegs.push(relayLegEntry)
}

export interface ISd3Validation {
  dateOfBirthValid: boolean
  invalidBirthdaySwimmers: { name: string; sd3Rows: number[] }[]
}

function checkD0Birthday(sd3Row: string): boolean {
  if (sd3Row.slice(0, 2) === "D0" && !/^[0-9]+$/.test(sd3Row.slice(55, 63))) {
    return false
  } else {
    return true
  }
}

function checkF0Birthday(sd3Row: string): boolean {
  if (sd3Row.slice(0, 2) === "F0" && !/^[0-9]+$/.test(sd3Row.slice(65, 73))) {
    return false
  } else {
    return true
  }
}

export function validateSd3(sd3Contents: string): ISd3Validation {
  const output: ISd3Validation = {
    dateOfBirthValid: true,
    invalidBirthdaySwimmers: [],
  }

  const sd3Rows = sd3Contents
    .replace(/\r\n/g, "NEW_LINE_HERE")
    .replace(/\n/g, "NEW_LINE_HERE")
    .split("NEW_LINE_HERE")
    .filter((x: string) => x.trim() !== "")

  for (let i = 0; i < sd3Rows.length; i++) {
    const theRow = sd3Rows[i]
    if (!checkD0Birthday(theRow) || !checkF0Birthday(theRow)) {
      output.dateOfBirthValid = false
      let swimmerName = ""
      if (theRow.slice(0, 2) === "D0") {
        swimmerName = theRow.slice(11, 39).trim()
      } else if (theRow.slice(0, 2) === "F0") {
        swimmerName = theRow.slice(22, 50).trim()
      }
      const row = i + 1
      const swimmerRecord = output.invalidBirthdaySwimmers.find(
        (s) => s.name === swimmerName,
      )
      if (swimmerRecord) {
        swimmerRecord.sd3Rows.push(row)
      } else {
        output.invalidBirthdaySwimmers.push({
          name: swimmerName,
          sd3Rows: [row],
        })
      }
    }
  }

  return output
}

export default function parseSD3Entries(sd3Contents: string): {
  clubNames: string[]
  swimmers: {
    club: string
    swimmerFirstName: string
    swimmerLastName: string
    swimmerGender: string
    swimmerDOB: string
    swimmerId: number
  }[]
  swimData: ISwimDatum
  meetEvents: MeetEvent[]
} {
  const swimData: ISwimDatum[] = []
  const meetCounter = { theMeetNumber: -1 }
  const theClubNumber = { num: -1 }
  const teamPosition = { current: -1 }
  const meetEvents: MeetEvent[] = []

  // split it by row
  const sd3Rows = sd3Contents
    .replace(/\r\n/g, "NEW_LINE_HERE")
    .replace(/\n/g, "NEW_LINE_HERE")
    .split("NEW_LINE_HERE")
    .filter((x: string) => x.trim() !== "")

  let relayEntryNum = { num: -1 }

  // #######################
  // ### Fill in the SD3 ###
  // #######################

  // iterate through each row of the SD3 input file
  for (let i = 0; i < sd3Rows.length; i++) {
    let theRow = sd3Rows[i]
    let recordType = theRow.slice(0, 2)
    if (recordType === "A0") {
      parseA0Record(theRow, swimData, meetCounter, relayEntryNum, theClubNumber)
    } else if (recordType === "B1") {
      parseB1Record(theRow, swimData, meetCounter)
    } else if (recordType === "C1") {
      parseC1Record(theRow, swimData, meetCounter, theClubNumber, relayEntryNum)
    } else if (recordType === "D0") {
      parseD0Record(theRow, swimData, meetCounter, theClubNumber, meetEvents)
    } else if (recordType === "E0") {
      parseE0Record(
        theRow,
        swimData,
        meetCounter,
        theClubNumber,
        relayEntryNum,
        teamPosition,
        meetEvents,
      )
    } else if (recordType === "F0") {
      parseF0Record(
        theRow,
        swimData,
        meetCounter,
        teamPosition,
        theClubNumber,
        relayEntryNum,
      )
    } else if (recordType === "Z0") {
      // do nothing - we don't care about Z0 records because they just terminate the file
    } else if (
      ["B2", "C2", "D1", "D2", "D3", "G0", "J0", "J1", "J2"].indexOf(
        recordType,
      ) !== -1
    ) {
      // do nothing because we don't care about these records in the SD3
    } else {
      // exception handling here
    }
  }

  if (swimData.length > 1) {
    throw new Error("too many swim meets in file.")
  }

  const meetData = swimData[0]
  const clubNames = meetData.clubs.map((x) => x.teamFullName)
  const swimmers = findUniqueSwimmers(meetData)

  // clean up relay heats and lanes

  let eventEntryCount = new Map()
  for (let clubIdx = 0; clubIdx < meetData.clubs.length; clubIdx++) {
    let thisSetOfClubEntries = meetData.clubs[clubIdx]
    for (
      let relayEntryIdx = 0;
      relayEntryIdx < thisSetOfClubEntries.relayEntries.length;
      relayEntryIdx++
    ) {
      let thisRelayEntry = thisSetOfClubEntries.relayEntries[relayEntryIdx]
      let thisEventNumber = thisRelayEntry.eventNumber
      if (eventEntryCount.has(thisEventNumber)) {
        let currentCount = eventEntryCount.get(thisEventNumber)
        eventEntryCount.set(thisEventNumber, currentCount + 1)
      } else {
        eventEntryCount.set(thisEventNumber, 1)
      }
      let isFinals = thisRelayEntry.finalsTime.trim() !== ""
      let isPrelims = thisRelayEntry.prelimTime.trim() !== ""
      let badHeat
      let badLane
      if (isFinals) {
        badHeat =
          /^0+$/.test(thisRelayEntry.finalsHeat.trim()) ||
          thisRelayEntry.finalsHeat.trim() === ""
        badLane =
          /^0+$/.test(thisRelayEntry.finalsLane.trim()) ||
          thisRelayEntry.finalsLane.trim() === ""
      } else if (isPrelims) {
        badHeat =
          /^0+$/.test(thisRelayEntry.prelimHeat.trim()) ||
          thisRelayEntry.prelimHeat.trim() === ""
        badLane =
          /^0+$/.test(thisRelayEntry.prelimLane.trim()) ||
          thisRelayEntry.prelimLane.trim() === ""
      }
      if (badHeat || badLane) {
        if (isFinals) {
          let curCount = eventEntryCount.get(thisEventNumber)
          thisRelayEntry.finalsHeat = String(Math.floor((curCount - 1) / 4) + 1)
          thisRelayEntry.finalsLane = String(4 - (curCount % 4))
        } else if (isPrelims) {
          let curCount = eventEntryCount.get(thisEventNumber)
          thisRelayEntry.prelimHeat = String(Math.floor((curCount - 1) / 4) + 1)
          thisRelayEntry.prelimLane = String(4 - (curCount % 4))
        }
      }
    }
  }

  meetEvents.sort((evt1, evt2) =>
    evt1.eventNumber > evt2.eventNumber ? 1 : -1,
  )

  return {
    clubNames,
    swimmers,
    swimData: meetData,
    meetEvents,
  }
}
