import React, { useEffect, useState } from "react"
import { useStoreState, StateMapper } from "easy-peasy"
import { Redirect } from "react-router-dom"
import { Empty, message, Radio } from "antd"
import moment from "moment-timezone"
import { gen, IClub, swimminglyApi } from "./utils"
import { IDeclarationsMeet } from "./ViewMeetLineUpParent"
import CenterSpin from "./CenterSpin"
import { AppDataStore } from "../appData/types"
import styled from "styled-components"
import { screenSizes } from "../styles/GlobalStyles"
import { cloneDeep } from "lodash"
import { RadioChangeEvent } from "antd/lib/radio"
import Checkbox from "antd/lib/checkbox/Checkbox"

interface IElligibleEvent {
  meetEventOrderId: number
  eventNumber: number
  distance: string
  age_group: string
  eventGender: "Boys" | "Girls" | "Mixed"
  race_type: number
  enteredInEvent: 0 | 1 | null
  stroke: string
  isRelay: 0 | 1 | null
  best_time_at_merge: number | null
  bestTime: number | null
  isConverted: 0 | 1 | null
  minAge: number
  maxAge: number
}

interface ISwimmerDeclarationInfo {
  swimmerId: number
  first_name: string
  last_name: string
  middle_initials: string | null
  leagueAge: number
  date_of_birth: string
  removedFromMeet: 0 | 1 | null
  confirmedForMeet: 0 | 1 | null
  gender: "Boy" | "Girl"
  elligibleEvents: IElligibleEvent[]
}

interface ISwimmerEntriesTableParentProps {
  meet: IDeclarationsMeet
  club: IClub
}

const Overview = styled.div`
  margin-bottom: 50px;

  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media only screen and (max-width: ${screenSizes.small}px) {
    p {
      text-align: center;
      border-bottom: 1px solid lightgray;
    }
  }
`

const CardGridStyles = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 30px;
  row-gap: 25px;
  grid-auto-rows: auto auto;

  @media only screen and (max-width: ${screenSizes.medium}px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: ${screenSizes.small}px) {
    grid-template-columns: 1fr;
  }
`

const DeclarationCardStyle = styled.div`
  display: grid;
  @supports not (grid-template-rows: subgrid) {
    --rows: auto 1fr;
  }
  grid-template-rows: var(--rows, subgrid);
  grid-row: span 2;
  row-gap: 0;

  border: 1px solid darkgrey;
  border-radius: 5px;

  .name {
    padding-left: 5px;
    background-color: var(--primaryblue);
    color: var(--white);
    padding-top: 5px;
  }

  .content {
    padding-left: 5px;
  }

  @media only screen and (max-width: ${screenSizes.small}px) {
    min-width: 270px;
    max-width: 400px;
    margin: auto;
    grid-template-rows: auto 1fr;
  }
`

const DeclarationStatus = styled.div`
  float: right;

  @media only screen and (max-width: ${screenSizes.small}px) {
    display: bloack;
    float: none;
    margin: auto;
    text-align: center;
    margin-bottom: 25px;
  }
`

const SwimmerDeclarationCard = ({
  swimmerDeclarationInfo,
  setSwimmerAttending,
  meetId,
  canEdit,
  canSubmitEntries,
  setSwimmerEntry,
  closedEntryDateTime,
  clubId,
  userId,
  individualEntryLimit,
}: {
  swimmerDeclarationInfo: ISwimmerDeclarationInfo
  setSwimmerAttending: (val: boolean) => void
  meetId: number
  canEdit: boolean
  canSubmitEntries: boolean
  setSwimmerEntry: (eventNumber: number, entryVal: 0 | 1) => void
  closedEntryDateTime: string | null
  clubId: number | undefined
  userId: number
  individualEntryLimit: number
}) => {
  return (
    <DeclarationCardStyle>
      <div className="name">
        {swimmerDeclarationInfo.first_name}{" "}
        {swimmerDeclarationInfo.middle_initials}{" "}
        {swimmerDeclarationInfo.last_name}
      </div>
      <div className="content">
        <p>
          {swimmerDeclarationInfo.gender} - {swimmerDeclarationInfo.leagueAge}
        </p>
        <p style={{ marginBottom: "0" }}>Attending?</p>
        <Radio.Group
          onChange={(e: RadioChangeEvent) => {
            if (
              closedEntryDateTime &&
              moment(closedEntryDateTime).diff(moment()) < 0
            ) {
              alert(
                "The meet entry deadline has passed. Please contact your coach for any additional changes.",
              )
              return
            }
            const isAttending = e.target.value
            let confirmEdit = true
            if (!canEdit) {
              message.error(
                "Meet declarations are closed for this meet. Your coaches will finalize the meet lineup.",
              )
              return
            }
            if (
              !isAttending &&
              swimmerDeclarationInfo.elligibleEvents.find(
                (elligibleEvent) => elligibleEvent.enteredInEvent,
              )
            ) {
              confirmEdit = window.confirm(
                `This will remove ${swimmerDeclarationInfo.first_name} ${swimmerDeclarationInfo.last_name} from all events in the meet. Are you sure you want to do that?`,
              )
            }
            if (confirmEdit) {
              swimminglyApi
                .post(
                  gen(
                    `/api/declareSwimmerForMeet?swimmerId=${swimmerDeclarationInfo.swimmerId}&meetId=${meetId}`,
                  ),
                )
                .body({ isAttending })
                .then((data) => {
                  if (data.status === "success") {
                    setSwimmerAttending(e.target.value)
                    message.success("Your meet declarations for this swimmer have been saved & sent to club leadership!", 10)
                  } else {
                    message.error("Uh oh... something went wrong")
                  }
                })
                .catch(() => message.error("Uh oh... something went wrong"))
            }
          }}
          value={
            swimmerDeclarationInfo.confirmedForMeet === 1
              ? true
              : swimmerDeclarationInfo.removedFromMeet === 1
              ? false
              : null
          }
        >
          <Radio value={true}>yes</Radio>
          <Radio value={false}>no</Radio>
        </Radio.Group>
        <div>
          {canSubmitEntries && swimmerDeclarationInfo.confirmedForMeet === 1
            ? swimmerDeclarationInfo.elligibleEvents
                .filter((event) => event.isRelay !== 1)
                .sort((evt1, evt2) =>
                  evt1.eventNumber > evt2.eventNumber ? 1 : -1,
                )
                .map((event, idx) => (
                  <p
                    key={`${swimmerDeclarationInfo.swimmerId}_${event.eventNumber}_${idx}`}
                  >
                    <Checkbox
                      checked={event.enteredInEvent === 1 ? true : false}
                      onChange={(_e) => {
                        const currentEntriesCount =
                          swimmerDeclarationInfo.elligibleEvents.filter(
                            (theEvent) =>
                              theEvent.enteredInEvent === 1 &&
                              theEvent.isRelay !== 1,
                          ).length
                        if (
                          individualEntryLimit !== null &&
                          currentEntriesCount >= individualEntryLimit &&
                          event.enteredInEvent !== 1
                        ) {
                          message.error(
                            "You are trying to enter too many individual events for this meet.",
                          )
                          return
                        }
                        swimminglyApi
                          .post(gen("/api/swimmerEntryInSingleEvent"))
                          .body({
                            meetId: meetId,
                            clubId,
                            swimmerId: swimmerDeclarationInfo.swimmerId,
                            eventNumber: event.eventNumber,
                            seedTime: event.bestTime,
                            addOrRemove:
                              event.enteredInEvent === 1 ? "remove" : "add",
                            userId,
                          })
                          .then((data) => {
                            if (data.status === "success") {
                              message.success("Your entry selection has been saved & sent to club leadership!", 10)
                              setSwimmerEntry(
                                event.eventNumber,
                                event.enteredInEvent === 1 ? 0 : 1,
                              )
                            } else {
                              message.error("Uh oh, something went wrong")
                            }
                          })
                          .catch(() => {
                            message.error("Uh oh, something went wrong...")
                          })
                      }}
                    />{" "}
                    #{event.eventNumber} {event.age_group} {event.eventGender}{" "}
                    {event.distance} {event.stroke} 
                  </p>
                ))
            : null}
        </div>
      </div>
    </DeclarationCardStyle>
  )
}

export default function SwimmerEntriesTableParent({
  meet,
  club
}: ISwimmerEntriesTableParentProps) {
  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)
  const [loading, setLoading] = useState(true)
  const [relayEntryLimit, setRelayEntryLimit] = useState(0)
  const [individualEntryLimit, setIndividualEntryLimit] = useState(0)
  const [closedEntryDateTime, setClosedEntryDateTime] =
    useState<string | null>(null)
  const [canSubmitEntries, setCanSubmitEntries] = useState(false)
  const [swimmerMeetOptions, setSwimmerMeetOptions] = useState<
    ISwimmerDeclarationInfo[]
  >([])
  const [canEdit, setCanEdit] = useState(false)

  useEffect(() => {
    let mounted = true
    swimminglyApi
      .get(
        gen(
          `/api/getMeetDeclarationInfo/meetId=${meet.meetId}/clubId=${club.clubId}`,
        ),
      )
      .then(
        ({
          status,
          meetData,
        }: {
          status: string
          meetData: {
            meetDeclarationsCloseDate: string
            relayEntryLimit: number
            individualEntryLimit: number
            canSubmitEntries: boolean
          }
        }) => {
          if (status !== "success")
            message.error("Problem finding meet information")
          else if (mounted) {
            setRelayEntryLimit(meetData.relayEntryLimit)
            setIndividualEntryLimit(meetData.individualEntryLimit)
            setCanSubmitEntries(meetData.canSubmitEntries)
            setClosedEntryDateTime(meetData.meetDeclarationsCloseDate)
          }
        },
      )
    return () => {
      mounted = false
    }
  }, [meet.meetId, club, canSubmitEntries])

  useEffect(() => {
    if (moment(closedEntryDateTime).diff(moment()) > 0) setCanEdit(true)
    else setCanEdit(false)
  }, [closedEntryDateTime])

  useEffect(() => {
    let mounted = true
    if (user?.userId && club.clubId) {
      if (mounted) setLoading(true)
      swimminglyApi
        .get(
          gen(
            `/api/getSwimmersForMeetDeclarations?meetId=${meet.meetId}&guardianId=${user.userId}&clubId=${club.clubId}`,
          ),
        )
        .then(
          ({
            status,
            swimmers,
          }: {
            status: string
            swimmers: ISwimmerDeclarationInfo[]
          }) => {
            if (status !== "success")
              message.error("Problem finding meet entry information")
            else {
              if (mounted) {
                setSwimmerMeetOptions(swimmers)
                setLoading(false)
              }
            }
          },
        )
        .catch(() => message.error("Problem finding meet entry information."))
    }
    return () => {
      mounted = false
    }
  }, [user?.userId, club, meet.meetId])

  if (!user) return <Redirect to="/login" />
  if (!club.clubId) return <Redirect to="/app/landingPage" />

  const MeetOverview = () => (
    <Overview>
      <p>
        Deadline to complete is:{" "}
        <b>
          {moment(closedEntryDateTime)
            .utcOffset(-new Date().getTimezoneOffset())
            .format("YYYY-MM-DD h:mm a")}
        </b>
      </p>
      <p>
        Your team leadership will have final review &amp; complete relay entries
      </p>
      <p>
        Official Individual Entry Limit: <b>{individualEntryLimit}</b>
      </p>
      <p>
        Official Relay Entry Limit: <b>{relayEntryLimit}</b>
      </p>
    </Overview>
  )

  if (loading)
    return (
      <>
        <MeetOverview />
        <CenterSpin />
      </>
    )

  if (!swimmerMeetOptions || swimmerMeetOptions.length === 0)
    return (
      <>
        <MeetOverview />
        <p style={{ textAlign: "center" }}>
          It looks like you don't have any children who are elligible to swim
          events in this meet based on the age groups in the event lineup.
        </p>
        <Empty />
      </>
    )

  return (
    <>
      <DeclarationStatus>
        <p style={{ display: "inline" }}>Declarations are: </p>
        <div
          style={{
            display: "inline",
            border: "1px solid darkgray",
            padding: "3px",
            backgroundColor: canEdit
              ? "var(--approvalgreen)"
              : "var(--rejectionred)",
            color: "var(--white)",
          }}
        >
          {canEdit ? "open" : "closed"}
        </div>
      </DeclarationStatus>
      <MeetOverview />
      <div style={{ width: "50%", minWidth: "275px", margin: "auto" }}>
        <p style={{ textAlign: "center" }}>
          All changes you make on this screen are saved automatically and are
          immediately visible to your club's coaches and administrators, who
          have then ability to make final revisions to the meet lineup.
        </p>
      </div>
      <CardGridStyles>
        {swimmerMeetOptions.map((s, idx) => (
          <SwimmerDeclarationCard
            key={`declaration_card_${idx}`}
            meetId={meet.meetId}
            swimmerDeclarationInfo={s}
            setSwimmerAttending={(val: boolean): void => {
              const newSwimmerMeetOptions = cloneDeep(swimmerMeetOptions)
              const thisSwimmerMeetOptions = newSwimmerMeetOptions.find(
                (sMO) => sMO.swimmerId === s.swimmerId,
              )
              if (thisSwimmerMeetOptions) {
                thisSwimmerMeetOptions.confirmedForMeet = val ? 1 : 0
                thisSwimmerMeetOptions.removedFromMeet = !val ? 1 : 0
                thisSwimmerMeetOptions.elligibleEvents.forEach(
                  (elligibleEvent) => {
                    elligibleEvent.enteredInEvent = 0
                  },
                )
              }
              setSwimmerMeetOptions(newSwimmerMeetOptions)
            }}
            closedEntryDateTime={closedEntryDateTime}
            clubId={club.clubId}
            userId={user.userId}
            canEdit={canEdit}
            canSubmitEntries={canSubmitEntries}
            individualEntryLimit={individualEntryLimit}
            setSwimmerEntry={(eventNumber: number, entryVal: 0 | 1) => {
              const newSwimmerMeetOptions = cloneDeep(swimmerMeetOptions)
              const thisSwimmerMeetOptions = newSwimmerMeetOptions.find(
                (sMO) => sMO.swimmerId === s.swimmerId,
              )
              const thisEventInfo =
                thisSwimmerMeetOptions?.elligibleEvents.find(
                  (event) => event.eventNumber === eventNumber,
                )
              if (thisEventInfo) {
                thisEventInfo.enteredInEvent = entryVal
                setSwimmerMeetOptions(newSwimmerMeetOptions)
              }
            }}
          />
        ))}
      </CardGridStyles>
    </>
  )
}
