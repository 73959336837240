import { useState, useCallback } from "react"
import { Button, message, Modal, Select } from "antd"
import { swimminglyApi, gen } from "./utils"
import { updateSeasonMeetArrayListener } from "./MeetSchedulePageNew"
import { IClubSeasonInformation } from "./MeetActionsModal"

const { Option } = Select

interface IVirtualMeetCourseModalProps {
  virtualMeetId: number | null
  visible: boolean
  setVisible: (newVisible: boolean) => void
  clubSeason: IClubSeasonInformation | null
}

export default function VirtualMeetCourseModal({
  virtualMeetId,
  visible,
  setVisible,
  clubSeason,
}: IVirtualMeetCourseModalProps): JSX.Element {
  const [newCourse, setNewCourse] = useState<number | null>(null)
  const [loading, setLoading] = useState(false)

  const onOk = useCallback(() => {
    setLoading(true)
    swimminglyApi
      .post(gen("/api/updateVirtualMeetCourse"))
      .body({ virtualMeetId, newCourse })
      .then((data) => {
        if (!data || data.status === "error") {
          console.error(data || "outcome not found")
          message.error("Uh oh, something went wrong...")
        } else {
          message.success(
            "You've successfully updated the course of your virtual meet!",
          )
          setVisible(false)
          setNewCourse(null)
          if (clubSeason) {
            updateSeasonMeetArrayListener.emit(
              `updateSeasonMeets${clubSeason.clubSeasonId}`,
            )
          }
        }
      })
      .finally(() => setLoading(false))
  }, [virtualMeetId, newCourse, setLoading, clubSeason, setVisible])

  const onCancel = () => {
    setVisible(false)
    setNewCourse(null)
  }

  return (
    <Modal
      title="Update Virtual Meet Course"
      visible={visible}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={onOk}>
          Ok
        </Button>,
      ]}
    >
      <div>
        <p>Choose below to update the course of your virtual meet</p>
        <p>
          NOTE: this will update the Virtual Meet course for all participants.
        </p>
        <Select
          style={{ minWidth: "180px" }}
          value={newCourse || -1}
          onChange={(value) => setNewCourse(value === -1 ? null : value)}
        >
          <Option value={-1} disabled={newCourse !== null}>
            Choose course...
          </Option>
          <Option value={0}>SCY</Option>
          <Option value={1}>SCM</Option>
          <Option value={2}>LCM</Option>
        </Select>
      </div>
    </Modal>
  )
}
