import React, { useState, useEffect } from "react"
import { Button, message, Modal } from "antd"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../appData/types"
import { gen, swimminglyApi } from "./utils"

interface IRefundConfirmationModalProps {
  swimmerId: number | null
  swimmerName: string | null
  seasonId: number | null
  seasonName: string | null
  clubId: number | null
  clubName: string | null
  numSwims: number
  visible: boolean
  refundRegistration: boolean
  closeModal: () => void
}
export default function RefundConfirmationModal({
  swimmerId,
  swimmerName,
  seasonId,
  seasonName,
  clubId,
  clubName,
  numSwims,
  visible,
  closeModal,
}: IRefundConfirmationModalProps): JSX.Element {
  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)

  const refundSwimminglyFee = async () => {
    swimminglyApi
      .post(gen(`/api/refundSwimminglyFee`))
      .body({
        userId: user?.userId,
        swimmerId,
        seasonId,
        clubId,
      })
      .then((data) => {
        if (data.status === "success") {
          message.success("Successfully refunded your payment")
          closeModal()
        } else {
          message.error(data.message || "Something went wrong")
        }
      })
  }

  return (
    <Modal
      visible={visible}
      title="Swimmer Refund"
      footer={[
        <Button key="cancel" onClick={closeModal}>
          Cancel
        </Button>,
        <Button key="confirm" type="primary" onClick={refundSwimminglyFee}>
          Confirm
        </Button>,
      ]}
    >
      <p>
        You are requsting a refund for {swimmerName}'s fees for {clubName} in{" "}
        {seasonName}.
      </p>
      <p>
        Please be aware that{" "}
        <b>
          continuing this request WILL remove them from the roster for this
          season
        </b>
        . They will no longer be eligible to swim in any meets for {clubName} in
        this season on the Swimmingly platform. Do you wish to proceed?
      </p>
    </Modal>
  )
}
