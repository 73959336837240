import React from "react"
import { withRouter, Link } from "react-router-dom"
import swimHeader from "../images/swimmingly_final_header.png"
import moment from "moment-timezone"
class NotFound extends React.Component {
  render() {
    return (
      <div className="container">
        <div
          style={{
            paddingTop: "50px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="panel">
            <div className="panel-heading">
              <Link to="/" className="logo pull-left">
                <img
                  src={swimHeader}
                  width="250px"
                  height="50px"
                  alt="Swimmingly Header"
                />
              </Link>
              <h2 style={{ marginTop: "60px" }}>404 page not found </h2>
              <div style={{ fontSize: "16px" }}>
                Click <Link to="/">here</Link> to get back to safety
              </div>
            </div>
          </div>
        </div>
        <p className="text-center text-muted mt-md mb-md">
          &copy; Copyright {moment().format("YYYY")}. All Rights Reserved.
        </p>
      </div>
    )
  }
}

export default withRouter(NotFound)
