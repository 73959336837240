import styled from "styled-components"

export const StyledSetupInfoDisplay = styled.div`
  font-size: 1.5rem;

  li {
    text-align: left;
  }

  .contact-info {
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
  }
`
