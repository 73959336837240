import { useState, Fragment, useEffect } from "react"
import { Alert } from "antd"
import styled from "styled-components"
import moment, { Moment } from "moment-timezone"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../appData/types"
import { screenSizes } from "../styles/GlobalStyles"
import { DateFormats, gen, swimminglyApi } from "./utils"
import QRCode from "qrcode.react"
import React from "react"
import HappyCampers from "../images/HappyCampers.png"
import { Colors } from "./ClubSeasonManagement/styles"

export default function QrCode() {

  const StyledButton = styled.button`
    background-color: #101242;
    border-radius: 10px;
    border: 1px white;
    color: white;
  `

  const StyledParentInfo = styled.div`
    padding: 10px;
    margin-top: 20px;
    border: 1px solid #25bfea;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr 100px;
    column-gap: 25px;
    row-gap: 10px;

    .underlined {
      text-decoration: underline;
    }

    @media (max-width: ${screenSizes.small}px) {
      grid-template-columns: 1fr;
      .parent-information {
        order: 2;
      }
      ${StyledButton} {
        order: 1;
      }
    }
  `

  const Sizes = {
    Font: {
      standard: "68px"
    }
  }

  const impersonateClub = useStoreState(
    (state: StateMapper<AppDataStore>) => state.aliasedClub,
  )
  const [showMoreInfo, setShowMoreInfo] = useState(false)
  const [signupOpenDate, setSignupOpenDate] = useState<Moment | null>(null)

  useEffect(() => {
    if (impersonateClub?.clubId)
      swimminglyApi
        .get(gen(`/api/getEarliestSignupOpenDate/${impersonateClub.clubId}`))
        .then((data) => {
          if (data.status === "success" && data.earliestSignupOpen) {
            setSignupOpenDate(moment(data.earliestSignupOpen))
          } else {
            setSignupOpenDate(null)
          }
        })
  }, [impersonateClub?.clubId])

  if (signupOpenDate === null) return null


  if (impersonateClub?.secretToken) {

    const qrCode = (
      <QRCode
        id="qrCodeElToRender"
        size={541}
        value={`https://clubhouse.swimmingly.app/clubs/register/${impersonateClub.secretToken}`}
        bgColor="white"
        fgColor={Colors.veryDarkBlue}
        includeMargin
        level="M"
      />
    );
    
      
  return (
      <Alert
        message={
          <Fragment>
            <span>
               <b>{impersonateClub?.name_long || impersonateClub?.nameLong}</b> - Share this screen for in-person QR Code "Quick Scan" Registration:
              <br />
              This QR Code directly links to your club's registration page
              <br />
              <b>
                QR Code link opens to your Families on {signupOpenDate.format(DateFormats.shortMonthAndDay)}{" "}
              </b>
            </span>
            <br />
            <span>
              <StyledButton onClick={() => setShowMoreInfo(!showMoreInfo)}>
                {showMoreInfo
                  ? "Collapse"
                  : "Show QR Code"}
              </StyledButton>{" "}
            </span>
            {showMoreInfo && (
              
              <StyledParentInfo>
                
                <div style={{ 
                  fontSize: Sizes.Font.standard, 
                  color: Colors.veryDarkBlue, 
                  textAlign: "center",
                  }}
                >
                  {impersonateClub?.name_long || impersonateClub?.nameLong} Quick-Scan Registration
                  </div>
                  <div style={{ 
                  fontSize: Sizes.Font.standard, 
                  color: Colors.veryDarkBlue, 
                  textAlign: "center", 
                  gridColumn: "1 / span 2",
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr ",

                  }}
                >
                  <span>
                {qrCode}
                </span>
                <img src={HappyCampers} width="750"  />
                </div>
                <div style={{ 
                  fontSize: Sizes.Font.standard, 
                  color: Colors.veryDarkBlue, 
                  textAlign: "center",
                  paddingTop: "0px",
                  }}
                >
                  Scan Me & Register Your Swimmer(s)
                  </div>
              </StyledParentInfo>  
              
            )}
          </Fragment>
        }
        type="info"
      />
    )
  } else {
    return null
  }
}

