import React, { useState, useEffect } from "react"
import { Modal, Radio, Divider, Button, message } from "antd"
import { createResultsSD3 } from "./CreateResultsSD3"
import { download, gen, swimminglyApi } from "./utils"
import InlineTextEdit from "./InlineTextEdit"

interface IExportResultsModalProps {
  meetId: number
  clubId: number
  isVisible: boolean
  updateVisible: (input: boolean) => void
}

export default function ExportResultsModal({
  meetId,
  clubId,
  isVisible,
  updateVisible,
}: IExportResultsModalProps) {
  const [lsc, setLsc] = useState<string>("not set")
  const [teamCode, setTeamCode] = useState<string>("not set")
  const [loading, setLoading] = useState<boolean>(false)
  const [unknownSwimmerCount, setUnknownSwimmerCount] = useState<number>(0)
  const [isVirtualMeet, setIsVirtualMeet] = useState<boolean>(false)
  const [virtualMeetId, setVirtualMeetId] = useState<number | null>(null)
  const [exportVirtualMeetSd3, setExportVirtualMeetSd3] = useState<string>("no")
  const [exportEntireRegularMeet, setExportEntireRegularMeet] =
    useState<boolean>(false)

  useEffect(() => {
    let mounted = true
    if (clubId && meetId && isVisible) {
      if (mounted) {
        setLoading(true)
        setExportVirtualMeetSd3("no")
      }

      const virtualMeetDataPromise = swimminglyApi
        .post(gen("/api/isItAVirtualMeet"))
        .body({ meetId })
        .then((virtualMeetData) => {
          if (virtualMeetData && virtualMeetData.status === "success") {
            let theInfo = virtualMeetData.data
            if (theInfo.isVirtualMeet === false) {
              if (mounted) {
                setIsVirtualMeet(false)
                setVirtualMeetId(null)
              }
            } else if (
              theInfo.isVirtualMeet === true &&
              theInfo.virtualMeetInfo &&
              theInfo.virtualMeetInfo.length > 0 &&
              mounted
            ) {
              setIsVirtualMeet(true)
              setVirtualMeetId(theInfo.virtualMeetInfo[0].virtualMeetId)
            }
          }
        })
      const clubResponsePromise = swimminglyApi
        .post(gen("/api/lscAndClubCode"))
        .body({ clubId })
        .then((clubData) => {
          if (clubData.status === "success") {
            let { sd3_lsc, sd3_team_code } = clubData.data
            if (!sd3_lsc || sd3_lsc.trim() === "") {
              sd3_lsc = "not set"
            }

            if (!sd3_team_code || sd3_team_code.trim() === "") {
              sd3_team_code = "not set"
            }
            if (mounted) {
              setLsc(sd3_lsc)
              setTeamCode(sd3_team_code)
            }
          }
          return clubData
        })
      const resultsDataPromise = swimminglyApi
        .post(gen("/api/countUnknownSwimmersInResults"))
        .body({ meetId })
        .then((resultsData) => {
          if (resultsData.status === "success") {
            let theCounts = resultsData.data
            let updatedCounts = theCounts.filter(
              (x: { clubId: number; [key: string]: any }) =>
                x.clubId === clubId,
            )
            if (updatedCounts.length > 0) {
              let thisClubCount = parseInt(updatedCounts[0].unknownSwimmerCount)
              if (mounted) setUnknownSwimmerCount(thisClubCount)
            } else {
              if (mounted) setUnknownSwimmerCount(0)
            }
          }
        })
      Promise.all([
        virtualMeetDataPromise,
        clubResponsePromise,
        resultsDataPromise,
      ]).then(() => {
        if (mounted) setLoading(false)
      })
    }
    return () => {
      mounted = false
    }
  }, [clubId, meetId, isVisible])

  const updateStateFromApiResponse = async (
    clubResponse: Response,
  ): Promise<void> => {
    const clubData = await clubResponse.json()

    if (clubData.status === "success") {
      let { sd3_lsc, sd3_team_code } = clubData.data
      if (!sd3_lsc || sd3_lsc.trim() === "") {
        sd3_lsc = "not set"
      }

      if (!sd3_team_code || sd3_team_code.trim() === "") {
        sd3_team_code = "not set"
      }
      setLsc(sd3_lsc)
      setTeamCode(sd3_team_code)
    }
  }

  const handleOk = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setLoading(true)

    await updateLscOrTeamCode({
      lscCode: ["", "not set"].includes(lsc.trim()) ? null : lsc,
      teamCode: ["", "not set"].includes(teamCode.trim()) ? null : teamCode,
    })

    // TODO
    // START code to modify
    let result = await fetch(gen("/api/sd3ResultsData"), {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
      body: JSON.stringify({
        meetId: meetId,
        clubId: clubId,
        exportEntireRegularMeet: exportEntireRegularMeet,
        virtualMeetData: exportVirtualMeetSd3,
        virtualMeetId: virtualMeetId,
      }),
    }).then((response) => response.json())

    let { meetResults } = result
    let teamNames = meetResults
      .map((x: any) => x.clubLongName)
      .filter((v: any, i: number, a: Array<any>) => a.indexOf(v) === i)
    let shouldSetVisibleFalse: boolean = false
    for (let i = 0; i < teamNames.length; i++) {
      let teamCode = meetResults.filter(
        (x: any) => x.clubLongName === teamNames[i],
      )[0].teamCode
      let lsc = meetResults.filter(
        (x: any) => x.clubLongName === teamNames[i],
      )[0].lsc
      if (!teamCode && !lsc) {
        message.error(
          `SD3 Export failed: ${teamNames[i]} does not have an sd3 team code or lsc entered in the clubhouse.`,
        )
        shouldSetVisibleFalse = true
      } else if (!teamCode) {
        message.error(
          `SD3 Export failed: ${teamNames[i]} does not have an sd3 team code entered in the clubhouse.`,
        )
        shouldSetVisibleFalse = true
      } else if (!lsc) {
        message.error(
          `SD3 Export failed: ${teamNames[i]} does not have an lsc entered in the clubhouse.`,
        )
        shouldSetVisibleFalse = true
      }
    }
    if (shouldSetVisibleFalse === true) {
      updateVisible(false)
      return
    }
    let theSd3Text: string = createResultsSD3(meetResults) || ""
    // TODO - read in virtualMeetResults in addition to meetResults
    // then, give them a modal asking if they want the consolidated
    // results or their individual leg of the virtual meet
    if (theSd3Text !== "") {
      await download(theSd3Text, `SD3_Results.sd3`)
    } else {
      message.error("Uh oh, something went wrong...")
    }
    // END code to modify

    updateVisible(false)
  }

  const handleCancel = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    updateVisible(false)
  }

  const updateLscOrTeamCode = async (value: any) => {
    let theData: {
      clubId: number
      newLscCode?: string
      newTeamCode?: string
    } = { clubId }
    if (value.lscCode) {
      theData.newLscCode = value.lscCode
    }

    if (value.teamCode) {
      theData.newTeamCode = value.teamCode
    }

    const clubResponse = await fetch(gen("/api/updateLscAndClubCode"), {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(theData),
    })

    await updateStateFromApiResponse(clubResponse)
  }

  if (!clubId || !meetId) {
    return (
      <Modal
        title="Export Meet Results"
        width={800}
        visible={isVisible}
        footer={[
          <Button key="back" onClick={() => updateVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => updateVisible(false)}
          >
            Ok
          </Button>,
        ]}
      >
        <p>
          This is where you can export your meet results... but you have to pick
          a meet first!
        </p>
      </Modal>
    )
  }
  return (
    <Modal
      visible={isVisible}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleOk}
        >
          Ok
        </Button>,
      ]}
      width={800}
      title="Export Meet Results"
    >
      <div>
        <p>
          You are about to download the SD3/SDIF file for your swim club's
          results for this meet. Please make note of and/or update the
          following:
        </p>
        <ul>
          <li>
            Your club's lsc code is:{" "}
            <InlineTextEdit
              updateTheValue={(val: string) => {
                console.log("UPDATING VALUE")
                console.log(val)
                let newVal = val.trim().slice(0, 2).toUpperCase()
                console.log(newVal)
                setLsc(newVal)
              }}
            >
              {lsc}
            </InlineTextEdit>
          </li>
          <li>
            Your club's team code is:{" "}
            <InlineTextEdit updateTheValue={setTeamCode}>
              {teamCode}
            </InlineTextEdit>
          </li>
        </ul>
        {isVirtualMeet === true ? (
          <div>
            <br />
            <Divider />
            <p>
              This is a <b>virtual meet</b>! You can export:
            </p>
            <Radio.Group
              onChange={(e) => setExportVirtualMeetSd3(e.target.value)}
              value={exportVirtualMeetSd3}
            >
              <Radio value="no">My Meet Only</Radio>
              <Radio value="yes">Entire Virtual Meet</Radio>
            </Radio.Group>
            <Divider />
          </div>
        ) : (
          <div>
            <br />
            <Divider />
            <p>Would you like to:</p>
            <Radio.Group
              onChange={(e) => setExportEntireRegularMeet(e.target.value)}
              value={exportEntireRegularMeet}
            >
              <Radio value={false}>My Club Only</Radio>
              <Radio value={true}>All Clubs In Meet</Radio>
            </Radio.Group>
            <Divider />
          </div>
        )}
        {unknownSwimmerCount > 0 ? (
          <React.Fragment>
            <br />
            <p>
              You have unknown swimmers in your meet results. These swimmers
              will not be exported in your sd3 file. We recommend that you go to
              your meet results and update them to the correct swimmers before
              exporting.
            </p>
          </React.Fragment>
        ) : null}
        <br />
        <p>Additionally, please note the following for accuracy:</p>
        <p>
          The SD3/SDIF file uses a universally generated USA Swimming ID per
          swimmer based on the following info:
        </p>
        <p>
          <b> Example : (11/03/1987) Charles G Houchin: 110387CHAGHOUC</b>{" "}
          <br />
          --DoB: [MMDDYY] --First three letters of your legal first name --First
          letter of your middle name --First four letters of your last name. If
          your first or last names don't have enough letters [or you don't have
          a middle name or middle initial], an * [asterisk] will be used to fill
          in the blanks.
        </p>
        <p>
          For your convenience, the Swimmingly Clubhouse automatically does this
          for you (based on your swimmer info under 'Manage Roster') upon
          exporting this file. Some other team management systems allow users to
          manually change this ID. To be compatible, we recommend you maintain
          the automatically generated ID in the system to which you're
          attempting to import this file.
        </p>
        <p>
          Note: the USA Swimming ID described above is not related to each
          Swimmer ID used on the app or in the Clubhouse
        </p>
        <p>
          LSC and Club Codes, editable under your 'Manage Club' tab must match
          the same codes you have in your team management system in which you
          wish to import. Hint: LSC code is usually your state's abbreviation
          and Club code is usually your club's 4 letter abbreviation. If you are
          trying to map to an existing swimmer in a separate team management
          system that contains the middle initial for each swimmer, you must
          include that information for each swimmer under 'Manage Roster'.
          Otherwise, a new swimmer will be created. Double check the file
          extension when you download this file (*.SD3). If it does not end with
          this, use a different web browser (we recommend Chrome).
        </p>
      </div>
    </Modal>
  )
}
