import React, {
  FunctionComponent,
  useState,
  useEffect,
  CSSProperties,
} from "react"
import { ISeasonDetails } from "../../../App"
import moment, { Moment } from "moment-timezone"
import { Button, Skeleton, Tooltip } from "antd"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../../../appData/types"
import ContentPanel from "../../ContentPanel"
import { StyledSetupInfoDisplay } from "./styles"
import {
  Colors as SeasonSetupColors,
  Sizes as SeasonSetupSizes,
} from "../styles"
import {
  URLs,
  DateFormats,
  formatMoney,
  gen,
  IClubSeasonInfo,
  PaymentTypes,
  swimminglyApi,
  IUser,
  Amounts,
} from "../../utils"
import StyledDatePicker from "../../PreSeasonModals/StyledDatePicker"
import { useClubSeasonManagementContext } from "../sharedState"
import {
  LeftOutlined,
  SolutionOutlined,
  UsergroupAddOutlined,
  ControlOutlined,
  DollarCircleOutlined,
  SendOutlined,
  SmileOutlined,
  ScheduleOutlined,
  CarryOutOutlined,
  BulbOutlined,
  QrcodeOutlined,
  DashboardOutlined,
  InfoCircleOutlined,
  ImportOutlined
} from "@ant-design/icons"
import { Divider, Steps, Row, Col, Space } from "antd"
import { RegistrationHelper } from "../RegistrationConfiguration/RegistrationHelper"
import Footer from "../../Footer/index";
import { FeeHelper } from "../Fees"
import _ from "lodash"

const { Step } = Steps


const confirmSeasonOpen = async (clubId: number, seasonId: number, userId: number, signupsOpenDate: Moment, setSignupsOpen: (value: Moment | null) => void) => {
  const helper = new RegistrationHelper()
  const didUpdateSignupOpen = await helper.updateSignupOpenDate({ clubId: clubId, seasonId: seasonId, confirmedByUserId: userId, date: signupsOpenDate })
  if (didUpdateSignupOpen) {
    setSignupsOpen(signupsOpenDate)
  }
}


interface IBaseSeasonConfigurationNoRegistrationProps {
  signupsOpen: Moment | null
  setSignupsOpen: (value: Moment | null) => void
  settingsConfirmed: boolean
  setSettingsConfirmed: (value: boolean) => void
}
interface ISeasonConfigurationNoRegistrationProps
  extends IBaseSeasonConfigurationNoRegistrationProps {
  settingsConfirmed: boolean
  setReviewRegistration: (value: boolean) => void
}

interface IConfirmDateProps
  extends IBaseSeasonConfigurationNoRegistrationProps {
  seasonInfo: ISeasonDetails | null
  selectedClubSeason: IClubSeasonInfo | null
  user: IUser | null
  frontendUsingRegistration: boolean
}

const Icons = {
  welcome: (
    <SolutionOutlined style={{ color: SeasonSetupColors.seasonSetupBlue }} />
  ),
  code: (
    <QrcodeOutlined
      style={{
        fontSize: SeasonSetupSizes.iconSize,
        color: SeasonSetupColors.primaryBlue,
      }}
    />
  ),
  import: (
    <ImportOutlined
      style={{
        fontSize: SeasonSetupSizes.iconSize,
        color: SeasonSetupColors.primaryBlue,
      }}
    />
  ),
  send: (
    <SendOutlined
      style={{
        fontSize: SeasonSetupSizes.iconSize,
        color: SeasonSetupColors.primaryBlue,
      }}
    />
  ),
  charges: (
    <DollarCircleOutlined
      style={{
        fontSize: SeasonSetupSizes.smallIconSize,
        color: SeasonSetupColors.primaryBlue,
      }}
    />
  ),
  dashboard: (
    <DashboardOutlined
      style={{
        fontSize: SeasonSetupSizes.smallIconSize,
        color: SeasonSetupColors.primaryBlue,
      }}
    />
  ),
  roster: (
    <UsergroupAddOutlined
      style={{
        fontSize: SeasonSetupSizes.smallIconSize,
        color: SeasonSetupColors.primaryBlue,
      }}
    />
  ),
  noFees: (
    <SmileOutlined
      style={{
        fontSize: SeasonSetupSizes.smallIconSize,
        color: SeasonSetupColors.primaryBlue,
      }}
    />
  ),
  signUp: (
    <ScheduleOutlined
      style={{
        fontSize: SeasonSetupSizes.smallIconSize,
        color: SeasonSetupColors.primaryBlue,
      }}
    />
  ),
  parents: (
    <ControlOutlined
      style={{
        fontSize: SeasonSetupSizes.smallIconSize,
        color: SeasonSetupColors.primaryBlue,
      }}
    />
  ),
  calendar: (
    <CarryOutOutlined
      style={{
        fontSize: SeasonSetupSizes.smallIconSize,
        color: SeasonSetupColors.primaryBlue,
      }}
    />
  ),
  linkOut: <QrcodeOutlined style={{ color: "white" }} />,
}

type SeasonSetupHeaderProps = {
  title: string
  iconName?: string
  iconNumber?: number
}
const SeasonSetupHeader = ({
  title,
  iconName,
  iconNumber,
}: SeasonSetupHeaderProps) => {
  const icon = Icons[iconName as keyof typeof Icons]
  const initial = (iconNumber || 1) - 1
  return (
    <Divider
      style={{ borderWidth: 1, borderColor: SeasonSetupColors.seasonSetupBlue }}
    >
      <Steps initial={initial}>
        {icon ? (
          <Step title={title} icon={icon} />
        ) : (
          <Step title={title} status="process" />
        )}
      </Steps>
    </Divider>
  )
}

type IconMessageProps = {
  iconName?: string
  present?: boolean
  width?: string
  center?: boolean
  contentColumnSpan?: number
  children?: React.ReactNode
}
const WelcomeColumn: FunctionComponent<IconMessageProps> = ({
  iconName,
  children,
}: IconMessageProps) => {
  return (
    <Col span={11} style={{ textAlign: "center", justifyContent: "center", padding: "35px" }}>
      {Icons[iconName as keyof typeof Icons]}
      <p>
        {children}</p>
    </Col>
  )
}

const ConfirmMessage: FunctionComponent<IconMessageProps> = ({
  iconName,
  present = true,
  width,
  center = false,
  children,
}: IconMessageProps) => {
  return IconicContainer({
    iconName: iconName,
    present: present,
    width: width,
    center: center,
    children: children,
    contentColumnSpan: 14,
  })
}



const HorizontalLine = () => {
  return (
    <Divider style={{ borderWidth: 1, borderColor: SeasonSetupColors.primaryBlue }} />
  )

}

const IconicContainer: FunctionComponent<IconMessageProps> = ({
  iconName = undefined,
  present = true,
  width,
  center = false,
  children,
  contentColumnSpan = undefined,
}: IconMessageProps) => {
  if (!present) {
    return null
  }
  const widthStyle = Boolean(width) ? { width: width } : {}
  const centerStyle = center ? { margin: "auto" } : {}
  return (
    <Row
      justify="center"
      gutter={32}
      style={Object.assign(widthStyle, centerStyle)}
    >
      <Col>{Boolean(iconName) && Icons[iconName as keyof typeof Icons]}</Col>
      <Col span={contentColumnSpan}>{children}</Col>
    </Row>
  )
}

type TextContainerProps = {
  children?: React.ReactNode
  present?: boolean
  style?: CSSProperties
}

const TextContainer: FunctionComponent<TextContainerProps> = ({
  children,
  present = true,
  style,
}: TextContainerProps) => {
  if (!present) {
    return null
  }
  return (
    <p
      style={Object.assign(
        {
          textAlign: "center",
          width: "60%",
          marginRight: "auto",
          marginLeft: "auto",
        },
        style,
      )}
    >
      {children}
    </p>
  )
}


type DisableableProps = {
  disabled: boolean
  children?: React.ReactNode
  present?: boolean
  style?: CSSProperties
}

const DisableableContainer = ({
  disabled,
  children,
  present = true,
  style,
}: DisableableProps) => {
  if (!present) {
    return null
  }
  return (
    <div style={Object.assign(style || {}, { position: "relative" })}>
      {children}
      <div
        id="signupsOpenOverlay"
        style={{
          display: disabled ? "block" : "none",
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0,0,0,0.5)",
          zIndex: 9,
        }}
      ></div>
    </div>
  )
}

export const ConfirmSignupOpenDate: FunctionComponent<IConfirmDateProps> = ({
  signupsOpen,
  setSignupsOpen,
  settingsConfirmed,
  setSettingsConfirmed,
  seasonInfo,
  selectedClubSeason,
  user,
  frontendUsingRegistration
}) => {

  const { loadBillingInfo, billingInfo, loadUpcomingSeason } = useClubSeasonManagementContext()

  const [hasDefaultCardOnFile, setHasDefaultCardOnFile] = useState(false)
  const [canOpenSeason, setCanOpenSeason] = useState(false)
  const [latestSeasonOpenDate, setLatestSeasonOpenDate] = useState<Moment | undefined>(undefined)

  const clubId = selectedClubSeason?.clubId || undefined

  const earliestSeasonOpenDate = moment()

  useEffect(() => {
    //keith says: we *should* be able to get away without this extra call to loadBillingInfo if we've already loaded it
    if (Boolean(selectedClubSeason?.seasonId) && Boolean(user?.clubId)) {
      loadBillingInfo(clubId, selectedClubSeason?.seasonId)
    }
  }, [clubId, loadBillingInfo, selectedClubSeason?.seasonId, user?.clubId])

  useEffect(() => {
    setHasDefaultCardOnFile(billingInfo.hasDefaultCardOnFile)

    const validToPayByParentOrLumpSum = PaymentTypes.isByParent(selectedClubSeason?.paymentType) ||
      PaymentTypes.isLumpSum(selectedClubSeason?.paymentType)
    const validToPayByClub = PaymentTypes.isByClub(selectedClubSeason?.paymentType) && hasDefaultCardOnFile
    const hasValidPaymentType = validToPayByParentOrLumpSum || validToPayByClub

    const canOpenSeason = Boolean(user?.userId) &&
      (frontendUsingRegistration || hasValidPaymentType || selectedClubSeason?.isSwimminglyCustomer === 0) 

    setCanOpenSeason(canOpenSeason)
  }, [loadBillingInfo, billingInfo, hasDefaultCardOnFile, selectedClubSeason?.paymentType, user?.userId])

  useEffect(() => {
    const date = selectedClubSeason?.endDate ? moment(selectedClubSeason.endDate, DateFormats.default) : undefined
    setLatestSeasonOpenDate(date)
  }, [selectedClubSeason])


  const clubPaysNoCardOnFile = !frontendUsingRegistration &&
    PaymentTypes.isByClub(selectedClubSeason?.paymentType) &&
    !hasDefaultCardOnFile

  const clubPaysHasCardOnFile = !frontendUsingRegistration &&
    PaymentTypes.isByClub(selectedClubSeason?.paymentType) &&
    hasDefaultCardOnFile


  const ServiceFeeToolTip: FunctionComponent = () => {
    return (
      <Tooltip
        title="Service fee is 5% and covers the credit card and Swimmingly processing fees. i.e. Transaction for $18 standard Swimmingly membership has a service fee of 90 cents."
      >
        <InfoCircleOutlined />
      </Tooltip>
    )
  }


  return (
    <StyledSetupInfoDisplay>
      <SeasonSetupHeader title="Welcome" iconName="welcome" />
      <TextContainer>
       Welcome back! Follow these steps to open <b>{seasonInfo?.seasonName}</b> & generate your Club Registration Code:
      </TextContainer>
      <Row justify="center" >
        <WelcomeColumn iconName="code">
          NEW families will need the Club Registration Code to register.<br />This is a club-level code to be shared with any family registering. <br /><br /><br />RETURNING
          families <b>do not need</b> the Club Registration Code - they will login, select your club as an existing club, & register for the new season.
        </WelcomeColumn>
        <WelcomeColumn iconName="send">
          Follow the <b>Club Registration Code</b> instructions in the activated tab above once your season has been opened below. These instructions are emailed to parents so they can successfully register.
          <br /><br />
          <b>Pro tip</b>: Returning parents will{" "}
          <b>
            <i>automatically see your club</i>
          </b>{" "}
          when they login, and can complete registration without needing the Club
          Registration Code!
        </WelcomeColumn>
        {!Boolean(selectedClubSeason?.isSwimminglyCustomer) ?
          (<WelcomeColumn iconName="import">
            <b>OR, AS A GUEST CLUB </b>
             simply import your meet entries for any swim meet and your swimmers will automatically populate on your guest club roster.
          </WelcomeColumn>) : null
        }
      </Row>

      {Boolean(selectedClubSeason?.isSwimminglyCustomer) ? (
        <>
          <SeasonSetupHeader title="Confirm Payment Method" iconNumber={1} />
          <TextContainer>
            The standard Swimmingly membership is $18.00/swimmer.
            <br />
            <b>Your club's rate for the season</b>:{" "}
            {new FeeHelper().swimminglyMembershipFees(selectedClubSeason, frontendUsingRegistration)} per swimmer
            <br />
          </TextContainer>
          <TextContainer present={!Boolean(selectedClubSeason?.usingRegistration)}>
            Your club has elected to pay{" "}
            <b>{selectedClubSeason?.paymentType ?? PaymentTypes.unknown}</b>
          </TextContainer>
          <TextContainer present={Boolean(selectedClubSeason?.usingRegistration)}>
            Your club has elected to use <b>Premium Club Registration</b>
          </TextContainer>

          <Space direction="vertical">
            <ConfirmMessage
              iconName="charges"
              present={Boolean(selectedClubSeason?.usingRegistration)}
            >
              Guardians will register their swimmers, pay Swimmingly membership and a small processing fee. Your club will receive revenue from
              Registration Dues & Additional Checkout Items that you setup (Step 3) - deposited into your club's connected bank account.

            </ConfirmMessage>

            <ConfirmMessage
              iconName="dashboard"
              present={Boolean(selectedClubSeason?.usingRegistration)}
            >
              Pause/Resume Registration at anytime, track the number of registrations, track the number of registered athletes, and
              download your up-to-date registration report anytime!

            </ConfirmMessage>

            <ConfirmMessage
              iconName="charges"
              present={!Boolean(selectedClubSeason?.usingRegistration) && !PaymentTypes.isLumpSum(selectedClubSeason?.paymentType) && !PaymentTypes.isByParent(selectedClubSeason?.paymentType)}
            >
              Charges to your club's card will be processed as your families register
              swimmers, using the Club Registration Code, plus a{" "}
              <ServiceFeeToolTip />
              {" "} small service fee. The total invoice will be
              available under 'Club Billing' in your Clubhouse for your records.

            </ConfirmMessage>
            <ConfirmMessage
              iconName="charges"
              present={!Boolean(selectedClubSeason?.usingRegistration) && PaymentTypes.isLumpSum(selectedClubSeason?.paymentType)}
            >
              No Swimmingly® membership fee charged when parents register swimmers as
              your organization has pre-arranged offline payment. Your organization
              will be invoiced at the start of the season, and will receive follow up
              invoices if more swimmers are registered.
            </ConfirmMessage>
            <ConfirmMessage
              iconName="charges"
              present={!Boolean(selectedClubSeason?.usingRegistration) && PaymentTypes.isByParent(selectedClubSeason?.paymentType)}
            >
              Parents/guardians will register their swimmers using the Club Registration Code and
              pay the Swimmingly® membership fee plus{" "}
              <ServiceFeeToolTip />
              {" "}small service fee.
            </ConfirmMessage>
            <ConfirmMessage iconName="roster" present={!Boolean(selectedClubSeason?.usingRegistration)}>
              Registered swimmers will auto-populate on the 'Manage Roster' screen for this season. Coaches can enter registered swimmers into any swim meet for the season!
            </ConfirmMessage>
            <ConfirmMessage iconName="roster" present={Boolean(selectedClubSeason?.usingRegistration)}>
              Registered swimmers will auto-populate on the 'Manage Roster' screen for this season. Coaches can enter registered swimmers into any swim meet for the season!
            </ConfirmMessage>
            <ConfirmMessage
              iconName="noFees"
              present={!Boolean(selectedClubSeason?.usingRegistration) && !PaymentTypes.isLumpSum(selectedClubSeason?.paymentType) && !PaymentTypes.isByParent(selectedClubSeason?.paymentType)}
            >
              Promote registration using the <b>Club Registration Code</b> instruction to any family!
            </ConfirmMessage>
            <ConfirmMessage
              iconName="noFees"
              present={!Boolean(selectedClubSeason?.usingRegistration) && !PaymentTypes.isLumpSum(selectedClubSeason?.paymentType) && PaymentTypes.isByParent(selectedClubSeason?.paymentType)}
            >
              Your club pays <b>nothing</b> and can promote
              registration to any families to join your swim team!
            </ConfirmMessage>
            <ConfirmMessage
              iconName="noFees"
              present={Boolean(selectedClubSeason?.usingRegistration) && !PaymentTypes.isLumpSum(selectedClubSeason?.paymentType)}
            >
              Your club incurs no additional processing fees as you collect revenue via registration dues & any additional checkout items that you have setup. You can promote Club Registration to an family by emailing them the Club Registration Code and instructions!
            </ConfirmMessage>
            <ConfirmMessage
              iconName="signUp"
              present={!Boolean(selectedClubSeason?.usingRegistration) && !PaymentTypes.isLumpSum(selectedClubSeason?.paymentType) && !PaymentTypes.isByClub(selectedClubSeason?.paymentType)}
            >
              Parents can register their swimmers anytime after your registration-open date below.
            </ConfirmMessage>
            <ConfirmMessage
              iconName="signUp"
              present={!Boolean(selectedClubSeason?.usingRegistration) && !PaymentTypes.isLumpSum(selectedClubSeason?.paymentType) && !PaymentTypes.isByParent(selectedClubSeason?.paymentType)}
            >
              Parents can register their swimmers anytime after your registration-open date below.
            </ConfirmMessage>
            <ConfirmMessage
              iconName="signUp"
              present={Boolean(selectedClubSeason?.usingRegistration) && !PaymentTypes.isLumpSum(selectedClubSeason?.paymentType)}
            >
              Parents can register at anytime after the date you choose to open Club Registration.
            </ConfirmMessage>
            <ConfirmMessage iconName="parents" present={!Boolean(selectedClubSeason?.usingRegistration)}>
              Parents can easily participate in meet declarations, access SwimminglyFan for live results, and track their athletes' time improvements here in the Swimmingly Clubhouse & more!
            </ConfirmMessage>
            <ConfirmMessage iconName="parents" present={Boolean(selectedClubSeason?.usingRegistration)}>
              Parents can easily participate in meet declarations, access SwimminglyFan for live results, and track their athletes' time improvements here in the Swimmingly Clubhouse & more!
            </ConfirmMessage>
          </Space>

          <SeasonSetupHeader title={`${settingsConfirmed ? "" : "Choose"} Registration-Open Date`} iconNumber={2} />

          <TextContainer
            style={{ color: SeasonSetupColors.hasCardOnFileText }}
            present={clubPaysHasCardOnFile}
          >
            Your club has a default payment card on file and can proceed with this
            step to open your season. Double check that your desired card is marked
            as 'default payment' if you have more than one card on file (found under
            'Club Billing' tab in navigation sidebar). Then proceed with this step to confirm your open-season
            date!
          </TextContainer>

          <TextContainer
            style={{ color: SeasonSetupColors.noCardOnFileText }}
            present={clubPaysNoCardOnFile}
          >
            Your club does not have a default card payment under the Club Billing tab (in your navigation sidebar).
            Navigate to the 'Club Billing' tab, add a card, and
            select 'Make Primary Method'. Once you've done so, navigate back to this page
            to complete this step and confirm your registration-open date to begin your
            season!
            <br /><br />
            If you have any questions about your payment method, read our customer policy page  <a href="https://swimmingly.app/customer-policy" target="_blank" rel="noopener noreferrer">here.</a>
          </TextContainer>
        </>
      ) : (<SeasonSetupHeader title={`${settingsConfirmed ? "" : "Choose"} Registration-Open Date`} iconNumber={2} />)}


      <DisableableContainer disabled={!canOpenSeason}  >
        <TextContainer present={!settingsConfirmed}
          style={{ color: "green" }}
        >
          By confirming the registration-open date below, you acknowledge that you are authorized to take the following actions:
          <br /><br />
          <ol>
            <li>
              You are authorizing your club to begin registration for {selectedClubSeason?.seasonName}.
            </li>
            <li>
              You are authorizing your club to collect revenue per your setup configuration in Steps 1-3 above.
            </li>
            <li>
              You are authorized to open your club's registration to parents/guardians on the following date:
            </li>
          </ol>
        </TextContainer>

        <IconicContainer iconName="calendar" width="50%" center={true} present={!settingsConfirmed} >
          <StyledDatePicker
            bordered={false}
            value={signupsOpen}
            onChange={(newDate) => setSignupsOpen(newDate)}
            size="large"
            disabledDate={(newDate) => newDate.isBefore(earliestSeasonOpenDate) || (!_.isNil(latestSeasonOpenDate) && newDate.isAfter(latestSeasonOpenDate))}
          />
        </IconicContainer>
        <br />
        <IconicContainer center={true} present={!settingsConfirmed} >
          <Button
            type="primary"
            onClick={() => {
              if (
                canOpenSeason &&
                selectedClubSeason != null &&
                user != null &&
                signupsOpen != null
              ) {
                confirmSeasonOpen(selectedClubSeason.clubId, selectedClubSeason.seasonId, user.userId, signupsOpen, (date) => {
                  setSettingsConfirmed(true)
                  setSignupsOpen(date)
                  loadUpcomingSeason()
                })
              }
            }}
            style={{ margin: "auto" }}
          >

            Confirm Date & Open Season Registration
          </Button>
        </IconicContainer>
        <IconicContainer center={true} present={settingsConfirmed} >
          <span style={{ color: "#111111", fontSize: "18px", backgroundColor: "#AAFF00", justifyContent: "center", }}>Thanks!
            Your registration-open date is confirmed for {signupsOpen?.format(DateFormats.default) ?? " this season."}.
            <br />
            Scroll up to the header - your Club Registration Code tab is now activated & includes registration instructions to send your families.
            <br />
            On {signupsOpen?.format(DateFormats.default) ?? " this season."}, your Club Registration Code link will officially open to parents, allowing registration to begin.</span>
        </IconicContainer>
      </DisableableContainer>
    </StyledSetupInfoDisplay>
  )
}

export const SeasonConfigurationNoRegistration: FunctionComponent<ISeasonConfigurationNoRegistrationProps> =
  ({
    signupsOpen,
    setSignupsOpen,
    settingsConfirmed,
    setSettingsConfirmed,
    setReviewRegistration,
  }) => {
    const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)
    const impersonateClub = useStoreState(
      (state: StateMapper<AppDataStore>) => state.aliasedClub,
    )

    const [understandsRulesOfEngagement, setUnderstandsRulesOfEngagement] =
      useState(false)
    const { selectedClubSeason, loadBillingInfo, billingInfo, upcomingSeason } =
      useClubSeasonManagementContext()

    const [seasonInfo, setSeasonInfo] = useState<ISeasonDetails | null>(null)

    useEffect(() => {
      if (
        Boolean(selectedClubSeason?.seasonId) &&
        Boolean(impersonateClub?.clubId)
      ) {
        loadBillingInfo(impersonateClub?.clubId, selectedClubSeason?.seasonId)
      }
    }, [selectedClubSeason?.seasonId, impersonateClub?.clubId, loadBillingInfo])

    useEffect(() => {
      if (billingInfo.seasonInfo !== undefined) {
        //keith says: still trying to figure out the best way to handle typescript/javascript's squirlyness around null, undefined and the ? decorator
        setSeasonInfo(billingInfo.seasonInfo)
      } else {
        setSeasonInfo(null)
      }
    }, [loadBillingInfo, billingInfo])

    if (!selectedClubSeason)
      return (
        <ContentPanel marginTop="25px" title="Loading...">
          <Skeleton />
        </ContentPanel>
      )

    return (
      <ContentPanel
        marginTop="25px"
        title={
          <span>
            {understandsRulesOfEngagement && !settingsConfirmed ? (
              <Tooltip title="Go back to review payment method">
                <LeftOutlined
                  onClick={() => setUnderstandsRulesOfEngagement(false)}
                  style={{
                    color: "var(--linkblue)",
                    cursor: "pointer",
                    marginRight: "20px",
                  }}
                />
              </Tooltip>
            ) : !Boolean(upcomingSeason?.seasonSetupConfirmedOn) && !settingsConfirmed ? (
              <Tooltip title="Go back to review registration">
                <LeftOutlined
                  onClick={() => setReviewRegistration(true)}
                  style={{
                    color: "var(--linkblue)",
                    cursor: "pointer",
                    marginRight: "20px",
                  }}
                />
              </Tooltip>
            ) : undefined}
            {impersonateClub?.nameLong} Season Setup & Confirmation
          </span>
        }
      >
        <ConfirmSignupOpenDate
          signupsOpen={signupsOpen}
          setSignupsOpen={setSignupsOpen}
          settingsConfirmed={settingsConfirmed}
          setSettingsConfirmed={setSettingsConfirmed}
          seasonInfo={seasonInfo}
          selectedClubSeason={selectedClubSeason}
          user={user}
          frontendUsingRegistration={false}
        />

        <HorizontalLine />
        <Footer />
      </ContentPanel>
    )
  }
