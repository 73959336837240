import React, { useState } from "react"
import { parseTime, gen } from "./utils"

interface IEditMeetEntryTimeProps {
  timeString: string
  entryId: number
  changeEditEntryId: (input: any) => void
  refreshSwimmers: () => void
}

export default function EditMeetEntryTime({
  timeString,
  entryId,
  changeEditEntryId,
  refreshSwimmers,
}: IEditMeetEntryTimeProps) {
  const [value, setValue] = useState<string>(timeString)
  const [loading, setLoading] = useState<boolean>(false)

  const isValidTime = (theTimeString: string) => {
    if (!theTimeString) {
      return { isGood: false, seconds: null }
    }

    theTimeString = String(theTimeString)
    // Check if isValid
    if (theTimeString.match(/^[0-9:.]+$/) === null) {
      console.log("error1")
      return { isGood: false, seconds: null }
    }
    if (theTimeString.split(".").length - 1 > 1) {
      console.log("error2")
      return { isGood: false, seconds: null }
    }

    if (theTimeString.split(":").length - 1 > 1) {
      console.log("error3")
      return { isGood: false, seconds: null }
    }

    // 1.23
    // 01:23.45
    // 01:34
    // 12.34
    // 34
    let parsedTime = parseTime(theTimeString)
    let seconds: number | null = null
    if (parsedTime !== null) {
      seconds = parsedTime / 100
    }

    let isGood = true
    if (seconds === null) {
      isGood = false
    }

    return { isGood, seconds }
  }

  const saveBestTime = () => {
    let { isGood, seconds }: { isGood: boolean; seconds: number | null } =
      isValidTime(value)

    if (isGood) {
      setLoading(true)
      fetch(gen(`/api/updateSwimmerBestTimeForEntryPost`), {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
        body: JSON.stringify({
          entry_id: entryId,
          best_time_at_merge: seconds,
        }),
      }).then(() => {
        setLoading(false)
        changeEditEntryId(null)
        refreshSwimmers()
      })
    }
  }

  const add = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === "Enter") {
      saveBestTime()
    }
  }

  return (
    <span>
      {loading ? <span>Loading...</span> : null}
      <input
        type="text"
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
        }}
        onBlur={saveBestTime}
        onKeyPress={add}
      />
    </span>
  )
}
