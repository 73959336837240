import React, { useEffect, useState, Fragment } from "react"
import { useStoreState, StateMapper } from "easy-peasy"
import { Link, useHistory } from "react-router-dom"
import { Button, DatePicker, Tooltip, message, Modal, Divider } from "antd"
import {
  CheckCircleTwoTone,
  CloseCircleOutlined,
  CloseCircleTwoTone,
  LoadingOutlined,
  PlusSquareTwoTone,
} from "@ant-design/icons"
import styled from "styled-components"
import moment, { Moment } from "moment-timezone"
import { FaCreditCard } from "react-icons/fa"
import { AppDataStore } from "../appData/types"
import {
  formatArrayAsText,
  gen,
  swimminglyApi,
  useScreenSize,
  ISwimmerWithNumericGender,
  IUser,
  ISwimmerWithGuardian,
  ISwimmerClubSeason,
  IClubSeason,
  IClubSeasonInfo,
  GenderFormatter,
  DateFormats,
} from "./utils"
import DashboardLayout from "./DashboardLayout"
import ContentPanel from "./ContentPanel"
import { screenSizes } from "../styles/GlobalStyles"
import GenericModal from "./GenericModal"
import { ConfirmClubModal } from "./ConfirmClubModal"
import { SeasonHelper } from "./ClubSeasonManagement/Season"
import { RegistrationForm } from "./RegistrationForm"
import { IRegistrationClubDue, IWaiver } from "./ClubSeasonManagement/sharedState"
import { GuardianWaiverResponse, IRegistrationFormInfoResult, RegistrationHelper } from "./ClubSeasonManagement/RegistrationConfiguration/RegistrationHelper"
import { GuardianWaiverMonitor, GuardianWaiversListener } from "./Helpers/GuardianWaiverMonitor"
import { validateEmail } from "./utils"

interface IClubSwimmerRegistrationProps {
  token?: string | null
}

const StyledRegistration = styled.div`
  width: 500px;
  max-width: 95%;
  min-width: 250px;
  margin: auto;
  text-align: center;
  color: var(--darkgrey);

  h1 {
    text-align: left;
    border-bottom: 1px solid var(--darkgrey);
    padding-bottom: 5px;
  }

  .upcoming-seasons h3 {
    color: var(--black);
    margin-bottom: 0;
    font-size: 1.75rem;
  }

  .upcoming-seasons .upcoming-seasons-title {
    background: var(--mediumgrey);
    color: var(--lightgrey);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .upcoming-seasons .upcoming-seasons-list {
    padding-top: 10px;
    border: 1px solid var(--mediumgrey);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .merge-swimmer-footnote {
    max-width: 400px;
    line-height: 0.2;
    margin: auto;
    margin-top: 15px;
  }

  @media (max-width: ${screenSizes.small}px) {
    width: 95%;

    .radio-options {
      margin-bottom: 8px;
    }

    .merge-swimmer-footnote {
      line-height: 1;
    }

    .merge-swimmer-footnote button {
      height: 15px;
    }
  }
`

const MergeSwimmerForm = styled.div`
  input,
  select,
  option,
  .ant-picker {
    height: 35px;
    border-radius: 5px;
    border: 1px solid var(--darkgrey);
  }

  .ant-picker input {
    border: none;
    color: var(--darkgrey);
  }

  select {
    padding: 0;
  }

  p .names {
    color: orange;
  }

  .form-grid-name {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 5px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .form-grid-row-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    row-gap: 5px;
  }
`

const AddSwimmerForm = styled.form`
  input,
  select,
  option,
  .ant-picker {
    height: 35px;
    border-radius: 5px;
    border: 1px solid var(--darkgrey);
  }

  .ant-picker input {
    border: none;
    color: var(--darkgrey);
  }

  select {
    padding: 0;
  }

  .form-grid-name {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 5px;
    margin-bottom: 5px;
  }

  .form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    row-gap: 5px;
  }

  .age-0 {
    background-color: #ffb5b0;
    border: 2px solid #f50;
  }
  .age-0 input {
    color: #8c0c00;
  }

  span.age-0 {
    background-color: rgba(0, 0, 0, 0.05);
    border: none;
  }

  span.ok {
    display: none;
  }
`

const MySwimmersTable = styled.table`
  /* border-collapse: separate; */
  /* width: 80%; */
  margin: auto;

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr {
    -webkit-transition: background-color 1s ease;
    -moz-transition: background-color 1s ease;
    -o-transition: background-color 1s ease;
    transition: background-color 1s ease;

    -webkit-transition: color 1s ease;
    -moz-transition: color 1s ease;
    -o-transition: color 1s ease;
    transition: color 1s ease;
  }

  tr:nth-child(even) {
    background-color: var(--tableblue);
  }
  tr:nth-child(odd) {
    background-color: var(--white);
  }
  tr.newly-registered {
    background-color: var(--approvalgreen);
    color: var(--white);
  }
`

const MobileSwimmerText = styled.div`
text-align: left;
  -webkit-transition: background-color 1s ease;
  -moz-transition: background-color 1s ease;
  -o-transition: background-color 1s ease;
  transition: background-color 1s ease;

  -webkit-transition: color 1s ease;
  -moz-transition: color 1s ease;
  -o-transition: color 1s ease;
  transition: color 1s ease;

  &.newly-registered {
    background-color: var(--approvalgreen);
    color: var(--white);
  }
`
const waiverMonitor = new GuardianWaiverMonitor()
//keith says: keep the state of the waiver responses outside of the react component which re-renders more often than i expect
const allWaiverResponses: Record<string, GuardianWaiverResponse> = {}
export default function ClubSwimmerRegistration({
  token,
}: IClubSwimmerRegistrationProps): JSX.Element {
  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)
  const [clubId, setClubId] = useState<number | undefined>()
  const [clubName, setClubName] = useState("")
  const [seasons, setSeasons] = useState<IClubSeason[]>([])
  const [seasonsNotYetOpen, setSeasonsNotYetOpen] = useState<IClubSeason[]>([])
  const [usersSwimmers, setUsersSwimmers] = useState<ISwimmerWithGuardian[]>([])
  const [selectedSeason, setSelectedSeason] = useState<number | null>(null)
  const [selectedClubSeasonInfo, setSelectedClubSeasonInfo] = useState<IClubSeasonInfo | undefined>(undefined)
  const [pricePoints, setPricePricePoints] = useState<IRegistrationClubDue[]>([])
  const [waivers, setWaivers] = useState<IWaiver[]>([])
  const [waiverResponses, setWaiverResponses] = useState<Record<string, GuardianWaiverResponse>>(allWaiverResponses)
  const [waiversCompleted, setWaiversCompleted] = useState<boolean>(false)
  const [selectedSwimmers, setSelectedSwimmers] = useState<number[]>([])
  const [mergeSwimmersVisible, setMergeSwimmersVisible] = useState(false)
  const [swimmersToMerge, setSwimmersToMerge] = useState<number[]>([])
  const [mergeFirstName, setMergeFirstName] = useState("")
  const [mergeMiddleInitial, setMergeMiddleInitial] = useState("")
  const [mergeLastName, setMergeLastName] = useState("")
  const [mergeGender, setMergeGender] = useState<"Boy" | "Girl" | "">("")
  const [mergeBirthdate, setMergeBirthdate] = useState<Moment | null>(null)
  const [mergeElligible, setMergeElligible] = useState(false)
  const [confirmMerge, setConfirmMerge] = useState(false)
  const [addSwimmerModalVisible, setAddSwimmerModalVisible] = useState(false)
  const [addSwimmerFirstName, setAddSwimmerFirstName] = useState("")
  const [addSwimmerMiddleInitial, setAddSwimmerMiddleInitial] = useState("")
  const [addSwimmerLastName, setAddSwimmerLastName] = useState("")
  const [addSwimmerGender, setAddSwimmerGender] =
    useState<"Boy" | "Girl" | "">("")
  const [addSwimmerBirthdate, setAddSwimmerBirthdate] =
    useState<Moment | null>(null)
  const [addElligible, setAddElligible] = useState(false)
  const [triggerUsersSwimmerRefresh, setTriggerUsersSwimmerRefresh] =
    useState(0)
  const [newestRegisteredSwimmer, setNewestRegisteredSwimmer] =
    useState<number | null>(null)
  const [newlyRegisteredSwimmers, setNewlyRegisteredSwimmers] = useState<
    number[]
  >([])
  const [confirmSignUpModalVisible, setConfirmSignUpModalVisible] =
    useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingClubInformation, setLoadingClubInformation] = useState(false)
    const [clubConfirmationModalVisible, setClubConfirmationModalVisible] =
    useState(false)
  const [seasonSelectionForModal, setSeasonSelectionForModal] = useState(-1)

  const isMobile = ["small", "medium"].includes(useScreenSize())
  const history = useHistory()
  const [addGuardianModalVisible, setAddGuardianModalVisible] = useState(false)
  const [newGuardianEmail, setNewGuardianEmail] = useState("")
  const [modalContent, setModalContent] = useState<ISwimmerWithGuardian | null>()

  useEffect(() => {
    setSwimmersToMerge([])
    setMergeFirstName("")
    setMergeMiddleInitial("")
    setMergeLastName("")
    setMergeGender("")
    setMergeBirthdate(null)
    setConfirmMerge(false)
  }, [mergeSwimmersVisible])

  useEffect(() => {
    setAddSwimmerFirstName("")
    setAddSwimmerMiddleInitial("")
    setAddSwimmerLastName("")
    setAddSwimmerGender("")
    setAddSwimmerBirthdate(null)
  }, [addSwimmerModalVisible])

  useEffect(() => {
    if (
      swimmersToMerge.length > 1 &&
      mergeFirstName.trim() !== "" &&
      mergeLastName.trim() !== "" &&
      mergeGender !== "" &&
      mergeBirthdate !== null
    ) {
      setMergeElligible(true)
    } else {
      setMergeElligible(false)
    }
  }, [
    swimmersToMerge,
    mergeFirstName,
    mergeLastName,
    mergeGender,
    mergeBirthdate,
  ])

  useEffect(() => {
    if (
      addSwimmerFirstName.trim() !== "" &&
      addSwimmerLastName.trim() !== "" &&
      addSwimmerGender !== "" &&
      addSwimmerBirthdate !== null
    ) {
      setAddElligible(true)
    } else {
      setAddElligible(false)
    }
  }, [
    addSwimmerFirstName,
    addSwimmerLastName,
    addSwimmerGender,
    addSwimmerBirthdate,
  ])

  useEffect(() => {
    let mounted = true
    if (token && token.trim() !== "") {
      setLoadingClubInformation(true)
      swimminglyApi.get(gen(`/api/getClubInformationFromToken/${token}`)).then(
        ({
          status,
          club,
        }: {
          status: string
          club?: {
            clubId: number
            name: string
            nameLong: string
            seasons: IClubSeason[]
          }
        }) => {
          setLoadingClubInformation(false)
          if (club) {
            if (mounted) {
              const validSeasons = club.seasons.filter((clubSeason) => {
                if (
                  clubSeason.clubPays &&
                  clubSeason.clubPays > 0 &&
                  !clubSeason.defaultPaymentMethodId
                ) {
                  return false
                }
                if (
                  clubSeason.isRegistrationEnabled !== 1
                ) {
                  return false
                }
                if (
                  !clubSeason.signUpsOpenDate ||
                  clubSeason.signUpsOpenDate > moment().format("YYYY-MM-DD")
                ) {
                  return false
                }
                return true
              })
              const invalidSeasons = club.seasons.filter(
                (clubSeason) =>
                  !validSeasons
                    .map((s) => s.seasonId)
                    .includes(clubSeason.seasonId),
              )
              setClubName(club.nameLong.trim())
              setSeasons(validSeasons)
              setSeasonsNotYetOpen(invalidSeasons)
              setClubId(club.clubId)
            }
          }
        },
      )
    }
    return () => {
      mounted = false
    }
  }, [token])

  useEffect(() => {
    let mounted = true
    if (user?.userId) {
      swimminglyApi
        .get(gen(`/api/getAllSwimmersOfGuardian/${user.userId}`))
        .then(
          ({
            status,
            swimmers,
          }: {
            status: string
            swimmers: {
              swimmer: ISwimmerWithNumericGender
              swimmerShoulderNumber: number
              thisGuardianStatus: "confirmed" | "requested"
              guardians: {
                user: IUser
                guardianStatus: "confirmed" | "requested"
              }[]
              clubSeasons: ISwimmerClubSeason[]
            }[]
          }) => {
            if (status === "success" && swimmers) {
              if (selectedSwimmers.length > 0) {
                // compare selectedSwimmers with newSelectedSwimmers
                const newSelectedSwimmers = selectedSwimmers
                  .filter((currentlySelectedSwimmer) =>
                    swimmers
                      .map((newSwimmer) => newSwimmer.swimmer.swimmerId)
                      .includes(currentlySelectedSwimmer),
                  )
                  .sort((a, b) => (a > b ? 1 : -1))
                let shouldUpdate = false
                if (newSelectedSwimmers.length === selectedSwimmers.length) {
                  for (let i = 0; i < selectedSwimmers.length; i++) {
                    if (newSelectedSwimmers[i] !== selectedSwimmers[i])
                      shouldUpdate = true
                  }
                } else {
                  shouldUpdate = true
                }
                if (mounted && shouldUpdate)
                  setSelectedSwimmers(newSelectedSwimmers)
              }
              const newUsersSwimmers: ISwimmerWithGuardian[] = []
              for (let i = 0; i < swimmers.length; i++) {
                newUsersSwimmers.push({
                  swimmer: swimmers[i].swimmer,
                  guardians: swimmers[i].guardians,
                  thisGuardianStatus: swimmers[i].thisGuardianStatus,
                  swimmerShoulderNumber: swimmers[i].swimmerShoulderNumber,
                  clubSeasons: swimmers[i].clubSeasons,
                })
              }
              if (mounted) {
                setUsersSwimmers(newUsersSwimmers)
              }
            } else {
              message.error("Something went wrong retrieving swimmers")
            }
          },
        )
    }
    return () => {
      mounted = false
    }
  }, [user?.userId, selectedSwimmers, triggerUsersSwimmerRefresh])

  // useEffect(() => {
  //   let mounted = true
  //   if (newlyRegisteredSwimmers.length > 0) {
  //     const newestSwimmer = newlyRegisteredSwimmers[newlyRegisteredSwimmers.length-1]
  //     setTimeout(() => {
  //       if (mounted) setNewlyRegisteredSwimmers(newlyRegisteredSwimmers.filter(s => s !== newestSwimmer))
  //     },1500)
  //   }
  //   return () => { mounted = false }
  // }, [newlyRegisteredSwimmers])

  useEffect(() => {
    let mounted = true
    if (mounted && newestRegisteredSwimmer !== null)
      setNewlyRegisteredSwimmers([
        ...newlyRegisteredSwimmers,
        newestRegisteredSwimmer,
      ])
    setNewestRegisteredSwimmer(null)
    return () => {
      mounted = false
    }
  }, [newestRegisteredSwimmer, newlyRegisteredSwimmers])


  const registrationHelper = new RegistrationHelper()
  const seasonHelper = new SeasonHelper()

  /**
   * Safely updates the state of waiverResponses as well as allWaiverResponses
   * @param guardianWaiverResponse 
   */
  const updateGuardianWaiverResponses = (guardianWaiverResponse: GuardianWaiverResponse[]) => {
    const waiverResponsesCopy = Object.assign({}, waiverResponses)
    guardianWaiverResponse.forEach((guardianWaiverResponse) => {
      waiverResponsesCopy[guardianWaiverResponse.waiverSectionId] = guardianWaiverResponse
      allWaiverResponses[guardianWaiverResponse.waiverSectionId] = guardianWaiverResponse
    })

    setWaiverResponses(waiverResponsesCopy)
  }

  //Club Registration data
  useEffect(() => {
    if (clubId !== undefined && selectedSeason !== undefined) {
      seasonHelper.getClubSeason(clubId, seasonSelectionForModal)
        .then(async (clubSeasonInfo: IClubSeasonInfo) => {
          setSelectedClubSeasonInfo(clubSeasonInfo)

          if (!clubSeasonInfo.usingRegistration) {
            return Promise.resolve(undefined)
          } else {
            //we only get the registration form information if this is a registration club
            return registrationHelper.getRegistrationFormInfo(clubId, seasonSelectionForModal, user?.userId)
              .then((registrationFormInfoResult: IRegistrationFormInfoResult) => {
                setPricePricePoints(registrationFormInfoResult.registrationForm.pricePoints)
                setWaivers(registrationFormInfoResult.registrationForm.waivers)
                updateGuardianWaiverResponses(Object.values(registrationFormInfoResult.guardianWaiverResponsesByWaiverSectionId))

                const waiverResponsesValues = Object.values(registrationFormInfoResult.guardianWaiverResponsesByWaiverSectionId)
                const registrationFormId = registrationFormInfoResult.registrationForm.registrationInfo.registrationFormId

                const waiverMonitorListener: GuardianWaiversListener = ((waiverSectionId: number | undefined, guardianAcknowledged: boolean | undefined, freeformResponse: string | undefined, isCompleted: boolean) => {

                  if (waiverSectionId !== undefined && user !== null) {
                    registrationHelper.setGuardianWaiverResponse(user.userId, registrationFormId, waiverSectionId, guardianAcknowledged, freeformResponse)
                    const guardianWaiverResponse = { waiverSectionId: waiverSectionId.toString(), guardianAcknowledged: guardianAcknowledged, freeformResponse: freeformResponse }
                    updateGuardianWaiverResponses([guardianWaiverResponse])
                  } else {
                    //enable the registration button
                    setWaiversCompleted(isCompleted)
                  }
                });

                waiverMonitor.init(registrationFormInfoResult.registrationForm.waivers, waiverResponsesValues, [waiverMonitorListener])
              })
              .catch((err) => {
                console.log(err)
                message.error("No season available")
              })

          }
        })
        .catch((err) => {
          console.log(err)
          message.error("No season available")
        })
    }

  }, [selectedSeason])

  useEffect(() => {
    if (selectedClubSeasonInfo?.usingRegistration) {
      setWaiversCompleted(waiverMonitor.isCompleted)
    } else {
      setWaiversCompleted(true)
    }
  }, [selectedSwimmers])

  /**********************
   * no club && no user *
   **********************/
  if ((!clubName || clubName.trim() === "") && !user) {
    return (
      <DashboardLayout user={user}>
        <header className="page-header">
          <h2>Sign Up Swimmers</h2>
        </header>
        <div>
          <p>
            Uh oh, we couldn't find the club you're looking for. Are you sure
            you navigated to the right link?
          </p>
          <p>
            If you're trying to add your children to the roster for a swim
            season please contact the administrator from the club you're trying
            to sign up for to make sure you've gone to the right link.
          </p>
        </div>
      </DashboardLayout>
    )
  }

  /*******************
   * no club && user *
   *******************/
  if ((!clubName || clubName.trim() === "") && user) {
    return (
      <DashboardLayout user={user}>
        <header className="page-header">
          <h2>Sign Up Swimmers</h2>
        </header>
        <div>
          {loadingClubInformation 
            ? (<LoadingOutlined hidden={false} spin={true} />)
            : (
              <ContentPanel title={"No Club Found"}>
                <p>
                  Uh oh, we couldn't find the club you're looking for. Are you sure
                  you navigated to the right link?
                </p>
                <p>
                  If you're trying to add your children to the roster for a swim
                  season please contact the administrator from the club you're
                  trying to sign up for to make sure you've gone to the right link.
                </p>
              </ContentPanel>
              ) }
        </div>
      </DashboardLayout>
    )
  }

  /*******************
   * club && no user *
   *******************/
  if (clubName && clubName.trim() !== "" && !user) {
    return (
      <DashboardLayout user={user}>
        <header className="page-header">
          <h2>Registration: {clubName}</h2>
        </header>
        <div>
          <ContentPanel>
            <p>
              Hi there! It looks like you're not signed in to the Swimmingly
              Clubhouse. You'll need to{" "}
              <Link to={{
                pathname: '/registerUser',
                state: { clubToken: token }
              }}>create an account</Link> or{" "}
              <Link to={{
                pathname: '/login',
                state: { token }
              }}>sign in</Link> to add your children to a season
              for {clubName}.
            </p>
          </ContentPanel>
        </div>
      </DashboardLayout>
    )
  }
  const validSeasonIds = new Set(seasons.map((season) => {
    return season.seasonId
  }))

  return (
    <DashboardLayout user={user}>
      <header className="page-header">
        <h2>Registration: {clubName}</h2>
      </header>
      <div>
        <ContentPanel>
          <StyledRegistration>
          <p>
              Register new/returning swimmers for upcoming season for<br />{" "}
              <b
                style={{
                  backgroundColor: "green",
                  color: "white",
                  padding: "2px",
                }}
              >
                {clubName}
              </b>
            </p>
            <div className="upcoming-seasons">
              <div className="upcoming-seasons-title">
                <h3>Upcoming Seasons</h3>
              </div>
              <div className="upcoming-seasons-list">
                {
                  // destruct the arrays to create one array of seasons and seasonsNotYetOpen
                  [...seasons, ...seasonsNotYetOpen].map((theSeason, index) => {
                    const isValidSeason = validSeasonIds.has(theSeason.seasonId)
                    return (
                      <div
                        className="radio-options"
                        key={`season_fragment_${theSeason.seasonId}_${index}`}
                      >
                        <input
                          type="radio"
                          id={`season_option_${index}`}
                          name="season"
                          // disabled if seasonNotYetOpen
                          disabled={!isValidSeason}
                          checked={selectedSeason === theSeason.seasonId}
                          value={theSeason.seasonId}
                          key={`${theSeason.seasonId}`}
                          onChange={(e) => {
                            setSeasonSelectionForModal(theSeason.seasonId)
                            setClubConfirmationModalVisible(true)
                          }}
                        />
                        <label
                          style={{ marginLeft: "5px" }}
                          htmlFor={`season_option_${index}`}
                        >
                          {theSeason.seasonName}
                        </label>
                        <br />
                      </div>
                    )
                  })
                }
              </div>
            </div>
            {selectedSeason && usersSwimmers.length === 0 && (
              <p style={{ marginTop: "20px" }}>
                You don't have any swimmers in the
                Swimmingly Clubhouse! Add your swimmers now to register them for any season for {clubName}!
              </p>
            )}
            {selectedSeason && usersSwimmers.length > 0 && (
              <>
                <p style={{ marginTop: "20px" }}>
                  Select swimmers for season registration:{" "}<br />
                  <b
                    style={{
                      background: "var(--primaryblue)",
                      color: "var(--white)",
                      padding: "2px",
                      fontSize: "10px",
                    }}
                  >
                    Then click 'Proceed to Register' at the bottom of the screen.
                  </b>
                </p>
                
                  <MySwimmersTable>
                    <thead>
                      <tr>
                        <Tooltip title="Season registration status of swimmer">
                          <th>Status</th>
                        </Tooltip>
                        <th>Name</th>
                        <th>Sex</th>
                        <th>Birthdate</th>
                     
                       
                      </tr>
                    </thead>
                    <tbody>
                      {usersSwimmers.map((theSwimmer, index) => {
                        const thisSeason = theSwimmer.clubSeasons?.find(
                          (cS) => cS.seasonId === selectedSeason,
                        )
                        return (
                          <tr
                            key={`swimmer_${theSwimmer.swimmer.swimmerId}_row_${index}`}
                            className={
                              newlyRegisteredSwimmers.includes(
                                theSwimmer.swimmer.swimmerId,
                              )
                                ? "newly-registered"
                                : "normal"
                            }
                          >
                            <td>
                              
                              <input
                                type="checkbox"
                                checked={selectedSwimmers.includes(
                                  theSwimmer.swimmer.swimmerId,
                                )}
                                disabled={thisSeason?.registered === 1 || false}
                                onChange={() => null}
                                onClick={() => {
                                  setModalContent(theSwimmer)
                                  setAddGuardianModalVisible(true)
                                  setNewGuardianEmail("")
                                  if (
                                    selectedSwimmers.includes(
                                      theSwimmer.swimmer.swimmerId,
                                    )
                                  ) {
                                    setSelectedSwimmers(
                                      selectedSwimmers.filter(
                                        (s) =>
                                          s !== theSwimmer.swimmer.swimmerId,
                                      ),
                                    )
                                  } else {
                                    setSelectedSwimmers([
                                      ...selectedSwimmers,
                                      theSwimmer.swimmer.swimmerId,
                                    ])
                                  }
                                }}
                              />
                            {" "}
                            {thisSeason?.registered ? (
                                <Tooltip title="Registered">
                                  <CheckCircleTwoTone
                                    style={{ fontSize: "1.5rem" }}
                                    twoToneColor="#52c41a"
                                  />
                                </Tooltip>
                              ) : (
                                <Tooltip title="Not Registered">
                                  <CloseCircleOutlined
                                    style={{ fontSize: "1.5rem" }}
                                  />
                                </Tooltip>
                              )}
                          

                            </td>
                            <td>{`${theSwimmer.swimmer.firstName} ${theSwimmer.swimmer.lastName}`}</td>
                            <td>
                              <Tooltip title="Sex">
                              {GenderFormatter.format(`${theSwimmer.swimmer.gender}`)}
                              </Tooltip>
                            </td>
                            <td>
                              <Tooltip title="Date of Birth">
                                {moment(theSwimmer.swimmer.dateOfBirth).format(
                                  DateFormats.monthDayYear,
                                )}
                              </Tooltip>
                            </td>
                          </tr>

                        )

                      })}
                    </tbody>
                  </MySwimmersTable>
  


                <Modal
                  title={`Invite User as a Guardian for ${modalContent?.swimmer.firstName} ${modalContent?.swimmer.lastName}`}
                  visible={addGuardianModalVisible}
                  okText="Add Guardian"
                  cancelText="No More Guardians"
                  onOk={() => {
                    const guardianEmail = newGuardianEmail.trim().toLowerCase()
                    if (
                      modalContent?.swimmer.swimmerId &&
                      validateEmail(newGuardianEmail.trim().toLowerCase())
                    ) {
                      registrationHelper.addGuardianToSwimmer(
                        modalContent?.swimmer.swimmerId,
                        user?.userId,
                        guardianEmail,
                      )
                      setAddGuardianModalVisible(false)
                      setModalContent(null)
                      setNewGuardianEmail("")
                    } else {
                      message.warn("Please check the email you are trying to add...", 4)
                    }
                  }}
                  onCancel={() => {
                    setAddGuardianModalVisible(false)
                    setModalContent(null)
                    setNewGuardianEmail("")
                  }}
                >
                  <p>
                    Enter email of any guardian you would like to tag to this
                    swimmer. 
                    <br /><br />
                    If guardian does not
                    have an account, they will receive an email invite to create their account.
                  </p>
                  <input
                    value={newGuardianEmail}
                    onChange={(e) =>
                      setNewGuardianEmail(e.target.value.trim().toLowerCase())
                    }
                    placeholder="email..."
                  />
                </Modal>






                {usersSwimmers.length > 1 ? (

                  <p className="merge-swimmer-footnote">
                    Duplicate swimmers listed above?{" "}
                    <Button
                      style={{ padding: 0 }}
                      type="link"
                      onClick={() => setMergeSwimmersVisible(true)}
                    >
                      Merge them
                    </Button>
                    .
                  </p>
                ) : null}
              </>
            )}
            <Divider />
            {selectedSeason && (
              <Fragment>
                <h5
                  style={{ marginTop: "0px" }}
                  onClick={() => setAddSwimmerModalVisible(true)}
                >
                  Need to Add a New Swimmer?
                </h5>
                <PlusSquareTwoTone
                  style={{ fontSize: "2.5rem" }}
                  twoToneColor="#52c41a"
                  onClick={() => setAddSwimmerModalVisible(true)}
                />
                <br />
              </Fragment>
            )}
            <Divider />

            {selectedSeason && selectedClubSeasonInfo?.usingRegistration && (
              <Fragment>
                <h4
                  style={{ marginTop: "25px" }}
                >
                  You are about to register your child{`${selectedSwimmers.length > 1 ? "(ren) " : " "}`} for {clubName} {selectedClubSeasonInfo.seasonName}.
                </h4>
                <RegistrationForm
                  previewMode={false}
                  selectedClubSeason={selectedClubSeasonInfo}
                  pricePoints={pricePoints}
                  waivers={waivers}
                  waiverResponses={waiverResponses}
                  waiverMonitor={waiverMonitor}
                  children={undefined}
                // tbd waiver responses
                />
                <br />
              </Fragment>
            )}
            {selectedSeason && (
              <Button
                type="primary"
                disabled={selectedSwimmers.length === 0 || !waiversCompleted}
                style={{ marginTop: "35px", marginBottom: "20px" }}
                onClick={() => setConfirmSignUpModalVisible(true)}
              >
                Proceed to Register
              </Button>
            )}
            <hr />
            {/* <RegistrationPreview /> */}
            <GenericModal
              visible={addSwimmerModalVisible}
              setVisible={setAddSwimmerModalVisible}
              title="Add a Swimmer"
              footer={() => (
                <>
                  <Button
                    onClick={() => {
                      setAddSwimmerFirstName("")
                      setAddSwimmerLastName("")
                      setAddSwimmerGender("")
                      setAddSwimmerBirthdate(null)
                      setAddSwimmerModalVisible(false)
                      setTriggerUsersSwimmerRefresh(
                        triggerUsersSwimmerRefresh + 1,
                      )
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    disabled={!addElligible}
                    onClick={() => {
                      swimminglyApi
                        .post(gen("/api/createNewSwimmer"))
                        .body({
                          firstName: addSwimmerFirstName,
                          middleInitial: addSwimmerMiddleInitial,
                          lastName: addSwimmerLastName,
                          gender: addSwimmerGender,
                          birthday: addSwimmerBirthdate?.format("YYYY-MM-DD"),
                          createdBy: user?.userId,
                        })
                        .then((response) => {
                          if (response.status === "error") {
                            console.log(response)
                            message.error("Uh oh, something went wrong...")
                          } else if (response.status === "success") {
                            message.success("Success!")
                          }
                        })
                        .catch((err) => {
                          console.log(err)
                          message.error("Uh oh, something went wrong...")
                        })
                        .finally(() => {
                          setAddSwimmerFirstName("")
                          setAddSwimmerLastName("")
                          setAddSwimmerGender("")
                          setAddSwimmerBirthdate(null)
                          setAddSwimmerModalVisible(false)
                          setTriggerUsersSwimmerRefresh(
                            triggerUsersSwimmerRefresh + 1,
                          )
                        })
                    }}
                  >
                    Submit
                  </Button>
                </>
              )}
            >
              <p>
                Please enter your swimmer's name, gender, and birthday below.
              </p>
              <AddSwimmerForm>
                <div className="form-grid-name">
                  <input
                    placeholder="First name..."
                    value={addSwimmerFirstName}
                    onChange={(e) => setAddSwimmerFirstName(e.target.value)}
                  />
                  <input
                    placeholder="Middle Initial..."
                    value={addSwimmerMiddleInitial}
                    onKeyDown={(e) => {
                      if (
                        ![
                          "Shift",
                          "Backspace",
                          "Delete",
                          "Meta",
                          "Tab",
                          "Enter",
                        ].includes(e.key) &&
                        !/[a-zA-Z]/.test(e.key)
                      ) {
                        e.preventDefault()
                      }
                    }}
                    onChange={(e) => {
                      const newValue = e.target.value
                      if (newValue.length < 2)
                        setAddSwimmerMiddleInitial(e.target.value.trim())
                    }}
                  />
                  <input
                    placeholder="Last name..."
                    value={addSwimmerLastName}
                    onChange={(e) => setAddSwimmerLastName(e.target.value)}
                  />
                </div>
                <div className="form-grid">
                  <select
                    value={addSwimmerGender}
                    onChange={(e) =>
                      setAddSwimmerGender(e.target.value as "Boy" | "Girl")
                    }
                  >
                    <option value="" disabled>
                      Boy/Girl...
                    </option>
                    <option value="Boy">Boy</option>
                    <option value="Girl">Girl</option>
                  </select>
                  <DatePicker
                    className={
                      addSwimmerBirthdate === null
                        ? "ok"
                        : moment().diff(addSwimmerBirthdate, "years") > 0
                          ? "ok"
                          : "age-0"
                    }
                    value={addSwimmerBirthdate}
                    showToday={false}
                    placeholder="(YYYY-MM-DD)"
                    onChange={setAddSwimmerBirthdate}
                  />
                  <div />
                  <span
                    className={
                      addSwimmerBirthdate === null
                        ? "ok"
                        : moment().diff(addSwimmerBirthdate, "years") > 0
                          ? "ok"
                          : "age-0"
                    }
                  >
                    Your swimmer's birthdate is less than a year ago.
                  </span>
                </div>
              </AddSwimmerForm>
            </GenericModal>
          </StyledRegistration>
        </ContentPanel>
        <GenericModal
          title="Merge Duplicate Swimmer Records"
          visible={mergeSwimmersVisible}
          setVisible={setMergeSwimmersVisible}
          footer={() => (
            <>
              <Button
                key="close"
                onClick={() => {
                  setMergeSwimmersVisible(false)
                }}
              >
                Close
              </Button>
              <Button
                key="Ok"
                disabled={!mergeElligible}
                type="primary"
                onClick={() => {
                  if (confirmMerge) {
                    swimminglyApi
                      .post(gen("/api/mergeMultipleSwimmersIntoOne"))
                      .body({
                        swimmerIds: swimmersToMerge,
                        firstName: mergeFirstName,
                        middleInitial: mergeMiddleInitial,
                        lastName: mergeLastName,
                        gender: mergeGender,
                        dateOfBirth: mergeBirthdate?.format("YYYY-MM-DD"),
                        userId: user?.userId,
                      })
                      .then((results) => {
                        if (results?.status === "success") {
                          message.success("Success!")
                          setTriggerUsersSwimmerRefresh(
                            triggerUsersSwimmerRefresh + 1,
                          )
                        } else {
                          message.error("Uh oh, something went wrong...")
                        }
                      })
                      .catch((err) => {
                        console.log(err)
                        message.error("Uh oh, something went wrong..")
                        setTriggerUsersSwimmerRefresh(
                          triggerUsersSwimmerRefresh + 1,
                        )
                      })
                    setMergeSwimmersVisible(false)
                  } else if (mergeElligible) setConfirmMerge(true)
                  else if (swimmersToMerge.length <= 1)
                    message.warn(
                      "You must select at least two swimmers to merge",
                    )
                  else
                    message.warn(
                      "Please let us know the correct information for your swimmer",
                    )
                }}
              >
                {confirmMerge ? "Confirm" : "Ok"}
              </Button>
            </>
          )}
        >
          {confirmMerge ? (
            <React.Fragment>
              <p>
                Are you sure you want to merge{" "}
                <span style={{ color: "orange" }}>
                  {swimmersToMerge
                    .map((sTM) => {
                      const firstName =
                        usersSwimmers.find((uS) => uS.swimmer.swimmerId === sTM)
                          ?.swimmer.firstName || ""
                      const lastName =
                        usersSwimmers.find((uS) => uS.swimmer.swimmerId === sTM)
                          ?.swimmer.lastName || ""
                      return (firstName + " " + lastName).trim()
                    })
                    .join(", ")}
                </span>{" "}
                into one swimmer with the following information:
              </p>
              <p>
                {mergeFirstName} {mergeLastName} - {mergeGender}{" "}
                {mergeBirthdate?.format("YYYY-MM-DD")}
              </p>
              <p>
                <b>This CANNOT be undone.</b> You are merging{" "}
                {swimmersToMerge.length} swimmers into <b>1 athlete</b>.
              </p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <p>
                Please check all of the swimmers below who should be combined
                into one athlete.
              </p>
              <p>
                <b
                  style={{
                    background: "var(--linkblue)",
                    color: "var(--white)",
                  }}
                >
                  IMPORTANT NOTE:{" "}
                </b>{" "}
                If you have multiple duplicate swimmers,
                <span
                  style={{
                    background: "var(--linkblue)",
                    color: "var(--white)",
                  }}
                >
                  only de-duplicate one of them
                </span>{" "}
                now. You can repeat this process for your other swimmers, but if
                you accidentally merge John Smith and Jane Doe's records{" "}
                <span
                  style={{
                    background: "var(--linkblue)",
                    color: "var(--white)",
                  }}
                >
                  we <b>CANNOT</b> undo the swimmer merge
                </span>{" "}
                and retrieve your original children's records.
              </p>
              {usersSwimmers.map((theSwimmer, index) => (
                <div key={`swimmer_${theSwimmer.swimmer.swimmerId}_merge_row_${index}`}>
                  <input
                    type="checkbox"
                    checked={swimmersToMerge.includes(
                      theSwimmer.swimmer.swimmerId,
                    )}
                    onChange={() => null}
                    onClick={() => {
                      if (
                        swimmersToMerge.includes(theSwimmer.swimmer.swimmerId)
                      ) {
                        setSwimmersToMerge(
                          swimmersToMerge.filter(
                            (s) => s !== theSwimmer.swimmer.swimmerId,
                          ),
                        )
                      } else {
                        setSwimmersToMerge([
                          ...swimmersToMerge,
                          theSwimmer.swimmer.swimmerId,
                        ])
                      }
                    }}
                  />
                  <span>
                  {" "}
                    {theSwimmer.swimmer.firstName} {theSwimmer.swimmer.lastName}{" "}
                    - {theSwimmer.swimmer.gender === 1 ? "Girl" : "Boy"}{" "}
                    {moment(theSwimmer.swimmer.dateOfBirth).format(
                      DateFormats.monthDayYear,
                    )}
                  </span>
                </div>
              ))}
              {swimmersToMerge.length > 0 ? (
                <MergeSwimmerForm>
                  <p style={{ marginTop: "20px" }}>
                    You have chosen to merge the following duplicate swimmers
                    into one swimmer:{" "}
                    <span className="names">
                      {swimmersToMerge
                        .map((sTM) => {
                          const firstName =
                            usersSwimmers.find(
                              (uS) => uS.swimmer.swimmerId === sTM,
                            )?.swimmer.firstName || ""
                          const lastName =
                            usersSwimmers.find(
                              (uS) => uS.swimmer.swimmerId === sTM,
                            )?.swimmer.lastName || ""
                          return (firstName + " " + lastName).trim()
                        })
                        .join(", ")}
                    </span>
                    .
                  </p>
                  <p style={{ marginBottom: "2px" }}>
                    Please verify the correct information to use for the
                    athlete:
                  </p>
                  <div className="form-grid-name">
                    <input
                      placeholder="First name..."
                      value={mergeFirstName}
                      onChange={(e) => setMergeFirstName(e.target.value)}
                    />
                    <input
                      placeholder="Middle Initial (optional)..."
                      value={mergeMiddleInitial}
                      onKeyDown={(e) => {
                        if (
                          ![
                            "Shift",
                            "Backspace",
                            "Delete",
                            "Meta",
                            "Tab",
                            "Enter",
                          ].includes(e.key) &&
                          !/[a-zA-Z]/.test(e.key)
                        ) {
                          e.preventDefault()
                        }
                      }}
                      onChange={(e) => {
                        const newValue = e.target.value
                        if (newValue.length < 2)
                          setMergeMiddleInitial(e.target.value.trim())
                      }}
                    />
                    <input
                      placeholder="Last name..."
                      value={mergeLastName}
                      onChange={(e) => setMergeLastName(e.target.value)}
                    />
                  </div>
                  <div className="form-grid-row-2">
                    <select
                      value={mergeGender}
                      onChange={(e) =>
                        setMergeGender(e.target.value as "Boy" | "Girl")
                      }
                    >
                      <option value="" disabled>
                        Choose sex...
                      </option>
                      <option value="Boy">Boy</option>
                      <option value="Girl">Girl</option>
                    </select>
                    <DatePicker
                      value={mergeBirthdate}
                      onChange={setMergeBirthdate}
                    />
                  </div>
                </MergeSwimmerForm>
              ) : null}
            </React.Fragment>
          )}
        </GenericModal>
        <GenericModal
          title="Proceed with Registration?"
          visible={confirmSignUpModalVisible}
          setVisible={setConfirmSignUpModalVisible}
          footer={() => (
            <Fragment>
              <Button
                key="close-confirm-sign-up"
                onClick={() => {
                  setConfirmSignUpModalVisible(false)
                }}
              >
                Close
              </Button>
              <Button
                key="confirm-swimmer-sign-up"
                type="primary"
                loading={loading}
                onClick={async () => {
                  setLoading(true)
                  setTriggerUsersSwimmerRefresh(triggerUsersSwimmerRefresh + 1)
                  setSelectedSwimmers([])
                  setConfirmSignUpModalVisible(false)
                  await new Promise((resolve, _reject) => {
                    setTimeout(resolve, 1500)
                  })
                  setLoading(false)
                  setNewestRegisteredSwimmer(null)
                  setNewlyRegisteredSwimmers([])
                  history.push("/app/cart",
                    {
                      clubId: clubId,
                      seasonId: selectedClubSeasonInfo?.seasonId,
                      swimmerIds: selectedSwimmers,
                      usingRegistration: selectedClubSeasonInfo?.usingRegistration,
                      selectedClubSeasonInfo: selectedClubSeasonInfo          
                    }
                  )
                }}
              >
                Proceed to Register
              </Button>
            </Fragment>
          )}
        >
          <p>
            Proceed to register the following swimmers for the club/season:
            <br />
            <b>
              {`${clubName} | ${selectedClubSeasonInfo?.seasonName ?? "season"}`}
            </b>
            <br /><br />
            {formatArrayAsText(
              selectedSwimmers.map((s) => {
                const theSwimmer = usersSwimmers.find(
                  (uS) => uS.swimmer.swimmerId === s,
                )
                if (theSwimmer) {
                  return `${theSwimmer.swimmer.firstName} ${theSwimmer.swimmer.lastName}`
                } else {
                  return ""
                }
              }),
            )}
          </p>
        </GenericModal>
        <ConfirmClubModal
          clubName={clubName}
          visible={clubConfirmationModalVisible}
          setVisible={setClubConfirmationModalVisible}
          onOk={() => {
            if (seasonSelectionForModal > -1) {
              setSelectedSeason(seasonSelectionForModal)
            } else {
              message.warn("Can't determine what season you're selecting")
            }
          }}
        />
      </div>
    </DashboardLayout>
  )
}


