import { gen, IClub, ResultStatus, swimminglyApi } from "../utils"

export type GuardianClubResult = {
    status: ResultStatus
    clubs: IClub[]
}

export class GuardianHelper {
    api = swimminglyApi

    public constructor(api = swimminglyApi) {
        this.api = api
    }

    public async getClubsForGuardian(userId: number): Promise<GuardianClubResult> {
        return this.api.get(gen(`/api/getClubsForGuardian/${userId}`))
    }

}  