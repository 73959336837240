import React, { useState, useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import { useStoreState, StateMapper } from "easy-peasy"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPowerOff,
  faCreditCard,
  faTrophy,
  faTable,
  faGlobe,
  faBullhorn,
  faUserFriends,
  faChartPie,
  faCalendarAlt,
  faSwimmer,
  faUsers,
  faHome,
  faStore,
  faPlaneDeparture,
  IconDefinition,
  faCalculator,
  faCalendarCheck,
  faLightbulb,
  faGraduationCap,
  faLifeRing,
} from "@fortawesome/free-solid-svg-icons"
import styled from "styled-components"
import { MenuText, HamburgerMenu } from "./SidebarComponents"
import { AppDataStore } from "../../appData/types"
import { gen, ISwimmer, IUser, swimminglyApi } from "../utils"
import { Tag, Tooltip } from "antd"
import { Colors } from "../ClubSeasonManagement/styles"

const Icon = ({
  icon,
  isClosed,
}: {
  icon: IconDefinition
  isClosed: boolean
}) => (
  <FontAwesomeIcon
    icon={icon}
    size="lg"
    style={{ marginRight: isClosed ? "0px" : "20px" }}
  />
)

const SidebarLeft = styled.aside`
  background-color: var(--primaryblue);
  width: ${({ isClosed }: { isClosed: boolean }) => (isClosed ? 73 : 240 )}px;
  min-width: ${({ isClosed }: { isClosed: boolean }) =>
    isClosed ? 73 : 240}px;
`

const SidebarHeader = styled.div`
  display: flex;
  flex-direction: ${({ isClosed }: { isClosed: boolean }) =>
    isClosed ? "column" : "row"};
  justify-content: ${({ isClosed }: { isClosed: boolean }) =>
    isClosed ? "space-around" : "space-between"};
`

const Submenu = styled.div`
  display: flex;
  padding-top: 0px;
  margin-top: 10px;
  flex-direction: column;

  a {
    padding-left: ${({ isClosed }: { isClosed: boolean }) =>
      isClosed ? 0 : 40}px;
    font-size: 1.4rem;
    color: var(--white);
    /* border: 1px solid orange; */
    padding-top: 5px;
    padding-bottom: 5px;
    align-self: ${({ isClosed }: { isClosed: boolean }) =>
      isClosed ? "center" : "auto"};
    justify-content: center;
    &:hover {
      background-color: #037994;
    }
    &:focus {
      background-color: #037994;
    }
    &.active {
      background-color: #037994;
    }
  }
`

const SpacedOutRow = styled.div`
  margin-top: 15px;
  margin-left: 25px;
  color: var(--white);

  a {
    color: var(--white);
  }
`

const WhiteText = styled.span`
  color: var(--white);
  padding-left: ${({ isMobile }: { isMobile: boolean }) =>
    isMobile ? 5 : 10}px;
`

const RestrictedLink = ({
  className,
  to,
  roles,
  children,
  allowInactive = false,
  setUserLockout,
}: {
  className: string
  to: string
  roles: Array<number>
  children: React.ReactNode
  allowInactive?: boolean
  setUserLockout: (val: boolean) => void 
}) => {
  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)
  if (!user || !roles.includes(user.role)) {
    return null
  }
  if (!allowInactive && user.isActive !== true) {
    return (
      <Link
        className={className}
        to={to}
        onClick={(e) => {
          e.preventDefault()
          setUserLockout(true)
        }}
      >
        {children}
      </Link>
    )
  }
  return (
    <Link className={className} to={to}>
      {children}
    </Link>
  )
}

interface ISidebarProps {
  isClosed: boolean
  changeClose: (val: boolean) => void
  isMobile: boolean
  setUserLockout: (value: boolean) => void
}

const getRole = (user: IUser | null) => {
  if (!user) return ""
  if (user.role === 2) return "Admin"
  if (user.role === 3) return "League Admin"
  if (user.role === 4) return "Club Admin"
  if (user.role === 5) return "Parent"
  return ""
}

export default function Sidebar({
  isClosed,
  changeClose,
  isMobile,
  setUserLockout,
}: ISidebarProps) {
  const location = useLocation()
  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)
  const impersonateClub = useStoreState(
    (state: StateMapper<AppDataStore>) => state.aliasedClub,
  )
  const triggerUsersSwimmersRefresh = useStoreState(
    (state: StateMapper<AppDataStore>) => state.triggerUsersSwimmersRefresh,
  )
  const [usersSwimmers, setUsersSwimmers] = useState<ISwimmer[]>([])

  useEffect(() => {
    if (user?.userId) {
      swimminglyApi
        .get(`/api/getAllSwimmersOfGuardian/${user.userId}`)
        .then((data) => {
          if (!data.swimmers) {
            setUsersSwimmers([])
          } else {
            setUsersSwimmers(data.swimmers)
          }
        })
    } else {
      setUsersSwimmers([])
    }
  }, [user?.userId, triggerUsersSwimmersRefresh])

  const logout = async (e: any) => {
    e.preventDefault()
    await swimminglyApi.get(gen("/api/logout"))
    window.location.reload()
  }

  const onClick = () => {
    changeClose(!isClosed)
  }

  let pathname = location.pathname

  if (isMobile && isClosed) {
    return null
  }

  return (
    <SidebarLeft isClosed={isClosed}>
      <SidebarHeader isClosed={isClosed}>
        <MenuText isClosed={isClosed}>
        </MenuText>
        <HamburgerMenu onClick={onClick} />
      </SidebarHeader>
      <Submenu isClosed={isClosed}>
        <RestrictedLink
          to="/app/parentMeets"
          className={pathname === "/app/parentMeets" ? "active" : "inactive"}
          roles={[5]}
          setUserLockout={setUserLockout}
        >
          <Icon icon={faCalendarAlt} isClosed={isClosed} />
          {!isClosed && <span> Meet Schedule</span>}
        </RestrictedLink>
        
      </Submenu>
      {impersonateClub && (
        <Submenu isClosed={isClosed}>
          <RestrictedLink
            className={pathname === "/app/club/admin" ? "active" : "inactive"}
            to="/app/club/admin"
            roles={[2, 3, 4]}
            setUserLockout={setUserLockout}
          >
            <Icon icon={faHome} isClosed={isClosed} />
            {!isClosed && <span>Manage Club</span>}
          </RestrictedLink>
          <RestrictedLink
            className={
              pathname === "/app/club/manageclubusers" ? "active" : "inactive"
            }
            to="/app/club/manageclubusers"
            roles={[2, 3, 4]}
            setUserLockout={setUserLockout}
          >
            <Icon icon={faUsers} isClosed={isClosed} />
            {!isClosed && <span>Club Admins</span>}
          </RestrictedLink>
          <RestrictedLink
            className={
              pathname === "/app/club/manageroster" ? "active" : "inactive"
            }
            to="/app/club/manageroster"
            roles={[2, 3, 4]}
            setUserLockout={setUserLockout}
          >
            <Icon icon={faSwimmer} isClosed={isClosed} />
            {!isClosed && <span>Manage Roster</span>}
          </RestrictedLink>
          {usersSwimmers.length > 0 ? (
            <RestrictedLink
              className={pathname === "/app/parentHome" ? "active" : "inactive"}
              to="/app/parentHome"
              roles={[5]}
              setUserLockout={setUserLockout}
            >
              <Icon icon={faSwimmer} isClosed={isClosed} />
              {!isClosed && <span>My Swimmers</span>}
            </RestrictedLink>
          ) : null}
          <RestrictedLink
            className={
              pathname === "/app/club/meetschedule2" ? "active" : "inactive"
            }
            to="/app/club/meetschedule2"
            roles={[2, 3, 4]}
            setUserLockout={setUserLockout}
          >
            <Icon icon={faCalendarAlt} isClosed={isClosed} />
            {!isClosed && <span>Meet Schedule</span>}
          </RestrictedLink>

          <RestrictedLink
            className={pathname === "/app/club/reports" ? "active" : "inactive"}
            to="/app/club/reports"
            roles={[2, 3, 4]}
            setUserLockout={setUserLockout}
          >
            <Icon icon={faChartPie} isClosed={isClosed} />
            {!isClosed && <span>Reports</span>}
          </RestrictedLink>
          
          <RestrictedLink
            className={pathname === "/app/billing" ? "active" : "inactive"}
            to="/app/billing"
            roles={[2, 3, 4, 5]}
            setUserLockout={setUserLockout}
          >
            <Icon icon={faCreditCard} isClosed={isClosed} />
            {!isClosed && <span>Payment History</span>}
          </RestrictedLink>
          {user?.clubId === impersonateClub?.clubId &&
            [3, 4].includes(user?.role || -1) && (
              <RestrictedLink
                className={
                  pathname === "/app/club/billing" ? "active" : "inactive"
                }
                to="/app/club/billing"
                roles={[3, 4]}
                setUserLockout={setUserLockout}
              >
                <Icon icon={faCreditCard} isClosed={isClosed} />
                {!isClosed && <span>Club Billing</span>}
              </RestrictedLink>
            )}
        </Submenu>
      )}
      {!isClosed &&
      user?.role === 5 &&
      user?.isActive &&
      usersSwimmers.length > 0 &&
      !impersonateClub ? (
        <Submenu isClosed={isClosed}>
          <RestrictedLink
            className={pathname === "/app/parentHome" ? "active" : "inactive"}
            to="/app/parentHome"
            roles={[5]}
            setUserLockout={setUserLockout}
          >
            <Icon icon={faSwimmer} isClosed={isClosed} />
            {!isClosed && <span>My Swimmers</span>}
          </RestrictedLink>
          <RestrictedLink
            className={pathname === "/app/billing" ? "active" : "inactive"}
            to="/app/billing"
            roles={[2, 3, 4, 5]}
            setUserLockout={setUserLockout}
          >
            <Icon icon={faCreditCard} isClosed={isClosed} />
              {!isClosed && <span>Payment History</span>}
          </RestrictedLink>
        </Submenu>
      ) : null}
      {!isClosed && (
        <SpacedOutRow>
          <Link to="/app/landingPage">
            <Icon icon={faTable} isClosed={isClosed} /> {getRole(user)}
          </Link>
        </SpacedOutRow>
      )}
      {isClosed ? <br /> : null}
      <Submenu isClosed={isClosed}>
        <RestrictedLink
          className={
            pathname === "/app/admin/club/seasons" ? "active" : "inactive"
          }
          to="/app/admin/club/seasons"
          roles={[2, 3, 4]}
          setUserLockout={setUserLockout}
        >
          <Icon icon={faCalendarCheck} isClosed={isClosed} />
          {!isClosed && <span>Registration</span>}
        </RestrictedLink>
        <RestrictedLink
          className={
            pathname === "/app/admin/manage_league" ? "active" : "inactive"
          }
          to="/app/admin/manage_league"
          roles={[2, 3]}
          setUserLockout={setUserLockout}
        >
          <Icon icon={faGlobe} isClosed={isClosed} />
          {!isClosed && <span>League</span>}
        </RestrictedLink>
        <RestrictedLink
          className={pathname === "/app/admin/club/" ? "active" : "inactive"}
          to="/app/admin/club/"
          roles={[2, 3]}
          setUserLockout={setUserLockout}
        >
          <Icon icon={faBullhorn} isClosed={isClosed} />
          {!isClosed && <span>Clubs</span>}
        </RestrictedLink>
        <RestrictedLink
          className={pathname === "/app/leaderboard" ? "active" : "inactive"}
          to="/app/leaderboard"
          roles={[2, 3]}
          setUserLockout={setUserLockout}
        >
          <Icon icon={faCalculator} isClosed={isClosed} />
          {!isClosed && <span>Leaderboard</span>}
        </RestrictedLink>
        <RestrictedLink
          className={
            pathname === "/app/admin/manage_users/" ||
            pathname === "/app/admin/manage_admin_users/"
              ? "active"
              : "inactive"
          }
          to={
            user && user.role === 2
              ? "/app/admin/manage_admin_users/"
              : "/app/admin/manage_users/"
          }
          roles={[2, 3]}
          setUserLockout={setUserLockout}
        >
          <Icon icon={faUserFriends} isClosed={isClosed} />
          {!isClosed && <span>League Admins</span>}
        </RestrictedLink>
        <RestrictedLink
          className={
            pathname === "/app/admin/swimminglyadmin" ? "active" : "inactive"
          }
          to="/app/admin/swimminglyadmin"
          roles={[2]}
          setUserLockout={setUserLockout}
        >
          <Icon icon={faPlaneDeparture} isClosed={isClosed} />
          {!isClosed && <span>Control Panel</span>}
        </RestrictedLink>
      </Submenu>
      <br />
      <SpacedOutRow>
        <a
          href="https://academy.swimmingly.app"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon icon={faGraduationCap} isClosed={isClosed} />
          {!isClosed && <span>Swimmingly Academy</span>}
        </a>
      </SpacedOutRow>
      <SpacedOutRow>&nbsp;
        <a
          href="https://support.swimmingly.app"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon icon={faLifeRing} isClosed={isClosed} />
          {!isClosed && <span>Help Center</span>}
        </a>
      </SpacedOutRow>
      <SpacedOutRow>
        <a
          href="https://store.swimmingly.app"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon icon={faStore} isClosed={isClosed} />
          {!isClosed && <span>Store</span>}
        </a>
      </SpacedOutRow>
      <SpacedOutRow>
        <Link to="/" onClick={logout}>
          <Icon icon={faPowerOff} isClosed={isClosed} />
          {!isClosed && <span>Logout</span>}
        </Link>
      </SpacedOutRow>
      {!isClosed && (
        <div style={{ marginTop: "25px" }}>
          <WhiteText isMobile={isMobile}>{user && user.email}</WhiteText>
          <br />
          <WhiteText isMobile={isMobile}>
            {impersonateClub && impersonateClub.name}
          </WhiteText>
        </div>
      )}
    </SidebarLeft>
  )
}
