import { Moment } from "moment-timezone"
import { PricingServiceRequest } from "../../Helpers/PricingServiceHelper"
import { DateFormats, ResultStatus, gen, swimminglyApi } from "../../utils"
import { RegistrationWaiverHelper } from "../RegistrationWaivers"
import { IPriceModificationCode, IRegistrationClubDue, IRegistrationForm, IWaiver } from "../sharedState"
import { message } from "antd"


export interface IPricePointInfo {
  pricePoints: IRegistrationClubDue[]
  priceModificationCodes: IPriceModificationCode[]
}

export interface IRegistrationFormInfo {
  registrationInfo: IRegistrationForm
  waivers: IWaiver[]
  dbUsingRegistration: boolean
  pricePoints: IRegistrationClubDue[]
  priceModificationCodes: IPriceModificationCode[]
  status: ResultStatus
}

export type GuardianWaiverResponse = {
  waiverSectionId: string,
  guardianAcknowledged: boolean | undefined,
  freeformResponse: string | undefined
}

export interface IRegistrationFormInfoResult {
  status: ResultStatus
  registrationForm: IRegistrationFormInfo
  guardianWaiverResponsesByWaiverSectionId: Record<string, GuardianWaiverResponse>
}

export interface NamedSwimmers {
  dateOfBirth: Date,
  firstName: string,
  gender: boolean,
  lastName: string,
  middleInitials: string,
  swimmerId: number,
  swimminglyMembershipFee: number
  discounts: string[]
}

export interface CheckoutItems {
  itemDescription: string,
  amount: number
}


export interface IInvoice {
  invoiceId: number
  invoiceDate: Moment
  invoiceDues: number
  invoiceSubtotal: number
  invoiceTotal: number
  discountAmount: number
  checkoutItemsAmount: number
  clubName: string
  clubId: number
  checkoutItems: CheckoutItems []
  seasonName: string
  seasonId: number
  processingFee: number 
  payingGuardianEmail?: string
  swimmers: NamedSwimmers[]
  
}

export interface IInvoiceResult {
  status: ResultStatus
  invoices: IInvoice[]
}

export interface IRegistrationStatus {
  countRegistrations: number
  countSwimmers: number
}

export interface IIsRegistrationEnabled {
  status: ResultStatus
  isRegistrationEnabled: boolean
}

interface RegistrationHelperProps {
  clubId: number
  seasonId: number
  confirmedByUserId: number
  date: Moment
}

export class RegistrationHelper {
  api = swimminglyApi

  public async updateSignupOpenDate({ clubId, seasonId, confirmedByUserId, date }: RegistrationHelperProps): Promise<boolean> {
    const { status } = await this.api
      .post(gen("/api/confirmSeasonSettingsAndOpenSignups"))
      .body({
        clubId: clubId,
        seasonId: seasonId,
        confirmedBy: confirmedByUserId,
        signupsOpenDate: date.format(DateFormats.default),
      })
    return status === "success"
  }

  public async getRegistrationFormInfo(clubId: number, seasonId: number, guardianId?: number): Promise<IRegistrationFormInfoResult> {
    const result: IRegistrationFormInfoResult = await (async () => {
      //account for how some clients of this code are club admins which will not have guardian ids or guardian responses.
      if (guardianId !== undefined) {
        const result: IRegistrationFormInfoResult = await this.api.get(gen(`/api/guardian/${guardianId}/registrationForm/clubId=${clubId}/seasonId=${seasonId}`))
        return result
      } else {
        const registrationForm: IRegistrationFormInfo = await this.api.get(gen(`/api/getRegistrationFormForClubSeason/clubId=${clubId}/seasonId=${seasonId}`))
        const result: IRegistrationFormInfoResult = { status: registrationForm.status, registrationForm: registrationForm, guardianWaiverResponsesByWaiverSectionId: {} }
        return result
      }
    })()

    //use RegistrationWaiverHelper to get waivers that already have their sections populated
    const waiversHelper = new RegistrationWaiverHelper()
    result.registrationForm.waivers = await waiversHelper.getWaiversWithSections(clubId, seasonId)

    if (result.status === "success") {
      return Promise.resolve(result)
    } else {
      return Promise.reject("Could not retreive registration info")
    }

  }

  public async setGuardianWaiverResponse(guardianId: number, registrationFormId: number, waiverSectionId: number, guardianAcknowledged: boolean | undefined, freeformResponse: string | undefined): Promise<ResultStatus> {
    try {
      const { status } = await this.api
        .post(gen(`/api/guardian/${guardianId}/registration/${registrationFormId}/waiverSection/${waiverSectionId}`))
        .body({
          guardianAcknowledged: guardianAcknowledged,
          freeformResponse: freeformResponse
        })
      return status
    } catch (error) {
      console.log(`error: ${error}`)
      return "error"
    }
  }

  public async checkout(clubId: number, seasonId: number, guardianId: number, pricingServiceRequest: PricingServiceRequest, stripePaymentMethodId?: string): Promise<ResultStatus> {
    const result = await this.api
      .post(gen(`/api/payForSwimmersRegistration/${clubId}/${seasonId}/${guardianId}`))
      .body({ cartData: pricingServiceRequest, stripePaymentMethodId: stripePaymentMethodId })
    return result.status
  }

  public async invoicesForGuardian(guardianId: number): Promise<IInvoiceResult> {
    return this.api
      .get(gen(`/api/getUsersRegistrationInvoiceHistory/${guardianId}`))

  }

  public async registrationStatus(clubId: number, seasonId: number): Promise<IRegistrationStatus> {
    return this.api
      .get(gen(`/api/getRegistrationStatus/${clubId}/${seasonId}`))
  }

  public async isRegistrationEnabled(clubId: number, seasonId: number): Promise<IIsRegistrationEnabled> {
    return this.api
      .get(gen(`/api/getIsRegistrationEnabled/${clubId}/${seasonId}`))
    
  }

  public async getRegistrationReport(clubId: number, seasonId: number): Promise<any> {
    return this.api
      .get(gen(`/api/registration/reports/${clubId}/${seasonId}`))
  }

  public async updateRegistrationEnabled(clubId: number, seasonId: number, isRegistrationEnabled: boolean): Promise<IIsRegistrationEnabled>{
    return this.api
      .post(gen(`/api/updateIsRegistrationEnabled/${clubId}/${seasonId}`))
      .body({isRegistrationEnabled: isRegistrationEnabled})
    
  }


  public async addGuardianToSwimmer(swimmerId: number, userId: number | undefined,
    guardianEmail: string) {
      try{
        const status = await this.api
          .post(gen(`/api/addNewGuardianToSwimmer/${swimmerId}`))
          .body({
            requesterId: userId,
            newGuardianEmail: guardianEmail
          })
          .then((data) => {
            if (data.status === "success") message.success("success!")
            else
              message.error("Problem adding guardian - please contact swimmingly")
          })
        return status
      } catch (error) {
        console.log(`error: ${error}`)
        return "Problem adding guardian - please contact swimmingly."
      }
      }

}

