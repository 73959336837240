import React from "react";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import { message, Spin, Popconfirm, Tooltip } from "antd";
import { actionItemStyle, gen } from "./utils";

class UnMergeButton extends React.Component {
  state = {
    loading: false,
    tooltipVisible: false, // State property to manage tooltip visibility
  };

  unmergeHeatSheet = () => {
    this.setState({ loading: true, tooltipVisible: false }); // Hide tooltip on button click
    $.ajax({
      method: "POST",
      url: gen(`/api/unmergeMeetPost`),
      data: {
        meetId: this.props.meetId,
      },
    }).done((data) => {
      this.setState({ loading: false });
      message.success("Meet has been unmerged");
      this.props.history.push("/app/club/meetschedule2");
    });
  };

  handleTooltipVisibleChange = (visible) => {
    this.setState({ tooltipVisible: visible });
  };

  render() {
    const { leagueAdminOrHydroAdminOrHomeClub } = this.props;

    return (
      <span style={actionItemStyle}>
        <Popconfirm
            title="Are you sure you wish to un-merge this meet? Doing so will discard any drag/drop changes made on this merged heat sheet."
            onConfirm={this.unmergeHeatSheet}
            onCancel={() => this.setState({ tooltipVisible: false })} // Ensure tooltip stays hidden on cancel
            okText="Yes, Un-Merge"
            cancelText="Cancel"
            placement="left"
            style={{ padding: "10px" }}
            id="unmergeId"
            disabled={!leagueAdminOrHydroAdminOrHomeClub}
          >
            <Tooltip
            title="Club Admins from the home team or League Admins have this permission"
            visible={this.state.tooltipVisible} // Control tooltip visibility
            onVisibleChange={this.handleTooltipVisibleChange} // Handle tooltip visibility change
            placement="bottom"
            >
              <span
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                }}
                onMouseEnter={() => this.setState({ tooltipVisible: true })} // Show tooltip on hover
                onMouseLeave={() => this.setState({ tooltipVisible: false })} // Hide tooltip when not hovering
              >
                
                  <span
                    style={{
                      color: leagueAdminOrHydroAdminOrHomeClub ? "inherit" : "gray",
                      cursor: leagueAdminOrHydroAdminOrHomeClub ? "pointer" : "not-allowed",
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                  >
                    {this.state.loading && (
                      <Spin size="small" style={{ marginRight: "5px" }} />
                    )}
                    Unmerge Heat Sheet
                  </span>
                
              </span>
            </Tooltip>
        </Popconfirm>
      </span>
    );
  }
}

export default withRouter(UnMergeButton);
