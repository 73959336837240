import { GuardianWaiverResponse } from "../ClubSeasonManagement/RegistrationConfiguration/RegistrationHelper";
import { IWaiver } from "../ClubSeasonManagement/sharedState";
import { parsedInt } from "../utils";

export type GuardianWaiversListener = (waiverSectionId: number|undefined, guardianAcknowledged: boolean|undefined, freeformResponse: string|undefined, isCompleted: boolean) => void;

export class GuardianWaiverMonitor {
    waiverSectionIdsRequiringResponse: Set<number>
    waiverSectionIdsWithResponses: Set<number>
    listeners: GuardianWaiversListener[] 

    public get isCompleted(): boolean {
        const waiverSectionIDsForRequiredSectionsThatHaveResponses = new Set(Array.from(this.waiverSectionIdsWithResponses).filter(sectionId => this.waiverSectionIdsRequiringResponse.has(sectionId)))
        
        return this.waiverSectionIdsRequiringResponse.size === waiverSectionIDsForRequiredSectionsThatHaveResponses.size
    }
    
    constructor() {
        //keith says: i'd really prefer to construct this with all of its dependencies, but they won't have been fetched from the server at the time that we need them
        this.waiverSectionIdsRequiringResponse = new Set<number>()
        this.waiverSectionIdsWithResponses = new Set<number>()
        this.listeners = []
    }

    public init(waivers: IWaiver[], guardianWaiverResponses: GuardianWaiverResponse[], listeners: GuardianWaiversListener[]) {

        const filteredWaivers = waivers.filter((waiver) => Boolean(waiver.waiverEnabled))

        const allWaiverSections = filteredWaivers
            .flatMap((waiver) => waiver.sections)
        const filteredWaiverSections = allWaiverSections
            .filter((section) => ["mandatory"].includes(section?.acknowledgmentRequirement ?? ""))


        filteredWaiverSections            
            .forEach((section) => {
                if (section !== undefined) {
                    this.waiverSectionIdsRequiringResponse.add(section.waiverSectionId)
                }
            }) 

        //filter out any guardian responses for waiver sections that are not currently enabled    
        const filteredGuardianWaiverResponses = guardianWaiverResponses.filter((guardianWaiverResponse) => {
            const waiverSectionId = parsedInt(guardianWaiverResponse.waiverSectionId)
            return waiverSectionId && this.waiverSectionIdsRequiringResponse.has(waiverSectionId)
        })


        //populate the set containing waiver section Id's that that 
        this.addResponses(filteredGuardianWaiverResponses)
        this.listeners = listeners
    }

    private addResponses(guardianWaiverResponses: GuardianWaiverResponse[]) {

        const validResponses = guardianWaiverResponses
        .filter((guardianWaiverResponse) => parsedInt(guardianWaiverResponse.waiverSectionId))
        //keith says: normally parseInt() is a little dangerous since it will return the almost always unexpected NaN when it can't coerce the value to a number
        //We're accounting for that by using the custom parsedInt() which instead returns undefined and filtering them out above

        validResponses.forEach((guardianWaiverResponse) => {
            const {guardianAcknowledged, freeformResponse, waiverSectionId} = guardianWaiverResponse
            const isCompleted = Boolean(guardianAcknowledged) || (freeformResponse && freeformResponse.trim().length > 0)
            if (isCompleted) {
                this.waiverSectionIdsWithResponses.add(parseInt(guardianWaiverResponse.waiverSectionId))
            } else {
                //Since Set is pretty new to Javascript, there's actually no remove() method
                //As such, here's a goofy mechanism to remove only one item from the set
                const waiverSectionIdsRemovingOne = Array.from(this.waiverSectionIdsWithResponses).filter((sectionId) => sectionId !== parsedInt(waiverSectionId))
                this.waiverSectionIdsWithResponses.clear()
                waiverSectionIdsRemovingOne.forEach((sectionId) => {this.waiverSectionIdsWithResponses.add(sectionId)})
            }
            
            
        })
        this.notify(validResponses)

    }

    public addResponse(waiverSectionId: string, guardianAcknowledged: boolean|undefined, freeformResponse: string|undefined) {
        
        this.addResponses([{waiverSectionId, guardianAcknowledged, freeformResponse}])
    }

    private notify(guardianWaiverResponses: GuardianWaiverResponse[]) {
        if (this.listeners.length > 0) {
    
            guardianWaiverResponses.forEach((guardianWaiverResponse) => {
                this.listeners.forEach((callback) => {
                    const waiverSectionId = parsedInt(guardianWaiverResponse.waiverSectionId)
                    if (waiverSectionId !== undefined) {
                        callback(waiverSectionId,guardianWaiverResponse.guardianAcknowledged, guardianWaiverResponse.freeformResponse,false)
                    }
                })
            })
    
            //notify if we're completed
            this.listeners.forEach((callback) => {
                callback(undefined, undefined, undefined, this.isCompleted)
            })

     
        }
   }

    
    
}