import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { useStoreState, useStoreActions, StateMapper } from "easy-peasy"
import { AppDataStore } from "../appData/types"
import AllClubs from "./AllClubs"
import AddClub from "./AddClub"
import AddLeague from "./AddLeague"
import AllLeagues from "./AllLeagues"
import DashboardLayout from "./DashboardLayout"
import ManageAdminUsers from "./ManageAdminUsers"
import EditLeague from "./EditLeague"
import EditClub from "./EditClub"
import ReportsPage from "./ReportsPage"
import ManageClubUsers from "./ManageClubUsers"
import MySwimmers from "./MySwimmers"
import MeetSchedulePage from "./MeetSchedulePageNew"
import ParentMeetSchedulePage from "./ParentMeetSchedule/ParentMeetSchedulePage"
import ViewMeetLineUp from "./ViewMeetLineUp"
import ViewMeetLineUpsParent from "./ViewMeetLineUpParent"
import HeatSheetPage from "./HeatSheetPage"
import MeetResults from "./MeetResults"
import ManageLeagueUsers from "./ManageLeagueUsers"
import PrivateRoute from "./PrivateRoute"
import NewPrivateRoute from "./NewPrivateRoute"
import Leaderboard from "./Leaderboard"
import NotFound from "./NotFound"
import VirtualMeetResults from "./VirtualMeetResults"
import BillingClub from "./BillingClub"
import SwimminglyAdminFunctionality from "./SwimminglyAdminFunctionality"
import AdvancedClubManagement from "./AdvancedClubManagement"
import UserManagement from "./UserManagement"
import DeleteVirtualMeet from "./DeleteVirtualMeet"
import CopyEventOrder from "./CopyEventOrder"
import { IClub } from "./utils"
import LandingPage from "./LandingPage"
import ManageRoster from "./ManageRoster"
import MergeSwimmers from "./MergeSwimmers"
import CreateSeason from "./CreateSwimSeason"
import LumpSumPayment from "./LumpSumPayment"
import Billing from "./Billing"
import ClubInvoices from "./ClubInvoices"
import { ClubSeasonManagement } from "./ClubSeasonManagement"
import GuardianCartView from "./GuardianCartView"
import AthleteViewReal from "./AthleteView"

export default function InAppRoutes() {
  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)
  const impersonateClub = useStoreState(
    (state: StateMapper<AppDataStore>) => state.aliasedClub,
  )
  const setImpersonateClub = useStoreActions(
    (actions: AppDataStore) => actions.setAliasedClub,
  )
 
  const parentNoClub = (
    <>
      <header className="page-header">
        <h2>Action Needed</h2>
      </header>
      <section className="panel">
        <header className="panel-heading">
          <h2 className="panel-title">Missing Club Data</h2>
        </header>
        <div className="ourpanel-body">
          <p>
            Uh oh! Your account isn't attached to any Swimmingly teams. Give us
            a call at (866) 377-7946 or email team@swimmingly.app so we can
            update your account with the correct team.
          </p>
        </div>
      </section>
    </>
  )

  return (
    <DashboardLayout user={user}>
      {/* Admin / League Admin routes */}
      <Switch>
        <NewPrivateRoute
          exact
          path="/app/admin/club"
          key="/app/admin/club"
          requiresImpersonateClub={false}
          allowedRoles={[2, 3]}
          render={(props: any) => {
            return (
              <AllClubs
                {...props}
                user={user}
                setImpersonateClub={(
                  club: IClub,
                  callback: (value?: any) => void,
                ) => {
                  setImpersonateClub(club)
                  if (callback) {
                    callback()
                  }
                }}
              />
            )
          }}
        />
        <NewPrivateRoute
          exact
          path="/app/admin/club/add"
          key="/app/admin/club/add"
          requiresImpersonateClub={true}
          allowedRoles={[2, 3]}
          render={(props: any) => (
            <AddClub {...props} user={user} club={impersonateClub} />
          )}
        />
        <NewPrivateRoute
          exact
          path="/app/admin/club/seasons"
          key="/app/admin/club/seasons"
          requiresImpersonateClub={true}
          allowedRoles={[2, 3, 4]}
          render={(props: any) => <ClubSeasonManagement />}
        />
        <NewPrivateRoute
          exact
          path="/app/admin/manage_league"
          key="/app/admin/manage_league"
          requiresImpersonateClub={false}
          allowedRoles={[2, 3]}
          render={(props: any) => <AllLeagues {...props} user={user} />}
        />
        <NewPrivateRoute
          exact
          path="/app/billing"
          key="/app/billing"
          requiresImpersonateClub={false}
          allowedRoles={[2, 3, 4, 5]}
          render={(props: any) => <Billing />}
        />
        <PrivateRoute
          exact
          path="/app/club/billing"
          key="/app/club/billing"
          allowedRoles={[2, 3, 4]}
          render={(props: any) => <BillingClub />}
        />
        <PrivateRoute
          exact
          path="/app/admin/add_league"
          key="/app/admin/add_league"
          allowedRoles={[2, 3]}
          render={(props: any) => <AddLeague {...props} user={user} />}
        />
        <PrivateRoute
          exact
          path="/app/admin/edit_league/:leagueId"
          key="/app/admin/edit_league/:leagueId"
          allowedRoles={[2, 3]}
          render={(props: any) => <EditLeague {...props} user={user} />}
        />
        <PrivateRoute
          exact
          path="/app/admin/manage_users"
          key="manageUsers"
          allowedRoles={[2, 3]}
          render={(props: any) => <ManageLeagueUsers {...props} user={user} />}
        />
        <PrivateRoute
          exact
          path="/app/admin/manage_admin_users"
          key="manageAdminUsers"
          allowedRoles={[2, 3]}
          render={(props: any) => <ManageAdminUsers {...props} user={user} />}
        />
        {/* Club Routes */}
        <NewPrivateRoute
          exact
          path="/app/club/admin"
          key="editClub"
          requiresImpersonateClub={true}
          allowedRoles={[2, 3, 4]}
          render={(props: any) => {
            if (!impersonateClub) {
              return <Redirect to="/" />
            } else {
              return (
                <EditClub
                  {...props}
                  user={user}
                  club={impersonateClub}
                  setImpersonateClub={(
                    club: IClub,
                    callback: (value?: any) => void,
                  ) => {
                    setImpersonateClub(club)
                    if (callback) {
                      callback()
                    }
                  }}
                />
              )
            }
          }}
        />
        <PrivateRoute
          exact
          path="/app/club/reports"
          key="reports"
          user={user}
          allowedRoles={[2, 3, 4]}
          render={() => <ReportsPage user={user} club={impersonateClub} />}
        />
        <NewPrivateRoute
          exact
          path="/app/club/manageclubusers"
          key="manageclubusers"
          requiresImpersonateClub={true}
          allowedRoles={[2, 3, 4]}
          render={() => <ManageClubUsers user={user} club={impersonateClub} />}
        />
        <NewPrivateRoute
          exact
          path="/app/club/manageroster"
          key="manageroster"
          requiresImpersonateClub={true}
          allowedRoles={[2, 3, 4]}
          render={() => <ManageRoster />}
        />
        <NewPrivateRoute
          exact
          path="/app/club/meetschedule2"
          key="meetschedule2"
          requiresImpersonateClub={true}
          allowedRoles={[2, 3, 4, 5]}
          render={() => <MeetSchedulePage />}
        />
        <PrivateRoute
          path="/app/landingPage"
          key="landingPage"
          allowedActivations={[0, 1]}
          allowedRoles={[2, 3, 4, 5]}
          render={() => <LandingPage />}
        />

        <NewPrivateRoute
          exact
          path={["/app/parent/viewMeetLineUp/:meetId/:clubId","/app/parent/viewMeetLineUp/:meetId"]}
          key="lineup"
          requiresImpersonateClub={false}
          allowedRoles={[2, 3, 4, 5]}
          render={(props: any) => {
            return <ViewMeetLineUpsParent meetId={props.match.params.meetId} user={user} />
          }}
        />        

        <NewPrivateRoute
          exact
          path="/app/club/viewMeetLineUp2/:meetId"
          key="lineup"
          requiresImpersonateClub={true}
          allowedRoles={[2, 3, 4]}
          render={(props: any) => {
            return <ViewMeetLineUp meetId={props.match.params.meetId} />
          }}
        />
        <PrivateRoute
          exact
          path="/app/club/heatSheet2/:meetId"
          key="heatsheet"
          allowedRoles={[2, 3, 4, 5]}
          render={(props: any) => {
            return (
              <HeatSheetPage
                user={user}
                club={impersonateClub}
                meetId={props.match.params.meetId}
              />
            )
          }}
        />
        <PrivateRoute
          exact
          path="/app/meet/results/:meetId"
          key="results"
          allowedRoles={[2, 3, 4, 5]}
          render={(props: any) => {
            return (
              <MeetResults
                user={user}
                club={impersonateClub}
                meetId={props.match.params.meetId}
              />
            )
          }}
        />
        <PrivateRoute
          exact
          path="/app/virtualMeet/results/:meetId"
          key="results"
          allowedRoles={[2, 3, 4, 5]}
          render={(props: any) => {
            return (
              <VirtualMeetResults
                user={user}
                club={impersonateClub}
                meetId={props.match.params.meetId}
              />
            )
          }}
        />
        {/* Parent routes */}
        <PrivateRoute
          exact
          path="/app/athleteProfile/:swimmerId"
          key="athleteProfile"
          allowedRoles={[2, 3, 4, 5]}
          render={(props: any) => {
            return (
              <AthleteViewReal
                user={user}
                swimmerId={props.match.params.swimmerId}
              />
            )
          }}
        />

        <PrivateRoute
          exact
          path="/app/parentMeets"
          key="parentMeets"
          allowedRoles={[5]}
          render={() => {
            return (
              <ParentMeetSchedulePage
                user={user}
                club={impersonateClub}
                isParent={true}
              />
            )
          }}
        />
        <PrivateRoute
          exact
          path="/app/parentHome"
          key="parentHome"
          allowedRoles={[5]}
          render={() => {
            // if (!impersonateClub) return parentNoClub
            return <MySwimmers />
          }}
        />
        <PrivateRoute
          exact
          path="/app/cart"
          key="cart"
          allowedRoles={[3,4, 5]}
          render={() => {
            return <GuardianCartView />
          }}
        />
        <PrivateRoute
          exact
          path="/app/leaderboard"
          key="leaderboard"
          allowedRoles={[2, 3, 4, 5]}
          render={() => {
            return <Leaderboard />
          }}
        />
        <PrivateRoute
          exact
          path="/app/admin/swimminglyadmin"
          key="swimminglyadmin"
          allowedRoles={[2]}
          render={() => {
            return <SwimminglyAdminFunctionality />
          }}
        />
        <PrivateRoute
          path="/app/admin/swimminglyadmin/advancedclubmanagement"
          key="swimminglyAdminAdvancedClubManagement"
          allowedRoles={[2]}
          render={() => {
            return <AdvancedClubManagement />
          }}
        />
        <PrivateRoute
          path="/app/admin/swimminglyadmin/clubInvoices"
          key="swimminglyAdminClubInvoices"
          allowedRoles={[2]}
          render={() => {
            return (
              <React.Fragment>
                <div>
                  <header className="page-header">
                    <h2>Invoices</h2>
                  </header>
                  <br />
                  <section className="panel">
                    <header className="panel-heading">
                      <h2 className="panel-title">Invoices</h2>
                    </header>
                    <div
                      style={{
                        border: "1px solid var(--mediumgrey)",
                        minHeight: "500px",
                        borderBottomLeftRadius: "5px",
                        borderBottomRightRadius: "5px",
                      }}
                    >
                      <h4 style={{ paddingLeft: "25px", paddingTop: "15px" }}>
                        {impersonateClub?.nameLong ||
                          impersonateClub?.name_long}
                      </h4>
                      <ClubInvoices />
                    </div>
                  </section>
                </div>
              </React.Fragment>
            )
          }}
        />
        <PrivateRoute
          path="/app/admin/swimminglyadmin/usermanagement"
          key="usermanagement"
          allowedRoles={[2]}
          render={() => {
            return <UserManagement />
          }}
        />
        <PrivateRoute
          path="/app/admin/swimminglyadmin/mergeswimmers"
          key="mergeswimmers"
          allowedRoles={[2]}
          render={() => {
            return <MergeSwimmers />
          }}
        />
        <PrivateRoute
          path="/app/admin/swimminglyadmin/deletevirtualmeet"
          key="deletevirtualmeet"
          allowedRoles={[2]}
          render={() => {
            return <DeleteVirtualMeet />
          }}
        />
        <PrivateRoute
          path="/app/admin/swimminglyadmin/copyeventorder"
          key="copyeventorder"
          allowedRoles={[2]}
          render={() => {
            return <CopyEventOrder />
          }}
        />
        <NewPrivateRoute
          path="/app/admin/swimminglyadmin/createseason"
          key="createseason"
          requiresImpersonateClub={false}
          allowedRoles={[2]}
          render={() => {
            return <CreateSeason />
          }}
        />
        <NewPrivateRoute
          path="/app/admin/swimminglyadmin/lumpsumpayment"
          key="lumpsumpayment"
          requiresImpersonateClub={false}
          allowedRoles={[2]}
          render={() => {
            return <LumpSumPayment />
          }}
        />
        <Route component={NotFound} />
      </Switch>
    </DashboardLayout>
  )
}
