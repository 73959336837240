import { message, Radio, Tooltip, Space } from "antd"
import { FunctionComponent, useCallback, useEffect, useState } from "react"
import { gen, swimminglyApi } from "../../utils"
import { useClubSeasonManagementContext } from "../sharedState"
import {
  StyledAlertSpan,
  StyledPreformattedText,
  StyledWarningOutlined,
} from "./styles"
import { EditOutlined, DeleteOutlined } from "@ant-design/icons"
import { Colors } from "../styles"
import { ParagraphedText } from "../../ui"


export type TAcknowledgmentRequirement =
  | "mandatory"
  | "no acknowledgement"
  | "text input"

interface IRegistrationWaiverSubsectionProps {
  waiverName: string
  waiverSectionId: number
  triggerRefresh: number
  refreshWaiver: () => void
  onlyWaiverSection: boolean
}

export interface IRegistrationWaiverSection {
  waiverSectionId: number
  waiverId: number
  sectionTitle: string
  sectionInUse: 0 | 1
  acknowledgmentRequirement: TAcknowledgmentRequirement
  wording: string
  orderInWaiver: number
  createdAt: string
  updatedAt: string
  deletedAt: string | null | undefined
  sectionId: number
}

export const RegistrationWaiverSubsection: FunctionComponent<IRegistrationWaiverSubsectionProps> =
  ({
    waiverName,
    waiverSectionId,
    triggerRefresh,
    refreshWaiver,
    onlyWaiverSection,
  }) => {
    const [editingTitle, setEditingTitle] = useState(false)
    const [editingWording, setEditingWording] = useState(false)
    const [title, setTitle] = useState("")
    const [requirementStatus, setRequirementStatus] =
      useState<TAcknowledgmentRequirement>("mandatory")
    const [wording, setWording] = useState("")
    const [sectionInUse, setSectionInUse] = useState(true)

    const { dbUsingRegistration, setRegistrationModalVisible } =
      useClubSeasonManagementContext()

    useEffect(() => {
      swimminglyApi
        .get(gen(`/api/getWaiverSection/${waiverSectionId}`))
        .then(
          (data: {
            status: string
            waiverSection: IRegistrationWaiverSection
          }) => {
            if (data.status === "success") {
              const { waiverSection } = data
              setTitle(waiverSection.sectionTitle)
              setWording(waiverSection.wording)
              setRequirementStatus(waiverSection.acknowledgmentRequirement)
              setSectionInUse(waiverSection.sectionInUse === 1)
              setEditingWording(false)
              setEditingTitle(false)
            }
          },
        )
    }, [waiverSectionId, triggerRefresh])

    const updateWaiverTitle = useCallback(() => {
      swimminglyApi
        .post(gen(`/api/updateWaiverSectionTitle/${waiverSectionId}`))
        .body({
          newTitle: title,
        })
        .then((data) => {
          if (data.status !== "success") {
            console.log(data)
            throw new Error(data?.message || "unknown error")
          }
        })
        .catch(() => {
          message.error("Something went wrong updating the title")
        })
        .finally(() => {
          setEditingTitle(false)
          refreshWaiver()
        })
    }, [refreshWaiver, title, waiverSectionId])

    return (
      <section>
        <hr />
        <div style={{ fontSize: "16px", display: "inline" }}>
          {editingTitle ? (
            <input
              value={title}
              onBlur={updateWaiverTitle}
              onKeyDown={(e) => {
                if (["enter", "return"].includes(e.key.toLowerCase())) {
                  updateWaiverTitle()
                }
              }}
              onChange={(e) => setTitle(e.target.value)}
            />
          ) : (
            <h4 style={{ display: "inline" }}>
              <br />
              {title}
              <span
                style={{ fontSize: "16px", color: Colors.veryDarkBlue, cursor: "pointer" }}
                onClick={() => {
                  if (!dbUsingRegistration) {
                    setRegistrationModalVisible(true)
                  } else {
                    setEditingTitle(true)
                  }
                }}
              >
                {" "}
                &nbsp;<EditOutlined />
                &nbsp;<b>Click to edit section title</b>
              </span>
              </h4>
          )}
          {!onlyWaiverSection && (
            <Tooltip title="Delete section">
              <DeleteOutlined
                onClick={(e) => {
                  if (!dbUsingRegistration) {
                    setRegistrationModalVisible(true)
                  } else {
                    swimminglyApi
                      .post(gen(`/api/deleteWaiverSection/${waiverSectionId}`))
                      .body({})
                      .then((data) => {
                        if (data.status !== "success") {
                          console.log(data)
                          throw new Error("Something went wrong")
                        }
                      })
                      .catch((err) => {
                        message.error(
                          "Something went wrong deleting this section",
                        )
                      })
                      .finally(refreshWaiver)
                  }
                }}
                style={{ cursor: "pointer", marginLeft: "15px" }}
              />
            </Tooltip>
          )}
          <p style={{ fontSize: "14px" }}>
            <i>
              This is a section of your {waiverName}.
              You can edit this section or add additional sections at your discretion.
            </i>
          </p>
        </div>
        <p style={{ outlineColor: Colors.primaryBlue, fontSize: "16px", marginBottom: "0px" }}>
          <b>Does this section require parent/guardian acknowledgment or a written
          response from a guardian?</b>
        </p>
        <Radio.Group
          value={requirementStatus}
          onChange={(e) => {
            if (!dbUsingRegistration) {
              setRegistrationModalVisible(true)
            } else {
              swimminglyApi
                .post(
                  gen(
                    `/api/updateRequirementStatusOfWaiverSection/${waiverSectionId}`,
                  ),
                )
                .body({
                  acknowledgmentRequirement: e.target.value,
                })
                .then((data) => {
                  if (data.status !== "success") {
                    console.log(data)
                    throw new Error(
                      "problem updating acknowledgmentRequirement",
                    )
                  }
                })
                .catch((err) => {
                  message.error(
                    "Something went wrong updating the requirements",
                  )
                })
                .finally(refreshWaiver)
            }
          }}
        >
          <Space direction="vertical">
          <Radio value="mandatory">Required Acknowledgement</Radio>
          <Radio value="no acknowledgment">No Acknowledgment (disclosure only)</Radio>      
          <Radio value="text input">Written Response Required from Guardian</Radio>
          </Space>
        </Radio.Group>
        <br />
        {!editingWording ? (
          <div>
            
            <StyledPreformattedText>
              <ParagraphedText idPrefix={`p_waiversection_${waiverSectionId}`} linkify={true}>
              {wording}
              </ParagraphedText>
            </StyledPreformattedText>
            <span
              style={{ fontSize: "16px", color: Colors.veryDarkBlue, cursor: "pointer" }}
              onClick={() => {
                if (!dbUsingRegistration) {
                  setRegistrationModalVisible(true)
                } else {
                  setEditingWording(true)
                }
              }}
            >
              <EditOutlined />
              &nbsp;<b>Click to edit section</b>
            </span>
          </div>
        ) : (
          <div>
            <textarea
              value={wording}
              onChange={(e) => setWording(e.target.value)}
              style={{ width: "95%", minHeight: "150px" }}
            />
            <br />
            <StyledAlertSpan
              onClick={() => {
                swimminglyApi
                  .post(
                    gen(`/api/updateWaiverSectionWording/${waiverSectionId}`),
                  )
                  .body({
                    wording,
                  })
                  .then((data) => {
                    if (data.status !== "success") {
                      console.log(data)
                      throw new Error("problem updating wording")
                    }
                  })
                  .catch((err) => {
                    message.error("Something went wrong updating the wording")
                  })
                  .finally(refreshWaiver)
                setEditingWording(false)
              }}
            >
              Click here to save
            </StyledAlertSpan>{" "}
            <Tooltip title="not saved">
              <StyledWarningOutlined />
            </Tooltip>
          </div>
        )}
        <hr />
      </section>
    )
  }
