import React, { useState, useEffect } from "react"
import { download, gen } from "./utils"
import moment from "moment-timezone"
import {
  message,
  Button,
  Divider,
  Row,
  Col,
  DatePicker,
  Checkbox,
  Table,
  Radio,
  Tooltip,
} from "antd"
import { cloneDeep } from "lodash"

const { RangePicker } = DatePicker

const HighPoints = (props) => {
  const [state, setState] = useState({
    meets: null,
    dates: [],
    meetsInRange: [],
    selectedMeets: [],
    includeRelays: false,
    relayPointsFraction: "25%",
  })
  const [initialRunOfEffect, setInitialRunOfEffect] = useState(true)

  // componentDidMount
  useEffect(() => {
    componentMounted()
  })

  async function componentMounted() {
    if (initialRunOfEffect === true) {
      const { clubId } = props.club
      let attempts = 0
      let successes = 0
      // try up to 3 times
      while (attempts < 3 && successes === 0) {
        attempts++
        let meetsData = await getClubMeetsData(clubId)
        if (meetsData.status === "success") {
          successes++
          const meets = meetsData.meets.filter((meet) => meet.meetstatus === 2)
          setState({ ...state, meets })
          setInitialRunOfEffect(false)
        } else if (attempts < 3) {
          // wait a second before trying again
          await new Promise((resolve) => setTimeout(resolve, 1000))
        } else {
          message.error("problem loading meets! try again later")
        }
      }
      return
    }
  }

  const getClubMeetsData = async (clubId) => {
    return await fetch(gen("/api/getClubMeets"), {
      method: "post",
      headers: { "Content-Type": "application/json" },
      credentials: "same-origin",
      body: JSON.stringify({ clubId }),
    })
      .then((response) => response.json())
      .catch((err) => {
        console.error(err)
      })
  }

  const dateRangeIsValid = ({ theDates } = { theDates: state.dates }) => {
    if (!theDates) return false
    if (theDates.length !== 2) return false
    if (theDates[0] === null || theDates[1] === null) return false
    if (theDates[0] === undefined || theDates[1] === undefined) return false
    return true
  }

  const changeSelectedDates = async (value) => {
    // figure out what meets were in range before and what meets are in range now
    const newValidMeets = cloneDeep(calcTableMeets({ theDates: value }))
    if (!newValidMeets || newValidMeets.length === 0) {
      setState({ ...state, dates: value })
      return
    }
    let previouslyValidMeets = state.meetsInRange
    let previouslyValidMeetIds = previouslyValidMeets.map(
      (previouslyValidMeet) => previouslyValidMeet.meetId,
    )
    // figure out what meets were selected before and what meets are selected now
    let newSelectedMeets = newValidMeets
    let previouslySelectedMeets = state.selectedMeets
    let previouslySelectedMeetIds = previouslySelectedMeets.map(
      (previouslySelectedMeet) => previouslySelectedMeet.meetId,
    )
    // figure out what meets are in range now, but should be auto-deslected
    // because they were in range before and the user had deselected them
    let meetIdsToRemove = []
    for (let newSelectedMeet of newSelectedMeets) {
      let newSelectedMeetWasPreviouslyInRange = previouslyValidMeetIds.includes(
        newSelectedMeet.meetId,
      )
      if (!newSelectedMeetWasPreviouslyInRange) {
        continue
      }
      let newSelectedMeetWasNotSelected = !previouslySelectedMeetIds.includes(
        newSelectedMeet.meetId,
      )
      if (newSelectedMeetWasNotSelected) {
        meetIdsToRemove.push(newSelectedMeet.meetId)
      }
    }

    const selectedMeets = newValidMeets.filter(
      (meet) => !meetIdsToRemove.includes(meet.meetId),
    )

    // update state
    setState({
      ...state,
      dates: value,
      meetsInRange: newValidMeets,
      selectedMeets,
    })
    return
  }

  const calcTableMeets = ({ theDates } = { theDates: state.dates }) => {
    let startDate = theDates[0]
    let endDate = theDates[1]
    return (
      state.meets &&
      dateRangeIsValid({ theDates }) &&
      state.meets.filter((meet) => {
        let meetDate = moment(meet.meet_date)
        let afterStartDate =
          meetDate.format("YYYY-MM-DD") >= startDate.format("YYYY-MM-DD")
        let beforeEndDate =
          meetDate.format("YYYY-MM-DD") <= endDate.format("YYYY-MM-DD")
        return afterStartDate && beforeEndDate
      })
    )
  }

  const tableColumns = [
    { title: "Meet", dataIndex: "meetName", key: "meetName" },
    {
      title: "Type",
      dataIndex: "isVirtualMeet",
      key: "virtualMeet",
      render: (text) => <span>{text === 1 ? "virtual" : "regular"}</span>,
    },
    {
      title: "Date",
      dataIndex: "meet_date",
      key: "meetDate",
      render: (text, row) => (
        <span key={`meetDate_${row.meetId}`}>
          {moment(text).format("YYYY-MM-DD")}
        </span>
      ),
    },
    {
      title: "Include In Report?",
      dataIndex: "meetId",
      render: (text, row) => (
        <span key={`meetIncluded_${row.meetId}`}>
          <Checkbox
            onChange={(e) => {
              let shouldInclude = e.target.checked
              let meetId = row.meetId
              let isIncluded = state.selectedMeets
                .map((selectedMeet) => selectedMeet.meetId)
                .includes(meetId)
              let newSelectedMeets
              if (shouldInclude && !isIncluded) {
                newSelectedMeets = [
                  ...state.selectedMeets,
                  state.meetsInRange.find(
                    (meetInRange) => meetInRange.meetId === meetId,
                  ),
                ]
              } else if (!shouldInclude && isIncluded) {
                newSelectedMeets = state.selectedMeets.filter(
                  (selectedMeet) => selectedMeet.meetId !== meetId,
                )
              }
              setState({ ...state, selectedMeets: newSelectedMeets })
            }}
            checked={state.selectedMeets
              .map((selectedMeet) => selectedMeet.meetId)
              .includes(row.meetId)}
          />
        </span>
      ),
    },
  ]

  const onSubmit = async () => {
    let relayPointsFraction = 0.25
    if (state.relayPointsFraction === "50%") relayPointsFraction = 0.5
    if (state.relayPointsFraction === "100%") relayPointsFraction = 1

    const postData = {
      clubId: props.club.clubId,
      clubName: props.club.name_long,
      dateRange: state.dates,
      meets: state.selectedMeets.map((selectedMeet) => {
        return {
          meetId: selectedMeet.meetId,
          meetName: selectedMeet.meetName,
          meetDate: selectedMeet.meet_date,
          isVirtualMeet: selectedMeet.isVirtualMeet,
          virtualMeetId: selectedMeet.virtualMeetId,
        }
      }),
      includeRelays: state.includeRelays === true ? 1 : 0,
      relayPointsFraction,
    }

    console.log(postData)

    const highPointExcel = await fetch(gen("/api/getHighPointData"), {
      method: "post",
      headers: { "Content-Type": "application/json" },
      credentials: "same-origin",
      body: JSON.stringify(postData),
    })
      .then((response) => {
        return response.blob()
      })
      .catch((err) => {
        console.error(err)
      })

    await download(highPointExcel, `HighPoints.xlsx`)
  }

  return (
    <div>
      <h4>High Point Report</h4>
      <Divider />
      <div>
        <Row>
          <Col style={{ width: "150px" }}>
            <span style={{ paddingTop: "5px", display: "inline-block" }}>
              Date
            </span>
          </Col>
          <Col style={{ width: "250px" }}>
            <RangePicker onCalendarChange={changeSelectedDates} />
          </Col>
        </Row>
        <Row style={{ marginTop: "15px" }}>
          <Col style={{ width: "150px" }}>
            <Checkbox
              onChange={(e) =>
                setState({ ...state, includeRelays: e.target.checked })
              }
            />
          </Col>
          <Col>
            <span style={{ paddingTop: "5px", display: "inline-block" }}>
              Include Relays?
            </span>
          </Col>
        </Row>
        {state.includeRelays && (
          <Row style={{ marginTop: "15px" }}>
            <Col style={{ width: "150px" }}>
              <Tooltip
                title={
                  <p>
                    <span>
                      This is the fraction of a relay's points that each swimmer
                      gets. For example, in a 4 person relay that scores 12
                      points, if you set Relay Fraction to 25%, then each
                      swimmer gets 3 points.
                    </span>
                  </p>
                }
              >
                Relay Points Fraction
              </Tooltip>
            </Col>
            <Col>
              <Radio.Group
                value={state.relayPointsFraction}
                options={["100%", "50%", "25%"]}
                onChange={(e) => {
                  setState({ ...state, relayPointsFraction: e.target.value })
                }}
              />
            </Col>
            <Col>
              <Tooltip
                title={
                  <span>
                    We only support the options listed, but in the exported file
                    we break down points by individual and relay events for each
                    athlete.
                    <br />
                    <br />
                    If you want to use a different fraction, we recommend
                    setting the value to 100% here and then editing the relay
                    points in excel to get your desired relay points weighting.
                  </span>
                }
              >
                <Button type="link">
                  Don't see the fraction you want to use?
                </Button>
              </Tooltip>
            </Col>
          </Row>
        )}
        <br />
        <Row>
          {state.selectedMeets && state.selectedMeets.length > 0 && (
            <Button onClick={onSubmit}>Generate Report</Button>
          )}
        </Row>
        <br />
        {state.meetsInRange && state.meetsInRange.length > 0 && (
          <Table
            rowKey={(row) => `tableRow${row.meetId}`}
            dataSource={state.meetsInRange}
            columns={tableColumns}
            pagination={false}
          />
        )}
      </div>
    </div>
  )
}

export default HighPoints
