import React, { useState, useEffect } from "react"
import { Button, message } from "antd"
import styled from "styled-components"
import { DeleteOutlined } from "@ant-design/icons"
import { debounce } from "lodash"
import moment from "moment-timezone"
import GenericModal from "./GenericModal"
import { screenSizes } from "../styles/GlobalStyles"
import SearchInput from "./SearchInput"
import CenterSpin from "./CenterSpin"
import { swimminglyApi, gen, fuzzyRank } from "./utils"

const VirtualMeetsTable = styled.table`
  /* border-collapse: collapse; */
  width: 95%;
  max-width: 1200px;
  margin: auto;

  td,
  th {
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: var(--primaryblue);
    color: white;
  }

  td.meet-name {
    max-width: 200px;
  }
`

type VirtualMeet = {
  virtualMeetId: number
  name: string
  meetIds?: Array<number>
  meetDate: Date
}

export default function DeleteVirtualMeet() {
  const [loading, setLoading] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [virtualMeets, setVirtualMeets] = useState<Array<VirtualMeet>>([])
  const [searchString, setSearchString] = useState("")
  const [selectedId, setSelectedId] = useState<number | null>(null)

  useEffect(() => {
    if (modalVisible === false) {
      setLoading(true)
      swimminglyApi
        .get(gen("/api/getAllVirtualMeets"))
        .then((data) => {
          setVirtualMeets(data.virtualMeets)
        })
        .catch((err) =>
          message.error("something went wrong finding virtual meets"),
        )
        .finally(() => setLoading(false))
    }
  }, [modalVisible])

  const onSearchChange = debounce((val: string) => {
    setSearchString(val)
  }, 350)

  let sortedVirtualMeets = [...virtualMeets]
  if (searchString !== "") {
    sortedVirtualMeets = fuzzyRank(
      sortedVirtualMeets,
      (virtualMeet) => [virtualMeet.name, `${virtualMeet.virtualMeetId}`],
      searchString,
    )
  }

  return (
    <>
      <section className="panel">
        <header className="panel-heading">
          <h2 className="panel-title">Delete Virtual Meet</h2>
        </header>
        <div className="ourpanel-body">
          {loading ? (
            <CenterSpin />
          ) : (
            <div>
              <p>
                Click on a virtual meet in the table below to delete it.{" "}
                <b>NOTE:</b>
                when you delete a virtual meet <b>ALL</b> records associated
                with the meet will be <b>HARD DELETED</b> including results,
                entries, and all of the underlying meets that make up the
                virtual meet.
              </p>
              <SearchInput onChange={(e) => onSearchChange(e.target.value)} />
              <VirtualMeetsTable>
                <thead>
                  <tr>
                    <th>virtualMeetId</th>
                    <th>name</th>
                    <th>date</th>
                    <th>actions</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedVirtualMeets.map((virtualMeet) => {
                    return (
                      <tr key={virtualMeet.virtualMeetId}>
                        <td>{virtualMeet.virtualMeetId}</td>
                        <td className="meet-name">{virtualMeet.name}</td>
                        <td>
                          {moment(virtualMeet.meetDate).format("YYYY-MM-DD")}
                        </td>
                        <td>
                          <DeleteOutlined
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSelectedId(virtualMeet.virtualMeetId)
                              setModalVisible(true)
                            }}
                          />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </VirtualMeetsTable>
            </div>
          )}
        </div>
      </section>
      <GenericModal
        visible={modalVisible}
        width="min(max(30%,300px),95%)"
        maxWidth={`${Math.round(screenSizes.big * 0.45)}`}
        setVisible={setModalVisible}
        title={`Are You Sure`}
        footer={() => (
          <>
            <Button
              key="close"
              onClick={() => {
                setSelectedId(null)
                setModalVisible(false)
              }}
            >
              Close
            </Button>
            <Button
              key="ok"
              onClick={async () => {
                await swimminglyApi
                  .delete(gen(`/api/deleteVirtualMeet/${selectedId}`))
                  .body({})
                setSelectedId(null)
                setModalVisible(false)
              }}
            >
              Ok
            </Button>
          </>
        )}
      >
        <p>
          Are you sure you want to delete <b>ALL MEETS AND RESULTS</b> that are
          a part of this virtual meet?
        </p>
      </GenericModal>
    </>
  )
}
