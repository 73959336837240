import React, { useEffect, useState } from "react"
import { Modal, message } from "antd"
import { gen, swimminglyApi } from "./utils"
import InlineTextEdit from "./InlineTextEdit"
import CenterSpin from "./CenterSpin"

interface IExportEntriesModal {
  isVisible: boolean
  clubId: number | string
  meetId: number
  updateVisible: (val: any) => void
}

function ExportEntriesModal({
  isVisible,
  clubId,
  meetId,
  updateVisible,
}: IExportEntriesModal) {
  const [lsc, setLsc] = useState("not set")
  const [teamCode, setTeamCode] = useState("not set")
  const [loading, setLoading] = useState(false)
  const [unknownSwimmerCount, setUnknownSwimmerCount] = useState(0)

  useEffect(() => {
    let mounted = true
    if (mounted) setLoading(true)

    const clubPromise = swimminglyApi
      .post(gen("/api/lscAndClubCode"))
      .body({ clubId: parseInt(clubId.toString()) })
      .then((clubResponse) => {
        if (clubResponse.status === "success") {
          let { sd3_lsc, sd3_team_code } = clubResponse.data
          if (!sd3_lsc || sd3_lsc.trim() === "") {
            sd3_lsc = "not set"
          }

          if (!sd3_team_code || sd3_team_code.trim() === "") {
            sd3_team_code = "not set"
          }

          if (mounted) {
            setLsc(sd3_lsc)
            setTeamCode(sd3_team_code)
          }
        }
      })

    const entriesPromise = swimminglyApi
      .post(gen("/api/countUnknownSwimmersInEntries"))
      .body({ meetId })
      .then((entriesData) => {
        if (entriesData.status === "success") {
          let theCounts: {
            club_id: number
            numEntries: number | string
          }[] = entriesData.data
          let updatedCounts = theCounts.filter((x) => x.club_id === clubId)
          if (updatedCounts.length > 0) {
            let thisClubCount = parseInt(updatedCounts[0].numEntries.toString())
            if (mounted) setUnknownSwimmerCount(thisClubCount)
          } else {
            if (mounted) setUnknownSwimmerCount(0)
          }
        }
      })

    Promise.all([clubPromise, entriesPromise]).then(() => {
      if (mounted) setLoading(false)
    })

    return () => {
      mounted = false
    }
  }, [isVisible, clubId, meetId])

  const updateStateFromApiResponse = (clubData: {
    status: string
    data: {
      sd3_lsc: string
      sd3_team_code: string
    }
  }) => {
    if (clubData.status === "success") {
      let { sd3_lsc, sd3_team_code } = clubData.data
      if (!sd3_lsc || sd3_lsc.trim() === "") {
        sd3_lsc = "not set"
      }

      if (!sd3_team_code || sd3_team_code.trim() === "") {
        sd3_team_code = "not set"
      }

      setLsc(sd3_lsc)
      setTeamCode(sd3_team_code)
    }
  }

  const handleOk = async () => {
    setLoading(true)

    await updateLscOrTeamCode({
      lscCode: ["", "not set"].includes(lsc.trim()) ? null : lsc,
      teamCode: ["", "not set"].includes(teamCode.trim()) ? null : teamCode,
    })


    try {
      await swimminglyApi.download(gen(`/api/meetEntriesSD3/${meetId}`), "SD3_Meet_Entries.sd3", document);
    } catch (error) {
      message.error("Could not download meet entries")
    }



    updateVisible(false)
  }


  const handleCancel = async () => {
    updateVisible(false)
  }

  const updateLscOrTeamCode = async (value: {
    lscCode: string | null
    teamCode: string | null
  }) => {
    const theData: {
      clubId: number
      newLscCode: string
      newTeamCode: string
    } = { clubId: parseInt(clubId.toString()), newLscCode: "", newTeamCode: "" }

    if (value.lscCode) {
      theData.newLscCode = value.lscCode
    }

    if (value.teamCode) {
      theData.newTeamCode = value.teamCode
    }

    const clubResponse = await fetch(gen("/api/updateLscAndClubCode"), {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(theData),
    }).then((response) => response.json())

    await updateStateFromApiResponse(clubResponse)
  }

  return (
    <Modal
      visible={isVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={800}
      title="Export Meet Entries"
    >
      <div>
        {loading ? <CenterSpin /> : null}
        <p>
          You are about to download the SD3/SDIF file for your swim club's
          entries for this meet. Please make note of and/or update the
          following:
        </p>
        <ul>
          <li>
            Your club's lsc code is:{" "}
            <InlineTextEdit
              updateTheValue={(val: string) => {
                let newValue = val.trim().slice(0, 2).toUpperCase()
                setLsc(newValue)
              }}
            >
              {lsc}
            </InlineTextEdit>
          </li>
          <li>
            Your club's team code is:{" "}
            <InlineTextEdit updateTheValue={setTeamCode}>
              {teamCode}
            </InlineTextEdit>
          </li>
        </ul>
        {unknownSwimmerCount > 0 ? (
          <React.Fragment>
            <br />
            <p>
              You have unknown swimmers in your meet entries. These swimmers
              will not be exported in your sd3 file. We recommend that you go to
              your meet entries and update them to the correct swimmers before
              exporting.
            </p>
          </React.Fragment>
        ) : null}
        <br />
        <p>Additionally, please note the following for accuracy:</p>
        <p>
          The SD3/SDIF file uses a universally generated USA Swimming ID per
          swimmer based on the following info:
        </p>
        <p>
          <b> Example : (11/03/1987) Charles G Houchin: 110387CHAGHOUC</b>{" "}
          <br />
          --DoB: [MMDDYY] --First three letters of your legal first name --First
          letter of your middle name --First four letters of your last name. If
          your first or last names don't have enough letters [or you don't have
          a middle name or middle initial], an * [asterisk] will be used to fill
          in the blanks.
        </p>
        <p>
          For your convenience, the Swimmingly Clubhouse automatically does this
          for you (based on your swimmer info under 'Manage Roster') upon
          exporting this file. Some other team management systems allow users to
          manually change this ID. To be compatible, we recommend you maintain
          the automatically generated ID in the system to which you're
          attempting to import this file.
        </p>
        <p>
          Note: the USA Swimming ID described above is not related to each
          Swimmer ID used on the app or in the Clubhouse
        </p>
        <p>
          LSC and Club Codes, editable under your 'Manage Club' tab must match
          the same codes you have in your team management system in which you
          wish to import. Hint: LSC code is usually your state's abbreviation
          and Club code is usually your club's 4 letter abbreviation. If you are
          trying to map to an existing swimmer in a separate team management
          system that contains the middle initial for each swimmer, you must
          include that information for each swimmer under 'Manage Roster'.
          Otherwise, a new swimmer will be created. Double check the file
          extension when you download this file (*.SD3). If it does not end with
          this, use a different web browser (we recommend Chrome).
        </p>
      </div>
    </Modal>
  )
}

export default ExportEntriesModal
