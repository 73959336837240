import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import ParentMeetDetailsModal from "./ParentMeetDetailsModal";
import { IClub, IMeet } from "../utils";
import ParentMeetCardDisplay from "./ParentMeetCardDisplay";
import styled from "styled-components";
import { screenSizes } from "../../styles/GlobalStyles";
import moment from "moment";
import { Card } from "antd";

export const StyledParentMeetDisplay = styled.div`
  .swim-meets {
    margin-left: 0px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    column-gap: 20px;
    row-gap: 25px;
  }
  

  .swim-meet {
    box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
  }

  h2.season-title {
    color: var(--darkgrey);
    display: flex;
  }

  h2.season-title button {
    align-self: center;
  }

  @media (max-width: ${screenSizes.medium}px) {
    .swim-meets {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  @media (max-width: ${screenSizes.small}px) {
    .swim-meets {
      grid-template-columns: 1fr 1fr;
    }
    .empty-image {
      margin-left: 20px;
    }
  }

  @media (max-width: ${screenSizes.extraSmall}px) {
    .swim-meets {
      display: flex;
      flex-direction: column-reverse;
    }
    .empty-image {
      margin-left: 20px;
    }
  }
`

interface IEventsGroupedByYear {
    [key: string]: IParentsMeet[]
}

export interface IParentsMeet extends IMeet {
    hasResultsFile: JSX.Element;
    club_id: any;
    clubIdsForClubsInMeet: number[]
    locationInfo: string | null
    meetDeclarationsCloseDate: string | null
    meetDeclarationsOpen: boolean
    meetName: string
}

interface IParentsMeetScheduleInner {
    eventsGroupedByYear: IEventsGroupedByYear
    clubsByClubId: { [key: string]: IClub }
    guardianClubIds: number[]
}


export default function ParentMeetScheduleInner({ eventsGroupedByYear, clubsByClubId, guardianClubIds }: IParentsMeetScheduleInner): JSX.Element {
    const [seasonExpanded, setSeasonExpanded] = useState(`${moment().year()}`)
    const [parentMeetDetailsModalVisible, setParentMeetDetailsModalVisible] = useState(false)
    const [meetToView, setMeetToView] = useState<IParentsMeet | null>(null)
    const [clubsToView, setClubsToView] = useState<IClub[]>([])
    const [triggerRefresh, setTriggerRefresh] = useState(0)

    useEffect(() => {
    }, [eventsGroupedByYear])


    const years = Object.keys(eventsGroupedByYear)
    years.sort()
    years.reverse()


    function SeasonHeader({
        year,
        eventsGroupedByYear
    }: {
        year: string,
        eventsGroupedByYear: IEventsGroupedByYear
    }): JSX.Element {

        const meetsForCurrentYear = eventsGroupedByYear[year]
        const meetCards = meetsForCurrentYear.map((meet: IParentsMeet) => {
            const clubs = meet.clubIdsForClubsInMeet.map((clubId) =>
                clubsByClubId[`${clubId}`]
            )
                .filter((club) => Boolean(club))
          const guardianClubs = guardianClubIds.map(clubId => clubsByClubId[clubId])
            .filter(club => club != null)
            return (

                <ParentMeetCardDisplay
                    meet={meet}
                    clubs={clubs}
                guardianClubs={guardianClubs}
                    setParentMeetDetailsModalVisible={setParentMeetDetailsModalVisible}
                    setMeetToView={setMeetToView}
                    setClubsToView={setClubsToView}
                />
            )
        })

        return (              
              <StyledParentMeetDisplay>
                <h3 className="season-title">                        
                        {seasonExpanded == year ? (
                            <DownOutlined
                                style={{ color: "var(--linkblue)", cursor: "pointer" }}
                                onClick={() => setSeasonExpanded('')}
                            />
                        ) : (
                            <RightOutlined
                                style={{ color: "var(--linkblue)", cursor: "pointer" }}
                                onClick={() => setSeasonExpanded(year)}
                            />
                        )}
                    {" "}{year} Swim Season
                    </h3>
                    {
                    seasonExpanded == year &&
                    <Card bordered={false}
                    > {meetCards} </Card>
                    }
                </StyledParentMeetDisplay>
        
        )
    }

    return (
        <div>
            <StyledParentMeetDisplay>
                {
                    years.map((year, index) => {
                      const header = <SeasonHeader year={year} eventsGroupedByYear={eventsGroupedByYear} key={`year_${year}_${index}`}/>
                        return [header].flat()
                    })
                }
                <ParentMeetDetailsModal
                    meet={meetToView}
                    visible={parentMeetDetailsModalVisible}
                    setVisible={setParentMeetDetailsModalVisible}
                    clubs={clubsToView}
                    refreshData={() => {
                        setTriggerRefresh(triggerRefresh + 1)
                    }}
                />
            </StyledParentMeetDisplay>
        </div>
    )


}

