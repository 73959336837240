import { FunctionComponent, useEffect, useState } from "react"
import { Alert, Button, Drawer, List, Space, Tag, Tooltip } from "antd"
import { LoadingOutlined, MessageOutlined, PlusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import { DateFormats, IAnnouncement, IUser, RoleHelper, useScreenSize } from "./utils"
import { Colors } from "./ClubSeasonManagement/styles"
import { AnnouncementHelper } from "./Helpers/AnnouncementHelper"
import moment from "moment"

type MessageHeaderProps  = {
    sender: string
    timestamp: moment.Moment
  }
  const MessageHeader: FunctionComponent<MessageHeaderProps>  = ({sender, timestamp}: MessageHeaderProps) => {
    const antDesignHalfWidth = 12
    return (<Space size={[antDesignHalfWidth,antDesignHalfWidth]} wrap>
        <div>{sender}</div>
        <div>{timestamp.format(DateFormats.monthDayYearTimestamp)}</div>
    </Space>)
  }

interface IClubCommunicationDrawerProps {
    visible: boolean
    user: IUser | null
    setVisible: (value: boolean) => void
    setMessageWindowVisible: (value: boolean) => void
}

export default function ClubCommunicationDrawer({
    visible,
    setVisible,
    setMessageWindowVisible,
    user
}: IClubCommunicationDrawerProps) {
    const [triggerRefresh, setTriggerRefresh] = useState(0)
    const [loading, setLoading] = useState(false)
    const [announcements, setAnnouncements] = useState<IAnnouncement[]>([])

    useEffect(() => {
        if (user && visible) {
            setLoading(true)
            new AnnouncementHelper()
                .getAnnouncements(user.userId)
                .then(( announcementResult ) => {
                    setLoading(false)
                    setAnnouncements(announcementResult)
                })
        }

     }, [user, visible])

    const roleHelper = new RoleHelper(user?.role)
    const screenSize = useScreenSize()
    const isMobile: boolean = screenSize === "small"
    
    return (
        <Drawer
            visible={visible}
            title= 
            {(roleHelper.isClubAdmin() || roleHelper.isLeagueAdmin()) ?
                
                (
                    <Tooltip
                        title="Create a new announcement for all guardians & admins on your club to view here . They will also receive an email notification."
                    >
                    <Button
                        type="link"
                        icon={<PlusCircleOutlined />}
                        size="large"
                        onClick={() => {
                            setMessageWindowVisible(true)
                            setVisible(false)
                        }
                        }
                    >
                        New Announcement
                    </Button>
                    </Tooltip>
                )
                
                : null
            }
            width={isMobile ? "75%" : 500}
            height={isMobile ? "92%" : 500}
            placement={isMobile ? "bottom" : "right"}
            onClose={() => {
                setLoading(false)
                setVisible(false)
                setTriggerRefresh(triggerRefresh + 1)
            }}
        >

            <div id="scrollableDiv">
                <div style={{textAlign:'center'}}>
                    <LoadingOutlined hidden={!loading} spin={true} />
                </div>
                <List
                    header={<Tooltip title="Club leadership will post announcements here. You will receive email notifications when a new announcement is made."><Tag color="#3c8825" icon={<MessageOutlined />}> Club Communication </Tag></Tooltip>}
                    
                    bordered={true}
                    itemLayout="vertical"
                    size="small"
                    dataSource={announcements}
                    renderItem={(announcement) => {
                        return (
                            <List.Item
                                key={announcement.announcementId}
                            >
                                <Tooltip
                                    title={announcement.seasonName}>
                                <List.Item.Meta
                                    title={<Tag color="geekblue">{<MessageHeader sender={announcement.senderName} timestamp={moment.tz(announcement.created_at, "UTC").local()} />}{<br />}{new RoleHelper(announcement.role).roleName()} </Tag>}
                                />
                                    <p>
                                    {announcement.announcement}
                                    </p>
                                </Tooltip>
                                
                            </List.Item>
                        )
                    }}
                />
            </div>


        </Drawer>
    )
}


