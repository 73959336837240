import styled from "styled-components"
import { screenSizes } from "../../styles/GlobalStyles"

export const EventFormStyle = styled.div`
  background-color: var(--snow);
  margin-bottom: 25px;
  padding-top: 25px;
  padding-bottom: 25px;

  label {
    margin-top: 15px;
  }

  input {
    height: 40px;
    border-radius: 5px;
    /* border: 1px solid lightgray; */
  }

  .leaderboard-mode-selector {
    width: 100%;
    margin-bottom: 15px;
  }

  .leaderboard-mode-option {
    width: 100%;
  }

  .event-form-container {
    margin: auto;
    width: 47%;
    min-width: 275px;
  }

  .event-form,
  .age-range {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 48%;
  }

  .event-form {
    column-gap: 10px;
  }

  .reset-button {
    background-color: var(--buttonblue);
    color: white;
    border-radius: 3px;
    border: none;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  .reset-button.small-screen {
    margin-top: 10px;
  }

  @media (min-width: ${screenSizes.medium + 1}px) {
    .reset-button.small-screen {
      display: none;
    }
  }

  @media (max-width: ${screenSizes.medium}px) {
    h2,
    h4,
    p {
      text-align: center;
    }

    .reset-button.large-screen {
      display: none;
    }

    .event-form {
      display: flex;
      flex-direction: column;
    }

    .event-form {
      max-width: 90%;
    }
  }

  hr {
    margin-bottom: 35px;
  }
`
