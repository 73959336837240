import React, { useState } from "react"

export default function HoverListItem(props) {
  const [isHovering, setHovering] = useState(false)

  return (
    <div
      style={isHovering ? { backgroundColor: "#e6f7ff" } : {}}
      onMouseOver={() => setHovering(true)}
      onMouseOut={() => setHovering(false)}
    >
      {props.children}
    </div>
  )
}
