import React from "react"
import $ from "jquery"
import { Link } from "react-router-dom"
import CenterSpin from "./CenterSpin"
import { Table, Button, Input } from "antd"
import { toLowerN, gen } from "./utils"
import ActionAlerts from "./ActionAlerts"
import { EditOutlined } from "@ant-design/icons"

export default class AllLeagues extends React.Component {
  state = {
    leaguesArr: [],
    showPay: false,
    searchText: "",
  }

  sortLeagues = (a, b) => {
    if (a.name > b.name) {
      return 1
    }
    if (a.name < b.name) {
      return -1
    }
    return 0
  }

  componentDidMount() {
    $.ajax({
      url: gen(`/api/manageLeagueDataPost`),
      method: "POST",
      dataType: "json",
      data: {
        userId: this.props.user.userId,
      },
    }).done((data) => {
      data.leaguesArr.sort(this.sortLeagues)
      // console.log(data)
      this.setState({ leaguesArr: data.leaguesArr, showPay: data.showPay })
    })
  }

  render() {
    let allLeagues = null

    if (this.state.leaguesArr.length === 0) {
      allLeagues = (
        <section className="panel">
          <header className="panel-heading">
            <h2 className="panel-title">Leagues</h2>
          </header>
          <div className="ourpanel-body">
            <CenterSpin />
          </div>
        </section>
      )
    } else {
      let curLeagues = this.state.leaguesArr

      if (this.state.value) {
        curLeagues = this.state.leaguesArr.filter((el) => {
          return toLowerN(el.name).includes(toLowerN(this.state.value))
        })
      }

      allLeagues = (
        <React.Fragment>
          <ActionAlerts />
          <section className="panel">
            <header className="panel-heading">
              <h2 className="panel-title">
                Leagues{" "}
                <span style={{ fontSize: "16px" }}>
                  ({this.state.leaguesArr.length} Leagues)
                </span>
              </h2>
            </header>

            <div className="ourpanel-body">
              {this.props.user.role === 2 && (
                <div>
                  <Button type="primary" style={{ marginBottom: "10px" }}>
                    <Link to="/app/admin/add_league">Add New League</Link>
                  </Button>
                  <br />
                </div>
              )}
              {/*this.props.user.role === 3 && this.state.showPay && (
              <div>
                <div className="form-group ">
                  <Link to="/admin/leaguePay">
                    Pay For Leagues
                  </Link>
                </div>
                <br />
              </div>
            )*/}
              <Input
                type="text"
                value={this.state.value}
                onChange={(e) => this.setState({ value: e.target.value })}
                style={{ marginBottom: "10px", width: "200px" }}
                placeholder="Search"
              />
              <Table
                bordered
                size="middle"
                dataSource={curLeagues}
                rowKey={(league) => league.id}
                rowClassName={(league) => {
                  if (
                    !league.age_cliffs ||
                    !league.cut_off_day ||
                    !league.cut_off_month ||
                    !league.cut_off_year ||
                    league.totalEvents <= 1
                  ) {
                    return "absolutelyOrange"
                  }
                  return ""
                }}
                columns={[
                  {
                    title: "League Name",
                    dataIndex: "name",
                    key: "name",
                  },
                  {
                    title: "State",
                    dataIndex: "StateID",
                    key: "StateID",
                  },
                  {
                    title: "Action",
                    key: "action",
                    render: (league) => (
                      <Link to={`/app/admin/edit_league/${league.id}`}>
                        <EditOutlined />
                      </Link>
                    ),
                  },
                ]}
              />
            </div>
          </section>
        </React.Fragment>
      )
    }

    return (
      <div>
        <header className="page-header">
          <h2>Leagues</h2>
        </header>
        {allLeagues}
      </div>
    )
  }
}
