import React, { useEffect, useState } from "react"
import { MailOutlined } from "@ant-design/icons"
import { Button, Modal, DatePicker, Checkbox, message, Tooltip } from "antd"
import moment, { Moment } from "moment-timezone"
import { gen, swimminglyApi } from "./utils"

function disabledDate(current: Moment): boolean {
  // Can not select days before today and today
  return current && current < moment().endOf("day")
}

export function MeetEntryButtonOpen({
  meetId,
  clubId,
  userId,
  className,
  refreshPage,
}: {
  meetId: number
  clubId: number
  userId: number
  className: string
  refreshPage: () => void
}) {
  const [visible, setVisible] = useState(false)
  const [closeDate, setCloseDate] = useState<string | null>(null)
  const [meetDeclarationsOpen, setMeetDeclarationsOpen] = useState(false)

  const clickOk = () => {
    if (!closeDate || closeDate.trim() === "") {
      message.error("Need to select a date")
      return
    }

    const browserOffset = new Date().getTimezoneOffset()
    const momentCloseDate = moment(closeDate)
      .utcOffset(-browserOffset)
      .tz("UTC")
    let finalCloseDate = ""
    if (momentCloseDate) {
      finalCloseDate = momentCloseDate.format("YYYY-MM-DD HH:mm:ss")
    }
    // console.log({
    //   closeDate: finalCloseDate,
    //   meetDeclarationsOpen: this.state.meetDeclarationsOpen,
    //   meetId: this.props.meetId,
    //   clubId: this.props.clubId,
    //   userId: this.props.userId,
    // })
    swimminglyApi
      .post(gen("/api/openMeetEntry"))
      .body({
        meetDeclarationsCloseDate: finalCloseDate,
        meetDeclarationsOpen,
        meetId,
        clubId,
        userId,
      })
      .then((data) => {
        if (data.error || data.status === "error") {
          console.log(data)
          message.error(data.error)
        } else {
          message.success("Opened meet entries!")
          setVisible(false)
          refreshPage()
        }
      })
  }
  return (
    <React.Fragment>
      <Button
        className={className}
        onClick={() => setVisible(true)}
        key="MeetEntryModal"
        style={{
          display: "grid",
          gridTemplateColumns: "auto auto",
          justifyContent: "center",
          columnGap: "10px",
          alignItems: "center",
          padding: "2px",
          backgroundColor: "#52C41B",
          color: "white",
        }}
      >
        <MailOutlined />
        <div style={{ display: "grid", gridTemplateColumns: "1fr" }}>
          <span style={{ height: "1.2em" }}>Meet Declarations</span>
          <span style={{ height: "1.2em" }}>Open to parents now</span>
        </div>
      </Button>
      <Modal
        title="Open Meet To Parents"
        visible={visible}
        okText="Open to parents and send email"
        // disabledDate={disabledDate}
        onOk={clickOk}
        onCancel={() => setVisible(false)}
      >
        Have parents declare their swimmers for this meet! Open to parent
        guardians on your roster, this will trigger an email to your parents and
        open declarations for this swim meet.
        <br />
        <br />
        Note: Your club leadership retain's final review &amp; approval of
        entries. <br />
        <br />
        <ol>
          <li>
            You will open this meet to declarations below. This will allow
            parents to attend/decline the meet by swimmer in the Clubhouse. You
            will see the status of each swimmer under View Meet Lineup.
          </li>
          <li>
            If you want to also allow parents to enter swimmers in specific
            events, check that feature below. Note that you will retain
            responsibility over entering relays. Parents will see the entry
            limit designated for this swim meet.
          </li>
          <li>
            Once opened, parents will receive an email to complete
            declarations/entries as well as a friendly reminder 48 hours before
            the deadline closes that you set below.
          </li>
          <li>
            We recommend setting a deadline far enough in advance that allows
            your team leadership to make any revisions.
          </li>
        </ol>
        <Checkbox
          checked={meetDeclarationsOpen}
          onChange={(e) => setMeetDeclarationsOpen(e.target.checked)}
        >
          Allow parents to enter their swimmers in individual events
        </Checkbox>
        <br />
        <br />
        <DatePicker
          showTime={{ format: "hh:mm A", minuteStep: 15 }}
          showNow={false}
          placeholder="Select Meet Entry Closing Date Time"
          style={{ width: "400px" }}
          disabledDate={disabledDate}
          value={
            closeDate?.trim() === "" || closeDate === null
              ? null
              : moment(closeDate)
          }
          format="MM/DD/YYYY hh:mm A"
          onChange={(val) => setCloseDate(val?.format() || null)}
        />
      </Modal>
    </React.Fragment>
  )
}

export function MeetEntryButtonInProgress({
  meetDeclarationsCloseDate,
  refreshPage,
  className,
  meetId,
  clubId,
  userId,
  parentEntries,
}: {
  meetDeclarationsCloseDate: string
  refreshPage: () => void
  className: string
  meetId: number
  clubId: number
  userId: number
  parentEntries: boolean
}) {
  const [visible, setVisible] = useState(false)
  const [closeDate, setCloseDate] = useState(
    meetDeclarationsCloseDate.trim() === ""
      ? null
      : moment(meetDeclarationsCloseDate),
  )
  const [meetDeclarationsOpen, setMeetDeclarationsOpen] =
    useState(parentEntries)

  useEffect(() => {
    setMeetDeclarationsOpen(parentEntries)
  }, [meetId, clubId, parentEntries])

  useEffect(() => {
    setCloseDate(
      meetDeclarationsCloseDate.trim() === ""
        ? null
        : moment(meetDeclarationsCloseDate),
    )
  }, [meetDeclarationsCloseDate])

  const clickOk = () => {
    if (!closeDate) {
      message.error("Need to select a date")
      return
    }

    const browserOffset = new Date().getTimezoneOffset()
    const momentCloseDate = moment(closeDate)
      .utcOffset(-browserOffset)
      .tz("UTC")
    let finalCloseDate = ""
    if (momentCloseDate) {
      finalCloseDate = momentCloseDate.format("YYYY-MM-DD HH:mm:ss")
    }

    swimminglyApi
      .post(gen("/api/inProgressMeetEntry"))
      .body({
        closeDate: finalCloseDate,
        meetDeclarationsOpen,
        meetId,
        clubId,
        userId,
      })
      .then((data) => {
        if (data.error) {
          message.error(data.error)
        } else {
          setVisible(false)
          refreshPage()
        }
      })
  }

  return (
    <React.Fragment>
      <Button
        className={className}
        onClick={() => setVisible(true)}
        key="MeetEntryModal"
        style={{
          display: "grid",
          gridTemplateColumns: "auto auto",
          justifyContent: "center",
          columnGap: "10px",
          alignItems: "center",
          padding: "2px",
          backgroundColor: "#FA8B14",
          color: "white",
        }}
      >
        <MailOutlined />
        <Tooltip
          title="As an admin, you can continue to make revisions to entries even after the deadline has closed."
          color="#101242"
        >
        <div style={{ display: "grid", gridTemplateColumns: "1fr" }}>
          <span style={{ height: "1.2em" }}>Meet Declarations Deadline</span>
          <span style={{ height: "1.2em" }}>
            {moment(closeDate).format("M/D h:mm a")}
          </span>
        </div>
        </Tooltip>
      </Button>
      <Modal
        title="Update Meet Declarations Deadline?"
        visible={visible}
        okText="Update deadline and resend email"
        onOk={clickOk}
        onCancel={() => setVisible(false)}
      >
        Updating your meet declarations deadline will not interrupt the meet declarations
        currently in progress.
        <br />
        <br />
        Note: Your club leadership retain's final review &amp; approval of
        entries. <br />
        <br />
        <ol>
          <li>
            Meet Declarations (attending/not attending) will be visible under your Entries in your 'By Swimmer' view.
          </li>
          <li>
            Change your mind? You can enable parents to enter swimmers
            directly into invidual events below by clicking 'Allow parents to
            enter their swimmers in individual events'. Note that you will retain
            responsibility over entering relays. Parents will see the entry
            limit designated for this swim meet.
          </li>
          <li>
            Parent/Guardians will receive a friendly email reminder 48 hours before
            the deadline closes that you update below.
          </li>
          <li>
            We recommend setting a deadline far enough in advance that allows
            your club leadership to make any revisions.
          </li>
        </ol>
        <br />
        <Checkbox
          checked={meetDeclarationsOpen}
          onChange={(e) => setMeetDeclarationsOpen(e.target.checked)}
        >
          Allow parents to enter their swimmers in individual events
        </Checkbox>
        <br />
        <DatePicker
          showTime={{ format: "HH:mm a", minuteStep: 15 }}
          showNow={false}
          placeholder="Select Meet Entry Closing Date Time"
          style={{ width: "400px" }}
          disabledDate={disabledDate}
          value={closeDate}
          format="MM/DD/YYYY hh:mm A"
          onChange={(val) => setCloseDate(val)}
        />
      </Modal>
    </React.Fragment>
  )
}

export function MeetEntryButtonClosed({
  meetId,
  clubId,
  className,
  refreshPage,
}: {
  meetId: number
  clubId: number
  className: string
  refreshPage: () => void
}): JSX.Element {
  const [visible, setVisible] = useState(false)
  const [numClicks, setNumClicks] = useState(0)
  const [closeDate, setCloseDate] = useState<string | null>(null)

  useEffect(() => {
    setNumClicks(0)
  }, [visible])

  const clickOk = () => {
    if (numClicks === 0) {
      setNumClicks(numClicks + 1)
      return
    } else if (closeDate === null || closeDate === "") {
      message.error("Must choose a date")
      return
    }

    swimminglyApi
      .post(gen("/api/closedMeetEntry"))
      .body({ meetId, clubId, meetDeclarationsCloseDate: closeDate })
      .then((data) => {
        if (data.status === "success") {
          message.success("reopened meet entries!")
        } else {
          message.error("uh oh, something went wrong reopening meet entries")
        }
      })
      .finally(() => {
        setVisible(false)
        refreshPage()
      })
  }
  return (
    <React.Fragment>
      <Button
        className={className}
        onClick={() => setVisible(true)}
        key="MeetEntryModal"
        style={{
          display: "grid",
          gridTemplateColumns: "auto auto",
          justifyContent: "center",
          columnGap: "10px",
          alignItems: "center",
          padding: "2px",
          backgroundColor: "#8898aa",
          color: "white",
        }}
      >
        <MailOutlined />
        <Tooltip
          title="As an admin, you can continue to make revisions to entries even after the deadline has closed."
          color="#101242"
        >
        <div style={{ display: "grid", gridTemplateColumns: "1fr" }}>
          <span style={{ height: "1.2em" }}>Meet Declarations</span>
          <span style={{ height: "1.2em" }}>Closed to parents</span>
        </div>
        </Tooltip>
      </Button>
      <Modal
        title={
          numClicks === 0
            ? "Meet Declarations are Closed"
            : "Reopen Meet Declarations?"
        }
        visible={visible}
        okText={
          numClicks === 0
            ? "Reopen Declarations"
            : "Confirm Reopening Declarations"
        }
        onOk={clickOk}
        onCancel={() => setVisible(false)}
      >
        {numClicks === 0 ? (
          <React.Fragment>
            Meet declarations &amp; entries are now closed to parents for this
            meet. As a club admin, you can still revise entries as desired.
            <br />
            <br />
            <ul>
              <li>
                To see the status of each swimmer for this meet, navigate to
                your entries 'By Swimmer' view.
              </li>
              <li>
                Swimmers attending the meet will have a check next to their name
              </li>
              <li>
                Swimmers not attending the meet will be struck through and at
                the bottom of the roster
              </li>
              <li>
                Swimmers who have not been declared will not have a check.
              </li>
              <li>
                If you enabled parents to directly enter their swimmers in individual
                events, you will see those swimmers entered!
              </li>
            </ul>
          </React.Fragment>
        ) : (
          <React.Fragment>
            Meet declarations &amp; entries are now closed to parents for this
            meet. If you would like to reopen them select when you'd like them
            to close and then click the "Confirm Reopening Declarations" button.
            <br />
            <br />
            <DatePicker
              showTime={{ format: "hh:mm A", minuteStep: 15 }}
              showNow={false}
              placeholder="Select Meet Entry Closing Date Time"
              style={{ width: "400px" }}
              disabledDate={disabledDate}
              value={
                closeDate?.trim() === "" || closeDate === null
                  ? null
                  : moment(closeDate)
              }
              format="MM/DD/YYYY hh:mm A"
              onChange={(val) => setCloseDate(val?.format() || null)}
            />
          </React.Fragment>
        )}
        <br />
      </Modal>
    </React.Fragment>
  )
}

export default function MeetEntryButton({
  refreshPage,
  meetId,
  clubId,
  userId,
  className,
}: {
  refreshPage: () => void
  meetId: number
  clubId: number
  userId: number
  className: string
}) {
  const [meetDeclarationsCloseDate, setMeetDeclarationsCloseDate] =
    useState(null)
  const [status, setStatus] = useState("closed")
  const [triggerRefresh, setTriggerRefresh] = useState(0)
  const [parentEntries, setParentEntries] = useState(false)

  const refreshData = () => {
    refreshPage()
    setTriggerRefresh(triggerRefresh + 1)
  }

  useEffect(() => {
    swimminglyApi
      .get(
        gen(
          `/api/getMeetDeclarationsCloseDate/meetId=${meetId}/clubId=${clubId}`,
        ),
      )
      .then((data) => {
        setMeetDeclarationsCloseDate(data.meetDeclarationsCloseDate || null)
        setParentEntries(data.parentEntries)
      })
  }, [meetId, clubId, triggerRefresh])

  useEffect(() => {
    let newStatus = "open"
    if (meetDeclarationsCloseDate) {
      if (moment() < moment(meetDeclarationsCloseDate)) {
        newStatus = "inProgress"
      } else {
        newStatus = "closed"
      }
    }
    setStatus(newStatus)
  }, [meetDeclarationsCloseDate])

  let browserOffset = new Date().getTimezoneOffset()
  if (status === "open") {
    return (
      <MeetEntryButtonOpen
        className={className}
        meetId={meetId}
        refreshPage={refreshData}
        clubId={clubId}
        userId={userId}
      />
    )
  }
  if (status === "inProgress") {
    return (
      <MeetEntryButtonInProgress
        className={className}
        meetId={meetId}
        meetDeclarationsCloseDate={moment(meetDeclarationsCloseDate)
          .utcOffset(-browserOffset)
          .format()}
        refreshPage={refreshData}
        clubId={clubId}
        userId={userId}
        parentEntries={parentEntries}
      />
    )
  }
  if (status === "closed") {
    return (
      <MeetEntryButtonClosed
        className={className}
        meetId={meetId}
        refreshPage={refreshData}
        clubId={clubId}
      />
    )
  }
  return null
}
