import React from "react"
import { InputNumber, Button } from "antd"
import { MinusOutlined } from "@ant-design/icons"

interface ISingleRankProps {
  points: number
  place: number
  changePoints: (points: number) => void
  changePlace: (place: number) => void
  remove: () => void
}
function SingleRank({
  points,
  place,
  changePoints,
  changePlace,
  remove,
}: ISingleRankProps) {
  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: "20%" }}>
        <Button onClick={remove} shape="circle" icon={<MinusOutlined />} />
      </div>
      <div style={{ width: "100px" }}>
        <InputNumber
          min={1}
          value={place}
          onChange={(value) => {
            if (typeof value === "string") {
              changePlace(parseInt(value))
            } else if (typeof value === "number") {
              changePlace(value)
            } else if (value === null) {
              changePlace(0)
            }
          }}
        />
      </div>
      <div style={{ width: "100px" }}>
        <InputNumber
          min={1}
          value={points}
          onChange={(value) => {
            if (typeof value === "string") {
              changePoints(parseInt(value))
            } else if (typeof value === "number") {
              changePoints(value)
            } else if (value === null) {
              changePoints(0)
            }
          }}
        />
      </div>
    </div>
  )
}

export default SingleRank
