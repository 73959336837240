import React, { useState } from "react"
import { Modal, Button, Input, Tooltip } from "antd"
import { gen } from "./utils"
import { QuestionCircleOutlined } from "@ant-design/icons"

interface IAddEventModalProps {
  league?: {
    id: number
  }
  club?: {
    clubId: number
  }
  getEventOrders: () => Promise<void>
}

export default function AddEventOrderModal({
  league,
  club,
  getEventOrders,
}: IAddEventModalProps) {
  const [visible, setVisible] = useState(false)
  const [eventOrderName, setEventOrderName] = useState("")

  const closeModal = () => {
    saveCustomEventOrder()
    setVisible(false)
  }

  const saveCustomEventOrder = () => {
    let requestBody: {
      clubId?: number
      league_id?: number
      eventOrderName: string
    } = { eventOrderName }

    if (club) {
      requestBody.clubId = club.clubId
    } else if (league) {
      requestBody.league_id = league.id
    }

    if (eventOrderName !== "") {
      fetch(gen("/api/postEventsOrdersPost"), {
        method: "post",
        headers: { "Content-Type": "application/json" },
        credentials: "same-origin",
        body: JSON.stringify(requestBody),
      })
        .then((response) => response.json())
        .then((data) => {
          getEventOrders()
        })
    }
  }

  return (
    <div>
      <Button type="primary" onClick={() => setVisible(true)}>
        <b>Create Brand New Event Order</b>
      </Button>
      <Modal
        title={
          <span>
            Create New Event Order&nbsp;
            <Tooltip title="This is the name visible to users when creating a swim meet. The name you choose here will correspond to the applicable event order they will run in their swim meet.">
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        }
        visible={visible}
        onOk={closeModal}
        onCancel={() => setVisible(false)}
        okText="Create Event Order"
      >
        <div className="form-group mt-lg">
          <label className="col-sm-3">Event Order</label>
          <div className="col-sm-9">
            <Input
              type="text"
              placeholder="Dual Meet Event Order..."
              required
              value={eventOrderName}
              onChange={(e) => setEventOrderName(e.target.value)}
            />
          </div>
        </div>
      </Modal>
    </div>
  )
}
