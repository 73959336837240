import React, { useState, useEffect, useRef } from "react"
import { Modal, Input, } from "antd"
import styled from "styled-components"
import { useScreenSize, IUser } from "./utils"
import Sidebar from "./Sidebar"
import EditUserProfile from "./EditUserProfile"
import { screenSizes } from "../styles/GlobalStyles"
import AppHeader from "./AppHeader"
import { ClubSeasonManagementContextProvider } from "./ClubSeasonManagement/sharedState"
import ClubCommunicationDrawer from "./ClubCommunicationDrawer"
import NewAnnouncementWindow from "./NewAnnouncementWindow";

const AppBody = styled.div`
  min-height: 100vh;
  width: 100%;

  @media only screen and (max-width: ${screenSizes.small}px) {
    min-height: 0;
    overflow: visible;
  }
`

interface ILayoutProps {
  user: IUser | null
  children: React.ReactNode
}

// export default class DashboardLayout extends React.Component {
export default function DashboardLayout({ user, children }: ILayoutProps) {
  const screenSize = useScreenSize()
  const [editProfileVisible, setEditProfileVisible] = useState<boolean>(false)
  const [clubCommunicationDrawerVisible, setClubCommunicationVisible] = useState<boolean>(false)
  const [messageWindowVisible, setMessageWindowVisible] = useState(false)
  const [userLockout, setUserLockout] = useState(false)

  const isMobile: boolean = screenSize === "small"
  const [isClosed, setIsClosed] = useState<boolean>(isMobile)
  const prevIsMobileRef = useRef(isMobile)


  useEffect(() => {
    if (user) {
      let mapping: { [key: number]: string }
      mapping = {
        2: "HydroAdmin",
        3: "LeagueAdmin",
        4: "ClubAdmin",
        5: "Parent",
      }
      if (window && window.Appcues) {
        window.Appcues.identify(user.email, {
          email: user.email,
          name: user.name,
          role: mapping[user.role],
        })

        let widget = window.AppcuesWidget(window.Appcues.user())
        widget.init("#my-widget")
      }
    }
  }, [user])

  useEffect(() => {
    if (isMobile && !prevIsMobileRef.current && !isClosed) {
      setIsClosed(true)
    } else if (!isMobile && prevIsMobileRef.current && isClosed) {
      setIsClosed(false)
    }
    prevIsMobileRef.current = isClosed
  }, [isMobile, isClosed])


  return (
    //Keith says: i got into a jam by relying on ClubSeasonManagementContextProvider to communicate to this header (and its banner) that the season setup event had occurred
    //This may be a good react pattern (¯\_(ツ)_/¯) but if it is, then we probably want an application wide context provider to manage this particular event and leave the club season management stuff specifically to ClubSeasonManagementContextProvider
    <ClubSeasonManagementContextProvider>
      <AppBody>
        <NewAnnouncementWindow
          messageWindowVisible={messageWindowVisible}
          setMessageWindowVisible={setMessageWindowVisible} 
        />

        <AppHeader
          setEditUserVisible={setEditProfileVisible}
          setClubCommunicationVisible={setClubCommunicationVisible}
          setSidebarClosed={setIsClosed}
          sidebarClosed={isClosed}
        />
        <div
          style={{
            display: "flex",
            paddingTop: isMobile ? "0px" : "60px",
            width: "100%",
            maxWidth: "100%",
            overflow: 'scroll'
          }}
        >
          {user && (
            <Sidebar
              isMobile={isMobile}
              isClosed={isClosed}
              changeClose={setIsClosed}
              setUserLockout={setUserLockout}
            />
          )}

          {user && <EditUserProfile visible={editProfileVisible} setVisible={setEditProfileVisible} />}
          {user && <ClubCommunicationDrawer visible={clubCommunicationDrawerVisible} setVisible={setClubCommunicationVisible} user={user} setMessageWindowVisible={setMessageWindowVisible} />}

          <section
            className="content-body"
            style={{
              backgroundColor: "#ecedf0",
              width: "100%",
              maxWidth: "100%",
            }}
          >
            {children}
          </section>
        </div>
        <Modal
          title="Cannot access"
          visible={userLockout}
          onOk={() => setUserLockout(false)}
          onCancel={() => setUserLockout(false)}
        >
          <div className="form-group mt-lg">
            You cannot access this tab until you have been approved by a user with
            appropriate credentials.
          </div>
        </Modal>
      </AppBody>
    </ClubSeasonManagementContextProvider>
  )
}
