import { MenuOutlined } from "@ant-design/icons"
import styled from "styled-components"

interface ISidebarProps {
  isClosed: boolean
  [key: string]: any
}

const getSidebarWidth = (isClosed: boolean) => (isClosed ? 73 : 260)
const getSidebarMenuJustify = (isClosed: boolean) =>
  isClosed ? "center" : "space-between"

export const SidebarLeft = styled.aside`
  background: var(--primaryblue);
  color: var(--white) !important;
  display: table-cell;
  position: relative;
  vertical-align: top;
  z-index: 9;
  width: ${(props: ISidebarProps) => getSidebarWidth(props.isClosed)}px;
  height: 100vh;
  .sidebar-menu {
    display: flex;
    justify-content: ${(props: ISidebarProps) =>
      getSidebarMenuJustify(props.isClosed)};
    align-items: center;
  }
`

const getMenuTextDisplay = (isClosed: boolean) =>
  isClosed ? "none" : "inline-block"

export const MenuText = styled.span`
  color: var(--white);
  padding: 15px;
  font-size: 2.8rem;
  display: ${(props: ISidebarProps) => getMenuTextDisplay(props.isClosed)};
`

export const HamburgerMenu = styled(MenuOutlined)`
  color: var(--white);
  padding: 15px;
  font-size: 18px;
`
