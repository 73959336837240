import React, { useState } from "react"
import { FileSearchOutlined } from "@ant-design/icons"
import styled from "styled-components"
import { screenSizes } from "../styles/GlobalStyles"

const SearchIcon = styled(FileSearchOutlined)`
  font-size: 2rem;
`

const InputForm = styled.form`
  max-width: 400px;
  display: grid;
  grid-template-columns: auto auto;
  gap: 0px;

  .input-icon {
    max-width: 50px;
    border-top: 1px solid var(--darkgrey);
    border-right: 1px solid var(--darkgrey);
    border-bottom: 1px solid var(--darkgrey);
    background-color: var(--mediumgrey);
    border-radius: 0px 3px 3px 0px;
    display: grid;
    justify-content: center;
    height: 40px;
    align-items: center;
    margin-left: 0px;
    padding: 0px;
    cursor: pointer;
  }

  input[type="text"] {
    border-radius: 3px 0px 0px 3px;
    margin-right: 0px;
    margin-bottom: 25px;
    font-size: 17px;
    border-left: 1px solid var(--darkgrey);
    border-top: 1px solid var(--darkgrey);
    border-bottom: 1px solid var(--darkgrey);
    border-right: none;
    height: 40px;
    padding-left: 10px;
  }

  @media (max-width: ${screenSizes.small}px) {
    input[type="text"] {
      display: block;
      text-align: left;
      width: 100%;
      margin: 0px;
      margin-bottom: 15px;
      padding: 14px;
    }
  }
`

interface ISearchInputProps {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSearch?: (val: string) => void
  placeholder?: string
}

export default function SearchInput({
  onChange,
  onSearch,
  placeholder,
}: ISearchInputProps) {
  const [text, setText] = useState("")

  return (
    <InputForm>
      <input
        type="text"
        placeholder={placeholder || "Search..."}
        onChange={(e) => {
          e.persist()
          if (onChange) {
            onChange(e)
          }
          setText(e.target.value)
          return
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault()
          }
          return
        }}
      />
      <div
        className="input-icon"
        onClick={onSearch ? (e) => onSearch(text) : () => null}
      >
        <SearchIcon />
      </div>
    </InputForm>
  )
}
