import { useState, Fragment, useEffect } from "react"
import { Alert, message, Tooltip } from "antd"
import styled from "styled-components"
import moment, { Moment } from "moment-timezone"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../appData/types"
import { screenSizes } from "../styles/GlobalStyles"
import { gen, swimminglyApi } from "./utils"

const StyledButton = styled.button`
  background-color: #101242;
  border-radius: 10px;
  border: 1px white;
  color: white;
`

const StyledParentInfo = styled.div`
  padding: 10px;
  margin-top: 20px;
  border: 1px solid green;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 100px;
  column-gap: 25px;
  row-gap: 10px;

  .underlined {
    text-decoration: underline;
  }

  @media (max-width: ${screenSizes.small}px) {
    grid-template-columns: 1fr;
    .parent-information {
      order: 2;
    }
    ${StyledButton} {
      order: 1;
    }
  }
`

export default function ClubSignUpAlert(): JSX.Element | null {
  const impersonateClub = useStoreState(
    (state: StateMapper<AppDataStore>) => state.aliasedClub,
  )
  const [showMoreInfo, setShowMoreInfo] = useState(false)
  const [signupOpenDate, setSignupOpenDate] = useState<Moment | null>(null)

  useEffect(() => {
    if (impersonateClub?.clubId)
      swimminglyApi
        .get(gen(`/api/getEarliestSignupOpenDate/${impersonateClub.clubId}`))
        .then((data) => {
          if (data.status === "success" && data.earliestSignupOpen) {
            setSignupOpenDate(moment(data.earliestSignupOpen))
          } else {
            setSignupOpenDate(null)
          }
        })
  }, [impersonateClub?.clubId])

  if (signupOpenDate === null) return null

  if (impersonateClub?.secretToken) {
    return (
      <Alert
        message={
          <Fragment>
            <span>
              <b> Club Registration Code </b>for{" "}
              {impersonateClub?.name_long || impersonateClub?.nameLong}
              <br />
              <b>
                Opens to your Families on {signupOpenDate.format("MMM Do")}{" "}
              </b>
              - (If you need to change this date email team@swimmingly.app)
            </span>
            <br />
            Copy these instructions and email to your swim families:
            <br />
            <span>
              <StyledButton onClick={() => setShowMoreInfo(!showMoreInfo)}>
                {showMoreInfo
                  ? "Collapse"
                  : "Show Club Registration Code & Instructions"}
              </StyledButton>{" "}
            </span>
            {showMoreInfo && (
              <StyledParentInfo>
                <div className="parent-information">
                  <p>
                    <b>Registration & Sign-Up Instructions</b> - an email to our
                    Swim Families
                    <br />
                    <b>Who:</b>{" "}
                    {impersonateClub?.name_long || impersonateClub?.nameLong}{" "}
                    Parent/Guardians
                    <br />
                    <b>When:</b> Season Registration & Sign-Ups open on{" "}
                    {signupOpenDate.format("MMM Do")}
                    <br />
                    <b>Why:</b> Parents/Guardians, once you register & sign-up
                    your swimmer(s) using the instructions below, they'll
                    automatically populate on the roster and become active for
                    the season! Per the Swimmingly® Child Protection Policy, as
                    a guardian, you will have oversight of your swimmer(s) and
                    can invite other parents/guardians/grandparents that you
                    wish to affiliate with your swimmers in the Swimmingly®
                    Clubhouse!
                    <br />
                    You must register & sign-up your swimmer(s) using these
                    instructions to participate in the season.
                  </p>
                  <p>
                    <i>
                      The Swimmingly® Clubhouse will be your home for your
                      swimmer results, meet declarations, time improvements,
                      best times, and MORE!
                    </i>
                  </p>
                  <p>
                    <i>
                      The steps below are required for ALL (new and returning)
                      swimmers to swim in meets this season. Please take action
                      ASAP so we can get ready for the swim season!
                    </i>
                  </p>
                  <p>
                    <b>
                      {impersonateClub?.name_long || impersonateClub?.nameLong}{" "}
                      - Club Registration Code
                    </b>
                    <br />
                    <Tooltip title="Click to copy Club Registration Code">
                      <span
                        onClick={() => {
                          navigator.clipboard.writeText(
                            impersonateClub?.secretToken || "",
                          )
                          message.success("Club Registration Code copied!")
                        }}
                        style={{
                          backgroundColor: "#101242",
                          color: "white",
                          cursor: "pointer",
                        }}
                      >
                        {impersonateClub.secretToken}
                      </span>
                    </Tooltip>{" "}
                    <br />
                    <a href={`/clubs/register/${impersonateClub.secretToken}`}>
                      https://clubhouse.swimmingly.app/clubs/register/
                      {impersonateClub.secretToken}
                    </a>{" "}
                    (Direct URL for{" "}
                    {impersonateClub?.name_long || impersonateClub?.nameLong}{" "}
                    Club Registration Code)
                    <br /> <br />
                    <b> Returning Parents with an active Swimmingly® account</b>
                    <br />
                    Go{" "}
                    <a href="https://support.swimmingly.app/returning-parent-register">
                      HERE
                    </a>{" "}
                    for step-by-step instructions to register & sign-up your
                    swimmer(s) using the Club Registration Code.
                    <br />
                    https://support.swimmingly.app/returning-parent-register
                    (Direct link)
                    <br />
                    <br />
                    <b> New Parents without an active Swimmingly® account</b>
                    <br />
                    Go{" "}
                    <a href="https://support.swimmingly.app/new-parent-register">
                      HERE
                    </a>{" "}
                    for step-by-step instructions to register & sign-up your
                    swimmer(s) using the Club Registration Code.
                    <br />
                    https://support.swimmingly.app/new-parent-register (Direct
                    link)
                  </p>
                </div>
                <StyledButton
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `Registration & Sign-Up Instructions - an email to our Swim Families

Who:     ${
                        impersonateClub?.name_long || impersonateClub?.nameLong
                      } Parent/Guardians
When:   Season Registration & Sign-Ups open on ${" "}${signupOpenDate.format(
                        "MMM Do",
                      )}
Why:     Parents/Guardians, once you register & sign-up your swimmer(s) using the instructions below, they'll
automatically populate on the roster and become active for the season! Per the Swimmingly® Child Protection Policy, as
a guardian, you will have oversight of your swimmer(s) and can invite other parents/guardians/grandparents that you
wish to affiliate with your swimmers in the Swimmingly® Clubhouse!
                    
You must register & sign-up your swimmer(s) using these instructions to participate in the season.
                
The Swimmingly® Clubhouse will be your home for your swimmer results, meet declarations, time improvements, best times, and MORE!
               
The steps below are required for ALL (new and returning) swimmers to swim in meets this season. Please take action ASAP so we can get ready for the swim season!

${impersonateClub?.name_long || impersonateClub?.nameLong}
Club Registration Code
${impersonateClub.secretToken}
https://clubhouse.swimmingly.app/clubs/register/${
                        impersonateClub.secretToken
                      } (Direct URL link)


Returning Parents with an active Swimmingly® account
Go here for step-by-step instructions to register & sign-up your swimmer(s) using the Club Registration Code:
https://support.swimmingly.app/returning-parent-register (Direct link)
                    
New Parents without an active Swimmingly® account
Go for step-by-step instructions to register & sign-up your swimmer(s) using the Club Registration Code:
https://support.swimmingly.app/new-parent-register (Direct link)`,
                    )
                    message.success(
                      "Club Registration Code instructions copied!",
                    )
                  }}
                >
                  Click to Copy Instructions
                </StyledButton>
              </StyledParentInfo>
            )}
          </Fragment>
        }
        type="success"
      />
    )
  } else {
    return null
  }
}
