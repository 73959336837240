import React, { useState, useEffect } from "react"
import { Table, Button, message, Switch } from "antd"
import { fuzzyRank, gen, IClubSeason, useScreenSize } from "./utils"
import GenericModal from "./GenericModal"
import { screenSizes } from "../styles/GlobalStyles"
import styled from "styled-components"
import { CheckOutlined, DeleteTwoTone } from "@ant-design/icons"
import SearchInput from "./SearchInput"
import { debounce } from "lodash"

interface IClubsInfoRow {
  clubId: number
  leagueId: number | null
  clubNameLong: string
  clubName: string
  league: string | null
  paymentOrganization: string | null
  organizationType: string | null
  canImportEntries: number | null
  canExportEntries: number | null
  canImportResults: number | null
  seasonType: string | null
  isSwimminglyCustomer: number
  key?: string
}

const SeasonsTable = styled.table`
  /* border-collapse: collapse; */
  width: 100%;

  td,
  th {
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: var(--primaryblue);
    color: white;
  }
`

export default function AdvancedClubManagement() {
  const [clubsInfo, setClubsInfo] = useState<Array<IClubsInfoRow>>([])
  const [selectedClub, setSelectedClub] = useState<IClubsInfoRow | null>(null)
  const [modalVisible, setModalVisible] = useState(false)
  const [clubSeasonsList, setClubSeasonsList] = useState<Array<IClubSeason>>([])
  const [searchText, setSearchText] = useState("")
  const [paginationCurrent, setPaginationCurrent] = useState<number>(1)
  const screenSize = useScreenSize()

  useEffect(() => {
    fetch(gen("/api/getBasicInfoForAllClubs"), {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "post",
      body: "",
    })
      .then((response) => response.json())
      .then((data: { status: string; clubsInfo: Array<IClubsInfoRow> }) => {
        let theClubsInfo = data.clubsInfo.map((row, idx) => {
          return { ...row, key: `club_row_${idx}` }
        })
        setClubsInfo(theClubsInfo)
      })
  }, [])

  const getSeasonData = (clubId: number) => {
    fetch(gen("/api/getAllSeasonsForClub"), {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify({
        clubId: clubId,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response
            .json()
            .then(
              (data: { status: string; clubSeasons: Array<IClubSeason> }) =>
                data.clubSeasons,
            )
        } else {
          message.error("Problem getting club's season info")
          return
        }
      })
      .then((clubSeasons) => {
        if (clubSeasons) {
          setClubSeasonsList(clubSeasons)
        } else {
          setClubSeasonsList([])
        }
      })
  }

  useEffect(() => {
    if (!modalVisible) {
      setClubSeasonsList([])
    }
  }, [modalVisible])

  let selectClub = (clubInfo: IClubsInfoRow) => {
    setSelectedClub(clubInfo)
    getSeasonData(clubInfo.clubId)
    setModalVisible(true)
  }

  const updateIsSwimminglyCustomer = (
    checked: boolean,
    clubSeason: IClubSeason,
  ) => {
    let newVal: 0 | 1 = checked ? 1 : 0
    fetch(gen("/api/updateIsSwimminglyCustomer"), {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify({
        seasonId: clubSeason.seasonId,
        clubId: clubSeason.clubId,
        isSwimminglyCustomer: newVal,
      }),
    }).then(async (response) => {
      if (response.status >= 200 && response.status < 300) {
        let newClubSeasonsList = [...clubSeasonsList]
        newClubSeasonsList.forEach((seasonMembership) => {
          if (seasonMembership.clubSeasonId === clubSeason.clubSeasonId) {
            seasonMembership.isSwimminglyCustomer = newVal
          }
        })
        setClubSeasonsList(newClubSeasonsList)
      } else {
        let data: {
          message?: string
        } = await response.json()
        let errMessage = `Something went wrong - the server responded with ${response.status}`
        if (data.message) {
          errMessage = `Something went wrong - the server responded with ${response.status} - ${data.message}`
        }
        message.error(errMessage)
      }
    })
  }

  let columns

  if (screenSize === "small") {
    columns = [
      {
        title: "Club",
        dataIndex: "clubNameLong",
        key: "clubNameLong",
        render: (text: string, record: IClubsInfoRow) => {
          return (
            <span
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => selectClub(record)}
            >
              {record.clubNameLong}
            </span>
          )
        },
      },
      {
        title: "Season Type",
        dataIndex: "seasonType",
        key: "seasonType",
        render: (text: string, record: IClubsInfoRow) => {
          return (
            <span
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => selectClub(record)}
            >
              {record.seasonType || ""}
            </span>
          )
        },
      },
      {
        title: "isSwimmingly",
        dataIndex: "isSwimminglyCustomer",
        key: "isSwimminglyCustomer",
        render: (text: string, record: IClubsInfoRow) => {
          return (
            <span
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => selectClub(record)}
            >
              {record.isSwimminglyCustomer === 1 ? "true" : "false"}
            </span>
          )
        },
      },
    ]
  } else if (screenSize === "medium") {
    columns = [
      {
        title: "Club",
        dataIndex: "clubNameLong",
        key: "clubNameLong",
        render: (text: string, record: IClubsInfoRow) => {
          return (
            <span
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => selectClub(record)}
            >
              {record.clubNameLong}
            </span>
          )
        },
      },
      {
        title: "League",
        dataIndex: "league",
        key: "league",
        render: (text: string, record: IClubsInfoRow) => {
          return (
            <span
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => selectClub(record)}
            >
              {record.league || ""}
            </span>
          )
        },
      },
      {
        title: "Org Type",
        dataIndex: "organizationType",
        key: "organizationType",
        render: (text: string, record: IClubsInfoRow) => {
          return (
            <span
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => selectClub(record)}
            >
              {record.organizationType || ""}
            </span>
          )
        },
      },
      {
        title: "Season Type",
        dataIndex: "seasonType",
        key: "seasonType",
        render: (text: string, record: IClubsInfoRow) => {
          return (
            <span
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => selectClub(record)}
            >
              {record.seasonType || ""}
            </span>
          )
        },
      },
      {
        title: "isSwimmingly",
        dataIndex: "isSwimminglyCustomer",
        key: "isSwimminglyCustomer",
        render: (text: string, record: IClubsInfoRow) => {
          return (
            <span
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => selectClub(record)}
            >
              {record.isSwimminglyCustomer === 1 ? "true" : "false"}
            </span>
          )
        },
      },
    ]
  } else {
    columns = [
      {
        title: "Club Id",
        dataIndex: "clubId",
        key: "clubId",
        render: (text: string, record: IClubsInfoRow) => {
          return (
            <span
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => selectClub(record)}
            >
              {record.clubId}
            </span>
          )
        },
      },
      {
        title: "Name (Long)",
        dataIndex: "clubNameLong",
        key: "clubNameLong",
        render: (text: string, record: IClubsInfoRow) => {
          return (
            <span
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => selectClub(record)}
            >
              {record.clubNameLong}
            </span>
          )
        },
      },
      {
        title: "Name (Short)",
        dataIndex: "clubName",
        key: "clubName",
        render: (text: string, record: IClubsInfoRow) => {
          return (
            <span
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => selectClub(record)}
            >
              {record.clubName}
            </span>
          )
        },
      },
      {
        title: "League",
        dataIndex: "league",
        key: "league",
        render: (text: string, record: IClubsInfoRow) => {
          return (
            <span
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => selectClub(record)}
            >
              {record.league || ""}
            </span>
          )
        },
      },
      {
        title: "Org Type",
        dataIndex: "organizationType",
        key: "organizationType",
        render: (text: string, record: IClubsInfoRow) => {
          return (
            <span
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => selectClub(record)}
            >
              {record.organizationType}
            </span>
          )
        },
      },
      {
        title: "Import Entries",
        dataIndex: "canImportEntries",
        key: "canImportEntries",
      },
      {
        title: "Export Entries",
        dataIndex: "canExportEntries",
        key: "canExportEntries",
      },
      {
        title: "Import Results",
        dataIndex: "canImportResults",
        key: "canImportResults",
      },
      {
        title: "Season Type",
        dataIndex: "seasonType",
        key: "seasonType",
        render: (text: string, record: IClubsInfoRow) => {
          return (
            <span
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => selectClub(record)}
            >
              {record.seasonType || ""}
            </span>
          )
        },
      },
      {
        title: "isSwimmingly",
        dataIndex: "isSwimminglyCustomer",
        key: "isSwimminglyCustomer",
        render: (text: string, record: IClubsInfoRow) => {
          return (
            <span
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => selectClub(record)}
            >
              {record.isSwimminglyCustomer === 1 ? "true" : "false"}
            </span>
          )
        },
      },
    ]
  }

  const clubsInfoFiltered =
    searchText.trim() === ""
      ? clubsInfo
      : fuzzyRank(
          clubsInfo,
          (oneClub: IClubsInfoRow) => [
            oneClub.clubName || "",
            oneClub.clubNameLong || "",
            oneClub.league || "",
          ],
          searchText,
        )

  const selectedClubName =
    selectedClub && selectedClub.clubNameLong ? selectedClub.clubNameLong : ""

  return (
    <>
      <header className="page-header">
        <h2>Swimmingly Admins - Advanced Functionality</h2>
      </header>
      <p>
        This page isn't complete yet... the data below are "accurate", but they
        reflect some changes that we're making in the database that we haven't
        communicated with the whole team, so don't stress if this looks funky!
        More info to come soon.
      </p>
      <SearchInput
        placeholder="Search for a club..."
        onChange={debounce((e) => {
          setSearchText(e.target.value)
          setPaginationCurrent(1)
        }, 250)}
      />
      <Table
        pagination={{
          current: paginationCurrent,
          onChange: (page: number) => setPaginationCurrent(page),
        }}
        rowKey="key"
        dataSource={clubsInfoFiltered}
        columns={columns}
      />
      <GenericModal
        visible={modalVisible}
        width="70%"
        maxWidth={`${Math.round(screenSizes.big * 0.45)}`}
        setVisible={setModalVisible}
        title={`${selectedClubName} Advanced Management Options`}
        footer={() => (
          <>
            <Button
              key="close"
              onClick={() => {
                setModalVisible(false)
              }}
            >
              Close
            </Button>
          </>
        )}
      >
        {clubSeasonsList.length > 0 ? (
          <>
            <h3>Past Seasons Using Swimmingly</h3>
            <SeasonsTable>
              <thead>
                <tr>
                  <th>League</th>
                  <th>Season</th>
                  <th>Dates</th>
                  <th>isSwimmingly</th>
                  <th>Had Season</th>
                  <th>Remove</th>
                </tr>
              </thead>
              <tbody>
                {clubSeasonsList.map((clubSeason, idx) => (
                  <tr key={`table_row_${idx}`}>
                    <td>{clubSeason.league}</td>
                    <td>{clubSeason.seasonName}</td>
                    <td>
                      {clubSeason.startDate.slice(0, 10)} -{" "}
                      {clubSeason.endDate.slice(0, 10)}
                    </td>
                    <td>
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        checked={clubSeason.isSwimminglyCustomer === 1}
                        onChange={(checked) =>
                          updateIsSwimminglyCustomer(checked, clubSeason)
                        }
                      />
                    </td>
                    <td>
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        checked={clubSeason.didNotHaveSeason !== 1}
                        onChange={(checked) => alert(`todo ${checked}`)}
                      />
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        fontSize: "2rem",
                        cursor: "pointer",
                      }}
                    >
                      <DeleteTwoTone />
                    </td>
                  </tr>
                ))}
              </tbody>
            </SeasonsTable>
          </>
        ) : null}
      </GenericModal>
    </>
  )
}
