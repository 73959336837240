import React from "react";
import EditUserLeagueModal from "./EditUserLeague";
import { roleMapping, titleMapping, gen } from "./utils";
import { Modal, Table, Button, message, Tag } from "antd";
import { EditOutlined } from "@ant-design/icons";

const { Column } = Table;

export default class AdminUsersLeague extends React.Component {
  state = {
    approveUserLoading: false,
    userIdEdit: null,
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    role: 3,
    title: "",
    visible: false,
    buttonLoading: false,
  };

  editUser = (user) => {
    let nameSplit = user.name.split(" ");
    this.setState({
      userIdEdit: user.id,
      email: user.email,
      firstName: nameSplit[0],
      lastName: nameSplit.slice(1).join(" "),
      phoneNumber: user.phone_number,
      role: String(user.role),
      title: user.title,
      visible: true,
    });
  };

  approveUser = async (userId) => {
    this.setState({ approveUserLoading: true });
    try {
      const response = await fetch(gen("/api/activateThisUserPost"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId: userId }),
      });

      const data = await response.json();
      if (data.status === "error" && /did not get email/.test(data.error)) {
        message.error(data.error);
      } else {
        message.success("Activated user!");
      }
    } catch (error) {
      console.error("Error activating user:", error);
      message.error("Failed to activate the user. Please try again.");
    } finally {
      this.setState({ approveUserLoading: false });
      this.props.getClubUsers();
    }
  };

  render() {
    if (this.props.adminUsers.length === 0) {
      return (
        <div className="row">
          <div className="col-md-12 center">
            <h3>No League User Found</h3>
          </div>
        </div>
      );
    }

    return (
      <div>
        <Table
          dataSource={this.props.adminUsers}
          bordered
          loading={this.state.buttonLoading}
          rowKey="userId"
          size="middle"
        >
          <Column title="Email Address" dataIndex="email" key="email" />
          <Column title="Name" dataIndex="name" key="name" />
          <Column title="Phone Number" dataIndex="phone_number" key="phone_number" />
          <Column
            title="Role"
            dataIndex="role"
            key="role"
            render={(role, user) => {
              const roleColor = user.role === 4 ? "purple" : user.role === 3 ? "cyan" : "default";
              return (
                <Tag color={roleColor}>
                  {roleMapping[user.role]}{" "}
                  {user.role === 3 && user.hasleague && `(${user.hasleague.name})`}
                </Tag>
              );
            }}
          />
          <Column
            title="Status"
            dataIndex="status"
            key="status"
            render={(status, user) => {
              const lastLogin = user.lastLogin || user.updated_at;
              const lastLoginFormatted = lastLogin
                ? `Last login ${new Date(lastLogin).toLocaleDateString("en-US")}`
                : "Last login unknown";

              const lastLoginStyle = {
                fontSize: "smaller",
                fontStyle: "italic",
                color: "#8898aa",
              };

              let activated = Number(user.activated);
              return (
                <span>
                  {activated === 1 ? "Active" : "Inactive"}{" "}
                  <div style={lastLoginStyle}>{lastLoginFormatted}</div>
                  {activated === 0 && (
                    <div>
                      <Button
                        type="default"
                        size="small"
                        onClick={() => this.approveUser(user.id)}
                        loading={this.state.approveUserLoading}
                        style={{
                          backgroundColor: "#369423",
                          color: "white",
                        }}
                      >
                        Approve
                      </Button>
                    </div>
                  )}
                </span>
              );
            }}
          />
          <Column title="Title" dataIndex="title" key="title" render={(title) => titleMapping[title]} />
          <Column
            title="Actions"
            key="actions"
            render={(user) => (
              <Button
                icon={<EditOutlined />}
                onClick={() => this.editUser(user)}
              />
            )}
          />
        </Table>

        <EditUserLeagueModal
          userIdEdit={this.state.userIdEdit}
          email={this.state.email}
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          phoneNumber={this.state.phoneNumber}
          title={this.state.title}
          user={this.props.user}
          visible={this.state.visible}
          setVisible={(val) => this.setState({ visible: val })}
          setLoading={(val) => this.setState({ buttonLoading: val })}
          getAdminUsers={this.props.getClubUsers}
        />
      </div>
    );
  }
}
