import { ResultStatus, gen, swimminglyApi } from "../../utils"
import { ISeasonDetails } from "../../../App"
import {BillingInfo} from "../sharedState"
import { SeasonHelper } from "../Season";
import { IPaymentMethod } from "../../Billing";

export type PaymentMethodResult = {
    status: ResultStatus
    message?: string
    paymentMethods: IPaymentMethod[]
}

export interface IClubSeasonPaymentInfo  {
    clubPaysAmount: number
    isUsingRegistration: boolean
    parentPaysAmount: number
    clubStripeCustomerId: string
    corporatePaymentId: number
    isSwimminglyCustomer: number
}

export type ClubSeasonPaymentInfoResult = {
    status: ResultStatus
    message: string
    clubSeasonPaymentInfo?: IClubSeasonPaymentInfo
}

// models the API to retreive billing information
export class BillingHelper {
    api = swimminglyApi;
    
    public constructor(api = swimminglyApi) {
        this.api = api
    }

    
    public async getBillingInfo(clubId: number, seasonId: number): Promise<BillingInfo>  {

        
        const seasonInfo = await new SeasonHelper().getSeasonDetails(clubId, seasonId)
        
        const { defaultPaymentMethod}: {defaultPaymentMethodStatus: string,defaultPaymentMethod: ISeasonDetails|undefined} 
            = await this.api.get(gen(`/api/getBillingDataForClub/${clubId}`))
        
        const errorMessage = seasonInfo === null || defaultPaymentMethod === null ? "Unable to retreive billing information" : undefined

        return {seasonInfo: seasonInfo, hasDefaultCardOnFile: Boolean(defaultPaymentMethod), errorMessage: errorMessage}
      
    }

    public async getPaymentMethods(userId: number): Promise<PaymentMethodResult> {
        const paymentMethods = await this.api.get(gen(`/api/getUsersStripePaymentMethods/${userId}?ensureStripeCustomerId=true`))
        if (paymentMethods.status === "success") {
            return paymentMethods
        } else {
            const message = paymentMethods.message ?? "Unable to retreive payment methods"
            return {status: "error", message: message, paymentMethods:[]}
        }
    } 

    public async getClubSeasonPaymentInfo(clubId: number, seasonId: number): Promise<ClubSeasonPaymentInfoResult> {
        const clubSeasonPaymentInfoResult = await this.api.get(gen(`/api/getClubSeasonPaymentInfo/${clubId}/${seasonId}`))
        if (clubSeasonPaymentInfoResult.status === "success") {
            return clubSeasonPaymentInfoResult
        } else {
            const message = clubSeasonPaymentInfoResult.message ?? "Unable to retreive clubSeasonPaymentInfo"
            return { status: "error", message: message }
        }
    } 
  

  
}
  