import { FunctionComponent } from "react"
import Modal from "antd/lib/modal/Modal"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../../../appData/types"
import { useClubSeasonManagementContext } from "../sharedState"
import { StyledRoundedButton } from "./styles"

export const ConfirmRegistrationModal: FunctionComponent = () => {
  const impersonateClub = useStoreState(
    (state: StateMapper<AppDataStore>) => state.aliasedClub,
  )

  const {
    selectedClubSeason,
    registrationModalVisible,
    setRegistrationModalVisible,
    confirmRegistrationChoice,
  } = useClubSeasonManagementContext()

  const confirmRegistration = async () => {
    if (selectedClubSeason?.seasonId) {
      await confirmRegistrationChoice(selectedClubSeason.seasonId, true)
      setRegistrationModalVisible(false)
    }
  }

  const backOutOfRegistration = () => {
    setRegistrationModalVisible(false)
  }

  return (
    <Modal
      visible={registrationModalVisible}
      title="Confim Using Registration"
      footer={[
        <StyledRoundedButton
          key="cancel-button"
          onClick={backOutOfRegistration}
        >
          Not yet - I'm still looking!
        </StyledRoundedButton>,
        <StyledRoundedButton
          key="confirm-button"
          type="primary"
          onClick={confirmRegistration}
        >
          Confirm
        </StyledRoundedButton>,
      ]}
    >
      <p>
        By editing this registration form you are about to confirm that{" "}
        {impersonateClub?.nameLong} will use Swimmingly's registration feature
        for <b>free</b> in {selectedClubSeason?.seasonName}.
      </p>
      <p>Are you sure you want to proceed?</p>
    </Modal>
  )
}
