import React, { useState } from "react"
import {
  raceTypeArr,
  ageGroupArr,
  genderTypeArr,
  distanceArr,
  gen,
  CallbackNoArg,
} from "./utils"
import { Modal, Button, message } from "antd"
import { PlusOutlined } from "@ant-design/icons"

interface IAddEventModalProps {
  league?: { id: number }
  club?: { clubId: number }
  eventOrderId: number | null
  getEvents: (
    theEventOrder: number | null,
    cb: CallbackNoArg | undefined,
  ) => Promise<void>
}

export default function AddEventModal({
  league,
  club,
  eventOrderId,
  getEvents,
}: IAddEventModalProps) {
  const [visible, setVisible] = useState(false)
  const [gender, setGender] = useState("")
  const [ageGroup, setAgeGroup] = useState(0)
  const [distance, setDistance] = useState("")
  const [raceType, setRaceType] = useState("")

  const addEventToEventOrder = () => {
    if (!gender) {
      message.error("Need to select a gender")
      return false
    }
    if (!ageGroup) {
      message.error("Need to select age group")
      return false
    }
    if (!distance) {
      message.error("Need to select distance")
      return false
    }
    if (!raceType) {
      message.error("Need to select race type")
      return false
    }
    if (!eventOrderId) {
      message.error("We're having trouble finding the id for this event order.")
      return false
    }

    let ageGroupText = ageGroupArr[ageGroup]

    let requestBodyObj: { [key: string]: any } = {
      gender: gender,
      age_group: ageGroupText,
      distance,
      race_type: raceType,
      eventOrderId,
    }

    if (league) {
      requestBodyObj.league_id = league.id
    } else if (club) {
      requestBodyObj.clubId = club.clubId
    } else {
      throw new Error(`Must have either league or club.`)
    }

    fetch(gen("/api/saveUserEventsPost"), {
      method: "post",
      headers: { "Content-Type": "application/json" },
      credentials: "same-origin",
      body: JSON.stringify(requestBodyObj),
    })
      .then((response) => response.json())
      .then((data) => {
        getEvents(eventOrderId, undefined)
      })
    return true
  }

  const closeModal = () => {
    let successfullyAddedEventOrder = addEventToEventOrder()
    if (successfullyAddedEventOrder) {
      setVisible(false)
    }
  }

  let genderKeys = Object.keys(genderTypeArr)
  let distanceKeys = Object.keys(distanceArr).map((key) => parseInt(key))
  let ageGroupKeys = Object.keys(ageGroupArr).map((key) => parseInt(key))
  let raceTypeKeys = Object.keys(raceTypeArr).map((key) => parseInt(key))
  // console.log(this.props)
  return (
    <div>
      <Button onClick={() => setVisible(true)}
        type="primary"
        shape="circle"
        icon={<PlusOutlined />}
      >
        
      </Button>
      &nbsp;Add Event Within Selected Event Order <i>(i.e 7-8 Boys 25 Freestyle)</i>
      <Modal
        title="Add New Event"
        visible={visible}
        onOk={closeModal}
        onCancel={() => setVisible(false)}
      >
        <div className="form-group">
          <label className="col-sm-3 ">Gender:</label>
          <div className="col-sm-9">
            <select value={gender} onChange={(e) => setGender(e.target.value)}>
              {genderKeys.map((el) => (
                <option value={el} key={el}>
                  {genderTypeArr[el]}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-group">
          <label className="col-sm-3 ">Age Group:</label>
          <div className="col-sm-9">
            <select
              value={ageGroup}
              onChange={(e) => setAgeGroup(parseInt(e.target.value))}
            >
              {ageGroupKeys.map((el) => (
                <option value={el} key={el}>
                  {ageGroupArr[el]}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="form-group">
          <label className="col-sm-3 ">Distance:</label>
          <div className="col-sm-9">
            <select
              value={distance}
              onChange={(e) => setDistance(e.target.value)}
            >
              {distanceKeys.map((el) => (
                <option value={el} key={el}>
                  {distanceArr[el]}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-group">
          <label className="col-sm-3 ">Race Type:</label>
          <div className="col-sm-9">
            <select
              value={raceType}
              onChange={(e) => setRaceType(e.target.value)}
            >
              <option value={""}>Select</option>
              {raceTypeKeys.map((el) => (
                <option value={el} key={el}>
                  {raceTypeArr[el]}
                </option>
              ))}
            </select>
          </div>
        </div>
      </Modal>
    </div>
  )
}
