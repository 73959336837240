import ReactDOM from "react-dom"
import App from "./App"
import LogRocket from "logrocket"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
// Leave this in here, I need do put in the jquery bundle to get "draggable"
// and I want to do it once for the whole repo
// import $ from "jquery"
import "jquery-ui-bundle"
import "jquery-ui-bundle/jquery-ui.min.css"

import "./theme.css"

const stripePromise =
  process.env.NODE_ENV === "development"
    ? loadStripe("pk_test_J7g0UDJ2qGUvvrVFLtGKtn2b", {
        apiVersion: "2020-03-02",
      })
    : loadStripe("pk_live_Uon94UOXHWs9m5tI8UDA3dIR", {
        apiVersion: "2020-03-02",
      })

/*
This is used to turn off all console.logs
function noop() {}

if (process.env.NODE_ENV !== 'development') {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}
*/

if (process.env.NODE_ENV !== "development") {
  LogRocket.init("iezzg4/clubhouse")
}
ReactDOM.render(
  <Elements stripe={stripePromise}>
    <App />
  </Elements>,
  document.getElementById("root"),
)
