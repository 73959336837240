import { Fragment, useEffect, useState } from "react"
import { Alert, Button, Card, Col, Divider, message, Modal, Row, Tag, Tooltip } from "antd"
import { useHistory, Link, useLocation } from "react-router-dom"
import styled from "styled-components"
import moment, { Moment } from "moment-timezone"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../appData/types"
import {
  gen,
  swimminglyApi,
  useScreenSize,
  ISwimmerWithGuardian,
  IClub,
  RoleHelper,
} from "./utils"
import { screenSizes } from "../styles/GlobalStyles"
import ContentPanel from "./ContentPanel"
import { ProgressSteps } from "./ManageRoster"
import { DownOutlined, LinkOutlined, RightOutlined } from "@ant-design/icons"
import MeetSchedule from "../images/MeetSchedule.png"
import MySwimmers from "../images/MySwimmers.png"
import _ from "lodash"
import ReactDependentScript from "react-dependent-script"
import { StyledWistia } from "./utils/sharedDataTypes";

const { Meta } = Card;

const LinkButton = styled.button`
  background: none;
  border: none;
  color: var(--linkblue);
  margin: 0;
  padding: 0;
`

const StyledSwimmerSignUpSearch = styled.div`
  fieldset {
    display: grid;
    grid-template-columns: 300px 75px;
    margin-bottom: 10px;
  }

  input {
    min-width: 200px;
    border: 1px solid var(--mediumgrey);
    border-radius: 3px;
  }

  button {
    min-width: 75px;
    background-color: var(--buttonblue);
    color: var(--snow);
    border: none;
    border-radius: 3px;
    font-size: 1.25rem;
    height: 100%;
  }

  @media (max-width: 400px) {
    fieldset {
      grid-template-columns: 200px 70px;
    }

    input {
      font-size: 1.25rem;
    }
  }
`

const StyledSeasonsDisplay = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 50px;
  row-gap: 25px;

  .display-card {
    box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.04);
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 0;
  }

  .card-header {
    cursor: pointer;
    border: 1px solid var(--lightgrey);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: var(--primaryblue);
    color: white;
    display: flex;
    justify-content: center;
    padding: 8px;
  }

  .card-header span {
    align-self: center;
  }

  .card-body {
    border: 1px solid var(--lightgrey);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 5px;
  }

  .display-card .card-body .shadowed {
    margin-top: 5px;
    margin-bottom: 5px;
    box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(0, 0, 0, 0.06);
    padding: 10px;
  }

  .display-card .card-body .shadowed.short {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .display-card .card-body .swimmer-info {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
  }

  .display-card .card-body .swimmer-info p {
    margin: 0;
  }

  .display-card .card-title {
    margin-top: 0;
    margin-bottom: 0;
    text-decoration: underline;
  }

  .contact-display {
    display: grid;
    font-size: 1.3rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
  }

  .contact-display-row {
    display: grid;
    grid-column: span 4;
    @supports not (grid-template-columns: subgrid) {
      --columns: 1fr 1fr 1fr 1fr;
    }
    grid-template-columns: var(--columns, subgrid);
    box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.02);
    line-height: 1.4rem;
  }

  @media (max-width: 1700px) {
    .contact-display {
      font-size: 1.1rem;
    }
  }

  @media (max-width: 1400px) {
    .contact-display {
      font-size: 1rem;
      grid-template-columns: 1fr 1fr 1fr;
    }

    .contact-display-row {
      grid-column: span 3;
      @supports not (grid-template-columns: subgrid) {
        --columns: 1fr 1fr 1fr;
      }
    }

    .contact-display-phone {
      display: none;
      visibility: hidden;
    }
  }

  @media (max-width: ${screenSizes.medium}px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${screenSizes.small}px) {
    grid-template-columns: 1fr;
    .contact-display {
      font-size: 0.8rem;
    }
    .display-card .card-body .swimmer-info {
      grid-template-columns: 1fr;
      text-align: center;
    }
  }
`


interface IClubSeasonsWithSwimmers {
  seasonId: number
  season: string
  league: string
  signUpCode: string
  startDate: string
  endDate: string
  clubId: number
  club: string
  swimmers: {
    swimmerId: number
    name: string
    swimmerShoulderNumber: number
    hasPaid: 0 | 1
    payingForMonth?: number | null
    registered: boolean
    registrationStatus: "signed-up" | "requested"
    usingRegistration: number
  }[]
}

interface IClubContact {
  clubId: number
  contactName: string
  contactEmail: string
  contactPhone: string | null
  contactTitle: string
  isCoach: boolean
  isPresident: boolean
  isGenericAdmin: boolean
}

interface LocationStateType {
  isThankYouModalOpen?: boolean;
}

export default function LandingPage() {
  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)

  const [swimmerSignUpCode, setSwimmerSignUpCode] = useState("")

  const [guardiansSwimmers, setGuardiansSwimmers] = useState<ISwimmerWithGuardian[]>([])
  const [guardiansSeasons, setGuardiansSeasons] = useState<IClubSeasonsWithSwimmers[]>([])
  const [clubContacts, setClubContacts] = useState<IClubContact[]>([])
  const [aliasableClubs, setAliasableClubs] = useState<IClub[]>([])
  const [registrationSearchExpanded, setRegistrationSearchExpanded] = useState(true)
  const [expandedSeasonDate, setExpandedSeasonDate] = useState<Moment | null>(moment())
  const [selectedSeasons, setSelectedSeasons] = useState<Number[]>([])


  const screenSize = useScreenSize()

  const history = useHistory()
  const { state: locationState } = useLocation<LocationStateType>()
  const [isThankYouModalOpen, setIsThankYouModalOpen] = useState(locationState?.isThankYouModalOpen || false)

  const getGuardianSwimmers = async (swimmers: ISwimmerWithGuardian[]) => {
    const clubIds = new Set<number>()
    const contactsList: IClubContact[] = []
    const getContactsList: Promise<any>[] = []
    const clubSeasonKeys = new Set<string>()
    const clubSeasonsList: IClubSeasonsWithSwimmers[] = []

    swimmers.forEach((swimmer) => {
      swimmer.clubSeasons?.forEach((clubSeason) => {
        if (!clubIds.has(clubSeason.clubId)) {
          clubIds.add(clubSeason.clubId)
          getContactsList.push(
            swimminglyApi
              .get(`/api/getClubContacts/${clubSeason.clubId}`)
              .then((data) => {
                if (data.status === "success" && data.clubContacts) {
                  contactsList.push(...data.clubContacts)
                } else {
                  console.log("Problem getting club contacts")
                }
              })
              .catch((err) => {
                console.log(err)
              }),
          )
        }

        const thisClubSeasonIdentifier = `${clubSeason.clubId}|${clubSeason.seasonId}`
        if (!clubSeasonKeys.has(thisClubSeasonIdentifier)) {
          clubSeasonKeys.add(thisClubSeasonIdentifier)
          const thisClubSeason: IClubSeasonsWithSwimmers = {
            seasonId: clubSeason.seasonId,
            season: clubSeason.season,
            league: clubSeason.league,
            startDate: clubSeason.startDate,
            endDate: clubSeason.endDate,
            clubId: clubSeason.clubId,
            club: clubSeason.club,
            signUpCode: clubSeason.clubSignUpCode,
            swimmers: [
              {
                swimmerId: swimmer.swimmer.swimmerId,
                name:
                  swimmer.swimmer.firstName.trim() +
                  " " +
                  swimmer.swimmer.lastName.trim(),
                swimmerShoulderNumber: clubSeason.swimmerShoulderNumber,
                hasPaid: clubSeason.hasPaid,
                registered: clubSeason.registered === 1,
                registrationStatus: clubSeason.registrationStatus,
                usingRegistration: clubSeason.usingRegistration
              },
            ],
          }
          clubSeasonsList.push(thisClubSeason)
        } else {
          const thisClubSeason = clubSeasonsList.find(
            (cSL) => cSL.clubId === clubSeason.clubId && cSL.seasonId === clubSeason.seasonId,
          )
          if (!thisClubSeason) {
            const unexpectedProblem = new Error(
              "Should have found a season here. Something went wrong.",
            )
            console.log(unexpectedProblem)
            console.log("looking for:")
            console.log("clubId: ", clubSeason.clubId)
            console.log("seasonId: ", clubSeason.seasonId)
            console.log(clubSeasonsList)
          } else {
            thisClubSeason.swimmers.push({
              swimmerId: swimmer.swimmer.swimmerId,
              name:
                swimmer.swimmer.firstName.trim() + " " + swimmer.swimmer.lastName.trim(),
              swimmerShoulderNumber: clubSeason.swimmerShoulderNumber,
              hasPaid: clubSeason.hasPaid,
              registered: clubSeason.registered === 1,
              registrationStatus: clubSeason.registrationStatus,
              usingRegistration: clubSeason.usingRegistration
            })
          }
        }
      })
    })

    Promise.all(getContactsList).then(() => {
      setClubContacts(contactsList)
    })

    clubSeasonsList.sort((season1, season2) => {
      if (season1.startDate > season2.startDate) return -1
      else if (season1.startDate < season2.startDate) return 1
      else if (season1.endDate > season2.endDate) return -1
      else return 1
    })
    return clubSeasonsList
  }

  useEffect(() => {
    swimminglyApi
      .get(gen(`/api/getAllSwimmersOfGuardian/${user?.userId}`))
      .then(
        async ({
          status,
          swimmers,
        }: {
          status: string
          swimmers?: ISwimmerWithGuardian[]
        }) => {
          if (status === "success" && swimmers) {
            setGuardiansSwimmers(swimmers)
            const clubSeasonsList = await getGuardianSwimmers(swimmers)
            setGuardiansSeasons(clubSeasonsList)
            setSelectedSeasons(
              clubSeasonsList.filter((gS) => moment().isBetween(gS.startDate, gS.endDate))
                .map((gS) => gS.seasonId)
            )
            const filteredClubSeasons = clubSeasonsList.sort((a, b) => moment(b.startDate).year() - moment(a.startDate).year())
            const latestClubSeason = !_.isEmpty(filteredClubSeasons) ? filteredClubSeasons[0] : undefined
            const registeredSwimmers = latestClubSeason ? Object.values(latestClubSeason.swimmers).filter(swimmer => swimmer.registered) : []
            const currentlyActiveSeasons = clubSeasonsList.filter(clubSeason => moment().isBetween(clubSeason.startDate, clubSeason.endDate))
            const hasCurrentlyActiveSeason = Boolean(currentlyActiveSeasons.length)
            if (hasCurrentlyActiveSeason && registeredSwimmers.length > 0) {
              setRegistrationSearchExpanded(false)
            } else {
              setRegistrationSearchExpanded(true)
            }

            const swimmersClubIds = swimmers.map((swimmer) => {
              const clubSeasons = swimmer.clubSeasons || []
              return clubSeasons
                .filter(
                  (cS) => moment().diff(moment(cS.endDate), "days") <= 366,
                )
                .map((cS) => cS.clubId)
            })
            let allClubIds: number[] = []
            swimmersClubIds.forEach((sCI) => allClubIds.push(...sCI))
            allClubIds = allClubIds.filter((v, i, a) => a.indexOf(v) === i)
            const clubs: IClub[] = []
            for (let i = 0; i < allClubIds.length; i++) {
              await swimminglyApi
                .get(gen(`/api/getClub/${allClubIds[i]}`))
                .then((data) => {
                  if (data.club) {
                    clubs.push(data.club)
                  }
                })
            }
            setAliasableClubs(clubs)
          }
        },
      )
  }, [user?.userId])



  const resendEmail = async () => {
    if (user) {
      const userId = user.userId
      let emailResponse = await swimminglyApi.get(
        gen(`/api/resendParentActivationEmail/${userId}`),
      )
      if (emailResponse && emailResponse.status === "success") {
        message.success("Email sent!")
      } else {
        message.error("Uh oh, something went wrong...")
      }
    }
  }

  const seasonList = guardiansSeasons?.map((gS, idx) => {
    const isSeasonExpanded = selectedSeasons.includes(gS.seasonId)

    return (
      <div
        key={`club_season_card_${gS.clubId}_${gS.seasonId}_${idx}`}
      >
        <div
          className="card-header"
        >
          {isSeasonExpanded ? (
            <DownOutlined
              style={{ color: "var(--white)", cursor: "pointer", paddingRight: 20 }}
              onClick={() => setSelectedSeasons(selectedSeasons.filter(seasonId => seasonId !== gS.seasonId))
              }
            />
          ) : (
            <RightOutlined
              style={{ color: "var(--white)", cursor: "pointer", paddingRight: 20 }}
              onClick={() => {
                setSelectedSeasons([...selectedSeasons, gS.seasonId])
              }
              }
            />
          )}
          <span
            onClick={() => {
              history.push(`/clubs/register/${gS.signUpCode}`)
            }}
          >
            {`${gS.club} - ${gS.season}`}
          </span>
        </div>
        {isSeasonExpanded && (
          <div className="display-card">
            <div className="card-body ">
              <Link to={`/clubs/register/${gS.signUpCode}`}>
                <LinkOutlined /> Go To Season Registration for {gS.club}
              </Link>
              <div className="shadowed">
                <p className="card-title">Your Swimmers:</p>
                {gS.swimmers.map((s, index) => (
                  <div
                    key={`season_swimmer_card_${s.swimmerId}_${gS.clubId}_${gS.seasonId}_${index}`}
                    className="shadowed short swimmer-info"
                    style={{ marginBottom: '10px' }}
                  >
                    <div 
                      className="swimmer-details"
                      style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}
                    >
                      <p style={{ margin: 0, fontWeight: 'bold' }}>{s.name}</p>
                      <Tooltip 
                        title="Athlete's Swimmer # for this season, also found on SwimminglyFan.
                              Swimmers can write their number in grease pen on their arm before warm-ups!"
                        placement="top"
                      >
                        <p style={{ margin: 0, cursor: 'pointer' }}>
                          Swimmer #: 
                          <Tag color="green">{
                            _.isNil(s.swimmerShoulderNumber) 
                              ? "N/A" 
                              : s.swimmerShoulderNumber.toString().padStart(3, '0')
                            }
                          </Tag>
                        </p>
                      </Tooltip>
                    </div>
                    <div className="custom-progress-steps">
                      <ProgressSteps
                        maxWidth={screenSize === "small" ? "275px" : screenSize === "medium" ? "350px" : "400px"}
                        fontSize="1.15rem"
                        size="small"
                        currentStep={s.hasPaid ? 2 : (s.registrationStatus === "signed-up" || s.usingRegistration === 1) ? 2 : 0}
                      />
                    </div>
                  </div>
                ))}





              </div>
              <div className="shadowed">
                <p className="card-title">Club Contact Information</p>
                <div className="contact-display">
                  {clubContacts
                    .filter((contact) => contact.clubId === gS.clubId)
                    .map((contact, idx) => {
                      return (
                        <div
                          key={`club_contact_${contact.clubId}_${contact.contactEmail}_${idx}`}
                          className="contact-display-row"
                        >
                          <span>{contact.contactName}</span>
                          <span>{contact.contactTitle}</span>
                          <span className="contact-display-phone">
                            {contact.contactPhone || ""}
                          </span>
                          <span>{contact.contactEmail}</span>
                        </div>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  })

  return (
    <Fragment>
      <header className="page-header">
        <h2>Welcome to the Clubhouse</h2>
      </header>
      {user && !user.isActive ? (
        <Alert
          style={{ marginBottom: "15px" }}
          key={1}
          message={
            <span style={{ fontSize: "1.75rem" }}>
              Your next step is to activate your account. Check for an email
              from us to activate your account. If you didn't receive an email
              click <LinkButton onClick={resendEmail}>here</LinkButton> to
              resend.
            </span>
          }
          type="warning"
          closable
        />
      ) : null}
      {user && (!user.isActive || guardiansSeasons.length === 0) ? (
        <section className="panel">
          <header className="panel-heading">
            <h2 className="panel-title">Swimmingly Clubhouse</h2>
          </header>
          <div className="ourpanel-body">
            <p>
              You can use the links on the left to navigate around the
              clubhouse.
            </p>
            {user && user.role === 5 && !user.isActive ? (
              <p>
                It looks like you haven't activated your account yet! You may
                not see any links to the left or have access to much of
                Swimmingly's functionality. Once you activate your account and
                get your swimmers added to a team's roster using their swimmer
                registration page you'll be able to use all of Swimmingly's features.
              </p>
            ) : null}
            {user && user.role === 5 && user.isActive ? (
              <p>
                If you aren't listed as the guardian of any swimmers you may not
                see any links to the left or have access to much of Swimmingly's
                functionality. Once you get your swimmers added to a team's
                roster on their swimmer registration page you'll be able to use all
                of Swimmingly's features.
              </p>
            ) : null}
            {user && !user.isActive ? (
              <p>
                If you're not sure what to do, we recommend checking your email
                for an activation email from Swimmingly or clicking click{" "}
                <LinkButton onClick={resendEmail}>here</LinkButton> to resend
                the email to your inbox at {user.email}. You can use the link in
                that email to activate your account.
              </p>
            ) : null}
          </div>
        </section>
      ) : null}
      {user?.isActive ? (
        <ContentPanel
          title={
            <span
              onClick={() => registrationSearchExpanded ?
                setRegistrationSearchExpanded(false) :
                setRegistrationSearchExpanded(true)
              }
            >
              Season Registration{" "}
              {registrationSearchExpanded ? (
                <DownOutlined
                  style={{ color: "var(--linkblue)", cursor: "pointer" }}
                  onClick={() => setRegistrationSearchExpanded(false)}
                />
              ) : (
                <RightOutlined
                  style={{ color: "var(--linkblue)", cursor: "pointer" }}
                  onClick={() => setRegistrationSearchExpanded(true)}
                />
              )}
            </span>
          }
        >
          {registrationSearchExpanded ? (
            <StyledSwimmerSignUpSearch>
              {aliasableClubs.length > 0 && (
                <div style={{ maxWidth: "600px", margin: "auto" }}>
                  <div style={{ marginBottom: "10px" }}>
                    <h4>Returning Parent?</h4>
                    <p>Click on your club to sign up for the season!</p>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr",
                        rowGap: "5px",
                      }}
                    >
                      {aliasableClubs.map((aliasableClub) => (
                        <Button
                          key={`aliasableClub_${aliasableClub.clubId}`}
                          type="primary"
                          style={{
                            borderRadius: "5px",
                            width: "100%",
                            padding: "10px",
                            fontSize: "1.5rem",
                          }}
                          onClick={() => {
                            if (aliasableClub.secretToken) {
                              history.push(
                                `/clubs/register/${aliasableClub.secretToken}`,
                              )
                            }
                          }}
                        >
                          {aliasableClub.nameLong}
                        </Button>
                      ))}
                    </div>
                  </div>
                  <Divider style={{ color: "var(--darkgrey)" }}>Or</Divider>
                </div>
              )}
              <div style={{ maxWidth: "600px", margin: "auto" }}>
                <p>
                  Enter your Club Registration Code below. If you're unsure
                  of your Club Registration Code please contact your club's
                  leadership.
                </p>
                <form
                  onSubmit={async (e) => {
                    e.preventDefault()
                    let signUpCode = swimmerSignUpCode
                    if (
                      /clubs\/register\//.test(swimmerSignUpCode) &&
                      !/clubs\/register\/$/.test(swimmerSignUpCode)
                    ) {
                      const possibleTokenValues =
                        swimmerSignUpCode.split(/clubs\/register\//)
                      signUpCode = possibleTokenValues[1]
                    }
                    if (signUpCode && signUpCode.length > 0) {
                      const validateClub = await swimminglyApi
                        .get(
                          gen(`/api/getClubInformationFromToken/${signUpCode}`),
                        )
                        .then((data) => {
                          if (data.status === "success" && data.club) {
                            return "valid"
                          } else {
                            return "invalid"
                          }
                        })
                        .catch((err) => {
                          console.log(err.message)
                          return "invalid"
                        })
                      if (validateClub === "invalid") {
                        message.error(
                          "Uh oh, it doesn't look like this is a valid Club Registration Code",
                        )
                      } else {
                        history.push(`/clubs/register/${signUpCode}`)
                        return
                      }
                    } else {
                      message.warning("Please enter a registration code first")
                    }
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      style={{ width: "75%", height: "30px" }}
                      placeholder="Club Registration Code"
                      onChange={(e) => setSwimmerSignUpCode(e.target.value)}
                    />
                    <button style={{ width: "25%", height: "30px" }}>
                      Go!
                    </button>
                  </div>
                </form>
              </div>
            </StyledSwimmerSignUpSearch>
          ) : null}
        </ContentPanel>
      ) : null}

      {user && new RoleHelper(user.role).isGuardian() && user.isActive ? (

        <p>
          <Row gutter={[16, 24]}>
            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 11, offset: 0 }} md={{ span: 11, offset: 0 }} lg={{ span: 10, offset: 2 }}>

              <a href="/app/parentMeets">
                <Card
                  headStyle={{ fontWeight: "bold" }}
                  hoverable
                  style={{ maxWidth: 360 }}
                  cover={<img
                    alt="Meet Schedule"
                    src={MeetSchedule}
                    style={{ maxWidth: 220, maxHeight: 220, margin: "auto", width: "50%", padding: "10px" }}
                  />}
                  title="Meet Schedule"
                >
                  <Meta
                    title="Go To Meet Schedule"
                    description="Go here to complete meet declarations, view results, and more!"
                  />
                </Card>
              </a>
            </Col>

            <Col sm={{ span: 2, offset: 0 }} md={{ span: 2, offset: 0 }} lg={{ span: 2, offset: 0 }}>
            </Col>

            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 11, offset: 0 }} md={{ span: 11, offset: 0 }} lg={{ span: 10, offset: 0 }}>
              <a href="/app/parentHome">
                <Card
                  headStyle={{ fontWeight: "bold" }}
                  hoverable
                  style={{ maxWidth: 360 }}
                  cover={<img
                    alt="My Swimmers"
                    src={MySwimmers}
                    style={{ maxWidth: 220, maxHeight: 220, margin: "auto", width: "50%", padding: "10px" }}
                  />}
                  title="My Swimmers"
                >
                  <Meta
                    title="Go to My Swimmers"
                    description="Go here to to see your swimmers' personalized meet results, best times, and time improvements!"
                  />
                </Card>
              </a>
            </Col>
          </Row>
        </p>

      ) : null}

      {(guardiansSeasons?.length || 0) > 0 && (
        <ContentPanel marginTop="25px">
          <h4>Season History</h4>
          <p>
            Season history for your swimmers.
          </p>
          <StyledSeasonsDisplay>
            {seasonList}
          </StyledSeasonsDisplay>
        </ContentPanel>
      )}

      <Modal
        visible={isThankYouModalOpen}
        title="Thank You & Welcome to the Season"
        onCancel={() => {
          setIsThankYouModalOpen(false)
         }}
        onOk={() => {
          setIsThankYouModalOpen(false)
        }}
      >
        <ReactDependentScript
          loadingComponent={<div>Loading video...</div>}
          scripts={[`https://fast.wistia.com/embed/medias/c6klt7ahq1.jsonp`]}
        >
          <StyledWistia className="wistia_embed wistia_async_c6klt7ahq1 videoFoam=true" />
        </ReactDependentScript>

      </Modal>
    </Fragment>
  )
}
