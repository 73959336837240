import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../../appData/types"
import {
  divLikeLink,
  formatNumberAsTime,
  gen,
  ISwimmerWithNumericGender,
  swimminglyApi,
} from "../utils"
import { CheckCircleTwoTone } from "@ant-design/icons"
import imageUp from "../../images/up.png"
import imageDown from "../../images/down.png"
import { Tooltip } from "antd"
import { CloseOutlined } from "@ant-design/icons"

type UnEnteredSwimmer = {
  attendingMeet: boolean | null
  swimmer: ISwimmerWithNumericGender
  meetAge: number
  individualEntries: number
  relayEntries: number
  seedTime: number | null
  previousBest: number | null
  converted: boolean
}

interface ISwimmersNotInEventProps {
  unEnteredEventSwimmers: UnEnteredSwimmer[]
  setSwimmerSelectedToViewHistory: (
    newValue: { swimmer: ISwimmerWithNumericGender; meetAge: number } | null,
  ) => void
  sortType: "by name" | "by time"
  sortDir: "aesc" | "desc"
  swimEventId: number
  isRelay: boolean
  refreshSwimmers: () => void
  meetId: number
  meetCourse: "SCY" | "SCM" | "LCM"
  setBestRelays: (
    newVal: {
      [key: string]: { swimmer_id: number; swimmer_name: string; time: number }
    }[],
  ) => void
  setRelayDisplayName: (newVal: string) => void
  setShowBestTimeRelayModal: (newVal: boolean) => void
  setBestRelaySeedTime: (newVal: number | null) => void
  setRelayStrokeType: (newVal: string) => void
  setSortType: (newSortType: "by name" | "by time") => void
  setSortDir: (newSortDir: "aesc" | "desc") => void
}

const StyledSwimmerDisplay = styled.div`
  display: grid;
  grid-template-columns: 20px 2fr 1fr 1fr 1fr 15px;
  grid-auto-rows: 25px;
  row-gap: 5px;
  column-gap: 5px;
  min-height: 0;
  overflow: hidden;

  .row::before,
  .row::after {
    display: none;
    visibility: hidden;
  }
  .row {
    margin-left: 0;
    margin-right: 0;
    /* height: 22px; */
    grid-column: 1 / span 6;
    display: grid;
    grid-template-columns: 20px 8fr 4fr 1fr 4fr 15px;
    column-gap: 5px;
    row-gap: 5px;
    min-height: 0;
    min-width: 0;
    overflow: hidden;
  }

  .add-all-button {
    grid-column: 1 / span 6;
    display: grid;
    column-gap: 5px;
    grid-template-columns: 20px 1fr;
  }

  .relay-button-container {
    grid-column: 1 / span 6;
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 10px;
  }

  .sort-filters {
    grid-column: 1 / span 6;
    display: grid;
  }

  .relay-button {
    max-height: 25px;
    background-color: var(--buttonblue);
    border: none;
    border-radius: 5px;
    color: var(--snow);
  }

  .add-swimmer {
    padding-left: 3px;
    padding-right: 3px;
    border-radius: 4px;
    background-color: var(--primaryblue);
    color: var(--snow);
    cursor: pointer;
    align-self: center;
    text-align: center;
  }

  .removed-swimmer {
    cursor: not-allowed;
  }

  .add-swimmer.removed-swimmer {
    background-color: var(--mediumgrey);
  }

  span.removed-swimmer {
    text-decoration: line-through;
  }

  .remove-swimmer {
    cursor: pointer;
  }

  .add-swimmer-declarations {
    font-size: 1.5em;
  }

  .add-swimmer-declarations:hover {
    color: #52c41a;
    cursor: pointer;
  }

  .link-looking-span {
    cursor: pointer;
    color: var(--linkblue);
  }

  .individual-entries {
    background: #25bfea;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: #fff;
    float: left;
    line-height: 20px;
    text-align: center;
    vertical-align: middle;
    margin-right: 1px;
  }

  .relay-entries {
    background: #8092a2;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: #fff;
    float: left;
    line-height: 20px;
    text-align: center;
    vertical-align: middle;
    margin-left: 1px;
  }
`

const sortUnEnteredSwimmersByName = (
  swimmers: UnEnteredSwimmer[],
  direction: "aesc" | "desc",
) => {
  const directionFactor = direction === "aesc" ? 1 : -1
  swimmers.sort((swimmer1, swimmer2) => {
    if (swimmer1.swimmer.lastName > swimmer2.swimmer.lastName)
      return directionFactor
    if (swimmer1.swimmer.lastName < swimmer2.swimmer.lastName)
      return -directionFactor
    if (swimmer1.swimmer.firstName > swimmer2.swimmer.firstName)
      return directionFactor
    if (swimmer1.swimmer.firstName < swimmer2.swimmer.firstName)
      return -directionFactor
    return 1
  })
}

const sortUnEnteredSwimmersByTime = (
  swimmers: UnEnteredSwimmer[],
  direction: "aesc" | "desc",
) => {
  const directionFactor = direction === "aesc" ? 1 : -1
  swimmers.sort((swimmer1, swimmer2) => {
    if (swimmer1.previousBest === null || swimmer2.previousBest === null) {
      if (swimmer1.previousBest === null && swimmer2.previousBest === null)
        return 1
      if (swimmer1.previousBest === null && swimmer2.previousBest !== null)
        return directionFactor
      if (swimmer1.previousBest !== null && swimmer2.previousBest === null)
        return -directionFactor
    } else {
      if (swimmer1.previousBest > swimmer2.previousBest) return directionFactor
      if (swimmer1.previousBest < swimmer2.previousBest) return -directionFactor
    }
    return 1
  })
}

export default function SwimmersNotInEvent({
  unEnteredEventSwimmers,
  setSwimmerSelectedToViewHistory,
  swimEventId,
  sortType,
  sortDir,
  isRelay,
  refreshSwimmers,
  meetId,
  meetCourse,
  setShowBestTimeRelayModal,
  setBestRelays,
  setRelayDisplayName,
  setBestRelaySeedTime,
  setRelayStrokeType,
  setSortType,
  setSortDir,
}: ISwimmersNotInEventProps) {
  const impersonateClub = useStoreState(
    (state: StateMapper<AppDataStore>) => state.aliasedClub,
  )
  const [meetRemovedSwimmers, setMeetRemovedSwimmers] = useState<
    UnEnteredSwimmer[]
  >([])
  const [availableSwimmers, setAvailableSwimmers] = useState<
    UnEnteredSwimmer[]
  >([])

  useEffect(() => {

    const newMeetRemovedSwimmers = unEnteredEventSwimmers.filter(
      (uEES) => uEES.attendingMeet === false,
    )
    const newAvailableSwimmers = unEnteredEventSwimmers.filter(
      (uEES) => uEES.attendingMeet !== false,
    )
    if (sortType === "by name") {
      sortUnEnteredSwimmersByName(newMeetRemovedSwimmers, sortDir)
      sortUnEnteredSwimmersByName(newAvailableSwimmers, sortDir)
    } else {
      sortUnEnteredSwimmersByTime(newMeetRemovedSwimmers, sortDir)
      sortUnEnteredSwimmersByTime(newAvailableSwimmers, sortDir)
    }
    setMeetRemovedSwimmers(newMeetRemovedSwimmers)
    setAvailableSwimmers(newAvailableSwimmers)
  }, [
    unEnteredEventSwimmers,
    setMeetRemovedSwimmers,
    setAvailableSwimmers,
    sortDir,
    sortType,
  ])

  const addSwimmerToEvent = (swimmerId: number, isExhibition: boolean) => {
    if (impersonateClub?.clubId) {
      swimminglyApi
        .post(gen("/api/addMeetSwimmers2Post"))
        .body({
          clubId: impersonateClub?.clubId,
          swimmer_id: swimmerId,
          event_id: swimEventId,
          isrelay: isRelay,
          meet_id: meetId,
          is_exhibition: isExhibition,
        })
        .then((data) => {
          refreshSwimmers()
        })
    }
  }

  const addAllSwimmersToEvent = () => {
    const addSwimmers: Promise<any>[] = []
    for (let i = 0; i < availableSwimmers.length; i++) {
      const swimmerId = availableSwimmers[i].swimmer.swimmerId
      addSwimmers.push(
        swimminglyApi
          .post(gen("/api/addMeetSwimmers2Post"))
          .body({
            clubId: impersonateClub?.clubId,
            swimmer_id: swimmerId,
            event_id: swimEventId,
            isrelay: isRelay,
            meet_id: meetId,
            is_exhibition: false,
          })
          .then((data) => {
            refreshSwimmers()
          }),
      )
    }
    Promise.all(addSwimmers).then(refreshSwimmers)
  }

  const removeSwimmerFromAllEvents = (swimmerId: number) => {
    swimminglyApi
      .post(gen("/api/removeSwimmerFromAllEventsPost"))
      .body({
        swimmer_id: swimmerId,
        meet_id: meetId,
      })
      .then(refreshSwimmers)
      .catch(console.error)
  }

  const findBestRelay = async () => {
    let postBody = {
      eligibleSwimmers: availableSwimmers.filter(
        (aS) => aS.attendingMeet !== false,
      ),
      meetId: meetId,
      eventId: swimEventId,
      club: impersonateClub?.clubId,
      bestTimeDefinition: impersonateClub?.best_time_definition,
      unitOfMeasure: meetCourse === "SCY" ? 0 : meetCourse === "SCM" ? 1 : 2,
      convertTimes: true,
    }
    if (availableSwimmers.length >= 4) {
      await swimminglyApi
        .post(gen("/api/calcBestRelay"))
        .body(postBody)
        .then((data) => {
          setBestRelays(data.relays)
          setRelayDisplayName(data.relayName)
          setBestRelaySeedTime(data.time)
          setRelayStrokeType(data.type)
        })
    } else {
      setBestRelays([])
      setRelayDisplayName("")
    }
    setShowBestTimeRelayModal(true)
  }

  return (
    <StyledSwimmerDisplay>
      {isRelay && (
        <div className="relay-button-container">
          <button onClick={findBestRelay} className="relay-button">
            Make fastest relay
          </button>
          <div style={{ display: "flex" }}>
            <p
              style={{
                color: "#25bfea",
                fontSize: "1.5rem",
                cursor: "pointer",
                marginRight: sortType === "by name" ? "5px" : "10px",
              }}
              onClick={() => setSortType("by name")}
            >
              By Name
            </p>
            {sortType === "by name" && sortDir === "desc" ? (
              <span
                id="imgByNameasc"
                onClick={() => setSortDir("aesc")}
                style={Object.assign({ display: "inline" }, divLikeLink, {
                  marginRight: "5px",
                })}
              >
                <img
                  style={{
                    width: "20px",
                    height: "15px",
                  }}
                  src={imageUp}
                  alt="Up Arrow"
                />
              </span>
            ) : null}
            {sortType === "by name" && sortDir === "aesc" ? (
              <span
                id="imgByNamedesc"
                onClick={() => setSortDir("desc")}
                style={Object.assign({ display: "inline" }, divLikeLink)}
              >
                <img
                  style={{
                    width: "20px",
                    height: "15px",
                  }}
                  src={imageDown}
                  alt="Down Arrow"
                />
              </span>
            ) : null}
            <p
              style={{
                color: "#25bfea",
                fontSize: "1.5rem",
                cursor: "pointer",
                marginLeft: "5px",
                marginRight: "5px",
              }}
              onClick={() => setSortType("by time")}
            >
              By Time
            </p>
            {sortType === "by time" && sortDir === "desc" ? (
              <span
                id="imgByTimeasc"
                onClick={() => setSortDir("aesc")}
                style={Object.assign({ display: "inline" }, divLikeLink)}
              >
                <img
                  style={{
                    width: "20px",
                    height: "15px",
                  }}
                  src={imageUp}
                  alt="Up Arrow"
                />
              </span>
            ) : null}
            {sortType === "by time" && sortDir === "aesc" ? (
              <span
                id="imgByTimedesc"
                onClick={() => setSortDir("desc")}
                style={Object.assign({ display: "inline" }, divLikeLink)}
              >
                <img
                  style={{
                    width: "20px",
                    height: "15px",
                  }}
                  src={imageDown}
                  alt="Down Arrow"
                />
              </span>
            ) : null}
          </div>
        </div>
      )}
      {!isRelay && (
        <React.Fragment>
          <div className="sort-filters">
            <div style={{ display: "flex" }}>
              <p
                style={{
                  color: "#25bfea",
                  fontSize: "1.5rem",
                  cursor: "pointer",
                  marginRight: sortType === "by name" ? "5px" : "10px",
                }}
                onClick={() => setSortType("by name")}
              >
                By Name
              </p>
              {sortType === "by name" && sortDir === "desc" ? (
                <span
                  id="imgByNameasc"
                  onClick={() => setSortDir("aesc")}
                  style={Object.assign({ display: "inline" }, divLikeLink, {
                    marginRight: "5px",
                  })}
                >
                  <img
                    style={{
                      width: "20px",
                      height: "15px",
                    }}
                    src={imageUp}
                    alt="Up Arrow"
                  />
                </span>
              ) : null}
              {sortType === "by name" && sortDir === "aesc" ? (
                <span
                  id="imgByNamedesc"
                  onClick={() => setSortDir("desc")}
                  style={Object.assign({ display: "inline" }, divLikeLink)}
                >
                  <img
                    style={{
                      width: "20px",
                      height: "15px",
                    }}
                    src={imageDown}
                    alt="Down Arrow"
                  />
                </span>
              ) : null}
              <p
                style={{
                  color: "#25bfea",
                  fontSize: "1.5rem",
                  cursor: "pointer",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
                onClick={() => setSortType("by time")}
              >
                By Time
              </p>
              {sortType === "by time" && sortDir === "desc" ? (
                <span
                  id="imgByTimeasc"
                  onClick={() => setSortDir("aesc")}
                  style={Object.assign({ display: "inline" }, divLikeLink)}
                >
                  <img
                    style={{
                      width: "20px",
                      height: "15px",
                    }}
                    src={imageUp}
                    alt="Up Arrow"
                  />
                </span>
              ) : null}
              {sortType === "by time" && sortDir === "aesc" ? (
                <span
                  id="imgByTimedesc"
                  onClick={() => setSortDir("desc")}
                  style={Object.assign({ display: "inline" }, divLikeLink)}
                >
                  <img
                    style={{
                      width: "20px",
                      height: "15px",
                    }}
                    src={imageDown}
                    alt="Down Arrow"
                  />
                </span>
              ) : null}
            </div>
          </div>
          <div className="add-all-button">
            <span onClick={addAllSwimmersToEvent} className="add-swimmer">
              +
            </span>
            <span>Add All Swimmers</span>
          </div>
        </React.Fragment>
      )}
      {availableSwimmers.map((aS, idx) => (
        <div className="row" key={`swimmer_${aS.swimmer.swimmerId}_${idx}`}>
          <Tooltip
            title="Add official entry"
            placement="left"
            color={"green"}
            mouseEnterDelay={0.5}

          >
          <span
            onClick={() => addSwimmerToEvent(aS.swimmer.swimmerId, false)}
            className="add-swimmer"
          >
            +
          </span>
          </Tooltip>
          <Tooltip
            title="Click for swimmer details"
            placement="left"
            color={"green"}
            mouseEnterDelay={0.5}
          >
          <span
            className="link-looking-span"
            onClick={() => {
              const swimmer = unEnteredEventSwimmers.find(
                (uEES) => uEES.swimmer.swimmerId === aS.swimmer.swimmerId,
              )?.swimmer
              const meetAge = unEnteredEventSwimmers.find(
                (uEES) => uEES.swimmer.swimmerId === aS.swimmer.swimmerId,
              )?.meetAge
              if (swimmer && meetAge)
                setSwimmerSelectedToViewHistory({ swimmer, meetAge })
              else setSwimmerSelectedToViewHistory(null)
            }}
          >
            {aS.swimmer.firstName} {aS.swimmer.lastName}
            {aS.attendingMeet === true && (
              <CheckCircleTwoTone twoToneColor={"#52c41a"} />
            )}
          </span>
          </Tooltip>
          <div>
            <Tooltip
            title="Individual Entries"
            color={"#25bfea"}
            >
            <span
              className="individual-entries"
            >
              {aS.individualEntries}
            </span>
            </Tooltip>
            <Tooltip
              title="Relay Entries"
              color={"#8092a2"}
            >
            <span
              className="relay-entries"
            >
              {aS.relayEntries}
            </span>
            </Tooltip>
          </div>
          <div />
          <Tooltip
          title="Swimmer's best time * denotes conversion"
          placement="top"
          color={"#25bfea"}
          >
          <span>
            {formatNumberAsTime(aS.previousBest)}
            {aS.previousBest !== null && aS.converted && "*"}
          </span>
          </Tooltip>
          <Tooltip
          title="Not Attending: Remove swimmer from this meet entirely"
          placement="right"
          color={"volcano"}
          mouseEnterDelay={0.5}
          >
          <span
            className="remove-swimmer"
            onClick={() => {
              removeSwimmerFromAllEvents(aS.swimmer.swimmerId)
            }}
          >
            <CloseOutlined />
          </span>
          </Tooltip>
        </div>
      ))}
      {meetRemovedSwimmers.map((mRS, idx) => (
        <div className="row" key={`swimmer_${mRS.swimmer.swimmerId}_${idx}`}>
          <span className="add-swimmer removed-swimmer">+!</span>
          <span className="removed-swimmer">
            {mRS.swimmer.firstName} {mRS.swimmer.lastName}
          </span>
          <div />
          <div />
          <span className="removed-swimmer">
            {formatNumberAsTime(mRS.previousBest)}
            {mRS.previousBest !== null && mRS.converted && "*"}
          </span>
          <Tooltip
          title="Attending: Swimmer is attending, add to the meet"
          placement="right"
          color={"green"}
          >
          <span
            className="add-swimmer-declarations"
            onClick={() => {
              impersonateClub?.clubId &&
                swimminglyApi
                  .post(
                    gen(
                      `/api/declareSwimmerForMeet?swimmerId=${mRS.swimmer.swimmerId}&meetId=${meetId}`,
                    ),
                  )
                  .body({ isAttending: true })
                  .then(refreshSwimmers)
            }}
          >
            +
          </span>
          </Tooltip>
        </div>
      ))}
    </StyledSwimmerDisplay>
  )
}
