import { useState, useEffect, Fragment } from "react"
import moment from "moment-timezone"
import { Redirect } from "react-router-dom"
import {
  convertCourse,
  gen,
  IClub,
  IMeet,
  IUser,
  swimminglyApi,
} from "./utils"
import CenterSpin from "./CenterSpin"
import SwimmerEntriesTableParent from "./SwimmerEntriesTableParent"
import { GuardianHelper } from "./Helpers/GuardianHelper"
import { MeetHelper } from "./Helpers/MeetHelper"

interface IViewMeetLineUpInnerProps {
  club: IClub
  meet: IDeclarationsMeet
}

function ViewMeetLineUpInner({
  club,
  meet,
}: IViewMeetLineUpInnerProps) {
  return (
    <div>
      <header className="page-header">
        <h2>
          Meet Lineup {club.name} {convertCourse[meet.unit_of_measure]}{" "}
          {moment.utc(meet.meet_date).format("MM/DD/YYYY")}
        </h2>
      </header>
      <section className="panel">
        <header
          className="panel-heading"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2 className="panel-title">My Swimmers</h2>
        </header>
        <div className="panel-body">
          <SwimmerEntriesTableParent meet={meet} club={club} />
        </div>
      </section>
    </div>
  )
}

interface IViewMeetLineUpsParentProps {
  meetId: number
  user: IUser | null
}

interface IViewMeetLineUpParentProps extends IViewMeetLineUpsParentProps {
  club: IClub
}

export interface IDeclarationsMeet extends IMeet {
  meetDeclarationsCloseDate: string
  individualLimit: number
  relayLimit: number
}

export function ViewMeetLineUpParent({
  meetId,
  club, 
  user
}: IViewMeetLineUpParentProps) {
  const [meet, setMeet] = useState<IDeclarationsMeet | null>(null)

  useEffect(() => {
    let mounted = true
    
    swimminglyApi
      .post(gen("/api/viewMeetLineUp2DataPost"))
      .body({ user: user, clubId: club.clubId, meetId })
      .then((data) => {
        if (mounted) {
          setMeet(data.meet)
        }
      })
    return () => {
      mounted = false
    }
  }, [user, club, meetId])


  

  return !meet ? null : (
    <ViewMeetLineUpInner
      meet={meet}
      club={club}
    />
  )
}


function Loading({hidden}: {hidden: boolean}) {

  return hidden ? null : (
    <div>
    <header className="page-header">
      <h2>Meet Lineup</h2>
    </header>

    <section className="panel">
      <header className="panel-heading">
        <h2 className="panel-title">Meet Lineup</h2>
      </header>
      <div className="panel-body">
        <CenterSpin />
      </div>
    </section>
  </div>
  )
}


export default function ViewMeetLineUpsParent({
  meetId,
  user
}: IViewMeetLineUpsParentProps) {
  const [ clubs, setClubs ] = useState<IClub[]>([])
  const [ meetClubs, setMeetClubs ] = useState<IClub[]>([])
  const [ guardianClubs, setGuardianClubs ] = useState<IClub[]>([])

  useEffect(() => {
    if (user) {
      new GuardianHelper()
      .getClubsForGuardian(user.userId)
      .then(({status,clubs}) => {
          setGuardianClubs(clubs)
          if (status != "success") {
            console.log("An error ocurred when retrieving clubs for ViewMeetLineUpsParent")
          }
      })
    }

  }, [user])

  useEffect(() => {
    new MeetHelper()
    .getClubsForMeet(meetId)
    .then(({status, meetClubs}) => {
      setMeetClubs(meetClubs)
      if (status != "success") {
        console.log("An error ocurred when retrieving meet clubs for ViewMeetLineUpsParent")
      }
})
  }, [meetId])

  useEffect(() => {
    const meetClubIds = new Set(meetClubs.map((club) => club.clubId ?? 0))
    const guardianClubsInMeet = guardianClubs.filter((guardianClub) => guardianClub.clubId && meetClubIds.has(guardianClub.clubId))
    setClubs(guardianClubsInMeet)

  }, [guardianClubs, meetClubs])


  if (!user) {
    return ( 
      <Redirect
        to={{
          pathname: "/login",
          state: { targetUrl: `/app/parent/viewMeetLineUp/${meetId}` }
        }}
      />
    )
  }

  return (
  <Fragment>
    <Loading hidden={Boolean(clubs.length)} />
    {clubs.map((club) => {
      return (
        <ViewMeetLineUpParent meetId={meetId} user={user} club={club} />
      )
    })}
  </Fragment>
  )
}
