import styled from "styled-components"
import { meetAgeGroupArr } from "../utils"

const StyledFilters = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
`

const FiltersContainer = styled.div`
  h3 {
    text-align: center;
  }
`

interface ISwimmerAgeAndGenderFilters {
  genderFilter: "Boys" | "Girls" | "Mixed"
  setGenderFilter: (newGenderFilter: "Boys" | "Girls" | "Mixed") => void
  ageGroupFilter: string
  setAgeGroupFilter: (newAgeGroupFilter: string) => void
}

export default function SwimmerAgeAndGenderFilters({
  genderFilter,
  setGenderFilter,
  ageGroupFilter,
  setAgeGroupFilter,
}: ISwimmerAgeAndGenderFilters): JSX.Element {
  return (
    <FiltersContainer className="column">
      <h3>Roster</h3>
      <StyledFilters>
        <select
          value={genderFilter}
          onChange={(e) =>
            setGenderFilter(e.target.value as "Boys" | "Girls" | "Mixed")
          }
        >
          <option value="Mixed">Both</option>
          <option value="Girls">Girl</option>
          <option value="Boys">Boy</option>
        </select>
        <select
          value={ageGroupFilter}
          onChange={(e) => setAgeGroupFilter(e.target.value)}
        >
          {Object.values(meetAgeGroupArr).map((ageGroupText) => (
            <option key={`age_group_${ageGroupText}`} value={ageGroupText}>
              {ageGroupText}
            </option>
          ))}
        </select>
      </StyledFilters>
    </FiltersContainer>
  )
}
