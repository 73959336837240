import { FunctionComponent, useEffect, useState } from "react"
import {
  IPriceModificationCode,
  useClubSeasonManagementContext,
} from "../sharedState"
import { PriceModificationCodeSection } from "./PriceModificationCodeSection"

export const FeeCodes: FunctionComponent = () => {
  return (
    <PriceModificationCodes type="fee" />
  )
}

export const DiscountCodes: FunctionComponent = () => {
  return (
    <PriceModificationCodes type="discount" />
  )
}

interface IPriceModificationCodeProps {
  type: "discount" | "fee"
}


const PriceModificationCodes: FunctionComponent<IPriceModificationCodeProps> = ({type}) => {
  const [discountCodes, setDiscountCodes] = useState<IPriceModificationCode[]>(
    [],
  )
  const { priceModificationCodes } = useClubSeasonManagementContext()

  useEffect(() => {
    setDiscountCodes(
      priceModificationCodes.filter(
        (priceModificationCode) => type === "discount" ? priceModificationCode.priceModificationAmount < 0 : priceModificationCode.priceModificationAmount >= 0
      ),
    )
  }, [priceModificationCodes])

  return (
    <div>
      <PriceModificationCodeSection type={type} codes={discountCodes} />
      
    </div>

  )
}
