import React from "react"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../appData/types"
import styled from "styled-components"

const StyledClubTable = styled.table`
  border: 1px solid var(--mediumgrey);
  border-color: #d2cfcf;
  color: #333;
  font-family: Helvetica, Arial, sans-serif;
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 5px;
    border: 1px solid var(--mediumgrey);
    border-collapse: collapse;
  }

  th {
    background: #f6f6f6;
    color: #757575;
  }

  td {
    color: #777;
  }
`

export default function ClubBlock() {
  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)
  const impersonateClub = useStoreState(
    (state: StateMapper<AppDataStore>) => state.aliasedClub,
  )

  if (!user || user.role === 5) return null

  return (
    <section className="panel">
      <div className="ourpanel-body">
        <StyledClubTable>
          <tbody>
            <tr>
              <th>Club Name</th>
              <th>Club abbrv</th>
              <th>Club ID</th>
              <th>Starter Key</th>
            </tr>
            <tr>
              <td width="25%">{impersonateClub && impersonateClub.nameLong}</td>
              <td width="25%">{impersonateClub && impersonateClub.name}</td>
              <td width="25%">{impersonateClub && impersonateClub.clubId}</td>
              <td width="25%">{impersonateClub && impersonateClub.starter_key}</td>
            </tr>
          </tbody>
        </StyledClubTable>
      </div>
    </section>
  )
}
