import React from "react"
import $ from "jquery"
import moment from "moment-timezone"
import Chart from "chart.js"
import { gen } from "./utils"

let nameToNumb = {
  "Medley Relay": 0,
  Freestyle: 1,
  Backstroke: 2,
  Breaststroke: 3,
  Butterfly: 4,
  "Freestyle Relay": 5,
  IM: 6,
  "Butterfly Relay": 7,
  "Backstroke Relay": 8,
  "Breaststroke Relay": 9,
}


function padSeconds(seconds) {
  let secondsString = seconds.toString()
  if (secondsString.includes(".")) {
    let pieces = secondsString.split(".")
    let seconds = pieces[0]
    let milli = pieces[1]
    return seconds.padStart(2, "0") + "." + milli
  } else {
    return seconds.padStart(2, "0")
  }
}

function formatNumberAsTime(seconds, fixed) {
  let newSeconds = seconds % 60
  let minutes = (seconds - newSeconds) / 60
  let newSecondsString = newSeconds.toFixed(fixed)
  if (minutes === 0) {
    return seconds.toFixed(fixed)
  } else {
    if (newSeconds < 10) {
      return `${minutes}:` + padSeconds(newSecondsString)
    } else {
      return `${minutes}:${newSeconds.toFixed(fixed)}`
    }
  }
}

// function returnData(records) {}

export default class SwimmerTimeCard extends React.Component {
  state = {
    tabText: "Freestyle",
    done: false,
    data: null,
    course: "SCY",

    dataFreeRelay: null,
    dataMedleyRelay: null,
    dataBackRelay: null,
    dataBreastRelay: null,
    dataFlyRelay: null,
  }
  componentDidMount() {
    this.getGraphs(this.state.tabText)
  }
  changeCourse = (newCourse) => {
    if (newCourse === this.state.course) {
      return
    }
    this.setState({ loading: true, course: newCourse }, () => {
      let data = this.state.data
      for (let distance of Object.keys(data)) {
        let timeArr = data[distance]
        for (let i = 0; i < timeArr.length; i++) {
          let time = timeArr[i].time
          if (newCourse === "SCM") {
            timeArr[i].time = (time * 1.11).toFixed(2)
          } else {
            timeArr[i].time = (time / 1.11).toFixed(2)
          }
        }
      }
      this.setState({ data: data, loading: false })
    })
  }
  setTabAndRefresh = (tabText) => {
    this.setState({ loading: true })
    this.getGraphs(tabText)
  }

  getGraphsRelays() {
    let self = this
    let url = gen("/api/getAthleteSwimData")
    $.when(
      $.ajax({
        url: url,
        dataType: "json",
        method: "POST",
        data: {
          swimmer: this.props.swimmer,
          user: this.props.user,
          clubId: this.props.clubId,
          strokeNumb: "5",
          strokeText: "FreestyleRelay",
        },
      }),
      $.ajax({
        url: url,
        dataType: "json",
        method: "POST",
        data: {
          swimmer: this.props.swimmer,
          user: this.props.user,
          clubId: this.props.clubId,
          strokeNumb: "0",
          strokeText: "MedleyRelay",
        },
      }),
      $.ajax({
        url: url,
        dataType: "json",
        method: "POST",
        data: {
          swimmer: this.props.swimmer,
          user: this.props.user,
          clubId: this.props.clubId,
          strokeNumb: "7",
          strokeText: "ButterflyRelay",
        },
      }),
      $.ajax({
        url: url,
        dataType: "json",
        method: "POST",
        data: {
          swimmer: this.props.swimmer,
          user: this.props.user,
          clubId: this.props.clubId,
          strokeNumb: "8",
          strokeText: "BackstrokeRelay",
        },
      }),
      $.ajax({
        url: url,
        dataType: "json",
        method: "POST",
        data: {
          swimmer: this.props.swimmer,
          user: this.props.user,
          clubId: this.props.clubId,
          strokeNumb: "9",
          strokeText: "BreaststrokeRelay",
        },
      }),
    ).done(function (d1, d2, d3, d4, d5) {
      let d1Info = d1[0]
      let d2Info = d2[0]
      let d3Info = d3[0]
      let d4Info = d4[0]
      let d5Info = d5[0]
      self.setState({
        done: true,
        loading: false,
        tabText: "Relays",
        strokeText: d1Info.strokeText,
        dataFreeRelay: d1Info.allDistances,
        dataMedleyRelay: d2Info.allDistances,
        dataFlyRelay: d3Info.allDistances,
        dataBackRelay: d4Info.allDistances,
        dataBreastRelay: d5Info.allDistances,
      })
    })
  }
 
  getGraphs = (tabText) => {
    if (tabText === "Relays") {
      return this.getGraphsRelays()
    }
    let self = this
    let strokeNumb = nameToNumb[tabText]
    let url = gen(`/api/getAthleteSwimData`)

    $.ajax({
      url: url,
      method: "POST",
      dataType: "json",
      data: {
        swimmer: this.props.swimmer,
        user: this.props.user,
        clubId: this.props.clubId,
        strokeNumb: strokeNumb,
        strokeText: tabText,
      },
    }).done(function (data) {
      self.setState({
        data: data.allDistances,
        done: true,
        strokeText: data.strokeText,
        tabText: tabText,
        loading: false,
      })
    })
  }
  renderInnerGraphsRelays(
    dataFreeRelay,
    dataMedleyRelay,
    dataFlyRelay,
    dataBackRelay,
    dataBreastRelay,
    loading,
  ) {
    if (loading) {
      return <div>Loading... </div>
    }

    let allRelays = []
    if (dataFreeRelay) {
      Object.keys(dataFreeRelay).forEach((distance) => {
        allRelays.push(
          <SingleGraph
            distance={distance}
            records={dataFreeRelay[distance]}
            strokeText={"Freestyle Relay"}
            key={distance}
            changeCourse={this.changeCourse}
          />,
        )
      })
    }

    if (dataMedleyRelay) {
      Object.keys(dataMedleyRelay).forEach((distance) => {
        allRelays.push(
          <SingleGraph
            distance={distance}
            records={dataMedleyRelay[distance]}
            strokeText={"Medley Relay"}
            key={distance}
            changeCourse={this.changeCourse}
          />,
        )
      })
    }

    if (dataFlyRelay) {
      Object.keys(dataFlyRelay).forEach((distance) => {
        allRelays.push(
          <SingleGraph
            distance={distance}
            records={dataFlyRelay[distance]}
            strokeText={"Butterfly Relay"}
            key={distance}
            changeCourse={this.changeCourse}
          />,
        )
      })
    }

    if (dataBackRelay) {
      Object.keys(dataBackRelay).forEach((distance) => {
        allRelays.push(
          <SingleGraph
            distance={distance}
            records={dataBackRelay[distance]}
            strokeText={"Backstroke Relay"}
            key={distance}
            changeCourse={this.changeCourse}
          />,
        )
      })
    }

    if (dataBreastRelay) {
      Object.keys(dataBreastRelay).forEach((distance) => {
        allRelays.push(
          <SingleGraph
            distance={distance}
            records={dataBreastRelay[distance]}
            strokeText={"Breast Relay"}
            key={distance}
            changeCourse={this.changeCourse}
          />,
        )
      })
    }

    return allRelays
  }

  renderInnerGraphs = (data, loading) => {
    if (loading) {
      return <div>Loading... </div>
    }
    if (!data) {
      return <div />
    }
    if (data.length === 0) {
      return <div>No data for this event </div>
    }
    return Object.keys(data).map((distance) => {
      return (
        <SingleGraph
          distance={distance}
          records={data[distance]}
          strokeText={this.state.strokeText}
          key={distance}
          changeCourse={this.changeCourse}
        />
      )
    })
  }

  render() {
    return (
      <div>
        <h4>Swimmer Progression</h4>
        <div className="row" style={{ marginBottom: "5px" }}>
          <div
            className="col-sm-2 myTab"
            onClick={() => this.setTabAndRefresh("Freestyle")}
            style={
              this.state.tabText === "Freestyle"
                ? { background: "#25bfea", color: "black" }
                : { color: "black" }
            }
          >
            Freestyle
          </div>
          <div
            className="col-sm-2 myTab"
            onClick={() => this.setTabAndRefresh("Backstroke")}
            style={
              this.state.tabText === "Backstroke"
                ? { background: "#25bfea", color: "black" }
                : { color: "black" }
            }
          >
            Backstroke
          </div>
          <div
            className="col-sm-2 myTab"
            onClick={() => this.setTabAndRefresh("Breaststroke")}
            style={
              this.state.tabText === "Breaststroke"
                ? { background: "#25bfea", color: "black" }
                : { color: "black" }
            }
          >
            Breaststroke
          </div>
          <div
            className="col-sm-2 myTab"
            onClick={() => this.setTabAndRefresh("Butterfly")}
            style={
              this.state.tabText === "Butterfly"
                ? { background: "#25bfea", color: "black" }
                : { color: "black" }
            }
          >
            Butterfly
          </div>
          <div
            className="col-sm-2 myTab"
            onClick={() => this.setTabAndRefresh("IM")}
            style={
              this.state.tabText === "IM"
                ? { background: "#25bfea", color: "black" }
                : { color: "black" }
            }
          >
            IM
          </div>
          <div
            className="col-sm-2 myTab"
            onClick={() => this.setTabAndRefresh("Relays")}
            style={
              this.state.tabText === "Relays"
                ? { background: "#25bfea", color: "black" }
                : { color: "black" }
            }
          >
            Relays
          </div>
        </div>
        <div
          style={{
            fontSize: "10px",
            fontWeight: 700,
          }}
        >
          Hover over any point to see details of that swim!
        </div>
        <div className="form-group">
          {this.state.tabText !== "Relays"
            ? this.renderInnerGraphs(this.state.data, this.state.loading)
            : this.renderInnerGraphsRelays(
              this.state.dataFreeRelay,
              this.state.dataMedleyRelay,
              this.state.dataFlyRelay,
              this.state.dataBackRelay,
              this.state.dataBreastRelay,
              this.state.loading,
            )}
        </div>
      </div>
    )
  }
}

class SingleGraph extends React.Component {
  componentWillUnmount() {
    let chart = this.chart
    chart.destroy()
  }
  refreshChart = () => {
    // let timeFormat = "MM/DD/YYYY"
    // let color = Chart.helpers.color
    let distance = this.props.distance
    let records = this.props.records
    for (let i = 0; i < records.length; i++) {
      records[i].dateTime = moment(records[i].date).toDate()
      records[i].timeAsNumb = Number(records[i].time)
    }

    // let ctx = document.getElementById('placeholder'+index);
    let labels = records.map((el) => el.dateTime)
    let yPoints = records.map((el) =>
      el.unit_measure === 0 ? el.timeAsNumb : el.timeAsNumb / 1.11,
    )
    let y2Points = records.map((el) =>
      el.unit_measure === 0 ? el.timeAsNumb * 1.11 : el.timeAsNumb,
    )
    let isSCYArr = records.map((el) => el.unit_measure === 0)
    let canvas = this.refs.thisChart
    let ctx = canvas.getContext("2d")
    ctx.clearRect(0, 0, ctx.width, ctx.height)
    let myChart = new Chart(ctx, {
      type: "line",
      data: {
        labels: labels,
        datasets: [
          {
            myLabel: distance + " " + this.props.strokeText,
            backgroundColor: "#25bfea",
            borderColor: "#25bfea",
            fill: false,
            lineTension: 0.1,
            data: yPoints,
            radius: 8,
          },
          {
            myLabel: distance + " " + this.props.strokeText,
            backgroundColor: "#0498BA",
            borderColor: "#0498BA",
            fill: false,
            lineTension: 0.1,
            data: y2Points,
            radius: 8,
          },
        ],
      },
      options: {
        title: {
          text: "Chart.js Time Scale",
        },
        hover: { mode: "nearest" },

        legend: {
          display: false,
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, chart) {
              let stroke = chart.datasets[0].myLabel
              let time = formatNumberAsTime(tooltipItem.yLabel, 2)
              let otherString = ""
              if (
                (isSCYArr[tooltipItem.index] &&
                  tooltipItem.datasetIndex === 1) ||
                (!isSCYArr[tooltipItem.index] && tooltipItem.datasetIndex === 0)
              ) {
                otherString = "*"
              }

              if (tooltipItem.datasetIndex === 0) {
                return `${stroke} SCY: ${otherString}${time}`
              } else {
                return `${stroke} SCM: ${otherString}${time}`
              }
            },
          },
        },
        scales: {
          xAxes: [
            {
              type: "time",
              time: {
                tooltipFormat: "ll",
                displayFormats: {
                  hour: "MMM D",
                  minute: "MMM D",
                  second: "MMM D",
                  millisecond: "MMM D",
                },
              },
              scaleLabel: {
                display: true,
                labelString: "Date",
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                callback: function (label, index, labels) {
                  return formatNumberAsTime(label, 1)
                },
              },

              scaleLabel: {
                display: true,
                labelString: "Time",
              },
            },
          ],
        },
      },
    })
    this.chart = myChart
  }
  componentDidMount() {
    this.refreshChart()
  }
  componentDidUpdate() {
    this.refreshChart()
  }
  render() {
    return (
      <div>
        <div
          style={{
            display: "flex",
            fontWeight: 700,
            justifyContent: "space-between",
            marginBottom: "20px",
            height: "70px",
          }}
        >
          <div style={{ width: "110px" }}>&nbsp;</div>
          <div style={{ alignSelf: "center", fontSize: "24px" }}>
            {this.props.distance + " " + this.props.strokeText}
          </div>
          <div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "110px", marginBottom: "10px" }}>
                25 Meters (SCM)
              </div>{" "}
              <div
                style={{
                  height: "30px",
                  width: "50px",
                  backgroundColor: "#0498BA",
                }}
              />
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "110px" }}>25 Yards (SCY)</div>{" "}
              <div
                style={{
                  height: "30px",
                  width: "50px",
                  backgroundColor: "#25bfea",
                }}
              />
            </div>
          </div>
        </div>
        <canvas ref="thisChart" />
        <div
          style={{
            display: "flex",
            fontWeight: 700,
            justifyContent: "flex-end",
          }}
        >
          *indicates time has been converted
        </div>
      </div>
    )
  }
}