import { DownloadOutlined } from "@ant-design/icons";
import { Button, Tag, Switch,  message } from "antd";
import { FunctionComponent, useEffect, useState } from "react";
import { IClubSeasonInfo, URLs } from "../../utils";
import { IIsRegistrationEnabled, IRegistrationStatus, RegistrationHelper } from "../RegistrationConfiguration/RegistrationHelper"
import { gen, swimminglyApi } from "../../utils"
import { Colors } from "../styles";
import axios from "axios"
import moment from "moment";

type TRegistrationStatusProps = {
    selectedClubSeason: IClubSeasonInfo | null
}
export const RegistrationStatus: FunctionComponent<TRegistrationStatusProps> = ({ selectedClubSeason }) => {
    const [statusResult, setStatusResult] = useState<IRegistrationStatus>()
    const clubId = selectedClubSeason?.clubId
    const seasonId = selectedClubSeason?.seasonId
    const [isRegistrationEnabled, setIsRegistrationEnabled] = useState(true)

    useEffect(() => {
        const registrationHelper = new RegistrationHelper()
        if (seasonId && clubId) {
            // setting the no. of athletes and no. of registrations
            registrationHelper.registrationStatus(clubId, seasonId)
                .then((statusResult) => {
                    setStatusResult(statusResult)
                })

            // registration open/closed
            registrationHelper.isRegistrationEnabled(clubId, seasonId)
                .then((registrationEnabled: IIsRegistrationEnabled) => {
                    setIsRegistrationEnabled(registrationEnabled.isRegistrationEnabled)
                })
        }
    }, [clubId, seasonId])

    const onChange = (checked: boolean) => {
            const registrationHelper = new RegistrationHelper()
            if(clubId && seasonId){
                registrationHelper.updateRegistrationEnabled(clubId, seasonId, checked)
                .then((registrationEnabledResult) => {
                    if (registrationEnabledResult.status === "success") {
                        setIsRegistrationEnabled(registrationEnabledResult.isRegistrationEnabled)
                    } 
                })
                .catch(() => {
                    message.error("There was a problem opening/closing registration")
                })
            }
    }

    const fileName = `${moment().format("L")}_${selectedClubSeason?.clubName}_${selectedClubSeason?.seasonName}`

    const downloadAs = () => {
        const registrationHelper = new RegistrationHelper()
        if (clubId && seasonId){
            registrationHelper.getRegistrationReport(clubId, seasonId)
            .then(response => {
                
                const a = document.createElement("a");
               
               const url = window.URL.createObjectURL(response);
               a.href = url;
               a.download = fileName;
               a.click();
            })
            .catch(err => {
                console.log("error", err);
            });
        }

    };

    return (
        <div>
            <div style={{marginBottom: "10px"}}>
                <Switch 
                    checkedChildren="Registration is Open" 
                    unCheckedChildren="Registration is Closed"
                    onChange= {onChange}
                    checked={isRegistrationEnabled}
                    />
            </div>
            <div>
                <Tag color={Colors.veryDarkBlue}>
                    No. of Athletes Registered: {statusResult?.countSwimmers}
                </Tag>
                <Tag color={Colors.primaryBlue}>
                    No. of Registrations: {statusResult?.countRegistrations}
                </Tag>
                <Button type="primary" shape="round" icon={<DownloadOutlined />} size="small"
                    onClick={() => {
                        downloadAs()
                    }
                    }
                >
                    Registration Report
                </Button>
            </div>
        </div>

    )

}