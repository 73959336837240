import { useState, useEffect, useCallback } from "react"
import { Button, message, Modal, Select } from "antd"
import { swimminglyApi, gen } from "./utils"
import { updateSeasonMeetArrayListener } from "./MeetSchedulePageNew"
import { IClubSeasonInformation } from "./MeetActionsModal"

const { Option } = Select

interface IRegularMeetCourseModalProps {
  visible: boolean
  setVisible: (newVisible: boolean) => void
  meetId: number
  clubSeason: IClubSeasonInformation | null
}

interface IPool {
  poolId: number | null
  pool_name: string | null
  course_label: "SCY" | "SCM" | "LCM" | null
  poolLengthInCourseUnits: number | null
  address: string | null
}

export default function RegularMeetCourseModal({
  visible,
  setVisible,
  meetId,
  clubSeason,
}: IRegularMeetCourseModalProps): JSX.Element {
  const [currentPool, setCurrentPool] = useState<IPool | null>(null)
  const [newPool, setNewPool] = useState<IPool | null>(null)
  const [allPools, setAllPools] = useState<IPool[] | null>(null)
  const [mounted, setMounted] = useState(false)
  const [loading, setLoading] = useState(false)

  const getPossiblePools = useCallback(() => {
    swimminglyApi
      .post(gen("/api/getAllPossibleLeaguePoolsForMeet"))
      .body({ meetId })
      .then((data) => {
        if (data.status === "success" && data.pools) return data.pools
        else return null
      })
      .then((possiblePools: IPool[] | null) => {
        if (!possiblePools) {
          message.error("Something went wrong finding pools for meet")
          return
        }

        // Trace TODO - get rid of these hard-codes and pull them in from server
        let genericPools: IPool[] = [
          {
            poolId: 2214,
            pool_name: "generic scy pool",
            course_label: "SCY",
            poolLengthInCourseUnits: 25.0,
            address: null,
          },
          {
            poolId: 2215,
            pool_name: "generic scm pool",
            course_label: "SCM",
            poolLengthInCourseUnits: 25.0,
            address: null,
          },
          {
            poolId: 2216,
            pool_name: "generic lcm pool",
            course_label: "LCM",
            poolLengthInCourseUnits: 50.0,
            address: null,
          },
        ]

        setAllPools([...possiblePools, ...genericPools])
        return
      })
  }, [meetId, setAllPools])

  const getCurrentMeetPool = useCallback(() => {
    swimminglyApi
      .post(gen("/api/getCurrentMeetPool"))
      .body({ meetId })
      .then((data) => {
        if (data.status === "success" && data.meetPool) return data.meetPool
        else return null
      })
      .then((currentMeetPool) => {
        if (!currentMeetPool) {
          message.error("Problem finding current pool for this meet.")
          return
        }
        setCurrentPool(currentMeetPool)
        return
      })
  }, [meetId, setCurrentPool])

  useEffect(() => {
    if (mounted === false && visible === true && meetId) {
      Promise.all([getPossiblePools(), getCurrentMeetPool()]).then(() => {
        setMounted(true)
        return
      })
      return
    }
  }, [
    getCurrentMeetPool,
    getPossiblePools,
    setMounted,
    meetId,
    mounted,
    visible,
  ])

  const updatePool = async () => {
    setLoading(true)
    if (
      !newPool ||
      !newPool.poolId ||
      !currentPool ||
      newPool.poolId === currentPool.poolId
    ) {
      message.error("Must choose a new pool for the meet.")
      return
    }
    swimminglyApi
      .post(gen("/api/updateMeetPool"))
      .body({ meetId: meetId, newPoolId: newPool.poolId })
      .then((data) => {
        if (data.status === "success") {
          message.success("Updated meet pool successfully!")
        } else {
          message.error("Failed to update meet pool.")
        }
      })
      .finally(() => setLoading(false))
  }

  const closeModal = () => {
    setMounted(false)
    setVisible(false)
    setCurrentPool(null)
    setNewPool(null)
    setAllPools(null)
  }

  const onOk = async () => {
    await updatePool()
    await getCurrentMeetPool()
    closeModal()
    if (clubSeason) {
      updateSeasonMeetArrayListener.emit(
        `updateSeasonMeets${clubSeason.clubSeasonId}`,
      )
    }
  }

  const onCancel = () => {
    closeModal()
  }

  const constructPoolIdentifier = (pool: IPool) =>
    `${pool.poolId || ""}|${pool.pool_name || ""}`

  return (
    <Modal
      title="Update Meet Pool"
      visible={visible}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={onOk}>
          Ok
        </Button>,
      ]}
    >
      {mounted === true ? (
        <div>
          <p>
            Choose below to update the pool for your meet. You have currently
            set the home pool to {currentPool && currentPool.pool_name}.
          </p>
          {newPool &&
            currentPool &&
            currentPool.course_label !== newPool.course_label && (
              <p>
                NOTE: you have selected a pool with a different course than the
                previous course of this meet. If you update to this pool all
                meet entries will be automatically converted to the new meet
                course.
              </p>
            )}
          <Select
            style={{ minWidth: "380px" }}
            value={newPool ? constructPoolIdentifier(newPool) : ""}
            onChange={(value) => {
              const [thePoolIdString, poolName] = value.split("|")
              let thePoolId = parseInt(thePoolIdString)
              const thePool = allPools?.find(
                (pool) =>
                  pool.poolId === thePoolId && pool.pool_name === poolName,
              )
              if (!thePool) {
                message.error("Uh oh, problem finding the pool.")
              }
              setNewPool(thePool || null)
            }}
          >
            <Option value={""} disabled={newPool !== null}>
              Choose pool...
            </Option>
            {allPools &&
              allPools.map((onePool, index) => {
                return (
                  <Option
                    key={`poolOption${index}`}
                    value={constructPoolIdentifier(onePool)}
                  >
                    {onePool.pool_name} ({onePool.course_label})
                  </Option>
                )
              })}
          </Select>
        </div>
      ) : (
        <p>loading...</p>
      )}
    </Modal>
  )
}
