import React from "react"
import { Input, Button, message, Alert } from "antd"
import { gen } from "./utils"
import { withRouter } from "react-router-dom"

function emailIsValid(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

class PasswordReset extends React.Component {
  state = {
    validEmail: true,
    invalidEmailMessage: null,
    email: "",
    validPassword: true,
    invalidPasswordMessage: null,
    password: "",
    password2: "",
    loading: false,
  }

  onSubmit = async () => {
    let thereIsAProblem = false
    await this.setState({
      validEmail: true,
      invalidEmailMessage: null,
      validPassword: true,
      invalidPasswordMessage: null,
    })
    let { password, password2, email } = this.state
    if (!emailIsValid(email)) {
      await this.setState({
        validEmail: false,
        invalidEmailMessage: "Something looks wrong with this email",
      })
      thereIsAProblem = true
    }
    if (/\s/.test(email)) {
      await this.setState({
        validEmail: false,
        invalidEmailMessage: "Uh oh, your email can't have a space in it.",
      })
      thereIsAProblem = true
    }
    if (password.trim() !== password) {
      await this.setState({
        validPassword: false,
        invalidPasswordMessage: "password shouldn't start or end with a space",
      })
      thereIsAProblem = true
    }
    if (password !== password2) {
      await this.setState({
        validPassword: false,
        invalidPasswordMessage: "passwords don't match",
      })
      thereIsAProblem = true
    }

    if (thereIsAProblem) {
      return
    }

    await this.setState({ loading: true })
    let data = {
      email,
      password,
      password_confirmation: this.state.password2,
      token: this.props.token,
    }

    let response
    try {
      response = await fetch(gen(`/api/passwordReset`), {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
      })
    } catch (error) {
      console.log(error)
    }
    let result = await response.json()
    if (result.error) {
      message.error(result.message)
      await this.setState({ loading: false })
      return
    }
    this.setState({ loading: false })
    message.success("Password successfully changed")
    this.props.history.push("/")
    // $.ajax({
    //   url: gen("/api/passwordReset"),
    //   method: "POST",
    //   data: data,
    // }).done((data) => {
    //   this.setState({ loading: false })
    //   message.success("Password successfully changed")
    //   this.props.history.push("/")
    // })
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "30px",
        }}
      >
        <section className="panel" style={{ width: "350px" }}>
          <div className="panel-heading">Reset Password</div>
          <div className="ourpanel-body">
            <Input
              type="email"
              value={this.state.email}
              onChange={(e) => this.setState({ email: e.target.value })}
              placeholder="Email Address"
            />
            {!this.state.validEmail ? (
              <Alert
                message={
                  this.state.invalidEmailMessage
                    ? this.state.invalidEmailMessage
                    : "fix email"
                }
                type="error"
                closable
              />
            ) : null}
            <Input
              value={this.state.password}
              style={{ marginTop: "5px" }}
              onChange={(e) => this.setState({ password: e.target.value })}
              type="password"
              placeholder="Password"
            />
            <Input
              value={this.state.password2}
              style={{ marginTop: "5px" }}
              type="password"
              onChange={(e) => this.setState({ password2: e.target.value })}
              placeholder="Password (repeat)"
            />
            {!this.state.validPassword ? (
              <Alert
                message={
                  this.state.invalidPasswordMessage
                    ? this.state.invalidPasswordMessage
                    : "fix password"
                }
                type="error"
                closable
              />
            ) : null}
            <Button
              style={{ width: "100%", marginTop: "10px" }}
              type="primary"
              onClick={this.onSubmit}
              loading={this.state.loading}
            >
              Reset Password
            </Button>
          </div>
        </section>
      </div>
    )
  }
}

export default withRouter(PasswordReset)
