import React from "react"
import { Button, message, Row, Col, Input, Select } from "antd"
import { withRouter } from "react-router-dom"
import { roleMapping, gen, swimminglyApi } from "./utils"
const { Option } = Select

class AddClub extends React.Component {
  state = {
    name: "",
    abbr: "",
    starter_key: "fun",
    leagueId: "",
    loading: false,
    leagueArr: [],
  }

  componentDidMount() {
    // console.log(this.props)
    if (this.props.user.role === 2) {
      swimminglyApi.get(gen("/api/getAllLeagues")).then((data) => {
        let firstArg =
          data.leaguesArr && data.leaguesArr[0] && data.leaguesArr[0].id
        this.setState({
          leagueArr: data.leaguesArr,
          leagueId: firstArg,
        })
      })
    }
  }

  onClickSubmit = () => {
    let leagueId = this.state.leagueId || this.props.user.league_id
    const { name, abbr, starter_key } = this.state

    if (!name || !abbr || !starter_key || !leagueId) {
      message.error("Can't have any empty fields")
      return
    }

    const obj = {
      club_name_long: name,
      club_name: abbr,
      starter_key: starter_key,
      public_result_turn_off: "1",
      is_meet_central_club: false,
      is_paid: false,
      pay_by_date: null,
      sd3_lsc: "",
      sd3_team_code: "",
      league_id: leagueId,
      unit_of_measure: 0,
      number_of_lanes: 6,
      best_time_definition: null,
    }

    this.setState({ loading: true })

    swimminglyApi
      .post(gen("/api/addClub2Post"))
      .body(obj)
      .then((data) => {
        message.success("Club created")
        this.setState({ loading: false })
        this.props.history.push("/app/admin/club")
      })
      .catch((error) => {
        console.log(error)
        message.error("Failure creating club")
      })
  }

  render() {
    return (
      <div>
        <header className="page-header">
          <h2>Add Club</h2>
        </header>

        <header className="panel-heading">
          <h2 className="panel-title">Add Club</h2>
        </header>
        <div className="ourpanel-body">
          <Row style={{ paddingBottom: "15px" }}>
            <Col span={6}>Club Name:</Col>
            <Col span={12}>
              <Input
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
                placeholder="Club Name"
              />
            </Col>
          </Row>

          <Row style={{ paddingBottom: "15px" }}>
            <Col span={6}>Club Abbr:</Col>
            <Col span={12}>
              Use your club's shorthand name or abbreviation for optimal heat
              sheet formatting
              <Input
                value={this.state.abbr}
                onChange={(e) => this.setState({ abbr: e.target.value })}
                placeholder="Club Abbr"
              />
            </Col>
          </Row>

          <Row style={{ paddingBottom: "15px" }}>
            <Col span={6}>Starter Key:</Col>
            <Col span={12}>
              This is the password that the Scorekeeper will use to download
              your meet schedule the day of the meet. This should be a "team
              password" that is known by your club administrators.
              <Input
                value={this.state.starter_key}
                onChange={(e) => this.setState({ starter_key: e.target.value })}
                placeholder="Starter Key"
              />
            </Col>
          </Row>
          {roleMapping[this.props.user.role] === "Hydro Admin" && (
            <Row style={{ paddingBottom: "15px" }}>
              <Col span={6}>League:</Col>
              <Col span={12}>
                <Select
                  style={{ width: "465px" }}
                  value={this.state.leagueId}
                  onChange={(val) => this.setState({ leagueId: val })}
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    )
                  }}
                  showSearch
                >
                  {this.state.leagueArr.map((league) => {
                    return (
                      <Option value={league.id} key={league.id}>
                        {league.name}
                      </Option>
                    )
                  })}
                </Select>
              </Col>
            </Row>
          )}

          <Row>
            <Col span={6} />
            <Col span={12}>
              <Button
                type="primary"
                loading={this.state.loading}
                onClick={this.onClickSubmit}
              >
                Save
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default withRouter(AddClub)
