import React from "react"
import $ from "jquery"
import InviteLeagueAdmin from "./InviteLeagueAdmin"
import AdminUsersLeague from "./AdminUsersLeague"
import CenterSpin from "./CenterSpin"
import { Input } from "antd"
import { toLowerN, gen } from "./utils"
export default class ManageLeagueUsers extends React.Component {
  state = {
    adminUsers: [],
    loaded: false,
    value: "",
  }

  componentDidMount() {
    this.getClubUsers()
  }

  getClubUsers = () => {
    this.setState({ loaded: false })
    $.ajax({
      url: gen("/api/getLeagueUsers2Post"),
      method: "POST",
      dataType: "json",
      data: {
        userId: this.props.user.userId,
      },
    }).done((data) => {
      this.setState({ adminUsers: data.clubUsersArr, loaded: true })
    })
  }
  render() {
    if (!this.state.loaded) {
      return (
        <div>
          <header className="page-header">
            <h2>League Admins</h2>
          </header>

          <section className="panel">
            <header className="panel-heading">
              <h2 className="panel-title">League Admins</h2>
            </header>
            <div className="ourpanel-body">
              <CenterSpin />
            </div>
          </section>
        </div>
      )
    }

    let curUsers = this.state.adminUsers

    if (this.state.value) {
      curUsers = this.state.adminUsers.filter((el) => {
        return (
          toLowerN(el.name).includes(toLowerN(this.state.value)) ||
          toLowerN(el.email).includes(toLowerN(this.state.value))
        )
      })
    }

    return (
      <div>
        <header className="page-header">
          <h2>League Admins</h2>
        </header>

        <section className="panel">
          <header className="panel-heading">
            <h2 className="panel-title">
              League Admins{" "}
              <span style={{ fontSize: "16px" }}>
                ({this.state.adminUsers.length} Users)
              </span>
            </h2>
          </header>
          <div className="ourpanel-body">
            <InviteLeagueAdmin
              user={this.props.user}
              leagueId={this.props.user.league_id}
            />
            <Input
              type="text"
              value={this.state.value}
              onChange={(e) => this.setState({ value: e.target.value })}
              style={{
                marginBottom: "10px",
                marginTop: "10px",
                width: "200px",
              }}
              placeholder="Search"
            />
            <AdminUsersLeague
              adminUsers={curUsers}
              getClubUsers={this.getClubUsers}
              user={this.props.user}
            />
          </div>
        </section>
      </div>
    )
  }
}
