import React from "react"
import GenericModal from "./GenericModal"
import { Button } from "antd"

interface IIndividualEntryLimitModalProps {
  addSwimmersToMeet: (swimmerId: number, eventId: number, val: boolean) => void
  swimmerId: number
  eventId: number
  visible: boolean
  setVisible: (val: boolean) => void
}

export default function IndividualEntryLimitModal({
  addSwimmersToMeet,
  swimmerId,
  eventId,
  visible,
  setVisible,
}: IIndividualEntryLimitModalProps) {
  return (
    <GenericModal
      visible={visible}
      setVisible={setVisible}
      title="Individual Limit Reached"
      footer={() => (
        <>
          <Button
            key="add"
            onClick={() => {
              addSwimmersToMeet(swimmerId, eventId, false)
              setVisible(false)
            }}
          >
            Add
          </Button>
          <Button
            key="addAsEx"
            onClick={() => {
              addSwimmersToMeet(swimmerId, eventId, true)
              setVisible(false)
            }}
          >
            Add As Exhibition
          </Button>
          <Button key="close" onClick={() => setVisible(false)}>
            Close
          </Button>
        </>
      )}
    >
      <p>
        If you click OK, this swimmer will be added to the event despite being
        over the individual limit.
      </p>
    </GenericModal>
  )
}
