import React from "react"
import $ from "jquery"
import { validateEmail, gen } from "./utils"
import { message, Button } from "antd"

export default class AddUserLeague extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: props.email ? props.email : "",
      firstName: "",
      password: "",
      password2: "",
      lastName: "",
      phoneNumber: "",
      title: "SwimminglyRep",
      role: 3,
      isEdit: false,
      userIdEdit: null,

      emailMessage: "",
      firstNameMessage: "",
      lastNameMessage: "",
      phoneNumberMessage: "",
      passwordMessage: "",
      password2Message: "",

      loading: false,
    }
  }

  onSubmitFunc = (e) => {
    let isGood = this.onCheckValues()
    if (!isGood) {
      return
    }

    // let val = confirm("Do you want to send this user their activation email now? If you cancel you can always send it later.")
    this.setState({ loading: true })
    $.ajax({
      url: gen("/api/registerPost"),
      method: "POST",
      data: {
        page: "league",
        email: this.state.email,
        FirstName: this.state.firstName,
        LastName: this.state.lastName,
        PhoneNumber: this.state.phoneNumber,
        title: this.state.title,
        league_id: this.props.leagueId,
        club_id: "",
        role: this.state.role,
        is_edit: false,
        id: "",
        send_validation_now: true,
        password: this.state.password,
        active: this.props.active,
        userId: this.props.userId,
      },
      error: () => {
        message.error("Error processing data")
        this.setState({ loading: false })
      },
      success: (response) => {
        if (response.error) {
          message.error(response.error)
        } else {
          // message.success("User added successfully")
          if (this.props.callAfterSuccess) {
            this.props.callAfterSuccess()
          } else {
            window.location.reload()
          }
        }
        this.setState({ loading: false })
      },
    })
  }

  onCheckValues = () => {
    let isGood = true
    if (!validateEmail(this.state.email)) {
      this.setState({ emailMessage: "Not a valid email" })
      isGood = false
    }

    if (!this.state.password.length > 4) {
      this.setState({
        passwordMessage: "Password must be at least 4 characters long",
      })
      isGood = false
    }

    if (this.state.password !== this.state.password2) {
      this.setState({ password2Message: "Passwords must be identical" })
      isGood = false
    }

    if (!this.state.firstName) {
      this.setState({ firstNameMessage: "Not a valid first name" })
      isGood = false
    }

    if (this.state.firstName.length < 3) {
      this.setState({
        firstNameMessage: "First name must be at least 3 characters",
      })
      isGood = false
    }

    if (this.state.firstName.length > 255) {
      this.setState({
        firstNameMessage: "First name must be at less than 255 characters",
      })
      isGood = false
    }

    if (!this.state.lastName) {
      this.setState({ lastNameMessage: "Not a valid last name" })
      isGood = false
    }

    if (this.state.lastName.length < 3) {
      this.setState({
        lastNameMessage: "Last name must be at least 3 characters",
      })
      isGood = false
    }

    if (this.state.lastName.length > 255) {
      this.setState({
        lastNameMessage: "Last name must be at less than 255 characters",
      })
      isGood = false
    }

    if (!this.state.phoneNumber) {
      this.setState({ phoneNumberMessage: "Not a valid phone number" })
      isGood = false
    }
    let onlyDigits = this.state.phoneNumber.replace(/[^0-9]/, "")
    if (onlyDigits.length < 10) {
      this.setState({ phoneNumberMessage: "Needs at least 10 digits" })
      isGood = false
    }
    return isGood
  }

  printClubInfo = () => {
    return this.props.leagueName ? `for ${this.props.leagueName}` : ""
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-lg-12">
            <h2 className="panel-title">
              Add League User {this.printClubInfo()}
            </h2>
          </div>
        </div>

        <div className="ourpanel-body marg-t20 bornone">
          <div className="form-group">
            <div className="row">
              <label className="col-sm-4 ">Email</label>
              <div className="col-sm-8">
                <input
                  id="email"
                  name="email"
                  value={this.state.email}
                  onChange={
                    () => {} /*(e) =>
                          this.setState({ email: e.target.value, emailMessage: "" })
                        */
                  }
                  className="form-control"
                  placeholder="Email Address"
                  type="email"
                  disabled={true}
                />
                {this.state.emailMessage && (
                  <span id="spanUserName" className="field-validation-error">
                    {this.state.emailMessage}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <label className="col-sm-4 ">Password</label>
              <div className="col-sm-8">
                <input
                  id="password"
                  name="password"
                  value={this.state.password}
                  onChange={(e) =>
                    this.setState({
                      password: e.target.value,
                      passwordMessage: "",
                    })
                  }
                  className="form-control"
                  placeholder="password"
                  type="password"
                />

                {this.state.passwordMessage && (
                  <span id="spanUserName" className="field-validation-error">
                    {this.state.passwordMessage}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <label className="col-sm-4 ">Password (confirm)</label>
              <div className="col-sm-8">
                <input
                  id="password2"
                  name="password2"
                  value={this.state.password2}
                  onChange={(e) =>
                    this.setState({
                      password2: e.target.value,
                      password2Message: "",
                    })
                  }
                  className="form-control"
                  placeholder="password (confirm)"
                  type="password"
                />

                {this.state.password2Message && (
                  <span id="spanUserName" className="field-validation-error">
                    {this.state.password2Message}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <label className="col-sm-4 ">First Name</label>
              <div className="col-sm-8">
                <input
                  id="FirstName"
                  name="FirstName"
                  className="form-control"
                  placeholder="First Name"
                  type="text"
                  value={this.state.firstName}
                  onChange={(e) =>
                    this.setState({
                      firstName: e.target.value,
                      firstNameMessage: "",
                    })
                  }
                />
                {this.state.firstNameMessage && (
                  <span id="spanUserName" className="field-validation-error">
                    {this.state.firstNameMessage}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <label className="col-sm-4 ">Last Name</label>
              <div className="col-sm-8">
                <input
                  name="LastName"
                  id="LastName"
                  className="form-control"
                  placeholder="Last Name"
                  type="text"
                  value={this.state.lastName}
                  onChange={(e) =>
                    this.setState({
                      lastName: e.target.value,
                      lastNameMessage: "",
                    })
                  }
                />
                {this.state.lastNameMessage && (
                  <span id="spanUserName" className="field-validation-error">
                    {this.state.lastNameMessage}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <label className="col-sm-4 ">Phone Number</label>
              <div className="col-sm-8">
                <input
                  name="PhoneNumber"
                  id="PhoneNumber"
                  className="form-control"
                  placeholder="Phone Number"
                  type="text"
                  value={this.state.phoneNumber}
                  onChange={(e) =>
                    this.setState({
                      phoneNumber: e.target.value,
                      phoneNumberMessage: "",
                    })
                  }
                />
                {this.state.phoneNumberMessage && (
                  <span id="spanUserName" className="field-validation-error">
                    {this.state.phoneNumberMessage}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <label className="col-sm-4 ">Title</label>
              <div className="col-sm-2 distanceClass">
                <select
                  id="person_title"
                  name="title"
                  value={this.state.title}
                  onChange={(e) => this.setState({ title: e.target.value })}
                >
                  <option value="SwimminglyRep">Swimmingly Rep</option>
                  <option value="HeadCoach">Head Coach</option>
                  <option value="Assistant Coach">Assistant Coach</option>
                  <option value="ClubTreasure">Club Treasurer</option>
                  <option value="LeaguePresident">League President</option>
                  <option value="LeagueTreasurer">League Treasurer</option>
                  <option value="League Board">League Board</option>
                </select>
              </div>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="primary"
              onClick={this.onSubmitFunc}
              loading={this.state.loading}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    )
  }
}
