import React, { useState, useEffect, useRef } from "react"
import _, { isEqual } from "lodash"
import { Button, message } from "antd"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../../appData/types"
import { ISwimmerInvoiceInfo } from "."
import { IClubSeasonInfo } from "../utils/sharedDataTypes"
import {
  formatMoney,
  gen,
  swimminglyApi,
  fuzzyMatchString,
  divLikeLink,
} from "../utils"
import imageUp from "../../images/up.png"
import imageDown from "../../images/down.png"
import { StyledArrowImage, StyledInvoice, StyledNameInput } from "./styles"
import { isInThePast } from "../utils/helperFunctions"

import RefundConfirmationModal from "../RefundConfirmationModal"


const SeasonHeader = ({
  seasonName,
  swimmerInvoiceGroups,
  payType,
  clubSeasonId,
  refreshSwimmers
}:{
  seasonName: string
  swimmerInvoiceGroups: SwimmerInvoiceGroup[]
  payType:
    | "offline by organization"
    | "by club"
    | "hybrid parent and club"
    | "by parent"
    | "unexpected payment type"
  clubSeasonId: number
  refreshSwimmers: () => void
}): JSX.Element => {
  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)
  const [loading, setLoading] = useState(false)
  const [amountOwed, setAmountOwed] = useState(0)
  const swimmerInvoices = swimmerInvoiceGroups
    .map((swimmerInvoiceGroup) => swimmerInvoiceGroup.invoices)
    .flat()
  const unpaidInvoiceTypes = ["not paid", "payment failed", "other"]   

  useEffect(() => {
    const unpaidInvoices = swimmerInvoices.filter((swimmerInvoice) => unpaidInvoiceTypes.includes(swimmerInvoice.paymentStatus))
    const amountOwedToClub = unpaidInvoices.reduce((total, swimmerInvoice) => {
      const amountOwedForCurrentInvoice = swimmerInvoice.clubPays ?? 0
      total += amountOwedForCurrentInvoice
      return total
    }, 0)
    setAmountOwed(amountOwedToClub)

  }, [swimmerInvoiceGroups])

  return (
    <React.Fragment>
      <h3 style={{ paddingLeft: "25px" }}>{seasonName}</h3>
      {["by club", "hybrid parent and club"].includes(payType) && (
        <h3 style={{ paddingLeft: "25px" }}>
          ({swimmerInvoices.filter((st) => st.paymentStatus === "paid").length} paid,{" "}
          {swimmerInvoiceGroups.filter((st) => st.status === "refunded").length}{" "}
          refunded,{" "}
          {
            swimmerInvoices.filter((st) =>
              unpaidInvoiceTypes.includes(st.paymentStatus),
            ).length
          }{" "}
          unpaid - {formatMoney(amountOwed)} outstanding)
        </h3>
      )}
      {[2, 3, 4].includes(user?.role || 0) &&
        amountOwed > 0 &&
        ["by club", "hybrid parent and club"].includes(payType) && (
          <Button
            style={
              !loading
                ? {
                    marginLeft: "35px",
                    marginBottom: "10px",
                    padding: "5px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    border: "none",
                    borderRadius: "7px",
                    backgroundColor: "var(--buttonblue)",
                    color: "white",
                  }
                : {
                    marginLeft: "35px",
                    marginBottom: "10px",
                    padding: "5px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    border: "none",
                    borderRadius: "7px",
                  }
            }
            loading={loading}
            onClick={() => {
              setLoading(true)
              const verification = window.confirm(
                "Your club's default card is about to be billed. Are you sure you want to proceed?",
              )
              if (verification) {
                swimminglyApi
                  .get(
                    gen(`/api/payOutstandingClubSeasonBalance/${clubSeasonId}`),
                  )
                  .then((data) => {
                    if (data.status === "success") {
                      message.success("success!")
                    } else {
                      message.error(
                        "Uh oh, there was a problem processing the payment with this club's default card.",
                      )
                    }
                  })
                  .finally(() => {
                    setLoading(false)
                    refreshSwimmers()
                  })
              } else {
                message.warn("You were NOT billed for any swimmers.", 4)
                setLoading(false)
              }
            }}
          >
            Pay Bill
          </Button>
        )}
    </React.Fragment>
  )
}

type SwimmerInvoiceGroup = {
  swimmerId: number
  lastName: string
  firstName: string
  status: "paid" | "refunded" | "not paid" | "payment failed" | "other"
  invoices: ISwimmerInvoiceInfo[]
}



export default function ClubSeasonSwimmerInvoices({
  clubSeason,
}: {
  clubSeason: IClubSeasonInfo
}): JSX.Element {
  const impersonateClub = useStoreState(
    (state: StateMapper<AppDataStore>) => state.aliasedClub,
  )
  const [swimmerInvoices, setSwimmerInvoices] = useState<SwimmerInvoiceGroup[]>(
    [],
  )
  const [sortedSwimmerInvoices, setSortedSwimmerInvoices] = useState<
    SwimmerInvoiceGroup[]
  >([])
  const [sortOption, setSortOption] =
    useState<"lastName" | "firstName">("lastName")
  const [sortDir, setSortDir] = useState<"aesc" | "desc">("aesc")
  const [searchFilter, setSearchFilter] = useState("")
  const [displayGroups, setDisplayGroups] = useState<
    ("paid" | "refunded" | "not paid" | "payment failed" | "other")[]
  >(["paid", "refunded", "not paid", "payment failed", "other"])
  const [filteredSwimmerInvoices, setFilteredSwimmerInvoices] = useState<
    SwimmerInvoiceGroup[]
  >([])
  const [triggerRefresh, setTriggerRefresh] = useState(0)
  const [refundClubId, setRefundClubId] = useState<number | null>(null)
  const [refundClubName, setRefundClubName] = useState<string | null>(null)
  const [refundSeasonId, setRefundSeasonId] = useState<number | null>(null)
  const [refundSeasonName, setRefundSeasonName] = useState<string | null>(null)
  const [refundSwimmerId, setRefundSwimmerId] = useState<number | null>(null)
  const [refundSwimmerName, setRefundSwimmerName] =
    useState<string | null>(null)
  const [refundModalVisible, setRefundModalVisible] = useState(false)

  const previousSwimmerInvoices = useRef<SwimmerInvoiceGroup[]>([])

  useEffect(() => {
    swimminglyApi
      .get(
        gen(`/api/getSwimmerInvoicesByClubSeason/${clubSeason.clubSeasonId}`),
      )
      .then(
        (data: { status: string; swimmerInvoices: ISwimmerInvoiceInfo[] }) => {
          if (data.status === "success") {
            const invoices = data.swimmerInvoices
            const swimmerIds = new Set<number>()
            const allSwimmerTransactions: SwimmerInvoiceGroup[] = []
            for (let i = 0; i < invoices.length; i++) {
              const invoice = invoices[i]
              if (swimmerIds.has(invoice.swimmerId)) {
                const swimmerRecord = allSwimmerTransactions.find(
                  (sT) => sT.swimmerId === invoice.swimmerId,
                )
                swimmerRecord?.invoices.push(invoice)
              } else {
                swimmerIds.add(invoice.swimmerId)
                allSwimmerTransactions.push({
                  swimmerId: invoice.swimmerId,
                  lastName: invoice.lastName,
                  firstName: invoice.firstName,
                  status: "other",
                  invoices: [invoice],
                })
              }
            }
            for (let i = 0; i < allSwimmerTransactions.length; i++) {
              const swimmerInfo = allSwimmerTransactions[i]
              const invoices = swimmerInfo.invoices
              const successfulClubPayments = invoices.filter(
                (inv) =>
                  inv.payingClubId === impersonateClub?.clubId &&
                  inv.paymentStatus === "success" &&
                  inv.paymentAmount >= (inv.clubPays || 0),
              )
              const failedClubPayments = invoices.filter(
                (inv) =>
                  inv.payingClubId === impersonateClub?.clubId &&
                  inv.paymentStatus === "fail" &&
                  inv.paymentAmount >= (inv.clubPays || 0),
              )
              const refundedClubPayments = invoices.filter(
                (inv) =>
                  inv.payingClubId === impersonateClub?.clubId &&
                  inv.paymentStatus === "refund" &&
                  inv.paymentAmount >= (inv.clubPays || 0),
              )
              const successfulParentPayments = invoices.filter(
                (inv) =>
                  inv.payingUserId &&
                  inv.paymentStatus === "success" &&
                  inv.paymentAmount >= (inv.clubPays || 0),
              )
              if (successfulClubPayments.length > 0) swimmerInfo.status = "paid"
              else if (
                refundedClubPayments.length > 0 &&
                failedClubPayments.length > 0
              ) {
                const refundDate = refundedClubPayments
                  .map((p) => p.createdAt)
                  .reduce((a, b) => {
                    if (a > b) return a
                    return b
                  }, "")
                const paymentFailDate = failedClubPayments
                  .map((p) => p.createdAt)
                  .reduce((a, b) => {
                    if (a > b) return a
                    return b
                  }, "")
                if (refundDate > paymentFailDate)
                  swimmerInfo.status = "refunded"
                else swimmerInfo.status = "payment failed"
              } else if (refundedClubPayments.length > 0)
                swimmerInfo.status = "refunded"
              else if (failedClubPayments.length > 0)
                swimmerInfo.status = "payment failed"
              else if (successfulParentPayments.length > 0)
                swimmerInfo.status = "not paid"
            }
            setSwimmerInvoices(allSwimmerTransactions)
          }
        },
      )
  }, [clubSeason.clubSeasonId, impersonateClub?.clubId, triggerRefresh])

  const refreshSwimmers = () => setTriggerRefresh(triggerRefresh + 1)

  useEffect(() => {
    const invoices = [...swimmerInvoices].map((sI, idx) => ({
      invoice: sI,
      idx,
    }))
    const multiplier = sortDir === "aesc" ? 1 : -1
    if (
      !isEqual(previousSwimmerInvoices.current, swimmerInvoices) &&
      sortOption === "lastName"
    ) {
      const invoicesToDisplay = invoices
        .sort((sI1, sI2) => {
          if (
            sI1.invoice.lastName.toLowerCase() >
            sI2.invoice.lastName.toLowerCase()
          )
            return multiplier
          if (
            sI1.invoice.lastName.toLowerCase() <
            sI2.invoice.lastName.toLowerCase()
          )
            return -multiplier
          if (
            sI1.invoice.firstName.toLowerCase() >
            sI2.invoice.firstName.toLowerCase()
          )
            return multiplier
          if (
            sI1.invoice.firstName.toLowerCase() <
            sI2.invoice.firstName.toLowerCase()
          )
            return -multiplier
          if (sI1.idx > sI2.idx) return 1
          if (sI1.idx < sI2.idx) return -1
          return 1
        })
        .map((invoiceData) => invoiceData.invoice)
      setSortedSwimmerInvoices(invoicesToDisplay)
    } else {
      const invoicesToDisplay = invoices
        .sort((sI1, sI2) => {
          if (
            (sI1.invoice[sortOption].toLowerCase() as any) >
            (sI2.invoice[sortOption].toLowerCase() as any)
          )
            return multiplier
          if (
            (sI1.invoice[sortOption].toLowerCase() as any) <
            (sI2.invoice[sortOption].toLowerCase() as any)
          )
            return -multiplier
          if (sI1.idx > sI2.idx) return 1
          if (sI1.idx < sI2.idx) return -1
          return 1
        })
        .map((invoiceData) => invoiceData.invoice)
      setSortedSwimmerInvoices(invoicesToDisplay)
    }
  }, [swimmerInvoices, sortOption, sortDir])

  useEffect(() => {
    let newFilteredSwimmerInvoices = [...sortedSwimmerInvoices]
    newFilteredSwimmerInvoices = newFilteredSwimmerInvoices.filter(
      (invoiceData) => displayGroups.includes(invoiceData.status),
    )
    if (searchFilter.trim() !== "") {
      const fuzzyMatchesLastName = fuzzyMatchString<SwimmerInvoiceGroup>(
        newFilteredSwimmerInvoices,
        (el: SwimmerInvoiceGroup) => el.lastName,
        searchFilter.trim(),
      ).map((inv) => inv.swimmerId)
      const fuzzyMatchesFirstName = fuzzyMatchString<SwimmerInvoiceGroup>(
        newFilteredSwimmerInvoices,
        (el: SwimmerInvoiceGroup) => el.firstName,
        searchFilter.trim(),
      ).map((inv) => inv.swimmerId)
      const fuzzyMatchesFullName = fuzzyMatchString<SwimmerInvoiceGroup>(
        newFilteredSwimmerInvoices,
        (el: SwimmerInvoiceGroup) => `${el.firstName} ${el.lastName}`,
        searchFilter.trim(),
      ).map((inv) => inv.swimmerId)
      newFilteredSwimmerInvoices = newFilteredSwimmerInvoices.filter(
        (invoice) => {
          if (fuzzyMatchesLastName.includes(invoice.swimmerId)) return true
          if (fuzzyMatchesFirstName.includes(invoice.swimmerId)) return true
          if (fuzzyMatchesFullName.includes(invoice.swimmerId)) return true
          return false
        },
      )
    }
    setFilteredSwimmerInvoices(newFilteredSwimmerInvoices)
  }, [sortedSwimmerInvoices, displayGroups, searchFilter])

  useEffect(() => {
    previousSwimmerInvoices.current = swimmerInvoices
  }, [swimmerInvoices])

  const viewRefundModal = (sI: ISwimmerInvoiceInfo) => {
    setRefundClubId(sI.clubId)
    setRefundClubName(sI.clubName)
    setRefundSeasonId(sI.seasonId)
    setRefundSeasonName(sI.seasonName)
    setRefundSwimmerId(sI.swimmerId)
    setRefundSwimmerName(sI.firstName + " " + sI.lastName)
    setRefundModalVisible(true)
  }

  const closeRefundModal = () => {
    setRefundModalVisible(false)
    setRefundClubId(null)
    setRefundClubName(null)
    setRefundSeasonId(null)
    setRefundSeasonName(null)
    setRefundSwimmerId(null)
    setRefundSwimmerName(null)
    setTriggerRefresh(triggerRefresh + 1)
  }


  return (
    <div style={{ marginTop: "25px" }}>
      <SeasonHeader
        seasonName={clubSeason.seasonName}
        swimmerInvoiceGroups={swimmerInvoices}
        payType={clubSeason.paymentType}
        clubSeasonId={clubSeason.clubSeasonId}
        refreshSwimmers={refreshSwimmers}
      />
      {["by club", "hybrid parent and club"].includes(
        clubSeason.paymentType,
      ) ? (
        <div>
          <StyledNameInput
            placeholder="Search by name..."
            value={searchFilter}
            onChange={(e) => setSearchFilter(e.target.value)}
          />
          <StyledInvoice>
            <thead>
              <tr>
                <th>
                  <span
                    style={divLikeLink}
                    onClick={() => {
                      if (sortOption !== "firstName") {
                        setSortOption("firstName")
                        setSortDir("aesc")
                      } else if (sortDir === "aesc") {
                        setSortDir("desc")
                      } else {
                        setSortDir("aesc")
                      }
                    }}
                  >
                    First Name
                  </span>
                  {sortOption === "firstName" && sortDir === "aesc" && (
                    <span
                      id="imgByNameasc"
                      onClick={() => setSortDir("desc")}
                      style={Object.assign({ display: "inline" }, divLikeLink, {
                        marginRight: "5px",
                      })}
                    >
                      <StyledArrowImage src={imageDown} alt="Down Arrow" />
                    </span>
                  )}
                  {sortOption === "firstName" && sortDir === "desc" && (
                    <span
                      id="imgByNameasc"
                      onClick={() => setSortDir("aesc")}
                      style={Object.assign({ display: "inline" }, divLikeLink, {
                        marginRight: "5px",
                      })}
                    >
                      <StyledArrowImage src={imageUp} alt="Up Arrow" />
                    </span>
                  )}
                </th>
                <th>
                  <span
                    style={divLikeLink}
                    onClick={() => {
                      if (sortOption !== "lastName") {
                        setSortOption("lastName")
                        setSortDir("aesc")
                      } else if (sortDir === "aesc") {
                        setSortDir("desc")
                      } else {
                        setSortDir("aesc")
                      }
                    }}
                  >
                    Last Name
                  </span>
                  {sortOption === "lastName" && sortDir === "aesc" && (
                    <span
                      id="imgByNameasc"
                      onClick={() => setSortDir("desc")}
                      style={Object.assign({ display: "inline" }, divLikeLink, {
                        marginRight: "5px",
                      })}
                    >
                      <img
                        style={{
                          width: "20px",
                          height: "15px",
                        }}
                        src={imageDown}
                        alt="Down Arrow"
                      />
                    </span>
                  )}
                  {sortOption === "lastName" && sortDir === "desc" && (
                    <span
                      id="imgByNameasc"
                      onClick={() => setSortDir("aesc")}
                      style={Object.assign({ display: "inline" }, divLikeLink, {
                        marginRight: "5px",
                      })}
                    >
                      <img
                        style={{
                          width: "20px",
                          height: "15px",
                        }}
                        src={imageUp}
                        alt="Up Arrow"
                      />
                    </span>
                  )}
                </th>
                <th>
                  <span
                    style={divLikeLink}
                    onClick={() => {
                      if (sortDir === "aesc") {
                        setSortDir("desc")
                      } else {
                        setSortDir("aesc")
                      }
                    }}
                  >
                    Billing Status
                  </span>
                </th>
                <th>Amount Paid</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredSwimmerInvoices.map((sI, idx) => {
                const amtPaid = sI.invoices
                  .filter(
                    (inv) =>
                    inv.payingClubId && inv.paymentStatus === "paid",
                    )
                  .map((inv) => inv.paymentAmount)
                  .reduce((a, b) => a + b, 0)
                
                const paymentStatus = _.first(_.uniq(sI.invoices.map((invoice) => invoice.paymentStatus))) ?? sI.status  

                const firstInvoice = Boolean(sI.invoices.length) ? sI.invoices[0] : null
                const endDate = firstInvoice?.endDate
                const refundsAreDisabled = true

                return (
                  <tr key={`swimmer_${sI.swimmerId}_${idx}`}>
                    <td>{sI.firstName}</td>
                    <td>{sI.lastName}</td>
                    <td>{paymentStatus}</td>
                    <td>{paymentStatus === "paid" && formatMoney(amtPaid)}</td>
                    <td>
                      {sI.invoices[0]?.numSwims === 0 &&
                        amtPaid > 0 &&
                        paymentStatus === "paid" && 
                        endDate &&
                        isInThePast(endDate) === false ?
                         (
                          <button
                            hidden={refundsAreDisabled}
                            onClick={() =>
                              viewRefundModal(
                                sI.invoices.filter(
                                  (inv) =>
                                    inv.payingClubId &&
                                    inv.paymentStatus === "success",
                                )[0],
                              )
                            }
                          >
                            Refund &amp; Revoke Season Membership
                          </button>
                        ): null}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </StyledInvoice>
          <RefundConfirmationModal
            visible={refundModalVisible}
            closeModal={closeRefundModal}
            swimmerId={refundSwimmerId}
            swimmerName={refundSwimmerName}
            clubId={refundClubId}
            clubName={refundClubName}
            seasonId={refundSeasonId}
            seasonName={refundSeasonName}
            numSwims={0}
            refundRegistration={false}
          />
        </div>
      ) : (
        <p>
          Your organization has elected "
          {clubSeason.paymentType === "offline by organization"
            ? "offline payment"
            : clubSeason.paymentType}
          " as its payment method for this season, and therefore does not have a
          swimmer-by-swimmer invoice available.
        </p>
      )}
    </div>
  )
}
