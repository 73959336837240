import React, { useState, useRef, useEffect } from "react"
import {
  DatePicker,
  Modal,
  Button,
  message,
  Checkbox,
  Input,
  Select,
  Row,
  Col,
  Tabs,
  Tooltip,
} from "antd"
import moment from "moment-timezone"
import { useHistory } from "react-router-dom"
import {
  roleMapping,
  gen,
  googleMapsServerApiKey,
  yardsPerMeter,
  swimminglyApi,
  metersPerYard,
} from "./utils"
import ClubBlock from "./ClubBlock"
import CenterSpin from "./CenterSpin"
import ActionAlerts from "./ActionAlerts"
import EventOrders from "./EventOrders"
import {
  BulbOutlined,
} from "@ant-design/icons"
import { Colors } from "./ClubSeasonManagement/styles"

const { TabPane } = Tabs

const { confirm } = Modal
const { Option } = Select

interface IClub {
  clubId: number
  name_long: string
  name: string
  starter_key: string
  league_id: number
  unit_of_measure: number
  number_of_lanes: number
  address: string
  lat: number | null
  lng: number | null
  is_meet_central_club: number
  unique_identifier: string
  pay_by_date: string
  sd3_lsc: string
  sd3_team_code: string
  best_time_definition: string
  pool_length: number | null
  course_label: string
}

interface ILeague {
  id: number
  name: string
}

interface IEditClubProps {
  club: IClub
  user: {
    userId: number
    role: number
  }
  leagueArr?: Array<ILeague>
  refreshData?: () => void
  setImpersonateClub?: (club: IClub) => void
}

function EditClubInner({
  club,
  user,
  refreshData,
  setImpersonateClub,
  leagueArr,
}: IEditClubProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)

  const [name_long, setName_long] = useState(club.name_long)
  const [name, setName] = useState(club.name)
  const [starter_key, setStarter_key] = useState(club.starter_key)

  const [league_id, setLeague_id] = useState(club.league_id)
  const [unit_of_measure, setUnit_of_measure] = useState(club.unit_of_measure)
  const [number_of_lanes, setNumber_of_lanes] = useState(club.number_of_lanes)
  const [homePoolAddress, setHomePoolAddress] = useState(club.address || "")
  const [home_pool_lat, setHome_pool_lat] = useState(club.lat || "")
  const [home_pool_lng, setHome_pool_lng] = useState(club.lng || "")

  const [is_meet_central_club, setIs_meet_central_club] = useState(
    club.is_meet_central_club === 1,
  )
  const [unique_identifier, setUnique_identifier] = useState(
    club.unique_identifier === "PAID",
  )
  const [pay_by_date, setPay_by_date] = useState(club.pay_by_date)

  const [sd3_lsc, setSd3_lsc] = useState(club.sd3_lsc)
  const [sd3_team_code, setSd3_team_code] = useState(club.sd3_team_code)
  const [best_time_definition, setBest_time_definition] = useState(
    club.best_time_definition,
  )

  const [poolLabel, setPoolLabel] = useState<"SCY" | "SCM" | "LCM">("SCY")
  const [poolLength, setPoolLength] = useState<number | null>(null) // pool length in yards (this IS yards, regardless of units below)
  const [poolLengthText, setPoolLengthText] = useState("")
  const [poolUnits, setPoolUnits] = useState<"Y" | "M">(
    club.course_label === "SCY" ? "Y" : "M",
  )
  const [poolEdited, setPoolEdited] = useState(false)
  const [refreshPoolData, setRefreshPoolData] = useState(0)
  const [tooltipVisible, setTooltipVisible] = useState(false)
  const [tooltipText, setTooltipText] = useState("")

  useEffect(() => {
    let mounted = true
    swimminglyApi
      .get(gen(`/api/getHomePoolOfClub/${club?.clubId}`))
      .then(
        ({
          status,
          pool,
        }: {
          status: string
          pool?: { label: "SCY" | "SCM" | "LCM"; length: number }
        }) => {
          if (status === "success" && pool && mounted) {
            setPoolLength(
              pool.label === "SCY" ? pool.length : pool.length * metersPerYard,
            )
            setPoolLabel(pool.label)
            setPoolUnits(pool.label === "SCY" ? "Y" : "M")
            setPoolLengthText(
              pool.label === "SCY"
                ? `${pool.length}`
                : `${Math.round(pool.length * metersPerYard * 100000) / 100000
                }`,
            )
          } else {
            swimminglyApi
              .post(`/api/createHomePoolForClub/${club?.clubId}`)
              .body({})
              .then((data) => {
                if (data.status === "success")
                  if (mounted) {
                    setRefreshPoolData(refreshPoolData + 1)
                  }
              })
              .catch((err) => {
                message.error(err.message)
                console.log(err)
              })
          }
        },
      )
    return () => {
      mounted = false
    }
  }, [club?.clubId, refreshPoolData])

  useEffect(() => {
    let mounted = true
    if (poolLengthText !== "") {
      let theNewPoolLength = parseFloat(poolLengthText)
      if (Math.abs(theNewPoolLength - Math.round(theNewPoolLength)) < 0.00001) {
        theNewPoolLength = Math.round(theNewPoolLength)
      }
      if (mounted) {
        setPoolLength(theNewPoolLength)
      }
    } else if (mounted) setPoolLength(null)
    return () => {
      mounted = false
    }
  }, [poolLengthText, setPoolLength])

  useEffect(() => {
    let mounted = true
    if (!tooltipVisible && mounted) setTooltipText("")
    else {
      let theTooltipText = " "
      if (poolUnits === "Y" && poolLength !== null) {
        theTooltipText = `${poolLength}y = ${Math.round(poolLength * metersPerYard * 10000) / 10000
          }m`
      } else if (poolUnits === "M" && poolLength !== null) {
        theTooltipText = `${poolLength}m = ${Math.round(poolLength * yardsPerMeter * 10000) / 10000
          }y`
      }
      if (mounted) setTooltipText(theTooltipText)
    }
    return () => {
      mounted = false
    }
  }, [poolLength, poolUnits, tooltipVisible])

  const history = useHistory()

  const autocompleteInput = useRef<HTMLInputElement>(null)
  const autocomplete = useRef<any>(null)

  const onClickSubmit = async () => {
    let poolSave = persistPoolLength()
    let is_paid = club.unique_identifier === "PAID"

    const obj = {
      club_name_long: name_long,
      club_name: name,
      starter_key: starter_key,
      public_result_turn_off: "1",
      is_meet_central_club: is_meet_central_club,
      is_paid: is_paid,
      pay_by_date: pay_by_date,
      sd3_lsc: sd3_lsc,
      sd3_team_code: sd3_team_code,
      club_id: club.clubId,
      league_id: league_id || club.league_id,
      unit_of_measure: unit_of_measure,
      number_of_lanes: number_of_lanes,
      best_time_definition: best_time_definition || null,
      home_pool_address: homePoolAddress,
      home_pool_lat: home_pool_lat,
      home_pool_lng: home_pool_lng,
      poolLength: poolLength,
    }

    if (!obj.club_name || !obj.starter_key || !obj.league_id) {
      console.log("Missing something")
      return
    }
    setLoading(true)

    await Promise.all([
      fetch(gen("/api/addClub2Post"), {
        method: "post",
        headers: { "Content-Type": "application/json" },
        credentials: "same-origin",
        body: JSON.stringify(obj),
      })
        .then((response) => response.json())
        .then((data: { [key: string]: any }) => {
          let club = data.club
          message.success("Updated club")
          if (refreshData) refreshData()
          if (club) {
            if (setImpersonateClub) setImpersonateClub(club)
          }
        })
        .catch((err) => {
          console.log(err)
          return false
        }),
      poolSave.catch((err) => {
        console.log(err)
        return false
      }),
    ]).then(() => setLoading(false))
    // console.log(obj)
  }

  const deleteClub = async (clubId: number) => {
    // /admin/club/delete_club/${this.club.clubId}
    setLoadingDelete(true)
    let response = await fetch(gen("/api/deleteClub"), {
      method: "post",
      headers: { "Content-Type": "application/json" },
      credentials: "same-origin",
      body: JSON.stringify({ clubId: clubId }),
    })
    let result = await response.json()
    if (result && result.ok === "ok") {
      setLoadingDelete(false)
      message.success("Club deleted")
      history.push("/app/admin/club")
    }
  }

  useEffect(() => {
    setHomePoolAddress(club.address || "")
  }, [club.address])


  useEffect(() => {
    const setupAutocomplete = () => {
      const google = window.google
      autocomplete.current = new google.maps.places.Autocomplete(
        autocompleteInput.current,
        { types: ["geocode"] },
      )
      autocomplete.current.addListener("place_changed", () =>
        handleAutoComplete(autocomplete),
      )
    }
    setupAutocomplete()
  }, [])

  const handleAutoComplete = (theAutocomplete: any) => {
    const place = theAutocomplete.current.getPlace()
    if (place) {
      const address = place.formatted_address
      const lat = place.geometry.location.lat()
      const lng = place.geometry.location.lng()

      setHomePoolAddress(address)
      setHome_pool_lat(lat)
      setHome_pool_lng(lng)
    }
  }

  const persistPoolLength = async () => {
    setTooltipVisible(false)
    setPoolEdited(false)
    if (poolLength === null) {
      message.warn(
        "Pool length not saved because you didn't set it to a number.",
      )
      setRefreshPoolData(refreshPoolData + 1)
      return
    }
    setLoading(true)
    let newPoolLengthInYards: number | null = null
    if (poolUnits === "Y") {
      newPoolLengthInYards = poolLength
    } else if (poolUnits === "M") {
      newPoolLengthInYards = poolLength ? poolLength * yardsPerMeter : null
    }

    if (!newPoolLengthInYards) {
      message.warn("You must set a valid pool length")
      return
    } else {
      if (
        Math.abs(newPoolLengthInYards - Math.round(newPoolLengthInYards)) <
        0.0001
      ) {
        newPoolLengthInYards = Math.round(newPoolLengthInYards)
      }
    }

    swimminglyApi
      .post(gen("/api/updatePoolLength"))
      .body({
        clubId: club.clubId,
        poolLength: newPoolLengthInYards,
        poolLabel,
      })
      .then((result) => {
        if (result && result.status === "success") {
          message.success("pool length updated!")
        } else {
          message.error("uh oh, something went wrong. try again later.")
        }
      })
      .finally(() => setLoading(false))
  }

  return (
    <div>
      <section className="panel" style={{ marginTop: "20px" }}>
        <div className="ourpanel-body">
          <Row style={{ marginBottom: "15px" }}>
            <Col span={6}>Club Name:</Col>
            <Col span={12}>
              <Input
                value={name_long}
                onChange={(e) => setName_long(e.target.value)}
                placeholder="Club Name"
                required
              />
            </Col>
          </Row>

          <Row style={{ marginBottom: "15px" }}>
            <Col span={6}>Club Abbrv:</Col>
            <Col span={12}>
              Use your club's shorthand name or abbreviation for optimal heat
              sheet formatting
              <Input
                placeholder="Club Abbr"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Col>
          </Row>

          <Row style={{ marginBottom: "15px" }}>
            <Col span={6}>Starter Key:</Col>
            <Col span={12}>
              This is the password that the Scorekeeper will use to download
              your meet schedule the day of the meet. This should be a "team
              password" that is known by your club administrators.
              <Input
                placeholder="Starter Key"
                value={starter_key}
                onChange={(e) => setStarter_key(e.target.value)}
                type="text"
              />
            </Col>
          </Row>

          <div style={{ display: "flex" }}>
            <div>Advanced Options</div>
            <div
              style={{
                marginLeft: "10px",
                fontSize: "20px",
                cursor: "pointer",
              }}
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? "\u25BC" : "\u25B2"}
            </div>
          </div>

          <div style={isOpen ? {} : { display: "none" }}>
            {roleMapping[user.role] === "Hydro Admin" && (
              <Row style={{ marginBottom: "15px" }}>
                <Col span={6}>League:</Col>
                <Col span={12}>
                  <Select
                    style={{ width: "455px" }}
                    value={league_id}
                    onChange={setLeague_id}
                  >
                    {leagueArr &&
                      leagueArr.map((league) => {
                        // console.log(league)
                        return (
                          <Option value={league.id} key={league.id}>
                            {league.name}
                          </Option>
                        )
                      })}
                  </Select>
                </Col>
              </Row>
            )}
            <Row>
              <Col span={6} style={{ display: "flex" }}>
                Course of your pool
              </Col>
              <Col span={18}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 2fr 1fr",
                    columnGap: "15px",
                    marginBottom: "10px",
                  }}
                >
                  <div style={{ backgroundColor: "lightgray" }}>
                    <p style={{ marginBottom: "0", textAlign: "center" }}>
                      Course
                    </p>
                    <Select
                      style={{ width: "100%" }}
                      value={poolLabel}
                      onChange={(val) => {
                        setPoolEdited(true)
                        if (val === "SCY") setUnit_of_measure(0)
                        if (val === "SCM") setUnit_of_measure(1)
                        if (val === "LCM") setUnit_of_measure(2)

                        if (poolUnits === "Y" && val !== "SCY" && poolLength) {
                          setPoolLength(poolLength * metersPerYard)
                          setPoolUnits("M")
                        } else if (
                          poolUnits === "M" &&
                          val === "SCY" &&
                          poolLength
                        ) {
                          setPoolLength(poolLength * yardsPerMeter)
                          setPoolUnits("Y")
                        }
                        if (poolLabel !== val) {
                          if (val === "SCY") {
                            setPoolLengthText("25")
                            // need to specifically set poolLength because the useEffect hook only
                            // gets triggered when poolLength text changes. for example, if you change
                            // from SCY to SCM, poolLengthText doesn't change so useEffect doesn't get
                            // triggered to update the poolLength
                            setPoolLength(25)
                            setPoolUnits("Y")
                          } else if (val === "SCM") {
                            setPoolLengthText("25")
                            setPoolLength(25)
                            setPoolUnits("M")
                          } else if (val === "LCM") {
                            setPoolLengthText("50")
                            setPoolLength(50)
                            setPoolUnits("M")
                          }
                        }
                        setPoolLabel(val)
                      }}
                    >
                      <Option value={"SCY"}>SCY</Option>
                      <Option value={"SCM"}>SCM</Option>
                      <Option value={"LCM"}>LCM</Option>
                    </Select>
                  </div>
                  <div style={{ backgroundColor: "lightgray" }}>
                    <p style={{ marginBottom: "0", textAlign: "center" }}>
                      Pool Length (&amp; units)
                    </p>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                      }}
                    >
                      <Tooltip
                        visible={tooltipVisible}
                        title={tooltipText}
                        placement="topLeft"
                        overlayClassName="numeric-input"
                      >
                        <Input
                          style={{ width: "100%" }}
                          value={poolLengthText}
                          onChange={(e) => {
                            setPoolEdited(true)
                            // here we will setPoolLengthText, and the useEffect hook will
                            // automatically update poolLength
                            const newLengthString = e.target.value
                            const numTest = /^[0-9]*\.{0,1}[0-9]*$/
                            if (newLengthString.trim() === "") {
                              setTooltipVisible(false)

                              setPoolLengthText("")
                            } else {
                              if (newLengthString === ".")
                                setPoolLengthText("0.")
                              else if (/^0{2,}/.test(newLengthString))
                                setPoolLengthText(
                                  newLengthString.replace(/^0{2,}/, "0"),
                                )
                              else if (numTest.test(newLengthString))
                                setPoolLengthText(newLengthString)
                              else if (numTest.test(newLengthString.trim()))
                                setPoolLengthText(newLengthString.trim())
                              else setPoolLengthText(`${poolLength || ""}`)
                              setTooltipVisible(true)
                            }
                          }}
                        />
                      </Tooltip>
                      <Select
                        style={{ width: "100%" }}
                        value={poolUnits}
                        onChange={(val) => {
                          setPoolEdited(true)
                          setPoolUnits(val)
                        }}
                      >
                        <Option value={"Y"}>Yards</Option>
                        <Option value={"M"}>Meters</Option>
                      </Select>
                    </div>
                  </div>
                  <div style={{ alignSelf: "center" }}>
                    <Button
                      type={poolEdited ? "primary" : "ghost"}
                      disabled={!poolEdited}
                      onClick={persistPoolLength}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Col>
              {/* <Col span={6}>
                <Select
                  style={{ width: "100%" }}
                  value={unit_of_measure}
                  onChange={(val) => {
                    setUnit_of_measure(val)
                    setPoolUnits(val === 0 ? "Y" : "M")
                  }}
                >
                  <Option value={0}>SCY</Option>
                  <Option value={1}>SCM</Option>
                  <Option value={2}>LCM</Option>
                </Select>
              </Col>
              <Col span={12}>
                <EditPoolLength
                  poolLength={poolLength}
                  setPoolLength={setPoolLength}
                  poolUnits={poolUnits}
                  setPoolUnits={setPoolUnits}
                  visible={editPoolVisible}
                  persistPoolLength={persistPoolLength}
                  changeVisible={setEditPoolVisible}
                />
              </Col> */}
            </Row>
            <Row style={{ marginBottom: "15px" }}>
              <Col span={6}>Number of Lanes in your pool</Col>
              <Col span={12}>
                <Select
                  className="valid"
                  value={number_of_lanes}
                  style={{ width: "455px" }}
                  onChange={setNumber_of_lanes}
                >
                  <Option value={1}>1 Lane</Option>
                  <Option value={2}>2 Lane</Option>
                  <Option value={3}>3 Lane</Option>
                  <Option value={4}>4 Lane</Option>
                  <Option value={5}>5 Lane</Option>
                  <Option value={6}>6 Lane</Option>
                  <Option value={7}>7 Lane</Option>
                  <Option value={8}>8 Lane</Option>
                  <Option value={9}>9 Lane</Option>
                  <Option value={10}>10 Lane</Option>
                </Select>
              </Col>
            </Row>
            {["Hydro Admin", "League Admin", "Club Admin"].includes(
              roleMapping[user.role],
            ) && (
                <React.Fragment>
                  <Row style={{ marginBottom: "15px" }}>
                    <Col span={6}>Home Pool Address:</Col>
                    <Col span={12}>

                      <input
                        style={{ width: "100%" }}
                        ref={autocompleteInput}
                        placeholder="Enter pool address..."
                        defaultValue={homePoolAddress}
                        onChange={(e) => {
                          setHomePoolAddress(e.target.value)
                        } 
                      }
                      />
                      {home_pool_lat ? (
                        <img
                          alt="home pool"
                          src={`https://maps.googleapis.com/maps/api/staticmap?center=${home_pool_lat},${home_pool_lng}&zoom=13&size=600x300&maptype=roadmap&markers=color:blue%7Clabel:P%7C${home_pool_lat},${home_pool_lng}&key=${googleMapsServerApiKey}`}
                        />
                      ) : null}
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            
            <Row style={{ marginBottom: "15px" }}>
              <Col span={6}>
                Best Time Definition (for entries / results csv):
              </Col>
              <Col span={12}>
                <Select
                  style={{ width: "455px" }}
                  value={best_time_definition}
                  onChange={setBest_time_definition}
                >
                  <Option value="AllTimeBest">All Time Best</Option>
                  <Option value="SeasonBest">Season Best</Option>
                </Select>
              </Col>
            </Row>
            <Row style={{ marginBottom: "15px" }}>
              <Col span={6}>SD3 Export Settings: </Col>
              <Col span={12}>
                {" "}
                These settings are only necessary if you plan to export your
                meet results using the published SD3 File Specification.
              </Col>
            </Row>

            <Row style={{ marginBottom: "15px" }}>
              <Col span={6}>LSC Code:</Col>
              <Col span={12}>
                <Input
                  value={sd3_lsc}
                  onChange={(e) => {
                    let newValue = e.target.value
                      .trim()
                      .slice(0, 2)
                      .toUpperCase()
                    setSd3_lsc(newValue)
                  }}
                  type="text"
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: "15px" }}>
              <Col span={6}>Team Code: </Col>
              <Col span={12}>
                <Input
                  value={sd3_team_code}
                  onChange={(e) => setSd3_team_code(e.target.value)}
                  type="text"
                />
              </Col>
            </Row>
          </div>

          <Row>
            <Col span={6} />
            <Col span={3}>
              <Button
                type="primary"
                onClick={onClickSubmit}
                loading={loading}
                style={{ marginRight: "20px" }}
              >
                Save
              </Button>
            </Col>
            <Col span={3}>
              {" "}
              {roleMapping[user.role] === "Hydro Admin" && (
                <Button
                  // type="danger"
                  loading={loadingDelete}
                  onClick={() => {
                    confirm({
                      title: "Hard Delete this club?",
                      content:
                        "This club will be permanently deleted from our database, and cannot be undone.",
                      onOk() {
                        deleteClub(club.clubId)
                      },
                      onCancel() { },
                    })
                  }}
                >
                  Delete Club
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </section>
    </div>
  )
}

export default function EditClub({
  club,
  user,
  setImpersonateClub,
}: IEditClubProps) {
  const [leagueArr, setLeagueArr] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [stateClub, setStateClub] = useState<IClub | null>(null)

  const userId = user.userId
  const clubId = club.clubId

  const refreshData = async () => {

    swimminglyApi
      .post(gen("/api/editClubDataPost"))
      .body({
        userId: userId,
        clubId: clubId,
      })
      .then((response) => response.json())
      .then((data) => {
        setLeagueArr(data.leagueArr)
        setStateClub(data.club)
        setLoaded(true)
      })
  }

  useEffect(() => {
    let mounted = true
    swimminglyApi
      .post(gen("/api/editClubDataPost"))
      .body({
        userId: user.userId,
        clubId: club.clubId,
      })
      .then((data) => {
        if (mounted) {
          setLeagueArr(data.leagueArr)
          setStateClub(data.club)
          setLoaded(true)
        }
      })
    return () => {
      mounted = false
    }
  }, [user.userId, club.clubId])

  if (!loaded) {
    return (
      <div>
        <header className="page-header">
          <h2>Edit Club</h2>
        </header>
        <ClubBlock />
        <section className="panel">
          <header className="panel-heading">
            <h2 className="panel-title">Edit Club</h2>
          </header>
          <div className="ourpanel-body">
            <CenterSpin />
          </div>
        </section>
      </div>
    )
  }
  return (
    <div>
      <header className="page-header">
        <h2>Edit Club</h2>
      </header>
      <ActionAlerts />
      <ClubBlock />
      <Tabs style={{ marginTop: "10px" }} defaultActiveKey="1">
        <TabPane tab={<h4>Edit Club</h4>} key="1">
          <EditClubInner
            user={user}
            leagueArr={leagueArr}
            club={stateClub || club}
            refreshData={refreshData}
            setImpersonateClub={setImpersonateClub}
          />
        </TabPane>
        <TabPane tab={<h4>Event Orders</h4>} key="2">
          <>
            <section className="panel">
              <header className="panel-heading">
                <h2 className="panel-title">Club Event Orders {" "}
                  <Tooltip title="Take me to Help Center!">
                    <Button type="link" icon={(<BulbOutlined />)} color={Colors.primaryBlue} href="https://support.swimmingly.app" target="_blank" rel="noreferrer noopener"></Button>
                  </Tooltip>{" "}
                </h2>
              </header>
              <div className="ourpanel-body">
                <EventOrders
                  // league={{ id: club.league_id }}
                  club={{ clubId: club.clubId }}
                />
              </div>
            </section>
          </>
        </TabPane>
      </Tabs>
    </div>
  )
}
