import { gen, swimminglyApi } from "../utils"

export type PricingServiceTotals = {
    swimmers: number,
    checkoutItems: number,
    beforeProcessingFee: number,
    clubDues: number,
    membershipFee: number,
    swimminglyServiceFee: number,
    appliedDiscountTotal: number, 
    registrationDuesTotal: number,
    total: number

}
export type PricingServiceResult = {
    status: "success"|"error"
    swimmerPrices: SwimmerPrice[]
    checkoutItems: CheckoutItem[]
    seasonName: string
    clubName: string
    rejectedCodes: string[]
    swimminglyServiceFeePercentage: number
    totals: PricingServiceTotals | undefined
}

export type Discount = {
    amount: number
    code: string
}
export type SwimmerPrice = {
    swimmerId: number
    swimmerName: string 
    opaqueCost: number
    total: number
    discounts: Discount[]
}
export type CheckoutItem = {
    quantity: number
    code: string 
    unitCost: number
    total: number
}

export type PricingServiceRequest = {
    swimmers: DiscountedSwimmer[],
    checkoutItemQuantitiesByCode: Record<string, number>
}

type DiscountedSwimmer = {
    id: string
    discounts: string[]
}

// models the API to retreive Registration payment configuration
export class PricingServiceHelper {
    api;
    clubId?: number ;
    seasonId?: number;
    swimmers?: DiscountedSwimmer[]; 
    checkoutItemQuantitiesByCode: Record<string, number>;
    
    public constructor(api = swimminglyApi) {
        this.api = api
        this.checkoutItemQuantitiesByCode = {}
    }

    public init(clubId: number, seasonId: number, swimmerIds: number[]) {
        const currentSwimmerIds = new Set(this.swimmers?.map((swimmer) => swimmer.id) ?? [])
        const shouldInitialize = swimmerIds.filter((swimmerId) => !currentSwimmerIds.has(`${swimmerId}`)).length > 0 || swimmerIds.length !== (this.swimmers?.length ?? 0)
        if (shouldInitialize){
            this.clubId = clubId
            this.seasonId = seasonId
            this.swimmers = swimmerIds.map((swimmerId) => {return {id: `${swimmerId}`, discounts: []}})
            this.checkoutItemQuantitiesByCode = {}
        }
    }

    public async getAllPrices(): Promise<PricingServiceResult>  {
        return await this.api
            .post(gen(`/api/pricing/swimmers/${this.clubId}/${this.seasonId}`)) 
            .body(this.requestBody())
            .then((data) => {
                return Promise.resolve(data)
            })
            .catch ((err) => {
                console.log(`err`)
                return Promise.resolve({status:"error", swimmerPrices: [], checkoutItems: [], rejectedCodes: [], processingFee:[], totals: undefined})
            })
    }

    public addSwimmerAndDiscount(swimmerId: number, discountCode: string) {
        const discountedSwimmer = this.swimmers?.find((discountedSwimmer) => {return discountedSwimmer.id === `${swimmerId}`})
        discountedSwimmer?.discounts.push(discountCode)
    }
    public setCheckoutItemQuantity(code: string, quantity: number) {
        this.checkoutItemQuantitiesByCode[code] = quantity
    }
    
    public pricingServiceRequest(): PricingServiceRequest {
        return {
            swimmers: this.swimmers ?? [],
            checkoutItemQuantitiesByCode: this.checkoutItemQuantitiesByCode
        }
    }

    requestBody() {        
        return this.pricingServiceRequest()
    }
}

