import React, { useEffect, useState } from "react"
import { Button, DatePicker, message } from "antd"
import moment, { Moment } from "moment-timezone"
import styled from "styled-components"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../appData/types"
import ContentPanel from "./ContentPanel"
import { gen, ISwimmerWithNumericGender, IUser, swimminglyApi } from "./utils"
import { IRosterSwimmer } from "./MySwimmers"
import { screenSizes } from "../styles/GlobalStyles"
import GenericModal from "./GenericModal"

interface IMergeSwimmerModalProps {
  mergeSwimmersVisible: boolean
  setMergeSwimmersVisible: (newVisible: boolean) => void
  swimmerIdsForMerge: number[]
  user: IUser | null
  triggerRefresh: number
  setTriggerRefresh: (newValue: number) => void
}

const MergeSwimmerForm = styled.div`
  input,
  select,
  option,
  .ant-picker {
    height: 35px;
    border-radius: 5px;
    border: 1px solid var(--darkgrey);
  }

  .ant-picker input {
    border: none;
    color: var(--darkgrey);
  }

  select {
    padding: 0;
  }

  p .names {
    color: orange;
  }

  .form-grid-name {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 5px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .form-grid-row-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    row-gap: 5px;
  }
`

function MergeSwimmerModal({
  mergeSwimmersVisible,
  setMergeSwimmersVisible,
  swimmerIdsForMerge,
  user,
  triggerRefresh,
  setTriggerRefresh,
}: IMergeSwimmerModalProps): JSX.Element {
  const [mergeElligible, setMergeElligible] = useState(false)
  const [confirmMerge, setConfirmMerge] = useState(false)
  const [mergeFirstName, setMergeFirstName] = useState("")
  const [mergeMiddleInitial, setMergeMiddleInitial] = useState("")
  const [mergeLastName, setMergeLastName] = useState("")
  const [mergeGender, setMergeGender] = useState<"Boy" | "Girl" | "">("")
  const [mergeBirthdate, setMergeBirthdate] = useState<Moment | null>(null)
  const [swimmersToMerge, setSwimmersToMerge] = useState<
    ISwimmerWithNumericGender[]
  >([])

  useEffect(() => {
    if (swimmerIdsForMerge && swimmerIdsForMerge.length > 0) {
      swimminglyApi
        .post(gen("/api/getSwimmers"))
        .body({ swimmerIds: swimmerIdsForMerge })
        .then((data) => {
          if (data.status === "success") {
            setSwimmersToMerge(data.swimmers)
          }
        })
    } else {
      setSwimmersToMerge([])
    }
  }, [swimmerIdsForMerge, swimmerIdsForMerge.length])

  useEffect(() => {
    setMergeFirstName("")
    setMergeMiddleInitial("")
    setMergeLastName("")
    setMergeGender("")
    setMergeElligible(false)
    setConfirmMerge(false)
    setMergeBirthdate(null)

    const firstNames = swimmersToMerge
      .map((sw) => sw.firstName)
      .filter((v, i, a) => a.indexOf(v) === i)
    const middleInitials = swimmersToMerge
      .map((sw) => sw.middleInitials || "")
      .filter((mI) => mI.trim() !== "")
      .filter((v, i, a) => a.indexOf(v) === i)
    const lastNames = swimmersToMerge
      .map((sw) => sw.lastName)
      .filter((v, i, a) => a.indexOf(v) === i)
    const genders = swimmersToMerge
      .map((sw) => (sw.gender === 2 ? "Boy" : sw.gender === 1 ? "Girl" : ""))
      .filter((g) => g !== "")
      .filter((v, i, a) => a.indexOf(v) === i)
    const birthDates = swimmersToMerge
      .map((sw) => moment(sw.dateOfBirth).format("YYYY-MM-DD"))
      .filter((v, i, a) => a.indexOf(v) === i)

    if (firstNames.length === 1) setMergeFirstName(firstNames[0])
    if (middleInitials.length === 1) setMergeMiddleInitial(middleInitials[0])
    if (lastNames.length === 1) setMergeLastName(lastNames[0])
    if (genders.length === 1) setMergeGender(genders[0])
    if (birthDates.length === 1) setMergeBirthdate(moment(birthDates[0]))
  }, [swimmersToMerge, mergeSwimmersVisible])

  useEffect(() => {
    let readyForMerge = true
    if (swimmersToMerge.length < 2) readyForMerge = false
    if (!/^[a-zA-Z]{1,}[a-zA-Z\s]*[a-zA-Z]{1,}$/.test(mergeFirstName))
      readyForMerge = false
    if (mergeMiddleInitial !== "" && !/^[a-zA-Z]{1}$/.test(mergeMiddleInitial))
      readyForMerge = false
    if (!/^[a-zA-Z]{1,}[a-zA-Z\s]*[a-zA-Z]{1,}$/.test(mergeLastName))
      readyForMerge = false
    if (mergeGender === "") readyForMerge = false
    if (mergeBirthdate === null) readyForMerge = false
    setMergeElligible(readyForMerge)
  }, [
    swimmersToMerge,
    mergeFirstName,
    mergeLastName,
    mergeMiddleInitial,
    mergeGender,
    mergeBirthdate,
  ])

  return (
    <GenericModal
      title="Merge Duplicate Swimmer Records"
      visible={mergeSwimmersVisible}
      setVisible={setMergeSwimmersVisible}
      footer={() => (
        <>
          <Button
            key="close"
            onClick={() => {
              setMergeSwimmersVisible(false)
            }}
          >
            Close
          </Button>
          <Button
            key="Ok"
            disabled={!mergeElligible}
            type="primary"
            onClick={() => {
              if (confirmMerge) {
                swimminglyApi
                  .post(gen("/api/mergeMultipleSwimmersIntoOne"))
                  .body({
                    swimmerIds: swimmersToMerge.map((sw) => sw.swimmerId),
                    firstName: mergeFirstName,
                    middleInitial: mergeMiddleInitial,
                    lastName: mergeLastName,
                    gender: mergeGender,
                    dateOfBirth: mergeBirthdate?.format("YYYY-MM-DD"),
                    userId: user?.userId,
                  })
                  .then((results) => {
                    if (results?.status === "success") {
                      message.success("Success!")
                      setTriggerRefresh(triggerRefresh + 1)
                    } else {
                      message.error("Uh oh, something went wrong...")
                    }
                  })
                  .catch((err) => {
                    console.log(err)
                    message.error("Uh oh, something went wrong..")
                    setTriggerRefresh(triggerRefresh + 1)
                  })
                setMergeSwimmersVisible(false)
              } else if (mergeElligible) setConfirmMerge(true)
              else if (swimmersToMerge.length <= 1)
                message.warn("You must select at least two swimmers to merge")
              else
                message.warn(
                  "Please let us know the correct information for your swimmer",
                )
            }}
          >
            {confirmMerge ? "Confirm" : "Ok"}
          </Button>
        </>
      )}
    >
      {confirmMerge ? (
        <React.Fragment>
          <p>
            Are you sure you want to merge{" "}
            <span style={{ color: "orange" }}>
              {swimmersToMerge
                .map((sTM) => {
                  if (sTM.middleInitials && sTM.middleInitials.trim() !== "") {
                    return (
                      sTM.firstName +
                      " " +
                      sTM.middleInitials +
                      " " +
                      sTM.lastName
                    ).trim()
                  } else {
                    return (sTM.firstName + " " + sTM.lastName).trim()
                  }
                })
                .join(", ")}
            </span>{" "}
            into one swimmer with the following information:
          </p>
          <p>
            {mergeFirstName} {mergeLastName} - {mergeGender}{" "}
            {mergeBirthdate?.format("YYYY-MM-DD")}
          </p>
          <p>
            <b>This CANNOT be undone.</b> You are merging{" "}
            {swimmersToMerge.length} swimmers into <b>1 athlete</b>.
          </p>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <h3>Merge {swimmersToMerge.length} swimmers?</h3>
          <p>
            You are about to merge:
            <span style={{ color: "orange" }}>
              {swimmersToMerge
                .map((sTM) => {
                  if (sTM.middleInitials && sTM.middleInitials.trim() !== "") {
                    return (
                      sTM.firstName +
                      " " +
                      sTM.middleInitials +
                      " " +
                      sTM.lastName
                    ).trim()
                  } else {
                    return (sTM.firstName + " " + sTM.lastName).trim()
                  }
                })
                .join(", ")}
            </span>
          </p>
          {swimmersToMerge.length > 0 ? (
            <MergeSwimmerForm>
              <p style={{ marginTop: "10px", marginBottom: "2px" }}>
                Please verify the correct information to use for the athlete:
              </p>
              <div className="form-grid-name">
                <input
                  placeholder="First name..."
                  value={mergeFirstName}
                  onChange={(e) => setMergeFirstName(e.target.value)}
                />
                <input
                  placeholder="Middle Initial (optional)..."
                  value={mergeMiddleInitial}
                  onKeyDown={(e) => {
                    if (
                      ![
                        "Shift",
                        "Backspace",
                        "Delete",
                        "Meta",
                        "Tab",
                        "Enter",
                      ].includes(e.key) &&
                      !/[a-zA-Z]/.test(e.key)
                    ) {
                      e.preventDefault()
                    }
                  }}
                  onChange={(e) => {
                    const newValue = e.target.value
                    if (newValue.length < 2)
                      setMergeMiddleInitial(e.target.value.trim())
                  }}
                />
                <input
                  placeholder="Last name..."
                  value={mergeLastName}
                  onChange={(e) => setMergeLastName(e.target.value)}
                />
              </div>
              <div className="form-grid-row-2">
                <select
                  value={mergeGender}
                  onChange={(e) =>
                    setMergeGender(e.target.value as "Boy" | "Girl")
                  }
                >
                  <option value="" disabled>
                    Choose gender...
                  </option>
                  <option value="Boy">Boy</option>
                  <option value="Girl">Girl</option>
                </select>
                <DatePicker
                  value={mergeBirthdate}
                  onChange={setMergeBirthdate}
                />
              </div>
            </MergeSwimmerForm>
          ) : null}
        </React.Fragment>
      )}
    </GenericModal>
  )
}

const StyledCheckboxRow = styled.div`
  width: 30%;
  min-width: 350px;
  color: var(--black);
  padding-left: 10px;

  &.even-row {
    background-color: var(--snow);
  }

  &.odd-row {
    background-color: var(--mediumgrey);
  }

  p {
    width: 350px;
  }

  @media (max-width: ${screenSizes.small}px) {
    width: 80%;
    min-width: 275px;
    p {
      width: 275px;
    }
  }
`

interface IUsersClub {
  clubId: number
  name_long: string
}

export default function MergeSwimmers(): JSX.Element {
  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)
  const [clubs, setClubs] = useState<IUsersClub[]>([])
  const [selectedClub, setSelectedClub] = useState<number>(0)
  const [swimmers, setSwimmers] = useState<IRosterSwimmer[]>([])
  const [swimmersForMerge, setSwimmersForMerge] = useState<number[]>([])
  const [mergeSwimmerModalVisible, setMergeSwimmerModalVisible] =
    useState(false)
  const [newSwimmerIdToMerge, setNewSwimmerIdToMerge] =
    useState<number | null | undefined>(undefined)
  const [triggerRefresh, setTriggerRefresh] = useState(0)

  useEffect(() => {
    setSwimmersForMerge([])
    setMergeSwimmerModalVisible(false)
    setSelectedClub(0)
    swimminglyApi
      .post(gen(`/api/getUsersClubsPost`))
      .body({
        userId: user?.userId,
      })
      .then(
        (data: {
          status: "success" | "error"
          clubs?: IUsersClub[]
          message?: string
        }) => {
          const usersClubs = data.clubs
          if (usersClubs) {
            usersClubs.sort((club1: IUsersClub, club2: IUsersClub) =>
              (club1.name_long || "") > (club2.name_long || "") ? 1 : -1,
            )
            setClubs(usersClubs)
          } else {
            message.error("problem finding clubs")
            return
          }
        },
      )
  }, [user?.userId])

  useEffect(() => {
    setSwimmersForMerge([])
    setMergeSwimmerModalVisible(false)
    if (selectedClub < 1) {
      if (swimmers.length > 0) setSwimmers([])
    } else {
      swimminglyApi
        .post(gen("/api/getClubSwimmers2Post"))
        .body({
          isChecked: true,
          user: user,
          clubId: selectedClub,
        })
        .then(
          ({
            status,
            swimmers,
          }: {
            status: string
            swimmers: {
              swimmer: ISwimmerWithNumericGender
              swimmerShoulderNumber: number
              swimmerLeagueAge: number
              guardians: IUser[]
            }[]
          }) => {
            if (status === "success") {
              const newSwimmerArr: IRosterSwimmer[] = []
              for (let i = 0; i < swimmers.length; i++) {
                newSwimmerArr.push({
                  ...swimmers[i].swimmer,
                  leagueAge: swimmers[i].swimmerLeagueAge,
                  swimmerShoulderNumber: swimmers[i].swimmerShoulderNumber,
                  guardians: swimmers[i].guardians,
                })
              }
              setSwimmers(newSwimmerArr)
            }
          },
        )
    }
  }, [user, selectedClub, swimmers.length, triggerRefresh])

  return (
    <>
      <ContentPanel title="Merge Swimmers">
        <h3>Choose Club</h3>
        <select
          value={selectedClub}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            setSelectedClub(parseInt(e.target.value))
          }
        >
          <option value={0} disabled>
            Select Club
          </option>
          {clubs.map((club, idx) => (
            <option key={`club_${club.clubId}_${idx}`} value={club.clubId}>
              {club.name_long}
            </option>
          ))}
        </select>
        <h3 style={{ marginTop: "25px" }}>
          Select Swimmers to Merge From Club
          {selectedClub >= 1
            ? " From " +
              clubs.find((club) => club.clubId === selectedClub)?.name_long
            : ""}
        </h3>
        {swimmers.map((swimmer, idx) => {
          return (
            <StyledCheckboxRow
              key={`checkrow_swimmer_${swimmer.swimmerId}_${idx}`}
              className={idx % 2 === 0 ? "even-row" : "odd-row"}
            >
              <p
                style={{
                  display: "inline-grid",
                  gridTemplateColumns: "10px 10px 3fr 25px 1fr 25px 2fr",
                }}
              >
                <input
                  type="checkbox"
                  checked={swimmersForMerge.includes(swimmer.swimmerId)}
                  onChange={() => {
                    if (swimmersForMerge.includes(swimmer.swimmerId)) {
                      setSwimmersForMerge(
                        swimmersForMerge.filter((s) => s !== swimmer.swimmerId),
                      )
                    } else {
                      setSwimmersForMerge([
                        ...swimmersForMerge,
                        swimmer.swimmerId,
                      ])
                    }
                  }}
                />
                <span>&nbsp;</span>
                <span>
                  {swimmer.lastName}, {swimmer.firstName}
                  {swimmer.middleInitials ? " " + swimmer.middleInitials : ""}
                </span>
                <span> - </span>
                <span>
                  {swimmer.gender === 2
                    ? "Boy"
                    : swimmer.gender === 1
                    ? "Girl"
                    : "gender"}
                </span>
                <span> - </span>
                <span>{moment(swimmer.dateOfBirth).format("YYYY-MM-DD")}</span>
              </p>
              <br />
            </StyledCheckboxRow>
          )
        })}
        <h3>
          OR Enter swimmerIds directly (BE CAREFUL)
        </h3>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {swimmersForMerge.map((sFM) => (
            <input
              key={`swimmer_disabled_input_${sFM}`}
              style={{ width: "200px" }}
              disabled
              value={sFM}
            />
          ))}
        </div>
        <input
          style={{ width: "200px" }}
          placeholder="swimmerId"
          type="number"
          value={newSwimmerIdToMerge || ""}
          onChange={(e) => {
            const newValue = e.target.value
            if (newValue === "") {
              setNewSwimmerIdToMerge(null)
            } else {
              setNewSwimmerIdToMerge(parseInt(newValue))
            }
          }}
        />
        <button
          disabled={typeof newSwimmerIdToMerge !== "number"}
          onClick={() => {
            if (typeof newSwimmerIdToMerge === "number") {
              setSwimmersForMerge([...swimmersForMerge, newSwimmerIdToMerge])
              setNewSwimmerIdToMerge(0)
            }
            setNewSwimmerIdToMerge(null)
            setNewSwimmerIdToMerge(undefined)
          }}
        >
          add
        </button>
        <br />
        <Button
          type="primary"
          disabled={swimmersForMerge.length < 2}
          style={{ marginTop: "25px", marginBottom: "25px" }}
          onClick={() => setMergeSwimmerModalVisible(true)}
        >
          Merge Swimmers
        </Button>
        <MergeSwimmerModal
          mergeSwimmersVisible={mergeSwimmerModalVisible}
          setMergeSwimmersVisible={setMergeSwimmerModalVisible}
          user={user}
          swimmerIdsForMerge={swimmersForMerge}
          triggerRefresh={triggerRefresh}
          setTriggerRefresh={setTriggerRefresh}
        />
      </ContentPanel>
    </>
  )
}
