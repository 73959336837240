/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from "react"
import { Tabs, Table, Empty, Popconfirm, Button, Divider, Tooltip } from "antd"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../appData/types"
import { gen, IClub, swimminglyApi } from "./utils"
import CenterSpin from "./CenterSpin"
import ClubBlock from "./ClubBlock"
import AddCardForClub from "./Billing/AddCardForClub"
import { CheckCircleTwoTone, CreditCardFilled, CreditCardOutlined, PlusCircleFilled, PlusCircleOutlined, PlusOutlined, PlusSquareFilled, PlusSquareOutlined } from "@ant-design/icons"
import ClubInvoices from "./ClubInvoices"
// import BillingAddBank from "./BillingAddBank"

const { TabPane } = Tabs

function PaymentMethodTable({
  paymentMethods,
  refreshData,
  clubId,
  defaultPaymentMethod,
}: {
  paymentMethods: IStripeCard[]
  refreshData: () => void
  clubId: number
  defaultPaymentMethod: string | null
}) {
  const columns = [
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      render: (_text: string, record: IStripeCard) => record.card.brand,
    },
    {
      title: "Number",
      dataIndex: "last4",
      key: "number",
      render: (_text: string, record: IStripeCard) =>
        `************${record.card.last4}`,
    },
    {
      title: "Exp Month",
      key: "expMonth",
      dataIndex: "card.exp_month",
      render: (_text: string, record: IStripeCard) => record.card.exp_month,
    },
    {
      title: "Exp Year",
      dataIndex: "card.exp_year",
      key: "expYear",
      render: (_text: string, record: IStripeCard) => record.card.exp_year,
    },
    {
      title: "Action",
      key: "action",
      render: (_text: string, record: IStripeCard) => {
        return (
          <div style={{ display: "flex" }}>
            <Popconfirm
              title="Are you sure remove this card?"
              id="archiveEvent"
              onConfirm={() => {
                swimminglyApi
                  .post(gen(`/api/deleteCardFromClub/${clubId}`))
                  .body({
                    paymentMethodId: record.id,
                  })
                  .then(refreshData)
              }}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="link"
                style={{ padding: 0, paddingLeft: "5px", paddingRight: "5px" }}
              >
                Delete
              </Button>
            </Popconfirm>
            <Button
              type="link"
              style={{ padding: 0, paddingLeft: "5px", paddingRight: "5px" }}
              onClick={() => {
                swimminglyApi
                  .post(gen(`/api/updateDefaultPaymentMethodForClub/${clubId}`))
                  .body({ paymentMethodId: record.id })
                  .then(refreshData)
              }}
            >
              Make Primary Method
            </Button>
          </div>
        )
      },
    },
    {
      title: "Primary Method",
      key: "default",
      render: (_text: string, record: IStripeCard) => {
        if (record.id === defaultPaymentMethod) {
          return (
            <Tooltip
              title="This card will be used for transactions"
            >
            <CheckCircleTwoTone
              style={{ fontSize: "1.5rem" }}
              twoToneColor="#52c41a"
            />
            </Tooltip>
          )
        } else {
          return null
        }
      },
    },
  ]
  if (paymentMethods.length === 0) {
    return <Empty description="No cards" />
  }
  return (
    <Table
      rowKey={(row: IStripeCard) => row.id}
      rowClassName={(record: IStripeCard, index) =>
        record.id === defaultPaymentMethod ? "default" : "not-default"
      }
      columns={columns}
      dataSource={paymentMethods}
      pagination={false}
    />
  )
}

function PaymentMethod({
  stripeCustomerId,
  paymentMethods,
  refreshData,
  userId,
  club,
  setAddCardModalVisible,
  defaultPaymentMethod,
}: {
  stripeCustomerId: string
  paymentMethods: any[]
  refreshData: () => void
  userId: number | null
  club: IClub | null
  setAddCardModalVisible: (newVisible: boolean) => void
  defaultPaymentMethod: string | null
}) {
  return (
    <React.Fragment>
      <br />
      <div>
        <div style={{ display: "flex" }}>
          <Button onClick={() => setAddCardModalVisible(true)}
                  type="link"
                  icon={<PlusCircleFilled />}
          >
            Add New Card
          </Button>
          {/*<BillingAddBank stripeCustomerId={stripeCustomerId} />*/}
        </div>
        <br />
        {club?.clubId && (
          <PaymentMethodTable
            paymentMethods={paymentMethods}
            refreshData={refreshData}
            clubId={club?.clubId}
            defaultPaymentMethod={defaultPaymentMethod}
          />
        )}
      </div>
    </React.Fragment>
  )
}

interface IStripeCustomer {
  address: string | null
  balance: number
  created: number
  currency: string | null
  delinquent: boolean
  description: string | null
  email: string | null
  id: string
  invoice_prefix: string
  metadata: { [key: string]: any }
  name: string
  object: string
}

interface IStripeCard {
  billing_details: {
    address: {
      city: string | null
      state: string | null
      country: string | null
      line1: string | null
      line2: string | null
      postal_code: string | null
    }
    email: string | null
    name: string | null
    phone: string | null
  }
  card: {
    brand: string
    country: string | null
    exp_month: number | null
    exp_year: number | null
    fingerprint: "aQ6eGsgG7DXLBAy8"
    funding: string
    last4: string
  }
  created: number
  customer: string
  id: string // paymentMethodId
  metadata: { [key: string]: any }
  object: "payment_method" | string
  type: "card" | string
}

export default function BillingClub(): JSX.Element {
  const [loading, setLoading] = useState(false)
  const [customer, setCustomer] = useState<IStripeCustomer | null>(null)
  const [paymentMethods, setPaymentMethods] = useState<IStripeCard[]>([])
  const [addCardModalVisible, setAddCardModalVisible] = useState(false)
  const [defaultPaymentMethod, setDefaultPaymentMethod] =
    useState<string | null>("")

  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)
  const impersonateClub = useStoreState(
    (state: StateMapper<AppDataStore>) => state.aliasedClub,
  )

  const refreshData = useCallback(() => {
    if (impersonateClub?.clubId) {
      setLoading(true)
      swimminglyApi
        .get(gen(`/api/getBillingDataForClub/${impersonateClub?.clubId}`))
        .then(
          ({
            customer,
            cardsOnFile,
            status,
            defaultPaymentMethod,
          }: {
            customer: IStripeCustomer
            cardsOnFile: IStripeCard[]
            status: string
            defaultPaymentMethod: string
          }) => {
            if (status === "success" && cardsOnFile) {
              setCustomer(customer)
              setPaymentMethods(cardsOnFile)
              setDefaultPaymentMethod(defaultPaymentMethod)
            }
          },
        )
        .finally(() => {
          setLoading(false)
        })
    }
  }, [setLoading, impersonateClub?.clubId, setCustomer, setPaymentMethods])

  useEffect(() => {
    let mounted = true
    if (mounted) {
      refreshData()
    }
    return () => {
      mounted = false
    }
  }, [refreshData, user?.userId])

  if (loading) {
    return (
      <React.Fragment>
        <div>
          <header className="page-header">
            <h2>Club Billing</h2>
          </header>
          <ClubBlock />
          <br />

          <section className="panel">
            <header className="panel-heading">
              <h2 className="panel-title">Club Billing</h2>
            </header>
            <div className="ourpanel-body">
              <CenterSpin />
            </div>
          </section>
        </div>
      </React.Fragment>
    )
  }

  if (!customer) {
    return (
      <React.Fragment>
        <div>
          <header className="page-header">
            <h2>Club Billing</h2>
          </header>
          {impersonateClub && <ClubBlock />}

          <section className="panel">
            <header className="panel-heading">
              <h2 className="panel-title">Club Billing</h2>
            </header>
            <div className="ourpanel-body">Can't find customer</div>
          </section>
        </div>
      </React.Fragment>
    )
  }

  return (
    <div>
      <header className="page-header">
        <h2>Club Billing</h2>
      </header>

      <ClubBlock />

      <section className="panel">
        <header className="panel-heading">
          <h2 className="panel-title">Club Billing</h2>

          <Tabs
            defaultActiveKey="paymentMethod"
            size={"large"}
            tabBarStyle={{ margin: 0, padding: 0 }}
            style={{ marginBottom: "20px" }}
          >
            <TabPane
              tab="Payment Method"
              key="paymentMethod"
              >
              <PaymentMethod
                setAddCardModalVisible={setAddCardModalVisible}
                stripeCustomerId={customer.id}
                paymentMethods={paymentMethods}
                refreshData={refreshData}
                userId={user?.userId || null}
                club={impersonateClub}
                defaultPaymentMethod={defaultPaymentMethod}
              />
            </TabPane>
            <TabPane tab="Invoices" key="invoices">
              <ClubInvoices />
            </TabPane>
          </Tabs>
        </header>
      </section>
      {impersonateClub?.clubId && (
        <AddCardForClub
          clubId={impersonateClub?.clubId}
          visible={addCardModalVisible}
          setVisible={setAddCardModalVisible}
          refreshData={refreshData}
        />
      )}
    </div>
  )
}
