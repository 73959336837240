import { FunctionComponent, useCallback, useState } from "react"
import { Button, message, Switch, Tooltip } from "antd"
import { IWaiver, useClubSeasonManagementContext } from "../sharedState"
import { RegistrationWaiverSubsection } from "./RegistrationWaiverSubsection"
import {
  StyledAlertSpan,
  StyledPreformattedText,
  StyledWarningOutlined,
} from "./styles"
import { Colors } from "../styles"
import { gen, swimminglyApi } from "../../utils"
import { EditOutlined } from "@ant-design/icons" 
import { ParagraphedText } from "../../ui"

interface IRegistrationWaiverProps {
  waiver: IWaiver
  goToWaiversOverview: () => void
}

export const RegistrationWaiver: FunctionComponent<IRegistrationWaiverProps> =
  ({ waiver, goToWaiversOverview }) => {
    const [editingDescription, setEditingDescription] = useState(false)
    const [waiverDescription, setWaiverDescription] = useState(
      waiver.waiverDescription,
    )
    const [triggerSectionsRefresh, setTriggerSectionsRefresh] = useState(0)
    const [editingWaiverName, setEditingWaiverName] = useState(false)
    const [waiverName, setWaiverName] = useState(waiver.waiverName)

    const {
      dbUsingRegistration,
      setRegistrationModalVisible,
      refreshOneWaiver,
    } = useClubSeasonManagementContext()

    const updateThisWaiver = useCallback(async () => {
      await refreshOneWaiver(waiver.waiverId)
      setTriggerSectionsRefresh(triggerSectionsRefresh + 1)
    }, [refreshOneWaiver, triggerSectionsRefresh, waiver.waiverId])

    const updateWaiverName = useCallback(() => {
      swimminglyApi
        .post(gen(`/api/updateWaiverName/${waiver.waiverId}`))
        .body({
          waiverName,
        })
        .then((data) => {
          if (data.status !== "success") {
            console.log(data)
            throw new Error(data?.message || "unknown error")
          }
        })
        .catch(() => {
          message.error("Something went wrong updating the waiver name")
        })
        .finally(() => {
          setEditingWaiverName(false)
          updateThisWaiver()
        })
    }, [updateThisWaiver, waiver.waiverId, waiverName])

    return (
      <div>
        <br />
        {editingWaiverName ? (
          <input
            value={waiverName}
            onBlur={updateWaiverName}
            onKeyDown={(e) => {
              if (["enter", "return"].includes(e.key.toLowerCase())) {
                updateWaiverName()
              }
            }}
            onChange={(e) => setWaiverName(e.target.value)}
          />
        ) : (
          <h4>
            {waiver.waiverName}{" "}
            <span
              style={{ fontSize: "16px", color: Colors.veryDarkBlue, cursor: "pointer" }}
              onClick={() => {
                if (!dbUsingRegistration) {
                  setRegistrationModalVisible(true)
                } else {
                  setEditingWaiverName(true)
                }
              }}
            >
              {" "}<EditOutlined />
              &nbsp;<b>Click to edit waiver title</b>
            </span>
          </h4>
        )}
        <label>
          <Switch
            checked={Boolean(waiver.waiverEnabled)}
            onChange={async (checked, e) => {
              e.preventDefault()
              await swimminglyApi
                  .post(gen(`/api/updateWaiverEnabled`))
                  .body({
                    registrationFormId: waiver.registrationFormId,
                    waiverId: waiver.waiverId,
                    choice: checked,
                  })
                  .then((data) => {
                    if (data.status === "success") {
                      updateThisWaiver()
                    }
                  })
            }}
          />{" "}
          waiver {Boolean(waiver.waiverEnabled) ? "enabled" : "disabled"}
        </label>
        <div>
          <br />
          <b>Description</b>
          {!editingDescription ? (
            <div>
              <StyledPreformattedText>
                <ParagraphedText idPrefix={`p_waiver_${waiver.waiverId}`} linkify={true}>
                  {waiverDescription}
                </ParagraphedText>                
              </StyledPreformattedText>
              <span
                style={{ fontSize: "16px", color: Colors.veryDarkBlue, cursor: "pointer" }}
                onClick={() => {
                  if (!dbUsingRegistration) {
                    setRegistrationModalVisible(true)
                  } else {
                    setEditingDescription(true)
                  }
                }}
              >
                <EditOutlined />
                &nbsp;<b>Click to edit waiver description</b>
              </span>
            </div>
          ) : (
            <div>
              <textarea
                value={waiverDescription}
                style={{ width: "95%", minHeight: "150px" }}
                onChange={(e) => setWaiverDescription(e.target.value)}
              />
              <br />
              <StyledAlertSpan
                onClick={async () => {
                  await swimminglyApi
                    .post(gen(`/api/updateWaiverDescription`))
                    .body({
                      registrationFormId: waiver.registrationFormId,
                      waiverId: waiver.waiverId,
                      newDescription: waiverDescription,
                    })
                    .then((data) => {
                      if (data.status === "success") {
                        setEditingDescription(false)
                        updateThisWaiver()
                      }
                    })
                    .catch(() => {
                      message.error("problem updating your waiver description")
                      setEditingDescription(false)
                    })
                }}
              >
                click to save
              </StyledAlertSpan>{" "}
              <Tooltip title="not saved">
                <StyledWarningOutlined />
              </Tooltip>
            </div>
          )}
        </div>
        {waiver.waiverSectionIds?.map((waiverSectionId) => (
          <RegistrationWaiverSubsection
            key={`registration_waiver_subsection_${waiverSectionId}`}
            waiverName={waiver.waiverName}
            waiverSectionId={waiverSectionId}
            triggerRefresh={triggerSectionsRefresh}
            refreshWaiver={updateThisWaiver}
            onlyWaiverSection={waiver.waiverSectionIds?.length === 1}
          />
        ))}
        <label>
        <p style={{ fontSize: "16px", color: Colors.veryDarkBlue }}>
          <Button
            style={{ borderRadius: "50px" }}
            type="primary"
            onClick={() => {
              if (!dbUsingRegistration) {
                setRegistrationModalVisible(true)
              } else {
                swimminglyApi
                  .post(gen(`/api/addWaiverSection`))
                  .body({
                    waiverId: waiver.waiverId,
                  })
                  .then((data) => {
                    if (data.status === "success") updateThisWaiver()
                    else {
                      message.error("Problem adding section to waiver")
                      console.log(data)
                    }
                  })
              }
            }}
          >
            +
          </Button>{" "}
          &nbsp;Add Another Section to {waiver.waiverName}
            </p>
        </label>
      </div>
    )
  }
