import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../../appData/types"
import {
  gen,
  convertAgeGroupToNumbers,
  ISwimmerWithNumericGender,
} from "../utils"
import { swimminglyApi } from "../utils"
import { TMeetEvent } from "."
import { Tooltip, Radio} from "antd"
import { CheckOutlined } from "@ant-design/icons"

export function SwimmerMeetEvents({
  meetId,
  refreshSwimmers,
  activeSwimmer,
  events,
  eventsIAmIn,
}: {
  meetId: number
  refreshSwimmers: () => void
  activeSwimmer: { swimmer: ISwimmerWithNumericGender | null; meetAge: number }
  events: TMeetEvent[]
  eventsIAmIn: { swimEventId: number; isExhibition: 0 | 1 }[]
}): JSX.Element | null {
  const impersonateClub = useStoreState(
    (state: StateMapper<AppDataStore>) => state.aliasedClub,
  )

  const addSwimmersToMeet = (
    swimmerId: number,
    swimEventId: number,
    isExhibition: boolean,
  ) => {
    swimminglyApi
      .post(gen("/api/addMeetSwimmers2Post"))
      .body({
        clubId: impersonateClub?.clubId,
        swimmer_id: swimmerId,
        event_id: swimEventId,
        isrelay: false,
        meet_id: meetId,
        is_exhibition: isExhibition,
      })
      .then((data) => {
        refreshSwimmers()
      })
  }

  const deleteSwimmerEvent = (swimmerId: number, swimEventId: number) => {
    swimminglyApi
      .post(gen("/api/removeSwimmerEvent2Post"))
      .body({
        meet_id: meetId,
        swimmer_id: swimmerId,
        swim_event_id: swimEventId,
        isrelay: false,
        clubId: impersonateClub?.clubId,
      })
      .then((data) => {
        refreshSwimmers()
      })
  }

  if (!activeSwimmer.swimmer) {
    return null
  }

  let filteredEventsByGender = events.filter(
    (el) =>
      el.gender === "Mixed" ||
      (el.gender === "Boys" && activeSwimmer.swimmer?.gender === 2) ||
      (el.gender === "Girls" && activeSwimmer.swimmer?.gender === 1),
  )
  let filteredEventsByAge = filteredEventsByGender.filter((el) => {
    let isGood = true
    const { lower: bottom, upper: top } = convertAgeGroupToNumbers(el.ageGroup)
    if (bottom) {
      isGood = isGood && activeSwimmer.meetAge >= bottom
    }
    if (top) {
      isGood = isGood && activeSwimmer.meetAge <= top
    }
    return isGood
  })
  let filteredEvents = filteredEventsByAge.filter((el) => !el.isRelay)

  let styleForBox = {
    height: "20px",
    width: "80px",
    border: "1px solid black",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    marginRight: "1px",
  }
  return (
    <div>
      {filteredEvents.map((event) => {
        let amPresent =
          eventsIAmIn.filter(
            (el) => el.swimEventId === event.swimEventId && !el.isExhibition,
          ).length > 0
        let amPresentInExhibition =
          eventsIAmIn.filter(
            (el) => el.swimEventId === event.swimEventId && el.isExhibition,
          ).length > 0
        let notThere = !eventsIAmIn
          .map((el) => el.swimEventId)
          .includes(event.swimEventId)
        return (
          <div style={{ display: "flex" }} key={event.swimEventId}>
            {(amPresent || notThere) && (
              <Tooltip
                title="Add official entry"
                placement="left"
                color={"green"}
                mouseEnterDelay={0.5}
              >
              <div
                style={
                  amPresent
                    ? Object.assign({}, styleForBox, {
                        backgroundColor: "#73d13d",
                      })
                    : styleForBox
                }
                onClick={() => {
                  activeSwimmer.swimmer?.swimmerId &&
                    addSwimmersToMeet(
                      activeSwimmer.swimmer.swimmerId,
                      event.swimEventId,
                      false,
                    )
                }}
              >
                <CheckOutlined />Official
              </div>
              </Tooltip>
            )}
            {(amPresentInExhibition || notThere) && (
              <Tooltip
              title="Add exhibition entry"
              placement="top"
              color={"green"}
              mouseEnterDelay={0.5}
            >
              <div
                style={
                  amPresentInExhibition
                    ? Object.assign({}, styleForBox, {
                        backgroundColor: "#73d13d",
                      })
                    : styleForBox
                }
                onClick={() =>
                  activeSwimmer.swimmer?.swimmerId &&
                  addSwimmersToMeet(
                    activeSwimmer.swimmer.swimmerId,
                    event.swimEventId,
                    true,
                  )
                }
              >
                <CheckOutlined />Exhibition
              </div>
              </Tooltip>
            )}
            <Tooltip
              title="Remove entry"
              placement="right"
              color={"volcano"}
              mouseEnterDelay={0.5}
            >
            <div
              style={
                notThere
                  ? Object.assign({}, styleForBox, {
                      backgroundColor: "#ff7a45",
                    })
                  : styleForBox
              }
              onClick={() =>
                activeSwimmer.swimmer?.swimmerId &&
                deleteSwimmerEvent(
                  activeSwimmer.swimmer.swimmerId,
                  event.swimEventId,
                )
              }
            /></Tooltip>

            <div>
            
              {event.gender} &nbsp;{event.ageGroup}&nbsp;{event.distance}&nbsp;
              {event.stroke}
            </div>
          </div>
        )
      })}
    </div>
  )
}
