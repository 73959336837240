import { FunctionComponent } from "react"
import { formatMoney } from "../utils"

export type MoneyCellProps  = {
    bold?: boolean
    color?: string
    amount?: number
}
  
export const MoneyCell: FunctionComponent<MoneyCellProps>  = ({amount = 0, bold = false, color}: MoneyCellProps)  => {
    const colorStyle = Boolean(color) ? {color: color} : {}
    return (<td align="right" style={{...colorStyle, fontWeight: bold ? "bold" : "normal"}}>{formatMoney(amount ?? 0, true)}</td>)
}
  