import { useCallback, useEffect } from "react"
import styled from "styled-components"
import { screenSizes } from "../../styles/GlobalStyles"
import { meetAgeGroupArr } from "../utils"

const EventPickerContainer = styled.div`
  h3 {
    text-align: center;
  }
`

const StyledEventPicker = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 10px;

  .direction-buttons {
    background: rgb(153, 153, 153) none repeat scroll 0 0;
    border-radius: 4px;
    color: #fff;
    padding: 4px 8px 4px 8px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
  }

  @media (max-width: ${screenSizes.extraSmall}px) {
    .direction-buttons {
      min-width: 25px;
    }
  }
`

interface ISwimEventSelectorProps {
  swimEvents: {
    stroke: string
    distance: number
    gender: string
    ageGroup: string
    isRelay: 0 | 1
    eventNumber: number
    swimEventId: number
  }[]
  currentEventId: number
  setCurrentEventId: (newId: number) => void
  setGenderFilter: (newGenderFilter: "Boys" | "Girls" | "Mixed") => void
  setAgeGroupFilter: (newAgeGroupFilter: string) => void
}

export default function SwimEventSelector({
  swimEvents,
  currentEventId,
  setCurrentEventId,
  setGenderFilter,
  setAgeGroupFilter,
}: ISwimEventSelectorProps): JSX.Element {
  const moveToNextEvent = useCallback(() => {
    const currentEventIndexPosition = swimEvents.findIndex(
      (evt) => evt.swimEventId === currentEventId,
    )
    if (currentEventIndexPosition < swimEvents.length - 1) {
      setCurrentEventId(swimEvents[currentEventIndexPosition + 1].swimEventId)
    } else {
      setCurrentEventId(swimEvents[0].swimEventId)
    }
  }, [swimEvents, currentEventId, setCurrentEventId])

  const moveToPreviousEvent = useCallback(() => {
    const currentEventIndexPosition = swimEvents.findIndex(
      (evt) => evt.swimEventId === currentEventId,
    )
    if (currentEventIndexPosition > 0) {
      setCurrentEventId(swimEvents[currentEventIndexPosition - 1].swimEventId)
    } else {
      setCurrentEventId(swimEvents[swimEvents.length - 1].swimEventId)
    }
  }, [swimEvents, currentEventId, setCurrentEventId])

  // update the gender and age group filters automatically when we switch events from the UI
  useEffect(() => {
    const event = swimEvents.find((evt) => evt.swimEventId === currentEventId)
    if (event) {
      setGenderFilter(event.gender as "Boys" | "Girls" | "Mixed")
      if (Object.values(meetAgeGroupArr).includes(event.ageGroup)) {
        setAgeGroupFilter(event.ageGroup)
      } else {
        setAgeGroupFilter("Open")
      }
    }
  }, [swimEvents, currentEventId, setGenderFilter, setAgeGroupFilter])

  return (
    <EventPickerContainer className="column">
      <h3>Current Event</h3>
      <StyledEventPicker>
        <span className="direction-buttons left" onClick={moveToPreviousEvent}>
          &lt;
        </span>
        <select
          value={currentEventId}
          onChange={(e) => setCurrentEventId(parseInt(e.target.value, 10))}
        >
          {swimEvents.map((event, idx) => {
            return (
              <option
                value={event.swimEventId}
                key={`swim_event_${event.swimEventId}_${idx}`}
              >
                {`${event.eventNumber}: ${event.gender} ${event.ageGroup} ${event.distance} ${event.stroke}`}
              </option>
            )
          })}
        </select>
        <span className="direction-buttons right" onClick={moveToNextEvent}>
          &gt;
        </span>
      </StyledEventPicker>
    </EventPickerContainer>
  )
}
