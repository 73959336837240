import { Fragment, useState } from "react"
import moment from "moment-timezone"
import { groupBy } from "lodash"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../../appData/types"
import { formatTimeWithoutLeadingZeros, gen, swimminglyApi } from "../utils"
import { RightOutlined, DownOutlined } from "@ant-design/icons"

type course = "SCY" | "SCM" | "LCM"

function SingleEvent({
  meetId,
  isClickable,
  meetDate,
  swimmerId,
  swimEventId,
  meetCourse,
  originalCourse,
  officialTime,
  convertedTime,
  refreshSwimmers,
}: {
  meetId: number
  isClickable: boolean
  meetDate: string
  swimmerId: number
  swimEventId: number
  meetCourse: course
  originalCourse: course
  officialTime: number | null
  convertedTime: number | null
  refreshSwimmers: () => void
}): JSX.Element {
  const impersonateClub = useStoreState(
    (state: StateMapper<AppDataStore>) => state.aliasedClub,
  )

  const onClick = () => {
    impersonateClub?.clubId &&
      swimminglyApi
        .post(gen("/api/changeEventTimePost"))
        .body({
          club: impersonateClub.clubId,
          meetId: meetId,
          athleteId: swimmerId,
          eventId: swimEventId,
          time: convertedTime,
        })
        .then(refreshSwimmers)
  }
  let style = isClickable
    ? { color: "rgb(0, 136, 204)", cursor: "pointer" }
    : undefined
  return (
    <div>
      {moment(meetDate).format("MM/DD/YYYY")}{" "}
      <span style={style} onClick={() => (isClickable ? onClick() : {})}>
        {officialTime ? formatTimeWithoutLeadingZeros(officialTime) : "NT"}
      </span>{" "}
      {originalCourse} <br />
    </div>
  )
}

function SingleEventRecord({
  meetId,
  meetCourse,
  records,
  name,
  isClickable,
  swimmerId,
  swimEventId,
  refreshSwimmers,
}: {
  meetId: number
  meetCourse: course
  isClickable: boolean
  records: {
    meetDate: string
    officialTime: number | null
    convertedTime: number | null
    originalCourse: course
  }[]
  swimmerId: number
  swimEventId: number
  name: string
  refreshSwimmers: () => void
}): JSX.Element | null {
  const [isOpen, setIsOpen] = useState(false)

  if (records.length === 0) {
    return null
  }

  const meetsByYear = groupBy(records, (x) => moment(x.meetDate).format("YYYY"))

  return (
    <div>
      <h4
        onClick={() => setIsOpen(!isOpen)}
        style={{ cursor: "pointer", color: "#0088cc" }}
      >
        {name} {isOpen ? <DownOutlined /> : <RightOutlined />}
      </h4>
      {isOpen && (
        <div style={{ lineHeight: "25px" }}>
          <div style={{ textAlign: "left" }}>
            {Object.keys(meetsByYear)
              .sort((a, b) => (a < b ? 1 : -1))
              .map((theYear, idx) => {
                const yearRecords = meetsByYear[theYear]
                return (
                  <Fragment key={`year_fragment_${theYear}_${idx}`}>
                    <h5 style={{ color: "#0088cc" }}>
                      <b>{theYear}</b>
                    </h5>
                    <ul>
                      {yearRecords.map((events, idx) => (
                        <SingleEvent
                          key={`${events.officialTime}_${idx}`}
                          meetDate={events.meetDate}
                          officialTime={events.officialTime}
                          convertedTime={events.convertedTime}
                          meetCourse={meetCourse}
                          swimmerId={swimmerId}
                          swimEventId={swimEventId}
                          meetId={meetId}
                          refreshSwimmers={refreshSwimmers}
                          isClickable={isClickable}
                          originalCourse={events.originalCourse}
                        />
                      ))}
                    </ul>
                    <br />
                  </Fragment>
                )
              })}
          </div>
        </div>
      )}
    </div>
  )
}

export default function SwimmerTimesHistory({
  event,
  historicalResults,
  meetId,
  swimmerId,
  meetCourse,
  removedFromMeet,
  swimEventId,
  refreshSwimmers,
}: {
  event: { distance: string; stroke: string }
  meetCourse: course
  historicalResults: {
    stroke: string
    distance: string
    meetDate: string
    officialTime: number | null
    convertedTime: number | null
    originalCourse: course
  }[][]
  meetId: number
  swimmerId: number
  removedFromMeet: boolean
  swimEventId: number
  refreshSwimmers: () => void
}): JSX.Element {
  return (
    <Fragment>
      {historicalResults.map((records) => {
        let firstRecord = records[0]
        const name = `${firstRecord.distance}|${firstRecord.stroke}`
        let isClickable = false
        if (event) {
          // console.log(event)
          isClickable =
            event.stroke === firstRecord.stroke &&
            event.distance === firstRecord.distance
        }
        // console.log(distance, stroke, this.props.eventId)
        return (
          <SingleEventRecord
            key={name}
            records={records}
            name={name}
            swimmerId={swimmerId}
            meetId={meetId}
            refreshSwimmers={refreshSwimmers}
            isClickable={isClickable && !removedFromMeet}
            meetCourse={meetCourse}
            swimEventId={swimEventId}
          />
        )
      })}
    </Fragment>
  )
}
