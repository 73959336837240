import styled from "styled-components"

export const StyledRegistrationForm = styled.div`
  text-align: center;
  border: 1px solid lightgrey;
  background-color: whitesmoke;
  border-radius: 5px;
  padding: 15px;
  max-width: 600px;
  margin: auto;
  margin-top: 25px;
`

export const StyledSingleWaiverSection = styled.div`
  border: 1px solid lightgrey;
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 10px;
`
export const StyledWaiver = styled.div`
  text-align: left;
  border-top: 1px solid lightgrey;
  margin-bottom: 5px;
  padding: 10px;
`
