import React, { useState, useEffect, useCallback } from "react"
import moment from "moment-timezone"
import { Button, message, Modal, Menu, Dropdown } from "antd"
import styled from "styled-components"
import { ExclamationCircleTwoTone, DeleteOutlined, DownOutlined } from "@ant-design/icons"
import { useHistory } from "react-router"
import { Link } from "react-router-dom"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../appData/types"
import { download, gen, IClub, IMeet, ISeason, swimminglyApi } from "./utils"
import { screenSizes } from "../styles/GlobalStyles"
import {
  MeetClubStatus,
  updateSeasonMeetArrayListener,
} from "./MeetSchedulePageNew"
import RegularMeetCourseModal from "./UpdateMeetCourseTraditional"
import VirtualMeetCourseModal from "./UpdateMeetCourseVirtual"
import MeetEntryButton from "./MeetEntryButton"
import { FaTrash } from "react-icons/fa"
import ExportResultsModal from "./ExportResultsModal"
import ImportEntriesModal from "./ImportSd3Modals/ImportEntriesModal"
import ImportResultsModal from "./ImportSd3Modals/ImportResultsModal"

// entries link
// clone meet button
// import entries
// import results
// copy results from another meet
// open parent entries
//   have parents declare their swimmers for this meet!
// update parent entries deadline
// add club to meet
// remove club from meet
// change pool of regular meet
// change course of virtual meet
// update scoring for virtual meet
// softRemove meet
// rename meet

const StyledModal = styled(Modal)`
  max-width: 40%;
  min-width: 725px;

  .modal-body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    align-content: center;
    column-gap: 25px;
    row-gap: 10px;
  }

  .item {
    text-align: center;
    height: 50px;
  }

  div.item {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  .ant-modal-content {
    max-height: calc(95vh - 100px);
    overflow: scroll;
  }

  .shadowed {
    box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    padding: 5px;
  }

  @media (max-width: ${screenSizes.medium}px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: ${screenSizes.small}px) {
    min-width: 0;
    max-width: 97%;

    .modal-body {
      grid-template-columns: 1fr 1fr;
    }
  }
`

export interface IClubSeasonInformation {
  club: IClub
  season: ISeason
  clubSeasonId: number
  entryStatusInMeet: null | 0 | 1 | 2
}

export interface IMeetPdfStatus {
  labelsExists: boolean
  resultsExists: boolean
}

export default function MeetActionsModal({
  meetId,
  visible,
  setVisible,
  refreshData,
}: {
  meetId: number
  visible: boolean
  setVisible: (newVisible: boolean) => void
  refreshData: () => void
}): JSX.Element {
  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)
  const impersonateClub = useStoreState(
    (state: StateMapper<AppDataStore>) => state.aliasedClub,
  )
  const [loading, setLoading] = useState(false)
  const [meetName, setMeetName] = useState("")
  const [course, setCourse] = useState<"SCY" | "SCM" | "LCM">("SCY")
  const [virtualCourse, setVirtualCourse] =
    useState<"SCY" | "SCM" | "LCM" | null>(null)
  const [homeClubId, setHomeClubId] = useState<number>(-1)
  const [virtualMeetId, setVirtualMeetId] = useState<number | null>(null)
  const [virtualMeetHasResults, setVirtualMeetHasResults] = useState(false)
  const [meetStartDate, setMeetStartDate] = useState(moment())
  const [meetEndDate, setMeetEndDate] = useState(moment())
  const [meetClubSeasons, setMeetClubSeasons] = useState<
    IClubSeasonInformation[]
  >([])
  const [meetPdfStatus, setMeetPdfStatus] = useState<IMeetPdfStatus>()
  const [thisClubSeason, setThisClubSeason] =
    useState<IClubSeasonInformation | null>(null)
  const [meetLineupConfirmationVisible, setMeetLineupConfirmationVisible] =
    useState(false)
  const [updateTraditionalCourseVisible, setUpdateTraditionalCourseVisible] =
    useState(false)
  const [updateVirtualCourseVisible, setUpdateVirtualCourseVisible] =
    useState(false)
  const [triggerRefresh, setTriggerRefresh] = useState(0)
  const [exportResultsVisible, setExportResultsVisible] = useState(false)
  const [importEntriesVisible, setImportEntriesVisible] = useState(false)
  const [importResultsVisible, setImportResultsVisible] = useState(false)
  const [sd3ImportDeactivated, setSd3ImportDeactivated] = useState(false)

  const history = useHistory()

  useEffect(() => {
    setExportResultsVisible(false)
    setUpdateVirtualCourseVisible(false)
    setUpdateTraditionalCourseVisible(false)
    setMeetLineupConfirmationVisible(false)
  }, [meetId])

  useEffect(() => {
    const clubSeason = meetClubSeasons.find(
      (mCS) => mCS.club.clubId === impersonateClub?.clubId,
    )
    if (clubSeason?.clubSeasonId) {
      setThisClubSeason(clubSeason)
    }
  }, [impersonateClub?.clubId, meetClubSeasons])

  const getMeetInformation = useCallback((meetId: number, clubId: number | undefined): Promise<{
    meet: IMeet
    clubSeasons: IClubSeasonInformation[]
    meetPdfStatus: IMeetPdfStatus
  }> => {
    return swimminglyApi
      .get(gen(`/api/getMeetInformation/${meetId}/${clubId}`))
      .then(
        ({
          status,
          meet,
          clubSeasons,
          meetPdfStatus
        }: {
          status: string
          meet: IMeet
          clubSeasons: IClubSeasonInformation[]
          meetPdfStatus: IMeetPdfStatus
        }) => {
          if (status === "success") {
            return { meet, clubSeasons, meetPdfStatus }
          }
          throw new Error("Unsuccessful api request")
        },
      )
      .catch((err) => {
        console.error(err)
        throw new Error("Something went wrong in the api call")
      })
  }, [])

  const cloneThisMeet = useCallback(() => {
    setLoading(true)
    fetch(gen("/api/cloneMeet"), {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
      body: JSON.stringify({ meetId: meetId }),
    })
      .then((result) => result.json())
      .then(({ status }) => {
        if (!status || status !== "success") {
          message.error("Uh oh, something went wrong")
        } else {
          message.success("Copied meet successfully!")
          const clubSeason = meetClubSeasons.find(
            (mCS) => mCS.club.clubId === impersonateClub?.clubId,
          )
          if (clubSeason?.clubSeasonId) {
            updateSeasonMeetArrayListener.emit(
              `updateSeasonMeets${clubSeason.clubSeasonId}`,
            )
          }
        }
      })
      .finally(() => {
        setLoading(false)
        setVisible(false)
      })
  }, [meetId, setLoading, setVisible, meetClubSeasons, impersonateClub?.clubId])

  useEffect(() => {
    if (meetId !== -1) {
      setLoading(true)
      getMeetInformation(meetId, impersonateClub?.clubId)
        .then(({ meet, clubSeasons, meetPdfStatus }) => {
          setMeetName(meet.meetName)
          setMeetStartDate(moment(meet.startDate))
          setMeetEndDate(moment(meet.endDate))
          setHomeClubId(meet.is_home || -1)
          setVirtualMeetId(meet.virtualMeetId || null)
          setVirtualMeetHasResults(meet.hasVirtualResults || false)
          setCourse(
            meet.unit_of_measure === 0
              ? "SCY"
              : meet.unit_of_measure === 1
                ? "SCM"
                : "LCM",
          )
          if (meet.virtualCourse === undefined || meet.virtualCourse === null) {
            setVirtualCourse(null)
          } else {
            setVirtualCourse(
              meet.virtualCourse === 0
                ? "SCY"
                : meet.virtualCourse === 1
                  ? "SCM"
                  : "LCM",
            )
          }
          setMeetClubSeasons(clubSeasons)
          setMeetPdfStatus(meetPdfStatus)
        })
        .finally(() => setLoading(false))
    }
  }, [meetId, getMeetInformation, triggerRefresh])

  const actionButton = ({ url, fileName, caption, visible }: { url: string, fileName: string, caption: string, visible: boolean }): JSX.Element | boolean => {
    return (visible &&
      <Button
        className="shadowed item"
        type="link"
        onClick={() => {
          swimminglyApi
            .get(gen(url))
            .then((file) => {
              download(file, fileName)
            })
        }}
      >
        {caption}
      </Button>)
  }

  return (
    <div>
      <StyledModal
        title={
          <span>
            Meet:
            <b>{meetName.length > 0 ? meetName : "NO MEET NAME"}</b>
          </span>
        }
        width="100%"
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        confirmLoading={loading}
      >
        <div className="modal-body">
          {thisClubSeason?.entryStatusInMeet === 2 && (
            <div className="shadowed item">
              <Link to={`/app/meet/results/${meetId}`}>
                {virtualMeetId === null ? "Results" : "My Club's Results"}
              </Link>
            </div>
          )}
          {virtualMeetId !== null && virtualMeetHasResults && (
            <Link
              className="shadowed item"
              to={`/app/virtualMeet/results/${virtualMeetId}`}
            >
              Virtual Meet Results
            </Link>
          )}
          {thisClubSeason?.entryStatusInMeet !== 2 &&
            thisClubSeason?.entryStatusInMeet !== 1 && (
              <div
                className="shadowed item"
                onClick={() => setMeetLineupConfirmationVisible(true)}
              >
                <Link
                  to={`/app/club/viewMeetLineUp2/${meetId}`}
                  onClick={(e) => {
                    e.preventDefault()
                  }}
                >
                  Edit Entries
                </Link>
              </div>
            )}
          {thisClubSeason?.entryStatusInMeet === 1 && (
            <div
              className="shadowed item"
              onClick={() => {
                history.push(`/app/club/heatSheet2/${meetId}`)
              }}
            >
              <Link to={`/app/club/heatSheet2/${meetId}`}>View Heat Sheet</Link>
            </div>
          )}
          {user?.role === 2 &&
            meetName !==
            "2021 All American Swim Summer National Championships" && (
              <Button
                className="shadowed item"
                type="link"
                onClick={cloneThisMeet}
              >
                Clone Meet
              </Button>
            )}
          <Button
            className="shadowed item"
            type="link"
            onClick={() => setUpdateTraditionalCourseVisible(true)}
          >
            Update My Meet Course
          </Button>
          {virtualMeetId !== null &&
            meetName !==
            "2021 All American Swim Summer National Championships" && (
              <Button
                className="shadowed item"
                type="link"
                onClick={() => setUpdateVirtualCourseVisible(true)}
              >
                Update Virtual Course
              </Button>
            )}
          {meetEndDate > moment() && impersonateClub?.clubId && user && (
            <MeetEntryButton
              className="shadowed item"
              meetId={meetId}
              clubId={impersonateClub.clubId}
              userId={user.userId}
              refreshPage={() => setTriggerRefresh(triggerRefresh + 1)}
            />
          )}
          {thisClubSeason?.entryStatusInMeet !== 2 ? (
            <Button
              disabled={sd3ImportDeactivated}
              className="shadowed item"
              type="link"
              onClick={() =>
                !sd3ImportDeactivated && setImportEntriesVisible(true)
              }
            >
              Import Meet Entries
            </Button>
          ) : null}
          <Button
            disabled={sd3ImportDeactivated}
            className="shadowed item"
            type="link"
            onClick={() =>
              !sd3ImportDeactivated && setImportResultsVisible(true)
            }
          >
            Import Meet Results
          </Button>
          {thisClubSeason?.entryStatusInMeet !== 2 ? (
            <Button
              className="shadowed item"
              type="link"
              onClick={() => {
                const confirmed = window.confirm(
                  "Are you sure you want to remove this meet?",
                )
                if (!confirmed) {
                  message.warning("Meet was not removed", 4)
                  return
                }
                swimminglyApi
                  .post(gen(`/api/softRemoveMeet`))
                  .body({ meetId })
                  .then(() => {
                    refreshData()
                    setTriggerRefresh(triggerRefresh + 1)
                    setVisible(false)
                    message.success("Meet was removed!", 4)
                  })
              }}
            >
              <DeleteOutlined /> <span>Delete Meet</span>
            </Button>
          ) : null}
          {meetClubSeasons.length > 0 &&
            meetClubSeasons.find(
              (mCS) => mCS.club.clubId === impersonateClub?.clubId,
            )?.entryStatusInMeet === 2 &&
            impersonateClub?.clubId && (
              <React.Fragment>
                <Button
                  className="shadowed item"
                  type="link"
                  onClick={() => {
                    setExportResultsVisible(true)
                  }}
                >
                  Export Meet Results
                </Button>
                <Button
                  className="shadowed item"
                  type="link"
                  onClick={() => {
                    swimminglyApi
                      .post(gen("/api/createPostMeetExcel"))
                      .body({ meetId, virtualMeetId })
                      .then((file) => {
                        download(file, `MeetResults.xlsx`)
                      })
                  }}
                >
                  Download Results Excel
                </Button>
                {actionButton({
                  url:`/api/downloadPdf/${meetId}/false/${impersonateClub.clubId}`, 
                  fileName:"MeetResults.pdf", 
                  caption:"Download Results PDF", 
                  visible:Boolean(meetPdfStatus?.resultsExists) 
                  })}

                {actionButton({
                  url: `/api/downloadPdf/${meetId}/true/${impersonateClub.clubId}`, 
                  fileName: "Labels.pdf",
                  caption: "Download Labels PDF",
                  visible: Boolean(meetPdfStatus?.labelsExists)
                })}

              </React.Fragment>
            )}

        </div>
        <div className="shadowed">
          <h4>Meet Clubs:</h4>
          <hr />
          {meetClubSeasons.map((mCS) => (
            <MeetClubStatus
              key={`meet_status_${meetId}_${mCS.club.clubId}`}
              club={mCS.club}
              isHome={homeClubId === mCS.club.clubId}
              meetstatus={mCS.entryStatusInMeet}
            />
          ))}
        </div>
      </StyledModal>
      {impersonateClub?.clubId && (
        <ExportResultsModal
          meetId={meetId}
          clubId={impersonateClub.clubId}
          isVisible={exportResultsVisible}
          updateVisible={setExportResultsVisible}
        />
      )}
      <ImportResultsModal
        visible={importResultsVisible}
        setVisible={setImportResultsVisible}
        meetId={meetId}
        refresh={() => setTriggerRefresh(triggerRefresh + 1)}
      />
      <ImportEntriesModal
        visible={importEntriesVisible}
        setVisible={setImportEntriesVisible}
        meetId={meetId}
        refresh={() => setTriggerRefresh(triggerRefresh + 1)}
      />
      <Modal
        visible={meetLineupConfirmationVisible}
        title={
          <span>
            <ExclamationCircleTwoTone /> Heading to Meet
            Entries for your Lineup
          </span>
        }
        onCancel={() => setMeetLineupConfirmationVisible(false)}
        okText="Proceed to Meet Entries"
        onOk={() => history.push(`/app/club/viewMeetLineUp2/${meetId}`)}
      >
        <p>
          <ul>
            <li>
              Proceeding to your club's live meet entries. 
            </li>
            <li>
              Double-check the event order is accurate before completing your
              entries. If it is not accurate, please contact your club or league rep.
            </li>
          </ul>
        </p>
      </Modal>
      <RegularMeetCourseModal
        visible={updateTraditionalCourseVisible}
        setVisible={setUpdateTraditionalCourseVisible}
        meetId={meetId}
        clubSeason={thisClubSeason}
      />
      <VirtualMeetCourseModal
        visible={updateVirtualCourseVisible}
        setVisible={setUpdateVirtualCourseVisible}
        clubSeason={thisClubSeason}
        virtualMeetId={virtualMeetId}
      />
    </div>
  )
}


