import { Empty, Modal, Switch } from "antd"
import { Fragment, FunctionComponent, useState } from "react"
import { AddItemButton } from "../../AddItemButton"
import { formatMoney } from "../../utils"
import {
  IPriceModificationCode,
  useClubSeasonManagementContext,
} from "../sharedState"
import { Colors } from "../styles"

interface IPriceModificationCodeSectionProps {
  codes: IPriceModificationCode[]
  type: "discount" | "fee"
}

export const PriceModificationCodeSection: FunctionComponent<IPriceModificationCodeSectionProps> =
  ({ codes, type }) => {
    const [modalVisible, setModalVisible] = useState(false)
    const [newAmount, setNewAmount] = useState(0)
    const [newCode, setNewCode] = useState(type === "discount" ? "NEWCODE" : "Team T Shirt")
    const [description, setDescription] = useState("Enter brief description")

    const {
      addPriceModificationCode,
      updatePriceModificationCode,
      dbUsingRegistration,
      setRegistrationModalVisible,
    } = useClubSeasonManagementContext()

    return (
      <Fragment>
        <div style={{
          marginTop: "15px",
          marginBottom: "15px",
          textAlign: "center",
          fontSize: "18px",
          color: Colors.veryDarkBlue ,
          fontWeight: "bold",
        }}>
          {type === "discount"
            ? "You Have Set Up These Discount Codes"
            : "You Have Set Up These Additional Checkout Items"}
        </div>
        <div
          style={{
            fontSize: "16px",
            maxWidth: "1200px",
            backgroundColor: "whitesmoke",
            borderRadius: "5px",
            padding: "30px",
            margin: "auto",
            display: "grid",
            gridTemplateColumns: "3fr 2fr 1fr",
          }}
        >
          <div
            style={{
              fontSize: "18px",
              gridColumn: "1 / span 3",
              display: "grid",
              gridTemplateColumns: "3fr 2fr 1fr",
              borderBottom: "1px solid lightgrey",
            }}
          >
            <span>
              <b>{type === "discount" ? "Discount Code" : "Additional Checkout Item"}</b>
            </span>
            <span>
              <b>{type === "discount" ? "$ Discount Amount" : "$ Cost of Item"}</b>
            </span>
            <span>
              <b>{type === "discount" ? "Code" : "Item"} is Active</b>
            </span>
          </div>
          {codes.length === 0 && (
            <div
              style={{
                gridColumn: "1 / span 4",
                margin: "auto",
                textAlign: "center",
                marginTop: "15px",
              }}
            >
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>no codes set up</span>}
              />
            </div>
          )}
          {codes.map((theCode) => {
            return (
              <Fragment key={`${type}_code_${theCode.priceModificationCodeId}`}>
                <span>{theCode.code}</span>
                <span>{formatMoney(theCode.priceModificationAmount)}</span>
                {/* additional fees are always per transaction */}
                <Switch
                  style={{ maxWidth: "25px" }}
                  checked={theCode.isActive === 1}
                  onChange={async (checked, e) => {
                    const {
                      priceModificationCodeId,
                      priceModificationAmount,
                      code,
                    } = theCode
                    await updatePriceModificationCode(
                      priceModificationCodeId,
                      priceModificationAmount,
                      code,
                      checked ? 1 : 0,
                    )
                  }}
                />
              </Fragment>
            )
          })}
        </div>
        <br />
        <AddItemButton
          style={{
            fontSize: "18px",
            color: Colors.veryDarkBlue,
            marginBottom: "25px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          text={`Add New ${type === "discount" ? "Discount Code" : "Additional Checkout Item"}`}
          onClick={() => {
            if (!dbUsingRegistration) {
              setRegistrationModalVisible(true)
            } else {
              setModalVisible(true)
            }
          }}
        />
        <Modal
          title={`Add New ${type.replace(/^[a-z]{1}/, (char) =>
            char.toUpperCase(),
          )} Code`}
          visible={modalVisible}
          onOk={async () => {
            await addPriceModificationCode(
              type === "discount" ? newAmount * -1 : newAmount,
              newCode,
            )
            setNewAmount(1000)
            setNewCode(type === "discount" ? "NEWCODE" : "Team T Shirt")
            setModalVisible(false)
          }}
          onCancel={() => {
            setNewAmount(1000)
            setNewCode(type === "discount" ? "NEWCODE" : "Team T Shirt")
            setModalVisible(false)
          }}
        >
          <p style={{
            fontSize: "18px",
            color: Colors.veryDarkBlue,
          }}
          >
            Enter your <b>{type === "discount" ? "Discount Code" : "Additional Checkout Item"}</b> here.
            <br />
            You can update the {type === "discount" ? "Discount Code" : "Additional Checkout Item"} itself as well
            as the dollar amount.
          </p>
          <div
            style={{

              fontSize: "18px",
              color: Colors.veryDarkBlue,
              display: "grid",
              gridTemplateColumns: "1fr",
              columnGap: "25px",
              rowGap: "5px",
            }}
          >

            <b>{type === "discount" ? "Discount Code" : "Additional Checkout Item & Description"}</b>
            <br />
            <input
              style={{ borderRadius: "5px", padding: "5px", height: "30px", textTransform: type === "discount" ? "uppercase" : "none" }}
              type="text"
              maxLength={50}
              value={newCode}
              onChange={(e) => setNewCode(e.target.value)}
            />

            <br />

            <b>{type === "discount" ? "Discount Amount in ($)" : "Item Cost in ($)"}</b>
            <br />
            <input
              style={{ borderRadius: "5px", padding: "5px", height: "30px", }}
              type="number"
              min="0"
              max={type === "discount" ? "200" : "500"}
              maxLength={5}
              step="0.01"
              value={newAmount / 100}
              onChange={(e) => {
                const discountAmountCents = Math.abs(parseFloat(e.target.value) * 100)
                setNewAmount(discountAmountCents >= 0.00 ? discountAmountCents : NaN)
              }
              }
            />
          </div>
        </Modal>
      </Fragment>
    )
  }
