import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Badge, Button, Avatar, Tooltip, Statistic, DatePicker, Tag } from "antd"
import { Loading3QuartersOutlined, LoadingOutlined, MenuOutlined, MessageTwoTone, SmileOutlined, SmileTwoTone, SyncOutlined, UserOutlined } from "@ant-design/icons"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../appData/types"
import { screenSizes } from "../styles/GlobalStyles"
import swimHeader from "../images/swimmingly_final_header.png"
import { IUser, RoleHelper, useScreenSize } from "./utils"
import { useClubSeasonManagementContext } from "./ClubSeasonManagement/sharedState"
import { useHistory } from "react-router-dom"
import { countdownValueType } from "antd/lib/statistic/utils"
import moment, { Moment } from "moment-timezone"
import { DateFormats } from "./utils"
import { SeasonHelper } from "./ClubSeasonManagement/Season"
import _ from "lodash"

const StyledHeader = styled.header`
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  background-color: #f6f6f6;
  background-image: -webkit-linear-gradient(#f6f6f6 0%, white 45%);
  background-image: linear-gradient(#f6f6f6 0%, white 45%);
  border-bottom: 1px solid #f6f6f6;
  border-top: 1px solid #f6f6f6;
  z-index: 1000;


  @media only screen and (max-width: ${screenSizes.small}px) {
    background: none;
    border: none;
    height: auto;
    position: static;
    background-color: #f6f6f6;
    border-bottom: 1px solid #f6f6f6;
    border-top: 1px solid #f6f6f6;
  }
`
interface IAppHeaderProps {
  setEditUserVisible: ((value: boolean) => void) | null
  setClubCommunicationVisible: ((value: boolean) => void) | null
  setSidebarClosed: ((value: boolean) => void) | null
  sidebarClosed: boolean | null
}

export default function AppHeader({
  setEditUserVisible,
  setClubCommunicationVisible,
  setSidebarClosed,
  sidebarClosed,
}: IAppHeaderProps): JSX.Element {
  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)
  const roleHelper = new RoleHelper(user?.role)
  const impersonateClub = useStoreState(
    (state: StateMapper<AppDataStore>) => state.aliasedClub,
  )
  const { loadUpcomingSeason, upcomingSeason } = useClubSeasonManagementContext()
  const [upcomingSeasonIsActive, setUpcomingSeasonIsActive] = useState <Boolean | undefined>(false)



  useEffect(() => {
    if (roleHelper.isAnyAdmin()) {
      loadUpcomingSeason()
    }
  }, [loadUpcomingSeason, impersonateClub])

  useEffect(() => {
    if (roleHelper.isAnyAdmin()) {

      const seasonStartDate = moment(upcomingSeason?.startDate, DateFormats.default)
      const seasonEndDate = moment(upcomingSeason?.endDate, DateFormats.default)

      const today = moment()
      const localUpcomingSeasonIsActive = today.isBetween(seasonStartDate, seasonEndDate)
      //only set upcomingSeasonIsActive to true. If it's already true, don't set it to false.
      setUpcomingSeasonIsActive(upcomingSeasonIsActive || localUpcomingSeasonIsActive)
    }
  }, [upcomingSeason])

  useEffect(() => {
    if (user?.userId) {
      new SeasonHelper()
        .getClubSeasonsForGuardian(user?.userId)
        .then((clubSeasons) => {
          const today = moment()
          const upcomingClubSeasons = clubSeasons.filter((clubSeason) => today.isBetween(clubSeason.startDate, clubSeason.endDate))
          const localUpcomingSeasonIsActive = !_.isEmpty(upcomingClubSeasons)
        //only set upcomingSeasonIsActive to true. If it's already true, don't set it to false.
        setUpcomingSeasonIsActive(upcomingSeasonIsActive || localUpcomingSeasonIsActive)
        })
    }
  }, [user])


  const history = useHistory()

  const screenSize = useScreenSize()

  const isMobile: boolean = screenSize === "small"

  const makeAvatar = (theUser?: IUser | null): JSX.Element => {
    if (!theUser) {
      return (
        <div
          onClick={
            setEditUserVisible ? () => setEditUserVisible(true) : () => null
          }
        >
          <Avatar
            size="large"
            style={{
              backgroundColor: "#101242",
              cursor: "pointer"
            }}
            icon={<UserOutlined />} />
        </div>
      )
    }

    let initials: string = ""
    let nameSplit: Array<string> = theUser.name.split(" ")
    let first: string = nameSplit[0]
    let last: string = nameSplit[1]

    if (first) {
      initials += first[0]
    }
    if (last) {
      initials += last[0]
    }

    if (initials.length === 0 || !theUser.name || theUser.name.trim() === "") {
      return (
        <div
          onClick={
            setEditUserVisible ? () => setEditUserVisible(true) : () => null
          }
        >
          <Badge count={1}>
            <Avatar size="large" style={{ backgroundColor: "#101242", cursor: "pointer" }} icon={<UserOutlined />} />
          </Badge>
        </div>
      )
    }
    return (
      <div
        onClick={
          setEditUserVisible ? () => setEditUserVisible(true) : () => null
        }
      >
        <Avatar size="large" style={{ backgroundColor: "#101242", cursor: "pointer" }}>{initials}</Avatar>
      </div>
    )
  }


  //keith says: VERY janky work here to provide a temporary season open banner
  //this "vw" stuff is a way to use a unit of text size that relates to the size of the "viewport width."
  const emojiSize: string = "2.5vw"
  const bannerCaption = `${upcomingSeason?.seasonName || "Your season"} - Complete season registration setup to open season for ${impersonateClub?.nameLong || "your club"}.`
  const captionViewportWidth = 80.0
  const textSizeVWs = 2 * captionViewportWidth / bannerCaption.length
  const textSize = `${textSizeVWs}vw`


  return (
    <StyledHeader style={{ height: !Boolean(upcomingSeason) || Boolean(upcomingSeason?.seasonSetupConfirmedBy) ? "60px" : "120px" }}>
      <div style={{ display: Boolean(upcomingSeason) && !Boolean(upcomingSeason?.seasonSetupConfirmedBy) ? "block" : "none", backgroundColor: "#110F43", color: "white", height: "60px", fontSize: textSize, padding: "10px", textAlign: "center", verticalAlign: "middle" }}>

        <span style={{ fontSize: emojiSize }}>🏊‍♀️</span>&nbsp;&nbsp;
        {bannerCaption}
        &nbsp;&nbsp;<span style={{ fontSize: emojiSize }}>🏊‍♂️</span>
        &nbsp;&nbsp;
        <Button
          type="default"
          shape="round"
          size="middle"
          style={{ backgroundColor: "#3C8825", color: "white" }}
          onClick={() => history.push("/app/admin/club/seasons")}
        >
          Go!
        </Button>
      </div>

      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>

        <div style={{ marginLeft: "10px", marginTop: "5px" }}>
          <img src={swimHeader} height="35" alt="Swimmingly Header" />
        </div>

        <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
          {user && upcomingSeasonIsActive ? (
            <div
              onClick={
                setClubCommunicationVisible ? () => setClubCommunicationVisible(true) : () => null
              }

            >
              <MessageTwoTone twoToneColor="#3c8825"
                style={{
                  fontSize: "35px",
                  cursor: "pointer"
                }}
              />
            </div>
          ) : user && user?.role === 6 ? (
            <Tooltip
              title="This feature is for paying clubs only. Register with Swimmingly to easily message your whole club!"
              placement="left"
              color="#8898aa"
              mouseEnterDelay={0.5}
            >
              <MessageTwoTone twoToneColor="#8898aa"
                style={{
                  fontSize: "35px",
                  cursor: "pointer"
                }} />
            </Tooltip>
          ) : user && user?.role === 4 || user?.role === 3 || user?.role === 2 && (!upcomingSeasonIsActive) ? (
            <MessageTwoTone twoToneColor="#8898aa"
                style={{
                  fontSize: "35px",
                  cursor: "pointer"
                }} />
          ) : null
          }
          <div
            id="my-widget-container"
            style={{ justifySelf: "flex-end", marginLeft: "10px" }}
          />
          {makeAvatar(user)}
          <div
            id="my-widget-container"
            style={{ justifySelf: "flex-end", marginLeft: "15px" }}
          />
          {isMobile && (
            <MenuOutlined
              style={{ marginRight: "15px", fontSize: "24px", color: "#8898aa" }}
              onClick={
                setSidebarClosed !== null && sidebarClosed !== null
                  ? () => setSidebarClosed(!sidebarClosed)
                  : () => null
              }
            />
          )}
        </div>
      </div>
    </StyledHeader>
  )
}
