import React from "react"
import $ from "jquery"
import { convertCourse, divLikeLink, gen } from "./utils"
import EditEventTimeModal from "./EditEventTimeModal"
import EditEventSwimmerModal from "./EditEventSwimmerModal"
import { message, Button } from "antd"
import CenterSpin from "./CenterSpin"
import moment from "moment-timezone"
import { groupBy } from "lodash"

function prep10(numb) {
  if (numb < 10) return `0${Math.round(numb)}`
  return String(Math.round(numb))
}

function formatTime(secondsString) {
  let hundrethSeconds = Number(secondsString) * 100
  const seconds = Math.floor(hundrethSeconds / 100)
  const hundreth = hundrethSeconds % 100

  if (seconds < 60) return `${seconds}.${prep10(hundreth)}`

  const minutes = Math.floor(seconds / 60)
  const actualSeconds = seconds % 60
  return `${minutes}:${prep10(actualSeconds)}.${prep10(hundreth)}`
}

function breakTime(secondsString) {
  let hundrethSeconds = Number(secondsString) * 100
  const seconds = Math.floor(hundrethSeconds / 100)
  const hundreth = hundrethSeconds % 100

  if (seconds < 60)
    return { min: "00", sec: prep10(seconds), hundreth: prep10(hundreth) }

  const minutes = Math.floor(seconds / 60)
  const actualSeconds = seconds % 60
  return {
    min: prep10(minutes),
    sec: prep10(actualSeconds),
    hundreth: prep10(hundreth),
  }
}

function getName(result) {
  if (!result) {
    return "Unknown Swimmer"
  }
  if (result.swimmer_id === 0) {
    return `(${result.swimmer_number_sent}) Unknown Swimmer`
  }

  return `${result.first_name} ${
    result.middle_initials ? result.middle_initials : ""
  } ${result.last_name}`
}

function getEventName(result) {
  return `Event ${result.gender} - ${result.age_group} - ${result.distance} - ${result.stroke}`
}

class SingleEventResults extends React.Component {
  getEventName = (result) => {
    let title = `Event ${result.event_sort_order}  / ${result.gender} - ${result.age_group} - ${result.distance} - ${result.stroke}`

    return title
  }

  editEventSwimmer = (resultId) => {
    this.props.setEventSwimmerModalState(resultId, true)
  }

  editEventTime = (result) => {
    let time = result.official_time
    let { min, sec, hundreth } = breakTime(time)
    this.props.setEventTimeModalState({
      min: min,
      sec: sec,
      milli: hundreth,
      result_id: result.id,
      editTimeVisible: true,
    })
  }

  createDQRow = (result, names, theId) => {
    let fontColor = { color: "#ff3e3e" }
    return (
      <tr key={`this_dq_row_${theId}`} style={{ backgroundColor: "#ffd7d7" }}>
        <td>
          <p style={fontColor}>
            DQ({result && result.disqualification_reason})
          </p>
        </td>
        <td>
          <p style={fontColor}>{names}</p>
        </td>
        <td>
          <p style={fontColor}>DQ {result && result.clubName}</p>
        </td>

        <td>
          <p style={fontColor}>DQ</p>
        </td>
        <td>
          <p style={fontColor}>DQ</p>
        </td>
      </tr>
    )
  }
  createSingleRow(resultsArr) {
    let result = resultsArr[resultsArr.length - 1]
    let resultId = `sResultId_${result.id}`
    let names = resultsArr.map((el) => getName(el)).join(", ")
    let points = resultsArr[0].points

    if (result.disqualification_reason) {
      return this.createDQRow(result, names, resultId)
    }

    return (
      <tr key={resultId}>
        <td>{result.isExhibition ? "EX" : result.place_in_event}</td>
        <td>
          {this.props.isAuth ? (
            <span
              style={divLikeLink}
              onClick={() => {
                this.editEventSwimmer(resultId, true)
              }}
            >
              {names}
            </span>
          ) : (
            <span>{names}</span>
          )}
        </td>
        <td>{result && result.clubName}</td>
        <td>{Math.round(points * 100) / 100}</td>
        <td>
          {this.props.isAuth ? (
            <span
              className="swimmertime"
              id={resultId}
              onClick={() => this.editEventTime(result)}
              style={divLikeLink}
            >
              {formatTime(result.official_time)}
            </span>
          ) : (
            <span className="swimmertime" id={resultId}>
              {formatTime(result.official_time)}
            </span>
          )}
        </td>
      </tr>
    )
  }

  // Two results are in the same bucket if they were swum in the
  // same heat and lane of the same event
  bucketResults = (results) => {
    let curBucketStringId = ""
    let allBuckets = []
    let curBucket = []
    for (let i = 0; i < results.length; i++) {
      let result = results[i]
      let thisBucketStringId = `heatId${result.heat_identifier}|club${result.clubId}|heatNum${result.heat_number}|laneNum${result.lane_number}|eventNum${result.event_number}`
      if (thisBucketStringId === curBucketStringId) {
        curBucket.push(result)
      } else {
        if (curBucket.length > 0) {
          allBuckets.push(curBucket)
        }
        curBucket = []
        curBucketStringId = thisBucketStringId
        curBucket.push(result)
      }
    }
    allBuckets.push(curBucket)
    return allBuckets
  }

  renderTable = (results) => {
    let buckets = this.bucketResults(results)

    return (
      <div className="table-responsive" style={{ marginBottom: "20px" }}>
        <table id="checkerTable" className="background-red">
          <thead>
            <tr>
              <th className="headEventPlace">Place</th>
              <th className="headName">Name</th>
              <th className="headClub">Club</th>
              <th className="headPoints">Points</th>
              <th className="headTime">Time</th>
            </tr>
          </thead>
          <tbody>{buckets.map((el) => this.createSingleRow(el))}</tbody>
        </table>
      </div>
    )
  }
  render() {
    let results = this.props.results

    return (
      <div>
        <h3
          style={{
            color: "#25bfea",
            textAlign: "center",
          }}
        >
          {this.getEventName(results[0])}
        </h3>
        {this.renderTable(results)}
      </div>
    )
  }
}

function sortForEvents(a, b) {
  if (a.event_sort_order < b.event_sort_order) {
    return -1
  }
  if (a.event_sort_order > b.event_sort_order) {
    return 1
  }
  if (a.place_in_event && !b.place_in_event) {
    return -1
  }
  if (!a.place_in_event && b.place_in_event) {
    return 1
  }
  if (
    (!a.place_in_event && !b.place_in_event) ||
    parseInt(a.place_in_event) === parseInt(b.place_in_event)
  ) {
    // check if one was disqualified
    if (
      a.disqualification_reason !== null &&
      b.disqualification_reason !== null
    ) {
      // if not, then check their times
      let aTime = parseFloat(a.official_time)
      let bTime = parseFloat(b.official_time)
      if (aTime < bTime) {
        return -1
      }
      if (bTime < aTime) {
        return 1
      }
    } else if (a.disqualification_reason === null) {
      return 1
    } else if (b.disqualification_reason === null) {
      return -1
    }
    // if none were disqualified AND they have the same time, check if clubs are the same
    if (a.clubName < b.clubName) {
      return -1
    } else if (b.clubName < a.clubName) {
      return 1
    }
    // if all of that is inconclusive, check heat and lane
    if (
      `${a.heat_number}|${a.lane_number}` < `${b.heat_number}|${b.lane_number}`
    ) {
      return -1
    } else if (
      `${b.heat_number}|${b.lane_number}` < `${a.heat_number}|${a.lane_number}`
    ) {
      return 1
    }
    if (a.swimmer_order_in_lane < b.swimmer_order_in_lane) {
      return -1
    } else if (a.swimmer_order_in_lane > b.swimmer_order_in_lane) {
      return 1
    }
    if (a.last_name + " " + a.first_name > b.last_name + " " + b.first_name) {
      return 1
    } else {
      return -1
    }
  }
  if (a.place_in_event < b.place_in_event) {
    return -1
  }
  if (a.place_in_event > b.place_in_event) {
    return 1
  }
  if (a.heat_number < b.heat_number) {
    return -1
  }
  if (a.heat_number > b.heat_number) {
    return 1
  }
  if (a.lane_number < b.lane_number) {
    return -1
  }
  if (a.lane_number > b.lane_number) {
    return 1
  }
  if (a.swimmer_order_in_lane < b.swimmer_order_in_lane) {
    return -1
  }
  if (a.swimmer_order_in_lane > b.swimmer_order_in_lane) {
    return 1
  }
  return 0
}

export default class VirtualMeetResults extends React.Component {
  state = {
    type: "event",
    teams: [],
    allEvents: [],
    selectedEvent: null,

    min: "00",
    sec: "00",
    milli: "00",
    result_id: null,
    editSwimmerVisible: false,
    editTimeVisible: false,

    done: false,
    isExpanded: false,
  }

  getResultWithEvents = (meet_id, swim_event_id) => {
    $.ajax({
      url: gen("/api/getVirtualMeetResults2Post"),
      method: "POST",
      dataType: "json",
      data: {
        user: this.props.user,
        meet_id: meet_id,
      },
    }).done((data) => {
      let newData = data

      let firstResult = newData.resultsArr[0]
      this.setState({
        resultsArr: newData.resultsArr,
        meet: newData.meet,
        // teams,
        firstResult,
        done: true,
        allEvents: newData.allEvents || [],
      })
    })
  }

  renderHeader = (teams, firstResult) => {
    let sortedTeams = teams.sort((a, b) => (a.club_id < b.club_id ? -1 : 1))
    let name = sortedTeams.map((el) => el.name).join(" vs ")
    let course = convertCourse[firstResult.unit_measure]
    let newDateString = moment
      .utc(this.state.meet.meetdate)
      .format("MM-DD-YYYY")

    return (
      <h2
        className="swimmeet-title"
        style={{
          marginTop: 0,
          paddingTop: 0,
          borderTop: "0px",
          marginBottom: "10px",
        }}
      >
        {name} : {course} {newDateString}
      </h2>
    )
  }

  renderTeams = (teams) => {
    console.log("in here")
    let myTeams = this.state.isExpanded ? teams : teams.slice(0, 1)
    let linkStyle = {
      cursor: "pointer",
      color: "blue",
      textDecoration: "underline",
    }

    return (
      <div>
        {myTeams
          .sort((a, b) => (a.points < b.points ? 1 : -1))
          .map((el) => {
            return (
              <h4 key={el.club_id}>
                {el.name} : {Math.round(el.points * 1000) / 1000}
              </h4>
            )
          })}
        {!this.state.isExpanded && teams.length > 1 && (
          <div
            style={linkStyle}
            onClick={() => this.setState({ isExpanded: true })}
          >
            +{teams.length - 1} more
          </div>
        )}
        {this.state.isExpanded && (
          <div
            style={linkStyle}
            onClick={() => this.setState({ isExpanded: false })}
          >
            &#9650; close
          </div>
        )}
      </div>
    )
  }

  renderTeams2 = (resultsArr) => {
    let scoreMapping = {}
    for (let i = 0; i < resultsArr.length; i++) {
      let curResult = resultsArr[i]
      if (curResult.points) {
        if (scoreMapping[curResult.clubName]) {
          scoreMapping[curResult.clubName] += curResult.points
        } else {
          scoreMapping[curResult.clubName] = curResult.points
        }
      } else if (!scoreMapping[curResult.clubName]) {
        scoreMapping[curResult.clubName] = 0
      }
    }
    let sortedTeams = Object.entries(scoreMapping)
      .map((el) => [el[0], el[1]])
      .sort((a, b) => (a[1] < b[1] ? 1 : -1))

    let myTeams = this.state.isExpanded ? sortedTeams : sortedTeams.slice(0, 10)
    let linkStyle = {
      cursor: "pointer",
      color: "blue",
      textDecoration: "underline",
    }
    return (
      <div>
        {myTeams.map((el) => {
          return (
            <h4 key={el[0]}>
              {el[0]} : {el[1]}
            </h4>
          )
        })}
        {!this.state.isExpanded && sortedTeams.length > 10 && (
          <div
            style={linkStyle}
            onClick={() => this.setState({ isExpanded: true })}
          >
            +{sortedTeams.length - 10} more
          </div>
        )}
        {this.state.isExpanded && (
          <div
            style={linkStyle}
            onClick={() => this.setState({ isExpanded: false })}
          >
            &#9650; close
          </div>
        )}
      </div>
    )
  }

  renderHeaderAndTeamScores = (resultsArr, firstResult) => {
    return (
      <div>
        {/*this.renderHeader(teams, firstResult)*/}
        {this.renderTeams2(resultsArr)}
      </div>
    )
  }
  breakIntoEvents(resultsArr) {
    if (resultsArr.length === 0) {
      return []
    }
    let events = groupBy(resultsArr, (el) => getEventName(el))
    return Object.values(events)

    // let cutOffs = []
    // for (let i = 0; i < resultsArr.length - 1; i++) {
    //   if (resultsArr[i].swim_event_id !== resultsArr[i + 1].swim_event_id) {
    //     cutOffs.push(i + 1)
    //   }
    // }
    // let events = []
    // cutOffs.unshift(0)
    // for (let i = 0; i < cutOffs.length - 1; i++) {
    //   let allEvents = resultsArr.slice(cutOffs[i], cutOffs[i + 1])
    //   events.push(allEvents)
    // }
    // events.push(resultsArr.slice(cutOffs[cutOffs.length - 1]))
    // return events
  }
  makeHeatKey = (result) => {
    return `${result.swim_event_id}|${result.heat_number}`
  }
  breakIntoHeats(resultsArr) {
    if (resultsArr.length === 0) {
      return []
    }

    let cutOffs = []
    for (let i = 0; i < resultsArr.length - 1; i++) {
      if (
        this.makeHeatKey(resultsArr[i]) !== this.makeHeatKey(resultsArr[i + 1])
      ) {
        cutOffs.push(i + 1)
      }
    }
    let events = []
    cutOffs.unshift(0)
    for (let i = 0; i < cutOffs.length - 1; i++) {
      let allEvents = resultsArr.slice(cutOffs[i], cutOffs[i + 1])
      events.push(allEvents)
    }
    events.push(resultsArr.slice(cutOffs[cutOffs.length - 1]))
    return events
  }
  renderInternalTable = () => {
    if (!this.state.firstResult) {
      return <h1>No results for this meet </h1>
    }
    let header = this.renderHeaderAndTeamScores(this.state.resultsArr)
    let isAuthCheck = this.props.user.role !== 5

    let sortedResults = this.state.resultsArr.sort(sortForEvents)
    let events = this.breakIntoEvents(sortedResults)
    return (
      <div>
        {header}
        <div style={{ fontSize: "16px", color: "black" }}>
          Virtual Course :{" "}
          {this.state.meet ? convertCourse[this.state.meet.course] : ""}
        </div>
        {events.map((el) => {
          return (
            <SingleEventResults
              key={el && el[0] && el[0].id}
              results={el}
              isAuth={isAuthCheck}
              setEventTimeModalState={this.setEventTimeModalState}
              setEventSwimmerModalState={this.setEventSwimmerModalState}
            />
          )
        })}
      </div>
    )
  }

  setEventSwimmerModalState = (resultId, editSwimmerVisible) => {
    let editTimeVisible = this.state.editTimeVisible
    if (editSwimmerVisible === true) {
      editTimeVisible = false
    }
    this.setState({ result_id: resultId, editSwimmerVisible, editTimeVisible })
  }

  setEventTimeModalState = ({
    min,
    sec,
    milli,
    result_id,
    editTimeVisible,
  }) => {
    let editSwimmerVisible = this.state.editSwimmerVisible
    if (editTimeVisible === true) {
      editSwimmerVisible = false
    }
    this.setState({
      min,
      sec,
      milli,
      result_id,
      editTimeVisible,
      editSwimmerVisible,
    })
  }

  renderSelectOption = () => {
    let isMobile = $(window).width() < 768
    if (!isMobile || this.state.allEvents.length === 0) {
      return null
    }

    return (
      <div>
        <select
          id="events"
          value={this.state.selectedEvent}
          onChange={(e) => this.setState({ selectedEvent: e.target.value })}
        >
          {this.state.allEvents.map((el) => (
            <option value={el.id}>{el.eventName}</option>
          ))}
        </select>
        <Button onClick={this.onClickResubmit}>Submit</Button>
      </div>
    )
  }

  onClickResubmit = () => {
    let meetId = this.props.meetId
    let swim_event_id = this.state.selectedEvent
    this.getResultWithEvents(meetId, swim_event_id)
  }

  componentDidMount() {
    let meetId = this.props.meetId
    this.getResultWithEvents(meetId)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.editSwimmerVisible === this.state.editSwimmerVisible) {
      return
    }
    let meetId = this.props.meetId
    this.getResultWithEvents(meetId)
  }

  putTimeHere = (result_id, seconds) => {
    if (!this.state.resultsArr) {
      return
    }
    let resultsArr = this.state.resultsArr
    let curVal = resultsArr.find((el) => el.id === result_id)
    if (!curVal) {
      message.error("Can't update")
    }
    curVal.official_time = seconds
    this.setState({ resultsArr })
  }

  render() {
    if (!this.state.done) {
      return (
        <section className="panel">
          <header className="panel-heading">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: 1 }}>
                <h2 className="panel-title heading-div">Results</h2>
              </div>
            </div>
          </header>
          <div className="ourpanel-body">
            <CenterSpin />
          </div>
        </section>
      )
    }

    return (
      <section className="panel">
        <header className="panel-heading">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <h2 className="panel-title heading-div">Results</h2>
            </div>
          </div>
        </header>
        <div className="ourpanel-body">
          <div className="form-group ">
            <div className="row-fluid">{this.renderSelectOption()}</div>
            <div className="row-fluid">{this.renderInternalTable()}</div>
          </div>
        </div>
        <EditEventTimeModal
          min={this.state.min}
          sec={this.state.sec}
          milli={this.state.milli}
          result_id={this.state.result_id}
          putTimeHere={this.putTimeHere}
          visible={this.state.editTimeVisible}
          setVisible={(val) => {
            let editSwimmerVisible = this.state.editSwimmerVisible
            if (val === true) {
              editSwimmerVisible = false
            }
            this.setState({ editTimeVisible: val, editSwimmerVisible })
          }}
        />
        <EditEventSwimmerModal
          visible={this.state.editSwimmerVisible}
          resultId={this.state.result_id}
          setVisible={(val) => {
            let editTimeVisible = this.state.editTimeVisible
            if (val === true) {
              editTimeVisible = false
            }
            this.setState({ editSwimmerVisible: val, editTimeVisible })
          }}
        />
      </section>
    )
  }
}
