import { useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import nProgress from "nprogress"
import { message } from "antd"
import { AiOutlineCloseCircle } from "react-icons/ai"
import { gen, swimminglyApi, validateEmail } from "../utils"
import { screenSizes } from "../../styles/GlobalStyles"
import Modal from "antd/lib/modal/Modal"
import { seasonPrepModalListener } from "../../App"

const AddPaymentModal = styled(Modal)`
  font-family: sans-serif;
  margin: auto;
  margin-top: 50px;
  box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.04);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 1rem;
  display: grid;
  grid-gap: 1rem;

  .ant-modal-content {
    width: 750px;
    max-width: 95%;
  }

  .memorandum {
    font-size: 1.5rem;
    border-bottom: 1px solid var(--darkgrey);
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    height: 35px;
  }

  .memorandum p {
    align-self: center;
    margin-bottom: 0;
  }

  p.address-disclaimer {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 1.1rem;
  }

  fieldset {
    box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.02);
    border: 1px solid rgba(0, 0, 0, 0.04);
    border-radius: 5px;
    margin-bottom: 25px;
  }

  input {
    padding-left: 10px;
    border: none;
    color: gray;
  }

  select,
  option {
    height: 30px;
    padding: 0;
  }

  button {
    font-size: 1.5rem;
    border: none;
    border-radius: 5px;
  }

  .error-text {
    font-size: 1.25rem;
    background-color: #ffb5b0;
    color: #8c0c00;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
  }

  .error-text.inputs {
    margin-top: 0;
    margin-bottom: 2px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .not-card-number {
    display: grid;
    grid-auto-columns: 50px 1fr;
  }

  .not-card-number .row {
    margin: 0;
    color: gray;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    display: grid;
    grid-column: span 2;
    @supports not (grid-template-columns: subgrid) {
      --columns: 100px 1fr;
    }
    grid-template-columns: var(--columns, subgrid);
    align-items: center;
  }

  .not-card-number .row label {
    margin: 0;
  }

  .not-card-number .row:before {
    content: none;
  }
  .not-card-number .row:after {
    content: none;
  }

  .not-card-number .row:first-child {
    border-top: none;
  }

  .not-card-number .row.split {
    grid-template-columns: 1fr 1fr;
  }
  .not-card-number .half-row {
    display: grid;
    grid-template-columns: 100px 1fr;
  }

  @media (max-width: ${screenSizes.medium}px) {
    .ant-modal-content {
      width: 97%;
    }
  }

  @media (max-width: ${screenSizes.small}px) {
    .not-card-number .row.split {
      grid-template-columns: 1fr;
    }
    .not-card-number .half-row {
      grid-template-columns: 80px 1fr;
      border-top: 1px solid var(--lightgrey);
    }
    .not-card-number .half-row:first-child {
      border-top: none;
    }
  }

  input:focus {
    outline: none;
  }

  input::placeholder {
    color: lightgray;
  }
  input:-ms-input-placeholder {
    color: lightgray;
  }
  input::-ms-input-placeholder {
    color: lightgray;
  }
`

const StyledCardElement = styled(CardElement)`
  font-size: 2rem;
  padding-top: 12px;
  height: 30px !important;
  padding-left: 10px;
  display: grid;
  align-self: center;
  align-items: center;
  align-content: center;

  iframe {
    height: 30px !important;
  }

  .CardField {
    height: 30px !important;
  }

  .__PrivateStripeElement {
    height: 30px !important;
  }
  .__PrivateStripeElement-input {
    height: 30px !important;
  }
`

const stripeLib = loadStripe(process.env.REACT_APP_STRIPE_KEY || "")

interface IBillingAddress {
  postal_code?: string
  country: string
  city?: string
  state?: string
  line1?: string
  line2?: string
}

interface IInputErrors {
  name?: string
  phone?: string
  email?: string
  address?: string
  city?: string
  state?: string
  country?: string
}

const stripeSupportedCountryCodes = [
  "US",
  "AT",
  "AU",
  "BE",
  "BG",
  "CA",
  "CH",
  "CY",
  "CZ",
  "DE",
  "DK",
  "EE",
  "ES",
  "FI",
  "FR",
  "GB",
  "GR",
  "HK",
  "IE",
  "IT",
  "LT",
  "LU",
  "LV",
  "MT",
  "NL",
  "NO",
  "NZ",
  "PL",
  "PT",
  "RO",
  "SE",
  "SG",
  "SI",
  "SK",
]

const usStateCodes = [
  "AA",
  "AE",
  "AK",
  "AL",
  "AP",
  "AR",
  "AS",
  "AZ",
  "CA",
  "CM",
  "CO",
  "CT",
  "CZ",
  "DC",
  "DE",
  "FL",
  "FM",
  "GA",
  "GU",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MH",
  "MI",
  "MN",
  "MO",
  "MP",
  "MS",
  "MT",
  "NB",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "PI",
  "PR",
  "PW",
  "RI",
  "SC",
  "SD",
  "TN",
  "TT",
  "TX",
  "UM",
  "UT",
  "VA",
  "VI",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
]
function AddPaymentMethodFormWithoutElements({
  visible,
  setVisible,
  clubId,
  refreshData,
}: {
  visible: boolean
  setVisible: (newVisible: boolean) => void
  clubId: number
  refreshData: () => void
}) {
  const [paymentMethodError, setPaymentMethodError] =
    useState<{ message?: string; [key: string]: any } | undefined>()
  const [loading, setLoading] = useState(false)
  const [paymentName, setPaymentName] = useState("")
  const [paymentAddressLine1, setPaymentAddressLine1] = useState("")
  const [paymentAddressLine2, setPaymentAddressLine2] = useState("")
  const [paymentCountry, setPaymentCountry] = useState("")
  const [paymentCity, setPaymentCity] = useState("")
  const [paymentState, setPaymentState] = useState("")
  const [paymentEmail, setPaymentEmail] = useState("")
  const [paymentPhone, setPaymentPhone] = useState("")
  const [paymentInputErrors, setPaymentInputErrors] = useState<IInputErrors>({})

  const stripe = useStripe()
  const elements = useElements()

  const resetDefaultValues = useCallback(() => {
    setPaymentMethodError(undefined)
    setPaymentInputErrors({})
    setPaymentName("")
    setPaymentAddressLine1("")
    setPaymentAddressLine2("")
    setPaymentCountry("")
    setPaymentCity("")
    setPaymentState("")
    setPaymentEmail("")
    setPaymentPhone("")
  }, [
    setPaymentMethodError,
    setPaymentInputErrors,
    setPaymentName,
    setPaymentAddressLine1,
    setPaymentAddressLine2,
    setPaymentCountry,
    setPaymentCity,
    setPaymentState,
    setPaymentEmail,
    setPaymentPhone,
  ])

  useEffect(() => {
    resetDefaultValues()
  }, [visible, setVisible, resetDefaultValues])

  function displayPhone(strNum: string): string {
    if (strNum.length > 0 && strNum[0] === "1")
      return "+1 " + displayPhone(strNum.slice(1))

    if (strNum.length === 0) return ""
    if (strNum.length <= 3) return "(" + strNum + ")"
    if (strNum.length <= 6)
      return "(" + strNum.slice(0, 3) + ") " + strNum.slice(3)
    return (
      "(" +
      strNum.slice(0, 3) +
      ") " +
      strNum.slice(3, 6) +
      "-" +
      strNum.slice(6)
    )
  }

  const onPhoneNumberKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key.toLowerCase() === "backspace") {
      if (paymentPhone.length > 0) {
        e.preventDefault()
        setPaymentPhone(paymentPhone.slice(0, paymentPhone.length - 1))
      }
    } else if (
      ["return", "enter", "delete", "tab", "meta", "shift"].includes(
        e.key.toLowerCase(),
      ) ||
      /^[0-9]$/.test(e.key)
    ) {
      // nothing to do here - let it update the input
    } else {
      e.preventDefault()
    }
  }

  const updatePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    setPaymentPhone(newValue.replace(/[^0-9]/g, ""))
  }

  const getInputErrors = () => {
    const theErrors: IInputErrors = {}
    // name, email, phone verification
    if (!paymentName || paymentName.trim() === "") {
      theErrors.name = "Must provide name"
    }
    if (!paymentEmail || !validateEmail(paymentEmail.trim())) {
      theErrors.email = "Please provide a valid email address"
    }
    if (!paymentPhone || paymentPhone.length < 10 || paymentPhone.length > 18) {
      theErrors.phone = "Please provide a valid phone number"
    }
    // address verification
    if (!paymentCountry || paymentCountry.trim().length < 2) {
      theErrors.country = "Must include a valid country code"
    } else if (!stripeSupportedCountryCodes.includes(paymentCountry)) {
      theErrors.country = "Country code not recognized"
    }
    if (
      stripeSupportedCountryCodes.includes(paymentCountry) &&
      paymentCountry !== "US"
    ) {
      return theErrors
    }
    if (!paymentAddressLine1 || paymentAddressLine1.trim() === "") {
      theErrors.address = "Must include address"
    }
    if (!paymentCity || paymentCity.trim().length < 2) {
      theErrors.city = "Invalid city"
    }
    if (!paymentState || paymentState.trim().length < 2) {
      theErrors.state = "Invalid state, use 2 character abbreviation"
    } else if (
      paymentCountry.trim() === "US" &&
      !usStateCodes.includes(paymentState.trim())
    ) {
      theErrors.state = "State code not recognized, use 2 character abbreviation"
    }
    return theErrors
  }

  const handleAddPaymentMethod = async (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement>,
  ) => {
    setPaymentMethodError(undefined)
    setPaymentInputErrors({})
    if (!stripe || !elements) {
      console.log("Uh oh, no stripe or no elements")
      setPaymentMethodError({
        message: "Problem with Stripe - Contact Swimmingly",
      })
      return
    }
    const theCard = elements.getElement(CardElement)
    if (!theCard) {
      console.log("No Card")
      setPaymentMethodError({ message: "No Card" })
      return
    }
    // referenced Wes Bos advanced react course
    // 1. stop form from submitting and turn the loader on
    e.preventDefault()
    setLoading(true)
    // 2. start page transition
    nProgress.start()
    // 3. create payment method via stripe and get token
    const billingAddress: IBillingAddress = {
      country: paymentCountry.trim(),
    }
    if (paymentCity.trim() !== "") billingAddress.city = paymentCity.trim()
    if (paymentState.trim() !== "") billingAddress.state = paymentState.trim()
    if (paymentAddressLine1.trim() !== "")
      billingAddress.line1 = paymentAddressLine1.trim()
    if (paymentAddressLine2.trim() !== "")
      billingAddress.line2 = paymentAddressLine2.trim()

    const inputErrors = getInputErrors()
    if (inputErrors && Object.keys(inputErrors).length > 0) {
      // terminate early
      setPaymentInputErrors(inputErrors)
      setLoading(false)
      nProgress.done()
      return
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: theCard,
      billing_details: {
        name: paymentName,
        phone: paymentPhone,
        address: billingAddress,
        email: paymentEmail,
      },
    })
    if (error) {
      console.log("error", error)
    }
    // 4. handle any errors from stripe
    if (error?.code) {
      setPaymentMethodError(error)
      nProgress.done()
      setLoading(false)
      return
    }
    if (!paymentMethod?.id) {
      setPaymentMethodError({ message: "did not receive token" })
      nProgress.done()
      return
    }
    // 5. send the token to the server to save it
    const addCardResult: "success" | "fail" = await swimminglyApi
      .post(gen(`/api/addStripeCardToClub/${clubId}`))
      .body({
        stripePaymentMethodId: paymentMethod.id,
      })
      .then((data) => {
        if (data.status !== "success") {
          if (data.message) {
            setPaymentMethodError({ message: data.message })
          }
          message.error("Problem saving this payment method on file", 5)
          return "fail"
        }
        return "success"
      })
      .catch((err) => {
        console.log(err.message)
        message.error("Problem saving this payment method on file", 5)
        return "fail"
      })
    // 6. update the page to reflect the saved payment method
    if (addCardResult === "success") {
      theCard.clear()
      resetDefaultValues()
      nProgress.done()
      setLoading(false)
      setVisible(false)
      refreshData()
      return
    }
    // 7. turn the loader off
    seasonPrepModalListener.emit("updateSeasonPrepModal")
    nProgress.done()
    setLoading(false)
  }

  return (
    <AddPaymentModal
      confirmLoading={loading}
      title="Add Your Club's Card"
      visible={visible}
      onCancel={() => {
        setVisible(false)
        refreshData()
      }}
      onOk={handleAddPaymentMethod}
    >
      <fieldset className="not-card-number">
        <div className="row">
          <label htmlFor="name">Name on Card</label>
          <input
            id="name"
            placeholder="John Doe"
            value={paymentName}
            onChange={(e) => setPaymentName(e.target.value)}
          />
        </div>
        <div className="row">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            placeholder="johndoe@email.com"
            value={paymentEmail}
            onChange={(e) => setPaymentEmail(e.target.value)}
          />
        </div>
        <div className="row">
          <label htmlFor="phone">Phone</label>
          <input
            id="phone"
            placeholder="(555) 555-5555"
            value={displayPhone(paymentPhone)}
            onKeyDown={onPhoneNumberKeyDown}
            onChange={updatePhoneNumber}
          />
        </div>
      </fieldset>
      <div>
        {paymentInputErrors.name && (
          <p className="error-text inputs">
            <span>{paymentInputErrors.name}</span>
            <span
              onClick={() =>
                setPaymentInputErrors({
                  ...paymentInputErrors,
                  name: undefined,
                })
              }
              style={{ cursor: "pointer" }}
            >
              x
            </span>
          </p>
        )}
        {paymentInputErrors.email && (
          <p className="error-text inputs">
            <span>{paymentInputErrors.email}</span>
            <span
              onClick={() =>
                setPaymentInputErrors({
                  ...paymentInputErrors,
                  email: undefined,
                })
              }
              style={{ cursor: "pointer" }}
            >
              x
            </span>
          </p>
        )}
        {paymentInputErrors.phone && (
          <p className="error-text inputs">
            <span>{paymentInputErrors.phone}</span>
            <span
              onClick={() =>
                setPaymentInputErrors({
                  ...paymentInputErrors,
                  phone: undefined,
                })
              }
              style={{ cursor: "pointer" }}
            >
              x
            </span>
          </p>
        )}
      </div>
      <fieldset className="not-card-number">
        <div className="row">
          <label htmlFor="address-line-1">Address Line 1</label>
          <input
            id="address-line-1"
            placeholder="123 Somewhere Rd"
            value={paymentAddressLine1}
            onChange={(e) => setPaymentAddressLine1(e.target.value)}
          />
        </div>
        <div className="row">
          <label htmlFor="address-line-2">Address Line 2</label>
          <input
            id="address-line-2"
            placeholder="Unit 1"
            value={paymentAddressLine2}
            onChange={(e) => setPaymentAddressLine2(e.target.value)}
          />
        </div>
        <div className="row split">
          <div className="half-row">
            <label htmlFor="city">City</label>
            <input
              id="city"
              placeholder="Somewhereville"
              value={paymentCity}
              onChange={(e) => setPaymentCity(e.target.value)}
            />
          </div>
          <div className="half-row">
            <label htmlFor="state">State</label>
            <input
              id="state"
              placeholder="NY"
              maxLength={2}
              value={paymentState}
              onChange={(e) => setPaymentState(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <label htmlFor="state">Country</label>
          {/* <input
            id="country"
            placeholder="US"
            value={paymentCountry}
            onChange={(e) => setPaymentCountry(e.target.value)}
          /> */}
          <select
            value={paymentCountry}
            onChange={(e) => setPaymentCountry(e.target.value)}
          >
            <option value="" disabled hidden>
              Choose Country Code...
            </option>
            {stripeSupportedCountryCodes.map((countryCode, idx) => {
              return (
                <option key={`country_code_${idx}`} value={countryCode}>
                  {countryCode}
                </option>
              )
            })}
          </select>
        </div>
      </fieldset>
      <div>
        {paymentInputErrors.address && (
          <p className="error-text inputs">
            <span>{paymentInputErrors.address}</span>
            <span
              onClick={() =>
                setPaymentInputErrors({
                  ...paymentInputErrors,
                  address: undefined,
                })
              }
              style={{ cursor: "pointer" }}
            >
              x
            </span>
          </p>
        )}
        {paymentInputErrors.country && (
          <p className="error-text inputs">
            <span>{paymentInputErrors.country}</span>
            <span
              onClick={() =>
                setPaymentInputErrors({
                  ...paymentInputErrors,
                  country: undefined,
                })
              }
              style={{ cursor: "pointer" }}
            >
              x
            </span>
          </p>
        )}
        {paymentInputErrors.city && (
          <p className="error-text inputs">
            <span>{paymentInputErrors.city}</span>
            <span
              onClick={() =>
                setPaymentInputErrors({
                  ...paymentInputErrors,
                  city: undefined,
                })
              }
              style={{ cursor: "pointer" }}
            >
              x
            </span>
          </p>
        )}
        {paymentInputErrors.state && (
          <p className="error-text inputs">
            <span>{paymentInputErrors.state}</span>
            <span
              onClick={() =>
                setPaymentInputErrors({
                  ...paymentInputErrors,
                  state: undefined,
                })
              }
              style={{ cursor: "pointer" }}
            >
              x
            </span>
          </p>
        )}
      </div>
      <fieldset>
        {paymentMethodError && (
          <p className="error-text">
            <span>{paymentMethodError.message}</span>
            <span>
              <AiOutlineCloseCircle
                style={{ cursor: "pointer" }}
                onClick={() => setPaymentMethodError(undefined)}
              />
            </span>
          </p>
        )}
        <StyledCardElement />
      </fieldset>
    </AddPaymentModal>
  )
}

export default function AddCardForClub({
  visible,
  setVisible,
  clubId,
  refreshData,
}: {
  visible: boolean
  setVisible: (newVisible: boolean) => void
  clubId: number
  refreshData: () => void
}) {
  return (
    <Elements stripe={stripeLib}>
      <AddPaymentMethodFormWithoutElements
        visible={visible}
        setVisible={setVisible}
        clubId={clubId}
        refreshData={refreshData}
      />
    </Elements>
  )
}
