import { Affix, Button, Input,Select, Switch, message, Tooltip } from "antd"
import { CloseOutlined, SendOutlined } from '@ant-design/icons';
import { useClubSeasonManagementContext } from "./ClubSeasonManagement/sharedState";
import { useEffect, useState } from "react";
import { Colors } from "./ClubSeasonManagement/styles"
import { gen, swimminglyApi } from "./utils";
import { StateMapper, useStoreState } from 'easy-peasy'
import { AppDataStore } from "../appData/types";
import { ISeasonDetails } from "../App"


interface INewAnnouncementWindowProps{
    messageWindowVisible: boolean
    setMessageWindowVisible: (value: boolean) => void
}

export default function NewAnnouncementWindow({ 
    messageWindowVisible,
    setMessageWindowVisible
} : INewAnnouncementWindowProps){
    const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)
    const [sendButtonActive,setSendButtonActive] = useState(false)
    const [announcement, setAnnouncement] = useState("")
    const [allowResponse, setAllowResponse] = useState(false)
    const [selectedSeason, setSelectedSeason] = useState<ISeasonDetails | undefined>()
    const { TextArea } = Input

    
    const { loadUpcomingSeason, upcomingSeason, allUpcomingSeasons } = useClubSeasonManagementContext()
    
    useEffect(() => {
        loadUpcomingSeason()
    }, [loadUpcomingSeason])
    
     
    useEffect(() => {
        setSelectedSeason(upcomingSeason)
    }, [upcomingSeason, allUpcomingSeasons])
    
    const handleChange = (option: any) => {
        const matchingSeason = allUpcomingSeasons.find(season => season.seasonId === option.value)
        setSelectedSeason(matchingSeason)
    };


    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setAnnouncement(e.target.value)
        setSendButtonActive(true)
    };
    
    let options = []

    for (let i = 0; i < allUpcomingSeasons.length; i++) {
        options.push({
            label: allUpcomingSeasons[i].seasonName,
            value: allUpcomingSeasons[i].seasonId,
        });
    }

    const newAnnouncementDefaultValue = selectedSeason?.seasonId ?
        { value: selectedSeason?.seasonId, label: selectedSeason?.seasonName }
        : undefined
    
    return (
        <Affix offsetBottom={0} style={{ position: 'fixed', right: 80, bottom: 10, zIndex: 1000, visibility: messageWindowVisible? "inherit": "hidden" }}>
            <div style={{ zIndex: 1000, width: 300, height: 420 }}>
                <div style={{ backgroundColor: "#c9d9e4", width: 350, height: 420, padding: 20, borderRadius: 20 }}>
                    <div style={{ borderBottom: "3px solid black"}}>
                        <div style={{ textAlign: "left", display: "inline-block", marginRight: "20px",  marginLeft: 5 }}>
                            <h4>Create Announcement</h4>
                            <h5>{selectedSeason?.clubName}</h5>
                        </div>
                        <div style={{ color: "black", float: "right", marginLeft: 80 }}>
                            <CloseOutlined 
                                onClick={() => {
                                 setMessageWindowVisible(false)
                                }} 
                            />
                        </div>
                    </div>
                    <Select 
                        style={{ width: '100%' }}
                        defaultValue={newAnnouncementDefaultValue}
                        onSelect={handleChange}
                        options={options}
                        labelInValue={true}
                        value={options.find((option) => option.value === selectedSeason?.seasonId)}
                    />
                    <br />
                    <br />
                    <TextArea 
                        rows={8} 
                        showCount 
                        maxLength={1000} 
                        onChange={onChange} 
                        placeholder="Your club's admins & guardians will see this in their communication drawer here
                                    and receive an email with this announcement." 
                        value={announcement}
                    />
                    <br />
                    <Tooltip
                        title="Receive email replies directly to your inbox, or route replies to a no-reply inbox. "
                        >
                    <Switch 
                        defaultChecked={false} 
                        onChange={(checked) => setAllowResponse(checked)} 
                        style={{ marginRight: "5px" }} 
                        checked={allowResponse}
                    />
                    Allow Replies
                    </Tooltip>
                    <br />
                    <br />
                    <Tooltip
                        title="Post this announcement in your club's communication drawer for all to see, and send email notification.">
                    <Button 
                        shape="round"
                        disabled={!sendButtonActive} 
                        onClick={() => {
                        // make call to backend sending the message, clubId, seasonId, clubSeasonName, userId
                            swimminglyApi
                                .post(gen(`/api/messaging/createAnnouncement`))
                                .body({
                                    clubId: selectedSeason?.clubId,
                                    seasonId: selectedSeason?.seasonId,
                                    clubName: selectedSeason?.clubName,
                                    userId: user?.userId,
                                    userName: user?.name,
                                    userEmail: user?.email,
                                    announcement: announcement,
                                    allowResponses: allowResponse
                                })
                                .then((data) => {
                                    if (data.status === "success") {
                                        message.success("Your announcement has been posted to the Clubhouse communication drawer for all families & admins to view. They have also received an email notification with your announcement.")
                                        setSelectedSeason(undefined)
                                        setAnnouncement("")
                                        setAllowResponse(false)
                                    } else if (data.status === "error") {
                                        message.error("uh oh, something went wrong...")
                                    }
                                    return
                                })
                                .catch(() => {
                                    message.error("uh oh, something went wrong...")
                                    return
                                })
                                .finally(() => {
                                    setAllowResponse(false)
                                })
                                
                        setMessageWindowVisible(false)
                        }}
                        style={{ backgroundColor: sendButtonActive ? Colors.veryDarkBlue : "white", color: sendButtonActive ? "white" : "black"}}
                    >
                        Post Announcement <SendOutlined />
                    </Button>
                    </Tooltip>
                    <Button 
                        shape="round"
                        style={{ marginLeft: "15px" }}
                        onClick={() => {
                            setMessageWindowVisible(false)
                        }}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </Affix>
    )
}