import { useCallback, useEffect, useState } from "react";
import moment, { Moment } from "moment-timezone";
import styled from "styled-components";
import { FaTrash } from "react-icons/fa";
import {
  DatePicker,
  Switch,
  Radio,
  message,
  Form,
  Row,
  Col,
  Input,
  Select,
  Tooltip,
  Button,
  Typography,
  Checkbox,
  Divider,
} from "antd";
import { cloneDeep } from "lodash";
import { useStoreState, StateMapper } from "easy-peasy";
import { AppDataStore } from "../appData/types";
import ContentPanel from "./ContentPanel";
import { gen, IClub, ILeague, swimminglyApi } from "./utils";
import { QuestionCircleOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;
const { Title } = Typography;
const { Option } = Select;

interface IClubWithHistoricalLeagues extends IClub {
  historicalLeagueName: string;
  leagueIds: number[];
}

interface ISelectedClub extends IClubWithHistoricalLeagues {
  isSwimmingly: 0 | 1;
  corporatePaymentId: number;
  parentPays: number;
  clubPays: number;
  leaguePays: number;
  seedPremium: boolean;
}

interface ILumpSumPayment {
  corporatePaymentId: number;
  referenceName: string;
  paymentType: "check" | "card" | "ach" | "cash" | "other" | "venmo";
  paymentAmount: number;
  paymentDate: Moment | string;
  payingEntity: string;
  stripePaymentIntentId?: string | null;
  intendedPricePerSwimmer: number | null;
  actualNumberOfSwimmers: number | null;
  expectedNumberOfSwimmers: number | null;
  notes: string;
  metadata?: { [key: string]: any } | null;
}

const StyledSeasonForm = styled.div`
  form {
    background-color: var(--white);
    box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(0, 0, 0, 0.06);
    padding: 10px;
  }

  form fieldset {
    background-color: var(--white);
    box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    margin-bottom: 10px;
  }
  form .grid-input {
    padding-left: 5px;
    display: grid;
    grid-template-columns: 100px 1fr;
    align-items: center;
    column-gap: 5px;
  }
  div.grid-input {
    padding-top: 10px;
  }

  form fieldset input {
    height: 30px;
    border: 1px solid var(--lightgrey);
  }

  form fieldset input:focus {
    outline: none;
  }

  form button.submit-button {
    font-size: 1.5rem;
    background-color: dodgerblue;
    color: var(--snow);
    border: none;
    border-radius: 5px;
    padding: 5px;
    padding-left: 30px;
    padding-right: 30px;
  }

  form button.submit-button:focus {
    outline: none;
  }

  form button.submit-button:disabled {
    background-color: whitesmoke;
    border-color: #a3a3a3;
    color: #a3a3a3;
  }



  .selected-club input {
    width: 70px;
  }

  .selected-club input[type="checkbox"] {
    width: 20px;
    margin: auto;
  }

  .selected-club .centered {
    text-align: center;
  }

  .selected-club .ant-row {
    align-items: center;
  }
`;

export default function CreateSeason() {
  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user);

  const [allLeagues, setAllLeagues] = useState<ILeague[]>([]);
  const [selectedLeague, setSelectedLeague] = useState<ILeague>({
    id: -1,
    name: "Select league...",
  });
  const [seasonName, setSeasonName] = useState("");
  const [allClubs, setAllClubs] = useState<IClubWithHistoricalLeagues[]>([]);
  const [selectedClubs, setSelectedClubs] = useState<ISelectedClub[]>([]);
  const [startDate, setStartDate] = useState<Moment>(moment());
  const [endDate, setEndDate] = useState<Moment>(moment().add(3, "months"));
  const [seasonType, setSeasonType] = useState<"summer" | "yearRound">("summer");
  const [cutOffType, setCutOffType] = useState<"meetStart" | "fixedCutOff">("fixedCutOff");
  const [cutOffDate, setCutOffDate] = useState(moment(moment().format("YYYY") + "-06-01"));
  const [lumpSumPayments, setLumpSumPayments] = useState<ILumpSumPayment[]>([]);

  const getLumpSumPayments = useCallback(() => {
    swimminglyApi.get(gen("/api/getAllLumpSumPayments")).then((data) => {
      if (data.status === "success" && data.lumpSumPayments) {
        setLumpSumPayments(data.lumpSumPayments);
      } else {
        message.error("Problem finding lump sum payment options.");
      }
    });
  }, [setLumpSumPayments]);

  // isSwimmingly vs not isSwimmingly
  // how much they pay
  useEffect(() => {
    swimminglyApi.get(gen("/api/getAllClubsWithHistoricalLeagues")).then((data) => {
      setAllClubs(data.clubs);
    });
    swimminglyApi.get(gen("/api/getAllLeagues")).then((data) => {
      setAllLeagues(data.leaguesArr);
    });
    getLumpSumPayments();
  }, [user?.userId, getLumpSumPayments]);

  useEffect(() => {
    if (selectedLeague?.id && selectedLeague.id !== -1) {
      setSelectedClubs(
        allClubs
          .filter((c) => c.leagueIds.includes(selectedLeague.id))
          .map((c) => {
            return {
              ...c,
              clubId: c.clubId as number,
              isSwimmingly: 1,
              parentPays: 1800,
              corporatePaymentId: -1,
              clubPays: 0,
              leaguePays: 0,
              seedPremium: true,
            };
          })
      );
    } else {
      setSelectedClubs([]);
    }
  }, [allClubs, selectedLeague?.id]);

  const resetForm = () => {
    setSelectedLeague({
      id: -1,
      name: "Select league...",
    });
    setSeasonName("");
    setSelectedClubs([]);
    setStartDate(moment());
    setEndDate(moment().add(3, "months"));
    setSeasonType("summer");
    setCutOffType("fixedCutOff");
    setCutOffDate(moment(moment().format("YYYY") + "-MM-DD"));
  };

  const handleSubmit = () => {
    if (!seasonName || seasonName.trim() === "") {
      message.error("Set season name", 5);
      return;
    }
    if (selectedLeague.id === -1) {
      message.error("Select league", 5);
      return;
    }
    if (selectedClubs.length === 0) {
      message.error("Add clubs!", 5);
      return;
    }
    swimminglyApi
      .post(gen("/api/createSwimSeason"))
      .body({
        seasonName,
        selectedClubs,
        leagueId: selectedLeague.id,
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
        seasonType,
        cutOffType,
        cutOffDate: cutOffType === "fixedCutOff" ? cutOffDate.format("YYYY-MM-DD") : null,
        paymentFrequency: "season",
      })
      .then((data) => {
        if (data.status === "success") {
          message.success("Success!");
          resetForm();
        } else {
          console.log(data);
          message.error(data.message || "Something went wrong.");
        }
      });
  };

  return (
    <ContentPanel title="Create New Season">
      <StyledSeasonForm>
        <Form
          layout="vertical"
          onFinish={(e) => {
            handleSubmit();
          }}
        >
          <Row gutter={16}>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                label="Season Name"
                rules={[{ required: true, message: "Please input the season name!" }]}
              >
                <Input
                  placeholder="[Year] - [Summer/Yr Round] - [League Name]"
                  value={seasonName}
                  onChange={(e) => setSeasonName(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                label="Select League"
                rules={[{ required: true, message: "Please select a league!" }]}
              >
                <Select
                  value={selectedLeague.id}
                  onChange={(value) =>
                    setSelectedLeague(
                      allLeagues.find((l) => l.id === value) || {
                        id: -1,
                        name: "Select league...",
                      }
                    )
                  }
                  showSearch
                  filterOption={(input, option) =>
                    option?.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={-1} disabled hidden>
                    Select league...
                  </Option>
                  {allLeagues.map((league) => (
                    <Option key={`league_${league.id}`} value={league.id}>
                      {league.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} md={12} lg={8}>
              <Form.Item label="Season Type">
                <Select
                  value={seasonType}
                  onChange={(value) => {
                    const newSeasonType = value;
                    if (seasonType === "yearRound" && newSeasonType === "summer") {
                      setCutOffType("fixedCutOff");
                    }
                    setSeasonType(newSeasonType);
                  }}
                >
                  <Option value="summer">Summer</Option>
                  <Option value="yearRound">Year-Round</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                label={
                  <>
                    Cut Off Date Type{" "}
                    <Tooltip title="This will determine the age cutoff for the season - either the start day of any respective meet or a fixed date">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </>
                }
              >
                <Radio.Group onChange={(e) => setCutOffType(e.target.value)} value={cutOffType}>
                  <Radio value="meetStart">Day of Meet</Radio>
                  <Radio value="fixedCutOff">Fixed Date</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {cutOffType === "fixedCutOff" && (
              <Col xs={24} md={12} lg={8}>
                <Form.Item
                  label={
                    <>
                      Age Cut Off Date{" "}
                      <Tooltip title="Swimmers will not age-up one or after this date">
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </>
                  }
                >
                  <DatePicker
                    value={cutOffDate}
                    onChange={(date) =>
                      setCutOffDate(date || moment(moment().format("YYYY") + "-06-01"))
                    }
                  />
                </Form.Item>
              </Col>
            )}
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                label={
                  <>
                    Season Start/End{" "}
                    <Tooltip title="Users can open season/register swimmer and create swim meets within this date window">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </>
                }
              >
                <RangePicker
                  value={[startDate, endDate]}
                  onChange={(dates) => {
                    if (!dates || !Array.isArray(dates) || dates.length !== 2) {
                      setStartDate(moment());
                      setEndDate(moment().add(3, "months"));
                    } else {
                      let newStartDate = moment();
                      let newEndDate = moment().add(3, "months");
                      if (dates[0] && dates[1]) {
                        newStartDate = moment.min(dates[0], dates[1]);
                        newEndDate = moment.max(dates[0], dates[1]);
                      } else if (dates[0]) {
                        newStartDate = dates[0];
                        newEndDate = moment.max(newEndDate, newStartDate.add(3, "months"));
                      } else if (dates[1]) {
                        newEndDate = dates[1];
                        newStartDate = moment.min(newStartDate, newEndDate.subtract(3, "months"));
                      }
                      setStartDate(newStartDate);
                      setEndDate(newEndDate);
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          {selectedLeague.id !== -1 && (
            <>
              <fieldset>
                <Title level={3} style={{ textAlign: "center", paddingTop: "10px" }}>
                  Select Clubs
                </Title>
                <Row gutter={16} align="middle">
                  <Col span={20}>
                    <Select
                      value={-1}
                      onChange={(value) => {
                        const newSelectedClubs = cloneDeep(selectedClubs);
                        const newClub = allClubs.find((aC) => aC.clubId === value);
                        if (newClub && newClub.name_long) {
                          newSelectedClubs.push({
                            ...newClub,
                            isSwimmingly: 1,
                            parentPays: 1800,
                            corporatePaymentId: -1,
                            clubPays: 0,
                            leaguePays: 0,
                            seedPremium: true,
                          });
                          newSelectedClubs.sort((cl1, cl2) => (cl1.name_long > cl2.name_long ? 1 : -1));
                          setSelectedClubs(newSelectedClubs);
                        }
                      }}
                      showSearch
                      filterOption={(input, option) =>
                        option?.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      style={{ width: "50%" }}
                    >
                      <Option value={-1} disabled hidden>
                        Select Club to Add to Season
                      </Option>
                      {allClubs
                        .filter((aC) => !selectedClubs.map((sC) => sC.clubId).includes(aC.clubId))
                        .map((club, idx) => (
                          <Option key={`club_option_${club.clubId}_idx`} value={club.clubId || -1}>
                            {club.name_long} ({club.historicalLeagueName})
                          </Option>
                        ))}
                    </Select>
                  </Col>
                </Row>
                <Divider />
                <Row gutter={2} style={{ textAlign: "left", paddingBottom: "25px" }}>
                  <Col span={1} className="centered"></Col>
                  <Col span={6}>
                    <b>Club Name</b>
                  </Col>
                  <Col span={2} className="centered">
                    <b>Club ID</b>
                  </Col>
                  <Col span={3} className="centered">
                    <b>Customer / Guest</b>
                  </Col>
                  <Col span={3} className="centered">
                    <b>Premium / Basic</b>{" "}
                    <Tooltip title="If you select Premium, club will only see Premium. If you select Basic, club will see BOTH Premium and Basic. They will still opt into what they want.">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </Col>
                  <Col span={3}>
                    <b>By Parent Payment</b>{" "}
                    <Tooltip title="Membership (in $) paid by parents upon registration.">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </Col>
                  <Col span={3}>
                    <b>By Club Payment</b>{" "}
                    <Tooltip title="Membership (in $) paid by club card on file upon each parent registration.">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </Col>
                  <Col span={3} className="centered">
                    <b>Offline Payment</b>
                  </Col>
                </Row>
                {selectedClubs.map((sC) => {
                  const thisSelectedClub = selectedClubs.find((c) => sC.clubId === c.clubId);
                  return (
                    <Row key={`selected_club_${sC.clubId}`} gutter={2} align="top">
                      <Col span={1} className="centered">
                        <FaTrash
                          onClick={() => {
                            const newSelectedClubs = selectedClubs.filter((club) => club.clubId !== sC.clubId);
                            setSelectedClubs(newSelectedClubs);
                          }}
                          cursor="pointer"
                        />
                      </Col>
                      <Col span={6}>
                        {thisSelectedClub?.name_long}
                      </Col>
                      <Col span={2} className="centered">
                        {thisSelectedClub?.clubId}
                      </Col>
                      <Col span={3} className="centered">
                        <Switch checkedChildren="Customer" unCheckedChildren="Guest"
                          checked={sC.isSwimmingly === 1}
                          onChange={(checked) => {
                            const newSelectedClubs = cloneDeep(selectedClubs);
                            const thisClub = newSelectedClubs.find((nSC) => nSC.clubId === sC.clubId);
                            if (thisClub) {
                              thisClub.isSwimmingly = checked ? 1 : 0;
                              setSelectedClubs(newSelectedClubs);
                              message.success(`Club toggled to ${checked ? "customer" : "guest"}!`);
                            }
                          }}
                        />
                      </Col>
                      <Col span={3} className="centered">
                      <Switch checkedChildren="Premium" unCheckedChildren="Basic"
                        checked={sC.seedPremium}
                        onChange={(checked) => {
                          const newSelectedClubs = cloneDeep(selectedClubs);
                          const thisClub = newSelectedClubs.find((nSC) => nSC.clubId === sC.clubId);
                          if (thisClub) {
                            thisClub.seedPremium = checked;
                            setSelectedClubs(newSelectedClubs);
                          }
                        }}
                      />
                      </Col>
                      <Col span={3}>
                        <Input
                          value={sC.parentPays / 100}
                          step={"any"}
                          onChange={(e) => {
                            const newSelectedClubs = cloneDeep(selectedClubs);
                            const thisClub = newSelectedClubs.find((nSC) => nSC.clubId === sC.clubId);
                            if (thisClub) {
                              thisClub.parentPays =
                                e.target.value.trim() === "" ? 0 : parseFloat(e.target.value) * 100;
                              if (thisClub.parentPays !== 0) {
                                thisClub.clubPays = 0;
                              }
                              thisClub.corporatePaymentId = -1;
                              setSelectedClubs(newSelectedClubs);
                            }
                          }}
                          type="number"
                        />
                      </Col>
                      <Col span={3}>
                        <Input
                          value={sC.clubPays / 100}
                          step={"any"}
                          onChange={(e) => {
                            const newSelectedClubs = cloneDeep(selectedClubs);
                            const thisClub = newSelectedClubs.find((nSC) => nSC.clubId === sC.clubId);
                            if (thisClub) {
                              thisClub.clubPays =
                                e.target.value.trim() === "" ? 0 : parseFloat(e.target.value) * 100;
                              if (thisClub.clubPays !== 0) {
                                thisClub.parentPays = 0;
                              }
                              thisClub.corporatePaymentId = -1;
                              setSelectedClubs(newSelectedClubs);
                            }
                          }}
                          type="number"
                        />
                      </Col>
                      <Col span={3} className="centered">
                        <Select 
                          value={thisSelectedClub?.corporatePaymentId || -1}
                          onChange={(value) => {
                            const newSelectedClubs = cloneDeep(selectedClubs);
                            const thisClub = newSelectedClubs.find((nSC) => nSC.clubId === thisSelectedClub?.clubId);
                            if (thisClub) {
                              thisClub.corporatePaymentId = value;
                              if (thisClub.corporatePaymentId !== -1) {
                                thisClub.parentPays = 0;
                                thisClub.clubPays = 0;
                              }
                              setSelectedClubs(newSelectedClubs);
                            }
                          }}
                          style={{ width: "100%" }}
                          dropdownMatchSelectWidth={false}
                          dropdownAlign={{ offset: [-400, 4] }}
                          showSearch
                          filterOption={(input, option) =>
                            option?.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option value={-1}>None</Option>
                          {lumpSumPayments.map((lSP) => (
                            <Option key={`lump_sum_payment_id_${lSP.corporatePaymentId}`} value={lSP.corporatePaymentId}>
                              {lSP.corporatePaymentId} | {lSP.referenceName}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                    </Row>
                  );
                })}
              </fieldset>
              <Divider />
              {selectedClubs.length > 0 && (
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      height: "64px",
                      display: "block",
                      margin: "0 auto",
                      width: "50%",
                      backgroundColor: "green",
                      fontSize: "32px",
                    }}
                  >
                    Create Season Now!
                  </Button>
                </Form.Item>
              )}
            </>
          )}
        </Form>
      </StyledSeasonForm>
    </ContentPanel>
  );
}
