import { useState, useEffect } from "react"
import { Radio, Card, Tag, Divider, Tooltip } from "antd"
import moment from "moment-timezone"
import styled from "styled-components"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../../appData/types"
import {
  convertAgeGroupToNumbers,
  gen,
  IMeet,
  ISwimmerWithNumericGender,
  swimminglyApi,
} from "../utils"
import ContentPanel from "../ContentPanel"
import ExportEntriesModal from "../ExportEntriesModal"
import { screenSizes } from "../../styles/GlobalStyles"
import SwimEventSelector from "./SwimEventSelector"
import SwimmerAgeAndGenderFilters from "./SwimmerAgeAndGenderFilters"
import SwimmersInEvent from "./SwimmersInEvent"
import SwimmersNotInEvent from "./SwimmersNotInEvent"
import { SwimmerMeetEvents } from "./SwimmerMeetEvents"
import SwimmerTimesHistory from "./SwimmerTimesHistory"
import BestRelayModal from "./BestRelayModal"
import SwimmerEntriesTableNew from "./SwimmerEntriesTableNew"
import ActionsDropdown from "./ActionsDropdown"

interface IViewMeetLineUpProps {
  meetId: number
}

export type TMeetEvent = {
  stroke: string
  distance: number
  gender: "Boys" | "Girls" | "Mixed"
  ageGroup: string
  isRelay: 0 | 1
  eventNumber: number
  swimEventId: number
}

const MeetLineupWithSidebar = styled.div`
  display: flex;

  @media (max-width: ${screenSizes.small}px) {
    display: block;
  }
`
const StyledMeetLineup = styled.div`
  padding-right: 15px;
  display: grid;
  grid-template-columns: 3fr 4fr;
  grid-template-rows: auto minmax(0, 1fr);
  column-gap: 15px;
  row-gap: 10px;
  min-height: 0;
  min-width: 0;
  overflow: hidden;

  @media (max-width: ${screenSizes.small}px) {
    grid-template-columns: 1fr;
  }
`

export default function ViewMeetLineup({ meetId }: IViewMeetLineUpProps) {
  const impersonateClub = useStoreState(
    (state: StateMapper<AppDataStore>) => state.aliasedClub,
  )
  const [meetName, setMeetName] = useState("")
  const [meetCourse, setMeetCourse] = useState<"SCY" | "SCM" | "LCM">("SCY")
  const [meetDate, setMeetDate] = useState("1900-01-01")
  const [swimEvents, setSwimEvents] = useState<TMeetEvent[]>([])
  const [currentEventId, setCurrentEventId] = useState(-1)
  const [enteredEventSwimmers, setEnteredEventSwimmers] = useState<
    {
      swimmer: ISwimmerWithNumericGender
      meetAge: number
      attendingMeet: boolean | null
      seedTime: number | null
      previousBest: number | null
      converted: boolean
      isExhibition: boolean
      individualEntries: number
      relayEntries: number
      teamNumber: number
      teamPosition: number
      isRelay: 0 | 1
      swimEventId: number
      meetEntryId: number
    }[]
  >([])
  const [unEnteredEventSwimmers, setUnEnteredEventSwimmers] = useState<
    {
      attendingMeet: boolean | null
      swimmer: ISwimmerWithNumericGender
      meetAge: number
      individualEntries: number
      relayEntries: number
      seedTime: number | null
      previousBest: number | null
      converted: boolean
    }[]
  >([])
  const [exportEntriesModalVisible, setExportEntriesModalVisible] =
    useState(false)
  const [swimmerSelectedToViewHistory, setSwimmerSelectedToViewHistory] =
    useState<{ swimmer: ISwimmerWithNumericGender; meetAge: number } | null>(
      null,
    )
  const [eventsForSelectedSwimmer, setEventsForSelectedSwimmer] = useState<
    {
      isRelay: 0 | 1
      isExhibition: 0 | 1
      distance: string
      stroke: string
      raceType: number
      gender: "Boys" | "Girls" | "Mixed"
      ageGroup: string
      unitOfMeasure: 0 | 1 | 2
      course: "SCY" | "SCM" | "LCM"
      swimEventId: number
    }[]
  >([])
  const [resultsForSelectedSwimmer, setResultsForSelectedSwimmer] = useState<
    {
      stroke: string
      distance: string
      meetDate: string
      officialTime: number | null
      convertedTime: number | null
      originalCourse: "SCY" | "SCM" | "LCM"
    }[][]
  >([])
  const [genderFilter, setGenderFilter] =
    useState<"Boys" | "Girls" | "Mixed">("Boys")
  const [ageGroupFilter, setAgeGroupFilter] = useState("Open")
  const [sortType, setSortType] = useState<"by name" | "by time">("by name")
  const [sortDir, setSortDir] = useState<"aesc" | "desc">("aesc")
  const [triggerSwimmerRefresh, setTriggerSwimmerRefresh] = useState(0)
  const [bestRelayModalVisible, setBestRelayModalVisible] = useState(false)
  const [bestRelays, setBestRelays] = useState<
    {
      [key: string]: { swimmer_id: number; swimmer_name: string; time: number }
    }[]
  >([])
  const [relayDisplayName, setRelayDisplayName] = useState("")
  const [relayStrokeType, setRelayStrokeType] = useState("")
  const [bestRelaySeedTime, setBestRelaySeedTime] =
    useState<number | null>(null)
  const [viewTab, setViewTab] = useState<"by swimmer" | "by event">("by event")
  const [actionsVisible, setActionsVisible] = useState(false)
  const [numSwimmers, setNumSwimmers] = useState(0)
  const [officialIndividualEntries, setOfficialIndividualEntries] = useState(0)
  const [exhibitionIndividualEntries, setExhibitionIndividualEntries] =
    useState(0)
  const [numRelays, setNumRelays] = useState(0)

  useEffect(() => {
    if (meetId) {
      swimminglyApi
        .get(gen(`/api/getMeetInformation/${meetId}`))
        .then((data: { meet: IMeet }) => {
          setMeetName(data.meet.meetName)
          setMeetDate(data.meet.meet_date)
          setMeetCourse(
            String(data.meet.unit_of_measure) === "0"
              ? "SCY"
              : String(data.meet.unit_of_measure) === "1"
              ? "SCM"
              : "LCM",
          )
        })
      swimminglyApi
        .get(gen(`/api/getMeetEventOrder/${meetId}`))
        .then((data: { meetEvents: TMeetEvent[] }) => {
          if (data.meetEvents && data.meetEvents.length > 0) {
            setSwimEvents(data.meetEvents)
            setCurrentEventId(data.meetEvents[0].swimEventId)
          }
        })
    }
  }, [meetId])

  useEffect(() => {
    let mounted = true
    if (impersonateClub?.clubId && currentEventId !== -1) {
      const { lower, upper } = convertAgeGroupToNumbers(ageGroupFilter)
      swimminglyApi
        .post(gen("/api/getEnteredSwimmersByEventId"))
        .body({
          swimEventId: currentEventId,
          clubId: impersonateClub.clubId,
          meetId,
          gender: "Both",
          minAge: 0,
          maxAge: 200,
        })
        .then((data) => {
          if (mounted) {
            setEnteredEventSwimmers(data.enteredSwimmers)
          }
        })
      swimminglyApi
        .post(gen("/api/getUnEnteredSwimmersByEventId"))
        .body({
          swimEventId: currentEventId,
          clubId: impersonateClub.clubId,
          meetId,
          gender: genderFilter,
          minAge: lower,
          maxAge: upper,
        })
        .then((data) => {
          if (mounted) {
            setUnEnteredEventSwimmers(data.unEnteredSwimmers)
          }
        })
    }
    return () => {
      mounted = false
    }
  }, [
    triggerSwimmerRefresh,
    meetId,
    swimEvents,
    currentEventId,
    impersonateClub?.clubId,
    genderFilter,
    ageGroupFilter,
    viewTab,
  ])

  useEffect(() => {
    let mounted = true
    if (swimmerSelectedToViewHistory?.swimmer.swimmerId) {
      swimminglyApi
        .get(
          gen(
            `/api/getSwimmersEntriesInMeet/swimmerId=${swimmerSelectedToViewHistory?.swimmer.swimmerId}/meetId=${meetId}`,
          ),
        )
        .then((data) => {
          if (mounted) setEventsForSelectedSwimmer(data.entries)
        })
      swimminglyApi
        .get(
          gen(
            `/api/getHistoricalResultsForSwimmer/${swimmerSelectedToViewHistory.swimmer.swimmerId}?referenceCourse=${meetCourse}`,
          ),
        )
        .then((data) => {
          setResultsForSelectedSwimmer(data.results)
        })
    } else {
      if (mounted) {
        setEventsForSelectedSwimmer([])
        setResultsForSelectedSwimmer([])
      }
    }
    return () => {
      mounted = false
    }
  }, [
    swimmerSelectedToViewHistory?.swimmer.swimmerId,
    meetId,
    triggerSwimmerRefresh,
    meetCourse,
  ])

  useEffect(() => {
    let mounted = true
    impersonateClub?.clubId &&
      swimminglyApi
        .post(gen("/api/getNumberOfEntries"))
        .body({
          meetId,
          clubId: impersonateClub.clubId,
        })
        .then((data) => {
          if (mounted) {
            setNumSwimmers(data.numSwimmers)
            setOfficialIndividualEntries(data.officialIndividualEntries)
            setExhibitionIndividualEntries(data.exhibitionIndividualEntries)
            setNumRelays(data.numRelays)
          }
        })
    return () => {
      mounted = false
    }
  }, [
    impersonateClub?.clubId,
    meetId,
    triggerSwimmerRefresh,
    currentEventId,
    setNumSwimmers,
    setOfficialIndividualEntries,
    setExhibitionIndividualEntries,
    setNumRelays,
  ])

  return (
    <div>
      <header className="page-header">
        <h2>
          {impersonateClub?.name} Meet Lineup - {meetCourse} {meetName}{" "}
          {moment.utc(meetDate).format("MM/DD/YYYY")}
        </h2>
      </header>
      

      
      <ContentPanel
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Tooltip 
              title="Edit entries anytime here (before or after meet declarations), then proceed to Assign Heats/Lanes to mark your club as heat sheet-ready!"
              placement="right"
              color="#101242"
            >
            <h3>Editing Meet Lineup Entries: {meetName}</h3>
            </Tooltip>
            <Radio.Group
              value={viewTab}
              onChange={(e) => setViewTab(e.target.value)}
              size="large"
            >
              <Radio.Button value="by swimmer">By Swimmer</Radio.Button>
              <Radio.Button value="by event">By Event</Radio.Button>
            </Radio.Group>
            
            <ActionsDropdown
              actionsVisible={actionsVisible}
              setActionsVisible={setActionsVisible}
              meetId={meetId}
              refreshSwimmers={() =>
                setTriggerSwimmerRefresh(triggerSwimmerRefresh + 1)
              }
              showHideExportEntriesModal={setExportEntriesModalVisible}
            />
          </div>
        }
      >
        <span>
        <Tag color="#52c41a">{numSwimmers}</Tag>swimmers entered
        <br />
        <Tag color="#25bfea">{officialIndividualEntries}</Tag>individual entries
        <br />
        <Tag color="#8092a2">{numRelays}</Tag>relay entries
          <br />
        <Tag color="#fa8c16">{exhibitionIndividualEntries}</Tag>exhibition
          entries
        </span>
        <Divider orientation="left"></Divider>
        <br />
        {viewTab === "by swimmer" && (
          <SwimmerEntriesTableNew
            meetId={meetId}
            setViewTab={setViewTab}
            setCurrentEventId={setCurrentEventId}
            refreshSwimmers={() =>
              setTriggerSwimmerRefresh(triggerSwimmerRefresh + 1)
            }
          />
        )}
        {viewTab === "by event" && (
          <MeetLineupWithSidebar>
            <StyledMeetLineup>
              <SwimEventSelector
                swimEvents={swimEvents}
                currentEventId={currentEventId}
                setCurrentEventId={setCurrentEventId}
                setGenderFilter={setGenderFilter}
                setAgeGroupFilter={setAgeGroupFilter}
              />
              <SwimmerAgeAndGenderFilters
                genderFilter={genderFilter}
                setGenderFilter={setGenderFilter}
                ageGroupFilter={ageGroupFilter}
                setAgeGroupFilter={setAgeGroupFilter}
              />
              <SwimmersInEvent
                enteredEventSwimmers={enteredEventSwimmers}
                setSwimmerSelectedToViewHistory={
                  setSwimmerSelectedToViewHistory
                }
                meetId={meetId}
                isRelay={
                  swimEvents.find((sE) => sE.swimEventId === currentEventId)
                    ?.isRelay === 1
                }
                swimEventId={currentEventId}
                refreshSwimmers={() =>
                  setTriggerSwimmerRefresh(triggerSwimmerRefresh + 1)
                }
              />
              <SwimmersNotInEvent
                unEnteredEventSwimmers={unEnteredEventSwimmers}
                setSwimmerSelectedToViewHistory={
                  setSwimmerSelectedToViewHistory
                }
                swimEventId={currentEventId}
                sortType={sortType}
                sortDir={sortDir}
                meetId={meetId}
                refreshSwimmers={() =>
                  setTriggerSwimmerRefresh(triggerSwimmerRefresh + 1)
                }
                isRelay={
                  swimEvents.find((sE) => sE.swimEventId === currentEventId)
                    ?.isRelay === 1
                }
                meetCourse={meetCourse}
                setShowBestTimeRelayModal={setBestRelayModalVisible}
                setBestRelays={setBestRelays}
                setRelayDisplayName={setRelayDisplayName}
                setBestRelaySeedTime={setBestRelaySeedTime}
                setRelayStrokeType={setRelayStrokeType}
                setSortDir={setSortDir}
                setSortType={setSortType}
              />
            </StyledMeetLineup>
            <div>
            <h3>Swimmer Details</h3>
            <Card title={swimmerSelectedToViewHistory &&
                    `${swimmerSelectedToViewHistory?.swimmer.firstName} ${swimmerSelectedToViewHistory?.swimmer.lastName}`} style={{ width: 500 }}>
              
              
              <SwimmerMeetEvents
                meetId={meetId}
                refreshSwimmers={() =>
                  setTriggerSwimmerRefresh(triggerSwimmerRefresh + 1)
                }
                activeSwimmer={{
                  swimmer: swimmerSelectedToViewHistory?.swimmer || null,
                  meetAge: swimmerSelectedToViewHistory?.meetAge || 0,
                }}
                events={swimEvents}
                eventsIAmIn={eventsForSelectedSwimmer}
              />
              <br />
              <SwimmerTimesHistory
                historicalResults={resultsForSelectedSwimmer}
                event={{
                  distance: String(
                    swimEvents.find((sE) => sE.swimEventId === currentEventId)
                      ?.distance || "50",
                  ),
                  stroke:
                    swimEvents.find((sE) => sE.swimEventId === currentEventId)
                      ?.stroke || "Freestyle",
                }}
                meetId={meetId}
                swimEventId={currentEventId}
                swimmerId={
                  swimmerSelectedToViewHistory?.swimmer.swimmerId || -1
                }
                refreshSwimmers={() =>
                  setTriggerSwimmerRefresh(triggerSwimmerRefresh + 1)
                }
                removedFromMeet={
                  unEnteredEventSwimmers.find(
                    (uEES) =>
                      uEES.swimmer.swimmerId ===
                      swimmerSelectedToViewHistory?.swimmer.swimmerId,
                  )?.attendingMeet === false
                }
                meetCourse={meetCourse}
              />
            </Card>
            </div>
          </MeetLineupWithSidebar>
        )}
      </ContentPanel>
      <ExportEntriesModal
        meetId={meetId}
        updateVisible={setExportEntriesModalVisible}
        isVisible={exportEntriesModalVisible}
        clubId={impersonateClub?.clubId || -1}
      />
      <BestRelayModal
        bestRelays={bestRelays}
        availableSwimmers={unEnteredEventSwimmers}
        relayDisplayName={relayDisplayName}
        bestRelayModalVisible={bestRelayModalVisible}
        relayStrokeType={relayStrokeType}
        bestRelaysSeedTime={bestRelaySeedTime}
        handleBestRelayOk={() => {
          setBestRelayModalVisible(false)
          setBestRelays([])
          setBestRelaySeedTime(null)
          setRelayStrokeType("")
        }}
        handleBestRelayCancel={() => {
          setBestRelayModalVisible(false)
          setBestRelays([])
          setBestRelaySeedTime(null)
          setRelayStrokeType("")
        }}
      />
    </div>
  )
}
