import { message, Switch, Tabs, Button } from "antd"
import { FunctionComponent, useState } from "react"
import { gen, swimminglyApi } from "../../utils"
import { useClubSeasonManagementContext } from "../sharedState"
import { RegistrationWaiver } from "./RegistrationWaiver"
import { StyledRegistrationWaiversTable } from "./styles"
import { PlusOutlined } from "@ant-design/icons"
import { Alert } from "antd"

const { TabPane } = Tabs

export const WaiversSection: FunctionComponent = () => {
  const [activeKey, setActiveKey] = useState("waiversOverview")
  const {
    dbUsingRegistration,
    waivers,
    setRegistrationModalVisible,
    registrationForm,
    loadRegistrationInfo,
    selectedClubSeason,
    refreshOneWaiver,
  } = useClubSeasonManagementContext()

  return (
    <div
      style={{
        backgroundColor: "lightgrey",
        borderRadius: "25px",
        paddingBottom: "25px",
      }}
    >
      <Tabs
        tabPosition="left"
        activeKey={activeKey}
        onChange={(key) => setActiveKey(key)}
      >
        <TabPane tab="Waivers Overview" key="waiversOverview">
          <div style={{
              textAlign: "left",
              width: "90%",
              marginTop: "15px",
              marginBottom: "50px",
              color: "#101242",
              fontSize: "16px",
            }}>
            <p>
            <b>Step 2:</b> Welcome to Waivers and Disclosures
            <br /><br />
            You can create as many custom waivers as you'd like.
            To speed up the process, we've preset 4 common waivers that are at your disposal to modify as needed. Each waiver includes the following features you can customize that your families will see:
            </p>
            <ul>
              <li><b>Title</b></li>
              <li><b>Description</b></li>
              <li><b>Sections</b> - add as many within each waiver as you need. Within each section, denote whether that section requires acknowledgement from guardian, disclosure only (no acknowledgement), or requires a freeform text response from parent/guardian.</li>
            </ul>
            <br />
            <Alert
              message="Pro Tip"
              description="Recommendation: If your club has any additional checkout details parents/guardians need to include for you to reconcile with another system of record (e.g., country club membership code), then create a custom freeform disclosure requiring parents to provide that information."
              style={{ padding: 5 }}
              type="info"
              showIcon
            />
           
            
            <br />
          <StyledRegistrationWaiversTable>
            <thead>
              <tr>
                <th>Waiver Name</th>
                <th>Using This Waiver</th>
              </tr>
            </thead>
            <tbody>
              {waivers?.map((waiver) => (
                
                
                <tr key={`table_row_waiver_${waiver.waiverId}`}>
                  <td>{waiver.waiverName}</td>
                  <td>
                    <Switch
                      checked={waiver.waiverEnabled === 1}
                      onChange={async (checked, e) => {
                        e.preventDefault()
                        await swimminglyApi
                            .post(gen(`/api/updateWaiverEnabled`))
                            .body({
                              registrationFormId: waiver.registrationFormId,
                              waiverId: waiver.waiverId,
                              choice: checked,
                            })
                            .then(async (data) => {
                              if (data.status === "success") {
                                await refreshOneWaiver(waiver.waiverId)
                              }
                            })
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </StyledRegistrationWaiversTable>
          <br />
          <Button type="primary" shape="circle" icon={<PlusOutlined />}
            onClick={() => {
              if (!dbUsingRegistration) {
                setRegistrationModalVisible(true)
              } else {
                swimminglyApi
                  .post(
                    gen(
                      `/api/addWaiverToRegistrationForm/${registrationForm?.registrationFormId}`,
                    ),
                  )
                  .body({})
                  .then((data) => {
                    if (data.status !== "success") {
                      console.log(data)
                      throw new Error(
                        data?.message ||
                          "Problem adding a waiver to this registration form",
                      )
                    }
                  })
                  .catch((err) => {
                    message.error(err.message || "Problem adding waiver")
                  })
                  .finally(() => {
                    if (selectedClubSeason?.seasonId) {
                      loadRegistrationInfo(selectedClubSeason.seasonId)
                    } else {
                      message.warn(
                        "You might want to refresh the page - we're having trouble connecting to the server",
                      )
                    }
                  })
              }
            }}
          >
            </Button>
            &nbsp;Add New Waiver
          </div>
        </TabPane>
        {waivers?.length
          ? waivers
              .map((waiver) => (
                <TabPane
                  tab={waiver.waiverName}
                  key={`waiver_${waiver.waiverId}`}
                >
                  <RegistrationWaiver
                    waiver={waiver}
                    goToWaiversOverview={() => setActiveKey("waiversOverview")}
                  />
                </TabPane>
              ))
          : null}
      </Tabs>
    </div>
  )
}
