import { Fragment, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import ContentPanel from './ContentPanel'
import { PricingServiceHelper, PricingServiceResult } from './Helpers/PricingServiceHelper'
import { InputNumber, Input, Button, message, Tooltip, Alert } from 'antd';
import { formatMoney } from './utils/helperFunctions'
import { IPaymentMethod } from './Billing'
import DisplayCards from './Billing/DisplayCards'
import { BillingHelper, IClubSeasonPaymentInfo } from './ClubSeasonManagement/Billing'
import { StateMapper, useStoreState } from 'easy-peasy'
import { AppDataStore } from '../appData/types'
import AddPaymentMethodForm from './Billing/AddPaymentMethodForm'
import { RegistrationHelper } from './ClubSeasonManagement/RegistrationConfiguration/RegistrationHelper'
import { IClubSeasonInfo } from './utils'
import _ from 'lodash'
import { InfoCircleTwoTone } from '@ant-design/icons'


const StyledClubDues = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr  200px;
  align-items: center;
  column-gap: 10px;
  border-bottom: 1px solid lightgray;

  .header {
    font-weight: bold;
    padding-top: 10px;
  }
`
const StyledCartView = styled.div`
margin-top: 32px;
`
const regisrationHelper = new RegistrationHelper()

export default function GuardianCartView() {
  const location = useLocation();
  const { clubId, seasonId, swimmerIds, usingRegistration, selectedClubSeasonInfo } = location.state as { clubId: number, seasonId: number, swimmerIds: number[], usingRegistration: boolean, selectedClubSeasonInfo: IClubSeasonInfo };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  
  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)
  const [pricingServiceHelper, setPricingServiceHelper] = useState<PricingServiceHelper>(new PricingServiceHelper())
  const [pricingServiceResults, setPricingServiceResults] = useState<PricingServiceResult | undefined>(undefined)
  const [serviceFeePercentage, setServiceFeePercentage] = useState<number | undefined>(undefined)
  const [keyboard, setKeyboard] = useState(true);
  const [quantityChanging, setQuantityChanging] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<IPaymentMethod | undefined>()
  const [paymentButtonEnabled, setPaymentButtonEnabled] = useState<boolean>(false)
  const [availablePaymentMethods, setAvailablePaymentMethods] = useState<
    IPaymentMethod[]
  >([])
  const [addPaymentMethodVisible, setAddPaymentMethodVisible] = useState(false)
  const [hasStripeCustomerId, setHasStripeCustomerId] = useState(false)
  const [parentPays, setParentPays] = useState(false)
  const [clubPays, setClubPays] = useState(false)
  const [isOfflinePayment, setIsOfflinePayment] = useState(false)
  const [isGuestClub, setIsGuestClub] = useState(false)
  const [isUsingRegistration, setIsUsingRegistration] = useState(false)
  const [discountCode, setDiscountCode] = useState("")
  const history = useHistory()


  useEffect(() => {
    if (quantityChanging === true) {
      pricingServiceHelper.init(clubId, seasonId, swimmerIds)
      setPricingServiceHelper(pricingServiceHelper)
        pricingServiceHelper.getAllPrices()
        .then((pricingServiceResults) => {
          if (pricingServiceResults.status == "success") {
            setPricingServiceResults(pricingServiceResults)
            setServiceFeePercentage(pricingServiceResults?.swimminglyServiceFeePercentage ? (pricingServiceResults?.swimminglyServiceFeePercentage * 100) : undefined)
          }
          setQuantityChanging(false)
        })
    }
  }, [quantityChanging, clubId, seasonId, swimmerIds])

  useEffect(() => {
    updatePaymentMethods()
    paymentInfoForClubSeason()
  }, [])


  useEffect(() => {
    if (_.isNil(user?.userId)) {
      setPaymentButtonEnabled(false)
    } else if (parentPays && !Boolean(selectedPaymentMethod)) {
      setPaymentButtonEnabled(false)
    } else if (parentPays && Boolean(selectedPaymentMethod)) {
      setPaymentButtonEnabled(true)
    } else if (clubPays && hasStripeCustomerId) {
      setPaymentButtonEnabled(true)
    } else if (clubPays && !hasStripeCustomerId) {
      setPaymentButtonEnabled(false)
    } else if (isOfflinePayment || !Boolean(isGuestClub)) {
      setPaymentButtonEnabled(true)
    }

  }, [hasStripeCustomerId, selectedPaymentMethod, clubPays, parentPays, isOfflinePayment, isGuestClub])


  const userProvidesPayment = usingRegistration || selectedClubSeasonInfo.parentPays
  
  const updatePaymentMethods = (makeAddPaymentMethodVisible = false) => {
    if (user) {
      const billingHelper = new BillingHelper()
      billingHelper.getPaymentMethods(user?.userId)
        .then((paymentMethodResult) => {
          if (paymentMethodResult.status === "success") {
            setAvailablePaymentMethods(paymentMethodResult.paymentMethods)
            setAddPaymentMethodVisible(makeAddPaymentMethodVisible)
          }
        })
    }
  }

  const paymentInfoForClubSeason = () => {
    if (user) {
      const billingHelper = new BillingHelper()
      billingHelper.getClubSeasonPaymentInfo(clubId, seasonId)
        .then((clubSeasonPaymentInfoResult) => {
          if (clubSeasonPaymentInfoResult?.clubSeasonPaymentInfo?.clubStripeCustomerId) {
            setHasStripeCustomerId(true)
          } else {
            setHasStripeCustomerId(false)
          }
          setIsGuestClub(Boolean(clubSeasonPaymentInfoResult?.clubSeasonPaymentInfo?.isSwimminglyCustomer))
          setClubPays(Boolean(clubSeasonPaymentInfoResult?.clubSeasonPaymentInfo?.clubPaysAmount))
          setParentPays(Boolean(clubSeasonPaymentInfoResult?.clubSeasonPaymentInfo?.parentPaysAmount)) 
          setIsOfflinePayment(Boolean(clubSeasonPaymentInfoResult?.clubSeasonPaymentInfo?.corporatePaymentId)) 
          setIsUsingRegistration(Boolean(clubSeasonPaymentInfoResult?.clubSeasonPaymentInfo?.isUsingRegistration))
        })
    }
  }

  const results = pricingServiceResults?.swimmerPrices.map((swimmerPrice) => {
    const inputIdentifier = `discount_${swimmerPrice.swimmerId}`
    const discountCodeInputAndButton = (
      <span>
        <Input
          id={inputIdentifier}
          placeholder="add code"
          style={{ width: 120 }}
          allowClear={true}
          size="small"
          value={discountCode}
          onChange= {(e) => setDiscountCode(e.target.value)}
          onPressEnter={() =>  
            {
            if (discountCode !== undefined) {
               pricingServiceHelper.addSwimmerAndDiscount(swimmerPrice.swimmerId, discountCode)
                setQuantityChanging(true)
                setDiscountCode("")
            }
            }
          }
        />
        <Button
          type="primary"
          size="small"
          style={{ paddingLeft: "10px" }}
          onClick={() => 
            {
              if (discountCode !== undefined) {
                pricingServiceHelper.addSwimmerAndDiscount(swimmerPrice.swimmerId, discountCode)
                setQuantityChanging(true)
                setDiscountCode("")
              }
            }
          }
        >Add Discount</Button>
      </span>
    )

    const swimmerRow = (
      <StyledClubDues>
        <span>{swimmerPrice.swimmerName}</span>
        <span>{pricingServiceResults?.clubName}</span>
        <span>{pricingServiceResults?.seasonName}</span>
        {
          usingRegistration ? discountCodeInputAndButton : null
        }

        <span>{formatMoney(swimmerPrice.opaqueCost)}</span>

      </StyledClubDues>
    )

    const discountRows = swimmerPrice.discounts.map((discount) => {
      const discountCode = (<span>{discount.code}</span>)
      return (
        <StyledClubDues>
          <span></span>
          <span></span>
          <span></span>
          {usingRegistration ? discountCode : null}
          <span style={{ color: 'red' }}>{formatMoney(discount.amount)}</span>
        </StyledClubDues>)
    })

    return [[swimmerRow], discountRows].flat()
  })

  const addPaymentMethodButton = (
    <Button
      onClick={() => setAddPaymentMethodVisible(!addPaymentMethodVisible)}
      type="primary"
    >
      {addPaymentMethodVisible ? "Show Existing Payment Methods" : "Add Payment Method"}
    </Button>
  )

  const additionalCheckoutItems = (
    <ContentPanel title="Additonal Items">
      <StyledClubDues>
        <span className="header">Item</span>
        <span className="header">Unit Cost</span>
        <span className="header">Quantity</span>
        <span className="header">Total</span>
      </StyledClubDues>

      {pricingServiceResults && pricingServiceResults.checkoutItems.map((checkoutItem) => {
        return (
          <StyledClubDues>
            <span>{checkoutItem.code}</span>
            <span>{formatMoney(checkoutItem.unitCost)}</span>
            <span>
              <InputNumber
                min={0}
                max={50}
                defaultValue={checkoutItem.quantity}
                onChange={(quantity) => {
                  setKeyboard(!keyboard);
                  pricingServiceHelper.setCheckoutItemQuantity(checkoutItem.code, quantity)
                  setQuantityChanging(true)

                }}
              />
            </span>
            <span>{formatMoney(checkoutItem.total)}</span>
          </StyledClubDues>
        )
      })}
    </ContentPanel>
  )

  const selectAvailablePaymentMethods = (
    <div>
      {addPaymentMethodVisible ? (
        <AddPaymentMethodForm setAddPaymentMethodVisible={(visible: boolean) => {
          //keith says: this looks like it was designed to just display the payment methods via a usestate hook, but i think we're better off with our own callback that reloads the payment methods
          updatePaymentMethods(visible)
        }} />
      ) : (
        availablePaymentMethods.length === 0 ? (
          <p style={{ textAlign: "center" }}>
            You don't have any payment methods yet!
          </p>
        ) : (
          <Fragment>
            <p style={{ textAlign: "center" }}>
              Please select a payment method:
            </p>
            <DisplayCards
              availablePaymentMethods={availablePaymentMethods}
              selectedPaymentMethod={selectedPaymentMethod}

              setSelectedPaymentMethod={setSelectedPaymentMethod}
              refresh={() => {
                updatePaymentMethods()
              }
              }
            />
          </Fragment>
        )
      )}
    </div>
  )

  const title = (clubPays || isOfflinePayment) ? "Swimmingly Membership Checkout" : "Club Registration"
  const banner = (clubPays || isOfflinePayment) ? (
  <>
  <Alert 
    message="Proceed to Checkout - No Payment Required"
    description="Your organization is paying for the Swimmingly membership on your behalf. There is no payment required to register."
    type="success" 
    showIcon
  />
  <p />
  </>) : <></>
  return (
    <StyledCartView>
      {banner}
      <ContentPanel title={title}>
        
        <StyledClubDues>
          <span className="header">Child's Name</span>
          <span className="header">Club Name</span>
          <span className="header">Registration Period</span>
          {
            usingRegistration
              ? (<span className="header">Discount Code</span>)
              : null
          }
          <span className="header">Amount Due</span>
        </StyledClubDues>
        {results}
      </ContentPanel>

      {usingRegistration ? additionalCheckoutItems : null}
      {banner}

      <ContentPanel title="Total">
        {
          parentPays ? 
            (
              <>
              {
                isUsingRegistration ? (
                  <>
                    <StyledClubDues>
                      <span></span>
                      <span></span>
                      <span>Dues:</span>
                      <span > {formatMoney(pricingServiceResults?.totals?.registrationDuesTotal) ?? 0}</span>
                    </StyledClubDues>
                    <StyledClubDues>
                      <span></span>
                      <span></span>
                      <span>Discount:</span>
                      <span style={{ color: 'red' }}> {formatMoney(pricingServiceResults?.totals?.appliedDiscountTotal) ?? 0}</span>
                    </StyledClubDues>
                    <StyledClubDues>
                      <span></span>
                      <span></span>
                      <span>Additional Checkout Items:</span>
                      <span > {formatMoney(pricingServiceResults?.totals?.checkoutItems) ?? 0}</span>
                    </StyledClubDues>                  
                  </>

                ) : (<></>)
              }
                <StyledClubDues>
                  <span></span>
                  <span></span>
                  <span>Subtotal:</span>
                  <span > {formatMoney(pricingServiceResults?.totals?.beforeProcessingFee) ?? 0}</span>
                </StyledClubDues>
                <StyledClubDues>
                  <span></span>
                  <span></span>
                  <span>
                  Service Fee:
                  <Tooltip
                    title={`This ${serviceFeePercentage ? serviceFeePercentage + "%" : ""} fee covers credit card & Swimmingly processing fees`}
                    mouseEnterDelay={0.5}
                  >
                    &nbsp; <InfoCircleTwoTone />          
                  </Tooltip>            
                  </span>
                  <span > {formatMoney(pricingServiceResults?.totals?.swimminglyServiceFee) ?? 0}</span>
                </StyledClubDues>              

                <StyledClubDues>
                  <span></span>
                  <span></span>
                  <span style={{ fontWeight: 'bold' }}>Total Registration Cost:</span>
                  <span style={{ fontWeight: 'bold' }}> {formatMoney(pricingServiceResults?.totals?.total ?? 0)}</span>
                </StyledClubDues>
              </>
            )
           : (
           <>
              <StyledClubDues>
                <span></span>
                <span></span>
                <span style={{ fontWeight: 'bold' }}>You pay:</span>
                <span style={{ fontWeight: 'bold' }}> {formatMoney(0)}</span>
              </StyledClubDues>
           </>
           )
        }
      </ContentPanel>

      {userProvidesPayment ? addPaymentMethodButton : null}
      {userProvidesPayment ? selectAvailablePaymentMethods : null}

      <Button 
        disabled={!paymentButtonEnabled}
        onClick={() => {
          if (paymentButtonEnabled && user) {
            setSubmitting(true)
            regisrationHelper.checkout(clubId, seasonId, user.userId, pricingServiceHelper.pricingServiceRequest(), selectedPaymentMethod?.paymentMethodId)
              .then((status) => {
                setSubmitting(false)
                if (status === "success") {
                  history.push("/app/landingPage", {isThankYouModalOpen: true})
                } else {
                  message.error(
                    "Uh oh, something went wrong processing your payment",
                    5,
                  )
                }
              })
          }
        }}
        type="primary"
        loading={submitting}
      >
        Register & Checkout
      </Button>

    </StyledCartView>
  )
}

