import styled from "styled-components"
import {
  FaCcVisa,
  FaCcMastercard,
  FaTrash,
  FaCcAmex,
  FaCcDiscover,
  FaCcDinersClub,
  FaCcJcb,
} from "react-icons/fa"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../../appData/types"
import { IPaymentMethod } from "./index"
import moment from "moment-timezone"
import { gen, swimminglyApi } from "../utils"
import { message } from "antd"

const StyledCardBlock = styled.div`
  width: 400px;
  max-width: 95%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;

  .card-row {
    border: 1px solid var(--mediumgrey);
    border-bottom: none;
    padding-left: 5px;
    padding-right: 5px;
    display: grid;
    align-items: center;
    grid-template-columns: 50px 4fr 3fr 3fr 1fr;
  }

  .card-row.expired {
    background-color: #ffb5b0;
    color: #8c0c00;
  }

  .card-row:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .card-row:nth-child(2) {
    border-top: 2px solid var(--mediumgrey);
  }

  .card-row:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom: 1px solid var(--mediumgrey);
  }
`

const GetCardIcon = ({
  cardBrand,
  ...rest
}: {
  cardBrand: string
  [key: string]: any
}): JSX.Element => {
  if (cardBrand === "visa") return <FaCcVisa {...rest} />
  if (cardBrand === "mastercard") return <FaCcMastercard {...rest} />
  if (cardBrand === "amex") return <FaCcAmex {...rest} />
  if (cardBrand === "discover") return <FaCcDiscover {...rest} />
  if (cardBrand === "diners_club") return <FaCcDinersClub {...rest} />
  if (cardBrand === "jcb") return <FaCcJcb {...rest} />
  return <span>{cardBrand}</span>
}

interface IDisplayCardsProps {
  availablePaymentMethods: IPaymentMethod[]
  selectedPaymentMethod: IPaymentMethod | undefined
  setSelectedPaymentMethod: (paymentMethod: IPaymentMethod | undefined) => void
  refresh: () => void
}

export default function DisplayCards({
  availablePaymentMethods,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  refresh,
}: IDisplayCardsProps) {
  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)

  const deleteCard = (paymentMethodId: string) => {
    swimminglyApi
      .post(gen(`/api/deletePaymentMethod/${paymentMethodId}`))
      .body({ userId: user?.userId })
      .then((data) => {
        if (data.status === "success") message.success("success!")
        else message.error("Uh oh, problem deleting your card")
      })
      .catch((err) => {
        console.log(err)
        message.error("Uh oh, problem deleting your card")
      })
      .finally(refresh)
  }

  return (
    <StyledCardBlock>
      <div className="card-row">
        <span>
          <b>Select</b>
        </span>
        <span>
          <b>Card #</b>
        </span>
        <span>
          <b>Type</b>
        </span>
        <span>
          <b>Exp.</b>
        </span>
        <div />
      </div>
      {availablePaymentMethods.map((aPM, idx) => {
        let isExpired = false
        if (moment().format("YYYY-MM") > `${aPM.expYear}-${aPM.expMonth}`) {
          isExpired = true
        }
        return (
          <div
            key={`paymentMethod_${idx}`}
            className={"card-row" + (isExpired ? " expired" : "")}
          >
            <span style={{ paddingLeft: "10px" }}>
              <input
                type="checkbox"
                checked={
                  selectedPaymentMethod?.paymentMethodId === aPM.paymentMethodId
                }
                onChange={() => {
                  if (
                    selectedPaymentMethod?.paymentMethodId ===
                    aPM.paymentMethodId
                  ) {
                    setSelectedPaymentMethod(undefined)
                  } else {
                    setSelectedPaymentMethod(aPM)
                  }
                }}
              />
            </span>
            <span>ends in {aPM.last4}</span>
            <GetCardIcon cardBrand={aPM.cardBrand} fontSize="2rem" />
            <span>
              {String(aPM.expMonth).padStart(2, "0")}/{aPM.expYear}
            </span>
            {isExpired ? (
              <FaTrash
                style={{ cursor: "pointer" }}
                onClick={() => deleteCard(aPM.paymentMethodId)}
              />
            ) : (
              <div>
                <FaTrash
                  style={{ cursor: "pointer" }}
                  onClick={() => deleteCard(aPM.paymentMethodId)}
                />
              </div>
            )}
          </div>
        )
      })}
    </StyledCardBlock>
  )
}
