import { message, Skeleton } from "antd"
import Modal from "antd/lib/modal/Modal"
import React, { useCallback, useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import { FaUpload } from "react-icons/fa"
import styled from "styled-components"
import JSZip from "jszip"
import { cloneDeep } from "lodash"
import parseSd3, {
  ISd3Validation,
  ISwimDatum,
  validateSd3,
} from "../utils/parseSd3"
import {
  CustomEventEmitter,
  gen,
  IClub,
  swimminglyApi,
  useNoRenderRef,
} from "../utils"
import { IClubSeasonInformation } from "../MeetActionsModal"
import { IManageRosterSeasonSwimmer } from "../ManageRoster"

const Sd3Manager = new CustomEventEmitter()

interface IImportEntriesModalProps {
  visible: boolean
  setVisible: (newVisible: boolean) => void
  meetId: number
  refresh: () => void
}

interface ISwimmerInSd3 {
  club: string
  swimmerFirstName: string
  swimmerLastName: string
  swimmerGender: string
  swimmerDOB: string
  swimmerId: number
}

interface ISd3Data {
  clubNames: string[]
  swimmers: ISwimmerInSd3[]
  swimData: ISwimDatum
  meetEvents: MeetEvent[]
}

const StyledDropZone = styled.div`
  border-radius: 10px;
  border: 3px dashed var(--mediumgrey);
  cursor: pointer;
  text-align: center;
  &:hover {
    border: 3px dashed lightblue;
    color: var(--primaryblue);
  }
`

type SetSd3 = (sd3Contents: ISd3Data) => void

function FileDropZone({
  setSd3Validation,
  setSd3Contents,
  setSd3LoadStep,
}: {
  setSd3Validation: (value: ISd3Validation) => void
  setSd3Contents: SetSd3
  setSd3LoadStep: (
    value:
      | "file-drop"
      | "club-map"
      | "choose-swimmers"
      | "swimmer-exceptions"
      | "load-data"
      | "invalid-sd3",
  ) => void
}): JSX.Element {
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (!acceptedFiles || acceptedFiles.length === 0) return

      const file = acceptedFiles[0]

      if (!file) {
        message.error("Problem with file")
        return
      }

      const fileNameParts = file.name.split(".")
      if (fileNameParts.length === 0) {
        message.error(
          "Invalid file extension - please upload a .sd3, .cl2, or .zip file",
          4,
        )
        return
      }
      const extension = fileNameParts[fileNameParts.length - 1].toLowerCase()
      if (!["sd3", "cl2", "zip"].includes(extension)) {
        message.error(
          "Invalid file extension - please upload a .sd3, .cl2, or .zip file",
          4,
        )
        return
      }

      let fileContents: string
      if (extension === "zip") {
        const zipReader = new JSZip()
        const zipFile = await zipReader.loadAsync(file)
        const validFiles: string[] = []
        zipFile.forEach((zipFilePath) => {
          if (/(cl2|sd3)$/.test(zipFilePath.toLowerCase())) {
            validFiles.push(zipFilePath)
          }
        })
        if (validFiles.length === 0) {
          message.error(
            "Swimmingly only supports .sd3 and .cl2 files. Your zip file does not contain any supported files!",
            4,
          )
          return
        }
        const contentsPromise = zipFile.file(validFiles[0])?.async("string")
        if (contentsPromise) {
          fileContents = await contentsPromise
        } else {
          message.error(
            "You haven't provided a valid zip file. Your zip file must contain a .sd3 or .cl2 file.",
          )
          return
        }
      } else {
        fileContents = await file.text()
      }

      const sd3Validation = validateSd3(fileContents)
      setSd3Validation(sd3Validation)
      if (sd3Validation.dateOfBirthValid) {
        const parsedSd3 = parseSd3(fileContents)
        setSd3Contents(parsedSd3)
        setSd3LoadStep("club-map")
      } else {
        setSd3LoadStep("invalid-sd3")
      }
    },
    [setSd3Contents, setSd3LoadStep, setSd3Validation],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  return (
    <React.Fragment>
      <p>You are about to import entries for your meet.</p>
      <StyledDropZone {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <React.Fragment>
            <p>Drop the files here ...</p>
            <FaUpload style={{ fontSize: "1.75rem" }} />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <p>
              Drag and drop your .sd3 or .cl2 file here, or click to upload a
              file!
            </p>
            <FaUpload style={{ fontSize: "1.75rem" }} />
          </React.Fragment>
        )}
      </StyledDropZone>
    </React.Fragment>
  )
}

const StyledClubMappingDiv = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  align-items: center;
  p {
    padding: 0;
    margin: 0;
  }
`

function MapClubs({
  clubMapping,
  setClubMapping,
  clubNames,
  meetClubs,
}: {
  clubMapping: { [key: string]: { clubId: number; clubName: string } }
  setClubMapping: (newMapping: {
    [key: string]: { clubId: number; clubName: string }
  }) => void
  clubNames: string[]
  meetClubs: IClub[]
}) {
  const updateClubMapping = (clubFromSd3: string, sqlClubId: number) => {
    const newMapping = cloneDeep(clubMapping)
    if (sqlClubId === -2) {
      newMapping[clubFromSd3] = {
        clubId: -2,
        clubName: "do not import",
      }
    } else {
      if (
        Object.values(newMapping)
          .map((v) => v.clubId)
          .includes(sqlClubId)
      ) {
        for (let theKey of Object.keys(newMapping)) {
          if (newMapping[theKey]?.clubId === sqlClubId)
            delete newMapping[theKey]
        }
      }
      newMapping[clubFromSd3] = {
        clubId: sqlClubId,
        clubName:
          meetClubs.find((mC) => mC.clubId === sqlClubId)?.nameLong || "",
      }
    }
    setClubMapping(newMapping)
  }

  return (
    <React.Fragment>
      <p>
        For each club in your import file please select the corresponding club
        on the Swimmingly Clubhouse so that we can add the correct data to each
        club.
      </p>
      <StyledClubMappingDiv>
        <p>
          <b>Club From File</b>
        </p>
        <p>
          <b style={{ float: "right" }}>Select the Correct Team</b>
        </p>
      </StyledClubMappingDiv>
      {clubNames.map((clubName, idx) => {
        return (
          <StyledClubMappingDiv key={`club_${clubName}_${idx}`}>
            <p>{clubName}</p>
            <select
              value={clubMapping[clubName]?.clubId || -1}
              onChange={(e) =>
                updateClubMapping(clubName, parseInt(e.target.value, 10))
              }
            >
              <option disabled hidden value={-1}>
                choose club...
              </option>
              <option value={-2}>Do NOT Import This Club</option>
              {meetClubs.map((mC, idx2) => {
                return (
                  <option
                    key={`club_${clubName}_option_${mC.clubId}_${idx2}`}
                    value={mC.clubId}
                  >
                    {mC.nameLong}
                  </option>
                )
              })}
            </select>
          </StyledClubMappingDiv>
        )
      })}
    </React.Fragment>
  )
}

const ChooseSwimmersGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  row-gap: 5px;
  align-items: center;

  h3.club-title {
    text-align: center;
    grid-column: 1 / 3;
  }

  p {
    margin: 0;
    padding: 0;
  }

  .no-match {
    background-color: rgba(255, 204, 231, 0.225);
  }

  .no-match select {
    background-color: rgba(255, 204, 231, 0.225);
  }
`

function ChooseSwimmers({
  meetId,
  sd3Contents,
  setSd3Contents,
  setSd3LoadStep,
  clubMapping,
}: {
  meetId: number
  sd3Contents: ISd3Data
  setSd3Contents: SetSd3
  setSd3LoadStep: (
    newStep:
      | "file-drop"
      | "club-map"
      | "choose-swimmers"
      | "swimmer-exceptions"
      | "load-data",
  ) => void
  clubMapping: { [key: string]: { clubId: number; clubName: string } }
}) {
  const [clubSeasons, setClubSeasons] = useState<IClubSeasonInformation[]>([])
  const [sd3SwimmersWhoNeedMatches, setSd3SwimmersWhoNeedMatches] = useState<
    ISwimmerInSd3[]
  >([])
  const [unmatchedRosterSwimmers, setUnmatchedRosterSwimmers] = useState<
    { swimmer: IManageRosterSeasonSwimmer; clubId: number; clubName: string }[]
  >([])
  const [sd3ToRosterSwimmerMapping, setSd3ToRosterSwimmerMapping] = useState<
    ISwimmerInSd3[]
  >([])
  const noRenderSd3Contents = useNoRenderRef<ISd3Data>(sd3Contents)

  useEffect(() => {
    let mounted = true
    Sd3Manager.on("saveSwimmerMapping", () => {
      const newSd3Contents = cloneDeep(sd3Contents)
      for (let i = 0; i < sd3ToRosterSwimmerMapping.length; i++) {
        const swimmerToMap = sd3ToRosterSwimmerMapping[i]
        if (!swimmerToMap.swimmerId || swimmerToMap.swimmerId <= 0) {
          continue
        }
        const sd3Swimmer = newSd3Contents.swimmers.find(
          (sw) =>
            sw.swimmerFirstName.toLowerCase() ===
              swimmerToMap.swimmerFirstName.toLowerCase() &&
            sw.swimmerLastName.toLowerCase() ===
              swimmerToMap.swimmerLastName.toLowerCase() &&
            sw.swimmerDOB === swimmerToMap.swimmerDOB &&
            sw.swimmerGender === swimmerToMap.swimmerGender &&
            sw.club === swimmerToMap.club,
        )
        if (sd3Swimmer) {
          sd3Swimmer.swimmerId = swimmerToMap.swimmerId
        }
      }
      if (mounted) setSd3Contents(newSd3Contents)
    })
    return () => {
      Sd3Manager.removeAllListeners("saveSwimmerMapping")
      mounted = false
    }
  }, [sd3Contents, sd3ToRosterSwimmerMapping, setSd3Contents])

  useEffect(() => {
    if (meetId && meetId !== -1) {
      swimminglyApi
        .get(gen(`/api/getMeetInformation/${meetId}`))
        .then((data) => {
          if (data.status === "success") {
            setClubSeasons(data.clubSeasons)
          }
        })
    }
  }, [meetId])

  useEffect(() => {
    const newSd3Contents = cloneDeep(noRenderSd3Contents.current)
    const swimmersFromSd3 = newSd3Contents.swimmers
    const updateSwimmers: Promise<void>[] = []
    const newUnmatchedRosterSwimmers: {
      swimmer: IManageRosterSeasonSwimmer
      clubId: number
      clubName: string
    }[] = []
    // loop through the clubs in the meet, pull in their rosters, and try to match to swimmers in the sd3
    for (let i = 0; i < clubSeasons.length; i++) {
      const clubSeason = clubSeasons[i]
      const updateClubSeasonSwimmers = swimminglyApi
        .get(gen(`/api/getRosterForClubSeason/${clubSeason.clubSeasonId}`))
        .then(
          (data: {
            status: string
            swimmers: IManageRosterSeasonSwimmer[]
          }) => {
            // at this point we've pulled roster data for the ith clubSeason. now let's search the swimmers from our
            // sd3 to see if they match any of the swimmers we pulled from the clubhouse roster
            for (let j = 0; j < data.swimmers.length; j++) {
              const rosterSwimmer = data.swimmers[j]
              const sd3Swimmer = swimmersFromSd3.find((sw) => {
                // first filter the sd3 swimmers down to only those who are on the club we're looking at
                if (clubMapping[sw.club]?.clubId !== clubSeason.club.clubId)
                  return false
                const swimmerGender =
                  sw.swimmerGender.toLowerCase() === "f" ? "Girl" : "Boy"
                return (
                  (sw.swimmerFirstName.toLowerCase() ===
                    rosterSwimmer.firstName.toLowerCase() ||
                    sw.swimmerFirstName.split(" ")[0].toLowerCase() ===
                      rosterSwimmer.firstName.toLowerCase()) &&
                  sw.swimmerLastName.toLowerCase() ===
                    rosterSwimmer.lastName.toLowerCase() &&
                  sw.swimmerDOB === rosterSwimmer.dateOfBirth &&
                  swimmerGender === rosterSwimmer.gender
                )
              })
              if (sd3Swimmer) {
                sd3Swimmer.swimmerId = rosterSwimmer.swimmerId
              } else {
                newUnmatchedRosterSwimmers.push({
                  swimmer: rosterSwimmer,
                  clubId: clubSeason.club.clubId || -1,
                  clubName: clubSeason.club.nameLong || "",
                })
              }
            }
          },
        )
      updateSwimmers.push(updateClubSeasonSwimmers)
    }
    Promise.all(updateSwimmers).then(() => {
      const swimmersWithoutRosterMatches: ISwimmerInSd3[] =
        newSd3Contents.swimmers.filter(
          (sw) => !sw.swimmerId || sw.swimmerId <= 0,
        )
      setSd3Contents(newSd3Contents)
      setSd3SwimmersWhoNeedMatches(swimmersWithoutRosterMatches)
      setUnmatchedRosterSwimmers(newUnmatchedRosterSwimmers)
      if (swimmersWithoutRosterMatches.length === 0) {
        setSd3LoadStep("load-data")
      }
    })
  }, [
    noRenderSd3Contents,
    clubMapping,
    setSd3Contents,
    clubSeasons,
    setSd3LoadStep,
  ])

  const updateSwimmerMapping = useCallback(
    (swimmerInSd3: ISwimmerInSd3, sqlId: number) => {
      if (!sqlId || sqlId <= 0) {
        const newSd3ToRosterSwimmerMapping = sd3ToRosterSwimmerMapping.filter(
          (swimmer) =>
            swimmer.swimmerFirstName.toLowerCase() !==
              swimmerInSd3.swimmerFirstName.toLowerCase() ||
            swimmer.swimmerLastName.toLowerCase() !==
              swimmerInSd3.swimmerLastName.toLowerCase() ||
            swimmer.swimmerDOB !== swimmerInSd3.swimmerDOB ||
            swimmer.swimmerGender !== swimmerInSd3.swimmerGender ||
            swimmer.club !== swimmerInSd3.club,
        )
        setSd3ToRosterSwimmerMapping(newSd3ToRosterSwimmerMapping)
        return
      }
      // get rid of all swimmers that we've already mapped to the supplied sqlId
      const newSd3ToRosterSwimmerMapping = cloneDeep(
        sd3ToRosterSwimmerMapping,
      ).filter((swimmer) => swimmer.swimmerId !== sqlId)
      // now check whether the swimmer we've received input for is already in our swimmer mapping
      const swimmer = newSd3ToRosterSwimmerMapping.find(
        (sw) =>
          sw.swimmerFirstName.toLowerCase() ===
            swimmerInSd3.swimmerFirstName.toLowerCase() &&
          sw.swimmerLastName.toLowerCase() ===
            swimmerInSd3.swimmerLastName.toLowerCase() &&
          sw.swimmerDOB === swimmerInSd3.swimmerDOB &&
          sw.swimmerGender === swimmerInSd3.swimmerGender &&
          sw.club === swimmerInSd3.club,
      )
      if (swimmer) {
        // update the swimmer because they were already in the mapping
        swimmer.swimmerId = sqlId
      } else {
        // add the swimmer to the mapping because they weren't there
        newSd3ToRosterSwimmerMapping.push({ ...swimmerInSd3, swimmerId: sqlId })
      }
      setSd3ToRosterSwimmerMapping(newSd3ToRosterSwimmerMapping)
    },
    [sd3ToRosterSwimmerMapping, setSd3ToRosterSwimmerMapping],
  )

  return (
    <React.Fragment>
      <p>
        Several of the swimmers in the file you uploaded do not match any of the
        swimmers on your club's roster here in the Swimmingly Clubhouse. If you
        believe this is a mistake - please select the correct swimmers below:
      </p>
      <ChooseSwimmersGrid>
        {sd3SwimmersWhoNeedMatches
          .map((sw) => sw.club)
          .filter((v, i, a) => a.indexOf(v) === i)
          .map((sd3Club, idx0) => (
            <React.Fragment key={`club_swimmers_${sd3Club}_${idx0}`}>
              <h3 className="club-title">{clubMapping[sd3Club]?.clubName}</h3>
              <p>
                <b>Swimmer From Import File</b>
              </p>
              <p>
                <b>Swimmer On Swimmingly Roster</b>
              </p>
              {sd3SwimmersWhoNeedMatches
                .filter((swimmers) => swimmers.club === sd3Club)
                .map((sw, idx1) => {
                  return (
                    <React.Fragment key={`swimmer_${sw.swimmerId}_${idx1}`}>
                      <p>
                        {sw.swimmerFirstName} {sw.swimmerLastName} -{" "}
                        {sw.swimmerGender} {sw.swimmerDOB}
                      </p>
                      <select
                        className={
                          !sd3ToRosterSwimmerMapping.find(
                            (sd3Sw) =>
                              sd3Sw.swimmerFirstName.toLowerCase() ===
                                sw.swimmerFirstName.toLowerCase() &&
                              sd3Sw.swimmerLastName.toLowerCase() ===
                                sw.swimmerLastName.toLowerCase() &&
                              sd3Sw.swimmerDOB === sw.swimmerDOB &&
                              sd3Sw.swimmerGender === sw.swimmerGender &&
                              sd3Sw.club === sw.club,
                          )
                            ? "no-match"
                            : "match"
                        }
                        value={
                          sd3ToRosterSwimmerMapping.find(
                            (sd3Sw) =>
                              sd3Sw.swimmerFirstName.toLowerCase() ===
                                sw.swimmerFirstName.toLowerCase() &&
                              sd3Sw.swimmerLastName.toLowerCase() ===
                                sw.swimmerLastName.toLowerCase() &&
                              sd3Sw.swimmerDOB === sw.swimmerDOB &&
                              sd3Sw.swimmerGender === sw.swimmerGender &&
                              sd3Sw.club === sw.club,
                          )?.swimmerId || -1
                        }
                        onChange={(e) => {
                          updateSwimmerMapping(sw, parseInt(e.target.value, 10))
                        }}
                      >
                        <option className="no-match" value={-1}>
                          Swimmer Is Not In The Clubhouse
                        </option>
                        {unmatchedRosterSwimmers
                          .filter(
                            (uRS) =>
                              uRS.clubId === clubMapping[sw.club]?.clubId,
                          )
                          .map((uRS, idx2) => {
                            return (
                              <option
                                key={`swimmer_option_${uRS.swimmer.swimmerId}_${idx1}_${idx2}`}
                                value={uRS.swimmer.swimmerId}
                              >
                                {uRS.swimmer.firstName} {uRS.swimmer.lastName}
                              </option>
                            )
                          })}
                      </select>
                    </React.Fragment>
                  )
                })}
            </React.Fragment>
          ))}
      </ChooseSwimmersGrid>
    </React.Fragment>
  )
}

export type MeetEvent = {
  distance: string
  gender: "Boys" | "Girls" | "Mixed"
  ageGroup: string
  eventNumber: number
  stroke: string
  isRelay: 0 | 1
  course: "SCY" | "SCM" | "LCM"
  eventName: string
}

export default function ImportEntriesModal({
  visible,
  setVisible,
  meetId,
  refresh,
}: IImportEntriesModalProps): JSX.Element {
  const [loading, setLoading] = useState(false)
  const [sd3Contents, setSd3Contents] = useState<ISd3Data | null>(null)
  const [sd3Validation, setSd3Validation] = useState<ISd3Validation>({
    dateOfBirthValid: true,
    invalidBirthdaySwimmers: [],
  })
  const [clubMapping, setClubMapping] = useState<{
    [key: string]: { clubId: number; clubName: string }
  }>({})
  const [sd3LoadStep, setSd3LoadStep] =
    useState<
      | "file-drop"
      | "club-map"
      | "choose-swimmers"
      | "swimmer-exceptions"
      | "load-data"
      | "invalid-sd3"
    >("file-drop")
  const [meetClubs, setMeetClubs] = useState<IClub[]>([])
  const [okButtonDisabled, setOkButtonDisabled] = useState(true)
  const [meetEventOrder, setMeetEventOrder] = useState<MeetEvent[]>([])
  const [eventOrderErrors, setEventOrderErrors] = useState<string[]>([])

  useEffect(() => {
    let mounted = true
    if (meetId && meetId > 0) {
      swimminglyApi.get(gen(`/api/getMeetEvents/${meetId}`)).then((data) => {
        if (data.status === "success" && mounted) {
          setMeetEventOrder(data.events)
        }
      })
    }
    return () => {
      mounted = false
    }
  }, [meetId, visible])

  useEffect(() => {
    const newEventOrderErrors: string[] = []
    const sd3Events = sd3Contents?.meetEvents
    if (sd3Events) {
      for (let i = 0; i < sd3Events.length; i++) {
        const sd3EventDescr = `Event ${sd3Events[i].eventNumber} ${sd3Events[i].gender} ${sd3Events[i].distance} ${sd3Events[i].stroke}`
        let foundMatch = false
        for (let j = 0; j < meetEventOrder.length; j++) {
          const meetEventDescr = `Event ${meetEventOrder[j].eventNumber} ${meetEventOrder[j].gender} ${meetEventOrder[j].distance} ${meetEventOrder[j].stroke}`
          if (sd3EventDescr === meetEventDescr) {
            foundMatch = true
            break
          }
        }
        if (foundMatch === false) {
          const eventNum = sd3Events[i].eventNumber
          const meetEvent = meetEventOrder.find(
            (mEO) => mEO.eventNumber === eventNum,
          )
          if (!meetEvent) {
            newEventOrderErrors.push(
              `This meet isn't set up with an event # ${eventNum}, but the file you're uploading has ${sd3EventDescr}`,
            )
          } else {
            newEventOrderErrors.push(
              `Expected to find ${meetEvent.eventName}, but the file you're uploading has ${sd3EventDescr}`,
            )
          }
        }
      }
    }
    setEventOrderErrors(newEventOrderErrors)
  }, [meetEventOrder, sd3Contents])

  useEffect(() => {
    setLoading(false)
    setSd3LoadStep("file-drop")
    setSd3Contents(null)
    setMeetClubs([])
    setClubMapping({})
    setOkButtonDisabled(true)
    if (!visible) {
      setMeetEventOrder([])
    }
    setEventOrderErrors([])
  }, [visible])

  useEffect(() => {
    if (visible && meetId && meetId !== -1) {
      swimminglyApi.get(gen(`/api/getMeetClubs/${meetId}`)).then((data) => {
        if (data.status === "success") {
          setMeetClubs(data.meetClubs)
        }
      })
    } else {
      setMeetClubs([])
    }
  }, [meetId, visible])

  useEffect(() => {
    let newOkButtonDisabled = true
    if (sd3LoadStep === "club-map") {
      if (Object.keys(clubMapping).length === sd3Contents?.clubNames.length) {
        newOkButtonDisabled = false
      }
      if (newOkButtonDisabled !== okButtonDisabled)
        setOkButtonDisabled(newOkButtonDisabled)
    } else if (
      sd3LoadStep === "choose-swimmers" &&
      okButtonDisabled !== false
    ) {
      setOkButtonDisabled(false)
    } else if (sd3LoadStep === "load-data") {
      setOkButtonDisabled(false)
    }
  }, [
    okButtonDisabled,
    sd3LoadStep,
    clubMapping,
    sd3Contents?.clubNames.length,
  ])

  const getSwimmersWhoWillNotBeUploaded = useCallback((): ISwimmerInSd3[] => {
    if (!sd3Contents?.swimmers) return []
    const allSd3Swimmers = sd3Contents?.swimmers
    const unmappedSd3Swimmers = allSd3Swimmers.filter(
      (sd3Swimmer) => sd3Swimmer.swimmerId === -1,
    )
    const unmappedSd3SwimmersOnPayingClubs = unmappedSd3Swimmers.filter(
      (unmappedSwimmer) =>
        meetClubs.find(
          (mC) => mC.clubId === clubMapping[unmappedSwimmer.club].clubId,
        )?.isSwimminglyCustomer === 1,
    )
    unmappedSd3SwimmersOnPayingClubs.sort((sw1, sw2) => {
      if (clubMapping[sw1.club].clubName > clubMapping[sw2.club].clubName)
        return 1
      if (clubMapping[sw1.club].clubName < clubMapping[sw2.club].clubName)
        return -1
      if (sw1.swimmerLastName > sw2.swimmerLastName) return 1
      if (sw1.swimmerLastName < sw2.swimmerLastName) return -1
      if (sw1.swimmerFirstName > sw2.swimmerFirstName) return 1
      if (sw1.swimmerFirstName < sw2.swimmerFirstName) return -1
      return 1
    })
    return unmappedSd3SwimmersOnPayingClubs
  }, [sd3Contents?.swimmers, meetClubs, clubMapping])

  return (
    <Modal
      title="Import Entries"
      visible={visible}
      onCancel={() => setVisible(false)}
      onOk={() => {
        if (eventOrderErrors.length > 0) {
          setVisible(false)
          return
        }
        if (sd3LoadStep === "club-map") {
          const newClubMapping = cloneDeep(clubMapping)
          const newSd3Contents = cloneDeep(sd3Contents)
          for (let theKey of Object.keys(newClubMapping)) {
            if (newClubMapping[theKey].clubId === -2) {
              if (newSd3Contents?.clubNames) {
                newSd3Contents.clubNames = newSd3Contents.clubNames.filter(
                  (cN) => cN !== theKey,
                )
              }
              if (newSd3Contents?.swimmers) {
                newSd3Contents.swimmers = newSd3Contents.swimmers.filter(
                  (sw) => sw.club !== theKey,
                )
              }
              if (newSd3Contents?.swimData.clubs) {
                newSd3Contents.swimData.clubs =
                  newSd3Contents.swimData.clubs.filter(
                    (cl) => cl.teamFullName !== theKey,
                  )
              }
              delete newClubMapping[theKey]
            }
          }
          setClubMapping(newClubMapping)
          setSd3Contents(newSd3Contents)
          setSd3LoadStep("choose-swimmers")
        } else if (sd3LoadStep === "choose-swimmers") {
          Sd3Manager.emit("saveSwimmerMapping")
          setSd3LoadStep("load-data")
        } else if (sd3LoadStep === "load-data") {
          if (Object.keys(clubMapping).length === 0) {
            setVisible(false)
            return
          }
          if (loading) return
          setLoading(true)
          swimminglyApi
            .post(gen(`/api/createMeetEntries/${meetId}`))
            .body({
              sd3Contents,
              clubMapping,
            })
            .then((data) => {
              if (data.status === "success") {
                message.success("Success!")
              } else {
                message.error(
                  "Uh oh, we had an issue loading your meet entries.",
                )
              }
            })
            .catch((_err) => {
              message.error(
                "Uh oh, something went wrong loading your meet entries.",
              )
            })
            .finally(() => {
              setLoading(false)
              setVisible(false)
              refresh()
            })
        } else if (sd3LoadStep === "invalid-sd3") {
          setVisible(false)
        }
      }}
      okButtonProps={{ disabled: okButtonDisabled, loading }}
      okText={
        eventOrderErrors.length > 0 || sd3LoadStep === "load-data"
          ? "Ok"
          : "Next"
      }
    >
      {loading && <Skeleton />}
      {sd3LoadStep === "file-drop" && (
        <FileDropZone
          setSd3Contents={setSd3Contents}
          setSd3LoadStep={setSd3LoadStep}
          setSd3Validation={setSd3Validation}
        />
      )}
      {sd3LoadStep === "invalid-sd3" && (
        <React.Fragment>
          <p>
            <span
              style={{
                backgroundColor: "#f50",
                color: "white",
                padding: "3px",
                borderRadius: "3px",
              }}
            >
              There was a problem uploading your file:
            </span>
          </p>
          {sd3Validation.invalidBirthdaySwimmers.length > 0 && (
            <div>
              <p>
                The following swimmers in your import file{" "}
                <b>do not have birthdays</b>:
              </p>
              <ul>
                {sd3Validation.invalidBirthdaySwimmers.map(
                  (invalidSwimmer, idx) => {
                    return (
                      <li key={`invalid_swimmer_${idx}`}>
                        {invalidSwimmer.name} - file rows:{" "}
                        {invalidSwimmer.sd3Rows.join(", ")}
                      </li>
                    )
                  },
                )}
              </ul>
            </div>
          )}
        </React.Fragment>
      )}
      {sd3LoadStep === "club-map" &&
        eventOrderErrors.length > 0 &&
        eventOrderErrors.map((err, idx) => <p key={`error_${idx}`}>{err}</p>)}
      {sd3LoadStep === "club-map" &&
        eventOrderErrors.length === 0 &&
        sd3Contents && (
          <MapClubs
            clubMapping={clubMapping}
            setClubMapping={setClubMapping}
            clubNames={sd3Contents.clubNames}
            meetClubs={meetClubs}
          />
        )}
      {sd3LoadStep === "choose-swimmers" && sd3Contents && (
        <ChooseSwimmers
          meetId={meetId}
          sd3Contents={sd3Contents}
          setSd3Contents={setSd3Contents}
          setSd3LoadStep={setSd3LoadStep}
          clubMapping={clubMapping}
        />
      )}
      {sd3LoadStep === "load-data" && !loading && sd3Contents && (
        <React.Fragment>
          {Object.keys(clubMapping).length > 0 ? (
            <p>
              <b>You are about to load entries</b> for your swim meet. If you
              click "Ok", you will overwrite entries for all of the teams
              supplied in your upload file.
            </p>
          ) : (
            <p>You have chosen not to import any clubs!</p>
          )}
          {getSwimmersWhoWillNotBeUploaded().length > 0 && (
            <p>
              Additionally, <b>the following swimmers will not be uploaded</b>{" "}
              with your meet entries because they are not on the roster for
              their respective clubs.
            </p>
          )}
          {getSwimmersWhoWillNotBeUploaded().map((unMappedSwimmer, idx) => (
            <p
              key={`${unMappedSwimmer.swimmerFirstName}${unMappedSwimmer.swimmerLastName}_${idx}`}
            >
              {unMappedSwimmer.swimmerFirstName}{" "}
              {unMappedSwimmer.swimmerLastName} -{" "}
              {clubMapping[unMappedSwimmer.club].clubName}
            </p>
          ))}
        </React.Fragment>
      )}
    </Modal>
  )
}

// get clubs in meet
// parse the sd3
