import React from "react"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../../appData/types"
import ActionsMenu from "../ActionsMenu"
// import BestTimesMatrixButton from "../BestTimesMatrixButton"
import HoverListItem from "../HoverListItem"
import BestTimesMatrixButton from "../BestTimesMatrixButtonNew"
import QuickStartMyMeetLineUp from "../QuickStartMyMeetLineup"
import PrintLineUp from "../PrintLineUp"
import ExportMeetEntries from "../ExportMeetEntries"
import { actionItemStyle, gen, RoleHelper, swimminglyApi } from "../utils"
import { message, Tooltip } from "antd"
import MergeHeatSheetButton from "../MergeHeatSheetButton"

export default function ActionsDropdown({
  actionsVisible,
  setActionsVisible,
  meetId,
  refreshSwimmers,
  showHideExportEntriesModal,
}: {
  actionsVisible: boolean
  setActionsVisible: (newVisible: boolean) => void
  meetId: number
  refreshSwimmers: () => void
  showHideExportEntriesModal: (newVisible: boolean) => void
}) {
  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)
  const impersonateClub = useStoreState(
    (state: StateMapper<AppDataStore>) => state.aliasedClub,
  )

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ActionsMenu visible={actionsVisible} setVisible={setActionsVisible}>
        <React.Fragment>
          <HoverListItem>
            <BestTimesMatrixButton
              meetId={meetId}
              clubId={impersonateClub?.clubId || -1}
              closeMenu={() => setActionsVisible(false)}
            />
          </HoverListItem>
          <HoverListItem>
            <QuickStartMyMeetLineUp
              meetId={meetId}
              clubId={impersonateClub?.clubId || -1}
              refreshSwimmers={refreshSwimmers}
              closeMenu={() => setActionsVisible(false)}
            />
          </HoverListItem>
          <HoverListItem>
            <PrintLineUp
              meetId={meetId}
              clubId={impersonateClub?.clubId || -1}
              isLineUp={true}
              closeMenu={() => setActionsVisible(false)}
            />
          </HoverListItem>
          {impersonateClub?.canExportEntries === 1 && (
            <HoverListItem>
              <ExportMeetEntries
                meetId={meetId}
                showModal={showHideExportEntriesModal}
                closeMenu={() => setActionsVisible(false)}
              />
            </HoverListItem>
          )}
          {new RoleHelper(user?.role).isAnyAdmin() && (
            <HoverListItem>
              <div
                onClick={() => {
                  if (meetId) {
                    swimminglyApi
                      .get(gen(`/api/updateMeetSeedTimes/${meetId}`))
                      .then((data) => {
                        if (data.status === "success") {
                          message.success("Entry times reset to current best times!")
                          refreshSwimmers()
                        }
                      })
                  } else {
                    message.warning("No meet id", 4)
                  }
                }}
              >
                <Tooltip
                  title="Reset your entry times for this meet to currently available best 
                  times for all entries/swimmers. Existing entries for this meet will be updated to reflect current best time."
                  placement="left">
                <span style={actionItemStyle}>Reset Entry Times to Current Best Times</span>
                </Tooltip>
              </div>
            </HoverListItem>
          )}
        </React.Fragment>
      </ActionsMenu>
      <MergeHeatSheetButton
        meetId={meetId}
        clubId={impersonateClub?.clubId || -1}
      />
    </div>
  )
}
