import React, { useState, useEffect } from "react"
import moment from "moment-timezone"

interface ISwimmerMeetResult {
    age_group: string,
    better_time: string,
    diff2: number | "-",
    distance: string,
    event_name: string,
    is_converted2: boolean,
    meet_course: string,
    meet_date: string,
    meet_id: number,
    meet_name: string,
    name: string,
    place: number,
    points: number,
    prevBestCourse: number,
    prevBestFormatted: string,
    race_type: number,
    stroke: string,
    time: number,
    unit_measure: number,
}
interface ISingleMeetResult {
    swimmerMeetResults: ISwimmerMeetResult[]
    expanded: boolean
}

export default function SingleMeetResult({ swimmerMeetResults, expanded }: ISingleMeetResult) {
    const [isExpanded, setIsExpanded] = useState(expanded)
    const [windowWidth, setWindowWidth] = useState<number>(0)

    useEffect(() => {
        setWindowWidth(window.innerWidth)
    }, [window.innerWidth])


    const renderHeader = () => {
        if (!swimmerMeetResults || swimmerMeetResults.length === 0) {
            return null
        }

        const firstSwimmerMeetResult = swimmerMeetResults[0]

        return (
            <h4
                style={{
                    backgroundColor: "#1589CA",
                    color: "white",
                    borderRadius: "5px",
                    padding: "12px",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <span>
                    - {firstSwimmerMeetResult.meet_name} |{" "}
                    {moment(firstSwimmerMeetResult.meet_date, "YYYY-MM-DD").format("MM-DD-YYYY")} |{" "}
                    {firstSwimmerMeetResult.meet_course}
                </span>
                <span
                    style={{ marginLeft: "10px", cursor: "pointer" }}
                    onClick={() => setIsExpanded(!isExpanded)}
                    dangerouslySetInnerHTML={
                        isExpanded ? { __html: "&#9650;" } : { __html: "&#9660;" }
                    }
                />
            </h4>
        )
    }

    const renderMobile = () => {
        return (
            <div style={{ marginBottom: "15px" }}>
                {renderHeader()}
                {isExpanded && (
                    <div className="form-group ">
                        <table
                            className="table table-bordered table-striped mb-none"
                            id="datatable-default"
                        >
                            <thead>
                                <tr style={{ backgroundColor: "#32BFE8" }}>
                                    <th>Event</th>
                                    <th>Time</th>
                                    <th>Prev best </th>
                                    <th>Impr</th>
                                    <th>Place</th>
                                </tr>
                            </thead>
                            <tbody>
                                {swimmerMeetResults.map((swimmerMeetResult, index) => {
                                    return (
                                        <tr
                                            key={index}
                                            style={{
                                                backgroundColor: swimmerMeetResult.diff2 < 0 ? "#C7FFC1" : undefined
                                            }}
                                        >
                                            <td> {swimmerMeetResult.event_name}</td>
                                            <td> {swimmerMeetResult.better_time}</td>
                                            <td>
                                                {swimmerMeetResult.is_converted2
                                                    ? `*${swimmerMeetResult.prevBestFormatted}`
                                                    : swimmerMeetResult.prevBestFormatted}
                                            </td>
                                            <td> {swimmerMeetResult.diff2 === "-" ? "-" : swimmerMeetResult.diff2.toFixed(2)} </td>
                                            <td> {swimmerMeetResult.place} </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <div
                            style={{
                                display: "flex",
                                fontWeight: 700,
                                fontSize: "10px",
                                justifyContent: "flex-end",
                            }}
                        >
                            *Indicates time has already been converted to course of meet you
                            are viewing. <br />
                            Green highlight indicates personal best in that event!
                        </div>
                    </div>
                )}
            </div>
        )
    }
    const renderWeb = () => {
        return (
            <div style={{ marginBottom: "15px" }}>
                {renderHeader()}
                {isExpanded && (
                    <div className="form-group ">
                        <table
                            className="table table-bordered table-striped mb-none"
                            id="datatable-default"
                        >
                            <thead>
                                <tr style={{ backgroundColor: "#32BFE8" }}>
                                    <th>Event</th>
                                    <th>Time</th>
                                    <th>Prev best </th>
                                    <th>Impr</th>
                                    <th>Place</th>
                                    <th>Points</th>
                                    <th>Age Group</th>
                                </tr>
                            </thead>
                            <tbody>
                                {swimmerMeetResults.map((swimmerMeetResult, index) => {
                                    return (
                                        <tr
                                            key={index}
                                            style={
                                                {
                                                    backgroundColor: swimmerMeetResult.diff2 < 0 ? "#C7FFC1" : undefined
                                                }
                                            }
                                        >
                                            <td> {swimmerMeetResult.event_name}</td>
                                            <td> {swimmerMeetResult.better_time}</td>
                                            <td>
                                                {swimmerMeetResult.is_converted2
                                                    ? `*${swimmerMeetResult.prevBestFormatted}`
                                                    : swimmerMeetResult.prevBestFormatted}
                                            </td>
                                            <td> {swimmerMeetResult.diff2 === "-" ? "-" : swimmerMeetResult.diff2.toFixed(2)} </td>
                                            <td> {swimmerMeetResult.place} </td>
                                            <td> {swimmerMeetResult.points} </td>
                                            <td> {swimmerMeetResult.age_group}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <div
                            style={{
                                display: "flex",
                                fontWeight: 700,
                                fontSize: "10px",
                                justifyContent: "flex-end",
                            }}
                        >
                            *Indicates time has already been converted to course of meet you
                            are viewing. <br />
                            Green highlight indicates personal best in that event!
                        </div>
                    </div>
                )}
            </div>
        )
    }

    return (
        <>
            {
                windowWidth <= 800 ? renderMobile() : renderWeb()
            }
        </>
    )
}
