import { Fragment, FunctionComponent, useCallback, useEffect, useState } from "react"
import { SingleWaiver } from "./SingleWaiver"
import {  IWaiver, useClubSeasonManagementContext } from "../ClubSeasonManagement/sharedState"
import { GuardianWaiverResponse } from "../ClubSeasonManagement/RegistrationConfiguration/RegistrationHelper"
import { GuardianWaiverMonitor } from "../Helpers/GuardianWaiverMonitor"
import { RegistrationWaiverHelper } from "../ClubSeasonManagement/RegistrationWaivers"
import { IClubSeasonInfo } from "../utils"


interface IWaiversAndDisclosuresProps {
  waiverResponses?: Record<string,GuardianWaiverResponse>
  waiverMonitor?: GuardianWaiverMonitor
  selectedClubSeason: IClubSeasonInfo

}


export const WaiversAndDisclosures: FunctionComponent<IWaiversAndDisclosuresProps> = ({waiverResponses, waiverMonitor, selectedClubSeason}) => {

  const [expandedWaiverId, setExpandedWaiverId] = useState<number>()
  const [filteredWaivers, setFilteredWaivers] = useState<IWaiver[]>([])

  const getFilteredWaivers = async (clubId: number, seasonId: number) => {
    const waiverHelper: RegistrationWaiverHelper = new RegistrationWaiverHelper()
    const waiversWithSections = await waiverHelper.getWaiversWithSections(clubId, seasonId)
    const filteredWaiversWithSections = waiversWithSections.filter( (waiver)=> Boolean(waiver.waiverEnabled)) 
    setFilteredWaivers(filteredWaiversWithSections)
  }

  useEffect(() => {
    if (selectedClubSeason) {
      getFilteredWaivers(selectedClubSeason.clubId, selectedClubSeason.seasonId)
    }
  }, [selectedClubSeason])


  return (
    <Fragment>
      <h4>Waivers and Disclosures</h4>
      
      {
        filteredWaivers.map((waiver) => {
        return(
          <SingleWaiver waiver={waiver} waiverResponses={waiverResponses} waiverMonitor={waiverMonitor} setExpandedWaiverId={setExpandedWaiverId} expandedWaiverId={expandedWaiverId}  />
        )
        })
      }
    </Fragment>
  )
}
