import React, { useState, useEffect } from "react"
import { Button, message } from "antd"
import styled from "styled-components"
import { debounce } from "lodash"
import GenericModal from "./GenericModal"
import { screenSizes } from "../styles/GlobalStyles"
import SearchInput from "./SearchInput"
import CenterSpin from "./CenterSpin"
import { swimminglyApi, gen, fuzzyRank } from "./utils"

const EventOrderTable = styled.table`
  /* border-collapse: collapse; */
  width: 95%;
  max-width: 1200px;
  margin: auto;

  td,
  th {
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: var(--primaryblue);
    color: white;
  }

  td.event-order-name {
    max-width: 300px;
  }
`

type EventOrder = {
  eventOrderId: number
  name: string
  leagueId: number | null
  league: string | null
  clubId: number | null
  club: string | null
  numEvents: number
}

type Club = {
  clubId: number
  name: string
  league: string
  address: string
}

type League = {
  leagueId: number
  name: string
  state: string | null
}

export default function CopyEventOrder() {
  const [loading, setLoading] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [searchString, setSearchString] = useState("")
  const [selectedId, setSelectedId] = useState<number | null>(null)
  const [eventOrders, setEventOrders] = useState<Array<EventOrder>>([])
  const [clubOrLeague, setClubOrLeague] = useState("club")
  const [copyTo, setCopyTo] = useState<number | null>(null)
  const [allClubs, setAllClubs] = useState<Array<Club>>([])
  const [allLeagues, setAllLeagues] = useState<Array<League>>([])

  useEffect(() => {
    if (modalVisible === false) {
      setLoading(true)
      swimminglyApi
        .get(gen("/api/getAllEventOrders"))
        .then((data) => {
          setEventOrders(data.eventOrders)
        })
        .catch((err) =>
          message.error("something went wrong finding event orders"),
        )
        .finally(() => setLoading(false))
      swimminglyApi
        .get(gen("/api/getAllClubs"))
        .then((data) => setAllClubs(data.clubs))
      swimminglyApi.get(gen("/api/getAllLeagues")).then((data) => {
        console.log("data", data)
        const leagues = data.leaguesArr.map(
          (league: { [key: string]: any }) => ({
            leagueId: league.id,
            name: league.name,
            state: league.StateID,
          }),
        )
        setAllLeagues(leagues)
      })
    }
  }, [modalVisible])

  const onSearchChange = debounce((val: string) => {
    setSearchString(val)
  }, 350)

  let sortedEventOrders = [...eventOrders]
  if (searchString !== "") {
    sortedEventOrders = fuzzyRank(
      sortedEventOrders,
      (eventOrder) => [
        eventOrder.name,
        `${eventOrder.eventOrderId}`,
        eventOrder.league || "",
        eventOrder.club || "",
        `${eventOrder.league || ""} ${eventOrder.name}`,
        `${eventOrder.club || ""} ${eventOrder.name}`,
      ],
      searchString,
    )
  }

  return (
    <>
      <section className="panel">
        <header className="panel-heading">
          <h2 className="panel-title">Copy Event Order</h2>
        </header>
        <div className="ourpanel-body">
          {loading ? (
            <CenterSpin />
          ) : (
            <div>
              <p>Click on an event order in the table below to copy it. </p>
              <SearchInput onChange={(e) => onSearchChange(e.target.value)} />
              <EventOrderTable>
                <thead>
                  <tr>
                    <th>eventOrderId</th>
                    <th>name</th>
                    <th>league</th>
                    <th>club</th>
                    <th>actions</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedEventOrders.map((eventOrder) => {
                    return (
                      <tr key={eventOrder.eventOrderId}>
                        <td>{eventOrder.eventOrderId}</td>
                        <td className="meet-name">{eventOrder.name}</td>
                        <td>{eventOrder.league}</td>
                        <td>{eventOrder.club}</td>
                        <td>
                          <button
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSelectedId(eventOrder.eventOrderId)
                              setModalVisible(true)
                            }}
                          >
                            Copy
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </EventOrderTable>
            </div>
          )}
        </div>
      </section>
      <GenericModal
        visible={modalVisible}
        width="min(max(30%,550px),95%)"
        maxWidth={`${Math.round(screenSizes.big * 0.65)}`}
        setVisible={setModalVisible}
        title={`Copy Event Order`}
        footer={() => (
          <>
            <Button
              key="close"
              onClick={() => {
                setSelectedId(null)
                setModalVisible(false)
              }}
            >
              Close
            </Button>
            <Button
              key="ok"
              onClick={async () => {
                let body
                if (clubOrLeague === "club") {
                  body = { eventOrderId: selectedId, clubId: copyTo }
                } else {
                  body = { eventOrderId: selectedId, leagueId: copyTo }
                }
                await swimminglyApi
                  .post(gen("/api/copyEventOrder"))
                  .body(body)
                  .then(() => {
                    message.success("Successfully copied event order")
                  })
                  .catch((err) => message.error("Uh oh, something went wrong"))
                setSelectedId(null)
                setModalVisible(false)
              }}
            >
              Ok
            </Button>
          </>
        )}
      >
        <div>
          <span>Do you want to copy this event order to a </span>
          <select
            style={{ padding: 0, height: "25px" }}
            value={clubOrLeague}
            onChange={(e) => {
              setClubOrLeague(e.target.value)
            }}
          >
            <option value="club">Club</option>
            <option value="league">League</option>
          </select>
          <br />
          <p style={{ padding: 0, marginTop: "5px", marginBottom: 0 }}>
            Which {clubOrLeague}?
          </p>
          <select
            style={{
              padding: 0,
              marginTop: 0,
              height: "25px",
              minWidth: "225px",
              maxWidth: "95%",
            }}
            value={copyTo || 0}
            onChange={(e) => {
              setCopyTo(parseInt(e.target.value))
            }}
          >
            <option value={0}>Select {clubOrLeague}...</option>
            {clubOrLeague === "club"
              ? allClubs.map((club) => {
                  return (
                    <option key={club.clubId} value={club.clubId}>
                      {club.name} - {club.league}
                    </option>
                  )
                })
              : allLeagues.map((league) => {
                  return (
                    <option key={league.leagueId} value={league.leagueId}>
                      {league.name}
                    </option>
                  )
                })}
          </select>
        </div>
      </GenericModal>
    </>
  )
}
