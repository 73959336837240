/* eslint-disable no-loop-func */
import { jsPDF } from "jspdf";
import './MuseoSansBinary.js'; // import the custom font binary
import { upperFirst } from "lodash";


function generateHeatSheetPDF(meet, props, numColumns) {
    const doc = new jsPDF();

    //set the number of lanes for the meet
    const NUM_LANES = meet.numLanes;

    // Set the margins and calculate the width of the content area
    const margin = 5;
    const pageWidth = doc.internal.pageSize.getWidth() - 10; // Adjusting for left and right margins
    const columnWidth = (pageWidth - ((numColumns - 1) /*margin*/)) / numColumns;

    let currentColumn = 0;
    let yPos = 30;

    // Function to draw grid lines and headers on each page
    const drawGridAndHeaders = () => {
        for (let i = 1; i < numColumns; i++) {
            doc.line((i * columnWidth) + margin, 27, (i * columnWidth) + margin, 290);
        }

        // Set font size and style for meet title and date
        doc.setFontSize(12);
        doc.setFont("MuseoSansRounded-500", "normal");

        // Centering the meet title and location
        let title = meet.meetName;
        const maxTitleLength = pageWidth; // Maximum length in characters
        if (title.length > maxTitleLength) {
            title = title.substring(0, maxTitleLength - 3) + '...';
        }
        const titleWidth = doc.getTextWidth(title);
        const titleX = (pageWidth + (2 * margin) - titleWidth) / 2;
        doc.text(title, titleX, 15);

        // Centering the meet date
        const dateWidth = doc.getTextWidth(meet.meetDate);
        const dateX = (pageWidth + (2 * margin) - dateWidth) / 2;
        doc.text(meet.meetDate, dateX, 20);

        //left center the swimminglyFan info
        doc.setFontSize(8);
        doc.text("Download SwimminglyFan",5,8);
        doc.text("(iOS / Android), paperless heat sheets & results",5,11);
    };

    // Function to check page height and add a new page or column if needed
    const checkPageHeight = (requiredSpace) => {

        // console.log("Required Space:" + requiredSpace);
        // console.log("Y Position:" + yPos);
        // console.log("Need to add new column or page:" + ((requiredSpace + yPos) > 290));
        // console.log("________________________");

        if (yPos + requiredSpace > 285) {
            yPos = 30;
            currentColumn++;
            if (currentColumn >= numColumns) {
                currentColumn = 0;
                doc.addPage();
                drawGridAndHeaders();
            }
        }
    };

    //check how many entrants are in the heat and addjust the space accordingly
    const checkRelayHeatHeight = (heat) => {
        let requiredSpace = (1 + NUM_LANES) * 6;
        let numEntrants = heat.entrants.length;
        if (numEntrants < NUM_LANES) {
            requiredSpace = ((1 + numEntrants) * 6) + ((1 + NUM_LANES - numEntrants) * 3);
        }

        return requiredSpace;
    }
    

    // Predefine header widths outside the loop to avoid "unsafe references" warning
    // relay headers: Lane Team Relay SeedTime
    // non-relay headers: Lane Name Age Team ID SeedTime
    
    //3 column specific header widths
    const headerWidths3ColumnsRelay = [7, 7, 38, 12];
    const eventHeaders3ColumnsRelay = [7, 7, 38, 12];
    const headerWidths3Columns = [7, 23, 7, 8, 7, 12];
    const eventHeaders3Columns = [7, 23, 7, 8, 7, 12];

    //need to change these four to fix 2 column heat sheet
    //2 column specific header widths
    const headerWidths2ColumnsRelay = [10, 10, 57, 18];
    const eventHeaders2ColumnsRelay = [10, 10, 57, 18];
    const headerWidths2Columns = [10, 34, 10, 10, 10, 18];
    const eventHeaders2Columns = [10, 34, 10, 10, 10, 18];

    // Initial grid/headers drawing
    drawGridAndHeaders();

    meet.events.forEach((event, index) => {

        // Check if the event is a relay
        const isRelay = event.isRelay === 1;

        // Calculate the required space for the event title, headers, and heats
        const eventHeaderSpace = 15; // Space for the event title and headers
        const requiredSpace = isRelay ? eventHeaderSpace + (1 + NUM_LANES) * 6 : eventHeaderSpace + (1 + NUM_LANES) * 3; // 1 line for heat title, NUM_LANES lines for participants

        // Ensure the event title, headers, and first heat are not split between pages or columns
        checkPageHeight(requiredSpace);

        // Update column position after checking page height
        const columnStartX = margin + (currentColumn * (columnWidth)); // Start X position for the current column
        const columnEndX = margin + ((currentColumn + 1) * (columnWidth)); // End X position for the current column

        // Set font size and style for event title
        if (numColumns === 3) {
            doc.setFontSize(7);
            doc.setFont("times", "bold");
        } else {
            doc.setFontSize(10);
            doc.setFont("times", "bold");
        }

        const eventName = `#${event.number} ${event.gender} ${event.ageGroup} ${event.distance}${event.course} ${event.stroke}`;
        doc.text(eventName, columnStartX + 2, yPos); // Adjusted to add some padding from the left

        yPos += 2;
        // Draw a line before the event headers, spanning the entire column width
        doc.line(columnStartX, yPos, columnEndX, yPos);
        yPos += 3;

        // Add headers for participant data
        let headers = isRelay ? ['Lane', 'Team', 'Relay', 'Seed Time'] : ['Lane', 'Name', 'Age', 'Team', 'ID', 'Seed Time'];
        let headerWidths;
        if (numColumns === 2) {
            headerWidths = isRelay ? eventHeaders2ColumnsRelay : eventHeaders2Columns; // Adjusted widths for 2 columns
        } else {
            headerWidths = isRelay ? eventHeaders3ColumnsRelay : eventHeaders3Columns; // Adjusted widths for 3 columns
        }

        let headerX = columnStartX + 2; // Adjusted to add some padding from the left
        const headerY = yPos;

        if(isRelay) {
            headers.forEach((header, headerIdx) => {
                if (numColumns === 3) {
                    doc.setFontSize(7);
                    doc.setFont("times", "bold");
                } else {
                    doc.setFontSize(10);
                    doc.setFont("times", "bold");
                }
                doc.text(header, headerX, headerY);
                headerX += headerWidths[headerIdx];
            });
        } else {
            headers.forEach((header, headerIdx) => {
                if (numColumns === 3) {
                    doc.setFontSize(7);
                    doc.setFont("times", "bold");
                } else {
                    doc.setFontSize(10);
                    doc.setFont("times", "bold");
                }
    
                if (headerIdx === 0 || headerIdx === 2 || headerIdx === 5) {
                    const textWidth = doc.getTextWidth(header);
                    const adjustedX = headerX + headerWidths[headerIdx] - textWidth - 2;
                    doc.text(header, adjustedX, headerY);
                    headerX += headerWidths[headerIdx];
                } else {
                    doc.text(header, headerX, headerY);
                    headerX += headerWidths[headerIdx];
                }
            });
        }
        
        

        yPos += 2;
        // Draw a line after the event headers, spanning the entire column width
        doc.line(columnStartX, yPos, columnEndX, yPos);
        yPos += 5;

        event.heats.forEach((heat, heatIndex) => {
            // Calculate required space for the heat (header + lanes)
            const heatRequiredSpace = isRelay ? checkRelayHeatHeight(heat) : (1 + NUM_LANES) * 3; // 1 line for header, NUM_LANES lines for participants

            // Ensure the heat is not split between pages or columns
            checkPageHeight(heatRequiredSpace);

            // Update column position after checking page height
            const columnStartX = margin + (currentColumn * (columnWidth)); // Start X position for the current column
            //const columnEndX = margin + ((currentColumn + 1) * (columnWidth)); // End X position for the current column

            // Set font size and style for heat title
            if (numColumns === 3) {
                doc.setFontSize(7);
                doc.setFont("times", "bold");
            } else {
                doc.setFontSize(10);
                doc.setFont("times", "bold");
            }
            doc.text(`Heat ${heat.number} of ${event.heats.length}`, columnStartX + 2, yPos); // Adjusted to add some padding from the left
            if (numColumns === 3) {
                yPos += 3;
            } else {
                yPos += 4;
            }

            for (let i = 0; i < NUM_LANES; i++) {
                let headers;
                let nameLength = 15;
                if (numColumns === 2) {
                    headerWidths = isRelay ? headerWidths2ColumnsRelay : headerWidths2Columns; // Adjusted widths for 2 columns
                    nameLength = isRelay ? (headerWidths2ColumnsRelay[2]-3)/2 - 4 : headerWidths2Columns[1];
                } else {
                    headerWidths = isRelay ? headerWidths3ColumnsRelay : headerWidths3Columns; // Adjusted widths for 3 columns
                    nameLength = isRelay ? (headerWidths3ColumnsRelay[2]-3)/2 - 4 : headerWidths3Columns[1];
                }
                let headerX = columnStartX + 2; // Adjusted to add some padding from the left
                let headerY = yPos;

                // Find entrant by lane number
                let entrant = heat.entrants.find(e => e.lane === (i + 1));
                if (entrant && entrant.swimmers && entrant.swimmers.length > 0) {
                    if (isRelay) {
                        // Map the swimmers for relay events
                        let swimmers = entrant.swimmers.map(swimmer => {
                        // Split the name into words and convert each word if it is all uppercase or all lowercase
                        let formattedName = swimmer.name.split(' ').map(word => {
                            if (word === word.toLowerCase() || word === word.toUpperCase()) {
                                return word.toLowerCase().split(' ').map(upperFirst).join(' ');
                            }
                            return word;
                        }).join(' ');

                        // Maintain the original truncation rules
                        let truncatedName = formattedName.length > nameLength ? formattedName.substring(0, nameLength) : formattedName;
                            let number = swimmer.number;
                            if(swimmer.number < 10 ) {
                                number = `00${swimmer.number}`;
                            } else if (swimmer.number < 100 ) {
                                number = `0${swimmer.number}`;
                            } else {
                                number = `${swimmer.number}`;
                            }
                            return `${truncatedName} ${number}`;
                        });
                        // If less than 4 swimmers, add underscores
                        while (swimmers.length < 4) {
                            swimmers.push('________________');
                        }

                        headers = [
                            `${i + 1}`,
                            `${entrant.swimmers[0].club}`,
                            `${swimmers[0]}, ${swimmers[1]},`,
                            `${entrant.swimmers[0].seed}`
                        ];
                        // Print the first two swimmers on one line
                        headers.forEach((header, headerIdx) => {
                            if (numColumns === 3) {
                                doc.setFontSize(6.2);
                                doc.setFont("times", "normal");
                            } else {
                                doc.setFontSize(9.3);
                                doc.setFont("times", "normal");
                            }

                            if (headerIdx === 0 || headerIdx === 3) {
                                const textWidth = doc.getTextWidth(header);
                                const adjustedX = headerX + headerWidths[headerIdx] - textWidth - 2;
                                doc.text(header, adjustedX, headerY);
                            } else {
                                let truncatedHeader = header;
                                while (doc.getTextWidth(truncatedHeader) > headerWidths[headerIdx] && truncatedHeader.length > 0) {
                                    truncatedHeader = truncatedHeader.substring(0, truncatedHeader.length - 1);
                                }
                                doc.text(truncatedHeader, headerX, headerY);
                            }
                            headerX += headerWidths[headerIdx];
                        });

                        headerX = columnStartX + 2; // Reset headerX for the next line
                        // Move down to the next line
                        if (numColumns === 3) {
                            headerY += 3;
                        } else {
                            headerY += 4;
                        }

                        headers = [
                            '',
                            '',
                            `${swimmers[2]}, ${swimmers[3]}`,
                            ''
                        ];
                        // Print the last two swimmers on the next line
                        headers.forEach((header, headerIdx) => {
                            if (numColumns === 3) {
                                doc.setFontSize(6.2);
                                doc.setFont("times", "normal");
                            } else {
                                doc.setFontSize(9.3);
                                doc.setFont("times", "normal");
                            }

                            if (headerIdx === 0 || headerIdx === 3) {
                                const textWidth = doc.getTextWidth(header);
                                const adjustedX = headerX + headerWidths[headerIdx] - textWidth - 2;
                                doc.text(header, adjustedX, headerY);
                            } else {
                                let truncatedHeader = header;
                                while (doc.getTextWidth(truncatedHeader) > headerWidths[headerIdx] && truncatedHeader.length > 0) {
                                    truncatedHeader = truncatedHeader.substring(0, truncatedHeader.length - 1);
                                }
                                doc.text(truncatedHeader, headerX, headerY);
                            }
                            headerX += headerWidths[headerIdx];
                        });
                    } else {
                        let swimmer = entrant.swimmers[0];

                        // Function to convert string to title case
                        function toTitleCase(str) {
                            return str.toLowerCase().split(' ').map(upperFirst).join(' ');
                        }

                        // Split the name into words and convert each word if it is all uppercase or all lowercase
                        let formattedName = swimmer.name.split(' ').map(word => {
                            if (word === word.toLowerCase() || word === word.toUpperCase()) {
                                return toTitleCase(word);
                            }
                            return word;
                        }).join(' ');

                         // Add "ex." if the swimmer is exhibition
                         if (swimmer.exhibition) {
                            formattedName = `ex. ${formattedName}`;
                        }

                        // Truncate the name if needed
                        let truncatedName = formattedName.length > nameLength ? formattedName.substring(0, nameLength-3) + '...' : formattedName;

                        //let truncatedName = swimmer.name.length > (headerWidths[1] - 3) ? swimmer.name.substring(0, headerWidths[1]-3) + '...' : swimmer.name;
                        let number;
                            if(swimmer.number < 10 ) {
                                number = `00${swimmer.number}`;
                            } else if (swimmer.number < 100 ) {
                                number = `0${swimmer.number}`;
                            } else {
                                number = `${swimmer.number}`;
                            }
                            if(swimmer.number < 10) {
                                //console.log(number);
                            }
                        
                        let club = swimmer.club.length > 5 ? swimmer.club.substring(0,5) : swimmer.club;

                        headers = [
                            `${i + 1}`,
                            `${truncatedName}`,
                            `${swimmer.age}`,
                            `${club}`,
                            `${number}`,
                            `${swimmer.seed}`
                        ];
                        headers.forEach((header, headerIdx) => {
                            if (numColumns === 3) {
                                doc.setFontSize(6.2);
                                doc.setFont("times", "normal");
                            } else {
                                doc.setFontSize(9.3);
                                doc.setFont("times", "normal");
                            }

                            if (headerIdx === 0 || headerIdx === 2 || headerIdx === 5) {
                                const textWidth = doc.getTextWidth(header);
                                const adjustedX = headerX + headerWidths[headerIdx] - textWidth - 2;
                                doc.text(header, adjustedX, headerY);
                            } else {
                                let truncatedHeader = header;
                                while (doc.getTextWidth(truncatedHeader) > headerWidths[headerIdx] && truncatedHeader.length > 0) {
                                    truncatedHeader = truncatedHeader.substring(0, truncatedHeader.length - 1);
                                }
                                doc.text(truncatedHeader, headerX, headerY);
                            }
                            headerX += headerWidths[headerIdx];
                        });
                    }
                } else {
                    if (isRelay) {
                        headers = [`${i + 1}`, ` `, ` `, ` `];
                        headers.forEach((header, headerIdx) => {
                            if (numColumns === 3) {
                                doc.setFontSize(6.2);
                                doc.setFont("times", "normal");
                            } else {
                                doc.setFontSize(9.3);
                                doc.setFont("times", "normal");
                            }

                            if (headerIdx === 0 || headerIdx === 3) {
                                const textWidth = doc.getTextWidth(header);
                                const adjustedX = headerX + headerWidths[headerIdx] - textWidth - 2;
                                doc.text(header, adjustedX, headerY);
                            } else {
                                let truncatedHeader = header;
                                while (doc.getTextWidth(truncatedHeader) > headerWidths[headerIdx] && truncatedHeader.length > 0) {
                                    truncatedHeader = truncatedHeader.substring(0, truncatedHeader.length - 1);
                                }
                                doc.text(truncatedHeader, headerX, headerY);
                            }
                            headerX += headerWidths[headerIdx];
                        });

                        headerX = columnStartX + 2; // Reset headerX for the next line
                        headerY += 3; // Move down to the next line

                        headers = ['', '', ``, ''];
                        headers.forEach((header, headerIdx) => {
                            if (numColumns === 3) {
                                doc.setFontSize(6.2);
                                doc.setFont("times", "normal");
                            } else {
                                doc.setFontSize(9.3);
                                doc.setFont("times", "normal");
                            }

                            if (headerIdx === 0 || headerIdx === 3) {
                                const textWidth = doc.getTextWidth(header);
                                const adjustedX = headerX + headerWidths[headerIdx] - textWidth - 2;
                                doc.text(header, adjustedX, headerY);
                            } else {
                                let truncatedHeader = header;
                                while (doc.getTextWidth(truncatedHeader) > headerWidths[headerIdx] && truncatedHeader.length > 0) {
                                    truncatedHeader = truncatedHeader.substring(0, truncatedHeader.length - 1);
                                }
                                doc.text(truncatedHeader, headerX, headerY);
                            }
                            headerX += headerWidths[headerIdx];
                        });
                    } else {
                        headers = [`${i + 1}`, ` `, ` `, ` `, ` `, ` `];
                        headers.forEach((header, headerIdx) => {
                            if (numColumns === 3) {
                                doc.setFontSize(6.2);
                                doc.setFont("times", "normal");
                            } else {
                                doc.setFontSize(9.3);
                                doc.setFont("times", "normal");
                            }

                            if (headerIdx === 0 || headerIdx === 2 || headerIdx === 5) {
                                const textWidth = doc.getTextWidth(header);
                                const adjustedX = headerX + headerWidths[headerIdx] - textWidth - 2;
                                doc.text(header, adjustedX, headerY);
                            } else {
                                let truncatedHeader = header;
                                while (doc.getTextWidth(truncatedHeader) > headerWidths[headerIdx] && truncatedHeader.length > 0) {
                                    truncatedHeader = truncatedHeader.substring(0, truncatedHeader.length - 1);
                                }
                                doc.text(truncatedHeader, headerX, headerY);
                            }
                            headerX += headerWidths[headerIdx];
                        });
                    }
                }
                //add space after each entrant
                if(numColumns === 3) {
                    yPos += isRelay ? (entrant && entrant.swimmers.length > 0 ? 8 : 3) : 3;
                } else {
                    yPos += isRelay ? (entrant && entrant.swimmers.length > 0 ? 10 : 4) : 4;
                }
                 // Adjust space for relays only if there are entrants
            }
            // add space after each heat
            if (numColumns === 3) {
                yPos += 2
            } else {
                yPos += 3;
            }
        });

        yPos += 1;
    });

    // Add page numbers to each page
    const pageCount = doc.getNumberOfPages();
    doc.setFontSize(8);
    doc.setFont("MuseoSansRounded-500", "normal");
    for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        let text = `Swimmingly® - Page ${i}/${pageCount}`;
        let xPosition = doc.internal.pageSize.getWidth() - text.length - 15;
        doc.text(text, xPosition, 8);
    }

    doc.save(`${meet.meetName}_heat_sheet.pdf`);
}

export default generateHeatSheetPDF;
