import { FunctionComponent, useState, useEffect } from "react"
import moment, { Moment } from "moment-timezone"
import { ISeasonDetails } from "../../../App"
import { Divider, Tabs, Tooltip, Row, Col, Button, message } from "antd"
import {
  CheckOutlined,
  FormOutlined,
  LeftOutlined,
  InfoCircleOutlined,
  LinkOutlined,
  LoadingOutlined,
  CheckCircleOutlined,
  BankOutlined,
  DollarCircleOutlined,
  CarryOutOutlined,
  BarChartOutlined,
  EyeOutlined
} from "@ant-design/icons"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../../../appData/types"
import ContentPanel from "../../ContentPanel"
import { WaiversSection } from "./WaiversSection"
import { useClubSeasonManagementContext } from "../sharedState"
import { PricingConfiguration } from "./PricingConfiguration"
import { RegistrationForm } from "../../RegistrationForm"
import { Colors as SeasonSetupColors, Sizes as SeasonSetupSizes } from "../styles"
import { ConfirmSignupOpenDate } from "../SeasonConfigurationNoRegistration" //keith says: this is cheap that we're getting this from SeasonConfigurationNoRegistration but it's a little over-entangled
import Footer from "../../Footer"
import { RegistrationStatus } from "../RegistrationStatus"
const { TabPane } = Tabs

const Icons = {
  linkOut: <LinkOutlined style={{ color: "white" }} />,
  loading: <LoadingOutlined spin={true} />,
  verified: <CheckCircleOutlined style={{ fontSize: SeasonSetupSizes.statusIconSize, color: "green" }} />,
  info: <InfoCircleOutlined />,
  tab: {
    home: <BankOutlined />,
    waivers: <FormOutlined />,
    pricing: <DollarCircleOutlined />,
    preview: <EyeOutlined />,
    confirm: <CarryOutOutlined />,
    status: <BarChartOutlined />
  }

}

type TitleableProps = {
  title?: string
}


const HorizontalLine = ({ title }: TitleableProps) => {
  return (
    <Divider style={{ borderWidth: 1, borderColor: SeasonSetupColors.primaryBlue }}>
      {title}
    </Divider>
  )

}

type TabTitleProps = {
  title: string,
  icon: JSX.Element
}

const TabTitle = ({ title, icon }: TabTitleProps) => {
  return (
    <span>
      {title} {icon}
    </span>

  )

}

interface IRegistrationReviewableProps {
  setReviewRegistration: (value: boolean) => void
  signupsOpen: Moment | null
  setSignupsOpen: (value: Moment | null) => void
  setSettingsConfirmed: (value: boolean) => void
  settingsConfirmed: boolean
  frontendUsingRegistration: boolean
}

type RegistrationStatusViewModel = {
  isConnectingBankAccount: boolean
  canReceivePayments: boolean
  errorMessage?: string
}


export const RegistrationConfiguration: FunctionComponent<IRegistrationReviewableProps> = ({ setReviewRegistration, setSignupsOpen, setSettingsConfirmed, settingsConfirmed, signupsOpen, frontendUsingRegistration }) => {
  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)
  const [registrationStatus, setRegistrationStatus] = useState<RegistrationStatusViewModel>({ isConnectingBankAccount: false, canReceivePayments: false })
  const [seasonInfo, setSeasonInfo] = useState<ISeasonDetails | null>(null)

  const impersonateClub = useStoreState(
    (state: StateMapper<AppDataStore>) => state.aliasedClub,
  )

  const { loadPaymentsConfiguration, paymentsConfiguration, selectedClubSeason, loadBillingInfo, billingInfo, upcomingSeason, loadPricePoints, pricePoints, waiversWithSections, loadWaiversWithSections } = useClubSeasonManagementContext()



  useEffect(() => {
    if (Boolean(paymentsConfiguration.errorMessage)) {
      message.error(paymentsConfiguration.errorMessage!)
    }
    const shouldRedirectToPaymentProcessor = registrationStatus.isConnectingBankAccount && !paymentsConfiguration.canReceivePayments && Boolean(paymentsConfiguration.accountLink)
    setRegistrationStatus(
      previousState => {

        return {
          ...previousState,
          canReceivePayments: paymentsConfiguration.canReceivePayments,
          isConnectingBankAccount: (shouldRedirectToPaymentProcessor || paymentsConfiguration.canReceivePayments) ? false : previousState.isConnectingBankAccount,
          errorMessage: paymentsConfiguration.errorMessage
        }
      }
    )
    if (shouldRedirectToPaymentProcessor) {
      window.location.href = paymentsConfiguration.accountLink!
    }

  }, [loadPaymentsConfiguration, paymentsConfiguration, registrationStatus.isConnectingBankAccount])

  useEffect(() => {
    if (registrationStatus.isConnectingBankAccount && Boolean(impersonateClub?.clubId) && Boolean(selectedClubSeason?.seasonId)) {
      //force unwrapping only after checking that these values are truthy
      loadPaymentsConfiguration(impersonateClub?.clubId!, selectedClubSeason?.seasonId!)
    }

  }, [impersonateClub?.clubId, loadPaymentsConfiguration, selectedClubSeason?.seasonId])

  useEffect(() => {
    if (Boolean(impersonateClub?.clubId) && Boolean(selectedClubSeason?.seasonId)) {
      //force unwrapping only after checking that these values are truthy
      loadPaymentsConfiguration(impersonateClub?.clubId!, selectedClubSeason?.seasonId!)
      loadBillingInfo(impersonateClub?.clubId!, selectedClubSeason?.seasonId!)
    }

  }, [selectedClubSeason, settingsConfirmed, impersonateClub?.clubId, loadBillingInfo, loadPaymentsConfiguration])

  useEffect(() => {
    if (billingInfo.seasonInfo !== undefined) {
      setSeasonInfo(billingInfo.seasonInfo)
    } else {
      setSeasonInfo(null)
    }
  }, [loadBillingInfo, billingInfo])

  useEffect(() => {
    loadPricePoints()
  }, [loadPricePoints])

  useEffect(() => {
    if (selectedClubSeason !== null) {
      loadWaiversWithSections(selectedClubSeason?.clubId, selectedClubSeason?.seasonId)
    }
  }, [selectedClubSeason, loadWaiversWithSections])

  return (
    <ContentPanel
      marginTop="25px"
      contentPaddingTop={0}
      title={
        <span>
          {selectedClubSeason && !Boolean(upcomingSeason?.seasonSetupConfirmedOn) && !settingsConfirmed && (
            <Tooltip title="Go back to review registration">
              <LeftOutlined
                onClick={() =>
                  setReviewRegistration(true)
                }
                style={{
                  color: "var(--linkblue)",
                  cursor: "pointer",
                  marginRight: "20px",
                }}
              />
            </Tooltip>
          )}
          {impersonateClub?.nameLong}'s PREMIUM Registration Setup
        </span>
      }
    >
      <div>
        <div
          style={{
            borderRadius: "5px",
            padding: "10px",
            paddingTop: "0px",
          }}
        >
          <Tabs defaultActiveKey="registrationHome" type="card" size="large">
            <TabPane tab={TabTitle({ title: "Step 1: Connect Bank Account", icon: Icons.tab.home })
            } key="registrationHome">
              <HorizontalLine title="Connect Bank Account" />
              <Row gutter={20} wrap={false} style={{ backgroundColor: registrationStatus.canReceivePayments ? SeasonSetupColors.bankAccountVerifiedBackground : SeasonSetupColors.bankAccountNotVerifiedBackground, maxWidth: "550px" }}>
                <Col>
                  {registrationStatus.canReceivePayments ? (
                    Icons.verified
                  ) : (
                    Icons.info
                  )}

                </Col>
                <Col>
                  <p style={{ fontWeight: "bold" }}>Bank Account {registrationStatus.canReceivePayments ? "Verified" : "Not Yet Verified"} </p>
                  <p>
                    {
                      registrationStatus.canReceivePayments ? "Your bank account is now verified, connected, & ready to accept registration payments. Please proceed to steps 2-5 and complete your PREMIUM club registration setup." : "Please connect & verify your club bank account. Then you can complete PREMIUM registration setup. If your club had a connected bank account last season, login with the same Stripe connect credentials to re-verify. Or, simply create a new Stripe connect account."
                    }
                    &nbsp;
                    &nbsp;
                    <br /><br />
                    <Button
                      shape="round"
                      style={{ color: "white", backgroundColor: SeasonSetupColors.linkButtonBackground }}
                      target="_blank"
                      onClick={() => {
                        if (registrationStatus.canReceivePayments && paymentsConfiguration.accountLink) {
                          window.location.href = paymentsConfiguration.accountLink!
                        } else {
                          setRegistrationStatus(
                            //update the Registration
                            previousState => (
                              {
                                ...previousState,
                                isConnectingBankAccount: !Boolean(previousState.isConnectingBankAccount)
                              }))
                        }
                      }}
                    >
                      {registrationStatus.canReceivePayments ? "Manage " : "Connect "}
                      Bank Account
                      {registrationStatus.isConnectingBankAccount ? (Icons.loading) : (Icons.linkOut)}
                    </Button>
                  </p>
                </Col>
              </Row>
            </TabPane>
            <TabPane
              tab={TabTitle({ title: "Step 2: Waivers", icon: Icons.tab.waivers })}
              key="waiversSection"
              disabled={!registrationStatus.canReceivePayments}
            >
              <WaiversSection />
            </TabPane>
            <TabPane
              tab={TabTitle({ title: "Step 3: Registration Dues & More", icon: Icons.tab.pricing })}
              key="pricingSection"
              disabled={!registrationStatus.canReceivePayments}
            >
              <PricingConfiguration />
            </TabPane>
            <TabPane
              tab={TabTitle({ title: "Step 4: Preview", icon: Icons.tab.preview })}
              key="previewSection"
              disabled={!registrationStatus.canReceivePayments}
            >
              {impersonateClub?.clubId && selectedClubSeason?.seasonId && (
                <RegistrationForm

                  previewMode={true}
                  selectedClubSeason={selectedClubSeason}
                  pricePoints={pricePoints}
                  waivers={waiversWithSections ?? []}
                  children={undefined}

                />)}

            </TabPane>
            <TabPane
              tab={TabTitle({ title: "Step 5: Confirm/Open Registration", icon: Icons.tab.confirm })}
              key="confirmSection"
              disabled={!registrationStatus.canReceivePayments}
            >

              <ConfirmSignupOpenDate
                signupsOpen={signupsOpen ?? moment()}
                setSignupsOpen={setSignupsOpen}
                settingsConfirmed={settingsConfirmed}
                setSettingsConfirmed={setSettingsConfirmed}
                seasonInfo={seasonInfo}
                selectedClubSeason={selectedClubSeason}
                user={user}
                frontendUsingRegistration={frontendUsingRegistration} />

            </TabPane>
            <TabPane
              tab={TabTitle({ title: "Step 6: Registration Status", icon: Icons.tab.status })}
              key="statusSection"
              disabled={!registrationStatus.canReceivePayments}
            >
              <RegistrationStatus
                selectedClubSeason={selectedClubSeason}
              />
            </TabPane>
          </Tabs>
          <HorizontalLine />
        </div>
        <Footer />
      </div>

    </ContentPanel>
  )
}

