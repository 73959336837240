import styled from "styled-components";
import { Modal } from "antd"
import { MeetClubStatus } from "../MeetSchedulePageNew";
import { IParentsMeet } from "./ParentMeetScheduleInner";
import { IClub } from "../utils";
import { screenSizes } from "../../styles/GlobalStyles";

const StyledModal = styled(Modal)`
  max-width: 40%;
  min-width: 725px;

  .modal-body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    align-content: center;
    column-gap: 25px;
    row-gap: 10px;
  }

  .item {
    text-align: center;
    height: 50px;
  }

  div.item {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  .ant-modal-content {
    max-height: calc(95vh - 100px);
    overflow: scroll;
  }

  .shadowed {
    box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    padding: 5px;
  }

  @media (max-width: ${screenSizes.medium}px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: ${screenSizes.small}px) {
    min-width: 0;
    max-width: 97%;

    .modal-body {
      grid-template-columns: 1fr 1fr;
    }
  }
`

export default function ParentMeetDetailsModal({
    meet,
    visible,
    setVisible,
    clubs, 
    refreshData
}: {
    meet: IParentsMeet | null,
    visible: boolean,
    setVisible: (visible: boolean) => void ,
    clubs: IClub[],
    refreshData: () => void
}
): JSX.Element {
    return (
        <StyledModal
            title={meet?.meetName}
            width="100%"
            visible={visible}
            onOk={() => setVisible(false)}
            okText="Close"
            onCancel={() => setVisible(false)}
        >
            <div className="shadowed">
                <h4>Clubs in this Meet:</h4>
                <hr />
                {clubs.map((club, index) => (
                <MeetClubStatus
                    key={`meet_status_${meet?.meetId}_${club.clubId}_${index}`}
                    club={club}
                    isHome={meet?.is_home === club.clubId}
                    meetstatus={meet?.meetstatus}
                />
            ))}
            </div>
        </StyledModal>
    )
}
