import React, { useState, useEffect } from "react"
import moment from "moment-timezone"
import CenterSpin from "../CenterSpin"
import { Empty, message } from "antd"
import { formatNumberAsTime } from "../utils"
import getSwimmerResults from "./SwimmerResultsHelper"
import SingleMeetResult from "./SingleMeetResult"

function convertToSCY(time, course) {
  return course === 0 ? time : time / 1.11
}

function convertToOther(prevBest, prevBestCourse, curSwimCourse) {
  if (prevBestCourse === curSwimCourse) {
    return prevBest
  }
  if (prevBestCourse === 0 && curSwimCourse !== 0) {
    return prevBest * 1.11
  } else {
    return prevBest / 1.11
  }
}

export default function AllMeetsCard(props) {
  const [done, setDone] = useState(false)
  const [lastMeetSwims, setLastMeetSwims] = useState([])

  useEffect(() => {    
      getSwimmerResults(
        props.swimmer_id,
        props.user.userId,
        props.clubId
      )
      .then((data) =>{
        if (data.error) {
          message.error(data.error)
          return
        }
        for (let i = 0; i < data.lastMeetSwims.length; i++) {
          let curResult = data.lastMeetSwims[i]
          curResult.time = Number(curResult.time)
        }
  
        setLastMeetSwims(data.lastMeetSwims)
        setDone(true)
      })
   
  }, [])

  const getArrayOfSwimsForEachMeet_FixTesting = (allSwims) => {
    let arrOfSwims = []
    let theMeetIds = allSwims
      .map((x) => x.meet_id)
      .filter((v, i, a) => a.indexOf(v) === i)
    for (let i = 0; i < theMeetIds.length; i++) {
      let thisMeetId = theMeetIds[i]
      let thisMeetSwims = allSwims.filter((x) => x.meet_id === thisMeetId)
      arrOfSwims.push(thisMeetSwims)
    }
    return arrOfSwims
  }

  const getPrevBest = (allSwims, eventName, startIndex) => {
    let prevBest = null
    let prevBestCourse = null
    for (let i = startIndex; i < allSwims.length; i++) {
      if (allSwims[i].event_name === eventName) {
        if (!prevBest) {
          prevBest = allSwims[i].time
          prevBestCourse = allSwims[i].unit_measure
        } else {
          if (
            convertToSCY(prevBest, prevBestCourse) >
            convertToSCY(allSwims[i].time, allSwims[i].unit_measure)
          ) {
            prevBest = allSwims[i].time
            prevBestCourse = allSwims[i].unit_measure
          }
        }
      }
    }
    return { prevBest, prevBestCourse }
  }

  const addPrevBest = (allSwims) => {
    for (let i = 0; i < allSwims.length; i++) {
      let curSwim = allSwims[i]
      let curEventName = curSwim.event_name
      let curSwimCourse = curSwim.unit_measure
      let { prevBest, prevBestCourse } = getPrevBest(
        allSwims,
        curEventName,
        i + 1,
      )
      if (prevBest) {
        if (curSwimCourse !== prevBestCourse) {
          allSwims[i].is_converted2 = true
          let prevBestConverted = convertToOther(
            prevBest,
            prevBestCourse,
            curSwimCourse,
          )
          allSwims[i].prevBestFormatted = formatNumberAsTime(prevBestConverted)
          allSwims[i].diff2 = allSwims[i].time - prevBestConverted
          allSwims[i].prevBestCourse = prevBestCourse
        } else {
          allSwims[i].prevBestFormatted = formatNumberAsTime(prevBest)
          allSwims[i].diff2 = allSwims[i].time - prevBest
          allSwims[i].is_converted2 = false
          allSwims[i].prevBestCourse = prevBestCourse
        }
      } else {
        allSwims[i].prevBestFormatted = "-"
        allSwims[i].diff2 = "-"
        allSwims[i].is_converted2 = false
        allSwims[i].prevBestCourse = "-"
      }
    }
    return allSwims
  }


  if (!done) {
    return <CenterSpin />
  }

  if (lastMeetSwims.length === 0) {
    return <Empty />
  }

  const lastMeetSwimsWithPrevBest = addPrevBest(lastMeetSwims)
  let arrOfSwims = getArrayOfSwimsForEachMeet_FixTesting(lastMeetSwimsWithPrevBest)

  return (
    <>
      {
        arrOfSwims.map((swimmerResultsArray, index) => (
          <SingleMeetResult
            key={index}
            swimmerMeetResults={swimmerResultsArray}
            expanded={index === 0 ? true : false}
          />
        ))

      }
    </>
  )
}