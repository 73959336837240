import React from "react"
import $ from "jquery"
import HighPoints from "./HighPoints"
import ClubCard from "./ClubCard"
import SwimmerCard from "./SwimmerCard"
import MeetsCard from "./MeetsCard"
import ServiceReport from "./ServiceReport"
import CenterSpin from "./CenterSpin"
import { Tabs } from "antd"
import { gen } from "./utils"
const { TabPane } = Tabs

export default class ReportsPage extends React.Component {
  state = {
    loaded: false,
  }

  componentDidMount() {
    if (this.props.club?.clubId && this.props.user) {
      $.ajax({
        url: gen("/api/reportsDataPost"),
        dataType: "json",
        method: "POST",
        data: {
          clubId: this.props.club.clubId,
          user: this.props.user,
        },
      }).done((returnData) => {
        this.setState({
          clubArr: returnData.clubArr,
          leagues: returnData.leagues,
          newMeetsArr: returnData.newMeetsArr,
          swimmers: returnData.swimmers,
          loaded: true,
        })
      })
    }
  }

  renderTabs = (user) => {
    if (user.role === 2) {
      return (
        <Tabs animated={false}>
          <TabPane key="meets" tab="Meets">
            <MeetsCard {...this.props} {...this.state} />
          </TabPane>
          <TabPane key="swimmer" tab="Swimmers">
            <SwimmerCard {...this.props} {...this.state} />
          </TabPane>
          <TabPane key="club" tab="Club">
            <ClubCard {...this.props} {...this.state} />
          </TabPane>
          <TabPane key="highpoint" tab="High Point">
            <HighPoints {...this.props} {...this.state} />
          </TabPane>
          <TabPane key="service" tab="Service Report">
            <ServiceReport {...this.props} {...this.state} />
          </TabPane>
        </Tabs>
      )
    }
    if (user.role === 3) {
      return (
        <Tabs animated={false}>
          <TabPane key="meets" tab="Meets">
            <MeetsCard {...this.props} {...this.state} />
          </TabPane>
          <TabPane key="swimmer" tab="Swimmers">
            <SwimmerCard {...this.props} {...this.state} />
          </TabPane>
         <TabPane key="club" tab="Club">
            <ClubCard {...this.props} {...this.state} />
          </TabPane>
          <TabPane key="highpoint" tab="High Point">
            <HighPoints {...this.props} {...this.state} />
          </TabPane>
        </Tabs>
      )
    }
    if (user.role === 4) {
      return (
        <Tabs animated={false}>
          <TabPane key="meets" tab="Meets">
            <MeetsCard {...this.props} {...this.state} />
          </TabPane>
          <TabPane key="swimmer" tab="Swimmers">
            <SwimmerCard {...this.props} {...this.state} />
          </TabPane>
          <TabPane key="club" tab="Club">
            <ClubCard {...this.props} {...this.state} />
          </TabPane>
          <TabPane key="highpoint" tab="High Point">
            <HighPoints {...this.props} {...this.state} />
          </TabPane>
        </Tabs>
      )
    }
  }

  render() {
    if (!this.state.loaded) {
      return (
        <div>
          <header className="page-header">
            <h2>Reports</h2>
          </header>

          <section className="panel">
            <header className="panel-heading">
              <h2 className="panel-title">Reports</h2>
            </header>
            <div className="ourpanel-body">
              <CenterSpin />
            </div>
          </section>
        </div>
      )
    }

    return (
      <div>
        <header className="page-header">
          <h2>Reports</h2>
        </header>

        <section className="panel">
          <header className="panel-heading">
            <h2 className="panel-title">Reports</h2>
          </header>
          <div className="ourpanel-body">
            {this.renderTabs(this.props.user)}
          </div>
        </section>
      </div>
    )
  }
}
