import { FunctionComponent } from "react"
import { LinkItUrl } from 'react-linkify-it';


export type ParagraphedProps = {
    idPrefix: string
    children: string
    linkify?: boolean
  }
  
  export const ParagraphedText: FunctionComponent<ParagraphedProps> =
  (  {idPrefix, children, linkify = false} ) => {
  
    const paragraphs = children
      .split(/(\r?\n|\r)/g)
      .filter((paragraph) => paragraph && paragraph.trim() !== "")
      .map((paragraph) => {
        return linkify ? (<LinkItUrl>{paragraph}</LinkItUrl>) : (<>{paragraph}</>)  
      }) 
  
      return (
        <div>
            {paragraphs.map((paragraph, idx) => (
              <p key={`${idPrefix}_${idx}`}>{paragraph}</p>
            ))}
          </div>
      )
  
  }
  