import React from "react"
import $ from "jquery"
import moment from "moment-timezone"
import { Modal, message, Button } from "antd"
import { actionItemStyle, gen } from "./utils"

export default class QuickStartMyMeetLineUp extends React.Component {
  state = {
    otherMeetId: "",
    otherMeetName: "",
    certainMeets: [],
    loading: false,
    visible: false,
  }

  saveMeetSchedule = () => {
    if (!this.state.otherMeetId || !this.props.meetId) {
      message.error("Need to select a meet")
      return
    }

    this.setState({ loading: true })
    console.log(this.props.meetId, this.state.otherMeetId)
    $.ajax({
      url: gen(`/api/copyMeetEntriesPost`),
      method: "POST",
      dataType: "json",
      data: {
        copyToMeetId: this.props.meetId,
        copyFromMeetId: this.state.otherMeetId,
        clubId: this.props.clubId,
      },
    }).done((data) => {
      this.props.refreshSwimmers()
      this.setState({ loading: false, visible: false })
      message.success(
        `You've successfully copied entries from ${this.state.otherMeetName}!`,
        7,
      )
      //this.props.changeVisible()
    })
  }

  generateName = (field) => {
    return (
      field.meet_date +
      " " +
      field.meet_clubs
        .map((el) => (el && el.meet_club_info && el.meet_club_info.name) || " ")
        .join(" vs ")
        .slice(0, 40)
    )
  }

  componentDidMount() {
    $.ajax({
      url: gen(`/api/getCommonEventOrdersPost`),
      method: "POST",
      dataType: "json",
      data: {
        meetId: this.props.meetId,
        clubId: this.props.clubId,
        user: this.props.user,
      },
    }).done((data) => {
      this.setState({ certainMeets: data.meetData })
    })
  }

  openModal = () => {
    this.props.closeMenu()
    this.setState({ visible: true })
  }

  render() {
    // console.log(this.state.certainMeets, this.props.meetId)
    let certainMeets = this.state.certainMeets.filter(
      (el) => el.meetId !== Number(this.props.meetId),
    )

    return (
      <div>
        <span onClick={this.openModal} style={actionItemStyle}>
          Quick start my meet lineup
        </span>
        <Modal
          visible={this.state.visible}
          title="Quick Start My Meet LineUp"
          onCancel={() => this.setState({ visible: false })}
          footer={[
            <Button
              key="cancel"
              onClick={() => this.setState({ visible: false })}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={this.state.loading}
              onClick={this.saveMeetSchedule}
            >
              Submit
            </Button>,
          ]}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <label>Choose lineup</label>
            <div>
              <select
                value={this.state.otherMeetId}
                onChange={(e) => {
                  console.log(e.currentTarget)
                  let filteredMeets = this.state.certainMeets.filter(
                    (x) =>
                      parseInt(x.meetId, 10) === parseInt(e.target.value, 10),
                  )
                  let thisMeet = filteredMeets[0]
                  this.setState({
                    otherMeetId: e.target.value,
                    otherMeetName: `${thisMeet.meetName} on ${moment(
                      thisMeet.meet_date,
                    ).format("YYYY-MM-DD")}`,
                  })
                }}
              >
                <option value={""}>Select</option>
                {certainMeets.map((el) => (
                  <option value={el.meetId} key={el.meetId}>
                    {`${el.meet_date.substring(0, 10)} ${el.meetName.substring(
                      0,
                      32,
                    )}${el.meetName.length > 32 ? "..." : ""}`}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div
            style={{
              justifyContent: "center",
              marginRight: "15px",
              marginLeft: "15px",
            }}
          >
            <p>
              Quick start your lineup this week and save a ton of time! Select
              your favorite meet lineup from any swim meet in the last 6 months
              that used the same event order as this meet.
            </p>
            <p>Notes:</p>
            <ul>
              <li>Any entries in your current lineup will be overwritten.</li>
              <li>
                When you quickstart a meet lineup, seed times will automatically
                reflect the swimmer's current best time converted to the course
                of the meet.
              </li>
            </ul>
          </div>
        </Modal>
      </div>
    )
  }
}
