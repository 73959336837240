import React from "react"
import { Table } from "antd"
import { DndProvider, DragSource, DropTarget } from "react-dnd"
import HTML5Backend from "react-dnd-html5-backend"
import update from "immutability-helper"
import { raceTypeArr } from "./utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
let dragingIndex = -1

class BodyRow extends React.Component {
  render() {
    const {
      isOver,
      connectDragSource,
      connectDropTarget,
      moveRow,
      ...restProps
    } = this.props
    const style = { ...restProps.style, cursor: "move" }

    let { className } = restProps
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += " drop-over-downward"
      }
      if (restProps.index < dragingIndex) {
        className += " drop-over-upward"
      }
    }

    return connectDragSource(
      connectDropTarget(
        <tr {...restProps} className={className} style={style} />,
      ),
    )
  }
}

const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index
    return {
      index: props.index,
    }
  },
}

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex)

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex
  },
}

const DragableBodyRow = DropTarget("row", rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource("row", rowSource, (connect) => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow),
)

class DragSortingTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: this.props.data,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({ data: nextProps.data })
    }
  }

  components = {
    body: {
      row: DragableBodyRow,
    },
  }

  moveRow = (dragIndex, hoverIndex) => {
    const { data } = this.state
    const dragRow = data[dragIndex]
    let self = this
    this.setState(
      update(this.state, {
        data: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        },
      }),
      () => self.props.resortEvents(this.state.data, self.props.getEvents),
    )
  }

  render() {
    let columns = [
      {
        title: "Event #",
        dataIndex: "SortOrder",
        key: "sortOrder",
      },
      {
        title: "Age Group",
        dataIndex: "AgeGroup",
        key: "ageGroup",
      },
      {
        title: "Gender",
        dataIndex: "Gender",
        key: "gender",
      },
      {
        title: "Distance",
        dataIndex: "Distance",
        key: "distance",
      },
      {
        title: "Stroke",
        key: "raceType",
        render: (record) => raceTypeArr[record.RaceType],
      },
      {
        title: "Actions",
        key: "actions",
        render: (record) => (
          <div
            className="delete"
            onClick={() => this.props.deleteEvent(record.id)}
            style={{ cursor: "pointer" }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </div>
        ),
      },
    ]
    return (
      <DndProvider backend={HTML5Backend}>
        <Table
          columns={columns}
          pagination={false}
          rowKey={(event) => event.id}
          dataSource={this.state.data}
          components={this.components}
          onRow={(record, index) => ({
            index,
            moveRow: this.moveRow,
          })}
        />
      </DndProvider>
    )
  }
}

export default DragSortingTable
