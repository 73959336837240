import React, { useCallback, useEffect, useState } from "react"
import { Route, Redirect } from "react-router-dom"
import { useStoreState, useStoreActions, StateMapper } from "easy-peasy"
import { AppDataStore } from "../appData/types"
// import { message } from "antd"
import { gen, swimminglyApi } from "./utils"
import { Skeleton } from "antd"

interface IPrivateRouteProps {
  render: <T>(t: T | undefined) => JSX.Element
  allowedRoles: Array<Number>
  allowedActivations?: Array<Number>
  requiresImpersonateClub: boolean
  [key: string]: any
}

export default function NewPrivateRoute({
  render: Render,
  allowedRoles,
  allowedActivations = [1],
  requiresImpersonateClub = false,
  ...rest
}: IPrivateRouteProps) {
  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)
  const impersonateClub = useStoreState(
    (state: StateMapper<AppDataStore>) => state.aliasedClub,
  )
  const setImpersonateClub = useStoreActions(
    (actions: AppDataStore) => actions.setAliasedClub,
  )
  const [loading, setLoading] = useState(false)

  const getLastAliasedClub = useCallback(() => {
    if (user?.userId && !impersonateClub) {
      setLoading(true)
      swimminglyApi
        .get(gen(`/api/getUserLastAliasedClub/${user?.userId}`))
        .then((data) => {
          if (data.club) {
            setImpersonateClub(data.club)
          }
        })
        .finally(() => setLoading(false))
    }
  }, [user?.userId, impersonateClub, setImpersonateClub])

  useEffect(() => {
    let mounted = true
    if (user && !impersonateClub) {
      if (mounted) {
        getLastAliasedClub()
      }
    }
    return () => {
      mounted = false
    }
  }, [user, impersonateClub, getLastAliasedClub])

  if (!user) {
    return <Redirect to="/login" />
  }

  if (loading) {
    return <Skeleton />
  }

  if (requiresImpersonateClub && !impersonateClub) {
    return <Redirect to="/app/landingPage" />
  }

  if (!allowedRoles.includes(user.role)) {
    // message.warning(
    //   "You do not have permission to visit that portion of the site.",
    // )
    return <Redirect to="/app/landingPage" />
  }

  if (!allowedActivations.includes(0) && !user.isActive) {
    // message.warning(
    //   "Your account needs to be activated before you can access that area of the site.",
    // )
    return <Redirect to="/app/landingPage" />
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return Render(props)
      }}
    />
  )
}
