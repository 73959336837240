import { Card, Button, Divider } from "antd";
import { CheckSquareTwoTone, CloseSquareTwoTone } from "@ant-design/icons";
import BasicRegistration from "../../images/Swimmingly_Illustrations_05.png";
import { CSSProperties } from "react";
import { PaymentTypes } from "../utils";

const bodyStyle: CSSProperties = {
  backgroundColor: '#f9f9f9',
  padding: "10px 15px 10px 15px",
  fontFamily: '"Open Sans", Arial, sans-serif',
  fontSize: '18px',
  textAlign: 'left',
  border: '1px solid lightgray',
};

const headStyle2: CSSProperties = {
  backgroundColor: '#c9d9e4',
  padding: '5px',
  fontFamily: '"Open Sans", Arial, sans-serif',
  fontSize: '16px',
  fontWeight: 'bold',
};

interface BasicRegistrationCardProps {
  cardStyle: CSSProperties;
  chooseRegistration: (isPremium: boolean, seasonId: number) => void;
  setReviewRegistration: (value: boolean) => void;
  selectedClubSeason: {
    seasonId: number;
    usingRegistration?: boolean;
    paymentType?: string;
  };
}

const BasicRegistrationCard: React.FC<BasicRegistrationCardProps> = ({ cardStyle, chooseRegistration, setReviewRegistration, selectedClubSeason }) => {
  const handleCardClick = () => {
    chooseRegistration(false, selectedClubSeason.seasonId);
    setReviewRegistration(false);
  };

  return (
    <Card 
      style={{ ...cardStyle, cursor: 'pointer' }} 
      headStyle={headStyle2}
      cover={
        <img
          alt="BASIC Registration"
          src={BasicRegistration}
          style={{
            width: "25%",
            margin: "2% 50% 0% 37%",
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center'
          }}
        />
      }
      title="BASIC Registration"
      hoverable={true}
      bordered={true}
      onClick={handleCardClick}
    >
      <Card.Meta
        avatar={<CheckSquareTwoTone twoToneColor="#52c41a" />}
        description={<>Full Swimmingly® app access featuring in-app timing, officiating, & scoring. <a href="https://splash.swimmingly.app/benefits">Benefits</a></>}
        style={bodyStyle}
      />
      <Card.Meta
        avatar={<CheckSquareTwoTone twoToneColor="#52c41a" />}
        description={<>Parent/guardians register their swimmers for full membership & benefits in both the Clubhouse and SwimminglyFan™. <a href="https://splash.swimmingly.app/benefits">Benefits</a></>}
        style={bodyStyle}
      />
      <Card.Meta
        avatar={<CheckSquareTwoTone twoToneColor="#52c41a" />}
        description={<>Full league & club admins membership & benefits in the Clubhouse. <a href="https://splash.swimmingly.app/benefits">Benefits</a></>}
        style={bodyStyle}
      />
      <Card.Meta
        avatar={<CheckSquareTwoTone twoToneColor="#52c41a" />}
        description={<>Coaches can open Meet Declarations to parents/guardians and optionally allow them to complete their swimmers' individual entries per meet. <a href="https://splash.swimmingly.app/benefits">Benefits</a></>}
        style={bodyStyle}
      />
      <Card.Meta
        avatar={<CheckSquareTwoTone twoToneColor="#52c41a" />}
        description="Team Communication"
        style={bodyStyle}
      />
      <Card.Meta
        avatar={<CloseSquareTwoTone twoToneColor="#FF8A8A" />}
        description={<>Customize your club's registration dues & collect payments
        directly from your swim families here in the Swimmingly 
        Clubhouse. <a href="https://splash.swimmingly.app/premium-club-registration">Learn more</a></>}
        style={bodyStyle}
      />
      <Card.Meta
        avatar={<CloseSquareTwoTone twoToneColor="#FF8A8A" />}
        description={<>Customize waivers & disclosures for your club - use ready-made
        templates or copy and paste your own. <a href="https://splash.swimmingly.app/premium-club-registration">Learn more</a></>}
        style={bodyStyle}
      />
      <Card.Meta
        avatar={<CloseSquareTwoTone twoToneColor="#FF8A8A" />}
        description={<>Add additional checkout items like team shirts, trophies,
          towels, city/champs meet entries, and more - and generate more
          revenue for your club. <a href="https://splash.swimmingly.app/premium-club-registration">Learn more</a></>}
        style={bodyStyle}
      />
      <Card.Meta
        avatar={<CloseSquareTwoTone twoToneColor="#FF8A8A" />}
        description={<>Pause/Resume/Close PREMIUM Registration on-demand at 
        anytime. <a href="https://splash.swimmingly.app/premium-club-registration">Learn more</a></>}
        style={bodyStyle}
      />
      <Divider />
      <Button
        type="ghost"
        block={true}
        size="large"
        icon={<CheckSquareTwoTone />}
        shape="round"
        onClick={handleCardClick}
      >
        BASIC Registration
      </Button>
      <p>
        <br />
        <TextContainer present={!Boolean(selectedClubSeason?.usingRegistration)}>
          When Parent/Guardians use BASIC registration, your club has elected to pay{" "}
          <b>{selectedClubSeason?.paymentType ?? PaymentTypes.unknown}</b>
          <br />
          Same registration process and features as last season, PLUS new features that we've added! 
        </TextContainer>
        <TextContainer present={Boolean(selectedClubSeason?.usingRegistration)}>
          Your club has elected to use <b>PREMIUM Club Registration</b>
        </TextContainer>
      </p>
    </Card>
  );
};

export default BasicRegistrationCard;

const TextContainer: React.FC<{ children?: React.ReactNode, present?: boolean }> = ({ children, present = true }) => {
  if (!present) return null;
  return (
    <p
      style={{
        textAlign: "center",
        width: "60%",
        marginRight: "auto",
        marginLeft: "auto",
      }}
    >
      {children}
    </p>
  );
};
