import React from "react"
import { Table, Button, Tag } from "antd"

const { Column } = Table

export default class MeetSummaryTable extends React.Component {
  state = {
    loading: false,
    showLeagueView: false,
    league: null,
    leagueName: null,
    date: null,
    leagueTableData: null,
  }

  switchToLeagueView = async (leagueId, leagueName, date) => {
    await this.setState({ loading: true })
    await this.setState({
      showLeagueView: true,
      league: leagueId,
      date: date,
      leagueName: leagueName,
      leagueTableData: this.props.data
        .filter((x) => {
          if (x.home_league_id === leagueId && x.meet_date === date) {
            return true
          } else {
            return false
          }
        })
        .map((x) => {
          return {
            meetId: x.meet_database_id,
            virtualMeetId: x.virtualMeetId,
            meetDate: x.meet_date,
            meetName: x.meet_name,
            homeClub: x.home_club,
            league: x.home_league,
            location: x.location,
            time: x.meet_time,
            laneAssignment: x.lane_assignment,
            published: x.has_results_in_database.toLowerCase(),
            meetStatus: x.meetstatus,
            receivedServiceEmail: x.was_sent_service_email.toLowerCase(),
            totalEntries: x.totalEntries,
            homeClubEntries: x.homeClubEntries,
          }
        })
        .sort((row1, row2) => {
          if (row1.meetDate > row2.meetDate) {
            return 1
          } else if (row1.meetDate < row2.meetDate) {
            return -1
          } else if (
            row1.time.slice(-2) === "pm" &&
            row2.time.slice(-2) === "am"
          ) {
            return 1
          } else if (
            row1.time.slice(-2) === "am" &&
            row2.time.slice(-2) === "pm"
          ) {
            return -1
          } else if (row1.virtualMeetId && !row2.virtualMeetId) {
            return -1
          } else if (!row1.virtualMeetId && row2.virtualMeetId) {
            return 1
          } else if (
            row1.virtualMeetId &&
            row1.virtualMeetId > row2.virtualMeetId
          ) {
            return 1
          } else if (
            row1.virtualMeetId &&
            row1.virtualMeetId < row2.virtualMeetId
          ) {
            return -1
          } else if (row1.meetId > row2.meetId) {
            return 1
          } else if (row1.meetId < row2.meetId) {
            return -1
          }
          return 0
        }),
    })
    await this.setState({ loading: false })
  }

  goBack = async () => {
    await this.setState({ loading: true })
    await this.setState({
      showLeagueView: false,
      league: null,
      leagueName: null,
      date: null,
      leagueTableData: null,
    })
    await this.setState({ loading: false })
  }

  render() {
    if (!this.props.shouldRender) {
      return null
    }
    // if they're looking at the main table
    if (!this.state.showLeagueView) {
      // these are the columns
      const columns = [
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
          render: (text, record) => {
            return {
              props: {
                style: { background: record.rowColor },
              },
              children: <b>{text}</b>,
            }
          },
        },
        {
          title: "League",
          dataIndex: "league",
          key: "league",
          render: (text, record) => {
            return {
              props: {
                style: { background: record.rowColor },
              },
              children: (
                <span
                  style={{
                    cursor: "pointer",
                    color: "#56c0ea",
                    textDecoration: "underline",
                  }}
                >
                  {text}
                </span>
              ),
            }
          },
        },
        {
          title: "Number of Meets",
          dataIndex: "numMeets",
          key: "numMeets",
          render: (text, record) => {
            let meetTags = [{ color: null, content: `${record.totalMeets}` }]
            if (record.publishedMeets > 0) {
              meetTags.push({
                color: "green",
                content: `published: ${record.publishedMeets}`,
              })
            }
            if (record.unpublishedMeets > 0) {
              meetTags.push({
                color: "red",
                content: `unpublished: ${record.unpublishedMeets}`,
              })
            }
            return {
              props: {
                style: { background: record.rowColor },
              },
              children: (
                <span>
                  {meetTags.map((tag, idx) => {
                    return tag.color === null ? (
                      <Tag key={`meet count item ${idx}`}>{tag.content}</Tag>
                    ) : (
                      <Tag color={tag.color} key={`meet count item ${idx}`}>
                        {tag.content}
                      </Tag>
                    )
                  })}
                </span>
              ),
            }
          },
        },
      ]
      // now rearrange the data so it works for our table
      let theDates = this.props.data
        .map((x) => x.meet_date)
        .filter((v, i, a) => a.indexOf(v) === i)
      let theData = []
      let dateCount = 0
      let rowKey = 1
      theDates.forEach((individualDate) => {
        let rowColor = dateCount % 2 === 0 ? "#f5f5f5" : "#fafafa"
        dateCount += 1
        let theLeagues = this.props.data
          .filter((x) => x.meet_date === individualDate)
          .map((x) => `${x.home_league}||${x.home_league_id}`)
          .filter((v, i, a) => a.indexOf(v) === i)
        theLeagues.forEach((individualLeague) => {
          let theObject = {
            date: individualDate,
            league: individualLeague.split("||")[0],
            leagueId: parseInt(individualLeague.split("||")[1], 10),
            totalMeets: this.props.data.filter(
              (x) =>
                x.meet_date === individualDate &&
                `${x.home_league}||${x.home_league_id}` === individualLeague,
            ).length,
            publishedMeets: this.props.data.filter(
              (x) =>
                x.meet_date === individualDate &&
                `${x.home_league}||${x.home_league_id}` === individualLeague &&
                x.meetstatus === "results",
            ).length,
            rowColor,
            rowKey,
          }
          theObject.unpublishedMeets =
            theObject.totalMeets - theObject.publishedMeets
          rowKey += 1
          theData.push(theObject)
        })
      })
      // and here is the table
      return (
        <div style={{ marginTop: "25px" }}>
          <Table
            columns={columns}
            onRow={(record) => ({
              onClick: (e) =>
                this.switchToLeagueView(
                  record.leagueId,
                  record.league,
                  record.date,
                ),
            })}
            dataSource={theData}
            rowKey={(record) => `${record.leagueId || record.date}`}
          />
        </div>
      )
    } else {
      return (
        <div style={{ marginTop: "25px" }}>
          <div>
            <Button onClick={this.goBack}>View All Leagues</Button>
            <div style={{ textAlign: "center", margin: "auto" }}>
              <h1>
                <span style={{ color: "#56c0ea" }}>
                  {this.state.leagueName}
                </span>
                {" - "}
                <span style={{ color: "#56c0ea" }}>{this.state.date}</span>
              </h1>
            </div>
          </div>
          <Table
            dataSource={this.state.leagueTableData}
            rowKey={(record) => record.meetId}
          >
            <Column title="Meet Id" dataIndex="meetId" key="meetId" />
            <Column
              title="Virtual Meet Id"
              dataIndex="virtualMeetId"
              key="virtualMeetId"
            />
            <Column title="Meet Name" dataIndex="meetName" key="meetName" />
            <Column title="Home Club" dataIndex="homeClub" key="homeClub" />
            <Column title="Time" dataIndex="time" key="time" />
            <Column title="Status" dataIndex="meetStatus" key="meetStatus" />
            <Column
              title="Received Service Email"
              dataIndex="receivedServiceEmail"
              render={(text) => (
                <div style={{ textAlign: "center" }}>
                  <Tag
                    color={text === "yes" ? "green" : "red"}
                    style={{ textAlign: "center" }}
                  >
                    {text}
                  </Tag>
                </div>
              )}
              key="receivedServiceEmail"
            />
            <Column
              title="Published"
              dataIndex="published"
              render={(text) => (
                <div style={{ textAlign: "center" }}>
                  <Tag color={text === "yes" ? "green" : "red"}>{text}</Tag>
                </div>
              )}
              key="published"
            />
          </Table>
        </div>
      )
    }
  }
}
