import { Fragment, useEffect, useState } from "react"
import styled from "styled-components"
import $ from "jquery"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../../appData/types"
import {
  formatNumberAsTime,
  gen,
  ISwimmerWithNumericGender,
  parseTime,
  swimminglyApi,
} from "../utils"
import InlineTextEdit from "../InlineTextEdit"
import { Tooltip } from "antd"
import { CloseOutlined } from "@ant-design/icons"

interface ISwimmersInEventProps {
  enteredEventSwimmers: {
    swimmer: ISwimmerWithNumericGender
    meetAge: number
    attendingMeet: boolean | null
    seedTime: number | null
    previousBest: number | null
    converted: boolean
    isExhibition: boolean
    individualEntries: number
    relayEntries: number
    teamNumber: number
    teamPosition: number
    isRelay: 0 | 1
    swimEventId: number
    meetEntryId: number
  }[]
  isRelay: boolean
  meetId: number
  swimEventId: number
  setSwimmerSelectedToViewHistory: (
    newValue: { swimmer: ISwimmerWithNumericGender; meetAge: number } | null,
  ) => void
  refreshSwimmers: () => void
}

const StyledSwimmerDisplay = styled.div`
  display: grid;
  grid-template-columns: 10px 5fr 2fr 1fr 1fr 2fr 15px;
  grid-auto-rows: 25px;
  column-gap: 5px;
  row-gap: 5px;
  min-height: 0;
  min-width: 0;
  overflow: hidden;
  cursor: pointer;

  .header-row {
    /* height: 25px; */
    grid-column: 1 / span 7;
    display: grid;
    grid-template-columns: 10px 5fr 2fr 1fr 1fr 2fr 150px;
    column-gap: 5px;
    row-gap: 5px;
    background-color: var(--lightgrey);
    min-height: 0;
    min-width: 0;
    overflow: hidden;
  }

  .relay-team-header {
    grid-column: 1 / span 7;
  }

  .row::before,
  .row::after {
    display: none;
    visibility: hidden;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
    grid-column: 1 / span 7;
    display: grid;
    grid-template-columns: 10px 5fr 2fr 1fr 1fr 2fr 15px;
    column-gap: 5px;
    row-gap: 5px;
    min-height: 0;
    min-width: 0;
    overflow: hidden;
  }
  .remove-swimmer {
    cursor: pointer;
  }
  .individual-entries {
    background: #25bfea;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: #fff;
    float: left;
    line-height: 20px;
    text-align: center;
    vertical-align: middle;
    margin-right: 1px;
  }

  .relay-entries {
    background: #8092a2;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: #fff;
    float: left;
    line-height: 20px;
    text-align: center;
    vertical-align: middle;
    margin-left: 1px;
  }
`

export default function SwimmersInEvent({
  meetId,
  swimEventId,
  isRelay,
  enteredEventSwimmers,
  setSwimmerSelectedToViewHistory,
  refreshSwimmers,
}: ISwimmersInEventProps): JSX.Element {
  const impersonateClub = useStoreState(
    (state: StateMapper<AppDataStore>) => state.aliasedClub,
  )
  const [triggerRefreshSeedTimeText, setTriggerRefreshSeedTimeText] =
    useState(0)

  useEffect(() => {
    if (isRelay) {
      $(".relay").draggable({
        appendTo: "body",
        helper: "clone",
        cursor: "pointer",
        revert: "invalid",
        distance: 3,
      })
      $(".relay").droppable({
        activeClass: "ui-state-default",
        hoverClass: "ui-drop-hover",
        accept: ":not(.ui-sortable-helper)",

        over: () => {},
        drop: function (_event: any, ui: any) {
          let frmEvent = $(ui.draggable)
          let frmEntryId = frmEvent.attr("data-entry-id")
          let frmTeamNumber = frmEvent.attr("data-team-number")
          let frmTeamPosition = frmEvent.attr("data-team-position")
          let toEvent = $(this)
          let toEntryId = toEvent.attr("data-entry-id")
          let toTeamNumber = toEvent.attr("data-team-number")
          let toTeamPosition = toEvent.attr("data-team-position")

          let entriesChanges = `${frmEntryId},${toTeamNumber},${toTeamPosition}|${toEntryId},${frmTeamNumber},${frmTeamPosition}`
          $.ajax({
            url: gen("/api/sortSwimmer2Post"),
            method: "POST",
            dataType: "json",
            data: {
              entriesChanges: entriesChanges,
            },
          }).done(refreshSwimmers)
        },
      })
    }
  }, [isRelay, refreshSwimmers])

  const removeSwimmerFromEvent = (swimmerId: number) => {
    if (impersonateClub?.clubId) {
      swimminglyApi
        .post(gen("/api/removeSwimmerEvent2Post"))
        .body({
          meet_id: meetId,
          swimmer_id: swimmerId,
          swim_event_id: swimEventId,
          isrelay: isRelay,
          clubId: impersonateClub?.clubId,
        })
        .then((data) => {
          refreshSwimmers()
        })
    }
  }

  const updateSeedTime = (swimmerId: number, newTime: number) => {
    impersonateClub?.clubId &&
      swimminglyApi
        .post(gen("/api/changeEventTimePost"))
        .body({
          club: impersonateClub.clubId,
          meetId: meetId,
          athleteId: swimmerId,
          eventId: swimEventId,
          time: newTime,
        })
        .then(refreshSwimmers)
  }

  return (
    <StyledSwimmerDisplay>
      <div className="header-row">
        <span />
        <span style={{ minHeight: 0 }}>Name</span>
        <div />
        <span>Age</span>
        <div />
        <span>Time</span>
        <span className="remove-swimmer"></span>
      </div>
      {enteredEventSwimmers.map((eES, idx) => (
        <Fragment key={`swimmer_with_title_${eES.swimmer.swimmerId}_${idx}`}>
          {isRelay &&
            (eES.teamPosition === 1 ||
              idx === 0 ||
              eES.teamNumber !== enteredEventSwimmers[idx - 1].teamNumber) && (
              <h3 className="relay-team-header">
                Team {String.fromCharCode(64 + Number(eES.teamNumber))}
              </h3>
            )}
          <div className="row" key={`swimmer_${eES.swimmer.swimmerId}_${idx}`}> 
            <span>{eES.isExhibition && "x"}
            </span>
            <Tooltip
              title="Click for swimmer details"
              placement="left"
              color={"green"}
              mouseEnterDelay={0.5}
            > 
            <span
              className={`${isRelay ? " relay" : ""}`}
              data-swimmer-id={eES.swimmer.swimmerId}
              data-entry-id={eES.meetEntryId}
              data-team-number={eES.teamNumber}
              data-team-position={eES.teamPosition}
              onClick={() => {
                const swimmer = enteredEventSwimmers.find(
                  (eES2) => eES2.swimmer.swimmerId === eES.swimmer.swimmerId,
                )?.swimmer
                const meetAge = enteredEventSwimmers.find(
                  (eES2) => eES2.swimmer.swimmerId === eES.swimmer.swimmerId,
                )?.meetAge
                if (swimmer && meetAge)
                  setSwimmerSelectedToViewHistory({ swimmer, meetAge })
                else setSwimmerSelectedToViewHistory(null)
              }}
            >
              {eES.swimmer.firstName} {eES.swimmer.lastName}
            </span>
            </Tooltip>
            <div>
            <Tooltip 
              title="Individual Entries"
              color={"#25bfea"}
            >
              <span
                className="individual-entries"
              >
                {eES.individualEntries}
              </span>
              </Tooltip>
              <Tooltip
                title="Relay Entries"
                color={"#8092a2"}
              >
              <span 
                className="relay-entries"
              >
                {eES.relayEntries}
              </span>
              </Tooltip>
            </div>
            <span>{eES.meetAge}</span>
            <div />
            <Tooltip
              title="Click to customize entry time"
              placement="right"
              color={"#25bfea"}
              mouseEnterDelay={0.5}
            >
              <span>
            <InlineTextEdit
              style={{ maxWidth: "100px" }}
              updateText={String(triggerRefreshSeedTimeText)}
              updateTheValue={async (newText: string) => {
                if (/^[0-9:.]+$/.test(newText.trim())) {
                  try {
                    const newTime = parseTime(newText.trim())
                    if (newTime)
                      await updateSeedTime(eES.swimmer.swimmerId, newTime / 100)
                  } catch (err) {
                    console.log(err)
                  }
                }
                await refreshSwimmers()
                setTriggerRefreshSeedTimeText(triggerRefreshSeedTimeText + 1)
              }}
            >
              {formatNumberAsTime(eES.seedTime)}
            </InlineTextEdit>
            </span>
            </Tooltip>
            {/* <span>{formatNumberAsTime(eES.seedTime)}</span> */}
            <Tooltip
            title="Remove entry"
            placement="right"
            color={"volcano"}
            mouseEnterDelay={0.5}
            >
            <span
              className="remove-swimmer"
              onClick={() => removeSwimmerFromEvent(eES.swimmer.swimmerId)}
            >
              <CloseOutlined />
            </span>
            </Tooltip>
          </div>
        </Fragment>
      ))}
    </StyledSwimmerDisplay>
  )
}
