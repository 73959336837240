import { useState, useEffect, useRef, useCallback } from "react"
import { debounce } from "lodash"
import moment from "moment-timezone"
import { screenSizes } from "../../styles/GlobalStyles"
import { gen, IClub, IClubSeasonInfo, swimminglyApi } from "."

interface IUseMobileProps {
  threshold: number
}
export function useMobile({ threshold }: IUseMobileProps): boolean {
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth)

  useEffect(() => {
    const handleResize = debounce(function () {
      setScreenWidth(window.innerWidth)
    }, 250)
    window.addEventListener("resize", handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return screenWidth < threshold
}

export function useScreenSize(): "small" | "medium" | "big" | "huge" {
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth)

  useEffect(() => {
    const handleResize = debounce(function () {
      setScreenWidth(window.innerWidth)
    }, 250)
    window.addEventListener("resize", handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  if (screenWidth <= screenSizes.small) {
    return "small"
  } else if (screenWidth <= screenSizes.medium) {
    return "medium"
  } else if (screenWidth <= screenSizes.big) {
    return "big"
  } else {
    return "huge"
  }
}

// referred to https://brandoncc.dev/blog/how-to-deal-with-circular-dependencies-in-react-hooks/
export function useNoRenderRef<T>(currentValue: T): React.MutableRefObject<T> {
  const ref = useRef(currentValue)
  ref.current = currentValue
  return ref
}

// referred to https://blog.logrocket.com/how-to-get-previous-props-state-with-react-hooks/
function usePrevious<T>(value: T) {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export function useClubsSeasons(
  clubId: number | null | undefined,
  pastSeasons: boolean = true,
) {
  const [seasons, setSeasons] = useState<IClubSeasonInfo[]>([])
  const [selectedSeason, setSelectedSeason] = useState(-1)
  const [clubsInSeason, setClubsInSeason] = useState<IClub[]>([])
  const [triggerUpdate, setTriggerUpdate] = useState(0)

  const previousSeason = usePrevious(selectedSeason)
  const previousClubId = usePrevious(clubId)

  const refreshClubSeasons = useCallback(
    () => setTriggerUpdate(triggerUpdate + 1),
    [triggerUpdate],
  )

  useEffect(() => {
    let keepSameSeason = false
    if (previousSeason !== undefined && previousSeason === selectedSeason)
      keepSameSeason = true
    if (previousClubId !== clubId) setSelectedSeason(-1)
    if (clubId && clubId > 0) {
      swimminglyApi
        .get(gen(`/api/getSwimSeasonsForClub/${clubId}`))
        .then((data: { status: String; seasons: IClubSeasonInfo[] }) => {
          if (data.status === "success") {
            let newSeasons = []
            if (pastSeasons) {
              newSeasons = data.seasons
            } else {
              newSeasons = data.seasons.filter(
                (season) => season.endDate >= moment().format("YYYY-MM-DD"),
              )
            }
            setSeasons(newSeasons)
            if (
              keepSameSeason &&
              newSeasons.map((s) => s.seasonId).includes(previousSeason!)
            ) {
              setSelectedSeason(previousSeason!)
            }
          } else {
            setSeasons([])
          }
        })
    } else {
      setSeasons([])
    }
  }, [
    previousClubId,
    clubId,
    pastSeasons,
    selectedSeason,
    previousSeason,
    triggerUpdate,
  ])

  useEffect(() => {
    if (selectedSeason > 0) {
      swimminglyApi
        .get(gen(`/api/getClubsInSeason/${selectedSeason}`))
        .then((data) => setClubsInSeason(data.clubs))
    } else {
      setClubsInSeason([])
    }
  }, [selectedSeason])

  return {
    clubSeasons: seasons,
    setSelectedSeason,
    selectedSeasonId: selectedSeason,
    selectedSeasonInfo: seasons.find((s) => s.seasonId === selectedSeason),
    clubsInSeason,
    refreshClubSeasons,
  }
}
