import React from "react"
import { message } from "antd"
import $ from "jquery"
import { Link } from "react-router-dom"
import swimHeader from "../images/swimmingly_final_header.png"
import { gen } from "./utils"
import moment from "moment-timezone"

export default class ActivateThisUser extends React.Component {
  componentDidMount() {
    const userId = this.props.userId
    if (!userId) {
      message.error("Not a valid userId")
    }
    $.ajax({
      url: gen("/api/activateThisUserPost"),
      method: "POST",
      dataType: "json",
      data: { userId: userId },
    }).done((data) => {
      if (data.status === "error" && /did not get email/.test(data.error)) {
        message.error(data.error)
      } else {
        message.success("User successfully validated")
      }
    })
  }

  render() {
    return (
      <div className="container">
        <div
          style={{
            paddingTop: "50px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="panel">
            <div className="panel-heading">
              <Link to="/" className="logo pull-left">
                <img
                  src={swimHeader}
                  width="250px"
                  height="50px"
                  alt="Swimmingly Header"
                />
              </Link>
              <h2 style={{ textAlign: "center", marginTop: "60px" }}>
                User Validated!
              </h2>
              <h2 style={{ textAlign: "center" }}>You did it!</h2>

              <div style={{ fontSize: "16px", textAlign: "center" }}>
                Click <Link to="/">here</Link> to login
              </div>
            </div>
          </div>
        </div>
        <p className="text-center text-muted mt-md mb-md">
          &copy; Copyright {moment().format("YYYY")}. All Rights Reserved.
        </p>
      </div>
    )
  }
}
