import { gen, swimminglyApi } from "../utils"

interface ISwimmerResults {
}

export default function getSwimmerResults(swimmerId?: number, userId?: number, clubId?: number): Promise<ISwimmerResults> {
    return swimminglyApi.post(gen("/api/getSwimmerRecentMeets2Post"))
        .body({
            swimmerId: swimmerId,
            userId: userId,
            clubId: clubId

        })
}

 