import React, { createContext, useCallback, useContext, useReducer } from "react";
import moment from "moment-timezone";
import { useStoreState, StateMapper } from "easy-peasy";
import { AppDataStore } from "../../../appData/types";
import { ISeasonDetails } from "../../../App";
import { gen, IClub, IClubSeasonInfo, swimminglyApi } from "../../utils";
import { PaymentsHelper } from "../Payments";
import { RegistrationWaiverHelper } from "../RegistrationWaivers";
import { message } from "antd";
import { BillingHelper } from "../Billing";
import { SeasonHelper } from "../Season";
import { IRegistrationWaiverSection } from "../RegistrationConfiguration/RegistrationWaiverSubsection";
import { RegistrationHelper } from "../RegistrationConfiguration/RegistrationHelper";

export interface IRegistrationForm {
  registrationFormId: number;
  clubId: number;
  seasonId: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}
export interface IWaiver {
  waiverId: number;
  registrationFormId: number;
  waiverName: string;
  waiverDescription: string;
  waiverEnabled: 0 | 1;
  waiverSectionIds: number[] | null | undefined;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  sections: IRegistrationWaiverSection[] | undefined;
}

export interface IRegistrationClubDue {
  clubDuesId: number;
  registrationFormId: number;
  numSwimmer: number;
  maxNumSwimmer: 0 | 1;
  price: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null | undefined;
}

export interface IPriceModificationCode {
  priceModificationCodeId: number;
  registrationFormId: number;
  priceModificationAmount: number;
  code: string;
  isActive: 0 | 1;
  appliesTo: "swimmer" | "whole checkout";
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null | undefined;
}

export type PaymentsConfiguration = {
  accountId?: string;
  canReceivePayments: boolean;
  accountLink?: string;
  errorMessage?: string;
};

export type BillingInfo = {
  seasonInfo: ISeasonDetails | undefined;
  hasDefaultCardOnFile: boolean;
  errorMessage: string | undefined;
};

interface IClubSeasonManagementData {
  clubSeasons: IClubSeasonInfo[];
  selectedClubSeason: IClubSeasonInfo | null;
  clubsInSeason: IClub[];
  registrationForm: IRegistrationForm | null;
  waivers: IWaiver[] | null;
  viewingWaiver: number | null;
  dbUsingRegistration: boolean | null;
  frontendUsingRegistration: boolean | null;
  hasStartedConfiguration: boolean;
  registrationModalVisible: boolean;
  pricePoints: IRegistrationClubDue[];
  priceModificationCodes: IPriceModificationCode[];
  paymentsConfiguration: PaymentsConfiguration;
  billingInfo: BillingInfo;
  upcomingSeason: ISeasonDetails | undefined;
  allUpcomingSeasons: ISeasonDetails[] | [];
  waiversWithSections: IWaiver[];
}

interface IClubSeasonManagementContext extends IClubSeasonManagementData {
  loadClubSeasons: () => Promise<void>;
  selectSeason: (selectedSeasonId: number) => Promise<void>;
  setSeasons: (newClubSeasons: IClubSeasonInfo[]) => void;
  loadRegistrationInfo: (seasonId: number) => Promise<void>;
  chooseRegistration: (choice: boolean | null, seasonId: number) => Promise<void>;
  setDbUsingRegistration: (choice: boolean | null) => void;
  confirmRegistrationChoice: (seasonId: number, choice: boolean | null) => Promise<void>;
  startOrStopConfiguration: (choice: "start" | "stop") => void;
  setRegistrationModalVisible: (visible: boolean) => void;
  refreshOneWaiver: (waiverId: number) => Promise<void>;
  loadPricePoints: () => Promise<void>;
  updatePricePoint: (clubDuesId: number, numSwimmer: number, price: number) => Promise<void>;
  addPriceModificationCode: (priceModificationAmount: number, code: string) => Promise<void>;
  updatePriceModificationCode: (priceModificationCodeId: number, priceModificationAmount: number, code: string, isActive: 0 | 1) => Promise<void>;
  loadPaymentsConfiguration: (clubId: number, seasonId: number) => Promise<void>;
  loadBillingInfo: (clubId?: number, seasonId?: number) => Promise<void>;
  loadUpcomingSeason: () => Promise<void>;
  loadWaiversWithSections: (clubId: number, seasonId: number) => Promise<void>;
  setSelectedClubSeason: (season: IClubSeasonInfo | null) => void;
  setClubsInSeason: (clubs: IClub[]) => void;
}

const defaultData: IClubSeasonManagementContext = {
  clubSeasons: [],
  selectedClubSeason: null,
  clubsInSeason: [],
  registrationForm: null,
  waivers: null,
  viewingWaiver: null,
  dbUsingRegistration: null,
  frontendUsingRegistration: null,
  hasStartedConfiguration: false,
  registrationModalVisible: false,
  pricePoints: [],
  priceModificationCodes: [],
  paymentsConfiguration: { accountId: undefined, canReceivePayments: false },
  billingInfo: { hasDefaultCardOnFile: false, seasonInfo: undefined, errorMessage: undefined },
  upcomingSeason: undefined,
  allUpcomingSeasons: [],
  waiversWithSections: [],
  loadClubSeasons: () => new Promise<void>((resolve) => resolve()),
  selectSeason: () => new Promise<void>((resolve) => resolve()),
  setSeasons: () => { },
  loadRegistrationInfo: () => new Promise<void>((resolve) => resolve()),
  chooseRegistration: () => new Promise<void>((resolve) => resolve()),
  setDbUsingRegistration: () => { },
  confirmRegistrationChoice: () => new Promise<void>((resolve) => resolve()),
  startOrStopConfiguration: () => { },
  setRegistrationModalVisible: () => { },
  refreshOneWaiver: () => new Promise<void>((resolve) => resolve()),
  loadPricePoints: () => new Promise<void>((resolve) => resolve()),
  updatePricePoint: () => new Promise<void>((resolve) => resolve()),
  addPriceModificationCode: () => new Promise<void>((resolve) => resolve()),
  updatePriceModificationCode: () => new Promise<void>((resolve) => resolve()),
  loadPaymentsConfiguration: () => new Promise<void>((resolve) => resolve()),
  loadBillingInfo: () => new Promise<void>((resolve) => resolve()),
  loadUpcomingSeason: () => new Promise<void>((resolve) => resolve()),
  loadWaiversWithSections: () => new Promise<void>((resolve) => resolve()),
  setSelectedClubSeason: () => { },
  setClubsInSeason: () => { },
}

const SharedContext = createContext<IClubSeasonManagementContext>(defaultData);

type TClubSeasonManagementActions = {
  type:
  | "setSeasons"
  | "selectSeason"
  | "updateForm"
  | "chooseRegistration"
  | "confirmRegistrationChoice"
  | "startOrStopConfiguration"
  | "setRegistrationModalVisible"
  | "setWaivers"
  | "setPricePoints"
  | "setPaymentsConfiguration"
  | "setBillingInfo"
  | "setUpcomingSeason"
  | "setWaiversWithSections"
  newData: Partial<IClubSeasonManagementData>
}

const reducer = (
  prevState: IClubSeasonManagementData,
  action: TClubSeasonManagementActions,
): IClubSeasonManagementData => {
  if (action.type === "setSeasons") {
    const newState: IClubSeasonManagementData = {
      ...prevState,
      clubSeasons: action.newData.clubSeasons || [],
    }
    const newSeasonIds =
      action.newData.clubSeasons
        ?.map((s) => s.seasonId)
        .filter((v, i, a) => a.indexOf(v) === i) || []
    if (
      action.newData.selectedClubSeason &&
      newSeasonIds.includes(action.newData.selectedClubSeason.seasonId)
    ) {
      newState.selectedClubSeason = action.newData.selectedClubSeason
    } else if (prevState.selectedClubSeason) {
      const currentSeasonIsInNewSeasonsList = newSeasonIds.includes(
        prevState.selectedClubSeason.seasonId,
      )
      if (!currentSeasonIsInNewSeasonsList) {
        newState.selectedClubSeason = null
      }
    }
    return newState
  } else if (action.type === "selectSeason") {
    const seasonIds = prevState.clubSeasons.map((s) => s.seasonId)
    const newSelectedClubSeason = action.newData.selectedClubSeason
    const newClubsInSeason = action.newData.clubsInSeason
    const newState: IClubSeasonManagementData = { ...prevState }
    if (
      newSelectedClubSeason &&
      seasonIds.includes(newSelectedClubSeason.seasonId)
    ) {
      newState.selectedClubSeason = newSelectedClubSeason
    } else {
      newState.selectedClubSeason = null
    }
    if (newClubsInSeason) {
      newState.clubsInSeason = newClubsInSeason
    }
    return newState
  } else if (action.type === "updateForm") {
    const newState: IClubSeasonManagementData = { ...prevState }
    if (action.newData.registrationForm !== undefined) {
      newState.registrationForm = action.newData.registrationForm
    }
    if (action.newData.waivers !== undefined) {
      newState.waivers = action.newData.waivers
    }
    if (action.newData.viewingWaiver !== undefined) {
      newState.viewingWaiver = action.newData.viewingWaiver
    }
    if (action.newData.hasStartedConfiguration !== undefined) {
      newState.hasStartedConfiguration = action.newData.hasStartedConfiguration
    }
    if (action.newData.frontendUsingRegistration !== undefined) {
      newState.frontendUsingRegistration =
        action.newData.frontendUsingRegistration
    }
    if (action.newData.dbUsingRegistration !== undefined) {
      newState.dbUsingRegistration = action.newData.dbUsingRegistration
      if (action.newData.dbUsingRegistration === true) {
        newState.frontendUsingRegistration = true
      }
    }
    if (action.newData.pricePoints !== undefined) {
      newState.pricePoints = action.newData.pricePoints
    }
    if (action.newData.priceModificationCodes !== undefined) {
      newState.priceModificationCodes = action.newData.priceModificationCodes
    }
    return newState
  } else if (action.type === "chooseRegistration") {
    const newState: IClubSeasonManagementData = { ...prevState }
    if (action.newData.frontendUsingRegistration !== undefined) {
      newState.frontendUsingRegistration =
        action.newData.frontendUsingRegistration
    }
    return newState
  } else if (action.type === "confirmRegistrationChoice") {
    const newState: IClubSeasonManagementData = { ...prevState }
    if (action.newData.dbUsingRegistration !== undefined) {
      newState.frontendUsingRegistration = action.newData.dbUsingRegistration
      newState.dbUsingRegistration = action.newData.dbUsingRegistration
    }
    if (action.newData.selectedClubSeason !== undefined) {
      newState.selectedClubSeason = action.newData.selectedClubSeason
    }
    if (action.newData.clubSeasons !== undefined) {
      newState.clubSeasons = action.newData.clubSeasons
    }
    return newState
  } else if (action.type === "startOrStopConfiguration") {
    const newState: IClubSeasonManagementData = { ...prevState }
    if (action.newData.hasStartedConfiguration !== undefined) {
      newState.hasStartedConfiguration = action.newData.hasStartedConfiguration
    }
    return newState
  } else if (action.type === "setRegistrationModalVisible") {
    const newState: IClubSeasonManagementData = { ...prevState }
    if (action.newData.registrationModalVisible !== undefined) {
      newState.registrationModalVisible =
        action.newData.registrationModalVisible
    }
    return newState
  } else if (action.type === "setWaivers") {
    const newState: IClubSeasonManagementData = { ...prevState }
    if (action.newData.waivers !== undefined) {
      newState.waivers = action.newData.waivers
    }
    return newState
  } else if (action.type === "setPricePoints") {
    const newState: IClubSeasonManagementData = { ...prevState }
    if (action.newData.pricePoints !== undefined) {
      newState.pricePoints = action.newData.pricePoints
    }
    if (action.newData.priceModificationCodes !== undefined) {
      newState.priceModificationCodes = action.newData.priceModificationCodes
    }
    return newState
  } else if (action.type === "setPaymentsConfiguration") {
    const newState: IClubSeasonManagementData = { ...prevState }
    if (action.newData.paymentsConfiguration !== undefined) {
      newState.paymentsConfiguration = action.newData.paymentsConfiguration
    }
    return newState
  } else if (action.type === "setBillingInfo") {
    const newState: IClubSeasonManagementData = { ...prevState }
    if (action.newData.billingInfo !== undefined) {
      newState.billingInfo = action.newData.billingInfo
    }
    return newState
  } else if (action.type === "setUpcomingSeason") {

    const newState: IClubSeasonManagementData = { ...prevState }
    //keith says: no null check here because we want to know that there's no more upcoming season.
    newState.upcomingSeason = action.newData.upcomingSeason
    newState.allUpcomingSeasons = action.newData.allUpcomingSeasons ?? []
    return newState
  } else if (action.type === "setWaiversWithSections") {
    const newState: IClubSeasonManagementData = { ...prevState }
    if (action.newData.waiversWithSections !== undefined) {
      newState.waiversWithSections = action.newData.waiversWithSections
    }
    return newState
  }
  return prevState
}

const getRegistrationFormDataViaApiCall = (
  clubId: number,
  seasonId: number,
  fn: (data: {
    status: string;
    registrationInfo: IRegistrationForm;
    waivers: IWaiver[];
    dbUsingRegistration: boolean;
    pricePoints: IRegistrationClubDue[];
    priceModificationCodes: IPriceModificationCode[];
  }) => any,
) => {
  return swimminglyApi
    .get(
      gen(
        `/api/getRegistrationFormForClubSeason/clubId=${clubId}/seasonId=${seasonId}`,
      ),
    )
    .then(fn)
}

export function ClubSeasonManagementContextProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const impersonateClub = useStoreState(
    (state: StateMapper<AppDataStore>) => state.aliasedClub,
  )
  const [state, dispatch] = useReducer(reducer, {
    clubSeasons: [],
    selectedClubSeason: null,
    clubsInSeason: [],
    registrationForm: null,
    waivers: null,
    viewingWaiver: null,
    dbUsingRegistration: null,
    frontendUsingRegistration: null,
    hasStartedConfiguration: false,
    registrationModalVisible: false,
    pricePoints: [],
    priceModificationCodes: [],
    paymentsConfiguration: defaultData.paymentsConfiguration,
    billingInfo: defaultData.billingInfo,
    upcomingSeason: defaultData.upcomingSeason,
    allUpcomingSeasons: defaultData.allUpcomingSeasons,
    waiversWithSections: defaultData.waiversWithSections
  })

  const loadClubSeasons = useCallback(async () => {
    if (impersonateClub && impersonateClub.clubId) {
      const newClubSeasons = await swimminglyApi
        .get(gen(`/api/getSwimSeasonsForClub/${impersonateClub.clubId}`))
        .then((data: { status: String; seasons: IClubSeasonInfo[] }) => {
          if (data.status === "success") {
            return data.seasons.filter(
              (season) => season.endDate >= moment().format("YYYY-MM-DD"),
            )
          } else {
            return []
          }
        })
      dispatch({ type: "setSeasons", newData: { clubSeasons: newClubSeasons } })
    }
  }, [impersonateClub])

  const selectSeason = useCallback(
    async (selectedSeasonId: number) => {
      const selectedClubSeason = state.clubSeasons.find(
        (s) => s.seasonId === selectedSeasonId,
      )
      if (selectedClubSeason) {
        const clubsInSeason = await swimminglyApi
          .get(gen(`/api/getClubsInSeason/${selectedSeasonId}`))
          .then((data: { status: string; clubs: IClub[] }) => data.clubs)
        dispatch({
          type: "selectSeason",
          newData: {
            selectedClubSeason,
            clubsInSeason,
          },
        })
      }
    },
    [state.clubSeasons],
  )

  const setSeasons = useCallback((newClubSeasons: IClubSeasonInfo[]) => {
    dispatch({ type: "setSeasons", newData: { clubSeasons: newClubSeasons } })
  }, [])

  const loadRegistrationInfo = useCallback(
    async (seasonId: number) => {
      if (!impersonateClub?.clubId) return
      const clubId = impersonateClub.clubId
      //also get the season information so we can tell if the season has been confirmed (and therefor discriminate between registration affirmitively opted out or just not yet chosen)
      const seasonDetails = await new SeasonHelper().getSeasonDetails(clubId, seasonId)
      return await getRegistrationFormDataViaApiCall(
        clubId,
        seasonId,
        (data: {
          status: string;
          registrationInfo: IRegistrationForm;
          waivers: IWaiver[];
          dbUsingRegistration: boolean;
          pricePoints: IRegistrationClubDue[];
          priceModificationCodes: IPriceModificationCode[];
        }) => {
          if (data.status === "success") {
            const seasonIsConfirmed = Boolean(seasonDetails.seasonSetupConfirmedBy)
            const frontendUsingRegistration = Boolean(data.dbUsingRegistration) ? true : (seasonIsConfirmed ? false : undefined)
            dispatch({
              type: "updateForm",
              newData: {
                registrationForm: data.registrationInfo,
                waivers: data.waivers,
                hasStartedConfiguration: data.dbUsingRegistration,
                dbUsingRegistration: data.dbUsingRegistration,
                frontendUsingRegistration: frontendUsingRegistration,
                pricePoints: data.pricePoints,
                priceModificationCodes: data.priceModificationCodes,
              },
            })
          } else {
            dispatch({
              type: "updateForm",
              newData: {
                registrationForm: null,
                waivers: null,
                hasStartedConfiguration: false,
                pricePoints: [],
                priceModificationCodes: [],
              },
            })
          }
        },
      )
    },
    [impersonateClub?.clubId],
  )

  const chooseRegistration = useCallback(
    async (choice: boolean | null, seasonId: number) => {
      //Do not change the registration opt in/opt out when there season setup is already confirmed
      if (!impersonateClub?.clubId || Boolean(state.selectedClubSeason?.seasonSetupConfirmedBy)) return


      const clubId = impersonateClub.clubId
      if (choice === true && (!state.waivers || state.waivers.length === 0)) {
        const createdForm = await swimminglyApi
          .post(
            gen(
              `/api/createDefaultRegistrationForm/clubId=${clubId}/seasonId=${seasonId}`,
            ),
          )
          .body({})
          .then((data) => {
            if (data.status === "success") {
              return true
            } else {
              return false
            }
          })
        if (!createdForm) {
          message.error("Problem creating registration defaults")
        }
        await getRegistrationFormDataViaApiCall(
          clubId,
          seasonId,
          (data: {
            status: string;
            registrationInfo: IRegistrationForm;
            waivers: IWaiver[];
            dbUsingRegistration: boolean;
            pricePoints: IRegistrationClubDue[];
            priceModificationCodes: IPriceModificationCode[];
          }) => {
            dispatch({
              type: "updateForm",
              newData: {
                registrationForm: data.registrationInfo,
                waivers: data.waivers,
                frontendUsingRegistration: true,
                pricePoints: data.pricePoints,
                priceModificationCodes: data.priceModificationCodes,
              },
            })
          },
        ).catch((err) => {
          message.error("Problem getting registration setup for your club")
          dispatch({
            type: "updateForm",
            newData: {
              registrationForm: null,
              waivers: null,
              hasStartedConfiguration: false,
              frontendUsingRegistration: true,
              pricePoints: [],
              priceModificationCodes: [],
            },
          })
        })
      } else {

        swimminglyApi
          .post(gen(`/api/confirmUsingRegistration`))
          .body({
            clubId: impersonateClub?.clubId,
            seasonId: seasonId,
            usingRegistration: choice === true,
          })
          .then((data) => {
            const newClubSeasons = [...state.clubSeasons]
            const newSelectedClubSeason = newClubSeasons.find(
              (clubSeason) =>
                clubSeason.seasonId === state.selectedClubSeason?.seasonId,
            )
            if (newSelectedClubSeason) {
              newSelectedClubSeason.usingRegistration = choice || false
              dispatch({
                type: "confirmRegistrationChoice",
                newData: {
                  dbUsingRegistration: choice,
                  clubSeasons: newClubSeasons,
                  selectedClubSeason: newSelectedClubSeason,
                },
              })
            } else {
              dispatch({
                type: "confirmRegistrationChoice",
                newData: { dbUsingRegistration: choice },
              })
            }

          })

      }
    },
    [impersonateClub, state.waivers, state.clubSeasons, state.selectedClubSeason?.seasonId, state.selectedClubSeason?.seasonSetupConfirmedBy],
  )

  const setDbUsingRegistration = useCallback((choice: boolean | null) => {
    dispatch({
      type: "confirmRegistrationChoice",
      newData: { dbUsingRegistration: choice },
    })
  }, [])

  const confirmRegistrationChoice = useCallback(
    async (seasonId: number, choice: boolean | null) => {

      if (state.dbUsingRegistration !== choice && choice === true) {
        swimminglyApi
          .post(gen(`/api/confirmUsingRegistration`))
          .body({
            clubId: impersonateClub?.clubId,
            seasonId: seasonId,
            usingRegistration: true,
          })
          .then((data) => {
            if (data.status === "success") {
              const newClubSeasons = [...state.clubSeasons]
              const newSelectedClubSeason = newClubSeasons.find(
                (clubSeason) =>
                  clubSeason.seasonId === state.selectedClubSeason?.seasonId,
              )
              if (newSelectedClubSeason) {
                newSelectedClubSeason.usingRegistration = choice
                dispatch({
                  type: "confirmRegistrationChoice",
                  newData: {
                    dbUsingRegistration: choice,
                    clubSeasons: newClubSeasons,
                    selectedClubSeason: newSelectedClubSeason,
                  },
                })
              } else {
                dispatch({
                  type: "confirmRegistrationChoice",
                  newData: { dbUsingRegistration: choice },
                })
              }
              message.success("You're confirmed to use registration!")
            } else {
              message.error(
                "Something went wrong trying to confirm your club using regsitration",
              )
            }
          })
      } else if (state.dbUsingRegistration !== choice) {
        const newClubSeasons = [...state.clubSeasons]
        const newSelectedClubSeason = newClubSeasons.find(
          (clubSeason) =>
            clubSeason.seasonId === state.selectedClubSeason?.seasonId,
        )
        if (newSelectedClubSeason) {
          newSelectedClubSeason.usingRegistration = choice || false
          dispatch({
            type: "confirmRegistrationChoice",
            newData: {
              dbUsingRegistration: choice,
              clubSeasons: newClubSeasons,
              selectedClubSeason: newSelectedClubSeason,
            },
          })
        } else {
          dispatch({
            type: "confirmRegistrationChoice",
            newData: { dbUsingRegistration: choice },
          })
        }
      }
    },
    [
      impersonateClub?.clubId,
      state.clubSeasons,
      state.dbUsingRegistration,
      state.selectedClubSeason?.seasonId,
    ],
  )

  const startOrStopConfiguration = useCallback((choice: "start" | "stop") => {
    if (choice === "start") {
      dispatch({
        type: "startOrStopConfiguration",
        newData: { hasStartedConfiguration: true },
      })
    } else {
      dispatch({
        type: "startOrStopConfiguration",
        newData: { hasStartedConfiguration: false },
      })
    }
  }, [])

  const setRegistrationModalVisible = useCallback((choice: boolean) => {
    dispatch({
      type: "setRegistrationModalVisible",
      newData: { registrationModalVisible: choice },
    })
  }, [])

  const refreshOneWaiver = useCallback(
    async (waiverId: number) => {
      return await swimminglyApi
        .get(gen(`/api/waiver/${waiverId}`))
        .then((data: { status: string; waiver: IWaiver }) => {
          const newWaivers = state.waivers ? [...state.waivers] : [data.waiver]
          const newWaiverIdx = newWaivers.findIndex(
            (waiver) => waiver.waiverId === waiverId,
          )
          if (newWaiverIdx > -1) {
            newWaivers.splice(newWaiverIdx, 1, data.waiver)
            dispatch({ type: "setWaivers", newData: { waivers: newWaivers } })
          }
        })
    },
    [state.waivers],
  )

  const loadPricePoints = useCallback(async () => {
    if (state.registrationForm?.clubId && state.registrationForm?.seasonId) {
      const clubId = state.registrationForm.clubId
      const seasonId = state.registrationForm.seasonId
      try {
        const registrationResult = await (new RegistrationHelper()).getRegistrationFormInfo(clubId, seasonId)
        const registrationForm = registrationResult.registrationForm
        dispatch({
          type: "setPricePoints",
          newData: {
            pricePoints: registrationForm.pricePoints,
            priceModificationCodes: registrationForm.priceModificationCodes,
          },
        })
      } catch (error) {
        console.log("Error retreiving price points for dispatch")
      }
    }
  }, [state.registrationForm?.registrationFormId])

  const updatePricePoint = useCallback(
    async (clubDuesId: number, numSwimmer: number, price: number) => {
      await swimminglyApi
        .post(gen(`/api/updatePricePoint/${clubDuesId}`))
        .body({
          numSwimmer,
          price,
        })
        .then(async (data) => {
          if (data.status === "success") {
            await loadPricePoints()
          }
        })
    },
    [loadPricePoints],
  )


  const addPriceModificationCode = useCallback(
    async (priceModificationAmount: number, code: string) => {
      if (state.registrationForm?.registrationFormId) {
        const registrationFormId = state.registrationForm.registrationFormId
        swimminglyApi
          .post(gen(`/api/addPriceModificationCode/${registrationFormId}`))
          .body({
            priceModificationAmount,
            code,
          })
          .then((data) => {
            if (data.status === "error") {
              if (/duplicate entry/.test(data.message.toLowerCase())) {
                message.error(
                  "You already have a discount or fee with the same code!",
                )
              } else {
                message.error(data.message || "Something went wrong!")
              }
            }
          })
          .catch((err) => {
            if (err instanceof Error) message.error(err.message)
            else message.error("Something went wrong!")
          })
          .finally(loadPricePoints)
      }
    },
    [loadPricePoints, state.registrationForm?.registrationFormId],
  )

  const updatePriceModificationCode = useCallback(
    async (
      priceModificationCodeId: number,
      priceModificationAmount: number,
      code: string,
      isActive: 0 | 1,
    ) => {
      swimminglyApi
        .post(
          gen(`/api/updatePriceModificationCode/${priceModificationCodeId}`),
        )
        .body({
          priceModificationAmount,
          code,
          isActive,
        })
        .then(loadPricePoints)
    },
    [loadPricePoints],
  )

  const loadPaymentsConfiguration = useCallback(async (clubId: number, seasonId: number) => {
    const paymentsHelper: PaymentsHelper = new PaymentsHelper()
    const paymentsConfiguration = await paymentsHelper.getPaymentsConfiguration(clubId, seasonId)

    dispatch({ type: "setPaymentsConfiguration", newData: { paymentsConfiguration: paymentsConfiguration } })

  }, [])

  const loadBillingInfo = useCallback(async (clubId?: number, seasonId?: number) => {
    if (clubId !== undefined && seasonId !== undefined) {
      const billingHelper: BillingHelper = new BillingHelper()
      const billingInfo = await billingHelper.getBillingInfo(clubId, seasonId)

      dispatch({ type: "setBillingInfo", newData: { billingInfo: billingInfo } })
    }

  }, [])

  const loadWaiversWithSections = useCallback(async (clubId: number, seasonId: number) => {
    const waiverHelper: RegistrationWaiverHelper = new RegistrationWaiverHelper()
    const waiversWithSections = await waiverHelper.getWaiversWithSections(clubId, seasonId)
    dispatch({ type: "setWaiversWithSections", newData: { waiversWithSections: waiversWithSections } })
  }, [])


  const loadUpcomingSeason = useCallback(async () => {
    if (impersonateClub && impersonateClub.clubId) {
      const helper: SeasonHelper = new SeasonHelper()
      const seasons = (await helper.getUpcomingSeasonDetails(impersonateClub.clubId)).reverse()
      const firstUpcomingSeason = seasons.filter((season) => Boolean(season.isSwimminglyCustomer)).shift()
      dispatch({ type: "setUpcomingSeason", newData: { upcomingSeason: firstUpcomingSeason, allUpcomingSeasons: seasons } })

    }
  }, [impersonateClub])

  const setSelectedClubSeason = useCallback((season: IClubSeasonInfo | null) => {
    dispatch({ type: "selectSeason", newData: { selectedClubSeason: season } });
  }, []);

  const setClubsInSeason = useCallback((clubs: IClub[]) => {
    dispatch({ type: "selectSeason", newData: { clubsInSeason: clubs } });
  }, []);

  return (
    <SharedContext.Provider
      value={{
        ...state,
        loadClubSeasons,
        selectSeason,
        setSeasons,
        loadRegistrationInfo,
        chooseRegistration,
        setDbUsingRegistration,
        confirmRegistrationChoice,
        startOrStopConfiguration,
        setRegistrationModalVisible,
        refreshOneWaiver,
        loadPricePoints,
        updatePricePoint,
        addPriceModificationCode,
        updatePriceModificationCode,
        loadPaymentsConfiguration,
        loadBillingInfo,
        loadUpcomingSeason,
        loadWaiversWithSections,
        setSelectedClubSeason,
        setClubsInSeason
      }}
    >
      {children}
    </SharedContext.Provider>
  )
}

export function useClubSeasonManagementContext() {
  const context = useContext(SharedContext)

  if (!context)
    throw new Error(
      "useClubSeasonManagementContext must be used inside a `ClubSeasonManagementContextProvider`",
    )

  return context
}
