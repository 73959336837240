import ReactDependentScript from "react-dependent-script"
import { useStoreState, StateMapper } from "easy-peasy"
import { AppDataStore } from "../appData/types"
import ParentSwimmers from "./ParentSwimmers"
import { IUser, ISwimmerWithNumericGender } from "./utils"
import ActionAlerts from "./ActionAlerts"
import { StyledWistia } from "./utils/sharedDataTypes"

// import MergeSwimmerButton from "./MergeSwimmerButton"

export interface IRosterSwimmer extends ISwimmerWithNumericGender {
  swimmerShoulderNumber: number | null
  leagueAge: number
  importedFromResults?: 0 | 1
  guardians: IUser[]
}

interface IMySwimmersProps {
  user: IUser
}


function MySwimmersInner({ user }: IMySwimmersProps): JSX.Element {
  return (
    <div>
      <ActionAlerts />
      <section className="panel">
        <div className="ourpanel-body">
          <ParentSwimmers user={user}/>
        </div>
      </section>
    </div>
  )
}

export default function MySwimmers(): JSX.Element {
  const user = useStoreState((state: StateMapper<AppDataStore>) => state.user)

  return (
    <div>
      <header className="page-header">
        <h2>My Swimmers</h2>
      </header>

      {user && <MySwimmersInner user={user} />}
    </div>
  )
}
